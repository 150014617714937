/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-nested-ternary */
import { useEffect, useState  } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { getMyRecordingsDetailed, getMySong, getMyAccount } from '../../../../api/queries';
import { hasAdminRights } from '../../../../auth/shared';
import LoadingSmall from '../../../../components/loadingSmall';
import Spacer from '../../../../components/spacer';
import AppState from '../../../../store/appstate';
import SongRecording from './SongRecording';
import SongWriterSplit from './SongWriterSplit';
import RebrandButtonLink from '../../../../components/rebrandButtonLink';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';
import Modal from '../../../../components/Modal';
import RecordingForm from './RecordingForm';
import SongsCreateUpdate from './songsCreateUpdate';

function SongDetails(props) {
  const { t } = useTranslation('songs');
  const {
    row,
  } = props;
  const { original } = row;
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const styles = isTunecore
  ? { textColor:'black', backgroundColor: '#00ef86' }
  : {};

  const [recordingModalIsOpen, setRecordingModalIsOpen] = useState(false);
  const [songEditModalIsOpen, setSongEditModalIsOpen] = useState(false);

  const [selectedRecordingId, setSelectedRecordingId] = useState(null);
  const [selectedSongEditId, setSelectedSongEditId] = useState(null);

  const [currentAac, setAac] = useState(null);

  useEffect(() => {
    setAac(original.artistaccountcode);
  }, []);

  let song = null;
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const songTemp = getMySong({ aac: original.artistaccountcode || selectedAccount, wc: original.workcode });

  if (songTemp.isSuccess && songTemp.data) {
    song = songTemp.data;
  }

  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const adminRights = hasAdminRights(myAccount?.data);
  const recordings = getMyRecordingsDetailed({ aac: original.artistaccountcode || selectedAccount, wc: original.workcode });
  useEffect(() => {
    recordings.refetch();
  }, [song]);

  let allRecordings = [];
  if (recordings.isSuccess && song) {
    allRecordings = recordings.data.recordings;
    const otherRecordings = song.recordings.filter((s) => !s.recordingidentifier);
    const otherRecordingsWithIds = otherRecordings.map((recordingItem) => ({
      artistaccountcode: song.artistaccountcode,
      isdeleted: recordingItem.isdeleted,
      isrc: recordingItem.isrc,
      performingartist: recordingItem.performingartist,
      recordingamendmentidentifier: recordingItem.recordingamendmentidentifier,
      recordingidentifier: recordingItem.recordingidentifier,
      recordlabel: recordingItem.recordlabel,
      releasedate: recordingItem.releasedate,
      title: recordingItem.title,
      uicbarcode: recordingItem.uicbarcode,
      workcode: original.workcode, // we need this for attaching an mp3 to the recording amendment record

    }));
    // console.log('otherRecordingsWithIds', otherRecordingsWithIds);
    allRecordings = [...allRecordings, ...otherRecordingsWithIds];
  }

  if (!song) {
    return (
      <tr>
        <td colSpan="5">
          <div className="p-4">
            <LoadingSmall />
          </div>
        </td>
      </tr>
    );
  }
  return (
    <>
      <Modal openPortal={recordingModalIsOpen} closePortal={setRecordingModalIsOpen}>
        <RecordingForm aac={currentAac} id={selectedRecordingId} modal closeForm={setRecordingModalIsOpen}/>
      </Modal>

      <Modal openPortal={songEditModalIsOpen} closePortal={setSongEditModalIsOpen}>
        <SongsCreateUpdate aac={currentAac} id={selectedSongEditId} modal closeForm={setSongEditModalIsOpen} />
      </Modal>

      <tr>
        <td colSpan="5">
          <div
            className={`rounded-lg ${isTunecore ? 'bg-tunecore-black text-white' : 'bg-sentric-rebrand-light-grey text-old-song-sub-color'} lg:p-6 p-4 mb-2 mt-2 lg:w-screen-width-minus-gutter w-full`}
          >
            <div className="flex flex-wrap">
              <div className="flex items-center w-full lg:w-1/2">
              <h2 className={`${isTunecore ? 'text-white' : 'text-black'} font-bold`}>
                  {song.title}
                </h2>
                {!!song?.alternativetitles.length && (
                  <div>
                    <div
                      data-tip={song.alternativetitles
                        .map((s) => s.title)
                        .join('<br />')}
                      className={`ml-2 mr-2 inline-block h-5 text-xxs py-0 px-1 rounded bg-opacity-25 ${isTunecore ? 'bg-white text-white' : 'bg-old-song-sub-color text-old-song-sub-color'}`}
                    >
                      {song.alternativetitles.length}
                      {' '}
                      {t('songs:alternative-titles-count')}
                    </div>
                    <ReactTooltip effect="solid" multiline {...styles} />
                  </div>
                )}
              </div>
              <div className="flex justify-end w-full lg:w-1/2">
                  <RebrandButtonLink
                    onClick={() => {
                      if (adminRights) {
                        setSelectedSongEditId(song.workcode)
                        setSongEditModalIsOpen(true);
                      }
                    }}
                    id="btnSongDetailEditSong"
                  >
                    <FaEdit className="inline-block mr-2" />
                    {t('songs:edit-song-link')}
                  </RebrandButtonLink>
              </div>
            </div>
            {song.iswc && (
              <p className="text-xs">
                <span className="font-semi-bold">ISWC:</span>
                {song.iswc}
              </p>
            )}
            <Spacer thin />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/2">
                <p
                  className={`uppercase text-xs ${isTunecore ? 'text-white' : 'font-medium text-sentric-rebrand-grey-text'}`}
                >
                  <span>
                    {t('songs:performed-by')}
                    :
                  </span>
                    {' '}
                  {song.performingartists
                    .map((p) => p.performingartistname.replace(/&#44;/g, ','))
                    .join(', ')}
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <p
                  className={`uppercase text-xs ${isTunecore ? 'text-white' : 'font-medium text-sentric-rebrand-grey-text'}`}
                >
                  <span>
                    {t('songs:duration')}
                    :
                  </span>
                    {' '}
                    {song.durationmins}
                    {' '}
                  {t('songs:minutes')}
                    {' '}
                    {song.durationsecs}
                    {' '}
                  {t('songs:seconds')}
                </p>
              </div>
            </div>
            <Spacer thin />
            <div>
              {song.containssamples && (
                <div className={`mr-1 inline-block text-xxs py-0 px-1 rounded uppercase bg-opacity-25 ${isTunecore ? 'bg-white text-white' : 'bg-old-song-sub-color text-old-song-sub-color'}`}>
                  {t('songs:contains-samples')}
                </div>
              )}
              {song.isremix && (
                <div className={`mr-1 inline-block text-xxs py-0 px-1 rounded uppercase bg-opacity-25 ${isTunecore ? 'bg-white text-white' : 'bg-old-song-sub-color text-old-song-sub-color'}`}>
                  {t('songs:is-remix')}
                </div>
              )}
            </div>
            <Spacer />
            <h2 className={`${isTunecore ? 'text-white' : 'text-black'}`}>
              {t('songs:writers')}
                {' '}
                &amp;
                {' '}
                {t('songs:splits')}
            </h2>
            <div className={`${isTunecore ? 'bg-tunecore-dark-element' : 'bg-white'} p-4 py-2 rounded-lg mt-2`}>
              {song.writersplits.map((w, i) => (
                <SongWriterSplit
                  aac={original.artistaccountcode || selectedAccount}
                  key={w.writercode}
                  writer={w}
                  last={i === song.writersplits.length - 1}
                />
              ))}
            </div>

            <Spacer />
            <hr />
            <Spacer thin />
            <div>
              <div className="flex items-center">
                <div className="flex items-center w-full">
                  <div className="flex items-center w-full lg:w-1/2">
                  <h2 className={`${isTunecore ? 'text-white' : 'text-black'} font-bold text-lg`}>
                      {t('songs:recordings-subheader')}
                    </h2>
                    <div className={`ml-2 mr-2 inline-block h-5 text-xxs py-0 px-1 rounded uppercase bg-opacity-25 ${isTunecore ? 'bg-white text-white' : 'bg-old-song-sub-color text-old-song-sub-color'}`}>
                      {allRecordings?.length || 0}
                    </div>
                  </div>
                    <div className="flex justify-end w-full lg:w-1/2">
                      <RebrandButtonLink
                        plusIcon
                        onClick={() => {
                          if (adminRights) {
                            setSelectedRecordingId(song.workcode)
                            setRecordingModalIsOpen(true);
                          }
                        }}
                        id="btnSongDetailEditRecording"
                      >
                        {t('songs:add-new-recording-link')}
                      </RebrandButtonLink>
                    </div>
                </div>
              </div>
              <Spacer thin />
              {!recordings.isSuccess && <LoadingSmall />}
              {recordings.isSuccess && allRecordings && (
                <div className={`${isTunecore ? 'bg-tunecore-dark-element' : 'bg-white'} p-4 py-2 rounded-lg mt-2`}>
                  {allRecordings.map((r, i) => (
                    <SongRecording
                      key={r.recordingidentifier || i}
                      recording={r}
                      last={i === song.recordings.length - 1}
                    />
                  ))}
                  {allRecordings.length === 0 && (
                    <p>{t('songs:no-recordings')}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default SongDetails;
