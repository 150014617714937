/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PortableText } from "@portabletext/react";
import HR from "../../../../components/hr";
import H2 from "../../../../components/h2";
import P from "../../../../components/p";
import Spacer from "../../../../components/spacer";
import LoadingSmall from "../../../../components/loadingSmall";
import AppState from "../../../../store/appstate";
import { getMyAccount } from "../../../../api/queries";
import { hasAdminRights } from "../../../../auth/shared";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useOpportunitiesQuery from "../../../../api/sanity/queries/getOpportunities";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";
import urlFor from "../../../../api/sanity/sanityImageUrl";

function Opportunities() {
	const history = useHistory();
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});
	const adminRights = hasAdminRights(myAccount?.data);
	const { t } = useTranslation("other");

	const { data: oppsData, isLoading, isSuccess } = useOpportunitiesQuery();
	const { data: siteConfig } = useSiteConfigAuth();
	const allOpportunitiesAvailable =
		AppState.getRawState()?.allOpportunitiesAvailable;
	let opps = [];
	if (allOpportunitiesAvailable) {
		opps = oppsData;
	} else {
		opps = oppsData?.opportunities_available;
	}

	const noOpps = siteConfig?.disable_opportunities;
	useEffect(() => {
		if (noOpps) {
			history.push("/");
		}
	}, [noOpps]);

	return (
		<>
			<div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
				<H2>{t("other:browse-opportunities-header")}</H2>
				<Spacer />
				<div className='w-full'>
					{isLoading && <LoadingSmall />}
					{isSuccess && opps && opps.length > 0 ? (
						opps.map((opp) => (
							<React.Fragment key={opp._id}>
								<div>
									<P className='mb-2 font-semibold'>{opp.name}</P>
									<div className='w-full flex flex-row justify-between gap-2'>
										<div className='w-full xl:w-3/4 html-content'>
											<PortableText value={opp.description} />
										</div>
										<img
											className='xl:w-1/4 hidden xl:flex h-72 object-cover rounded-lg'
											src={opp.imageUrl ? urlFor(opp.imageUrl).url() : null}
											alt={opp.altText}
										/>
									</div>
									<Spacer thin />
									<RebrandPrimaryButton
										id='btnOpportunitiesApply'
										onClick={
											adminRights
												? () =>
														opp.url
															? window.open(opp.url, "_blank")
															: history.push(`/opportunities/apply/${opp._id}`)
												: undefined
										}
										disabled={!adminRights}
										arrow
									>
										{t("other:apply")}
									</RebrandPrimaryButton>
								</div>
								<Spacer />
								<HR className='w-full lg:w-3/4' />
								<Spacer />
							</React.Fragment>
						))
					) : (
						<div>
							<p>{t("other:no-opportunities")}</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
export default Opportunities;
