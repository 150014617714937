/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { elasticQuery } from '../../../../api/mutations';
import LoadingSmall from '../../../../components/loadingSmall';
import Table from '../../../../components/table';
import { buildFilters } from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import SongReveal from './Songs/_songReveal';
import CumulativeGraph from './_cumulativeGraph';
import MapSelector from './_mapSelector';
import TableAndPie from './_tableAndPie';
import Tabs from './_tabs';
import { indexReducedPattern } from './constants';
import getColours from '../../../../helpers/getColours';

const TerritorySection = ({ matchAccounts, dateType }) => {
  const { t } = useTranslation('other');
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState(null);
  const [region, setRegion] = useState('Global');
  const [regionResult, setRegionResult] = useState(null);
  const [regionPending, setRegionPending] = useState(true);
  const flags = useFlags();
  const { highlightColour } = getColours();
  const range = AppState.useState((s) => s.range);

  let filter = [
    {
      range: { [dateType]: range[dateType] },
    },
    ...buildFilters(flags, matchAccounts),

  ];

  if (region !== 'Global') {
    filter = [
      {
        bool: {
          should: [
            {
              match_phrase: {
                territoryName: region,
              },
            },
          ],
          minimum_should_match: 1,
        },
      },
      {
        range: { [dateType]: range[dateType] },
      },
      ...buildFilters(flags, matchAccounts),

    ];
  }

  const query = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter: [
            {
              range: { [dateType]: range[dateType] },
            },
            ...buildFilters(flags, matchAccounts),

          ],
        },
      },
      aggs: {
        Territories: {
          terms: {
            field: 'territoryName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
      },
    },
  };

  const queryRegion = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter,
        },
      },
      aggs: {
        WriterPaymentSum: {
          sum: {
            field: 'ownerWriterPayment',
          },
        },
        Writers: {
          terms: {
            field: 'writerCode',
            size: 7,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            WriterName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: [
                    'writerLastName',
                    'writerFirstName',
                    'writerMiddleName',
                  ],
                },
              },
            },
          },
        },
        Territories: {
          terms: {
            field: 'territoryName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        CumulativeSumHistogram: {
          date_histogram: {
            field: dateType,
            calendar_interval: '1M',
            time_zone: 'Europe/London',
            min_doc_count: 0,
          },
          aggs: {
            CumulativeSumPipeline: {
              cumulative_sum: {
                buckets_path: 'CumulativeSum',
              },
            },
            CumulativeSum: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        IncomeTypes: {
          terms: {
            field: 'incomeType.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        Usage: {
          terms: {
            field: 'usage.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        Source: {
          terms: {
            field: 'sourceName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        Songs: {
          terms: {
            field: 'songCode',
            size: 500,
            order: {
              SongValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            SongValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            SongTitle: {
              terms: {
                field: 'songTitle.keyword',
                size: 1,
              },
            },
            WriterName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: [
                    'workWriterLastNames',
                  ],
                },
              },
            },
          },
        },
      },
    },
    size: 0,
  };

  const elasticQueryMutation = elasticQuery();
  const elasticQueryMutation2 = elasticQuery();

  useEffect(() => {
    setPending(true);

    elasticQueryMutation.mutate(query, {
      onSuccess: (data) => {
        setPending(false);
        setResult(data);
      },
      onError: (error) => {
        console.log(error);
        setPending(false);
        setResult(null);
      },
    });
  }, [range, dateType]);

  useEffect(() => {
    // setRegionPending(true);
    elasticQueryMutation2.mutate(queryRegion, {
      onSuccess: (data) => {
        setRegionPending(false);
        setRegionResult(data);
      },
      onError: (error) => {
        console.log(error);
        setRegionPending(false);
        setRegionResult(null);
      },
    });
  }, [range, region, dateType]);

  const columnsUsage = React.useMemo(
    () => [
      {
        Header: t('common:_name'),
        accessor: 'key', // accessor is the "key" in the data
      },
      {
        Header: t('common:_total'),
        accessor: (r) => `${currencySymbol}${Number(
          parseFloat(r?.WriterValues?.value).toFixed(2),
        ).toLocaleString()}`,
      },
    ],
    [],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: t('other:song-title'),
        accessor: (row) => `${row?.SongTitle?.buckets?.[0].key}`,
        width: '25%',
      },
      {
        Header: t('other:_writers'),
        accessor: (row) => `${row?.WriterName?.hits?.hits?.[0]._source?.workWriterLastNames?.replace(/,/g, ', ')}`,
        width: '25%',
      },
      {
        Header: t('other:earnings2'),
        accessor: (row) => `${currencySymbol}${Number(
          parseFloat(row?.SongValues?.value).toFixed(2),
        ).toLocaleString()}`,
        width: '20%',
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        width: '5%',

        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span>{row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}</span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
    ],
    [],
  );

  if (!pending && result) {
    return (
      <>
        <MapSelector
          setRegion={setRegion}
          songCode="territory"
          pending={pending}
          buckets={result?.body?.aggregations?.Territories?.buckets || []}
        />
        {!regionPending && regionResult && (
          <Tabs
            tabs={[
              t('other:songs-tab'),
              t('other:cumulative-earnings-tab'),
              t('other:income-type-earnings-tab'),
              t('other:usage-earnings-tab'),
              t('other:source-earnings-tab'),
            ]}
            objects={[
              <div className="mt-5">
                <Table
                  data={regionResult?.body?.aggregations?.Songs?.buckets || []}
                  columns={columns}
                  highlightColour={highlightColour}
                  revealComponent={<SongReveal dateType={dateType} noMap passRegion={region} matchAccounts={matchAccounts} />}
                  searchSuffix={t('common:writers')}
                  disableSearch
                  initialSort={[{
                    id: 'Earnings',
                    desc: true,
                  }]}
                />
              </div>,

              <CumulativeGraph
                unique={`${region}IncomeTypes`}
                pending={pending}
                buckets={
                  regionResult?.body?.aggregations?.CumulativeSumHistogram
                    ?.buckets || []
                }
              />,
              <TableAndPie
                unique={`${region}IncomeTypes`}
                pending={pending}
                buckets={regionResult?.body?.aggregations?.IncomeTypes?.buckets || []}
                columns={columnsUsage}
              />,
              <TableAndPie
                unique={`${region}Usage`}
                pending={pending}
                buckets={regionResult?.body?.aggregations?.Usage?.buckets || []}
                columns={columnsUsage}
              />,
              <TableAndPie
                unique={`${region}Source`}
                pending={pending}
                buckets={regionResult?.body?.aggregations?.Source?.buckets || []}
                columns={columnsUsage}
              />,
            ]}
          />
        )}
      </>
    );
  }
  return (
    <div className="p-4">
      <LoadingSmall />
    </div>
  );
};

export default TerritorySection;
