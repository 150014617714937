import { FaSpinner } from 'react-icons/fa';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';
import getColours from '../helpers/getColours';

function ButtonDashboard(props) {
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const { highlightColour } = getColours(siteConfig);
  const {
    children, onClick, disabled, pending, id,
  } = props;

  return (
    <>
        <>
          {!isTunecore ? (
            <button
              id={id}
              style={{ backgroundColor: highlightColour }}
              disabled={disabled}
              type="button"
              className="disabled:opacity-25 disabled:text-gray-400 disabled:pointer-events-none text-sentric-rebrand-dark-grey font-sentric-alt uppercase font-medium w-full text-sm x-8 py-4 rounded-lg shadow hover:shadow-lg disabled:shadow-none outline-none focus:outline-none mb-2 ease-linear transition-all duration-150 tracking-tight h-16 hover:scale-105"
              onClick={onClick}
            >
              {children}
              {pending && <FaSpinner className="ml-3 text-white inline animate-spin" />}
            </button>
          ) : (
            <button
              id={id}
              disabled={disabled}
              type="button"
              style={{
                background: `linear-gradient(to bottom right, #00ef86, #008eb9)`,
                borderRadius: '50px',
                padding: '12px 20px',
              }}
              className="border-none text-white font-sentric-alt uppercase font-medium w-full text-sm x-8 rounded-full h-16 tunecore-button-primary mb-2"
              onClick={onClick}
            >
              {children}
              {pending && <FaSpinner className="ml-3 text-white inline animate-spin" />}
            </button>
          )}
        </>
    </>
  );
}

export default ButtonDashboard;
