import { useQuery } from '@tanstack/react-query';
import client from '../client';
import AppState from '../../../store/appstate';

const getOpportunities = async () => {
  const catalogueCode = AppState.getRawState().selectedCatalogue;
  const domain = window.location.origin;

  const allOppsQuery = '*[_type == "opportunities"]{_type, _id, name, description, "altText": image.altText,"imageUrl": image.asset->url, url}';

  const availableOppsQuery = `*[_type == 'siteConfiguration' && ('${catalogueCode}' in catalogueConfig.catalogue_codes || '${domain}' in domainConfig.domain)][0]{'opportunities_available': syncsAndOpportunities.opportunitiesAvailable[]->{
    _type, _id, name, description, "altText": image.altText,"imageUrl": image.asset->url, url
    },
  }`

  try {
    const allOpportunitiesAvailable = AppState.getRawState()?.allOpportunitiesAvailable;
    
    const query = allOpportunitiesAvailable ? allOppsQuery : availableOppsQuery;
    const oppsData = await client.fetch(query);
    return oppsData;
  } catch (error) {
    console.error('Error fetching Opps data:', error);
    throw error;
  }
};

const useOpportunitiesQuery = () => useQuery(['opps'], getOpportunities, {
    onError: (error) => {
      console.error('Error in useOpportunitiesQuery:', error);
    },
    staleTime: 120000, // 2 minutes
  });

export default useOpportunitiesQuery;
