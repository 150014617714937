import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaRegEdit, FaLock } from 'react-icons/fa';
import {
  getBroadcasts, getMyAccount,
} from '../api/queries';
import { hasAdminRights } from '../auth/shared';
import AppState from '../store/appstate';
import LoadingSmall from './loadingSmall';
import Table from './table';
import getColours from '../helpers/getColours';

const BroadcastsTable = () => {
  const { t } = useTranslation('other');
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const history = useHistory();
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const broadcasts = getBroadcasts({
    ac: selectedAccount,
    oc: myAccount?.data?.originatorcode?.code,
  });
  const adminRights = hasAdminRights(myAccount?.data);
  const { highlightColour } = getColours();

  useEffect(() => {
    if (selectedAccount) {
      broadcasts.refetch();
    }
  }, [selectedAccount]);

  const columns = React.useMemo(() => [
    {
      Header: t('other:medium'),
      accessor: 'medium',
      width: '15%',
      id: 'colBroadcastMedium',
    },
    {
      Header: t('other:song'),
      accessor: 'song',
      width: '15%',
      id: 'colBroadcastSong',
      Cell: ({ row }) => (JSON.parse(row?.values?.colBroadcastSong).title),
    },
    {
      Header: t('other:aired'),
      accessor: 'date_aired',
      width: '15%',
      id: 'colBroadCastDate',
      Cell: ({ row }) => (row?.values?.colBroadCastDate
        && row?.values?.colBroadCastDate !== '0000-00-00'
        ? format(Date.parse(row.values.colBroadCastDate), 'yyyy-MM-dd')
        : 'unknown'),
    },
    {
      Header: t('other:station'),
      accessor: 'station',
      width: '15%',
      id: 'colBroadcastStation',
    },
    {
      Header: t('other:program'),
      accessor: 'program',
      width: '15%',
      id: 'colBroadcastProgram',
    },
    {
      accessor: '',
      width: '25%',
      id: 'colBroadcastStatus',
      disableSortBy: true,
      Cell: ({ row }) => (row?.original.legacy_import === null && adminRights ? (
        <FaRegEdit className="text-lg gig-claim-editable-pen-icon gig-claim-icon" />
      ) : (
        <FaLock className="text-lg text-old-sentric-submenu-icon gig-claim-icon" />
      )),
    },
    {
      // hidden column in Table component
      Header: '',
      accessor: 'id',
      width: null,
      id: 'colBroadcastId',
      Cell: '',
    },
  ]);

  const onRowClick = (row) => {
    // eslint-disable-next-line no-unused-expressions
    adminRights
      ? history.push(`/broadcasts/add/${row?.values?.colBroadcastId}`)
      : null;
  };

  return (
    <>
      <div className="w-full p-8 bg-white rounded-lg">
        {broadcasts.isLoading && <LoadingSmall />}
        {broadcasts.isSuccess && (
          <div>
            <Table
              area="broadcasts"
              data={broadcasts.data}
              columns={columns}
              min={5}
              highlightColour={highlightColour}
              revealComponent={null}
              onRowClick={onRowClick}
              searchSuffix={t('common:broadcasts')}
              bgClass="bg-white rounded-lg p-0"
            />
          </div>
        )}
        {broadcasts.isError && (
          <div>
            <p>Error getting recent broadcasts</p>
          </div>
        )}
      </div>
    </>
  );
};

export default BroadcastsTable;
