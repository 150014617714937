import { Store } from 'pullstate';

const to = new Date();

const AppState = new Store({
  authentication: {

  },
  kyc: null,
  hubspot: {

  },
  selectedAccount: null,
  allSyncsAvailable: null,
  allOpportunitiesAvailable: null,
  selectedCatalogue: null,
  tenantCurrencyCode: '',
  tenantCurrencySymbol: '*',
  tenantCurrencyLocale: '',
  serverError: null,
  onboarding: {
    howHear: null,
    role: null,
    accountName: null,
    address1: null,
    address2: null,
    town: null,
    county: null,
    postcode: null,
    country: null,
  },
  addAccount: {
    name: null,
    live: null,
    streaming: null,
    spotify: null,
    facebook: null,
    twitter: null,
  },
  spotifyAccountMethod: '',
  agreedTerms: false,
  songsTable: {
    page: 1,
    perPage: 10,
    sortBy: null,
    globalFilter: null,
  },
  range: {
    receiptDate: {
      format: 'strict_date_optional_time',
      gte: '2000-01-01T00:00:00.000Z',
      lte: to.toISOString(),
    },
    publishedStatementDate: {
      format: 'strict_date_optional_time',
      gte: '2000-01-01T00:00:00.000Z',
      lte: to.toISOString(),
    },
  },
  selectedDates: {
    fromDate: '2000-01-01T00:00:00.000Z',
    toDate: to.toISOString(),
  },
  dataRange: {
    from: null,
    to: null,
  },
  royaltiesFilter: [],
  navItemsEnabled: true,
  apiUrl: import.meta.env.VITE_API,
  hubspotDisabled: null,
  clientDisplayName: '',
});

try {
  const value = sessionStorage.getItem('@userData');
  if (value !== null) {
    const obj = JSON.parse(value);

    // migrations
    // if (!obj.songsTable) {
    obj.songsTable = {
      page: 1,
      perPage: 10,
      sortBy: [],
      globalFilter: null,
    };

    if (!obj.songsTable.globalFilter) {
      obj.songsTable.globalFilter = null;
    }

    if (!obj.songsTable.sortBy) {
      obj.songsTable.sortBy = [];
    }

    if (!obj.range) {
      obj.range = {
        receiptDate: {
          format: 'strict_date_optional_time',
          gte: '2000-01-01T00:00:00.000Z',
          lte: to.toISOString(),
        },
        publishedStatementDate: {
          format: 'strict_date_optional_time',
          gte: '2000-01-01T00:00:00.000Z',
          lte: to.toISOString(),
        },
      };
    }

    obj.range = {
      receiptDate: {
        format: 'strict_date_optional_time',
        gte: '2000-01-01T00:00:00.000Z',
        lte: to.toISOString(),
      },
      publishedStatementDate: {
        format: 'strict_date_optional_time',
        gte: '2000-01-01T00:00:00.000Z',
        lte: to.toISOString(),
      },
    };

    if (!obj.selectedDates) {
      obj.selectedDates = {
        fromDate: '2000-01-01',
        toDate: to.toISOString().split('T', 1)[0],
      };
    }

    if (!obj.dataRange) {
      obj.dataRange = {
        from: null,
        to: null,
      };
    }
    if (!obj.royaltiesFilter) {
      obj.royaltiesFilter = [];
    }

    AppState.replace(obj);
  }
} catch (e) {
  console.log('error reading stored value');
}

AppState.subscribe(
  (s) => s,
  (state) => {
    sessionStorage.setItem('@userData', JSON.stringify(state));
  },
);

export default AppState;
