/* eslint-disable object-shorthand */
import { createClient } from '@sanity/client';

const dataset = import.meta.env.VITE_STAGE === 'prd' ? 'production' : 'development';

const client = createClient({
  projectId: '7b8rh73g',
  dataset: dataset,
  useCdn: false, // set to `false` to bypass the edge cache
  apiVersion: '2023-10-16', // use current date (YYYY-MM-DD) to target the latest API version
});

export default client;
