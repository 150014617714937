/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import { FaInfoCircle } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { strToBool } from "../../../../helpers/helpers";
import Select from "../../../../components/select";
import { getMyWriters } from "../../../../api/queries";
import AppState from "../../../../store/appstate";
import RebrandButtonLink from "../../../../components/rebrandButtonLink";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import Spacer from "../../../../components/spacer";
import SongWriterSplitCreateRow from "./SongWriterSplitCreateRow";
import getColours from "../../../../helpers/getColours";
// import WritersForm from '../writers/writersForm';
import P from "../../../../components/p";
import LoadingSmall from "../../../../components/loadingSmall";
// import SubModal from '../../../../components/SubModal';

function SongWriterSplitCreate({
	onWorkSplitFieldUpdate,
	onWriterModalOpen,
	errors,
	existingSplits,
	updateWorkEtag,
}) {
	const [splits, setSplits] = useState([]);
	const [selectedWriter, setSelectedWriter] = useState("");
	const { t } = useTranslation("songs");
	const [selectKey, setSelectKey] = useState(0); // New state to force re-render
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const writers = getMyWriters({ aac: selectedAccount });
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour2 } = getColours();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	useEffect(() => {
		setSplits(existingSplits);
	}, [existingSplits]);

	const handleWriterSelect = (selected) => {
		if (selected) {
			setSplits((prevSplits) => [
				...prevSplits,
				{
					writercode: selected,
					righttocollect: "false",
				},
			]);
			setSelectedWriter("");
			setSelectKey((prevKey) => prevKey + 1); // using a key forces the select to re-render as it wasn't clearing the selected value properly without it - matt
		}
	};

	const handleDeleteSongWriter = (writercode) => {
		let splitArray = splits.slice();
		splitArray = splitArray.filter((s) => s.writercode !== writercode);
		onWorkSplitFieldUpdate(splitArray);
	};

	const handlesetWriterSplitField = ({ writercode, field, x }) => {
		const splitArray = splits.slice();
		const item = splitArray.find((w) => w.writercode === writercode);
		if (field === "righttocollect") {
			item.righttocollect = strToBool(item.righttocollect) ? "false" : "true";
		} else {
			item[field] = x;
		}
		onWorkSplitFieldUpdate(splitArray);
	};

	const shareSplitsEqually = () => {
		const numberWriters = splits.length;
		const writerArray = splits.slice();
		if (numberWriters > 0) {
			const share = (100 / numberWriters).toFixed(2);
			writerArray.forEach((s, i) => {
				s.writershare = share;
				// set last writer slightly higher incase odd number
				if (i === numberWriters - 1) {
					s.writershare = (100 - i * share).toFixed(2);
				}
			});
			onWorkSplitFieldUpdate(writerArray);
		}
	};

	let writerSplitsPercentage = 0;
	splits?.forEach((s) => {
		if (s.writershare && !s.isdeleted) {
			writerSplitsPercentage += Number(s.writershare);
		}
	});
	writerSplitsPercentage = writerSplitsPercentage.toFixed(2);

	return (
		<>
			<div className='w-full'>
				<h2 className='text-xl'>
					{t("songs:writers")} &amp; {t("songs:splits")}
				</h2>
				<Spacer thin />

				<div
					style={{ backgroundColor: isTunecore ? "#222222" : highlightColour2 }}
					className={`${
						isTunecore
							? "px-14 py-9 text-white h-auto md:h-28 flex-wrap md:flex-nowrap"
							: "px-14 py-9 text-black h-auto md:h-28 flex-wrap md:flex-nowrap"
					} rounded-t-lg flex gap-5 md:gap-0`}
				>
					<div className='md:w-1/2 w-full flex flex-col justify-evenly'>
						<div>
							<p className='text-lg'>
								{t("songs:writer-splits-assigned")}:
								<span className='ml-2 font-semibold'>
									{writerSplitsPercentage}%
								</span>
							</p>
						</div>
						<div className='relative w-full pt-1'>
							<div className='flex h-2 overflow-hidden text-xs rounded bg-old-sentric-menu-gray'>
								<div
									style={{ width: `${writerSplitsPercentage}%` }}
									className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
										isTunecore
											? "bg-tunecore-green"
											: "bg-old-sentric-submenu-icon"
									}`}
								/>
							</div>
						</div>
					</div>
					<div className='flex justify-center w-full md:w-1/2 md:justify-end lg:mb-0'>
						<RebrandPrimaryButton
							onClick={() => shareSplitsEqually()}
							id='btnSongFormShareWriterSplit'
							underlined={!isTunecore}
						>
							{t("songs:share-splits-equally")}
						</RebrandPrimaryButton>
					</div>
				</div>
				<div className={`${isTunecore ? "" : "bg-white"} rounded-lg`}>
					{splits &&
						splits.map((w, i) => (
							<SongWriterSplitCreateRow
								key={w.writercode}
								writer={w}
								deleteSongWriter={handleDeleteSongWriter}
								i={i}
								last={i === splits.length - 1}
								setWriterSplitField={handlesetWriterSplitField}
								updateWorkEtag={updateWorkEtag}
							/>
						))}

					<div className={`p-6 ${isTunecore ? "bg-tunecore-mid-grey" : ""}`}>
						<div className='flex flex-wrap gap-4 items-center w-full'>
							<div className='w-full flex flex-col gap-4 mb-2 lg:w-4/12 lg:mb-0'>
								{writers.isLoading && (
									<p>
										<LoadingSmall />
									</p>
								)}
								{writers.isSuccess && (
									<Select
										key={selectKey}
										showLabel
										search
										id='btnSongFormSelectWriter'
										options={writers?.data?.writers
											.filter(
												(w) =>
													!splits
														.filter((sw) => !sw.isdeleted)
														.map((s) => s.writercode)
														.includes(w.writercode)
											)
											.map((w) => ({
												name: `${w.firstname} ${
													!w.middlename || w.middlename === "null"
														? ""
														: w.middlename
												} ${w.lastname} ${
													!w.caeipinumber || w.caeipinumber === "null"
														? ""
														: w.caeipinumber
												}`,
												value: w.writercode,
											}))}
										onChange={handleWriterSelect}
										value={selectedWriter || ""}
										className={`${
											isTunecore
												? "tunecore-select"
												: "font-sentric-alt text-base select-search select-grey-bg"
										} text-black font-semibold`}
										filterOptions
										customLabel={t("songs:select-writer-placeholder")}
									/>
								)}
								{writers.isError && (
									<p className='text-red-700'>
										{writers.error.message}
									</p>
								)}
							</div>

							<div className='flex items-center justify-end flex-1 mt-4 text-xs md:mt-0'>
								<p className='mr-2'>{t("songs:writer-not-listed")}</p>
								<RebrandButtonLink
									smallfont
									plusIcon
									id='btnSongFormCreateWriter'
									onClick={() => onWriterModalOpen(true)}
								>
									{t("songs:create-new-writer")}
								</RebrandButtonLink>
							</div>
						</div>
					</div>
				</div>
				<Spacer thin />
				{!!errors.writers && (
					<p
						className={`w-4/5 ${
							isTunecore
								? "bg-tunecore-error-red bg-opacity-20 border-none"
								: "font-sentric-alt bg-red-700 border-white border"
						} text-sm inline-block p-1 pr-3 pl-3 mt-2 text-white rounded-lg mb-4 `}
					>
						{isTunecore && (
							<span>
								<RiErrorWarningFill className='inline-block mr-2 text-2xl text-tunecore-error-red' />
							</span>
						)}
						{errors.writers}
					</p>
				)}
				<P small>
					<FaInfoCircle className='inline mr-2' />{" "}
					{t("songs:when-adding-writers-percentage")}
				</P>
			</div>
		</>
	);
}

export default SongWriterSplitCreate;
