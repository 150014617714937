/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getMyAccounts, useTopWriters } from "../api/queries";
import ChangeAccountModal from "./ChangeAccountModal";
import ChooseAccountModal from "./ChooseAccountModal";
import LoadingSmall from "./loadingSmall";
import Table from "./table";
import RebrandButtonLink from "./rebrandButtonLink";
import getColours from "../helpers/getColours";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";

const TopWriters = () => {
	const { t } = useTranslation("dashboard");
	const flags = useFlags();
	const disableTopGigs = flags["pd-disable-top-gigs"];
	const myAccounts = getMyAccounts();
	const topWriters = useTopWriters({
		accounts: myAccounts?.data?.artistaccounts,
	});
	const { highlightColour } = getColours();
	const [switchModal, setSwitchModal] = useState(null);
	const [accountChoiceModal, setAccountChoiceModal] = useState(false);
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const columns = React.useMemo(() => [
		{
			Header: t("dashboard:ra-column-name"),
			accessor: (row) =>
				`${row?.firstname} ${row?.middlename ? row?.middlename : ""} ${
					row?.lastname
				}`,
			width: "25%",
			id: "colTopWriterName",
		},
		{
			Header: t("dashboard:ra-column-artist-account"),
			accessor: "accountname",
			width: "25%",
			id: "colTopWriterAccount",
		},
		{
			Header: "",
			accessor: "expander",
			width: "5%",
			Cell: () => (
				<>
					<div className='float-right'>
						<FaChevronRight />
					</div>
				</>
			),
		},
	]);

	const onRowClick = (row) => {
		const song = row.original;
		// switch account, and go to writers
		setSwitchModal({
			artistAccountCode: song.artistaccountcode,
			catalogueCode: song.CatalogueCode,
			name: song.accountname,
		});
	};

	return (
		<>
			{!!switchModal && (
				<ChangeAccountModal
					accounts={myAccounts?.data?.artistaccounts}
					to='/writers'
					setSwitchModal={setSwitchModal}
					account={switchModal}
				/>
			)}
			{!!accountChoiceModal && (
				<ChooseAccountModal
					title={t("dashboard:choose-account-writer-modal-header")}
					to='/writers/create'
					setAccountChoiceModal={setAccountChoiceModal}
					accounts={myAccounts?.data?.artistaccounts}
					nonAdminTo='/writers/'
				/>
			)}
			<div
				className={`${
					isTunecore
						? "bg-tunecore-dark-element text-white w-full"
						: "bg-white text-black w-full lg:w-1/2"
				} ${disableTopGigs ? "lg:grow" : ""} p-8 rounded-lg`}
			>
				<div className='flex flex-row justify-between items-center mb-5'>
					<h2 className='font-medium text-xl'>
						{t("dashboard:recently-added-writers-header")}
					</h2>
					<RebrandButtonLink
						plusIcon
						onClick={() => {
							setAccountChoiceModal(true);
						}}
						id='btnTopWriterAddWriter'
					>
						{t("dashboard:add-new-writer-link")}
					</RebrandButtonLink>
				</div>
				{topWriters.isLoading && <LoadingSmall />}
				{topWriters.isSuccess && (
					<div>
						<Table
							area='TopWriters'
							data={topWriters.data}
							columns={columns}
							min={5}
							highlightColour={highlightColour}
							revealComponent={null}
							onRowClick={onRowClick}
							searchSuffix={t("dashboard:writers-search-suffix")}
							disableSearch
							bgClass={`${
								isTunecore ? "bg-tunecore-dark-element" : "bg-white"
							} rounded-lg p-0`}
							initialSort={[
								{
									id: "verifieddate",
									desc: true,
								},
							]}
						/>
					</div>
				)}
				{topWriters.isError && (
					<div>
						<p>{t("dashboard:recent-writers-error")}</p>
					</div>
				)}
			</div>
		</>
	);
};

export default TopWriters;
