/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronRight, FaChevronUp } from 'react-icons/fa';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { elasticQuery } from '../../../../../api/mutations';
import LoadingSmall from '../../../../../components/loadingSmall';
import Spacer from '../../../../../components/spacer';
import AppState from '../../../../../store/appstate';
import MapSelector from '../_mapSelector';
import Table from '../../../../../components/table';
import {
  buildFilters,
  twoDp,
} from '../../../../../helpers/helpers';
import Tabs from '../_tabs';
import CumulativeGraph from '../_cumulativeGraph';
import TableAndPie from '../_tableAndPie';
import { indexReducedPattern } from '../constants';
import getColours from '../../../../../helpers/getColours';

const UsageReveal = ({ row, dateType, matchAccounts }) => {
  const { t } = useTranslation('other');
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState(null);
  const [region, setRegion] = useState('Global');
  const [regionPending, setRegionPending] = useState(true);
  const [regionResult, setRegionResult] = useState(null);
  const flags = useFlags();
  const range = AppState.useState((s) => s.range);
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const usage = row?.original?.key;
  const { highlightColour } = getColours();

  let filter = [
    {
      bool: {
        should: [
          {
            match_phrase: {
              usage,
            },
          },
        ],
        minimum_should_match: 1,
      },
    },
    {
      range: { [dateType]: range[dateType] },
    },
    ...buildFilters(flags, matchAccounts),
  ];

  if (region !== 'Global') {
    filter = [
      {
        bool: {
          should: [
            {
              match_phrase: {
                usage,
              },
            },
            {
              match_phrase: {
                territoryName: region,
              },
            },
          ],
          minimum_should_match: 2,
        },
      },
      {
        range: { [dateType]: range[dateType] },
      },
      ...buildFilters(flags, matchAccounts),
    ];
  }

  const query = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter,
        },
      },
      aggs: {
        Usage: {
          terms: {
            field: 'usage.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        CumulativeSumHistogram: {
          date_histogram: {
            field: dateType,
            calendar_interval: '1M',
            time_zone: 'Europe/London',
            min_doc_count: 0,
          },
          aggs: {
            CumulativeSumPipeline: {
              cumulative_sum: {
                buckets_path: 'CumulativeSum',
              },
            },
            CumulativeSum: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        IncomeTypes: {
          terms: {
            field: 'incomeType.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        Source: {
          terms: {
            field: 'sourceName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        Songs: {
          terms: {
            field: 'songCode',
            size: 500,
            order: {
              SongValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            SongValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            SongTitle: {
              terms: {
                field: 'songTitle.keyword',
                size: 1,
              },
            },
            WriterName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: [
                    'workWriterLastNames',
                  ],
                },
              },
            },
          },
        },
      },
    },
    size: 0,
  };

  const mapQuery = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter: [
            {
              bool: {
                should: [
                  {
                    match_phrase: {
                      usage,
                    },
                  },
                ],
                minimum_should_match: 1,
              },
            },
            {
              range: { [dateType]: range[dateType] },
            },
            ...buildFilters(flags, matchAccounts),
          ],
        },
      },
      aggs: {
        Territories: {
          terms: {
            field: 'territoryName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
      },
    },
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('common:_name'),
        accessor: 'key', // accessor is the "key" in the data
      },
      {
        Header: t('common:_percentage'),
        accessor: (r) => `${Number(parseFloat(r.percent)).toFixed(2)}%`,
      },
      {
        Header: t('common:_total'),
        accessor: (r) => r.WriterValues.value,
        Cell: ({ row: { original } }) => (
          <span>{`${currencySymbol}${twoDp(original?.WriterValues?.value)}`}</span>
        ),
      },
    ],
    [],
  );

  const elasticQueryMutation = elasticQuery();
  const elasticQueryMutatio2 = elasticQuery();

  useEffect(() => {
    if (usage) {
      setPending(true);

      elasticQueryMutatio2.mutate(mapQuery, {
        onSuccess: (data) => {
          setPending(false);
          setResult(data);
        },
        onError: (error) => {
          setPending(false);
          setResult(null);
        },
      });
    }
  }, [range, usage]);

  useEffect(() => {
    if (usage) {
      // setRegionPending(true);

      elasticQueryMutation.mutate(query, {
        onSuccess: (data) => {
          const newData = data;
          let j = 0;
          let total = 0;
          const items = newData?.body?.aggregations?.Usage?.buckets || [];
          while (j < items.length) {
            const item = items[j];
            total += parseFloat(item.WriterValues.value);
            j++;
          }

          j = 0;
          while (j < items.length) {
            const item = items[j];
            item.total = total;
            item.percent = (parseFloat(item.WriterValues.value) / total) * 100;
            j++;
          }

          setRegionPending(false);
          setRegionResult(newData);
        },
        onError: (error) => {
          console.log(error);
          setRegionPending(false);
          setRegionResult(null);
        },
      });
    }
  }, [range, usage, region]);

  const columnsUsage = React.useMemo(
    () => [
      {
        Header: t('common:_name'),
        accessor: 'key', // accessor is the "key" in the data
      },
      {
        Header: t('common:_total'),
        accessor: (r) => r.WriterValues.value,
        Cell: ({ row: { original } }) => (
          <span>{`${currencySymbol}${twoDp(original?.WriterValues?.value)}`}</span>
        ),
      },
    ],
    [],
  );

  const columnsSongs = React.useMemo(
    () => [
      {
        Header: t('other:song-title'),
        accessor: (row) => `${row?.SongTitle?.buckets?.[0].key}`,
        width: '25%',
      },
      {
        Header: t('other:_writers'),
        accessor: (row) => `${row?.WriterName?.hits?.hits?.[0]._source?.workWriterLastNames.replace(/,/g, ', ')}`,
        width: '25%',
      },
      {
        Header: t('other:earnings2'),
        accessor: (row) => `${currencySymbol}${Number(
          parseFloat(row?.SongValues?.value).toFixed(2),
        ).toLocaleString()}`,
        width: '20%',
      },
    ],
    [],
  );

  if (!pending && result) {
    return (
      <td colSpan={10}>
        <div className="rounded-lg lg:p-4 mt-2 mb-2">
          <MapSelector
            setRegion={setRegion}
            songCode={`${usage}incomeReveal`}
            pending={pending}
            buckets={result?.body?.aggregations?.Territories?.buckets || []}
          />
          <Spacer />
          {!regionPending && regionResult && (
            <div>
              <Spacer />

              <Tabs
                tabs={[
                  t('other:cumulative-earnings-tab'),
                  t('other:songs-tab'),
                  t('other:income-earnings-tab'),
                  t('other:source-earnings-tab'),
                ]}
                objects={[
                  <CumulativeGraph
                    unique={`${usage}Cum`}
                    pending={pending}
                    buckets={
                      regionResult?.body?.aggregations?.CumulativeSumHistogram
                        ?.buckets || []
                    }
                  />,
                  <div className="mt-5">
                    <Table
                      data={regionResult?.body?.aggregations?.Songs?.buckets || []}
                      columns={columnsSongs}
                      highlightColour={highlightColour}
                      searchSuffix={t('common:writers')}
                      disableSearch
                      initialSort={[
                        {
                          id: 'Earnings',
                          desc: true,
                        },
                      ]}
                    />
                  </div>,
                  <TableAndPie
                    unique={`${usage}IncomeType`}
                    pending={pending}
                    buckets={regionResult?.body?.aggregations?.IncomeTypes?.buckets || []}
                    columns={columnsUsage}
                  />,
                  <TableAndPie
                    unique={`${usage}Source`}
                    pending={pending}
                    buckets={regionResult?.body?.aggregations?.Source?.buckets || []}
                    columns={columnsUsage}
                  />,
                ]}
              />
            </div>
          )}

          {regionPending && <LoadingSmall />}
          <Spacer />
        </div>
      </td>
    );
  }

  return (
    <div className="p-4">
      <LoadingSmall />
    </div>
  );
};

export default UsageReveal;
