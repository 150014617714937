/* eslint-disable no-useless-catch */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import Input from '../../components/input';
import P from '../../components/p';
import Spacer from '../../components/spacer';
import { signupConfirm } from '../../api/mutations';
import FormError from '../../components/formError';
import AppState from '../../store/appstate';
import AuthPrimaryButton from '../../components/authPrimaryButton';

function SignUpConfirm() {
  const { t } = useTranslation();
  const [warnings, setWarnings] = useState({});
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);
  const [pending, setPending] = useState(false);

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const history = useHistory();
  const location = useLocation();
  const usernameRegex = new RegExp('^(?=[a-zA-Z0-9._-]{6,24}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$');
  const passwordRegex = new RegExp('^(?=.{8,100})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!£*]).*$');
  const signUpConfirmMutation = signupConfirm();

  const links = (parse(location.search.slice(1)));

  const confirm = async () => {
    setValid(false);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
    setPending(true);
    const data = {
      linktoken: links.lt,
      linkcontext: links.lc,
      username,
      password,
    };
    signUpConfirmMutation.reset();
    signUpConfirmMutation.mutate(data, {
      onSuccess: (response) => {
        const message = response.messages[0];
        if (message !== 'Success.') {
          setValid(true);
          setValidationErrorMessage(response.messages);
          setPending(false);
        } else {
          setPending(false);
          const details = {
            renewal_token: btoa(password),
            userName: username,
            access_token: '123',
          };
          AppState.update((s) => { s.authentication = details; });
          history.push('/');
        }
      },
      onError: (error) => {
        setValid(true);
        setMutationErrorMessage(error.message);
        setPending(false);
      },
    });
  };

  const checkValid = (passed = {}) => {
    let val = true;

    // firstname check
    const name = passed.username || username;
    const validUsername = name.trim().length > 5;
    if (!validUsername) {
      val = false;
    }

    // password check
    const pass = passed.password || password;
    const validPassword = pass.length > 6 && pass.length < 100;

    if (!validPassword) {
      val = false;
    }

    // pass regex check
    if (!passwordRegex.test(pass)) {
      val = false;
    }

    setValid(val);
  };

  const checkErrors = () => {
    const errs = {};

    // first name check
    if (username.trim().length > 0) {
      const validUsername = username.trim().length > 5;
      if (!validUsername) {
        errs.username = t('auth:longer-username-error');
      }
    }

    // last name check
    if (password.trim().length > 6) {
      let validPassword = password.trim().length > 6;
      validPassword = password.trim().length < 100;
      if (!validPassword) {
        errs.password = t('regex:password-regex-error2');
      }
    }

    // user regex check
    if (!usernameRegex.test(username)) {
      errs.username = t('regex:username-regex');
    }

    // pass regex check
    if (!passwordRegex.test(password)) {
      errs.password = t('regex:password-regex-error');
    }
    setWarnings(errs);
  };

  return (
    <div>
      <div className="container mx-auto">
          <div className="flex flex-col items-center justify-center p-4 text-center min-h-screen-minus-footer">
            <h1 className="text-6xl">
              {t('auth:account-setup-header')}
            </h1>
            <Spacer />
            <P>{t('auth:account-setup-text')}</P>
            <div className="w-full lg:w-1/3 text-old-sentric-dark-gray">
              <Spacer />
              <Input
                error={warnings.username}
                className='auth-page-input'
                placeholder={t('auth:username-placeholder')}
                value={username}
                onBlur={() => checkErrors()}
                onChange={(e) => { setUsername(e.target.value); checkValid({ username: e.target.value }); }}
              />
              <Spacer />
              <Input
                className='auth-page-input'
                error={warnings.password}
                type="password"
                placeholder={t('auth:password-placeholder')}
                onBlur={() => checkErrors()}
                value={password}
                onChange={(e) => { setPassword(e.target.value); checkValid({ password: e.target.value }); }}
              />
              <Spacer />
              <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
              <AuthPrimaryButton
                id="btnLogin"
                disabled={!valid}
                pending={pending}
                onClick={confirm}
                >
                {t('common:next-button')}
              </AuthPrimaryButton>
            </div>
          </div>
      </div>
    </div>
  );
}

export default SignUpConfirm;
