/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import P from "../../../components/p";
import Spacer from "../../../components/spacer";
import AppState from "../../../store/appstate";
import { addArtistAccount, updateArtistAccountMeta } from "../../../api/mutations";
import FormError from "../../../components/formError";
import { getMyAccounts, getKycStatus } from "../../../api/queries";
import { apiCall } from "../../../api/shared";
import AuthPrimaryButton from "../../../components/authPrimaryButton";

function OnboardingWelcome() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();
	const accountData = AppState.useState((s) => s.addAccount);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
	const [pending, setPending] = useState(false);
	const myAccounts = getMyAccounts();
	const addMutation = addArtistAccount();
	const accountMetaMutation = updateArtistAccountMeta();

	const updateAccountMeta = async (aac) => {
		// update account meta
		const data = {
			account_id: aac,
			facebook_obj: accountData?.facebook,
			spotify_obj: accountData?.spotify,
			songkick_obj: accountData?.songkick,
			twitter_obj: accountData?.twitter,
			bandsintown_obj: accountData?.bandsintown,
			instagram_obj: accountData?.instagram,
		};

		accountMetaMutation.reset();

		accountMetaMutation.mutate(data, {
			onSuccess: async (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
					await myAccounts.refetch();
					const kyc = await apiCall({
						endpoint: "/rightsapp/get",
						path: "/api/v1/user/getkyc",
					});
					setPending(false);
					AppState.update((s) => {
						s.addAccount = {
							name: null,
							live: null,
							streaming: null,
							spotify: null,
							twitter: null,
							facebook: null,
							songkick: null,
							bandsintown: null,
							instagram: null,
						};
						s.kyc = kyc;
					});
					if (kyc?.messages[0] === "KYC Required") {
						history.push("/security");
					} else {
						history.push("/");
					}
				}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};

	const addAccount = async () => {
		setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');

		// add artist account to rights app
		const data = {
			cataloguecode: "",
			accountname: accountData.accountName,
			facebookpageurl: accountData.facebook,
			spotifyid: accountData.spotify
				? JSON.parse(accountData.spotify).id
				: null,
			releasedtoserviceorstore: accountData.streaming === "true",
			performedlastsixmonths: accountData.live === "true",
			twitterprofileurl: accountData.twitter
				? `https://twitter.com/${accountData.twitter}`
				: null,
			soundcloudpageurl: null,
		};

		addMutation.reset();

		addMutation.mutate(data, {
			onSuccess: (result, response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
					const aac = result?.model?.artistaccountcode;
					updateAccountMeta(aac);
				}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};
	return (
		<div className='container mx-auto'>
			<div className='flex flex-col justify-center items-center text-white text-center my-44'>
				<Spacer />
				<P>{t("onboarding:completed-account-setup")}</P>
				<Spacer />
				<AuthPrimaryButton
					onClick={addAccount}
					pending={pending}
					arrow
					className='w-64'
				>
					{t("onboarding:go-to-dashboard")}
				</AuthPrimaryButton>
				
				<FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
			</div>
		</div>
	);
}

export default OnboardingWelcome;
