/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import { parseISO, formatISO } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { elasticQuery } from '../../../../api/mutations';
import { getMyAccounts, getMyProfile } from '../../../../api/queries';
import H2 from '../../../../components/h2';
import LoadingSmall from '../../../../components/loadingSmall';
import Spacer from '../../../../components/spacer';
import { buildFilters } from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import DateRangePicker from '../../../../components/DateRangePicker';
import CompontentTabs from './_componentTabs';
import CumulativeEarnings from './_cumulativeEarningsAndByPeriod';
import TabIncome from './_tabIncome';
import TabSongs from './_tabSongs';
import TabTerritories from './_tabTerritories';
import TabWriters from './_tabWriters';
import 'react-datepicker/dist/react-datepicker.css';
import SongsSection from './_songsSection';
import WritersSection from './_writersSection';
import IncomeSection from './_incomeSection';
import TerritorySection from './_territorySection';
import RoyaltiesFilter from './_filter';
import { indexReducedPattern } from './constants';
import getColours from '../../../../helpers/getColours';
import { processKycStatuses } from '../../../../helpers/kycStatus';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function Royalties() {
  const history = useHistory();
  const kyc = AppState.getRawState()?.kyc;
  const { showKyc, showKycPending } = processKycStatuses(kyc);
  const profile = getMyProfile();

  const { data: siteConfig } = useSiteConfigAuth();

  const hideRoyalties =
    (siteConfig?.disable_royalties) ||
    profile?.data?.hideroyalties ||
    showKyc || showKycPending;
  useEffect(() => {
    if(hideRoyalties) {
      history.push('/');
    }
  }, [hideRoyalties]);

  const flags = useFlags();
  const royaltiesMaintenanceMode = flags['royalties-maintenance-mode'];
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const [pending, setPending] = useState(true);
  const [result, setResult] = useState(null);
  const [pendingReduced, setPendingReduced] = useState(true);
  const [resultReduced, setResultReduced] = useState(null);
  const [period, setPeriod] = useState('ALL');
  const [isCustomDates, setIsCustomDates] = useState(false);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [hideDatePicker, setHideDatePicker] = useState(true);
  const range = AppState.useState((s) => s.range);
  const selectedDates = AppState.useState((s) => s.selectedDates);
  const dataRange = AppState.useState((s) => s.dataRange);
  const [graph, setGraph] = useState('line');
  const [dateRange, setDateRange] = useState([parseISO(selectedDates.fromDate), parseISO(selectedDates.toDate)]);

  const { highlightColour } = getColours();
  const highlight = isTunecore ? '#00ef86' : highlightColour;

  const filters = AppState.useState((s) => s.royaltiesFilter);

  const myAccounts = getMyAccounts();

  const matchAccountsFull = useMemo(() => myAccounts?.data.artistaccounts?.map((a) => (a.artistaccountcode)), []);
  const matchAccounts = matchAccountsFull.slice(0, 2000);

  // eslint-disable-next-line no-unused-vars
  const [dateType, setDateType] = useState('publishedStatementDate');

  const [buttonLoading, setButtonLoading] = useState(false);

  const dateFormat = (num) => formatISO(dateRange[num], {
    representation: 'date',
  }).split('T', 1)[0];

  const queryReduced = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter: [
            ...buildFilters(flags, matchAccounts),
          ],
        },
      },
      aggs: {
        MinDate: { min: { field: dateType } },
        MaxDate: { max: { field: dateType } },
        CumulativeSumHistogram: {
          date_histogram: {
            field: dateType,
            calendar_interval: '1M',
            time_zone: 'Europe/London',
            min_doc_count: 0,
          },
          aggs: {
            CumulativeSumPipeline: {
              cumulative_sum: {
                buckets_path: 'CumulativeSum',
              },
            },
            CumulativeSum: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
      },
      size: 0,
    },
  };

  const query = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter: [
            {
              range: { [dateType]: range[dateType] },
            },
            ...buildFilters(flags, matchAccounts),
          ],
        },
      },
      aggs: {
        SongCount: {
          cardinality: {
            field: 'songCode',
          },
        },
        Songs: {
          terms: {
            field: 'songCode',
            size: 500,
            order: {
              SongValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            SongValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            SongTitle: {
              terms: {
                field: 'songTitle.keyword',
                size: 1,
              },
            },
            ArtistAccountCode: {
              terms: {
                field: 'artistAccountCode',
                size: 1,
              },
            },
          },
        },
        WriterPaymentSum: {
          sum: {
            field: 'ownerWriterPayment',
          },
        },
        Writers: {
          terms: {
            field: 'writerCode',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            WriterName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: [
                    'writerLastName',
                    'writerFirstName',
                    'writerMiddleName',
                  ],
                },
              },
            },
          },
        },
        ArtistAccounts: {
          terms: {
            field: 'artistAccountCode',
            size: 1,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            ArtistAccountName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: ['artistAccountName'],
                },
              },
            },
          },
        },
        Territories: {
          terms: {
            field: 'territoryName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        IncomeTypes: {
          terms: {
            field: 'incomeType.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        UsageTypes: {
          terms: {
            field: 'usage.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        CumulativeSumHistogram: {
          date_histogram: {
            field: dateType,
            calendar_interval: '1M',
            time_zone: 'Europe/London',
            min_doc_count: 0,
          },
          aggs: {
            CumulativeSumPipeline: {
              cumulative_sum: {
                buckets_path: 'CumulativeSum',
              },
            },
            CumulativeSum: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
      },
      size: 0,
    },
  };

  const elasticQueryMutation = elasticQuery();
  const elasticQueryMutationReduced = elasticQuery();

  const getDateType = () => {
    const to = new Date(dataRange.to);
    const fromAll = new Date(dataRange.from);
    const from1Y = new Date(dataRange.to);
    from1Y.setMonth(from1Y.getMonth() - 12);
    const from6M = new Date(dataRange.to);
    from6M.setMonth(from6M.getMonth() - 6);
    const from3M = new Date(dataRange.to);
    from3M.setMonth(from3M.getMonth() - 3);
    const from1M = new Date(dataRange.to);
    from1M.setMonth(from1M.getMonth() - 1);

    if (range[dateType].lte !== to.toISOString().split('T', 1)[0]) {
      return 'CUSTOM';
    }

    if (range[dateType].gte === fromAll.toISOString().split('T', 1)[0]) {
      return 'ALL';
    }

    if (range[dateType].gte === from1Y.toISOString().split('T', 1)[0]) {
      return '1Y';
    }

    if (range[dateType].gte === from6M.toISOString().split('T', 1)[0]) {
      return '6M';
    }

    if (range[dateType].gte === from3M.toISOString().split('T', 1)[0]) {
      return '3M';
    }

    if (range[dateType].gte === from1M.toISOString().split('T', 1)[0]) {
      return '1M';
    }

    return 'CUSTOM';
  };

	const noRoyalties = siteConfig?.disable_royalties;
	useEffect(() => {
		if(noRoyalties) {
			history.push('/');
		}
	}, [noRoyalties])

  useEffect(() => {
    if (royaltiesMaintenanceMode) {
      return;
    }
    setIsCustomDates(
      setButtonLoading(true),
      selectedDates.fromDate !== range[dateType].gte
        || selectedDates.toDate !== range[dateType].lte,
    );
    elasticQueryMutation.mutate(query, {
      onSuccess: (data) => {
        setPending(false);
        setButtonLoading(false);
        setResult(data);
      },
      onError: (error) => {
        console.log(error);
        setPending(false);
        setResult(null);
        history.push('/');
        console.log('No accounts found');
      },
    });
  }, [range, dateType, filters, royaltiesMaintenanceMode]);

  useEffect(() => {
    if (royaltiesMaintenanceMode) {
      return;
    }
    if (isCustomDates) {
      setPeriod(null);
    }
  }, [isCustomDates, royaltiesMaintenanceMode]);

  useEffect(() => {
    if (royaltiesMaintenanceMode) {
      return;
    }
    setPendingReduced(true);

    elasticQueryMutationReduced.mutate(queryReduced, {
      onSuccess: (data) => {
        setPendingReduced(false);
        setResultReduced(data);
        if (!resultReduced) {
          const minDateTimestamp = data?.body?.aggregations?.MinDate?.value;
          const maxDateTimestamp = data?.body?.aggregations?.MaxDate?.value;

          let minDate = new Date(minDateTimestamp);
          let maxDate = new Date(maxDateTimestamp);

          // show date picker options if we have the data
          if (minDateTimestamp !== maxDateTimestamp) {
            setHideDatePicker(false);
          }

          minDate = minDate.toISOString().split('T', 1)[0];
          maxDate = maxDate.toISOString().split('T', 1)[0];

          AppState.update((s) => {
            s.dataRange = {
              from: minDate,
              to: maxDate,
            };
          });

          AppState.update((s) => {
            s.range = {
              publishedStatementDate: {
                format: 'strict_date_optional_time',
                gte: minDate,
                lte: maxDate,
              },
              receiptDate: {
                format: 'strict_date_optional_time',
                gte: minDate,
                lte: maxDate,
              },
            };
          });

          AppState.update((s) => {
            s.selectedDates = {
              fromDate: minDate,
              toDate: maxDate,
            };
          });
        }
      },
      onError: (error) => {
        console.log(error);
        setPendingReduced(false);
        setResultReduced(null);
      },
    });
  }, [dateType, filters]);

  // period change button e.g, 1M, 3M
  useEffect(() => {
    setButtonLoading(true);
    if (royaltiesMaintenanceMode) {
      return;
    }

    if (period === null) {
      return;
    }

    let from = new Date(dataRange.from);
    const to = new Date(dataRange.to);

    if (period === 'ALL') {
      AppState.update((s) => {
        s.selectedDates = {
          fromDate: from.toISOString().split('T', 1)[0],
          toDate: to.toISOString().split('T', 1)[0],
        };
      });
      return;
    }
    if (period === '1Y') {
      from = new Date(dataRange.to);
      from.setMonth(from.getMonth() - 12);
    }
    if (period === '6M') {
      from = new Date(dataRange.to);
      from.setMonth(from.getMonth() - 6);
    }
    if (period === '3M') {
      from = new Date(dataRange.to);
      from.setMonth(from.getMonth() - 3);
    }
    if (period === '1M') {
      from = new Date(dataRange.to);
      from.setMonth(from.getMonth() - 1);
    }

    // if new range is out of bounds, set max
    if (from < new Date(dataRange.from)) {
      from = new Date(dataRange.from);
    }

    AppState.update((s) => {
      s.selectedDates = {
        fromDate: from.toISOString().split('T', 1)[0],
        toDate: to.toISOString().split('T', 1)[0],
      };
    });
  }, [period]);

  // set period options once we have data
  useEffect(() => {
    if (royaltiesMaintenanceMode) {
      return;
    }
    if (dataRange.from && dataRange.to) {
      const options = ['ALL'];

      const to = new Date(dataRange.to);
      const from = new Date(dataRange.from);

      let months = to.getMonth()
        - from.getMonth()
        + 12 * (to.getFullYear() - from.getFullYear());

      if (to.getDate() < from.getDate()) {
        // eslint-disable-next-line no-plusplus
        months--;
      }

      if (months > 12) {
        options.push('1Y');
      }
      if (months > 6) {
        options.push('6M');
      }
      if (months > 3) {
        options.push('3M');
      }
      if (months > 1) {
        options.push('1M');
      }

      // reverse array

      options.reverse();

      setPeriodOptions(options);
    }
  }, [dataRange]);

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) return;

    const fromDate = dateFormat(0);
    const toDate = dateFormat(1);

    AppState.update((s) => {
      s.selectedDates = {
        fromDate,
        toDate,
      };
    });
    setButtonLoading(true);
  }, [dateRange]);

  const { t } = useTranslation('other');

  return (
    <>
      <div className="flex flex-wrap">
        <div className='w-full lg:min-h-screen-minus-header '>
          <div className="mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/2">
                <H2>{t('other:royalties-header')}</H2>
              </div>
            </div>
            {royaltiesMaintenanceMode
              ? (
                <div className="p-4">
                  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">{t('other:maintenance-mode1')}</strong>
                    <span className="block sm:inline"> </span>
                    <span className="block sm:inline">{t('other:maintenance-mode2')}</span>
                  </div>
                </div>
              )
              : (
                <div>
                  <Spacer />
                  <RoyaltiesFilter matchAccounts={matchAccountsFull} />
                  <div className={`${isTunecore ? 'bg-tunecore-dark-element' : ' bg-white'} rounded-lg p-4 lg:p-8`}>
                    {/* <div className="mt-4 mb-4">
                      Date type for testing:
                      {' '}
                      <button className="font-semibold text-xs border rounded-md px-4 h-9 ml-2 mr-2 focus:outline-none" type="button" onClick={() => { setDateType(dateType === 'receiptDate' ? 'publishedStatementDate' : 'receiptDate'); }}>{dateType}</button>
                    </div> */}
                    {!hideDatePicker
                    && (
                    <div className="flex flex-col xl:flex-row gap-5 items-center mb-4 justify-between space-y-1">
                      <div className="flex flex-col md:flex-row gap-2 items-center w-full justify-center sm:justify-start">
                        <div className="flex flex-col w-full gap-2">
                            <div className={`${isTunecore ? 'text-white' : 'text-old-sentric-text-gray'} mr-4 font-semibold text-xs uppercase`}>
                              {t('other:view-period-by')}
                            </div>

                          <div className="flex flex-col xs:flex-row gap-2 2xl:gap-10 items-center">
                            {periodOptions.map((p, i) => (
                              <button
                                id={`btnRoyaltyPeriod${p}`}
                                style={{
                                  backgroundColor: getDateType() === p ? highlight : "transparent",
                                  borderColor: getDateType() === p ? highlight : (isTunecore ? "#FFF" : "#000"),
                                  color: getDateType() === p ? (isTunecore ? "black" : "white") : (isTunecore ? "#FFF" : "#000")
                              }}
                              
                                className={`font-semibold text-xs ${isTunecore ? 'rounded-full tracking-wider border-2' : 'border rounded-md'} w-1/2 md:w-full focus:outline-none p-2 flex justify-center`}
                                onClick={() => setPeriod(p)}
                                type="button"
                                key={`period${i}`}
                              >
                                <>
                                  {buttonLoading ? (
                                    <LoadingSmall />
                                  ) : (
                                    <>
                                    {p}
                                    </>
                                  )}
                                </>
                              </button>
                            ))}
                            <button
                              id="btnRoyaltyPeriodCustom"
                              style={{
                                backgroundColor: getDateType() === 'CUSTOM' ? highlight : 'transparent',
                                borderColor: getDateType() === 'CUSTOM' ? highlight : (isTunecore ? '#FFF' : '#000'),
                                color: getDateType() === 'CUSTOM' ? (isTunecore ? 'black' : 'white') : (isTunecore ? '#FFF' : '#000')
                              }}                            
                              className={`font-semibold text-xs ${isTunecore ? 'rounded-full tracking-wider border-2' : 'border rounded-md'} w-1/2 md:w-full focus:outline-none p-2 flex justify-center`}
                              type="button"
                              key="periodcustom"
                            >
                            <>
                              {buttonLoading ? (
                                <LoadingSmall />
                              ) : (
                                <>
                                {t('other:custom-period')}
                                </>
                              )}
                            </>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={`${isTunecore ? 'tunecore-datepicker' : 'themed-datepicker'} flex flex-col w-full lg:w-1/2 gap-2`}>
                        <div className={`${isTunecore ? 'text-white' : 'text-old-sentric-text-gray'} mr-2 font-semibold text-xs uppercase`}>
                          {t('other:between-dates')}
                        </div>
                        <>
                          <DateRangePicker id="btnRoyaltyDatePicker" dateRange={dateRange} setDateRange={setDateRange} />
                        </>
                      </div>
                    </div>
                    )}
                    <div className="absolute right-4 top-4">
                      {pending && <LoadingSmall />}
                    </div>
                    {/* <Tabs
                      tabs={['Accumulative Earnings', 'Earnings by period']}
                      objects={[(<CumulativeEarnings range={range} pending={pendingReduced} data={resultReduced} />),
                        (<EarningsByPeriod range={range} pending={pendingReduced} data={resultReduced} />)]}
                    /> */}

                    <div className="border-b-2 border-gray-200 h-14 flex items-center">
                      <button
                        type="button"
                        onClick={() => setGraph('line')}
                        style={{
                          borderColor:
                            graph === 'line' ? highlight : 'transparent',
                        }}
                        className={`${isTunecore ? 'text-white' : 'text-old-sentric-text-gray '} px-3 uppercase text-xs font-semibold flex items-center border-b-4 h-14 -mb-1 focus:outline-none`}
                      >
                        {t('other:cumulative-earnings')}
                      </button>
                      <button
                        type="button"
                        onClick={() => setGraph('bar')}
                        style={{
                          borderColor:
                            graph === 'bar' ? highlight : 'transparent',
                        }}
                        className={`${isTunecore ? 'text-white' : 'text-old-sentric-text-gray '} px-3 uppercase text-xs font-semibold flex items-center border-b-4 h-14 -mb-1 focus:outline-none`}
                      >
                        {t('other:earnings-by-period')}
                      </button>
                    </div>
                    <div className="block">
                      <CumulativeEarnings
                        data={resultReduced}
                        range={range}
                        dateType={dateType}
                        pending={pendingReduced}
                        graph={graph}
                      />
                    </div>
                    <Spacer />

                    <CompontentTabs
                      tabs={[
                        <TabSongs pending={pending} result={result} />,
                        <TabWriters pending={pending} result={result} />,
                        <TabIncome pending={pending} result={result} />,
                        <TabTerritories pending={pending} result={result} />,
                      ]}
                      objects={[
                        <SongsSection
                          dateType={dateType}
                          matchAccounts={matchAccounts}
                        />,
                        <WritersSection
                          dateType={dateType}
                          matchAccounts={matchAccounts}
                        />,
                        <IncomeSection
                          dateType={dateType}
                          matchAccounts={matchAccounts}
                        />,
                        <TerritorySection
                          dateType={dateType}
                          matchAccounts={matchAccounts}
                        />,
                      ]}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Royalties;
