const currencies = {
  AED: {
      name: 'United Arab Emirates Dirham',
      demonym: 'UAE',
      majorSingle: 'Dirham',
      majorPlural: 'Dirhams',
      ISOnum: 784,
      symbol: 'د.إ.',
      symbolNative: 'د.إ.',
      minorSingle: 'Fils',
      minorPlural: 'Fils',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-AE'
  },
  AFN: {
      name: 'Afghan Afghani',
      demonym: 'Afghan',
      majorSingle: 'Afghani',
      majorPlural: 'Afghani',
      ISOnum: 971,
      symbol: 'Af',
      symbolNative: '؋',
      minorSingle: 'Pul',
      minorPlural: 'Pul',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'fa-AF'
  },
  ALL: {
      name: 'Albanian Lek',
      demonym: 'Albanian',
      majorSingle: 'Lek',
      majorPlural: 'Lekë',
      ISOnum: 8,
      symbol: 'L',
      symbolNative: 'L',
      minorSingle: 'Qindarka',
      minorPlural: 'Qindarka',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'sq-AL'
  },
  AMD: {
      name: 'Armenian Dram',
      demonym: 'Armenian',
      majorSingle: 'Dram',
      majorPlural: 'Dram',
      ISOnum: 51,
      symbol: '֏',
      symbolNative: 'դր',
      minorSingle: 'Luma',
      minorPlural: 'Luma',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'hy-AM'
  },
  ANG: {
      name: 'Netherlands Antillean Guilder',
      demonym: 'Netherlands Antillean',
      majorSingle: 'Guilder',
      majorPlural: 'Guilders',
      ISOnum: 532,
      symbol: 'ƒ',
      symbolNative: 'ƒ',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-SX'
  },
  AOA: {
      name: 'Angolan Kwanza',
      demonym: 'Angolan',
      majorSingle: 'Kwanza',
      majorPlural: 'Kwanza',
      ISOnum: 973,
      symbol: 'Kz',
      symbolNative: 'Kz',
      minorSingle: 'Centimo',
      minorPlural: 'Centimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ln-AO'
  },
  ARS: {
      name: 'Argentine Peso',
      demonym: 'Argentine',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 32,
      symbol: 'AR$',
      symbolNative: '$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-AR'
  },
  AUD: {
      name: 'Australian Dollar',
      demonym: 'Australian',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 36,
      symbol: 'AU$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-AU'
  },
  AWG: {
      name: 'Aruban Florin',
      demonym: 'Aruban',
      majorSingle: 'Florin',
      majorPlural: 'Florin',
      ISOnum: 533,
      symbol: 'ƒ',
      symbolNative: 'ƒ',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'nl-AW'
  },
  AZN: {
      name: 'Azerbaijani Manat',
      demonym: 'Azerbaijani',
      majorSingle: 'Manat',
      majorPlural: 'Manat',
      ISOnum: 944,
      symbol: 'ман',
      symbolNative: '₼',
      minorSingle: 'Qapik',
      minorPlural: 'Qapik',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'az-Cyrl-AZ'
  },
  BAM: {
      name: 'Bosnia and Herzegovina Convertible Mark',
      demonym: 'Bosnia-Herzegovina',
      majorSingle: 'Convertible Mark',
      majorPlural: 'Marks',
      ISOnum: 977,
      symbol: 'KM',
      symbolNative: 'КМ',
      minorSingle: 'Fening',
      minorPlural: 'Fening',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'bs-Cyrl-BA'
  },
  BBD: {
      name: 'Barbadian Dollar',
      demonym: 'Barbadian',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 52,
      symbol: 'BBD$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-BB'
  },
  BDT: {
      name: 'Bangladeshi Taka',
      demonym: 'Bangladeshi',
      majorSingle: 'Taka',
      majorPlural: 'Taka',
      ISOnum: 50,
      symbol: '৳',
      symbolNative: '৳',
      minorSingle: 'Poisha',
      minorPlural: 'Poisha',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'bn-BD'
  },
  BGN: {
      name: 'Bulgarian Lev',
      demonym: 'Bulgarian',
      majorSingle: 'Lev',
      majorPlural: 'Leva',
      ISOnum: 975,
      symbol: 'лв.',
      symbolNative: 'лв.',
      minorSingle: 'Stotinka',
      minorPlural: 'Stotinki',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'bg-BG'
  },
  BHD: {
      name: 'Bahraini Dinar',
      demonym: 'Bahraini',
      majorSingle: 'Dinar',
      majorPlural: 'Dinars',
      ISOnum: 48,
      symbol: 'BD',
      symbolNative: 'د.ب.',
      minorSingle: 'Fils',
      minorPlural: 'Fils',
      ISOdigits: 3,
      decimals: 3,
      numToBasic: 1000,
  locale: 'ar-BH'
  },
  BIF: {
      name: 'Burundian Franc',
      demonym: 'Burundian',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 108,
      symbol: 'FBu',
      symbolNative: 'FBu',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-BI'
  },
  BMD: {
      name: 'Bermudian Dollar',
      demonym: 'Bermudian',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 60,
      symbol: '$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-BM'
  },
  BND: {
      name: 'Brunei Dollar',
      demonym: 'Brunei',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 96,
      symbol: 'B$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ms-BN'
  },
  BOB: {
      name: 'Bolivian Boliviano',
      demonym: 'Bolivian',
      majorSingle: 'Boliviano',
      majorPlural: 'Bolivianos',
      ISOnum: 68,
      symbol: 'Bs.',
      symbolNative: 'Bs.',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-BO'
  },
  BRL: {
      name: 'Brazilian Real',
      demonym: 'Brazilian',
      majorSingle: 'Real',
      majorPlural: 'Reais',
      ISOnum: 986,
      symbol: 'R$',
      symbolNative: 'R$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-BR'
  },
  BSD: {
      name: 'Bahamian Dollar',
      demonym: 'Bahamian',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 44,
      symbol: '$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-BS'
  },
  BTN: {
      name: 'Bhutanese Ngultrum',
      demonym: 'Bhutanese',
      majorSingle: 'Ngultrum',
      majorPlural: 'Ngultrums',
      ISOnum: 64,
      symbol: 'Nu.',
      symbolNative: 'Nu.',
      minorSingle: 'Chetrum',
      minorPlural: 'Chetrums',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'dz-BT'
  },
  BWP: {
      name: 'Botswana Pula',
      demonym: 'Botswana',
      majorSingle: 'Pula',
      majorPlural: 'Pula',
      ISOnum: 72,
      symbol: 'P',
      symbolNative: 'P',
      minorSingle: 'Thebe',
      minorPlural: 'Thebe',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-BW'
  },
  BYN: {
      name: 'Belarusian Ruble',
      demonym: 'Belarusian',
      majorSingle: 'Ruble',
      majorPlural: 'Rubles',
      ISOnum: 933,
      symbol: 'Br',
      symbolNative: 'руб.',
      minorSingle: 'Kapiejka',
      minorPlural: 'Kapiejka',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'be-BY'
  },
  BZD: {
      name: 'Belize Dollar',
      demonym: 'Belize',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 84,
      symbol: 'BZ$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-BZ'
  },
  CAD: {
      name: 'Canadian Dollar',
      demonym: 'Canadian',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 124,
      symbol: 'CA$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-CA'
  },
  CDF: {
      name: 'Congolese Franc',
      demonym: 'Congolese',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 976,
      symbol: 'FC',
      symbolNative: '₣',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'fr-CD'
  },
  CHF: {
      name: 'Swiss Franc',
      demonym: 'Swiss',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 756,
      symbol: 'Fr.',
      symbolNative: '₣',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'de-CH'
  },
  CKD: {
      name: 'Cook Islands Dollar',
      demonym: 'Cook Islands',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: null,
      symbol: '$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  CLP: {
      name: 'Chilean Peso',
      demonym: 'Chilean',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 152,
      symbol: 'CL$',
      symbolNative: '$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 0,
      decimals: 0,
      numToBasic: 100,
  locale: 'es-CL'
  },
  CNY: {
      name: 'Chinese Yuan',
      demonym: 'Chinese',
      majorSingle: 'Yuan',
      majorPlural: 'Yuan',
      ISOnum: 156,
      symbol: 'CN¥',
      symbolNative: '¥元',
      minorSingle: 'Fen',
      minorPlural: 'Fen',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'bo-CN'
  },
  COP: {
      name: 'Colombian Peso',
      demonym: 'Colombian',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 170,
      symbol: 'CO$',
      symbolNative: '$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-CO'
  },
  CRC: {
      name: 'Costa Rican Colon',
      demonym: 'Costa Rican',
      majorSingle: 'Colón',
      majorPlural: 'Colones',
      ISOnum: 188,
      symbol: '₡',
      symbolNative: '₡',
      minorSingle: 'Centimo',
      minorPlural: 'Centimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-CR'
  },
  CUC: {
      name: 'Cuban convertible Peso',
      demonym: 'Cuban Convertible',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 931,
      symbol: 'CUC$',
      symbolNative: '$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
      locale: ''
  },
  CUP: {
      name: 'Cuban Peso',
      demonym: 'Cuban',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 192,
      symbol: '$MN',
      symbolNative: '₱',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-CU'
  },
  CVE: {
      name: 'Cabo Verdean Escudo',
      demonym: 'Cabo Verdean',
      majorSingle: 'Escudo',
      majorPlural: 'Escudo',
      ISOnum: 132,
      symbol: 'CV$',
      symbolNative: '$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'kea-CV'
  },
  CZK: {
      name: 'Czech Koruna',
      demonym: 'Czech',
      majorSingle: 'Koruna',
      majorPlural: 'Koruny',
      ISOnum: 203,
      symbol: 'Kč',
      symbolNative: 'Kč',
      minorSingle: 'Haléř',
      minorPlural: 'Haléř',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'cs-CZ'
  },
  DJF: {
      name: 'Djiboutian Franc',
      demonym: 'Djiboutian',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 262,
      symbol: 'Fdj',
      symbolNative: 'ف.ج.',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  DKK: {
      name: 'Danish Krone',
      demonym: 'Danish',
      majorSingle: 'Krone',
      majorPlural: 'Kroner',
      ISOnum: 208,
      symbol: 'kr.',
      symbolNative: 'kr.',
      minorSingle: 'Øre',
      minorPlural: 'Øre',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'da-DK'
  },
  DOP: {
      name: 'Dominican Peso',
      demonym: 'Dominican',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 214,
      symbol: 'RD$',
      symbolNative: '$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-DO'
  },
  DZD: {
      name: 'Algerian Dinar',
      demonym: 'Algerian',
      majorSingle: 'Dinar',
      majorPlural: 'Dinars',
      ISOnum: 12,
      symbol: 'DA',
      symbolNative: 'د.ج.',
      minorSingle: 'Santeem',
      minorPlural: 'Santeems',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-DZ'
  },
  EGP: {
      name: 'Egyptian Pound',
      demonym: 'Egyptian',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 818,
      symbol: 'E£',
      symbolNative: 'ج.م.',
      minorSingle: 'Qirsh',
      minorPlural: 'Qirsh',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-EG'
  },
  EHP: {
      name: 'Sahrawi Peseta',
      demonym: 'Sahrawi',
      majorSingle: 'Peseta',
      majorPlural: 'Pesetas',
      ISOnum: null,
      symbol: 'Ptas.',
      symbolNative: 'Ptas.',
      minorSingle: 'Céntimo',
      minorPlural: 'Céntimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  ERN: {
      name: 'Eritrean Nakfa',
      demonym: 'Eritrean',
      majorSingle: 'Nakfa',
      majorPlural: 'Nakfa',
      ISOnum: 232,
      symbol: 'Nkf',
      symbolNative: 'ناكفا',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-ER'
  },
  ETB: {
      name: 'Ethiopian Birr',
      demonym: 'Ethiopian',
      majorSingle: 'Birr',
      majorPlural: 'Birr',
      ISOnum: 230,
      symbol: 'Br',
      symbolNative: 'ብር',
      minorSingle: 'Santim',
      minorPlural: 'Santim',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'am-ET'
  },
  EUR: {
      name: 'Euro',
      demonym: '',
      majorSingle: 'Euro',
      majorPlural: 'Euros',
      ISOnum: 978,
      symbol: '€',
      symbolNative: '€',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'fr-FR'
  },
  FJD: {
      name: 'Fijian Dollar',
      demonym: 'Fijian',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 242,
      symbol: 'FJ$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-FJ'
  },
  FKP: {
      name: 'Falkland Islands Pound',
      demonym: 'Falkland Islands',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 238,
      symbol: 'FK£',
      symbolNative: '£',
      minorSingle: 'Penny',
      minorPlural: 'Pence',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-FK'
  },
  FOK: {
      name: 'Faroese Króna',
      demonym: 'Faroese',
      majorSingle: 'Króna',
      majorPlural: 'Krónas',
      ISOnum: null,
      symbol: 'kr',
      symbolNative: 'kr',
      minorSingle: 'Oyra',
      minorPlural: 'Oyra',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  GBP: {
      name: 'Pound Sterling',
      demonym: 'Pound Sterling',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 826,
      symbol: '£',
      symbolNative: '£',
      minorSingle: 'Penny',
      minorPlural: 'Pence',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'cy-GB'
  },
  GEL: {
      name: 'Georgian Lari',
      demonym: 'Georgian',
      majorSingle: 'Lari',
      majorPlural: 'Lari',
      ISOnum: 981,
      symbol: '₾',
      symbolNative: '₾',
      minorSingle: 'Tetri',
      minorPlural: 'Tetri',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ka-GE'
  },
  GGP: {
      name: 'Guernsey Pound',
      demonym: 'Guernsey',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: null,
      symbol: '£',
      symbolNative: '£',
      minorSingle: 'Penny',
      minorPlural: 'Pence',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  GHS: {
      name: 'Ghanaian Cedi',
      demonym: 'Ghanaian',
      majorSingle: 'Cedi',
      majorPlural: 'Cedis',
      ISOnum: 936,
      symbol: 'GH₵',
      symbolNative: '₵',
      minorSingle: 'Pesewa',
      minorPlural: 'Pesewas',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ak-GH'
  },
  GIP: {
      name: 'Gibraltar Pound',
      demonym: 'Gibraltar',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 292,
      symbol: '£',
      symbolNative: '£',
      minorSingle: 'Penny',
      minorPlural: 'Pence',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GI'
  },
  GMD: {
      name: 'Gambian Dalasi',
      demonym: 'Gambian',
      majorSingle: 'Dalasi',
      majorPlural: 'Dalasis',
      ISOnum: 270,
      symbol: 'D',
      symbolNative: 'D',
      minorSingle: 'Butut',
      minorPlural: 'Bututs',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GM'
  },
  GNF: {
      name: 'Guinean Franc',
      demonym: 'Guinean',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 324,
      symbol: 'FG',
      symbolNative: 'FG',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'ff-Adlm-GN'
  },
  GTQ: {
      name: 'Guatemalan Quetzal',
      demonym: 'Guatemalan',
      majorSingle: 'Quetzal',
      majorPlural: 'Quetzales',
      ISOnum: 320,
      symbol: 'Q',
      symbolNative: '$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-GT'
  },
  GYD: {
      name: 'Guyanese Dollar',
      demonym: 'Guyanese',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 328,
      symbol: 'G$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GY'
  },
  HKD: {
      name: 'Hong Kong Dollar',
      demonym: 'Hong Kong',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 344,
      symbol: 'HK$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-HK'
  },
  HNL: {
      name: 'Honduran Lempira',
      demonym: 'Honduran',
      majorSingle: 'Lempira',
      majorPlural: 'Lempiras',
      ISOnum: 340,
      symbol: 'L',
      symbolNative: 'L',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-HN'
  },
  HRK: {
      name: 'Croatian Kuna',
      demonym: 'Croatian',
      majorSingle: 'Kuna',
      majorPlural: 'Kuna',
      ISOnum: 191,
      symbol: 'kn',
      symbolNative: 'kn',
      minorSingle: 'Lipa',
      minorPlural: 'Lipa',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'hr-HR'
  },
  HTG: {
      name: 'Haitian Gourde',
      demonym: 'Haitian',
      majorSingle: 'Gourde',
      majorPlural: 'Gourdes',
      ISOnum: 332,
      symbol: 'G',
      symbolNative: 'G',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'fr-HT'
  },
  HUF: {
      name: 'Hungarian Forint',
      demonym: 'Hungarian',
      majorSingle: 'Forint',
      majorPlural: 'Forint',
      ISOnum: 348,
      symbol: 'Ft',
      symbolNative: 'Ft',
      minorSingle: 'fillér',
      minorPlural: 'fillér',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'hu-HU'
  },
  IDR: {
      name: 'Indonesian Rupiah',
      demonym: 'Indonesian',
      majorSingle: 'Rupiah',
      majorPlural: 'Rupiah',
      ISOnum: 360,
      symbol: 'Rp',
      symbolNative: 'Rp',
      minorSingle: 'Sen',
      minorPlural: 'Sen',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'id-ID'
  },
  ILS: {
      name: 'Israeli new Shekel',
      demonym: 'Israeli',
      majorSingle: 'Shekel',
      majorPlural: 'Shekels',
      ISOnum: 376,
      symbol: '₪',
      symbolNative: '₪',
      minorSingle: 'Agora',
      minorPlural: 'Agoras',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-IL'
  },
  IMP: {
      name: 'Manx Pound',
      demonym: 'Manx',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: null,
      symbol: '£',
      symbolNative: '£',
      minorSingle: 'Penny',
      minorPlural: 'Pence',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  INR: {
      name: 'Indian Rupee',
      demonym: 'Indian',
      majorSingle: 'Rupee',
      majorPlural: 'Rupees',
      ISOnum: 356,
      symbol: 'Rs.',
      symbolNative: '₹',
      minorSingle: 'Paisa',
      minorPlural: 'Paise',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'as-IN'
  },
  IQD: {
      name: 'Iraqi Dinar',
      demonym: 'Iraqi',
      majorSingle: 'Dinar',
      majorPlural: 'Dinars',
      ISOnum: 368,
      symbol: 'د.ع.',
      symbolNative: 'د.ع.',
      minorSingle: 'Fils',
      minorPlural: 'Fils',
      ISOdigits: 3,
      decimals: 3,
      numToBasic: 1000,
  locale: 'ar-IQ'
  },
  IRR: {
      name: 'Iranian Rial',
      demonym: 'Iranian',
      majorSingle: 'Rial',
      majorPlural: 'Rials',
      ISOnum: 364,
      symbol: '﷼',
      symbolNative: '﷼',
      minorSingle: 'Dinar',
      minorPlural: 'Dinars',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ckb-IR'
  },
  ISK: {
      name: 'Icelandic Krona',
      demonym: 'Icelandic',
      majorSingle: 'Krona',
      majorPlural: 'Krónur',
      ISOnum: 352,
      symbol: 'kr',
      symbolNative: 'kr',
      minorSingle: 'Aurar',
      minorPlural: 'Aurar',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'is-IS'
  },
  JEP: {
      name: 'Jersey Pound',
      demonym: 'Jersey',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: null,
      symbol: '£',
      symbolNative: '£',
      minorSingle: 'Penny',
      minorPlural: 'Pence',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  JMD: {
      name: 'Jamaican Dollar',
      demonym: 'Jamaican',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 388,
      symbol: 'J$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-JM'
  },
  JOD: {
      name: 'Jordanian Dinar',
      demonym: 'Jordanian',
      majorSingle: 'Dinar',
      majorPlural: 'Dinars',
      ISOnum: 400,
      symbol: 'JD',
      symbolNative: 'د.أ.',
      minorSingle: 'Fils',
      minorPlural: 'Fils',
      ISOdigits: 3,
      decimals: 3,
      numToBasic: 1000,
  locale: 'ar-JO'
  },
  JPY: {
      name: 'Japanese Yen',
      demonym: 'Japanese',
      majorSingle: 'Yen',
      majorPlural: 'Yen',
      ISOnum: 392,
      symbol: '¥',
      symbolNative: '¥',
      minorSingle: 'Sen',
      minorPlural: 'Sen',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'ja-JP'
  },
  KES: {
      name: 'Kenyan Shilling',
      demonym: 'Kenyan',
      majorSingle: 'Shilling',
      majorPlural: 'Shillings',
      ISOnum: 404,
      symbol: 'KSh',
      symbolNative: 'KSh',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'dav-KE'
  },
  KGS: {
      name: 'Kyrgyzstani Som',
      demonym: 'Kyrgyzstani',
      majorSingle: 'Som',
      majorPlural: 'Som',
      ISOnum: 417,
      symbol: 'с',
      symbolNative: 'с',
      minorSingle: 'Tyiyn',
      minorPlural: 'Tyiyn',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ky-KG'
  },
  KHR: {
      name: 'Cambodian Riel',
      demonym: 'Cambodian',
      majorSingle: 'Riel',
      majorPlural: 'Riels',
      ISOnum: 116,
      symbol: '៛',
      symbolNative: '៛',
      minorSingle: 'Sen',
      minorPlural: 'Sen',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'km-KH'
  },
  KID: {
      name: 'Kiribati Dollar',
      demonym: 'Kiribati',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: null,
      symbol: '$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  KMF: {
      name: 'Comorian Franc',
      demonym: 'Comorian',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 174,
      symbol: 'CF',
      symbolNative: 'CF',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  KPW: {
      name: 'North Korean Won',
      demonym: 'North Korean',
      majorSingle: 'Won',
      majorPlural: 'Won',
      ISOnum: 408,
      symbol: '₩',
      symbolNative: '₩',
      minorSingle: 'Chon',
      minorPlural: 'Chon',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ko-KP'
  },
  KRW: {
      name: 'South Korean Won',
      demonym: 'South Korean',
      majorSingle: 'Won',
      majorPlural: 'Won',
      ISOnum: 410,
      symbol: '₩',
      symbolNative: '₩',
      minorSingle: 'Jeon',
      minorPlural: 'Jeon',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'ko-KR'
  },
  KWD: {
      name: 'Kuwaiti Dinar',
      demonym: 'Kuwaiti',
      majorSingle: 'Dinar',
      majorPlural: 'Dinars',
      ISOnum: 414,
      symbol: 'KD',
      symbolNative: 'د.ك.',
      minorSingle: 'Fils',
      minorPlural: 'Fils',
      ISOdigits: 3,
      decimals: 3,
      numToBasic: 1000,
  locale: 'ar-KW'
  },
  KYD: {
      name: 'Cayman Islands Dollar',
      demonym: 'Cayman Islands',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 136,
      symbol: 'CI$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-KY'
  },
  KZT: {
      name: 'Kazakhstani Tenge',
      demonym: 'Kazakhstani',
      majorSingle: 'Tenge',
      majorPlural: 'Tenge',
      ISOnum: 398,
      symbol: '₸',
      symbolNative: '₸',
      minorSingle: 'Tıyn',
      minorPlural: 'Tıyn',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'kk-KZ'
  },
  LAK: {
      name: 'Lao Kip',
      demonym: 'Lao',
      majorSingle: 'Kip',
      majorPlural: 'Kip',
      ISOnum: 418,
      symbol: '₭N',
      symbolNative: '₭',
      minorSingle: 'Att',
      minorPlural: 'Att',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'lo-LA'
  },
  LBP: {
      name: 'Lebanese Pound',
      demonym: 'Lebanese',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 422,
      symbol: 'LL.',
      symbolNative: 'ل.ل.',
      minorSingle: 'Qirsh',
      minorPlural: 'Qirsh',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-LB'
  },
  LKR: {
      name: 'Sri Lankan Rupee',
      demonym: 'Sri Lankan',
      majorSingle: 'Rupee',
      majorPlural: 'Rupees',
      ISOnum: 144,
      symbol: 'Rs.',
      symbolNative: 'රු or ரூ',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'si-LK'
  },
  LRD: {
      name: 'Liberian Dollar',
      demonym: 'Liberian',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 430,
      symbol: 'L$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-LR'
  },
  LSL: {
      name: 'Lesotho Loti',
      demonym: 'Lesotho',
      majorSingle: 'Loti',
      majorPlural: 'maLoti',
      ISOnum: 426,
      symbol: 'L',
      symbolNative: 'L',
      minorSingle: 'Sente',
      minorPlural: 'Lisente',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
      locale:"",
  },
  LYD: {
      name: 'Libyan Dinar',
      demonym: 'Libyan',
      majorSingle: 'Dinar',
      majorPlural: 'Dinars',
      ISOnum: 434,
      symbol: 'LD',
      symbolNative: 'ل.د.',
      minorSingle: 'Dirham',
      minorPlural: 'Dirhams',
      ISOdigits: 3,
      decimals: 3,
      numToBasic: 1000,
  locale: 'ar-LY'
  },
  MAD: {
      name: 'Moroccan Dirham',
      demonym: 'Moroccan',
      majorSingle: 'Dirham',
      majorPlural: 'Dirhams',
      ISOnum: 504,
      symbol: 'DH',
      symbolNative: 'د.م.',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-EH'
  },
  MDL: {
      name: 'Moldovan Leu',
      demonym: 'Moldovan',
      majorSingle: 'Leu',
      majorPlural: 'Lei',
      ISOnum: 498,
      symbol: 'L',
      symbolNative: 'L',
      minorSingle: 'Ban',
      minorPlural: 'Bani',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ro-MD'
  },
  MGA: {
      name: 'Malagasy Ariary',
      demonym: 'Malagasy',
      majorSingle: 'Ariary',
      majorPlural: 'Ariary',
      ISOnum: 969,
      symbol: 'Ar',
      symbolNative: 'Ar',
      minorSingle: 'Iraimbilanja',
      minorPlural: 'Iraimbilanja',
      ISOdigits: 2,
      decimals: 0,
      numToBasic: 5,
  locale: 'en-MG'
  },
  MKD: {
      name: 'Macedonian Denar',
      demonym: 'Macedonian',
      majorSingle: 'Denar',
      majorPlural: 'Denars',
      ISOnum: 807,
      symbol: 'den',
      symbolNative: 'ден',
      minorSingle: 'Deni',
      minorPlural: 'Deni',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'mk-MK'
  },
  MMK: {
      name: 'Myanmar Kyat',
      demonym: 'Myanmar',
      majorSingle: 'Kyat',
      majorPlural: 'Kyat',
      ISOnum: 104,
      symbol: 'Ks',
      symbolNative: 'Ks',
      minorSingle: 'Pya',
      minorPlural: 'Pya',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'my-MM'
  },
  MNT: {
      name: 'Mongolian Tögrög',
      demonym: 'Mongolian',
      majorSingle: 'Tögrög',
      majorPlural: 'Tögrög',
      ISOnum: 496,
      symbol: '₮',
      symbolNative: '₮',
      minorSingle: 'möngö',
      minorPlural: 'möngö',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'mn-MN'
  },
  MOP: {
      name: 'Macanese Pataca',
      demonym: 'Macanese',
      majorSingle: 'Pataca',
      majorPlural: 'Patacas',
      ISOnum: 446,
      symbol: 'MOP$',
      symbolNative: 'MOP$',
      minorSingle: 'Avo',
      minorPlural: 'Avos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-MO'
  },
  MRU: {
      name: 'Mauritanian Ouguiya',
      demonym: 'Mauritanian',
      majorSingle: 'Ouguiya',
      majorPlural: 'Ouguiya',
      ISOnum: 929,
      symbol: 'UM',
      symbolNative: 'أ.م.',
      minorSingle: 'Khoums',
      minorPlural: 'Khoums',
      ISOdigits: 2,
      decimals: 0,
      numToBasic: 5,
  locale: 'en-GB'
  },
  MUR: {
      name: 'Mauritian Rupee',
      demonym: 'Mauritian',
      majorSingle: 'Rupee',
      majorPlural: 'Rupees',
      ISOnum: 480,
      symbol: 'Rs.',
      symbolNative: 'रु ',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-MU'
  },
  MVR: {
      name: 'Maldivian Rufiyaa',
      demonym: 'Maldivian',
      majorSingle: 'Rufiyaa',
      majorPlural: 'Rufiyaa',
      ISOnum: 462,
      symbol: 'MRf',
      symbolNative: '.ރ',
      minorSingle: 'laari',
      minorPlural: 'laari',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
      locale:"",
  },
  MWK: {
      name: 'Malawian Kwacha',
      demonym: 'Malawian',
      majorSingle: 'Kwacha',
      majorPlural: 'Kwacha',
      ISOnum: 454,
      symbol: 'MK',
      symbolNative: 'MK',
      minorSingle: 'Tambala',
      minorPlural: 'Tambala',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-MW'
  },
  MXN: {
      name: 'Mexican Peso',
      demonym: 'Mexican',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 484,
      symbol: 'MX$',
      symbolNative: '$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-MX'
  },
  MYR: {
      name: 'Malaysian Ringgit',
      demonym: 'Malaysian',
      majorSingle: 'Ringgit',
      majorPlural: 'Ringgit',
      ISOnum: 458,
      symbol: 'RM',
      symbolNative: 'RM',
      minorSingle: 'Sen',
      minorPlural: 'Sen',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-MY'
  },
  MZN: {
      name: 'Mozambican Metical',
      demonym: 'Mozambican',
      majorSingle: 'Metical',
      majorPlural: 'Meticais',
      ISOnum: 943,
      symbol: 'MTn',
      symbolNative: 'MT',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'mgh-MZ'
  },
  NAD: {
      name: 'Namibian Dollar',
      demonym: 'Namibian',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 516,
      symbol: 'N$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'af-NA'
  },
  NGN: {
      name: 'Nigerian Naira',
      demonym: 'Nigerian',
      majorSingle: 'Naira',
      majorPlural: 'Naira',
      ISOnum: 566,
      symbol: '₦',
      symbolNative: '₦',
      minorSingle: 'Kobo',
      minorPlural: 'Kobo',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-NG'
  },
  NIO: {
      name: 'Nicaraguan Córdoba',
      demonym: 'Nicaraguan',
      majorSingle: 'Córdoba Oro',
      majorPlural: 'Córdoba Oro',
      ISOnum: 558,
      symbol: 'C$',
      symbolNative: 'C$',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-NI'
  },
  NOK: {
      name: 'Norwegian Krone',
      demonym: 'Norwegian',
      majorSingle: 'Krone',
      majorPlural: 'Kroner',
      ISOnum: 578,
      symbol: 'kr',
      symbolNative: 'kr',
      minorSingle: 'øre',
      minorPlural: 'øre',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'nb-NO'
  },
  NPR: {
      name: 'Nepalese Rupee',
      demonym: 'Nepalese',
      majorSingle: 'Rupee',
      majorPlural: 'Rupees',
      ISOnum: 524,
      symbol: 'Rs.',
      symbolNative: 'रू',
      minorSingle: 'Paisa',
      minorPlural: 'Paise',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ne-NP'
  },
  NZD: {
      name: 'New Zealand Dollar',
      demonym: 'New Zealand',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 554,
      symbol: 'NZ$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-CK'
  },
  OMR: {
      name: 'Omani Rial',
      demonym: 'Omani',
      majorSingle: 'Rial',
      majorPlural: 'Rials',
      ISOnum: 512,
      symbol: 'OR',
      symbolNative: 'ر.ع.',
      minorSingle: 'Baisa',
      minorPlural: 'Baisa',
      ISOdigits: 3,
      decimals: 3,
      numToBasic: 1000,
  locale: 'ar-OM'
  },
  PAB: {
      name: 'Panamanian Balboa',
      demonym: 'Panamanian',
      majorSingle: 'Balboa',
      majorPlural: 'Balboa',
      ISOnum: 590,
      symbol: 'B/.',
      symbolNative: 'B/.',
      minorSingle: 'Centésimo',
      minorPlural: 'Centésimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-PA'
  },
  PEN: {
      name: 'Peruvian Sol',
      demonym: 'Peruvian',
      majorSingle: 'Sol',
      majorPlural: 'Soles',
      ISOnum: 604,
      symbol: 'S/.',
      symbolNative: 'S/.',
      minorSingle: 'Céntimo',
      minorPlural: 'Céntimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-PE'
  },
  PGK: {
      name: 'Papua New Guinean Kina',
      demonym: 'Papua New Guinean',
      majorSingle: 'Kina',
      majorPlural: 'Kina',
      ISOnum: 598,
      symbol: 'K',
      symbolNative: 'K',
      minorSingle: 'Toea',
      minorPlural: 'Toea',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-PG'
  },
  PHP: {
      name: 'Philippine Peso',
      demonym: 'Philippine',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 608,
      symbol: '₱',
      symbolNative: '₱',
      minorSingle: 'Sentimo',
      minorPlural: 'Sentimo',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ceb-PH'
  },
  PKR: {
      name: 'Pakistani Rupee',
      demonym: 'Pakistani',
      majorSingle: 'Rupee',
      majorPlural: 'Rupees',
      ISOnum: 586,
      symbol: 'Rs.',
      symbolNative: 'Rs',
      minorSingle: 'Paisa',
      minorPlural: 'Paise',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-PK'
  },
  PLN: {
      name: 'Polish Zloty',
      demonym: 'Polish',
      majorSingle: 'Zloty',
      majorPlural: 'Zlotys',
      ISOnum: 985,
      symbol: 'zł',
      symbolNative: 'zł',
      minorSingle: 'Grosz',
      minorPlural: 'Groszy',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'pl-PL'
  },
  PND: {
      name: 'Pitcairn Islands Dollar',
      demonym: 'Pitcairn Islands',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: null,
      symbol: '$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  PRB: {
      name: 'Transnistrian Ruble',
      demonym: 'Transnistrian',
      majorSingle: 'Ruble',
      majorPlural: 'Rubles',
      ISOnum: null,
      symbol: 'р.',
      symbolNative: 'р.',
      minorSingle: 'Kopek',
      minorPlural: 'Kopeks',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  PYG: {
      name: 'Paraguayan Guaraní',
      demonym: 'Paraguayan',
      majorSingle: 'Guaraní',
      majorPlural: 'Guaraníes',
      ISOnum: 600,
      symbol: '₲',
      symbolNative: '₲',
      minorSingle: 'Centimo',
      minorPlural: 'Centimos',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-PY'
  },
  QAR: {
      name: 'Qatari Riyal',
      demonym: 'Qatari',
      majorSingle: 'Riyal',
      majorPlural: 'Riyals',
      ISOnum: 634,
      symbol: 'QR',
      symbolNative: 'ر.ق.',
      minorSingle: 'Dirham',
      minorPlural: 'Dirhams',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-QA'
  },
  RON: {
      name: 'Romanian Leu',
      demonym: 'Romanian',
      majorSingle: 'Leu',
      majorPlural: 'Lei',
      ISOnum: 946,
      symbol: 'L',
      symbolNative: 'L',
      minorSingle: 'Ban',
      minorPlural: 'Bani',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ro-RO'
  },
  RSD: {
      name: 'Serbian Dinar',
      demonym: 'Serbian',
      majorSingle: 'Dinar',
      majorPlural: 'Dinars',
      ISOnum: 941,
      symbol: 'din',
      symbolNative: 'дин',
      minorSingle: 'Para',
      minorPlural: 'Para',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'sr-Cyrl-RS'
  },
  RUB: {
      name: 'Russian Ruble',
      demonym: 'Russian',
      majorSingle: 'Ruble',
      majorPlural: 'Rubles',
      ISOnum: 643,
      symbol: '₽',
      symbolNative: '₽',
      minorSingle: 'Kopek',
      minorPlural: 'Kopeks',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ce-RU'
  },
  RWF: {
      name: 'Rwandan Franc',
      demonym: 'Rwandan',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 646,
      symbol: 'FRw',
      symbolNative: 'R₣',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-RW'
  },
  SAR: {
      name: 'Saudi Riyal',
      demonym: 'Saudi',
      majorSingle: 'Riyal',
      majorPlural: 'Riyals',
      ISOnum: 682,
      symbol: 'SR',
      symbolNative: 'ر.س.',
      minorSingle: 'Halalah',
      minorPlural: 'Halalahs',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-SA'
  },
  SBD: {
      name: 'Solomon Islands Dollar',
      demonym: 'Solomon Islands',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 90,
      symbol: 'SI$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  SCR: {
      name: 'Seychellois Rupee',
      demonym: 'Seychellois',
      majorSingle: 'Rupee',
      majorPlural: 'Rupees',
      ISOnum: 690,
      symbol: 'Rs.',
      symbolNative: 'Rs',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-SC'
  },
  SDG: {
      name: 'Sudanese Pound',
      demonym: 'Sudanese',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 938,
      symbol: '£SD',
      symbolNative: 'ج.س.',
      minorSingle: 'Qirsh',
      minorPlural: 'Qirsh',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-SD'
  },
  SEK: {
      name: 'Swedish Krona',
      demonym: 'Swedish',
      majorSingle: 'Krona',
      majorPlural: 'Kronor',
      ISOnum: 752,
      symbol: 'kr',
      symbolNative: 'kr',
      minorSingle: 'Öre',
      minorPlural: 'Öre',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-SE'
  },
  SGD: {
      name: 'Singapore Dollar',
      demonym: 'Singapore',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 702,
      symbol: 'S$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-SG'
  },
  SHP: {
      name: 'Saint Helena Pound',
      demonym: 'Saint Helena',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 654,
      symbol: '£',
      symbolNative: '£',
      minorSingle: 'Penny',
      minorPlural: 'Pence',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-SH'
  },
  SLL: {
      name: 'Sierra Leonean Leone',
      demonym: 'Sierra Leonean',
      majorSingle: 'Leone',
      majorPlural: 'Leones',
      ISOnum: 694,
      symbol: 'Le',
      symbolNative: 'Le',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-SL'
  },
  SLS: {
      name: 'Somaliland Shilling',
      demonym: 'Somaliland',
      majorSingle: 'Shilling',
      majorPlural: 'Shillings',
      ISOnum: null,
      symbol: 'Sl',
      symbolNative: 'Sl',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  SOS: {
      name: 'Somali Shilling',
      demonym: 'Somali',
      majorSingle: 'Shilling',
      majorPlural: 'Shillings',
      ISOnum: 706,
      symbol: 'Sh.So.',
      symbolNative: 'Ssh',
      minorSingle: 'Senti',
      minorPlural: 'Senti',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-SO'
  },
  SRD: {
      name: 'Surinamese Dollar',
      demonym: 'Surinamese',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 968,
      symbol: 'Sr$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'nl-SR'
  },
  SSP: {
      name: 'South Sudanese Pound',
      demonym: 'South Sudanese',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 728,
      symbol: 'SS£',
      symbolNative: 'SS£',
      minorSingle: 'Qirsh',
      minorPlural: 'Qirsh',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  STN: {
      name: 'Sao Tome and Príncipe Dobra',
      demonym: 'Sao Tome',
      majorSingle: 'Dobra',
      majorPlural: 'Dobras',
      ISOnum: 930,
      symbol: 'Db',
      symbolNative: 'Db',
      minorSingle: 'Centimo',
      minorPlural: 'Centimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'pt-ST'
  },
  SVC: {
      name: 'Salvadoran Colón',
      demonym: 'Salvadoran',
      majorSingle: 'Colón',
      majorPlural: 'Colones',
      ISOnum: 222,
      symbol: '₡',
      symbolNative: '₡',
      minorSingle: 'Centavo',
      minorPlural: 'Centavos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
      locale:"",
  },
  SYP: {
      name: 'Syrian Pound',
      demonym: 'Syrian',
      majorSingle: 'Pound',
      majorPlural: 'Pounds',
      ISOnum: 760,
      symbol: 'LS',
      symbolNative: 'ل.س.',
      minorSingle: 'Qirsh',
      minorPlural: 'Qirsh',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-SY'
  },
  SZL: {
      name: 'Swazi Lilangeni',
      demonym: 'Swazi',
      majorSingle: 'Lilangeni',
      majorPlural: 'Emalangeni',
      ISOnum: 748,
      symbol: 'L',
      symbolNative: 'L',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-SZ'
  },
  THB: {
      name: 'Thai Baht',
      demonym: 'Thai',
      majorSingle: 'Baht',
      majorPlural: 'Baht',
      ISOnum: 764,
      symbol: '฿',
      symbolNative: '฿',
      minorSingle: 'Satang',
      minorPlural: 'Satang',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'th-TH'
  },
  TJS: {
      name: 'Tajikistani Somoni',
      demonym: 'Tajikistani',
      majorSingle: 'Somoni',
      majorPlural: 'Somoni',
      ISOnum: 972,
      symbol: 'SM',
      symbolNative: 'SM',
      minorSingle: 'Diram',
      minorPlural: 'Diram',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'tg-TJ'
  },
  TMT: {
      name: 'Turkmenistan Manat',
      demonym: 'Turkmenistan',
      majorSingle: 'Manat',
      majorPlural: 'Manat',
      ISOnum: 934,
      symbol: 'm.',
      symbolNative: 'T',
      minorSingle: 'Tenge',
      minorPlural: 'Tenge',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'tk-TM'
  },
  TND: {
      name: 'Tunisian Dinar',
      demonym: 'Tunisian',
      majorSingle: 'Dinar',
      majorPlural: 'Dinars',
      ISOnum: 788,
      symbol: 'DT',
      symbolNative: 'د.ت.',
      minorSingle: 'Millime',
      minorPlural: 'Millime',
      ISOdigits: 3,
      decimals: 3,
      numToBasic: 1000,
  locale: 'ar-TN'
  },
  TOP: {
      name: 'Tongan Paʻanga',
      demonym: 'Tongan',
      majorSingle: "Pa'anga",
      majorPlural: "Pa'anga",
      ISOnum: 776,
      symbol: 'T$',
      symbolNative: 'PT',
      minorSingle: 'Seniti',
      minorPlural: 'Seniti',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-TO'
  },
  TRY: {
      name: 'Turkish Lira',
      demonym: 'Turkish',
      majorSingle: 'Lira',
      majorPlural: 'Lira',
      ISOnum: 949,
      symbol: 'TL',
      symbolNative: '₺',
      minorSingle: 'Kuruş',
      minorPlural: 'Kuruş',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ku-TR'
  },
  TTD: {
      name: 'Trinidad and Tobago Dollar',
      demonym: 'Trinidad and Tobago',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 780,
      symbol: 'TT$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-TT'
  },
  TVD: {
      name: 'Tuvaluan Dollar',
      demonym: 'Tuvaluan',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: null,
      symbol: '$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  TWD: {
      name: 'New Taiwan Dollar',
      demonym: 'New Taiwan',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 901,
      symbol: 'NT$',
      symbolNative: '圓',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'zh-Hant-TW'
  },
  TZS: {
      name: 'Tanzanian Shilling',
      demonym: 'Tanzanian',
      majorSingle: 'Shilling',
      majorPlural: 'Shillings',
      ISOnum: 834,
      symbol: 'TSh',
      symbolNative: 'TSh',
      minorSingle: 'Senti',
      minorPlural: 'Senti',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'asa-TZ'
  },
  UAH: {
      name: 'Ukrainian Hryvnia',
      demonym: 'Ukrainian',
      majorSingle: 'Hryvnia',
      majorPlural: 'Hryvnias',
      ISOnum: 980,
      symbol: '₴',
      symbolNative: 'грн',
      minorSingle: 'Kopiyka',
      minorPlural: 'kopiyky',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ru-UA'
  },
  UGX: {
      name: 'Ugandan Shilling',
      demonym: 'Ugandan',
      majorSingle: 'Shilling',
      majorPlural: 'Shillings',
      ISOnum: 800,
      symbol: 'USh',
      symbolNative: 'Sh',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'cgg-UG'
  },
  USD: {
      name: 'United States Dollar',
      demonym: 'US',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 840,
      symbol: '$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-US'
  },
  UYU: {
      name: 'Uruguayan Peso',
      demonym: 'Uruguayan',
      majorSingle: 'Peso',
      majorPlural: 'Pesos',
      ISOnum: 858,
      symbol: '$U',
      symbolNative: '$',
      minorSingle: 'Centésimo',
      minorPlural: 'Centésimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-UY'
  },
  UZS: {
      name: 'Uzbekistani Som',
      demonym: 'Uzbekistani',
      majorSingle: 'Som',
      majorPlural: 'Som',
      ISOnum: 860,
      symbol: 'сум',
      symbolNative: 'сум',
      minorSingle: 'Tiyin',
      minorPlural: 'Tiyin',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'uz-Cyrl-UZ'
  },
  VED: {
      name: 'Venezuelan bolívar digital',
      demonym: 'Venezuelan',
      majorSingle: 'Bolívar Digital',
      majorPlural: 'Bolívars Digital',
      ISOnum: null,
      symbol: 'Bs.',
      symbolNative: 'Bs.',
      minorSingle: 'Céntimo',
      minorPlural: 'Céntimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  VES: {
      name: 'Venezuelan Bolívar Soberano',
      demonym: 'Venezuelan',
      majorSingle: 'Bolívar',
      majorPlural: 'Bolívares',
      ISOnum: 928,
      symbol: 'Bs.F',
      symbolNative: 'Bs.F',
      minorSingle: 'Centimo',
      minorPlural: 'Centimos',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'es-VE'
  },
  VND: {
      name: 'Vietnamese Dong',
      demonym: 'Vietnamese',
      majorSingle: 'Dong',
      majorPlural: 'Dong',
      ISOnum: 704,
      symbol: '₫',
      symbolNative: '₫',
      minorSingle: 'Hào',
      minorPlural: 'Hào',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 10,
  locale: 'vi-VN'
  },
  VUV: {
      name: 'Vanuatu Vatu',
      demonym: 'Vanuatu',
      majorSingle: 'Vatu',
      majorPlural: 'Vatu',
      ISOnum: 548,
      symbol: 'VT',
      symbolNative: 'VT',
      minorSingle: '',
      minorPlural: '',
      ISOdigits: 0,
      decimals: 0,
      numToBasic: null,
  locale: 'en-VU'
  },
  WST: {
      name: 'Samoan Tala',
      demonym: 'Samoan',
      majorSingle: 'Tala',
      majorPlural: 'Tala',
      ISOnum: 882,
      symbol: 'T',
      symbolNative: 'ST',
      minorSingle: 'Sene',
      minorPlural: 'Sene',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-WS'
  },
  XAF: {
      name: 'Central African CFA Franc BEAC',
      demonym: 'Central African CFA',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 950,
      symbol: 'Fr',
      symbolNative: 'Fr.',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'agq-CM'
  },
  XCD: {
      name: 'East Caribbean Dollar',
      demonym: 'East Caribbean',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 951,
      symbol: '$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-AG'
  },
  XOF: {
      name: 'West African CFA Franc BCEAO',
      demonym: 'West African CFA',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 952,
      symbol: '₣',
      symbolNative: '₣',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 0,
      decimals: 2,
      numToBasic: 100,
  locale: 'bm-ML'
  },
  XPF: {
      name: 'CFP Franc (Franc Pacifique)',
      demonym: 'CFP',
      majorSingle: 'Franc',
      majorPlural: 'Francs',
      ISOnum: 953,
      symbol: '₣',
      symbolNative: '₣',
      minorSingle: 'Centime',
      minorPlural: 'Centimes',
      ISOdigits: 0,
      decimals: 0,
      numToBasic: 100,
  locale: 'fr-NC'
  },
  YER: {
      name: 'Yemeni Rial',
      demonym: 'Yemeni',
      majorSingle: 'Rial',
      majorPlural: 'Rials',
      ISOnum: 886,
      symbol: 'YR',
      symbolNative: 'ر.ي.',
      minorSingle: 'Fils',
      minorPlural: 'Fils',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'ar-YE'
  },
  ZAR: {
      name: 'South African Rand',
      demonym: 'South African',
      majorSingle: 'Rand',
      majorPlural: 'Rand',
      ISOnum: 710,
      symbol: 'R',
      symbolNative: 'R',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'af-ZA'
  },
  ZMW: {
      name: 'Zambian Kwacha',
      demonym: 'Zambian',
      majorSingle: 'Kwacha',
      majorPlural: 'Kwacha',
      ISOnum: 967,
      symbol: 'ZK',
      symbolNative: 'ZK',
      minorSingle: 'Ngwee',
      minorPlural: 'Ngwee',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'bem-ZM'
  },
  ZWB: {
      name: 'RTGS Dollar',
      demonym: 'RTGS',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: null,
      symbol: '',
      symbolNative: '',
      minorSingle: '',
      minorPlural: '',
      ISOdigits: 0,
      decimals: 0,
      numToBasic: null,
  locale: 'en-GB'
  },
  ZWL: {
      name: 'Zimbabwean Dollar',
      demonym: 'Zimbabwean',
      majorSingle: 'Dollar',
      majorPlural: 'Dollars',
      ISOnum: 932,
      symbol: 'Z$',
      symbolNative: '$',
      minorSingle: 'Cent',
      minorPlural: 'Cents',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
  Abkhazia: {
      name: 'Abkhazian Apsar',
      demonym: 'Abkhazian',
      majorSingle: 'Apsar',
      majorPlural: 'Apsark',
      ISOnum: null,
      symbol: '',
      symbolNative: '',
      minorSingle: '',
      minorPlural: '',
      ISOdigits: 0,
      decimals: 0,
      numToBasic: null,
  locale: 'en-GB'
  },
  Artsakh: {
      name: 'Artsakh Dram',
      demonym: 'Artsakh',
      majorSingle: 'Dram',
      majorPlural: 'Dram',
      ISOnum: null,
      symbol: 'դր.',
      symbolNative: 'դր.',
      minorSingle: 'Luma',
      minorPlural: 'Luma',
      ISOdigits: 2,
      decimals: 2,
      numToBasic: 100,
  locale: 'en-GB'
  },
};

export default currencies;