/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */

import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';
import { getMyProfile, getUserMeta } from '../api/queries';

const Tracking = ({ history }) => {
  const [verifiedObject, setVerifiedObject] = useState(null);
  const userMeta = getUserMeta();
  const profile = getMyProfile();
  const { data: siteConfigData, isSuccess: domainConfigIsSuccess } = useSiteConfigAuth();
  
  const trackingEnabled = !siteConfigData?.disable_hubspot_tracking;

  useEffect(() => {
    if (domainConfigIsSuccess && trackingEnabled && import.meta.env.VITE_STAGE === 'prd') {
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', history.location.pathname]);
      _hsq.push(['trackPageView']);
      console.log('Tracking 1');
    }
  }, [history.location.key]);

  useEffect(() => {
    if (domainConfigIsSuccess && trackingEnabled && userMeta.isSuccess && profile.isSuccess && profile.data !== undefined && profile.data !== 'undefined' && profile.data !== null) {
      if (profile.data.countrycode) {
        const obj = {
          email: profile.data.email,
          firstname: profile.data.firstname,
          lastname: profile.data.lastname,
          username: profile.data.username,
          address: profile.data.addressline1,
          address_line_2: profile.data.addressline2,
          zip: profile.data.postzipcode,
          country: profile.data.countrycode,
          city: profile.data.towncity,
          referral_source: userMeta?.data?.how_hear,
          role: userMeta?.data?.role,
        };

        if (verifiedObject !== JSON.stringify(obj)) {
          const _hsq = (window._hsq = window._hsq || []);
          _hsq.push([
            'identify',
            obj,
          ]);
          setVerifiedObject(JSON.stringify(obj));
          _hsq.push(['trackPageView']);
          console.log('Tracking 2');
        }
      }
    }
  }, [userMeta.isSuccess, profile]);

  return null;
};

export default withRouter(Tracking);
