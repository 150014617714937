/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { elasticQuery } from '../../../../api/mutations';
import LoadingSmall from '../../../../components/loadingSmall';
import Table from '../../../../components/table';
import { buildFilters, twoDp } from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import SongReveal from './Songs/_songReveal';
import { indexReducedPattern } from './constants';
import getColours from '../../../../helpers/getColours';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const SongsSection = ({ matchAccounts, dateType }) => {
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const { t } = useTranslation('other');
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState(null);
  const flags = useFlags();
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const { highlightColour } = getColours();

  const range = AppState.useState((s) => s.range);

  const query = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter: [
            {
              range: { [dateType]: range[dateType] },
            },
            ...buildFilters(flags, matchAccounts),

          ],
        },
      },
      aggs: {
        Songs: {
          terms: {
            field: 'songCode',
            size: 500,
            order: {
              SongValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            SongValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            SongTitle: {
              terms: {
                field: 'songTitle.keyword',
                size: 1,
              },
            },
            WriterName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: ['workWriterLastNames'],
                },
              },
            },

          },
        },
      },
    },
    size: 0,
  };

  const elasticQueryMutation = elasticQuery();
  useEffect(() => {
    setPending(true);

    elasticQueryMutation.mutate(query, {
      onSuccess: (data) => {
        setPending(false);
        setResult(data);
      },
      onError: (error) => {
        console.log(error);
        setPending(false);
        setResult(null);
      },
    });
  }, [range, dateType]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('other:song-title'),
        accessor: (row) => `${row?.SongTitle?.buckets?.[0].key}`,
        width: '25%',
      },
      {
        Header: t('other:_writers'),
        accessor: (row) => `${row?.WriterName?.hits?.hits?.[0]._source?.workWriterLastNames?.replace(/,/g, ', ')}`,
        width: '25%',
      },
      {
        Header: t('other:earnings2'),
        accessor: (r) => r.SongValues.value,
        Cell: ({ row: { original } }) => (
          <span>
            {`${currencySymbol}${twoDp(original?.SongValues?.value)}`}
          </span>
        ),
        width: '20%',
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        width: '5%',

        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span>
            {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },

    ],
    [],
  );

  if (!pending && result) {
    return (

      <Table
        data={result?.body?.aggregations?.Songs?.buckets || []}
        columns={columns}
        highlightColour={highlightColour}
        revealComponent={(
          <SongReveal dateType={dateType} matchAccounts={matchAccounts} />
        )}
        searchSuffix={t('common:songs')}
        disableSearch
        initialSort={[{
          id: 'Earnings',
          desc: true,
        }]}
        bgClass={`${isTunecore ? 'bg-tunecore-dark-element' : 'bg-white'} rounded-lg p-0`}
      />
    );
  }
  return (
    <div className="p-4">
      <LoadingSmall />

    </div>
  );
};

export default SongsSection;
