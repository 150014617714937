import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	getGigClaims,
	getMyAccount,
	getMyProfile,
} from "../../../../api/queries";
import AllGigs from "../../../../components/allGigsTable";
import Spacer from "../../../../components/spacer";
import { sendProperty } from "../../../../helpers/helpers";
import AppState from "../../../../store/appstate";
import H2 from "../../../../components/h2";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function Gigs() {
	const { t } = useTranslation(["dashboard", "gigs", "countries"]);
	const history = useHistory();
	const { data: siteConfig } = useSiteConfigAuth();
	if (siteConfig.disable_gigs) {
		history.push("/");
	}
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});
	const gigClaims = getGigClaims({
		ac: selectedAccount,
		oc: myAccount?.data?.originatorcode?.code,
	});
	const profile = getMyProfile();
	const hubspotProps = AppState.useState((s) => s.hubspot);

	const noGigs = siteConfig?.disable_gigs;
	useEffect(() => {
		if (noGigs) {
			history.push("/");
		}
	}, [noGigs]);

	useEffect(() => {
		if (selectedAccount) {
			gigClaims.refetch();
		}
	}, [selectedAccount]);

	useEffect(() => {
		if (profile.isSuccess && gigClaims.isSuccess) {
			if (
				!hubspotProps?.songs ||
				hubspotProps?.songs !== gigClaims?.data?.length
			) {
				sendProperty({
					property: "gigs",
					value: gigClaims?.data?.length,
					email: profile.data.email,
				});
				AppState.update((s) => {
					s.hubspot.songs = gigClaims?.data?.length;
				});
			}
		}
	}, [profile.isSuccess, gigClaims.isSuccess]);

	return (
		<>
			<div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
				<H2>{t("gigs:your-gigs-header")}</H2>
				<Spacer />
				<AllGigs />
			</div>
		</>
	);
}
export default Gigs;
