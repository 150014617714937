import { useQuery } from '@tanstack/react-query';
import client from '../client';

const getSiteConfigWithDomain = async () => {
  const domain = window.location.origin

  const queryFields = `'domain': domainConfig.domain,
  'support_email': domainConfig.support_email,
  'display_name': genericConfig.display_name,
  'logo': genericConfig.logo.asset->url,
  'show_hear_about': domainConfig.show_hear_about,
  'hear_about_options': domainConfig.hear_about_options,
  'hide_show_address': domainConfig.hide_show_address,
  'hide_terms_and_conditions': domainConfig.hide_terms_and_conditions,
  'onboarding_background_colour_1': domainConfig.onboarding_background_colour_1.hex,
  'onboarding_background_colour_2': domainConfig.onboarding_background_colour_2.hex,
  'onboarding_dark_colour': domainConfig.onboarding_dark_colour.hex,
  'onboarding_highlight_colour': domainConfig.onboarding_highlight_colour.hex,
  'onboarding_highlight_colour_2': domainConfig.onboarding_highlight_colour_2.hex,
  'add_account_catalogue_options': domainConfig.add_account_catalogue_options,
  'dark_colour': catalogueConfig.dark_colour.hex,
  'highlight_colour': catalogueConfig.highlight_colour.hex,
  'highlight_colour_2': catalogueConfig.highlight_colour_2.hex,
  'hide_gig_integrations': domainConfig.hide_gig_integrations,
  'hide_socials': domainConfig.hide_socials,
  'disable_hubspot_tracking': catalogueConfig.disable_hubspot_tracking,
  'sign_up_page_array': domainConfig.SignUpPagesArray[],
  'disable_suggestions': catalogueConfig.disable_suggestions,`
  
  const mainQuery = `*[_type == 'siteConfiguration' && '${domain}' in domainConfig.domain][0]{${queryFields}}`;

  const defaultQuery = `*[_type == 'siteConfiguration' && name=='DefaultSentric'][0]{${queryFields}}`;
  
  try {
    let domainConfig = await client.fetch(mainQuery);
    if (!domainConfig) {
      domainConfig = await client.fetch(defaultQuery);
    }
    return domainConfig;
  } catch (error) {
    console.error('Error fetching config data:', error);
    throw error;
  }
};

const useSiteConfigUnAuth = () => {
  const domain = window.location.origin;
  const domainExists = Boolean(domain);

  return useQuery(['config'], getSiteConfigWithDomain, {
    onError: (error) => {
      console.error('Error in useSiteConfigAuth:', error);
    },
    staleTime: 600_000, // 10 minutes
    enabled: domainExists,
  });
};

export default useSiteConfigUnAuth;
