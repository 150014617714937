/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import { updateMyProfile } from '../../../../api/mutations';
import { getMyProfile } from '../../../../api/queries';
import FormError from '../../../../components/formError';
import H2 from '../../../../components/h2';
import Input from '../../../../components/input';
import Spacer from '../../../../components/spacer';
// import SettingsMenu from '../../../../layouts/settingsMenu';
import Select from '../../../../components/select';
import countries from '../../../../data/countries';
import RebrandPrimaryButton from '../../../../components/rebrandPrimaryButton';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function Settings() {
  const { t } = useTranslation(['other', 'settings', 'countries']);
  const [errors] = useState({});
  const [obj, setObj] = useState({});
  const [pending, setPending] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [renderComponent, setRenderComponent] = useState(false); // Added state for rendering
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const profile = getMyProfile();
  const createMutation = updateMyProfile();

  useEffect(() => {
    if (profile.isSuccess) {
      setObj({
        firstname: profile.data.firstname,
        lastname: profile.data.lastname,
        addressline1: profile.data.addressline1,
        addressline2: profile.data.addressline2,
        addressline3: profile.data.addressline3,
        towncity: profile.data.towncity,
        countystate: profile.data.countystate,
        postzipcode: profile.data.postzipcode,
        phonenumber: profile.data.phonenumber,
        countrycode: profile.data.countrycode,
      });
    }
  }, []);

  // setTimeout to allow for Select country list to be translated
  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderComponent(true);
    }, 1000); // Adjust the delay as needed (in milliseconds)

    return () => clearTimeout(timer); // Clear the timeout when the component unmounts
  }, []); // Empty dependency array ensures the effect runs only once

  const fields = [
    {
      field: 'firstname',
      label: t('common:first-name'),
      required: true,
    },
    {
      field: 'lastname',
      label: t('common:last-name'),
      required: true,
    },
    {
      field: 'addressline1',
      label: t('common:address-line-1'),
      required: true,
    },
    {
      field: 'addressline2',
      label: t('common:address-line-2'),
      required: false,
    },
    {
      field: 'addressline3',
      label: t('common:address-line-3'),
      required: false,
    },
    {
      field: 'towncity',
      label: t('common:town-city'),
      required: true,
    },
    {
      field: 'countystate',
      label: t('common:county'),
      required: true,
    },
    {
      field: 'postzipcode',
      label: t('common:postcode'),
      required: true,
    },
    {
      field: 'phonenumber',
      label: t('common:phone-number'),
      required: false,
    },
  ];

  const updateProfile = () => {
    setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
    // reset mutation
    createMutation.reset();
    // const valid = checkErrors();
    const valid = true;
    if (valid) {
      const data = obj;

      createMutation.mutate(data, {
        onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
          toast.success(t('settings:profile-updated-toast'), {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          profile.refetch();

          setPending(false);
        }
        },
        onError: (error) => {
          setMutationErrorMessage(error.message);
          setPending(false);
        },
      });
    } else {
      setPending(false);
    }
  };

  if (!renderComponent) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-4xl animate-spin">
          <FaSpinner className="align-center" />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-wrap">
      <div className='w-full lg:min-h-screen-minus-header'>
        <div className="mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12">
          <H2>{t('settings:update-details-subheader')}</H2>
          <Spacer />
          <div className="w-full pb-24">
            <Input
              disabled
              type="text"
              placeholder={t('auth:email-placeholder')}
              value={profile?.data?.email || ''}
              onBlur={() => {}}
              onChange={() => { }}
              className="settings-disabled-input cursor-not-allowed"
              
            />
            <Spacer thin />

            {fields.map((f) => (
              <div key={f.field}>
                <Input
                  required={f.required}
                  type="text"
                  error={errors[f.field]}
                  placeholder={f.label}
                  value={obj[f.field] || ''}
                  onBlur={() => {}}
                  onChange={(e) => {
                    setObj({ ...obj, [f.field]: e.target.value });
                  }}
                />
                <Spacer thin />
              </div>
            ))}
            <Select
              className={`select-search ${isTunecore ? 'tunecore-select' : 'font-sentric-alt text-base'} font-semibold required-star`}
              required
              name="country"
              placeholder={t('other:select-country-placeholder')}
              value={obj.countrycode}
              onChange={(selected) => {
                setObj({ ...obj, countrycode: selected });
              }}
              options={countries.map((option) => ({
                name: t(`countries:${option.name}`),
                value: option.value,
              }))}
              search
              filterOptions
            />
            <Spacer />
            <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
            <RebrandPrimaryButton
              onClick={updateProfile}
              disabled={pending}
              pending={pending}
            >
              {t('settings:update-button')}
            </RebrandPrimaryButton>
            <Spacer />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Settings;
