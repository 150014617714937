import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import P from "../../components/p";
import Radio from "../../components/radio";
import Spacer from "../../components/spacer";
import { getUserMeta } from "../../api/queries";
import AppState from "../../store/appstate";
import BackButton from "../../components/backButton";
import AuthPrimaryButton from "../../components/authPrimaryButton";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";

function AddAccount4() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();
	const userMeta = getUserMeta();
	const addAccount = AppState.useState((s) => s.addAccount);
	const [pending, setPending] = useState(false);
	const { data: domainConfig } = useDomainConfigAuth();

	if (domainConfig.hide_gig_integrations) {
		history.push("/add-account-6");
	}

	let title = t("onboarding:perform-live-header1");
	let desc = t("onboarding:perform-live-desc1");
	switch (userMeta?.data?.role) {
		case "Manager":
			title = t("onboarding:perform-live-header2");
			desc = t("onboarding:perform-live-desc2");
			break;
		default:
			break;
	}

	return (
		<div className='container mx-auto'>
			<div className='flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer'>
				<h1 className='text-6xl'>{title}</h1>
				<Spacer thin />
				<P>{desc}</P>
				<Spacer />
				<Radio
					name='live'
					placeholder=''
					value={addAccount.live}
					onChange={(e) =>
						AppState.update((s) => {
							s.addAccount.live = e.target.value;
						})
					}
					options={[
						{ label: t("common:yes"), value: true },
						{ label: t("common:no"), value: false },
					]}
				/>
				<Spacer />
				<div className='w-full lg:w-1/3'>
					<div className='flex flex-row items-center justify-evenly'>
						<BackButton onClick={() => history.push("/add-account-3")}>
							{t("onboarding:back-button")}
						</BackButton>
						<AuthPrimaryButton
							disabled={addAccount.live === null}
							pending={pending}
							onClick={() =>
								history.push(
									addAccount.live === "true"
										? "/add-account-5"
										: "/add-account-6"
								) && setPending(true)
							}
							arrow
						>
							{t("onboarding:next-step-button")}
						</AuthPrimaryButton>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddAccount4;
