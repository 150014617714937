/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import { noDp } from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';
import getColours from '../../../../helpers/getColours';

const TabWriters = ({ result }) => {
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const { t } = useTranslation('other');
  const total = result?.body?.aggregations?.WriterPaymentSum?.value || 0;
  const highestWriter = result?.body?.aggregations?.Writers?.buckets?.[0]?.WriterName?.hits?.hits?.[0]?._source;
  const highestWriterTotal = result?.body?.aggregations?.Writers?.buckets?.[0]?.WriterValues?.value;
  const highestAccount = result?.body?.aggregations?.ArtistAccounts?.buckets?.[0]?.ArtistAccountName?.hits?.hits?.[0]?._source;
  const highestAccountTotal = result?.body?.aggregations?.ArtistAccounts?.buckets?.[0]?.WriterValues?.value;
  const { data: siteConfig } = useSiteConfigAuth();
  const { highlightColour2 } = getColours();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  // if (pending || !result) {
  //   return (<LoadingSmall />);
  // }

  return (
    <div className="p-4  h-full flex items-start flex-col">
      <h1 className={`text-md xl:text-xl font-medium ${isTunecore ? 'text-white' : 'text-black'} lg:mb-4`}>{t('other:writers-and-accounts-tab-header')}</h1>
      <div className='w-full font-sentric-alt'>
        <div className="h-14 flex flex-row justify-between w-full items-center ">
          <div style={{color : isTunecore ? '' : highlightColour2}} className='uppercase text-left text-xs font-semibold'>
            {t('other:total-writer')}
            <br />
            {t('other:earnings')}
            :
          </div>
          <div style={{color : isTunecore ? '' : highlightColour2}}  className='uppercase text-left text-base font-semibold'>
            {currencySymbol}
            {noDp(total)}
          </div>

        </div>

        <div className="h-14 flex flex-row justify-between w-full items-center ">
          <div style={{color : isTunecore ? '' : highlightColour2}}  className='text-left text-xs font-semibold'>
            {t('other:highest-earning-writer')}
          </div>
          <div  className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} font-medium text-xs pl-1`}>
            <span>
              {highestWriter?.writerFirstName}
              {' '}
              {highestWriter?.writerMiddleName || ''}
              {' '}
              {highestWriter?.writerLastName}
              {' - '}
              {currencySymbol}
              {noDp(highestWriterTotal)}
            </span>
          </div>

        </div>

        <div className="h-14 flex flex-row justify-between w-full items-center ">
          <div style={{color : isTunecore ? '' : highlightColour2}}  className='uppercase text-left text-xs font-semibold mr-2'>
            {t('other:total-account')}
            <br />
            {t('other:earnings')}
            :
          </div>
          <div style={{color : isTunecore ? '' : highlightColour2}}  className='uppercase text-left text-base font-semibold'>
            {currencySymbol}
            {noDp(total)}
          </div>
        </div>

        <div className="h-14 flex flex-row justify-between w-full items-center ">
          <div style={{color : isTunecore ? '' : highlightColour2}}  className='text-left text-xs font-semibold'>
            {t('other:highest-earning-account')}
          </div>
          <div  className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} font-medium text-xs pl-1`}>
            <span>
              {highestAccount?.artistAccountName}
              {' - '}
              {currencySymbol}
              {noDp(highestAccountTotal)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabWriters;
