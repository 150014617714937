/* eslint-disable no-unreachable */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-mixed-operators */
/* eslint-disable radix */
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState, useEffect } from 'react';
import AppState from '../store/appstate';

export const getApiUrl = () => {
  const ldFlags = useFlags();
  let apiUrl = ldFlags['sa-api-use-old-deployment'] ? import.meta.env.VITE_API_OLD : import.meta.env.VITE_API;
  if (apiUrl === undefined) {
    apiUrl = import.meta.env.VITE_API;
  }
  return apiUrl;
};

export const slugify = (string) => string
  .normalize('NFD') // split an accented letter in the base letter and the acent
  .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
  .toLowerCase()
  .trim()
  .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
  .replace(/\s+/g, '-');

// Our hook
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value],
  );

  return debouncedValue;
};

export const strToBool = (s) => {
  // will match one and only one of the string 'true','1', or 'on' rerardless
  // of capitalization and regardless off surrounding white-space.
  //
  const regex = /^\s*(true|1|on)\s*$/i;

  return regex.test(s);
};

export const titleRegexTest = (s) => {
  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp(
    "^[A-Za-z0-9 !\"#$%&'()*+,\\-./:;<>?=@`{}~£€[\\]^_\\\\]+$",
  );
  return regex.test(s);
};

export const publisherRegexTest = (s) => {
  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp("^[A-Za-z0-9 !#$%&'()+\\-./?@`~]+$");
  return regex.test(s);
};

export const nameRegexTest = (s) => {
  // eslint-disable-next-line no-useless-escape
  const regex = new RegExp("^[A-Za-z0-9 !#$%&'()+\\-./?@`~]+$");
  return regex.test(s);
};

export const logout = () => {
  AppState.update((s) => {
   s.authentication = null;
   s.selectedAccount = null;
  });
  sessionStorage.removeItem('@userData');
};

export const getS3 = async (key) => {
  const apiUrl = AppState.getRawState()?.apiUrl;
  const accessToken = AppState.getRawState()?.authentication?.access_token;
  const response = await fetch(`${apiUrl}/s3/get?Key=${key}`, {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  });
  const file = await response.text();
  return file;
};

export const themeVariables = (domainConfig) => {
  let darkColour = '#000000';
  if (domainConfig?.dark_colour) {
    darkColour = domainConfig?.dark_colour;
  }

  let highlightColour = '#FFFFFF';
  if (domainConfig?.highlight_colour) {
    highlightColour = domainConfig?.highlight_colour;
  }

  let highlightColour2 = '#FFFFFF';
  if (domainConfig?.highlight_colour_2) {
    highlightColour2 = domainConfig?.highlight_colour_2;
  }

  let grad1Colour = '#000000';
  if (domainConfig?.onboarding_background_colour_1) {
    grad1Colour = domainConfig?.onboarding_background_colour_1;
  }

  let grad2Colour = '#000000';
  if (domainConfig?.onboarding_background_colour_2) {
    grad2Colour = domainConfig?.onboarding_background_colour_2;
  }

  return {
    darkColour,
    grad1Colour,
    grad2Colour,
    highlightColour,
    highlightColour2,
  };
};

export const themeVariablesAuth = (domainConfig) => {
  let darkColour = '#000000';
  if (domainConfig?.dark_colour) {
    darkColour = domainConfig?.dark_colour;
  }

  let highlightColour = '#FFFFFF';
  if (domainConfig?.highlight_colour) {
    highlightColour = domainConfig?.highlight_colour;
  }

  let highlightColour2 = '#FFFFFF';
  if (domainConfig?.highlight_colour_2) {
    highlightColour2 = domainConfig?.highlight_colour_2;
  }

  let grad1Colour = '#000000';
  if (domainConfig?.onboarding_background_colour_1) {
    grad1Colour = domainConfig?.onboarding_background_colour_1;
  }

  let grad2Colour = '#000000';
  if (domainConfig?.onboarding_background_colour_2) {
    grad2Colour = domainConfig?.onboarding_background_colour_2;
  }

  return {
    darkColour,
    grad1Colour,
    grad2Colour,
    highlightColour,
    highlightColour2,
  };
};

export const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

export const sendProperty = ({ property, value, email }) => {
  let v = value;
  if (value === 'CURRENT_DATE') {
    v = Date.now();
  }

  // Adding a 10-second delay
  setTimeout(() => {
    if (!AppState.getRawState()?.hubspotDisabled && import.meta.env.VITE_STAGE === 'prd') {
      const obj = {
        email,
        [property]: v,
      };
      console.log(`send property${  property}`);

      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['identify', obj]);
      _hsq.push(['trackPageView']);
    }
  }, 5000);
};

export const sendEvent = ({ property }) => {
  // Adding a 10-second delay
  setTimeout(() => {
    if (!AppState.getRawState()?.hubspotDisabled && import.meta.env.VITE_STAGE === 'prd') {
      const _hsq = (window._hsq = window._hsq || []);
      console.log(`send event${  property}`);
      _hsq.push([
        'trackCustomBehavioralEvent',
        {
          name: property,
        },
      ]);
    }
  }, 10000); // 10000 milliseconds = 10 seconds
};

export const sendStatementInfo = ({ eventName, properties }) => {
  setTimeout(() => {
    if (!AppState.getRawState()?.hubspotDisabled && import.meta.env.VITE_STAGE === 'prd') {
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        'trackCustomBehavioralEvent',
        {
          name: eventName,
          properties,
        },
      ]);
      console.log('send hs event', eventName, properties);
    }
  }, 10000); // Delaying the tracking to not interfere with user experience
};

export const twoDp = (num) => parseFloat(num).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const noDp = (num) => parseFloat(num).toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const buildFilters = (flags, matchAccounts) => {
  const filters = AppState.getRawState()?.royaltiesFilter || [];
  const filterObj = [];
  const searchFilters = [];

  filters.forEach((f) => {
    if (f?.type === 'Account') {
      searchFilters.push({
        match_phrase: {
          artistAccountCode: f.value,
        },
      });
    }
    if (f?.type === 'Song') {
      searchFilters.push({
        match_phrase: {
          songCode: f.value,
        },
      });
    }
    if (f?.type === 'Writer') {
      searchFilters.push({
        match_phrase: {
          writerCode: f.value,
        },
      });
    }
  });

  if (searchFilters.length > 0) {
    filterObj.push({
      bool: {
        should: searchFilters,
        minimum_should_match: 1,
      },
    });
  }
  filterObj.push({
    terms: {
      artistAccountCode: matchAccounts,
    },
  });

  filterObj.push({
    bool: {
      should: [
        {
          bool: { "must_not": [{ exists: { field: "rowHidden" } }] }
        },
        { term: { "rowHidden": { "value": false } } }
      ],
      minimum_should_match: 1,
    },
  });

  const showHistoricRoyalties = flags['show-historic-royalties'];
  if (!showHistoricRoyalties) {
    filterObj.push({
      bool: {
        must_not: {
          terms: {
            distributionUniqueCode: ['HISTORIC'],
          },
        },
      },
    });
  }
  return filterObj;
};

// consistent country names between lowRes map json and sentric data
export const mapMapper = (name) => {
  const n = name.toLowerCase();

  /*
RUSSIAN FEDERATION
CZECH REPUBLIC
VIET NAM
TAIWAN, PROVINCE OF CHINA
MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF
MOLDOVA, REPUBLIC OF
KOREA, REPUBLIC OF
MACAO
AFRICA
TANZANIA, UNITED REPUBLIC OF
SWAZILAND
  */

  if (n === 'RUSSIAN FEDERATION'.toLowerCase()) {
    return 'russia';
  }

  if (n === 'CZECH REPUBLIC'.toLowerCase()) {
    return 'czechia';
  }

  if (n === 'VIET NAM'.toLowerCase()) {
    return 'vietnam';
  }

  if (n === 'TAIWAN, PROVINCE OF CHINA'.toLowerCase()) {
    return 'taiwan';
  }

  if (n === 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF'.toLowerCase()) {
    return 'north macedonia';
  }

  if (n === 'MOLDOVA, REPUBLIC OF'.toLowerCase()) {
    return 'moldova';
  }

  if (n === 'KOREA, REPUBLIC OF'.toLowerCase()) {
    return 'south korea';
  }

  if (n === 'MACAO'.toLowerCase()) {
    return 'macau';
  }

  if (n === 'AFRICA'.toLowerCase()) {
    return 'south africa';
  }

  if (n === 'TANZANIA, UNITED REPUBLIC OF'.toLowerCase()) {
    return 'tanzania';
  }

  if (n === 'SWAZILAND'.toLowerCase()) {
    return 'eswatini';
  }

  return n;
};
