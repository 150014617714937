/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getMyProfile } from "../../../../api/queries";
import H2 from "../../../../components/h2";
import Spacer from "../../../../components/spacer";
import AppState from "../../../../store/appstate";
import StatementsTable from "../../../../components/statementsTable";
import { processKycStatuses } from "../../../../helpers/kycStatus";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function Statements() {
	const history = useHistory();
	const { t } = useTranslation("other");
	const profile = getMyProfile();

	const { data: siteConfig } = useSiteConfigAuth();
	const kyc = AppState.getRawState()?.kyc;
	const { showKyc, showKycPending } = processKycStatuses(kyc);

	const hideRoyalties =
		siteConfig?.disable_statements ||
		profile?.data?.hideroyalties ||
		showKyc ||
		showKycPending;

	useEffect(() => {
		if (hideRoyalties) {
			history.push("/");
		}
	}, [hideRoyalties]);
	return (
		<div className='flex flex-wrap'>
			<div className='w-full lg:min-h-screen-minus-header'>
				<div className='mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12'>
					<H2>{t("other:statements-header")}</H2>
					<Spacer />
					<StatementsTable />
				</div>
			</div>
		</div>
	);
}
export default Statements;
