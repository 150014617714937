/* eslint-disable no-nested-ternary */
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';
import getColours from '../helpers/getColours';
import LoadingSmall from './loadingSmall';


function Select(props) {
  const { t } = useTranslation(['common']);
  const { data: siteConfig } = useSiteConfigAuth();
  const { highlightColour2 } = getColours();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const {
    id, options, value, onChange, placeholder, name, className, search, error, required, open, onBlur = () => {}, reactSelectSearchClass, getOptions, debounce, showLabel = true, list, authSearch, customLabel, blackIcon,
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`relative w-full cursor-pointer ${className} ${required ? 'required-star' : ''} ${required && isTunecore ? 'text-white'
    : ''}`}>
            {showLabel && (
        <label
          className={`pointer-events-none flex text-sm items-center pb-0.5 ${isTunecore ? 'text-white opacity-70' : 'font-sentric-alt font-semibold text-sentric-rebrand-grey-text'}`}
          htmlFor={name}
        >
          {customLabel || (
            options && options.length > 0 ? placeholder : <p className='flex flex-row gap-2 items-center'><LoadingSmall />{t('common:loading')}</p>
          )}
        </label>
      )}
      <div className='flex flex-row gap-2 items-center'>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            borderColor: isHovered || isFocused ? highlightColour2 : undefined,
            boxShadow: isHovered || isFocused  ? `0 0 0 2px ${highlightColour2}` : undefined,
          }}
          className={`rounded-lg w-full ${authSearch && !isTunecore ? 'ring-1 ring-white' : ''} `}
        >
          <SelectSearch
            customLabel={customLabel}
            id={id}
            onChange={onChange}
            blackIcon={blackIcon}
            options={options || []}
            list={list}
            authSearch={authSearch}
            value={value}
            name={name}
            search={search}
            filterOptions={fuzzySearch}
            onFocus={() => setIsFocused(true)}
            placeholder
            getOptions={getOptions}
            autoFocus={open}
            onBlur={(e) => {
              setIsFocused(false);
              setIsHovered(false);
              onBlur(e);
            }}
            debounce={debounce}
            className={reactSelectSearchClass || `select-search cursor-pointer ${isTunecore
                ? "text-black"
                : 'font-sentric-alt font-semibold'}`}
          />
        </div>
        {search && (
          <div className='cursor-default' style={{ position: 'relative', display: 'inline-block' }}>
            <FaSearch className={`${blackIcon ? 'text-black' : authSearch ? 'text-white' : 'text-sentric-rebrand-grey-text'} text-md`}
              style={{
              transition: 'transform 0.15s ease-out, opacity 0.15s ease-out',
              transform: isFocused ? 'scale(1.1)' : 'scale(1)',
              opacity: isFocused ? 1 : 0.7
            }} />
          </div>        
        )}
        {list && (
          <div className={`${blackIcon ? 'text-black' : authSearch ? 'text-white' : 'text-sentric-rebrand-grey-text'} cursor-default transition-all duration-150 ease-linear text-md`}>
            <div style={{ position: 'relative', height: '1em', width: '1em' }}>
              <FaChevronDown style={{ position: 'absolute', transition: 'all 0.15s ease-out', opacity: !isFocused ? 1 : 0, transform: `translateY(${!isFocused ? 0 : -10}px)` }} />
              <FaChevronUp style={{ position: 'absolute', transition: 'all 0.15s ease-out', opacity: isFocused ? 1 : 0, transform: `translateY(${isFocused ? 0 : 10}px)` }} />
            </div>
          </div>
        )}

      </div>
      {!!error
      && <p className="inline-block w-4/5 p-1 pl-3 pr-4 mt-2 text-sm text-white bg-red-700 border border-white rounded-lg">{error}</p>}
    </div>
  );
}

export default Select;
