/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { RiErrorWarningFill } from "react-icons/ri";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import getColours from "../helpers/getColours";

const DateFieldInput = ({
	year,
	month,
	day,
	onDatetimeChange,
	required,
	disabled,
	error,
	placeholderYear,
	placeholderMonth,
	placeholderDay,
}) => {
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour2 } = getColours();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	// Inside your component
	const [isYearFocused, setIsYearFocused] = useState(false);
	const [isMonthFocused, setIsMonthFocused] = useState(false);
	const [isDayFocused, setIsDayFocused] = useState(false);
	const [isYearHovered, setIsYearHovered] = useState(false);
	const [isMonthHovered, setIsMonthHovered] = useState(false);
	const [isDayHovered, setIsDayHovered] = useState(false);

	const focusStyles = {
		borderColor: highlightColour2,
		boxShadow: `0 0 0 2px ${highlightColour2}`,
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		// Update state
		onDatetimeChange(name, value);
	};

	const ref = useRef();
	useEffect(() => {
		if (error) {
			ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
		}
	}, [error]);

	const labelClasses = clsx(
		"pointer-events-none flex text-sm items-center pb-0.5",
		{
			"text-sm leading-normal capitalize text-sentric-rebrand-grey-text font-sentric-alt font-semibold":
				!isTunecore,
			"text-white opacity-70": isTunecore,
		}
	);

	const containerClasses = clsx("relative block flex flex-row w-full gap-3", {
		"required-star": required,
		"rounded-lg bg-gray-100 opacity-60 pointer-events-none": disabled,
		"text-white": required && isTunecore,
		"text-black border-none": isTunecore,
		"rounded-lg font-sentric-alt bg-transparent border": true,
		"font-semibold": !isTunecore,
	});

	const inputClasses = clsx(
		"rounded-lg border-0 focus:outline-none focus:ring-2 w-full text-sm font-semibold p-2 pl-3 pr-3",
		{
			"bg-tunecore-grey-element focus:ring-tunecore-green border-none":
				isTunecore,
			"bg-white text-black placeholder-sentric-rebrand-grey-text": !isTunecore,
		}
	);

	const inputBoxClasses = clsx("font-semibold rounded-md", {
		"bg-tunecore-grey-element text-white": isTunecore,
		"bg-white border border-old-sentric-table-border": !isTunecore,
	});

	return (
		<div ref={ref} className='flex flex-col'>
			<div className={containerClasses}>
				<div className='w-1/3'>
					<label className={labelClasses} htmlFor='year'>
						Year
					</label>
					<div className={inputBoxClasses}>
						<input
							style={isYearFocused || isYearHovered ? focusStyles : {}}
							className={inputClasses}
							type='number'
							id='year'
							name='year'
							placeholder={placeholderYear || "YYYY"}
							value={year}
							onFocus={() => setIsYearFocused(true)}
							onBlur={() => setIsYearFocused(false)}
							onMouseEnter={() => setIsYearHovered(true)}
							onMouseLeave={() => setIsYearHovered(false)}
							onChange={handleInputChange}
							min={new Date().getFullYear() - 100}
							max={new Date().getFullYear()}
						/>
					</div>
				</div>

				<div className='w-1/3'>
					<label className={labelClasses} htmlFor='month'>
						Month
					</label>
					<div className={inputBoxClasses}>
						<input
							style={isMonthFocused || isMonthHovered ? focusStyles : {}}
							className={inputClasses}
							placeholder={placeholderMonth || "MM"}
							type='number'
							name='month'
							value={month}
							onFocus={() => setIsMonthFocused(true)}
							onBlur={() => setIsMonthFocused(false)}
							onMouseEnter={() => setIsMonthHovered(true)}
							onMouseLeave={() => setIsMonthHovered(false)}
							onChange={handleInputChange}
							min={1}
							max={12}
						/>
					</div>
				</div>

				<div className='w-1/3'>
					<label className={labelClasses} htmlFor='day'>
						Day
					</label>
					<div className={inputBoxClasses}>
						<input
							style={isDayFocused || isDayHovered ? focusStyles : {}}
							className={inputClasses}
							placeholder={placeholderDay || "DD"}
							type='number'
							name='day'
							value={day}
							onFocus={() => setIsDayFocused(true)}
							onBlur={() => setIsDayFocused(false)}
							onMouseEnter={() => setIsDayHovered(true)}
							onMouseLeave={() => setIsDayHovered(false)}
							onChange={handleInputChange}
							min={1}
							max={31}
						/>
					</div>
				</div>
			</div>
			{!!error && (
				<p
					className={`w-4/5 ${
						isTunecore
							? "bg-tunecore-error-red bg-opacity-20 border-none"
							: "font-sentric-alt bg-red-700 border-white border"
					} text-sm inline-block p-1 pr-3 pl-3 mt-2 text-white rounded-lg `}
				>
					{isTunecore && (
						<span>
							<RiErrorWarningFill className='inline-block mr-2 text-2xl text-tunecore-error-red' />
						</span>
					)}
					{error}
				</p>
			)}
		</div>
	);
};

export default DateFieldInput;
