/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import H1 from "../../components/h1";
import P from "../../components/p";
import Spacer from "../../components/spacer";
import Button from "../../components/button";
import songkick from "../../images/songkick.png";
import AppState from "../../store/appstate";
import { addArtistAccount, updateArtistAccountMeta } from "../../api/mutations";
import FormError from "../../components/formError";
import { getMyAccounts, getKycStatus } from "../../api/queries";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import { sendEvent } from "../../helpers/helpers";
import { apiCall } from "../../api/shared";
import AuthPrimaryButton from "../../components/authPrimaryButton";

function AddAccountFinal() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();
	const accountData = AppState.useState((s) => s.addAccount);
	const spotifyLinkMethod = AppState.useState((s) => s.spotifyAccountMethod);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
	const [pending, setPending] = useState(false);
	const myAccounts = getMyAccounts();
	const { data: domainConfig } = useDomainConfigAuth();

	const addMutation = addArtistAccount();
	const accountMetaMutation = updateArtistAccountMeta();

	const updateAccountMeta = async (aac) => {
		// update account meta
		const data = {
			account_id: aac,
			facebook_obj: accountData?.facebook,
			spotify_obj: accountData?.spotify,
			songkick_obj: accountData?.songkick,
			twitter_obj: accountData?.twitter,
			bandsintown_obj: accountData?.bandsintown,
			instagram_obj: accountData?.instagram,
		};

		accountMetaMutation.reset();

		accountMetaMutation.mutate(data, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
				setTimeout(() => {
					myAccounts.refetch();
					setTimeout(async () => {
						const kyc = await apiCall({
							endpoint: "/rightsapp/get",
							path: "/api/v1/user/getkyc",
						});
						setPending(false);
						AppState.update((s) => {
							s.addAccount = {
								name: null,
								live: null,
								streaming: null,
								spotify: null,
								twitter: null,
								facebook: null,
								songkick: null,
								bandsintown: null,
								instagram: null,
							};
							s.kyc = kyc;
						});
						if (kyc?.messages[0] === "KYC Required") {
							history.push("/security");
						} else {
							history.push("/");
						}
					}, 2000);
				}, 1000);
			}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};

	const addAccount = async () => {
		setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');

		// add artist account to rights app
		const data = {
			cataloguecode: accountData.cataloguecode || "",
			accountname: accountData.accountName,
			facebookpageurl: accountData.facebook,
			spotifyid: accountData.spotify
				? JSON.parse(accountData.spotify).id
				: null,
			releasedtoserviceorstore: accountData.streaming === "true",
			performedlastsixmonths: accountData.live === "true",
			twitterprofileurl: accountData.twitter
				? `https://twitter.com/${accountData.twitter}`
				: null,
			soundcloudpageurl: null,
		};

		addMutation.reset();

		addMutation.mutate(data, {
			onSuccess: (result, response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
				const aac = result?.model?.artistaccountcode;
				updateAccountMeta(aac);

				}
				if (spotifyLinkMethod === "searchWithName") {
					sendEvent({ property: "pe14489857_spotify_account_name" });
				} else if (spotifyLinkMethod === "searchWithURL") {
					sendEvent({ property: "pe14489857_spotify_account_link" });
				}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};

	return (
		<div className='container mx-auto'>
			<div className='flex flex-col justify-center items-center text-white p-4 text-center min-h-screen-minus-footer'>
				<Spacer />
				<P>{t("onboarding:completed-account-setup")}</P>

				<Spacer />
				<div className='w-1/2'>
					<AuthPrimaryButton
						disabled={pending}
						pending={pending}
						onClick={() => {
							addAccount();
							setPending(true);
						}}
						arrow
					>
						{t("onboarding:go-to-dashboard")}
					</AuthPrimaryButton>
				</div>
				<FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
			</div>
		</div>
	);
}

export default AddAccountFinal;
