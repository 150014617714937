/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable no-else-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { postOpportunity } from "../../../../api/mutations";
import {
	getMyAccount,
	getMyProfile,
	getMyWorkAutoSuggest,
} from "../../../../api/queries";
import FormError from "../../../../components/formError";
import H2 from "../../../../components/h2";
import H4 from "../../../../components/h4";
import MultipleSelect from "../../../../components/multipleSelect";
import Select from "../../../../components/select";
import Spacer from "../../../../components/spacer";
import TextArea from "../../../../components/textArea";
import AppState from "../../../../store/appstate";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useOpportunitiesQuery from "../../../../api/sanity/queries/getOpportunities";

function OpportunitiesApply(props) {
	const { id } = useParams();
	const [opp, setOpp] = useState(id || null);
	const [songList, setSongList] = useState([]);
	const [text, setText] = useState("");
	const [pending, setPending] = useState(false);
	const [mutationErrorMessage, setMutationErrorMessage] = useState("");
	const [validationErrorMessage, setValidationErrorMessage] = useState("");
	const [errors, setErrors] = useState({});
	const profile = getMyProfile();
	const [searchString, setSearchString] = useState("");
	const [options, setOptions] = useState([]);
	const [cachedSongs, setCachedSongs] = useState([]);
	const [noOptionsMessage, setNoOptionsMessage] = useState("Start typing to search...");
	const fetchOptions = useCallback(
		debounce((inputValue) => {
			if (inputValue.length > 0) {
				setSearchString(inputValue);
			}
		}, 500),
		[]
	);
	const handleInputChange = (inputValue) => {
		fetchOptions(inputValue);
	};
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const works = getMyWorkAutoSuggest({
		aac: selectedAccount,
		searchstring: searchString,
	});

	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});
	const userInfo = getMyProfile();
	const createMutation = postOpportunity();

	const history = useHistory();
	const { t } = useTranslation("other");

	useEffect(() => {
		if (works && works?.data?.works) {
			const fetchedOptions = works?.data.works.map((songData) => {
				const songOption = {
					label: `${songData.title} - ${songData.performingartists}`,
					value: songData.workcode,
				};
				return songOption;
			});
			setOptions(fetchedOptions);
			setCachedSongs((prevCachedSongs) => [
				...prevCachedSongs,
				...works?.data?.works,
			]);
			setNoOptionsMessage(
				fetchedOptions.length === 0
					? "No options found"
					: "Start typing to search..."
			);
		}
	}, [works?.data?.works]);

	useEffect(() => {
		if (selectedAccount) {
			works.refetch();
		}
	}, [selectedAccount]);

	const { data: oppsData } = useOpportunitiesQuery();
	console.log("oppsData:", oppsData);

	const checkErrors = () => {
		const errs = {};
		let valid = true;
		if (!opp) {
			errs.opp = t("regex:choose-opportunity-error");
			valid = false;
		}

		// has at least five songs
		if (!songList || songList.length < 5) {
			errs.songList = t("regex:add-five-songs-error");
			valid = false;
		}

		if (songList) {
			songList.forEach((sl) => {
				if (!sl.workcode) {
					errs.songList = t("regex:select-every-song-error");
					valid = false;
				}
			});
		}

		setErrors(errs);
		return valid;
	};

	const submitApplication = () => {
		setPending(true);
		setMutationErrorMessage("");
		setValidationErrorMessage("");
		const valid = checkErrors();

		if (valid) {
			const selectedWorkcodes = songList.map((s) => s.workcode);
			const selectedSongs = selectedWorkcodes.map((workcode) => {
				const song = songList.find((s) => s.workcode === workcode);
				const cachedSong = cachedSongs.find(
					(option) => option.workcode === workcode
				);
				return {
					workcode,
					title: song.title || (cachedSong ? cachedSong.title : ""),
				};
			});

			const data = {
				type: "opportunity",
				songs: selectedSongs ? JSON.stringify(selectedSongs) : null,
				opportunity:
					oppsData && opp
						? oppsData.find((o) => parseInt(o._id) === parseInt(opp))?.name
						: "",
				content: text,
				username: userInfo.data.username,
				email: userInfo.data.email,
				artist: myAccount.data.accountname,
			};

			createMutation.mutate(data, {
				onSuccess: (response) => {
					if (response.failedvalidation) {
						setValidationErrorMessage(response.data.messages);
						setPending(false);
					} else {
						toast.success(t("other:successfully-submitted-opportunity-toast"), {
							position: "bottom-right",
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						history.push("/opportunities");
						setPending(false);
						sendEvent({ property: "pe14489857_opportunities_applied_for" });
					}
				},
				onError: (error) => {
					setMutationErrorMessage(error.message);
					setPending(false);
				},
			});
		} else {
			setPending(false);
		}
	};

	return (
		<>
			<div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
				<H2>{t("other:apply")}</H2>
				<Spacer />
				<div className='w-full lg:w-2/3'>
					<H4>{t("other:which-opportunity-header")}</H4>
					<Spacer thin />
					{	oppsData &&
						<Select
							id='selectOpportunity'
							list
							required
							customLabel={t("other:select-opportunity-placeholder")}
							className='select-search font-sentric-alt text-base'
							name='opp'
							placeholder={t("other:select-opportunity-placeholder")}
							value={opp}
							error={errors.opp}
							onChange={(selected) => setOpp(selected)}
							options={oppsData.map((o) => ({
								label: o.name,
								value: o._id,
							}))}
						/>
					}
					<Spacer />
					<H4>{t("other:select-five-songs-header")}</H4>
					<Spacer thin />
					<MultipleSelect
						id='selectOpportunityAddSong'
						type='text'
						customLabel={t("other:select-a-song-placeholder")}
						valueKey='workcode'
						value={songList}
						onChange={(arr) => {
							setSongList(arr);
						}}
						error={errors.songList}
						options={options}
						onInputChange={handleInputChange}
						noOptionsMessage={noOptionsMessage}
					/>
					<Spacer />
					<H4>{t("other:tell-us-more-header")}</H4>

					<Spacer thin />
					<TextArea
						id='txtOpportunityEvidence'
						className='w-full'
						type='textarea'
						error={errors.durationsecs}
						placeholder={t("other:supporting-text-placeholder")}
						value={text}
						onBlur={() => {}}
						onChange={(e) => {
							setText(e.target.value);
						}}
					/>

					<Spacer />
					<FormError
						mutationError={mutationErrorMessage}
						validationError={validationErrorMessage}
					/>
					<div className='w-full flex justify-end'>
						<RebrandPrimaryButton
							id='btnOpportunitySubmit'
							pending={pending}
							onClick={submitApplication}
							arrow
						>
							{t("common:submit-button")}
						</RebrandPrimaryButton>
					</div>
					<Spacer />
				</div>
			</div>
		</>
	);
}
export default OpportunitiesApply;
