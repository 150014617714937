function Spacer(props) {
  const { thin } = props;

  const height = thin ? 'h-4' : 'h-8';

  return (
    <div className={`w-full ${height}`} />
  );
}

export default Spacer;
