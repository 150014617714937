/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { getMySongsDetailed } from '../../../../api/queries';
import Table from '../../../../components/table';
import { strToBool } from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';
import getColours from '../../../../helpers/getColours';

// Our table component
function WritersSongsTable({
  highlightColour, writercode,
}) {
  const { t } = useTranslation(['writers', 'songs', 'other']);
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const [data, setData] = useState([]);
  const songs = getMySongsDetailed({ aac: selectedAccount });
  const { data: siteConfig } = useSiteConfigAuth();
  const { statusSuccessColour } = getColours();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const designationCodes = [
    { name: t('songs:author-option'), value: 1 },
    { name: t('songs:composer-option'), value: 2 },
    { name: t('songs:both-option'), value: 3 },
  ];

  useMemo(() => {
    if (songs.isSuccess) {
      const objArray = [];

      songs.data.works.forEach((w) => {
        const writerSplit = w.writersplits.find((s) => s.writercode === writercode);

        if (!writerSplit) {
          return;
        }

        // if writer is on the work

        const obj = {
          title: w.title,
          split: writerSplit.writershare,
          righttocollect: writerSplit.righttocollect,
          designation: designationCodes.find((d) => d.value === writerSplit.writerdesignationcode)?.name,
        };

        objArray.push(obj);
      });

      setData(objArray);
    }
  }, [songs.isSuccess]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('writers:_title'),
        accessor: 'title',
        width: '25%',
      },
      {
        Header: t('writers:writer-split'),
        accessor: 'split',
        width: '25%',
        Cell: ({ cell }) => (
          <div>
            {cell.row.values.split}

            %
          </div>
        ),
      },
      {
        Header: t('writers:writer-controlled'),
        accessor: 'righttocollect',
        width: '25%',
        Cell: ({ cell }) => (
          <div>
            {strToBool(cell.row.values.righttocollect) ? (
                <span
                  style={{ color: isTunecore ? '#00ef86' : statusSuccessColour }}
                  >
                  <FaCheck className="inline mr-2" />
                  {t('other:right-to-collect')}
                </span>
              ) : (
                <span
                  className={`${isTunecore ? 'text-tunecore-error-red' : 'text-red-600'}`}
                  >
                  <FaTimes className="inline mr-2" />
                  {t('other:no-right-to-collect')}
                </span>
              )
            }
          </div>
        ),
      },
      {
        Header: t('writers:designation-code'),
        accessor: 'designation',
        width: '25%',
      },
    ],
    [songs.isSuccess],
  );

  return (
    <>
      <Table
        data={data}
        columns={columns}
        highlightColour={highlightColour}
        revealComponent={null}
        searchSuffix={t('common:songs')}
        disableSearch
        title={t('writers:songs-written-header')}
        bgClass={`${isTunecore ? 'bg-tunecore-black' : 'bg-white'} rounded-lg p-0`}
      />
    </>

  );
}

export default WritersSongsTable;
