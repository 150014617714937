/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState } from "react";
import { FaSearch, FaTwitter } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import H1 from "../../components/h1";
import P from "../../components/p";
import Spacer from "../../components/spacer";
import Input from "../../components/input";
import AppState from "../../store/appstate";
import { getUserMeta } from "../../api/queries";
import Select from "../../components/select";
import AuthPrimaryButton from "../../components/authPrimaryButton";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import getColours from "../../helpers/getColours";

function AddTwitter() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();
	const apiUrl = AppState.getRawState()?.apiUrl;
	// const twitterResult = AppState.useState((s) => s.addAccount.twitter);
	const [pending, setPending] = useState(false);
	const { data: domainConfig } = useDomainConfigAuth();
	const isTunecore = domainConfig?.display_name === "TuneCore";
	const { onboardingHighlightColour } = getColours(domainConfig);

	const userMeta = getUserMeta();

	let title = t("onboarding:add-twitter-header1");
	let desc = t("onboarding:add-twitter-desc1");

	switch (userMeta?.data?.role) {
		case "Manager":
			title = t("onboarding:add-twitter-header2");
			desc = t("onboarding:add-twitter-desc2");

			break;
		default:
			break;
	}

	const getSelectOptions = (query) =>
		new Promise((resolve, reject) => {
			if (!query) {
				resolve([]);
				return;
			}
			fetch(`${apiUrl}/twitter?s=${query.replace("@", "")}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((response) => response.json())
				.then((user) => {
					if (user.errors) {
						reject(user.errors);
					}
					resolve([
						{
							value: JSON.stringify(user),
							name: user.data.name,
							photo: user.data.profile_image_url,
						},
					]);
				})
				.catch(reject);
		});

	const addAccount = AppState.useState((s) => s.addAccount);
	const show = false;

	return (
		<div className='container mx-auto'>
			<div className='flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer'>
				<p className='text-6xl text-white'>
					<FaTwitter />
				</p>

				<Spacer />
				<>
					<H1>{title}</H1>
					<Spacer />
					<P>{desc}</P>
					<Spacer />
					{show && (
						<div className='relative flex flex-col items-center justify-center w-full'>
							<div className='w-full lg:w-1/3'>
								<div className='flex flex-row items-start justify-center w-full text-old-sentric-dark-gray'>
									<Select
										className={` custom-select no-dropdown ${
											isTunecore ? "tunecore-select tunecore-input-hover" : ""
										} `}
										debounce={1000}
										onChange={(artist) => {
											AppState.update((s) => {
												s.addAccount.twitter = artist;
											});
										}}
										getOptions={getSelectOptions}
										placeholder='@'
										search
									/>
									<div
										className='text-xl ml-4 text-black rounded-full p-4 -mt-1'
										style={{ backgroundColor: onboardingHighlightColour }}
									>
										<FaSearch />
									</div>
								</div>
							</div>
						</div>
					)}
					<div className='relative flex flex-col items-center justify-center w-full text-old-sentric-dark-gray'>
						<div
							className={`w-full lg:w-1/3  ${
								isTunecore ? "tunecore-input-hover" : ""
							}`}
						>
							<Input
								className={` auth-page-input${
									isTunecore ? "auth-tunecore-page-input" : ""
								}`}
								placeholder='@username'
								whiteLabelText
								value={addAccount.twitter}
								onChange={(e) =>
									AppState.update((s) => {
										s.addAccount.twitter = e.target.value;
									})
								}
							/>
						</div>
					</div>
				</>

				{/* {!!twitterResult
            && (
              <>
                <H1>{t('onboarding:selected-twitter-profile')}</H1>
                <Spacer />
                <div className="flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg text-old-sentric-dark-gray">
                  {JSON.parse(twitterResult).data.profile_image_url && JSON.parse(twitterResult).data.profile_image_url.length > 0
                    && (
                    <img
                      className="object-cover w-20 h-20 mb-4 rounded-full"
                      alt={JSON.parse(twitterResult).data.name}
                      src={JSON.parse(twitterResult).data.profile_image_url}
                    />
                    )}
                  {(!JSON.parse(twitterResult).data.profile_image_url)
                    && <div className="w-20 h-20 mb-4 bg-gray-300 rounded-full" />}
                  <p className="mb-4 font-bold">{JSON.parse(twitterResult).data.name}</p>
                  <SmallButton onClick={() => { AppState.update((s) => { s.addAccount.twitter = null; }); }}>{t('common:remove')}</SmallButton>
                </div>

              </>
            )} */}

				<Spacer />

				<div className='flex flex-row justify-center w-full lg:w-1/3'>
					<AuthPrimaryButton
						pending={pending}
						onClick={() => history.push("/add-account-6") && setPending(true)}
						arrow
					>
						{t("common:_done")}
					</AuthPrimaryButton>
				</div>
			</div>
		</div>
	);
}

export default AddTwitter;
