import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";

function MaintenanceMode() {
  const { t } = useTranslation(['other', 'security']);
	const { data: domainConfig } = useDomainConfigAuth();
  const supportEmail = domainConfig?.support_email;
	const isTunecore = domainConfig?.display_name === "TuneCore";
  return (
    <ErrorBoundary
      fallback={({ error, componentStack }) => (
        <>
          <div>You have encountered an error</div>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
        </>
      )}
    >
        <div className='h-full py-10 justify-center flex gap-10 flex-col lg:flex-row-reverse items-center w-full px-10 lg:pt-32 lg:px-28'>
          <img
            className='w-52 invert'
            src='https://static.thenounproject.com/png/1659519-200.png'
            alt='broken disc'
          />
          <div className=' flex flex-col gap-5 w-fit md:w-4/5 items-center text-center lg:text-left lg:items-start'>
            <h1 className='text-xl font-extrabold'>
              {t('other:maintenance-mode1')}
            </h1>
            <p>{t('other:maintenance-mode2')}</p>
            <p>{t("security:error-page-pg-2")}{" "}
            {!isTunecore ? (
						<a
							className='underline'
							href={
								supportEmail
									? `mailto:${domainConfig.support_email}`
									: "mailto:support@sentricmusic.com"
							}
						>
							{supportEmail
								? domainConfig.support_email
								: "support@sentricmusic.com"}
						</a>
					) : (
						<a
							className='underline'
							target='_blank'
							href='https://support.tunecore.com/hc/en-us/requests/new?ticket_form_id=662748'
							rel='noreferrer'
						>
							tunecore.com/support
						</a>
					)}</p>
          </div>
        </div>
    </ErrorBoundary>
  );
}

export default MaintenanceMode;
