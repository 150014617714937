import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserMeta } from '../../api/queries';
import P from '../../components/p';
import Spacer from '../../components/spacer';
import AppState from '../../store/appstate';
import AuthPrimaryButton from '../../components/authPrimaryButton';

function ResetPasswordSuccess() {
  const { t } = useTranslation();
  const history = useHistory();
  const username = AppState.useState((s) => s?.authentication.userName);
  const userMeta = getUserMeta();
  const [pending, setPending] = useState(false);

  const goDashboard = () => {
    setPending(true);
    userMeta.refetch();
    setTimeout(() => {
      history.push('/');
    }, 3000);
  };

  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center min-h-screen-minus-footer p-4 text-center">
          <h1 className="text-6xl">{t('auth:password-reset-success-header')}</h1>
          <div className="w-full lg:w-2/3 text-white">
            <Spacer />
            <P>
              {t('auth:your-username')}
              {' '}
              <b>{username}</b>
            </P>
            <Spacer thin />
            <P>
              {t('auth:use-this-login-button')}
            </P>
            <Spacer />
            <AuthPrimaryButton
              disabled={pending}
              pending={pending}
              onClick={goDashboard}
              >
              {t('common:login')}
            </AuthPrimaryButton>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ResetPasswordSuccess;
