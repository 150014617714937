/* eslint-disable no-nested-ternary */
import getColours from "../helpers/getColours";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";

function SmallButton(props) {
	const { children, onClick, disabled } = props;
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour } = getColours(siteConfig);
	const isTunecore = siteConfig?.display_name === "TuneCore";

	return (
		<>
			<button
				disabled={disabled}
				type='button'
				style={
					isTunecore
						? {
								background: disabled
									? "linear-gradient(to bottom right, rgba(0, 239, 134, 0.5), rgba(0, 142, 185, 0.5))"
									: "linear-gradient(to bottom right, #00ef86, #008eb9)",
								borderRadius: "50px",
								padding: "10px 16px",
            }
						: {
								backgroundColor: highlightColour,
								opacity: disabled ? "0.5" : "1",
            }
				}
				className='disabled:pointer-events-none text-white active:bg-old-sentric-dark-gray font-bold uppercase text-xs px-4 py-2 rounded-lg shadow hover:scale-105 hover:shadow-md disabled:shadow-none outline-none focus:outline-none mb-1 ease-linear transition-all duration-150'
				onClick={onClick}
			>
				{children}
			</button>
		</>
	);
}

export default SmallButton;
