import { useTranslation } from "react-i18next";
import getColours from "../helpers/getColours";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";

function TablePagination({
	pageSize,
	setPageSize,
	pageIndex,
	totalPages,
	previousPage,
	canPreviousPage,
	nextPage,
	canNextPage,
	area,
}) {
	const { t } = useTranslation();
	const { highlightColour } = getColours();
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	return (
		<>
			<div
				className={`flex flex-wrap justify-end items-center pl-0 list-none my-2 mt-5 text-sm gap-4 ${
					totalPages <= 1 && pageSize >= 5 && totalPages * pageSize <= 5
						? "hidden"
						: ""
				}`}
			>
				<div className='w-full lg:w-auto flex justify-end'>
					<select
						id={`btn${area}DisplayCount`}
						className='text-black relative block rounded-lg border-0 bg-sentric-rebrand-light-grey py-1 pr-7 focus:outline-none text-sm'
						value={pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value));
						}}
					>
						{[5, 10, 20, 50, 100, 250].map((pSize) => (
							<option key={pSize} value={pSize}>
								{t("common:show-pagination")} {pSize}
							</option>
						))}
					</select>
				</div>
				<div className='w-full flex lg:w-auto justify-end'>
					<span className='relative block'>
						{t("common:page-pagination")} {pageIndex + 1}{" "}
						{t("common:of-pagination")} {totalPages}
					</span>
				</div>

				<div className='w-full lg:w-auto flex justify-end'>
					<button
						id={`btn${area}PreviousPage`}
						type='button'
						className='mr-2'
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						{!canPreviousPage ? (
							<svg
								width='29'
								height='23'
								viewBox='0 0 29 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M10.6312 -1.60585e-06L12.5709 1.64505L3.6038 10.2483L29 10.2483L29 12.7414L3.6144 12.7414L12.5709 21.355L10.6312 23L8.95938e-07 12.7517L1.11389e-06 10.2585L10.6312 0.0102161L10.6312 -1.60585e-06Z'
									fill={`${isTunecore ? "#FFFFFF" : highlightColour}`}
									fillOpacity='0.3'
								/>
							</svg>
						) : (
							<svg
								width='29'
								height='23'
								viewBox='0 0 29 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M10.6312 -1.60585e-06L12.5709 1.64505L3.6038 10.2483L29 10.2483L29 12.7414L3.6144 12.7414L12.5709 21.355L10.6312 23L8.95938e-07 12.7517L1.11389e-06 10.2585L10.6312 0.0102161L10.6312 -1.60585e-06Z'
									fill={`${isTunecore ? "#FFFFFF" : highlightColour}`}
								/>
							</svg>
						)}
					</button>{" "}
					<button
						id={`btn${area}NextPage`}
						type='button'
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						{canNextPage ? (
							<svg
								width='29'
								height='23'
								viewBox='0 0 29 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M18.3688 23L16.4291 21.355L25.3962 12.7517H0V10.2586H25.3856L16.4291 1.64505L18.3688 0L29 10.2483V12.7414L18.3688 22.9898V23Z'
									fill={`${isTunecore ? "#FFFFFF" : highlightColour}`}
								/>
							</svg>
						) : (
							<svg
								width='29'
								height='23'
								viewBox='0 0 29 23'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M18.3688 23L16.4291 21.355L25.3962 12.7517H0V10.2586H25.3856L16.4291 1.64505L18.3688 0L29 10.2483V12.7414L18.3688 22.9898V23Z'
									fill={`${isTunecore ? "#FFFFFF" : highlightColour}`}
									fillOpacity='0.3'
								/>
							</svg>
						)}
					</button>
				</div>
			</div>
		</>
	);
}

export default TablePagination;
