import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spacer from "../../components/spacer";
import P from "../../components/p";
import BackButton from "../../components/backButton";
import AuthPrimaryButton from "../../components/authPrimaryButton";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";

function AddAccountPre() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();
	const { data: domainConfig } = useDomainConfigAuth();
	const supportEmail = domainConfig?.support_email;
	const isTunecore = domainConfig?.display_name === "TuneCore";
	return (
		<div className='container mx-auto'>
			<div className='flex flex-col justify-center items-center text-white p-4 text-center min-h-screen-minus-footer'>
				<div className='w-full lg:w-1/2'>
					<h1 className='text-6xl'>{t("onboarding:add-new-account-header")}</h1>
					<Spacer />
					<P>{t("onboarding:add-new-account-text1")}</P>
					<Spacer slim />
					<P>
						{t("onboarding:add-new-account-text2")}{" "}
						{!isTunecore ? (
							<a
								className='underline'
								href={
									supportEmail
										? `mailto:${domainConfig.support_email}`
										: "mailto:support@sentricmusic.com"
								}
							>
								{supportEmail
									? domainConfig.support_email
									: "support@sentricmusic.com"}
							</a>
						) : (
							<a
								className='underline'
								target='_blank'
								href='https://support.tunecore.com/hc/en-us/requests/new?ticket_form_id=662748'
								rel='noreferrer'
							>
								tunecore.com/support
							</a>
						)}
						.
					</P>
					<Spacer />
				</div>
				<div className='flex flex-row w-full lg:w-1/3 justify-evenly'>
					<BackButton onClick={() => history.goBack()}>
						{t("onboarding:back-button")}
					</BackButton>
					<AuthPrimaryButton
						onClick={() => history.push("/add-account-1")}
						arrow
					>
						{t("onboarding:next-step-button")}
					</AuthPrimaryButton>
				</div>
			</div>
		</div>
	);
}

export default AddAccountPre;
