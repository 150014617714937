/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import getColours from "../helpers/getColours";

import Spacer from "./spacer";
import RebrandButtonLink from "./rebrandButtonLink";

function MultipleSelect(props) {
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour2 } = getColours();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const { t } = useTranslation();

	const {
		value,
		onChange,
		valueKey,
		placeholder,
		required,
		name,
		error,
		showLabel = true,
		className,
		options,
		customLabel,
		onInputChange,
	} = props;

	const [isHovered, setIsHovered] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	const customStyles = {
		control: (provided) => ({
			...provided,
			border: "none",
			boxShadow: "none",
			borderRadius: "0.5rem",
			cursor: "pointer",
			fontSize: "14px",
			width: "100%",
			minWidth: "100%",
			"&:hover": { borderColor: "none" },
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: "white",
			width: "100%",
			minWidth: "100%",
		}),
		container: (provided) => ({
			...provided,
			width: "100%",
			minWidth: "100%",
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? "#d9d9d9" : "white",
			cursor: "pointer",
		}),
	};

	const customComponents = {
		DropdownIndicator: null,
		IndicatorSeparator: null,
	};

	const [items, setItems] = useState([]);

	useEffect(() => {
		if (value) {
			const itemArray = value.map((v) => ({
				...v,
				id: v.id || uuidv4(),
				label:
					v.label ||
					options.find((option) => option.value === v[valueKey])?.label,
			}));
			setItems(itemArray);
		}
	}, [value, options, valueKey]);

	const updateOnChange = (newItems) => {
		setItems(newItems);
		const copy = JSON.parse(JSON.stringify(newItems));
		copy.forEach((item) => {
			delete item.id;
		});
		onChange(copy);
	};

	const onChangeItem = (selectedOption) => {
		if (selectedOption) {
			const newItems = [
				...items,
				{
					id: uuidv4(),
					[valueKey]: selectedOption.value,
					label: selectedOption.label,
				},
			];
			updateOnChange(newItems);
		}
	};

	const remove = (id) => {
		const newItems = items.filter((n) => n.id !== id);
		updateOnChange(newItems);
	};

	return (
		<div
			className={`relative w-full cursor-pointer ${className} ${
				required ? "required-star" : ""
			} ${required && isTunecore ? "text-white" : ""}`}
		>
			{showLabel && (
				<label
					className={`pointer-events-none flex text-sm items-center pb-0.5 ${
						isTunecore
							? "text-white opacity-70"
							: "font-sentric-alt font-semibold text-sentric-rebrand-grey-text"
					}`}
					htmlFor={name}
				>
					{customLabel || placeholder}
				</label>
			)}

			<div className='flex flex-row gap-2 items-center'>
				<div
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					style={{
						borderColor: isHovered || isFocused ? highlightColour2 : undefined,
						boxShadow:
							isHovered || isFocused
								? `0 0 0 2px ${highlightColour2}`
								: undefined,
					}}
					className={`rounded-lg w-full`}
				>
					<Select
						required
						id={`btn${name}`}
						name={name}
						placeholder={null}
						value={null}
						onChange={onChangeItem}
						options={options.filter(
							(o) => !items.map((j) => j[valueKey]).includes(o.value)
						)}
						noOptionsMessage={() => "Type to search..."}
						onInputChange={onInputChange}
						isSearchable
						styles={customStyles}
						components={customComponents}
						className={`select-search cursor-pointer font-sentric-alt ${
							isTunecore ? "text-black" : "font-sentric-alt font-semibold"
						}`}
					/>
				</div>

				<div
					className='cursor-default'
					style={{ position: "relative", display: "inline-block" }}
				>
					<FaSearch
						className='text-sentric-rebrand-grey-text text-md'
						style={{
							transition: "transform 0.15s ease-out, opacity 0.15s ease-out",
							transform: isFocused ? "scale(1.1)" : "scale(1)",
							opacity: isFocused ? 1 : 0.7,
						}}
					/>
				</div>
			</div>

			<div className='flex flex-col gap-2 mt-4 items-center'>
				{items.map((v) => (
					<div
						className={`${isTunecore ? 'bg-tunecore-grey-element' : 'bg-white'} rounded-lg self-start w-full font-sentric-alt flex items-center justify-between p-2 border`}
						key={`item${v.id}`}
					>
						<span className="font-semibold">{v.label}</span>
						<RebrandButtonLink
							onClick={() => remove(v.id)}
							className='items-center h-4 underline'
						>
							<span className='ml-2'>{t("common:remove")}</span>
						</RebrandButtonLink>
					</div>
				))}
			</div>

			{!!error && (
				<p className='inline-block w-4/5 p-1 pl-3 pr-3 mt-2 text-sm text-white bg-red-700 border border-white rounded-lg'>
					{error}
				</p>
			)}
		</div>
	);
}

export default MultipleSelect;
