import { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';

import AppState from '../../store/appstate';
import ldDefaultUser from './ldDefaultUser';

function LdSetup({ ldClient }) {
  const authentication = AppState.useState((s) => s?.authentication);

  const isAuthed = !isEmpty(authentication);
  const usernameToSet = !isAuthed ? '' : authentication.userName;
  const [userName, setUserName] = useState('');
  useEffect(() => {
    if (ldClient
      && userName !== usernameToSet) {
      const context = !isAuthed ? ldDefaultUser :{
        kind: 'multi',
        user: {
          key: authentication.userSegmentKey,
        },
        tenant: {
          key: authentication.tenantPrefixCode
        },
        domain:{
          key: window.location.hostname
        }
      };
      ldClient.identify(context);
      setUserName(usernameToSet);
    }
  });

  return (<></>);
}

export default withLDConsumer()(LdSetup);
