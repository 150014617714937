/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PortableText } from "@portabletext/react";
import LoadingSmall from "./loadingSmall";
import AppState from "../store/appstate";
import RebrandPrimaryButton from "./rebrandPrimaryButton";
import { getMyAccounts } from "../api/queries";
import ChooseAccountModal from "./ChooseAccountModal";
import useSyncsQuery from "../api/sanity/queries/getSyncs";
import useOpportunitiesQuery from "../api/sanity/queries/getOpportunities";
import getColours from "../helpers/getColours";
// import urlFor from '../api/sanity/sanityImageUrl';

function DashboardOpportunitiesBlock() {
	const { data: syncsData, isFetching, isSuccess } = useSyncsQuery(); // sanity
	const { data: oppsData } = useOpportunitiesQuery(); // sanity
	const { t } = useTranslation("dashboard");
	const myAccounts = getMyAccounts();
	const { highlightColour } = getColours();

	const [addOpportunityModal, setAddOpportunityModal] = useState(false);
	const [addOportunityUrl, setAddOportunityUrl] = useState(null);

	const allSyncsAvailable = AppState.getRawState()?.allSyncsAvailable;
	const allOpportunitiesAvailable =
		AppState.getRawState()?.allOpportunitiesAvailable;
	let syncs = [];
	let opps = [];
	// Conditionally set syncs based on allSyncsAvailable
	if (allSyncsAvailable) {
		syncs = syncsData;
	} else {
		syncs = syncsData?.syncs_available;
	}

	// Conditionally set opps based on allOpportunitiesAvailable
	if (allOpportunitiesAvailable) {
		opps = oppsData;
	} else {
		opps = oppsData?.opportunities_available;
	}

	return (
		<>
			{!!addOpportunityModal && addOportunityUrl && (
				<ChooseAccountModal
					accounts={myAccounts?.data?.artistaccounts}
					title={t("dashboard:choose-account-modal-header")}
					to={addOportunityUrl}
					nonAdminTo='/opportunities/'
					setAccountChoiceModal={setAddOpportunityModal}
				/>
			)}
			<div className='lg:grid lg:grid-cols-3 gap-6 flex flex-col'>
				{isFetching && <LoadingSmall />}
				{isSuccess ? (
					opps || syncs ? (
						[...(opps || []).slice(0, 2), ...(syncs || []).slice(0, 4)].map(
							(item) => (
								<React.Fragment key={item._id}>
									<div className='w-full col-span-1 lg:mb-0 flex flex-col'>
										<div className='relative'>
											<img
												src={item.imageUrl}
												alt={item.altText}
												className='w-full object-cover rounded-b-lg min-h-32 md:min-h-70 max-h-48 object-center rounded-t-lg'
											/>
											<span
												style={{ backgroundColor: highlightColour }}
												className='absolute top-0 left-0 w-max text-sm text-white post-type-tag bg-opacity-80 px-2 py-1 rounded-tl-lg rounded-br-lg'
											>
												{item._type === "syncs" ? (
													<p>{t("sync-tag")}</p>
												) : item._type === "opportunities" ? (
													<p>{t("news-tag")}</p>
												) : null}
											</span>
										</div>
										<div
											className='text-left p-8 rounded-b-lg flex flex-col flex-auto gap-2 justify-between'
											style={{ backgroundColor: "#ffffff" }}
										>
											<h2 className='flex-grow line-clamp-2'>{item.name}</h2>
											<div
												style={{ color: "#868484" }}
												className='text-sm font-medium font-sentric-alt line-clamp-3 dashboard-html-cards'
											>
												<PortableText value={item.description} />
											</div>
											<div className='flex w-full justify-start mt-2'>
												<RebrandPrimaryButton
													onClick={() => {
														if (item._type === "opportunities") {
															setAddOportunityUrl("/opportunities");
														} else {
															setAddOportunityUrl("/syncs");
														}
														setAddOpportunityModal(true);
													}}
													arrow
												>
													{t("dashboard:opportunities-find-out-more")}
												</RebrandPrimaryButton>
											</div>
										</div>
									</div>
								</React.Fragment>
							)
						)
					) : (
						<div>
							<p>{t("other:no-opportunities")}</p>
						</div>
					)
				) : null}
			</div>
		</>
	);
}

export default DashboardOpportunitiesBlock;
