import ReactTooltip from 'react-tooltip';
import {
  FaInfoCircle,
} from 'react-icons/fa';
import RequiredStar from './requiredStar';
import { slugify } from '../helpers/helpers';
import getColours from '../helpers/getColours';
import Spacer from './spacer';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function InnerRadio(props) {
  const {
    options, value, onChange, label, name, error, required, infoTooltip,
  } = props;
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const textColor = isTunecore ? 'black' : 'white';
  const { highlightColour } = getColours();
  const backgroundColor = isTunecore ? '#00ef86' : highlightColour;
  // console.log('infoTooltip', infoTooltip);
  return (
    <div>
      {label && (
        <>
          <h3 className={`text-sm ${isTunecore ? 'text-white' : 'text-black'} `}>
            <div className='flex items-center'>
              {label}
              {required && (
                <RequiredStar customStyles={{ height: 8, marginLeft: 4 }} />
              )}
              <span>
                {infoTooltip && (
                  <div className="flex items-center">
                    <i data-tip data-for={`tooltip-${name}`} type="button">
                      <FaInfoCircle className="ml-2" />
                    </i>
                    <ReactTooltip id={`tooltip-${name}`} className="max-w-md" place="right" type="info" effect="solid" multiline="true" textColor={textColor} backgroundColor={backgroundColor}>
                      {infoTooltip}
                    </ReactTooltip>
                  </div>
                )}
              </span>
            </div>
          </h3>
          <Spacer thin />
        </>
      )}

      <div className="flex flex-row">
        {options.map((o) => (
          <div className={`${isTunecore ? 'text-white' : 'text-black'}`} key={slugify(`${o.label}${name}`)}>
            <input 
              style={{ accentColor: highlightColour }}
              onChange={onChange}
              className={`${isTunecore ? 'text-tunecore-blue' : 'text-black'}`}
              id={slugify(`${o.label}${name}`)}
              type="radio"
              name={name}
              value={o.value}
              defaultChecked={value !== null && o.value === value}
            />
            <label className='p-2 font-sentric-alt' htmlFor={slugify(`${o.label}${name}`)}>
              {o.label}
            </label>
          </div>
        ))}
      </div>
      {!!error && (
        <p className={`w-4/5  text-sm inline-block p-1 pr-3 pl-3 mt-2 text-white rounded-lg  ${isTunecore ? 'bg-tunecore-red' : 'bg-red-700 border-white border'}`}>
          {error}
        </p>
      )}
    </div>
  );
}

export default InnerRadio;
