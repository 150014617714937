/* eslint-disable no-console */
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiCall } from "../../api/shared";
import AppState from "../../store/appstate";
import Input from "../../components/input";
import Spacer from "../../components/spacer";
import Alert from "../../components/alert";
import ButtonLink from "../../components/buttonLink";
import Loading from "../../layouts/loading";

import useSiteConfigUnAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import AuthPrimaryButton from "../../components/authPrimaryButton";

function Login() {
	const { data: siteConfig, isSuccess: domainConfigIsSuccess } =
		useSiteConfigUnAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const [kycObj, setKycObj] = useState(null);
	const { t } = useTranslation();
	const location = useLocation();
	const [password1, setPassword1] = useState("");
	const [username, setUsername] = useState("");
	const [valid, setValid] = useState(false);
	const [pending, setPending] = useState(false);
	const [error, setError] = useState(null);
	const usernameRegex = new RegExp(
		"^(?=[a-zA-Z0-9._-]{6,24}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$"
	);
	const { redirectUrl } = location.state || { redirectUrl: "/" };
	const history = useHistory();
	const accessToken = AppState.getRawState()?.authentication?.access_token;
	const login = async () => {
		setPending(true);
		setError(null);
		const apiUrl = AppState.getRawState()?.apiUrl;
		const details = await fetch(`${apiUrl}/rightsapp/login`, {
			method: "POST",
			body: JSON.stringify({
				username,
				password: password1,
			}),
		})
			.then((res) => res.json())
			.catch((e) => {
				setError(`${e.name}: ${e.message}`);
			});

		if (details?.access_token) {
			// get artist accounts
			details.renewal_token = btoa(password1);
			AppState.update((s) => {
				s.authentication = details;
			});
			return;
		}
		if (details?.error) {
			setError(details.error_description);
		}
		setPending(false);
	};

	const checkValid = (passed = {}) => {
		let val = true;

		// password check
		const pass = passed.password1 || password1;
		const validPassword = pass.trim().length > 6;
		if (!validPassword) {
			val = false;
		}

		// username check
		const user = passed.username || username;
		const validUsername = user.trim().length > 5;
		if (!validUsername) {
			val = false;
		}

		// user regex check
		if (!usernameRegex.test(user)) {
			val = false;
			setError(t("regex:login-username-regex-error"));
		}

		setValid(val);
	};

	useEffect(() => {
		const fetchKycStatus = async () => {
			const kyc = await apiCall({
				endpoint: "/rightsapp/get",
				path: "/api/v1/user/getkyc",
			});
			AppState.update((s) => {
				s.kyc = kyc;
			});
			setKycObj(kyc);
		};
		if (accessToken) {
			fetchKycStatus();
		}
	}, [accessToken]);

	useEffect(() => {
		if (kycObj) {
			const showSecurity =
				kycObj.kycrequired &&
				!kycObj.kycpassed &&
				kycObj.messages[0] === "KYC Required";
			const showSecurityStep2 =
				kycObj.kycrequired &&
				!kycObj.kycpassed &&
				kycObj.messages[0] === "KYC still pending";
			if (showSecurity) {
				history.push("/security");
			} else if (showSecurityStep2) {
				history.push("/security/begin-security-check");
			} else {
				history.replace(redirectUrl);
			}
		}
	}, [kycObj]);

	// handler onKeypress enter key
	useEffect(() => {
		const keyDownHandler = (event) => {
			if (event.key === "Enter") {
				event.preventDefault();
				document.getElementById("btnLogin").click();
			}
		};

		document.addEventListener("keydown", keyDownHandler);

		return () => {
			document.removeEventListener("keydown", keyDownHandler);
		};
	}, []);

	if (isTunecore && location.pathname === "/login" && domainConfigIsSuccess) {
		window.location.href = "https://www.tunecore.com/";
	}

	if (domainConfigIsSuccess && !isTunecore) {
		return (
			<div className='container mx-auto'>
				<div className='flex flex-col items-center justify-center p-4 text-center min-h-screen-minus-footer'>
					<h1 className='text-6xl'>{t("auth:login-header")}</h1>
					<div className='w-full lg:w-1/3 text-old-sentric-dark-gray'>
						<Spacer />
						<Input
							placeholder={t("auth:username-placeholder")}
							value={username}
							onChange={(e) => {
								setUsername(e.target.value);
								checkValid({ username: e.target.value });
								setError(t("regex:login-username-regex-error"));
							}}
							id='txtUsername'
							className='bg-transparent auth-page-input'
						/>
						<Spacer />
						<Input
							type='password'
							placeholder={t("auth:password-placeholder")}
							value={password1}
							onChange={(e) => {
								setPassword1(e.target.value);
								checkValid({ password1: e.target.value });
								setError(null);
							}}
							id='txtPassword'
							className='bg-transparent auth-page-input'
						/>
						<Spacer />
						<AuthPrimaryButton
							arrow
							id='btnLogin'
							disabled={!valid}
							pending={pending}
							onClick={() => {
								login();
							}}
						>
							{t("auth:login-button")}
						</AuthPrimaryButton>
						{error && (
							<>
								<Spacer />
								<Alert msg={error} />
							</>
						)}
					</div>
					<Spacer />
					<div className='flex'>
						<ButtonLink
							className='mr-8'
							id='btnSignUp'
							onClick={() => history.push("/sign-up")}
						>
							{t("common:sign-up-link")}
						</ButtonLink>
						<ButtonLink
							id='btnForgot'
							onClick={() => history.push("/reset-password")}
						>
							{t("auth:forgot-password")}
						</ButtonLink>
					</div>
				</div>
			</div>
		);
	}
	return <Loading />;
}

export default Login;
