import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/backButton';
import P from '../../components/p';
import Radio from '../../components/radio';
import Spacer from '../../components/spacer';
import { getUserMeta } from '../../api/queries';
import AppState from '../../store/appstate';
import AuthPrimaryButton from '../../components/authPrimaryButton';

function AddAccount2() {
  const { t } = useTranslation('onboarding');
  const history = useHistory();
  const userMeta = getUserMeta();
  const addAccount = AppState.useState((s) => s.addAccount);

  let title = t('onboarding:have-you-released-title');
  let desc = t('onboarding:have-you-released-desc');
  switch (userMeta?.data?.role) {
    case 'Manager':
      title = t('onboarding:have-they-released-title');
      desc = t('onboarding:have-they-released-desc');
      break;
    default:
      break;
  }

  return (

    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer">
          <h1 className="text-6xl">{title}</h1>
          <Spacer />
          <P>{desc}</P>
          <Spacer />
          <Radio
            name="live"
            placeholder=""
            value={addAccount.streaming}
            onChange={(e) => AppState.update((s) => { s.addAccount.streaming = e.target.value; })}
            options={[
              { label: t('common:yes'), value: true },
              { label: t('common:no'), value: false },
            ]}
          />
          <Spacer />
          <div className="w-full lg:w-1/3">
            <div className='flex flex-row justify-evenly'>
              <BackButton onClick={() => history.push('/add-account-1')}>{t('onboarding:back-button')}</BackButton>
                  <AuthPrimaryButton
                  disabled={addAccount.streaming === null}
                  onClick={() => history.push(addAccount.streaming === 'true' ? '/add-account-3' : '/add-account-4')}
                    >
                    {t('onboarding:next-step-button')}
                  </AuthPrimaryButton>
            </div>
          </div>
      </div>
    </div>
  );
}

export default AddAccount2;
