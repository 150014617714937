/* eslint-disable jsx-a11y/control-has-associated-label */
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFile, FaFileCsv, FaFilePdf } from 'react-icons/fa';
import {
  getMyStatements,
  getMyStatementFiles,
  getMyProfile,
} from '../api/queries';
import AppState from '../store/appstate';
import LoadingSmall from './loadingSmall';
import Table from './table';
import RebrandPrimaryButton from './rebrandPrimaryButton';
import getColours from '../helpers/getColours';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';
import { sendStatementInfo } from '../helpers/helpers';
import currencies from "../helpers/currency";

const StatementsTable = () => {
  const { t } = useTranslation('other');
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const statements = getMyStatements({ aac: selectedAccount });
	const profile = getMyProfile();

  const [uuid, setUuid] = useState(null);
  const [downloads, setDownloads] = useState([]);
  const files = getMyStatementFiles({ aac: selectedAccount, suid: uuid });
  const { highlightColour } = getColours();
  const tenantCurrencyCode = AppState.useState((s) => s.tenantCurrencyCode);
  const tenantCurrencyLocale = AppState.useState((s) => s.tenantCurrencyLocale);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
 
  function formatCurrencyString(code, val) {
    return code && currencies[code]
    ? val.toLocaleString(
        currencies[code].locale, {
          style: 'currency',
          currency: code,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }
      )
    : val.toLocaleString(
      tenantCurrencyLocale, {
        style: 'currency',
        currency: code ?? tenantCurrencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }
    )
  };

  useEffect(() => {
    if (
      files.isSuccess
      && files?.data?.statementsFiles
      && downloads.length === 0
    ) {
      if (files?.data?.statementsFiles.length === 0) {
        return;
      }

      const fileArray = JSON.parse(
        JSON.stringify(files?.data?.statementsFiles),
      );
      // sort by filename

      fileArray.sort(
        (a, b) => a.mimetype - b.mimetype || b.filename - a.filename,
      );
      setDownloads(fileArray);
    }
  }, [files.isSuccess, downloads]);

  const handleDocumentOpen = async (url, filename, mimetype) => {
    window.open(url, '_blank');
    const userEmail = profile?.data?.email;
  
    sendStatementInfo({
      eventName: "pe14489857_april_2024_statement_download_pdf",
      properties: {
        email: userEmail,
        documentName: filename,
        documentType: mimetype,
      }
    });
  };

  const getTypeIcon = (mime) => {
    switch (mime) {
      case 'application/pdf':
        return (
          <div className="text-xxs md:text-sm flex flex-row items-center">
            <FaFilePdf />
            {' '}
            <p className="sm:hidden pl-1">PDF</p>
          </div>
        );
      case 'text/csv':
        return (
          <div className="text-xxs md:text-sm flex flex-row items-center">
            <FaFileCsv />
            {' '}
            <p className="sm:hidden pl-1">CSV</p>
          </div>
        );
      default:
        return (
          <div className="text-xxs md:text-sm flex flex-row items-center">
            <FaFile />
          </div>
        );
    }
  };

  const columns = React.useMemo(() => [
    {
      Header: t('common:_name'),
      accessor: 'name',
      width: '25%',
      id: 'colStatementName',
      Cell: ({ row }) => (
        <>
          <p>{row?.values?.colStatementName}</p>
          <small className="hidden md:inline">
            {'Published '}
            {row?.original.publishdate
              && row?.original.publishdate !== '0000-00-00'
              ? format(Date.parse(row?.original.publishdate), 'yyyy-MM-dd')
              : 'unknown'}
          </small>
        </>
      ),
    },
    {
      Header: t('other:closing-balance'),
      accessor: 'closingbalance',
      width: '25%',
      id: 'colStatementBalance',
      Cell: ({ row }) => (
        <p className="text-sm">
          {formatCurrencyString(row?.original.currencycode, row?.original.closingbalanceclientcurrency ?? row?.original.closingbalance)}
        </p>
      ),
    },
    {
      Header: t('other:statement-table-header-files'),
      accessor: 'hasstatement',
      width: '50%',
      id: 'colStatementFiles',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="flex flex-col lg:flex-row w-full items-start gap-2 lg:items-center justify-start">
          {row?.values?.colStatementFiles && (
            <div>
              <RebrandPrimaryButton
                id="btnStatementsViewFiles"
                onClick={() => {
                  setDownloads([]);
                  setUuid(row?.original.uniqueidentifier);
                }}
              >
                {t('other:view-files-button')}
              </RebrandPrimaryButton>
            </div>
          )}
          {uuid === row?.original.uniqueidentifier && (
            <div className="flex flex-row flex-auto w-3/4 gap-3 flex-wrap justify-start lg:justify-center">
              {files.isLoading && <LoadingSmall />}
              {files.isSuccess && (
                <>
                  {downloads.map((d) => (
                    <React.Fragment key={d.filename}>
                        <RebrandPrimaryButton
                          onClick={() => handleDocumentOpen(d.presignedurl, d.filename, d.mimetype)}
                        >
                          <div className="flex items-center">
                            <span className="mr-2 text-sm">
                              {getTypeIcon(d.mimetype)}
                            </span>
                            <span className="hidden sm:inline">{d.filename}</span>
                          </div>
                        </RebrandPrimaryButton>
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      ),
    },
  ]);
  return (
    <>
      <div className={`${isTunecore ? 'bg-tunecore-dark-element' : ' bg-white'} rounded-lg p-4 lg:p-8`}>
        {statements.isLoading && <LoadingSmall />}
        {statements.data?.total >= 1
          ? (
            <div>
              <Table
                area="statements"
                data={statements?.data?.statements}
                columns={columns}
                min={5}
                highlightColour={highlightColour}
                revealComponent={null}
                searchSuffix={t('other:statements-search-suffix')}
                bgClass={`${isTunecore ? 'bg-tunecore-dark-element' : 'bg-white'} rounded-lg p-0`}
              />
            </div>
          )
          : (
            <div>
              <p>No statements available.</p>
            </div>
          )}
      </div>
    </>
  );
};

export default StatementsTable;
