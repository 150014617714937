/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import clsx from "clsx";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Select from "react-select-virtualized";
import Avatar from "./avatar";
import AppState from "../store/appstate";

function UserDropDown(props) {
	const { t } = useTranslation();
	const { data, logout, accounts, switchAccount, selectedAccount, siteConfig } =
		props;

	const [showMenu, setShowMenu] = useState(false);
	const [showSwitch, setShowSwitch] = useState(false);
	const history = useHistory();
	const username = AppState.useState((s) => s.authentication?.userName);
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const accountsToSort = JSON.parse(JSON.stringify(accounts));

	const handleChange = (selected) => {
		if (!selected) return;
		switchAccount(selected.value);
		setShowMenu(false);
		setShowSwitch(false);
	};
	const userButtonClasses = clsx(
		"flex flex-row w-full h-full items-center justify-end gap-2 group lg:p-2",
		{
			"text-old-sentric-dark-gray": !isTunecore,
			"text-white font-tunecore": isTunecore,
			"": isTunecore && showMenu,
		}
	);

	const userDropDownClasses = clsx("shadow-2xl min-h-72 mt-2 pt-2 px-4 rounded-lg", {
		"bg-white": !isTunecore,
		"bg-tunecore-dark-element text-white ": isTunecore,
	});

	const customStyles = {
		menu: (provided) => ({
				...provided,
				zIndex: 0, // Adjust the z-index as needed
				position: 'relative', // Adjust the position as needed
				maxHeight: '300px',
				marginBottom: '16px',
		}),
		// You can add more custom styles for other parts of the component if needed
};

	return (
		<OutsideClickHandler
			onOutsideClick={() => {
				setShowMenu(false);
			}}
		>
			<button
				onClick={() => setShowMenu(!showMenu)}
				type='button'
				className={userButtonClasses}
				id='menu-button'
				tabIndex='0'
			>
				<div className='flex flex-row gap-2 lg:items-end'>
					<span
						className={`line-clamp-2 text-left self-center group-hover:underline ${isTunecore ? 'tracking-wide text-lg' : 'tracking-tight text-base'}`}
					>
						{data?.accountname}
					</span>
				</div>
				<div>
					<Avatar data={data} />
				</div>
			</button>

			{showMenu && (
				<div className={userDropDownClasses}>
					<div className="user-dropdown-child-border-bottom ">
					<p
						className={`${
							isTunecore
								? "text-white border-none text-base lg:text-sm"
								: "text-sm border-none "
						} text-left px-2 py-2 wrap-text`}
					>
						<span className='font-bold'>{t("common:user-menu-username")}{' '}</span>
						{username}
					</p>

					</div>
					{accounts?.artistaccounts?.length > 0 && (
						<button
							id='btnUserSwitchAccount'
							type='button'
							onClick={() => setShowSwitch(!showSwitch)}
							className={`${
								isTunecore ? "text-white border-none" : "text-gray-700"
							} block w-full text-left px-4 py-2 text-sm hover:underline`}
							role='menuitem'
							tabIndex='0'
						>
							{t("common:user-menu-switch")}
						</button>
					)}

					{showSwitch && (
						<div
							className={
								isTunecore
									? "bg-tunecore-dark-element"
									: "bg-white"
							}
						>
							<Select
								id='txtUserAccountSearch'
								menuIsOpen
								className='text-old-sentric-dark-gray text-sm w-full'
								name='artistAccount'
								placeholder='Search...'
								value={selectedAccount}
								onChange={handleChange}
								options={accountsToSort?.artistaccounts
									?.sort((a, b) => a.accountname.localeCompare(b.accountname))
									.map((s) => ({ label: s.accountname, value: s }))}
								search
								styles={customStyles}
							/>
						</div>
					)}
					{!siteConfig.disable_add_account_button && (
						<button
							id='btnUserAddAccount'
							type='button'
							onClick={() => history.push("/add-account-pre")}
							className={`${
								isTunecore
									? "border-none text-white"
									: "text-gray-700"
							} block w-full text-left px-4 py-2 text-sm hover:underline user-dropdown-child-border-top`}
							role='menuitem'
							tabIndex='0'
						>
							{t("common:user-menu-add")}
						</button>
					)}
					<button
						id='btnUserSignOut'
						type='button'
						onClick={() => logout()}
						className={`${
							isTunecore
								? "border-none text-white"
								: " text-gray-700"
						} block w-full text-left px-4 py-2 text-sm mb-4 hover:underline user-dropdown-child-border-top`}
						role='menuitem'
						tabIndex='0'
					>
						{t("common:user-menu-sign-out")}
					</button>
				</div>
			)}
		</OutsideClickHandler>
	);
}

export default UserDropDown;
