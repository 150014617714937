import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import H2 from '../../../../components/h2';
import Spacer from '../../../../components/spacer';
import {
  getMyAccount,
  getMySongsDetailedPaginated,
  getMyProfile,
} from '../../../../api/queries';
import AppState from '../../../../store/appstate';
import { sendProperty } from '../../../../helpers/helpers';
import LoadingSmall from '../../../../components/loadingSmall';
import SongsTable from './SongsTable';
import SongSuggestions from './SongSuggestions';
import { hasAdminRights } from '../../../../auth/shared';
import RebrandButtonLink from '../../../../components/rebrandButtonLink';
import getColours from '../../../../helpers/getColours';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function Songs() {
  const { t } = useTranslation('songs');
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const history = useHistory();
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const profile = getMyProfile();

  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const { highlightColour } = getColours();
  const tableState = AppState.useState((s) => s.songsTable);

  const songs = getMySongsDetailedPaginated({
    aac: selectedAccount, perPage: tableState.perPage, page: tableState.page, sortBy: tableState.sortBy?.[0]?.id, desc: tableState.sortBy?.[0]?.desc, globalFilter: tableState.globalFilter,
  });

  const adminRights = hasAdminRights(myAccount?.data);

  useEffect(() => {
    songs.refetch();
  }, [tableState]);

  useEffect(() => {
    if (selectedAccount) {
      songs.refetch();
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (songs?.isSuccess && songs?.data?.works !== null) {
      sendProperty({
        property: 'songs',
        value: songs.data.works.length,
        email: profile?.data?.email,
      });
    }
  }, [songs]);

  return (
    <>
        <div className="flex flex-wrap">
          <div className="w-full lg:min-h-screen-minus-header">
            <div className="mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12">
              <div className="flex flex-wrap justify-between items-baseline">
                <div>
                  <H2>{t("songs:songs-header")}</H2>
                </div>
                <div>
                  <RebrandButtonLink
                    plusIcon
                    id="btnSongsAddNew"
                    onClick={
                      adminRights
                        ? () => history.push("/songs/create")
                        : undefined
                    }
                  >
                    {t("songs:add-new-song-button")}
                  </RebrandButtonLink>
                </div>
              </div>
              <Spacer />
                {!siteConfig.disable_suggestions && (
                  <>
                    <div
                      className={`${
                        isTunecore ? "bg-tunecore-dark-element" : " bg-white"
                      } rounded-lg p-4 lg:p-8`}
                    >
                    <div className="flex items-center">
                    <h2 className="text-lg font-semibold">
                      {isTunecore
                        ? t("songs:suggestions-header")
                        : t("songs:spotify-header")}
                    </h2>
                    </div>
                    <Spacer thin />
                    <SongSuggestions highlightColour={highlightColour} />
                    </div>
                  </>
                )}
              <Spacer />
              <div
                className={`${
                  isTunecore ? "bg-tunecore-dark-element" : " bg-white"
                } rounded-lg p-4 lg:p-8`}
              >
                <div className="flex flex-col justify-center gap-2">
                  <h2 className="text-lg font-semibold">
                    {t("songs:song-library-header")}
                  </h2>
                </div>
                <Spacer thin />
                {!songs.isSuccess && <LoadingSmall />}
                {songs.isSuccess && (
                  <SongsTable
                    highlightColour={highlightColour}
                    data={songs?.data?.works}
                    total={songs?.data?.total}
                  />
                )}
              </div>
              <Spacer />
            </div>
          </div>
        </div>
    </>
  );
}
export default Songs;
