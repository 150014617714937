/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-multi-assign */
import React, { useEffect, useState } from "react";
import { FaSpotify, FaTimesCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PortableText } from "@portabletext/react";
import getColours from "../../../helpers/getColours";
import {
	getMyAccount,
	getMyAccounts,
	useSpotifyCount,
	useTopGigs,
	useTopSongs,
	useTopWriters,
} from "../../../api/queries";
import ButtonDashboard from "../../../components/buttonDashboard";
import RebrandPrimaryButton from "../../../components/rebrandPrimaryButton";
import ButtonInner from "../../../components/buttonInner";
import H2 from "../../../components/h2";
import H4 from "../../../components/h4";
import Spacer from "../../../components/spacer";
import AppState from "../../../store/appstate";
import WriterIcon from "../../../images/writer-icon.png";
import SongIcon from "../../../images/song-icon.png";
import GigIcon from "../../../images/gig-icon.png";
import WriterIconDark from "../../../images/rebrandIcons/WriterIconDark.png";
import SongIconDark from "../../../images/rebrandIcons/SongIconDark.png";
import GigClaimIconDark from "../../../images/rebrandIcons/GigClaimIconDark.png";
import ButtonIcon from "../../../images/rebrandIcons/ButtonIcon.png";
import TopSongs from "../../../components/TopSongs";
import TopWriters from "../../../components/TopWriters";
import TopGigs from "../../../components/TopGigs";
import ChooseAccountModal from "../../../components/ChooseAccountModal";
import LoadingSmall from "../../../components/loadingSmall";
import H1 from "../../../components/h1";
import { hasAdminRights } from "../../../auth/shared";
import P from "../../../components/p";
import useSiteConfigAuth from "../../../api/sanity/queries/getSiteConfigWithCode";
import DashboardOpportunitiesBlock from "../../../components/dashboardOpportunitiesBlock";

function Dashboard() {
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const flags = useFlags();
  const disableTopGigs = flags['pd-disable-top-gigs'];
	

	const cookies = new Cookies();

	const [newFeatureModal, setNewFeatureModal] = useState(
		!cookies.get("s-newfeature")
	);

	const myAccounts = getMyAccounts();

	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";
  const isRoughTrade = siteConfig?.display_name?.includes('Rough Trade Publishing');

	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
		accountname: selectedAccount,
	});

	const { highlightColour2 } = getColours(siteConfig);

	const adminRights = hasAdminRights(myAccount?.data);

	const topSongs = useTopSongs({ accounts: myAccounts?.data?.artistaccounts });
	const topWriters = useTopWriters({
		accounts: myAccounts?.data?.artistaccounts,
	});
	const topGigs = useTopGigs({ accounts: myAccounts?.data?.artistaccounts });

	const pendingSpotifyCount = useSpotifyCount({
		accounts: myAccounts?.data?.artistaccounts,
	});
	const history = useHistory();
	const [addGigModal, setAddGigModal] = useState(false);
	const [addStatementsModal, setAddStatementsModal] = useState(false);
	const [addSongModal, setAddSongModal] = useState(false);
	const [addWriterModal, setAddWriterModal] = useState(false);

	const [addOpportunityModal, setAddOpportunityModal] = useState(false);
	const [addOportunityUrl, setAddOportunityUrl] = useState(null);

	const [getSongs, setGetSongs] = useState(false);
	const [getWriters, setGetWriters] = useState(false);
	const [getGigs, setGetGigs] = useState(false);
	const [getSpotify, setGetSpotify] = useState(false);
	const { t } = useTranslation("dashboard");
	const viewStatements = () => {
		history.push("/statements");
	}
	;

	useEffect(() => {
		if (selectedAccount) {
			myAccount.refetch();
		}
	}, [selectedAccount]);
	useEffect(() => {
		if (myAccounts.isSuccess) {
			// getting tops
			if (!getSpotify) {
				setTimeout(() => {
					setGetSpotify(true);
				}, 2000);
			}
			if (!getSongs) {
				setTimeout(() => {
					setGetSongs(true);
				}, 4000);
			}
			if (!getWriters) {
				setTimeout(() => {
					setGetWriters(true);
				}, 6000);
			}
			if (!getGigs) {
				setTimeout(() => {
					setGetGigs(true);
				}, 8000);
			}
		}
	}, [myAccounts.isSuccess]);

	useEffect(() => {
		if (getGigs && !disableTopGigs) {
			topGigs.refetch();
		}
	}, [getGigs, disableTopGigs]);

	useEffect(() => {
		if (getSongs) {
			topSongs.refetch();
		}
	}, [getSongs]);

	useEffect(() => {
		if (getWriters) {
			topWriters.refetch();
		}
	}, [getWriters]);

	useEffect(() => {
		if (getSpotify) {
			pendingSpotifyCount.refetch();
		}
	}, [getSpotify]);
	
	useEffect(() => {
		if (isRoughTrade) {
			history.push("/statements");
		}
	}
	, [isRoughTrade]);

	return (
		<>
			{!!addGigModal && (
				<ChooseAccountModal
					accounts={myAccounts?.data?.artistaccounts}
					title={t("dashboard:choose-account-gigs-modal-header")}
					to='/gigs/claim'
					setAccountChoiceModal={setAddGigModal}
					nonAdminTo='/gigs/'
				/>
			)}
			{!!addStatementsModal && (
				<ChooseAccountModal
					accounts={myAccounts?.data?.artistaccounts}
					title=''
					to='/statements'
					setAccountChoiceModal={setAddStatementsModal}
					nonAdminTo='/statements/'
				/>
			)}
			{!!addOpportunityModal && addOportunityUrl && (
				<ChooseAccountModal
					accounts={myAccounts?.data?.artistaccounts}
					title={t("dashboard:choose-account-modal-header")}
					to={addOportunityUrl}
					nonAdminTo='/opportunities/'
					setAccountChoiceModal={setAddOpportunityModal}
				/>
			)}
			{!!addWriterModal && (
				<ChooseAccountModal
					accounts={myAccounts?.data?.artistaccounts}
					title={t("dashboard:choose-account-writer-modal-header")}
					to='/writers/create'
					nonAdminTo='/writers/'
					setAccountChoiceModal={setAddWriterModal}
				/>
			)}
			{!!addSongModal && (
				<ChooseAccountModal
					accounts={myAccounts?.data?.artistaccounts}
					title={t("dashboard:choose-account-songs-modal-header")}
					to='/songs/create'
					nonAdminTo='/songs/'
					setAccountChoiceModal={setAddSongModal}
				/>
			)}

			{newFeatureModal && !isTunecore && (
				<div className='fixed top-0 left-0 z-40 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50'>
					<div className='relative w-11/12 max-w-2xl text-center bg-white rounded-lg'>
						<button
							type='button'
							onClick={() => {
								setNewFeatureModal(false);
								cookies.set("s-newfeature", true, { path: "/" });
							}}
							className='absolute top-0 right-0 z-50 p-5 text-2xl'
						>
							<FaTimesCircle />
						</button>
						<div className='p-4 overflow-auto lg:p-8'>
							<Spacer />
							<div className='flex items-center justify-center'>
								<FaSpotify className='mr-2 ' />
								<H2>{t("dashboard:spotify-modal-header")}</H2>
							</div>
							<Spacer />
							<p className='text-lg'>{t("dashboard:spotify-modal-text1")}</p>
							<Spacer />
							<ButtonInner
								onClick={() => {
									setNewFeatureModal(false);
									cookies.set("s-newfeature", true, { path: "/" });
								}}
							>
								{t("dashboard:spotify-modal-button")}
							</ButtonInner>
							<Spacer />
						</div>
					</div>
				</div>
			)}

			<div className='flex flex-wrap'>
				<div className='w-full lg:min-h-screen-minus-header'>
					<div className='mx-4 my-12 lg:mx-12 xl:mx-20 2xl:mx-40'>
						<H1>
							{t("dashboard:welcome-back")}
							{", "}
							{myAccount.data?.accountname}!
						</H1>
						<Spacer />
						<div className='flex flex-row flex-wrap justify-between gap-0 font-semibold lg:flex-nowrap lg:gap-6'>
							<div
								style={{
									borderRadius: "10px 10px 10px 10px",
									backgroundColor: !isTunecore ? highlightColour2 : undefined,
								}}
								className={`${
									isTunecore
										? "bg-tunecore-dark-element text-white"
										: "text-old-sentric-table-text"
								} lg:w-1/3 w-full h-44 flex flex-col justify-center gap-4 px-6 mb-6`}
							>
								<div className='flex font-semibold text-center rounded'>
									{pendingSpotifyCount?.isSuccess &&
										pendingSpotifyCount?.data && (
											<div>
												<p className='text-3xl xl:text-4xl'>
													{pendingSpotifyCount.data?.[0]?.count}
												</p>
											</div>
										)}
									{pendingSpotifyCount?.isLoading && <LoadingSmall />}
									{!pendingSpotifyCount?.isLoading &&
										!pendingSpotifyCount?.data && <span>-</span>}
								</div>
								<p className='text-lg font-medium text-left xl:text-xl'>
									{/* izzy's code */}
									{t("dashboard:song-suggestions")}
								</p>
							</div>
							<div
								style={{
									borderRadius: "10px 10px 10px 10px",
									backgroundColor: !isTunecore ? highlightColour2 : undefined,
								}}
								className={`${
									isTunecore
										? "bg-tunecore-dark-element text-white"
										: "text-old-sentric-table-text"
								} lg:w-1/3 w-full h-44 flex flex-col justify-center gap-4 px-6 mb-6`}
							>
								<div className='flex font-semibold text-center rounded'>
									{topWriters?.isSuccess && topWriters?.data && (
										<span className='text-3xl xl:text-4xl'>
											{topWriters.data?.[0]?.verifieddate
												? format(
														Date.parse(topWriters.data?.[0]?.verifieddate),
														"yyyy-MM-dd"
												  )
												: "-"}
										</span>
									)}

									{topWriters?.isLoading && <LoadingSmall />}

									{!topWriters?.isLoading && !topWriters?.data && (
										<span>-</span>
									)}
								</div>
								<p className='text-lg font-medium text-left xl:text-xl'>
									{t("dashboard:last-writer-added")}
								</p>
							</div>
							<div
								style={{
									borderRadius: "10px 10px 10px 10px",
									backgroundColor: !isTunecore ? highlightColour2 : undefined,
								}}
								className={`${
									isTunecore
										? "bg-tunecore-dark-element text-white"
										: "text-old-sentric-table-text"
								} lg:w-1/3 w-full h-44 flex flex-col justify-center gap-4 px-6 mb-6`}
							>
								<div className='flex font-semibold text-center rounded'>
									{topSongs?.isSuccess && topSongs?.data && (
										<span className='text-3xl xl:text-4xl'>
											{topSongs.data?.[0]?.createddate
												? format(
														Date.parse(topSongs.data?.[0]?.createddate),
														"yyyy-MM-dd"
												  )
												: "-"}
										</span>
									)}

									{topSongs?.isLoading && <LoadingSmall />}
									{!topSongs?.isLoading && !topSongs?.data && <span>-</span>}
								</div>
								<p className='text-lg font-medium text-left xl:text-xl '>
									{t("dashboard:last-song-added")}
								</p>
							</div>
						</div>

						<div
							style={{ borderRadius: "10px 10px 10px 10px" }}
							className='flex flex-row flex-wrap justify-between gap-0 lg:flex-nowrap lg:gap-6'
						>
							<div className='w-full lg:w-1/3'>
								<ButtonDashboard
									id='btnDashboardAddSong'
									onClick={() => setAddSongModal(true)}
									disabled={!adminRights}
								>
									{!isTunecore ? (
										<div className='flex items-center ml-6'>
											<img
												className='h-4 mr-3'
												alt='song icon'
												src={SongIconDark}
											/>
											{t("dashboard:add-new-song")}
										</div>
									) : (
										<div className='flex items-center justify-center text-sm tracking-wider font-tunecore'>
											{t("dashboard:add-new-song")}
										</div>
									)}
								</ButtonDashboard>
							</div>
							<div className='w-full lg:w-1/3'>
								<ButtonDashboard
									id='btnDashboardAddWriter'
									onClick={() => setAddWriterModal(true)}
									disabled={!adminRights}
								>
									{!isTunecore ? (
										<div className='flex items-center ml-6'>
											<img
												className='h-4 mr-3'
												alt='writer icon'
												src={WriterIconDark}
											/>
											{t("dashboard:add-new-writer")}
										</div>
									) : (
										<div className='flex items-center justify-center text-sm tracking-wider font-tunecore'>
											{t("dashboard:add-new-writer")}
										</div>
									)}
								</ButtonDashboard>
							</div>
							<div className='w-full lg:w-1/3'>
								{!isTunecore ? (
									<ButtonDashboard
										id='btnDashboardAddClaim'
										onClick={() => setAddGigModal(true)}
										disabled={!adminRights}
									>
										<div className='flex items-center ml-6'>
											<img
												className='h-4 mr-3'
												alt='gig icon'
												src={GigClaimIconDark}
											/>
											{t("dashboard:create-new-gig-claim")}
										</div>
									</ButtonDashboard>
								) : (
									<ButtonDashboard
										id='btnDashboardViewStatments'
										onClick={viewStatements}
										disabled={!adminRights}
									>
										<div className='flex items-center justify-center text-sm tracking-wider font-tunecore'>
											{t("dashboard:view-statements")}
										</div>
									</ButtonDashboard>
								)}
							</div>
						</div>

						{!siteConfig?.disable_syncs_and_opportunities_on_dashboard && (
							<>
								<Spacer />
								<Spacer />
								<H2>{t("dashboard:recent-opportunities-header")}</H2>
								<Spacer />
								<DashboardOpportunitiesBlock />
							</>
						)}

						<div className='flex flex-wrap w-full'>
							{siteConfig?.dashboard_box_1 &&
								siteConfig?.dashboard_box_1.dates && (
									<div className='w-full mt-10 grow lg:w-1/2 lg:pr-4'>
										<div className='h-full p-8 px-8 bg-white rounded-lg html-content db-box'>
											<PortableText value={siteConfig?.dashboard_box_1.dates} />
										</div>
									</div>
								)}
							{siteConfig?.dashboard_box_2 &&
								siteConfig?.dashboard_box_2.useful_links && (
									<div className='w-full mt-10 grow lg:w-1/2'>
										<div className='h-full p-8 px-8 bg-white rounded-lg html-content db-box'>
											<PortableText
												value={siteConfig?.dashboard_box_2.useful_links}
											/>
										</div>
									</div>
								)}
							<Spacer />
							<Spacer />
							<H2>{t("dashboard:activity-overview-header")}</H2>
							<Spacer />
							<TopSongs />
							<Spacer />
							<div className='flex flex-wrap w-full gap-8 lg:flex-nowrap'>
								<TopWriters />
								{!isTunecore && !disableTopGigs && <TopGigs />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Dashboard;
