/* eslint-disable no-nested-ternary */
import { formatISO, parseISO, isValid } from "date-fns";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RiErrorWarningFill } from "react-icons/ri";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import getColours from "../helpers/getColours";

function Input(props) {
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour2 } = getColours();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const {
		value,
		pattern,
		accept,
		placeholder,
		maxLength,
		id,
		name,
		type,
		error,
		required,
		showLabel = true,
		showMonthDropdown,
		showYearDropdown,
		dateDropdownMode,
		useShortMonthInDropdown,
		dateFormatCalendar,
		onChange = () => {},
		onKeyDown = () => {},
		className = null,
		min,
		max,
		step,
		disabled,
		isClearable = false,
		onBlur = () => {},
		blackLabel = false,
	} = props;

	const ref = useRef();
	const v = value;

	const [isHovered, setIsHovered] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		if (error) {
			ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
		}
	}, [error]);

	const handleChangeDate = (date) => {
		const dateValue =
			!date || !isValid(date)
				? ""
				: formatISO(date, { representation: "date" });
		onChange(dateValue);
		// somehow the calendar on the datepicker is getting stuck in a hovered state? so we need to ensure both states are killed
		setIsFocused(false);
		setIsHovered(false);
	};

	const handleBlurDate = (date) => {
		const dateValue =
			!date || !isValid(date)
				? ""
				: formatISO(date, { representation: "date" });
		onBlur(dateValue);
	};

	return (
		<div ref={ref} className={`relative ${className} `}>
			{showLabel && (
				<label
					className={[
						`pointer-events-none flex text-sm items-center pb-0.5 ${
							isTunecore
								? "text-white opacity-70"
								: blackLabel
								? "text-black font-sentric-alt font-semibold opacity-70"
								: "font-sentric-alt font-semibold text-sentric-rebrand-grey-text"
						}`,
					]}
					htmlFor={name}
				>
					{placeholder}
				</label>
			)}
			<div
				className={`block w-full ${
					disabled ? "rounded-lg bg-gray-100 opacity-60" : ""
				} ${
					required && isTunecore
						? "text-white"
						: isTunecore
						? "text-black"
						: "rounded-lg font-sentric-alt bg-transparent  font-semibold"
				} ${required ? "required-star" : ""}`}
			>
				{type !== "date" && (
					<input
						style={{
							borderColor:
								isFocused || isHovered ? highlightColour2 : undefined,
							boxShadow:
								isFocused || isHovered
									? `0 0 0 2px ${highlightColour2}`
									: undefined,
						}}
						disabled={disabled}
						className={[
							`${
								isTunecore
									? "bg-tunecore-grey-element text-white focus:ring-tunecore-green tunecore-input-hover"
									: ""
							} rounded-lg border-0 focus:outline-none focus:ring-2 w-full text-sm font-semibold p-2 pl-3 pr-3`,
							(v && v.toString().length > 0) ||
							v === 0 ||
							["file"].includes(type)
								? ""
								: "",
						].join(" ")}
						id={id}
						name={name}
						value={v}
						type={type || "text"}
						onChange={onChange}
						onKeyDown={onKeyDown}
						min={min}
						pattern={pattern}
						max={max}
						maxLength={maxLength}
						step={step}
						accept={accept}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
						onFocus={() => setIsFocused(true)}
						onBlur={(e) => {
							setIsFocused(false);
							onBlur(e);
						}}
						onWheel={(event) => event.currentTarget.blur()}
					/>
				)}
				{type === "date" && (
					<div
						type='input'
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
						onFocus={() => setIsFocused(true)}
						style={{
							borderColor:
								isFocused || isHovered ? highlightColour2 : undefined,
							boxShadow:
								isFocused || isHovered
									? `0 0 0 2px ${highlightColour2}`
									: undefined,
						}}
						className='rounded-lg'
					>
						<DatePicker
							id={id}
							selected={v ? parseISO(v) : null}
							onChange={handleChangeDate}
							onBlur={handleBlurDate}
							showPopperArrow={false}
							showMonthDropdown={showMonthDropdown}
							showYearDropdown={showYearDropdown}
							dropdownMode={dateDropdownMode}
							useShortMonthInDropdown={useShortMonthInDropdown}
							dateFormatCalendar={dateFormatCalendar || "LLLL yyyy"}
							className={[
								`${
									isTunecore
										? "bg-tunecore-grey-element text-white focus:ring-tunecore-green tunecore-datepicker"
										: "focus:ring-0"
								} rounded-lg border-0 focus:outline-none w-full text-sm font-semibold p-2 pl-3 pr-3`,
								(v && v.toString().length > 0) ||
								v === 0 ||
								["file"].includes(type)
									? ""
									: "",
							].join(" ")}
							dateFormat='yyyy-MM-dd'
							isClearable={isClearable}
							minDate={min}
							maxDate={max}
						/>
					</div>
				)}
			</div>
			{!!error && (
				<p
					className={`w-4/5 ${
						isTunecore
							? "bg-tunecore-error-red bg-opacity-20 border-none"
							: "font-sentric-alt bg-red-700 border-white border"
					} text-sm inline-block p-1 pr-3 pl-3 mt-2 text-white rounded-lg `}
				>
					{isTunecore && (
						<span>
							<RiErrorWarningFill className='inline-block mr-2 text-2xl text-tunecore-error-red' />
						</span>
					)}
					{error}
				</p>
			)}
		</div>
	);
}

export default Input;
