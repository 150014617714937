/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import H1 from "../../components/h1";
import P from "../../components/p";
import Spacer from "../../components/spacer";
import SmallButton from "../../components/smallButton";
import songkick from "../../images/songkick.png";
import AppState from "../../store/appstate";
import Select from "../../components/select";
import AuthPrimaryButton from "../../components/authPrimaryButton";

function AddSongkick() {
	const { t } = useTranslation("onboarding");
	const songkickResult = AppState.useState((s) => s.addAccount.songkick);
	const history = useHistory();
	const apiUrl = AppState.getRawState()?.apiUrl;

	const getOptions = (query) =>
		new Promise((resolve, reject) => {
			if (!query) {
				resolve([]);
				return;
			}
			fetch(`${apiUrl}/songkick?s=${query}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((response) => response.json())
				.then((result) => {
					resolve(
						result.resultsPage.results.artist.map((artist) => ({
							value: JSON.stringify(artist),
							name: artist.displayName,
						}))
					);
				})
				.catch(reject);
		});

	return (
		<div className='container mx-auto'>
			<div className='flex flex-col justify-center items-center text-white p-4 text-center min-h-screen-minus-footer'>
				<img width='80' src={songkick} alt='songkick logo' />
				<Spacer />
				{!songkickResult && (
					<>
						<h1 className='text-6xl'>{t("onboarding:add-songkick-header")}</h1>
						<Spacer />
						<P>{t("onboarding:add-songkick-desc")}</P>
						<Spacer />
						<div className='relative w-full flex flex-col items-center justify-center'>
							<div className='w-full lg:w-1/3'>
								<div className='w-full flex flex-row items-start justify-center text-old-sentric-dark-gray'>
									<Select
										authSearch
										className='custom-select no-dropdown'
										debounce={500}
										onChange={(artist) => {
											AppState.update((s) => {
												s.addAccount.songkick = artist;
											});
										}}
										getOptions={getOptions}
										// customRenderOption={renderFriend}
										placeholder={t("onboarding:search-songkick-placeholder")}
										search
										customLabel='Songkick Artist Name'
									/>
								</div>
							</div>
						</div>
					</>
				)}
				{!!songkickResult && (
					<>
						<H1>{t("onboarding:selected-songkick-profile")}</H1>
						<Spacer />
						<div className='bg-white rounded-lg shadow-lg flex flex-col p-4 px-6 items-center text-old-sentric-dark-gray'>
							<p className='font-bold mb-4'>
								{JSON.parse(songkickResult).displayName}
							</p>
							<SmallButton
								onClick={() => {
									AppState.update((s) => {
										s.addAccount.songkick = null;
									});
								}}
							>
								{t("common:remove")}
							</SmallButton>
						</div>
					</>
				)}
				<Spacer />

				<div className='flex flex-row w-full lg:w-1/3 justify-center'>
					<AuthPrimaryButton onClick={() => history.push("/add-account-5")}>
						{t("common:_done")}
					</AuthPrimaryButton>
				</div>
			</div>
		</div>
	);
}

export default AddSongkick;
