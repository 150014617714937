/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { MdWarningAmber } from 'react-icons/md';
// import { apiCall } from '../../../../api/shared';
import FeatureDisabled from '../../../pages/featureDisabled';
import {
  startKyc,
} from '../../../../api/mutations';
import AppState from '../../../../store/appstate';
import H2 from '../../../../components/h2';
import Spacer from '../../../../components/spacer';
import H3 from '../../../../components/h3';
import P from '../../../../components/p';
import ButtonInner from '../../../../components/buttonInner';
import ButtonPrimaryLarge from '../../../../components/buttonPrimaryLarge';
import FormError from '../../../../components/formError';
import RebrandPrimaryButton from '../../../../components/rebrandPrimaryButton';

function Security() {
  const history = useHistory();
  const [agree, setAgree] = useState(false);
  const [gettingKyc, setGettingKyc] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const startKycStatusMutation = startKyc();
  const termsCheckboxHandler = () => {
    setAgree(!agree);
  };

  const kyc = AppState.useState((s) => s?.kyc);
  if (kyc?.messages[0] === 'KYC still pending') {
    history.push('/security/begin-security-check');
  }

  if (kyc.kycpassed) {
    history.push('/');
  }
  const beginKyc = () => {
    setGettingKyc(true);
    startKycStatusMutation.reset();

    setMutationErrorMessage('');
    setValidationErrorMessage('');
    startKycStatusMutation.mutate(null, {
      onSuccess: (res) => {
          if (res?.kycpassed === true) {
            setValidationErrorMessage('Security Check Already Completed');
          } else {
            history.push('/security/begin-security-check');
          }
          setGettingKyc(false);
      },
      onError: (error) => {
          setMutationErrorMessage(error.message);
          setGettingKyc(false);
          AppState.update((s) => {
            s.navItemsEnabled = true;
          });
          console.log('start kyc error', res);
      },
    });
  };

  const { t } = useTranslation('security');
  return (
    <div className="flex flex-wrap">
      <div className="w-full bg-sentric-menu-gray lg:min-h-screen-minus-header sentric-shadow-left">
        <div className="mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-6">
          <H2>{t('security:security-check-header')}</H2>
          <Spacer />
          <div className='font-sentric-alt bg-white p-8 rounded-lg whitespace-pre-wrap security-text-container grid grid-flow-col grid-auto-columns: minmax(0, 2fr);'>
            <div className="security-icon py-8 pr-8 hidden sm:block">
              <MdWarningAmber />
            </div>
            <div>{t('security:security-existing-user-text')}</div>
          </div>
          <Spacer />
          <div className="bg-white p-8 rounded-lg">
            <div>
              <H3>IMPORTANT INFORMATION - PLEASE READ</H3>
              <div className='font-sentric-alt'>
                <small>
                (definitions below which are capitalised are given in The
                General Data Protection Regulation (2016/679, "GDPR"))
                </small>
                <Spacer />
                <p>
                  We at Sentric Music care about your privacy, therefore, before
                  moving forward with this 'Know Your Clients' procedure, there's
                  a few things we wanted you to know.
                </p>
                <Spacer thin />
                <p>
                  Sentric are committed to our clients and are constantly striving
                  to deliver efficient, tech-based solutions to music publishing
                  administration. Whilst efficiency and excellent service are
                  important to us, so is integrity and ensuring everything we do
                  is done ethically and in the best interest not only of our own
                  clients but all creators and musicians worldwide.
                </p>
                <Spacer thin />
                <p>
                  <strong>WHY ARE WE DOING THIS: </strong>
                  The purpose of the 'Know
                  Your Clients' process is fraud prevention. Sentric wants to
                  ensure we're collecting on behalf of and also paying the correct
                  rights holders. Sentric values the contribution creators make to
                  this wonderful industry and wants to make every effort to ensure
                  that we are always working towards making the music industry a
                  fairer place. We will use the data to ensure the people who are
                  claiming royalties are who they say they are.
                </p>
                <Spacer thin />
                <p>
                  By continuing the 'Know Your Clients' process below, you will
                  be:
                  {' '}
                  <strong>(i) explicitly consenting to Sentric processing and temporarily storing your data as further details in Sentric's Privacy Policy; and (ii) explicitly consenting to Sentric's partner in this process, IDenfy, also processing and storing your data on behalf of Sentric.</strong>
                </p>
                <Spacer />
                <p>
                  You can find more information in our Privacy Policy
                  {' '}
                  <a className="underline font-semibold" href="/privacy-policy">here</a>
                  , but here are the TLDR highlights:
                </p>
                <Spacer thin />
                <ul className="list-disc pl-8">
                  <li>
                    Sentric will be the Data Controller of the data provided
                    throughout this 'Know Your Clients' process and will store the
                    information for no longer than 6 months.
                  </li>
                  <li>
                    IDenfy is Sentric's trusted partner for this process and will
                    be the Data Processor. No other third parties will hold or
                    process the data provided to us during the 'Know Your Clients'
                    process. You can find out more about IDenfy's security and
                    compliance information
                    {' '}
                    <a className="underline font-semibold" href="https://idenfy.com/security">here</a>
                    {' '}
                    and its Privacy
                    Policy
                    {' '}
                    <a href="https://idenfy.com/privacy-policy">here</a>
                    .
                  </li>
                  <li>
                    The purpose for the processing and storing of your data
                    throughout the 'Know Your Clients' process is fraud
                    prevention, and your data will not be used for any other
                    purpose.
                  </li>
                  <li>
                    All of your Individual Rights (i.e. Right to be informed,
                    Right to access, Right to rectification, Right to erasure,
                    Right to restrict processing, Right to restrict portability,
                    Right to object, Rights related to automated decision making
                    including profiling) afforded by the GDPR are protected, and
                    Sentric's full information is contained in our Privacy Policy,
                    should you want to exercise any of these rights.
                  </li>
                  <li>
                    You can withdraw your consent at ANY time, and we will delete
                    your information immediately. If you wish to withdraw your
                    consent, email us at:
                    {' '}
                    <a className="underline font-semibold" href="mailto:privacy@sentricmusic.com">
                      privacy@sentricmusic.com
                    </a>
                    .
                  </li>
                </ul>
                <Spacer thin />
                <p>
                  Don't worry if you're uncomfortable with the above, please
                  contact:
                  {' '}
                  <a className="underline font-semibold" href="mailto:privacy@sentricmusic.com">
                    privacy@sentricmusic.com
                  </a>
                  , and we will make every effort to try to ascertain your natural
                  person status another way!
                </p>

                <p>
                  For any enquiries or issues, please contact:
                  {' '}
                  <a className="underline font-semibold" href="mailto:privacy@sentricmusic.com">
                    privacy@sentricmusic.com
                  </a>
                </p>
                <Spacer thin />
                <P>
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    className="underline text-base font-semibold"
                  >
                    {t('security:security-full-terms')}
                  </a>
                </P>
                <Spacer thin />
                <label className="flex items-center cursor-pointer" htmlFor="terms">
                  <input
                    onChange={termsCheckboxHandler}
                    type="checkbox"
                    id="terms"
                    name="terms"
                    className="mr-4 cursor-pointer"
                  />
                  {t('security:security-checkbox-label')}
                </label>
              </div>
            </div>
          </div>
          <Spacer />
            <RebrandPrimaryButton
              disabled={!agree && !gettingKyc}
              onClick={() => beginKyc()}
              arrow
            >
            {t('security:security-check-button')}
            </RebrandPrimaryButton>
          <Spacer />
          <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
        </div>
      </div>
    </div>
  );
}
export default Security;
