import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import useSiteConfigUnAuth from "../api/sanity/queries/getSiteConfigWithDomain";

const getColours = () => {
  const { data: siteConfig } = useSiteConfigAuth();
  const { data: domainConfig } = useSiteConfigUnAuth();

  let darkColour = '#24201F'; // default dark grey
  if (siteConfig?.dark_colour) {
    darkColour = siteConfig?.dark_colour;
  } 

  let highlightColour = '#EC542E'; // default orange
  if (siteConfig?.highlight_colour) {
    highlightColour = siteConfig?.highlight_colour;
  }

  let statusSuccessColour = '#22c55e'; // default green
  if (siteConfig?.status_colour_1_success) {
    statusSuccessColour = siteConfig?.status_colour_1_success;
  }

  let statusWarningColour = '#fb923c'; // default orange
  if (siteConfig?.status_colour_2_warning) {
    statusWarningColour = siteConfig?.status_colour_2_warning;
  }

  let statusErrorColour = '#ef4444'; // default red
  if (siteConfig?.status_colour_3_error) {
    statusErrorColour = siteConfig?.status_colour_3_error;
  }

  let highlightColour2 = '#25BCBB'; // default teal
  if (siteConfig?.highlight_colour_2) {
    highlightColour2 = siteConfig?.highlight_colour_2;
  }

  let onboardingDarkColour = '#24201F'; // default dark grey
  if (domainConfig?.onboarding_dark_colour) {
    onboardingDarkColour = domainConfig?.onboarding_dark_colour;
  }

  let grad1Colour = '#24201F'; // default dark grey
  if (domainConfig?.onboarding_background_colour_1) {
    grad1Colour = domainConfig?.onboarding_background_colour_1;
  }

  let grad2Colour = '#24201F'; // default dark grey
  if (domainConfig?.onboarding_background_colour_2) {
    grad2Colour = domainConfig?.onboarding_background_colour_2;
  }

  let onboardingHighlightColour = '#EC542E'; // default orange
  if (domainConfig?.onboarding_highlight_colour) {
    onboardingHighlightColour = domainConfig?.onboarding_highlight_colour;
  }

  let onboardingHighlightColour2 = '#25BCBB'; // default teal
  if (domainConfig?.onboarding_highlight_colour_2) {
    onboardingHighlightColour2 = domainConfig?.onboarding_highlight_colour_2;
  }

  return {
    darkColour,
    highlightColour,
    highlightColour2,
    statusSuccessColour,
    statusWarningColour,
    statusErrorColour,
    onboardingDarkColour,
    grad1Colour,
    grad2Colour,
    onboardingHighlightColour,
    onboardingHighlightColour2
  };
}

export default getColours;
