/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-danger */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaMinusCircle } from "react-icons/fa";
import Select from "../../../../components/select";
import HR from "../../../../components/hr";
import Input from "../../../../components/input";
import Spacer from "../../../../components/spacer";
import H4 from "../../../../components/h4";
import InnerRadio from "../../../../components/innerRadio";
import FormError from "../../../../components/formError";
import AppState from "../../../../store/appstate";
import {
	getMySong,
	getMySongsDetailedPaginated,
	getMyWriters,
	getMyProfile,
} from "../../../../api/queries";
import {
	createRecording,
	createSong,
	updateRecordingMeta,
	updateSong,
} from "../../../../api/mutations";
import MultipleInput from "../../../../components/multipleInput";
import WritersForm from "../writers/writersForm";
import LoadingSmall from "../../../../components/loadingSmall";
import {
	nameRegexTest,
	sendEvent,
	sendProperty,
	strToBool,
	titleRegexTest,
} from "../../../../helpers/helpers";
import SongWriterSplitCreate from "./SongWriterSplitCreate";
import H2 from "../../../../components/h2";
import RebrandButtonLink from "../../../../components/rebrandButtonLink";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";
import SubModal from "../../../../components/SubModal";
import getColours from "../../../../helpers/getColours";

function SongsCreateSameSplit(props) {
	const {
		id,
		closeForm,
		suggestion = null,
		onComplete = () => {},
		aac = null,
	} = props;
	const siteConfigData = useSiteConfigAuth();
	const [reset, setReset] = useState(0);
	const isUpdate = !!id;
	const { t } = useTranslation("songs");
	const [title, setTitle] = useState();
	const [durationmins, setDurationmins] = useState();
	const [durationsecs, setDurationsecs] = useState();
	const [isremix, setIsremix] = useState("false");
	const [containssamples, setContainssamples] = useState("false");
	const [performers, setPerformers] = useState();
	const [alternativetitles, setAlternativetitles] = useState();
	const [isLocked, setIsLocked] = useState(false);
	const [splits, setSplits] = useState([]);
	const [addNewWriterModalIsOpen, setAddNewWriterModalIsOpen] = useState(false);

	const [performingartist, setPerformingartist] = useState();
	const [isrc, setIsrc] = useState();
	const [releasedate, setReleasedate] = useState();
	const [pending, setPending] = useState(false);
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour } = getColours(siteConfig);
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const [spotifySongIdentifier, setSpotifySongIdentifier] = useState(null);
	const [spotifyPopularity, setSpotifyPopularity] = useState(null);

	const [mutationErrorMessage, setMutationErrorMessage] = useState("");
	const [validationErrorMessage, setValidationErrorMessage] = useState("");
	const [errors, setErrors] = useState({});
	const history = useHistory();
	const createMutation = createSong();
	const updateMutation = updateSong();
	const recordingCreateMutation = createRecording();
	const recordingMetaMutation = updateRecordingMeta();

	let selectedAccount = null;
	if (aac) {
		selectedAccount = aac;
	} else {
		selectedAccount = AppState.useState((s) => s.selectedAccount);
	}

	const tableState = AppState.useState((s) => s.songsTable);

	const songs = getMySongsDetailedPaginated({
		aac: selectedAccount,
		perPage: tableState.perPage,
		page: tableState.page,
		sortBy: tableState.sortBy?.[0]?.id,
		desc: tableState.sortBy?.[0]?.desc,
		globalFilter: tableState.globalFilter,
	});
	const song = getMySong({ aac: selectedAccount, wc: id });

	const updateWorkEtag = () => {
		song.refetch({ throwOnError: true });
	};
	const [justAddedWorkCode, setJustAddedWorkCode] = useState(null);

	const songJustAdded = getMySong({
		aac: selectedAccount,
		wc: justAddedWorkCode,
	});

	const profile = getMyProfile();

	const writers = getMyWriters({ aac: selectedAccount });

	const [spotifyResult, setSpotifyResult] = useState();
	const [agreeAddRecording, setAgreeAddRecording] = useState(true);
	const [apiToken, setAPIToken] = useState(null);

	const getSpotifyAPIToken = async () => {
		const apiUrl = AppState.getRawState()?.apiUrl;

		const response = await fetch(`${apiUrl}/spotify-token`);
		const responseData = await response.json();
		return responseData.access_token;
	};

	const location = useLocation();
	useEffect(() => {
		async function go() {
			setAPIToken(await getSpotifyAPIToken());
		}
		go();
	}, []);

	useEffect(() => {
		if (selectedAccount) {
			writers.refetch();
			song.refetch();
		}
	}, [selectedAccount]);

	if ((song.isError || song.data === null) && isUpdate) {
		history.push("/songs");
	}

	// if song just added, then also add recording
	useEffect(() => {
		if (songJustAdded?.data) {
			addRecording();
		}
	}, [songJustAdded.data]);

	if (song.isSuccess && id && !title) {
		setIsLocked(song.data.islocked);
		setTitle(song.data.title);
		setDurationmins(song.data.durationmins);
		setDurationsecs(song.data.durationsecs);
		setIsremix(song.data.isremix ? "true" : "false");
		setContainssamples(song.data.containssamples ? "true" : "false");
		setPerformers(
			song.data.performingartists
				.map((a) => ({
					...a,
					name: a.performingartistname,
				}))
				.filter((p) => !p.isdeleted)
		);
		setAlternativetitles(
			song.data?.alternativetitles.filter((p) => !p.isdeleted)
		);

		setSplits(
			song.data.writersplits.map((w) => {
				const writer = writers.data.writers.find(
					(writer) => writer.writercode === w.writercode
				);
				return {
					...w,
					righttocollect: w.righttocollect ? "true" : "false",
					writer,
				};
			})
		);
	}

	if (suggestion && !title) {
		const tr = suggestion;

		setTitle(tr.title);

		setDurationmins(Math.floor(tr.duration / 1000 / 60));

		setDurationsecs(Math.floor((tr.duration / 1000) % 60));

		setPerformers(
			tr.artists.split(",").map((a) => ({
				name: a.replace(/&#44;/g, ","),
			}))
		);

		const date =
			tr?.release_date?.length === 4
				? `${tr.release_date}-01-01`
				: tr?.release_date;

		setReset(reset + 1);
		setPerformingartist(tr.artists.split(",")[0]);
		setIsrc(tr?.isrc);
		setSpotifyResult(tr);
		setSpotifySongIdentifier(tr?.spotify_song_id);
		setSpotifyPopularity(tr?.popularity);
		setReleasedate(date);
		setAgreeAddRecording(true);
	}

	const checkErrors = (field) => {
		let errs = {};
		if (field) {
			errs = JSON.parse(JSON.stringify(errors));
			delete errs[field];
		}

		let valid = true;
		// title check

		if (!field || field === "title") {
			const validTitle = title?.trim().length > 0;
			if (!validTitle) {
				errs.title = t("regex:song-title-regex");
				valid = false;
			}

			if (title && !titleRegexTest(title)) {
				errs.title = t("regex:invalid-characters");
				valid = false;
			}
		}

		if (!field || field === "alternativetitles") {
			if (alternativetitles) {
				alternativetitles.forEach((p) => {
					if (!p?.title || p.title.trim().length < 1) {
						errs.alternativetitles = t("regex:song-alternative-title-regex");
						valid = false;
					} else if (p.title && !titleRegexTest(p.title)) {
						errs.alternativetitles = t("regex:invalid-characters");
						valid = false;
					}
				});
			}
		}

		if (!field || field === "performers") {
			// has at least one performer
			if (!performers || performers.length < 1) {
				errs.performers = t("regex:song-performer-regex");
				valid = false;
			}

			// check valid performer names
			if (performers) {
				performers.forEach((p) => {
					if (!p?.name || p.name.trim().length < 1) {
						errs.performers = t("regex:song-performer-name-regex");
						valid = false;
					} else if (p.name.length > 44) {
						errs.performers = t("regex:song-performer-max-length-regex");
						valid = false;
					} else if (p.name && !nameRegexTest(p.name)) {
						errs.performers = t("regex:invalid-characters2");
						valid = false;
					}
				});
			}
		}
		if (!field || field === "writers") {
			if (!splits && !field) {
				errs.writers = t("regex:add-songwriter-regex");
				valid = false;
			}
			if (splits) {
				let total = 0;
				let fieldError = false;
				splits.forEach((p) => {
					if (
						!field &&
						(!p?.writercode ||
							!p?.righttocollect ||
							!p?.writershare ||
							!p?.writerdesignationcode)
					) {
						errs.writers = t("regex:select-a-writer-regex");
						valid = false;
						fieldError = true;
					} else if (p.writershare) {
						total += Number(p.writershare);

						total = Number(total.toFixed(2));

						if (p.writershare.toString().indexOf(".") > -1) {
							const decimalLength =
								p.writershare.toString().length -
								(p.writershare.toString().indexOf(".") + 1);
							if (decimalLength > 2) {
								errs.writers = t("regex:writer-splits-regex");
								valid = false;
							}
						}
					} else {
						fieldError = true;
					}
				});
				if (total !== 100 && !fieldError) {
					errs.writers = t("regex:writer-share-regex");
					valid = false;
				}
			}
			if (splits) {
				let atLeastOneRightToCollect = false;
				splits.forEach((p) => {
					if (strToBool(p.righttocollect)) {
						atLeastOneRightToCollect = true;
					}
				});

				if (!atLeastOneRightToCollect) {
					errs.writers = t("regex:writer-right-to-collect-regex");
					valid = false;
				}
			}

			if (siteConfigData.data.ipicae_required && splits) {
				splits.forEach((p) => {
					if (
						p.righttocollect === "true" &&
						(!p.writer.caeipinumber ||
							p.writer.caeipinumber === "" ||
							p.writer.caeipinumber === null ||
							p.writer.caeipinumber === undefined)
					) {
						errs.writers = t("regex:writer-missing-cae-regex");
						valid = false;
					}
				});
			}

			if (siteConfigData.data.date_of_birth_required && splits) {
				splits.forEach((p) => {
					if (
						p.righttocollect === "true" &&
						(!p.writer.dateofbirth || p.writer.dateofbirth === "")
					) {
						errs.writers = t("regex:writer-missing-dob-regex");
						valid = false;
					}
				});
			}
		}

		setErrors(errs);
		return valid;
	};

	function renderFriend(props, option, snapshot, className) {
		return (
			<button type='button' {...props} className={className}>
				<div className='flex flex-row items-center w-full'>
					{option.photo && (
						<img
							className='object-cover w-10 h-10 mr-4 rounded-full'
							alt={option.name}
							src={option.photo}
						/>
					)}
					{!option.photo && (
						<div className='w-12 h-12 mr-4 bg-gray-300 rounded-full' />
					)}
					<h4>{option.name}</h4>
				</div>
			</button>
		);
	}

	function addSongSuccess() {
		toast.success(`${t("songs:song-added-toast")}: ${title}`, {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});

		sendProperty({
			property: "last_song_added",
			value: "CURRENT_DATE",
			email: profile?.data?.email,
		});
		sendEvent({ property: "pe14489857_last_song_added" });

		songs.refetch();
		history.push("/songs");
		setPending(false);
	}

	function validationFailed(message) {
		if (
			message.toString().includes("There is an existing record, Work code(s)")
		) {
			setValidationErrorMessage("This song has already been added.");
		} else {
			setMutationErrorMessage(message);
		}
		setPending(false);
	}

	const addSong = async () => {
		setPending(true);
		setMutationErrorMessage("");
		setValidationErrorMessage("");

		// reset mutation
		createMutation.reset();
		const valid = checkErrors();

		if (valid) {
			let alts = [];
			if (alternativetitles) {
				alts = alternativetitles.filter((a) => a.title.length > 0);
			}

			const data = {
				artistaccountcode: selectedAccount,
				title,
				durationmins,
				durationsecs,
				isremix: isremix === "true",
				containssamples: containssamples === "true",
				ispublicdomain: true,
				alternativetitles: alts,
				performingartistnames: performers,
				writersplits: splits
					? splits.map((w) => ({
							...w,
							righttocollect: strToBool(w.righttocollect),
					  }))
					: [],
			};

			createMutation.mutate(data, {
				onSuccess: (response) => {
					if (response.failedvalidation) {
						setValidationErrorMessage(response.messages);
						setPending(false);
					} else if (spotifyResult && agreeAddRecording === true) {
						setJustAddedWorkCode(response.model.workcode);
					} else {
						addSongSuccess();
					}
				},
				onError: (error) => {
					setMutationErrorMessage(error.message);
					setPending(false);
				},
			});
		}
		setPending(false);
	};

	const update = async () => {
		setPending(true);
		setMutationErrorMessage("");
		setValidationErrorMessage("");
		// reset mutation
		updateMutation.reset();
		const valid = checkErrors();

		if (valid) {
			let alts = [];
			if (alternativetitles) {
				alts = alternativetitles.filter((a) => a.title.length > 0);
			}

			alts = alts.map((a) => ({
				title: a.title,
				alternativetitleidentifier: a.hasOwnProperty(
					"alternativetitleidentifier"
				)
					? a.alternativetitleidentifier
					: "00000000-0000-0000-0000-000000000000",
				alternativetitleamendmentidentifier: a.hasOwnProperty(
					"alternativetitleamendmentidentifier"
				)
					? a.alternativetitleamendmentidentifier
					: "00000000-0000-0000-0000-000000000000",
			}));

			const performersNew = performers.map((a) => ({
				performingartistname: a.name,
				performingartistidentifier: a.hasOwnProperty(
					"performingartistidentifier"
				)
					? a.performingartistidentifier
					: "00000000-0000-0000-0000-000000000000",
				performingartistamendmentidentifier: a.hasOwnProperty(
					"performingartistamendmentidentifier"
				)
					? a.performingartistamendmentidentifier
					: "00000000-0000-0000-0000-000000000000",
			}));

			const writersplitsNew = splits.map((a) => ({
				writercode: a.writercode,
				writershare: a.writershare,
				writerdesignationcode: a.writerdesignationcode,
				righttocollect: strToBool(a.righttocollect),
				writersplitamendmentidentifier: a.hasOwnProperty(
					"writersplitamendmentidentifier"
				)
					? a.writersplitamendmentidentifier
					: "00000000-0000-0000-0000-000000000000",
				writersplitidentifier: a.hasOwnProperty("writersplitidentifier")
					? a.writersplitidentifier
					: "00000000-0000-0000-0000-000000000000",
			}));

			const data = {
				artistaccountcode: selectedAccount,
				title,
				durationmins,
				durationsecs,
				isremix: isremix === "true",
				containssamples: containssamples === "true",
				ispublicdomain: true,
				alternativetitles: alts,
				performingartistnames: performersNew,
				writersplits: writersplitsNew,
				workcode: song.data.workcode,
				etag: song.data.etag,
				iswc: song.data.iswc,
			};

			updateMutation.mutate(data, {
				onSuccess: (response) => {
					if (response.failedvalidation) {
						setValidationErrorMessage(response.messages);
						setPending(false);
					} else {
						toast.success(`${t("songs:song-updated-toast")}: ${title}`, {
							position: "bottom-right",
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
						});
						song.refetch();
						songs.refetch();
						if (isUpdate) {
							closeForm();
						} else {
							history.push("/songs");
						}
						setPending(false);
					}
				},
				onError: (error) => {
					setMutationErrorMessage(error.message);
					setPending(false);
				},
			});
			setPending(false);
		} else {
			setPending(false);
		}
	};

	const addRecording = async () => {
		setPending(true);
		setMutationErrorMessage("");
		setValidationErrorMessage("");
		// reset mutation
		recordingCreateMutation.reset();
		const valid = checkErrors();
		if (valid) {
			const data = {
				artistaccountcode: selectedAccount,
				title,
				performingartist,
				isrc,
				releasedate,
				workcode: songJustAdded.data.workcode,
				spotifyidentifier:
					spotifySongIdentifier === undefined || spotifySongIdentifier == null
						? null
						: spotifySongIdentifier,
				spotifypopularity:
					spotifySongIdentifier === undefined || spotifySongIdentifier == null
						? null
						: spotifyPopularity,
				spotifypopularitylastupdatedutc:
					spotifySongIdentifier === undefined || spotifySongIdentifier == null
						? null
						: new Date().toISOString(),
				etag: songJustAdded.data.etag,
			};

			recordingCreateMutation.mutate(data, {
				onSuccess: (result, response) => {
					if (response.failedvalidation) {
						setValidationErrorMessage(response.data.messages);
						setPending(false);
					} else {
						addRecordingMeta(result.model.recordingidentifier, null);
						addSongSuccess();
					}
				},
				onError: (error) => {
					setMutationErrorMessage(error.message);
					setPending(false);
				},
			});
		} else {
			setPending(false);
		}
	};

	const addRecordingMeta = (rid, mp3url = null) => {
		recordingMetaMutation.reset();
		let data = {};

		if (suggestion) {
			let imagesArray = [];

			if (suggestion.images) {
				try {
					imagesArray = JSON.parse(suggestion.images);
				} catch (e) {
					console.error("Error parsing images JSON", e);
				}
			}

			data = {
				id: rid,
				spotify_id: suggestion.spotify_song_id ?? "",
				mp3: mp3url,
				thumb_url: imagesArray.length > 0 ? imagesArray[0].url : null,
				song_id: songJustAdded.data.workcode,
			};
		} else {
			data = {
				id: rid,
				spotify_id: spotifyResult ? spotifyResult.id : null,
				mp3: mp3url,
				thumb_url: spotifyResult ? spotifyResult?.album?.images[0].url : null,
				song_id: songJustAdded.data.workcode,
			};
		}

		recordingMetaMutation.mutate(data, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
					toast.success(`${t("songs:song-updated-toast")}: ${title}`, {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					sendProperty({
						property: "last_recording_added",
						value: "CURRENT_DATE",
						email: profile?.data?.email,
					});
					sendEvent({ property: "pe14489857_recording_added" });

					songs.refetch();
					if (suggestion) {
						onComplete();
					} else {
						history.push("/songs");
					}
					setPending(false);
				}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};

	const removeSpotify = () => {
		setTitle("");
		setDurationmins("");
		setDurationsecs("");
		setPerformers(null);
		setAlternativetitles(null);
		setPerformingartist("");
		setIsrc(null);
		setReleasedate(null);
		setSpotifySongIdentifier(null);
		setSpotifyPopularity(null);
		setSpotifyResult(null);
		setReset(reset + 1);
	};

	const setWriterSplitFields = (splitArray) => {
		setSplits(splitArray);
	};

	const handleChangeSearchForRecords = (track) => {
		const tr = JSON.parse(track);
		const date =
			tr.album.release_date?.length === 4
				? `${tr.album.release_date}-01-01`
				: tr.album.release_date;
		setTitle(tr.name);
		setDurationmins(Math.floor(tr.duration_ms / 1000 / 60));
		setDurationsecs(Math.floor((tr.duration_ms / 1000) % 60));
		setPerformers(
			tr.artists.map((a) => ({
				name: a.name,
			}))
		);
		setReset(reset + 1);
		setPerformingartist(tr.artists[0].name);
		setIsrc(tr?.external_ids?.isrc);
		setReleasedate(date);
		setSpotifySongIdentifier(tr.id); // should be 'spotify_song_id' but for some reason, this is the primary key, it's the spotify song id
		setSpotifyPopularity(tr.popularity);
		setSpotifyResult(tr);
	};

	const getOptionsOfRecords = (query) =>
		new Promise((resolve, reject) => {
			if (!query) {
				resolve([]);
				return;
			}
			fetch(
				`https://api.spotify.com/v1/search?q=${query}&type=track&market=GB&limit=50`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${apiToken}`,
					},
				}
			)
				.then((response) => response.json())
				.then(({ tracks }) => {
					resolve(
						tracks.items.map((track) => ({
							value: JSON.stringify(track),
							name: `${track.name} - ${track.artists[0].name}`,
							photo:
								track.album &&
								track.album.images &&
								track.album.images.length > 0
									? track.album.images[0].url
									: null,
						}))
					);
					resolve([]);
					setSpotifyResult(null);
				})
				.catch(reject);
		});

	return (
		<>
			<SubModal
				openSubPortal={addNewWriterModalIsOpen}
				closeSubPortal={setAddNewWriterModalIsOpen}
			>
				<WritersForm modal closeForm={setAddNewWriterModalIsOpen} />
			</SubModal>
			{/* below location.pathname logic necessary to determine if margins for main /songs page need to be added or if element is in dropdown such as adding new song from spotify suggestion area */}
			<div className='flex flex-wrap  lg:min-h-screen-minus-header'>
			<div
					className={`w-full  ${
						location.pathname === "/songs" &&
						"lg:p-6 p-4"
					} ${isTunecore ? "text-white" : ""}`}
				>
					<div className=''>
						{!suggestion && (
							<div className='flex flex-col-reverse items-start justify-between mb-4 sm:items-baseline sm:flex-row'>
								<H2>
									{isUpdate ? t("songs:update-header") : t("songs:add-header")}{" "}
									{t("songs:song")} SAME SPLIT
								</H2>
								{!isUpdate && (
									<RebrandButtonLink
										id='btnSongFormCancel'
										onClick={() => history.push("/songs")}
									>
										{t("songs:cancel-and-return")}
									</RebrandButtonLink>
								)}
							</div>
						)}
						<div>
							{(!id || (id && song.isSuccess && song.data !== null)) && (
								<>
									<div className='w-full'>
										{!isUpdate && !suggestion && (
											<>
												<div
													style={{
														backgroundColor: isTunecore
															? "#333333"
															: highlightColour,
													}}
													className={
														isTunecore
															? "text-white px-12 py-10 rounded-lg"
															: "px-12 py-10 rounded-lg text-old-sentric-dark-gray"
													}
												>
													<h2 className='text-xl'>
														{t("songs:import-from-spotify-header")}
													</h2>
													<Spacer thin />
													<p className='font-sentric-alt'>
														{t("songs:import-from-spotify-text")}
													</p>
													<Spacer thin />
													<div className='flex flex-row items-start justify-center w-full'>
														<Select
															showLabel={false}
															className={`${
																isTunecore
																	? "tunecore-select-alt"
																	: "font-sentric-alt text-base select-search select-grey-bg"
															}  text-black font-semibold`}
															id='txtSongFormSearchSpotify'
															debounce={500}
															onChange={handleChangeSearchForRecords}
															value={
																spotifyResult
																	? {
																			value: JSON.stringify(spotifyResult),
																			name: spotifyResult.name,
																	  }
																	: null
															}
															getOptions={getOptionsOfRecords}
															placeholder={t(
																"songs:search-on-spotify-placeholder"
															)}
															search
															blackIcon={!isTunecore}
														/>
													</div>
													<Spacer thin />
													{!!spotifyResult && (
														<>
															<div>
																<iframe
																	title='preview'
																	src={`https://open.spotify.com/embed/track/${spotifyResult.id}`}
																	height='80'
																	frameBorder='0'
																	allowtransparency='true'
																	allow='encrypted-media'
																	className='w-full lg:w-3/4'
																/>
															</div>
															<Spacer thin />
															<button
																type='button'
																id='btnSongFormRemoveSelection'
																className={`${
																	isTunecore
																		? "text-white hover:text-tunecore-green"
																		: "text-black font-sentric-alt hover:scale-105"
																} underline flex items-center justify-end`}
																onClick={removeSpotify}
															>
																<FaMinusCircle className='mr-2' />{" "}
																<span>{t("songs:remove-selection")}</span>
															</button>
															<Spacer />
															<InnerRadio
																name='agreeaddrecording'
																label={t(
																	"songs:add-spotify-song-as-recording-confirm"
																)}
																value={agreeAddRecording}
																onChange={(e) =>
																	setAgreeAddRecording(e.target.value)
																}
																options={[
																	{ label: t("common:yes"), value: true },
																	{ label: t("common:no"), value: false },
																]}
															/>
														</>
													)}
												</div>
											</>
										)}
										{!isUpdate && !suggestion && (
											<>
												<Spacer />
												<HR />
											</>
										)}
										<Spacer />
										{/* ELEMENT STARTS */}
										<div className='w-full'>
											<h2 className='text-xl'>
												{t("songs:song-details-header")}
											</h2>
											<Spacer thin />
											<div className='flex flex-col lg:flex-row w-full gap-6 lg:gap-12'>
												<div className='w-full space-y-8 flex flex-col justify-between'>
													<Input
														className='form-input'
														id='txtSongFormTitle'
														required
														type='text'
														error={errors.title}
														placeholder={t("songs:title-placeholder")}
														value={title}
														onBlur={() => {
															checkErrors("title");
														}}
														onChange={(e) => {
															setTitle(e.target.value);
														}}
													/>
													<MultipleInput
														id='txtSongFormAltTitle'
														name='txtSongFormAltTitle'
														type='text'
														addAnotherText={t("songs:add-another-title-button")}
														error={errors.alternativetitles}
														onBlur={() => {
															checkErrors("alternativetitles");
														}}
														placeholder={t(
															"songs:alternative-title-placeholder"
														)}
														valueKey='title'
														value={alternativetitles}
														onChange={(arr) => {
															setAlternativetitles(arr);
														}}
														infoTooltip={t(
															"regex:song-alternative-title-description-regex"
														)}
													/>
												</div>
												<div className='w-full space-y-8 flex flex-col justify-between'>
													<div className='flex'>
														<Input
															id='txtSongFormMin'
															className='w-40 lg:w-full mr-2'
															type='number'
															error={errors.durationmins}
															placeholder={t("common:mins-capital")}
															value={durationmins}
															onBlur={() => {}}
															onKeyDown={(e) => {
																if (
																	e.keyCode === 69 ||
																	e.keyCode === 190 ||
																	e.keyCode === 110 ||
																	e.keyCode === 107 ||
																	e.keyCode === 187 ||
																	e.keyCode === 109 ||
																	e.keyCode === 189
																) {
																	e.preventDefault();
																}
															}}
															onChange={(e) => {
																setDurationmins(e.target.value);
															}}
															min='0'
															max='120'
															step='1'
														/>
														<Input
															id='txtSongFormSec'
															className='w-40 lg:w-full'
															type='number'
															error={errors.durationsecs}
															placeholder={t("common:secs-capital")}
															value={durationsecs}
															onBlur={() => {}}
															onKeyDown={(e) => {
																if (
																	e.keyCode === 69 ||
																	e.keyCode === 190 ||
																	e.keyCode === 110 ||
																	e.keyCode === 107 ||
																	e.keyCode === 187 ||
																	e.keyCode === 109 ||
																	e.keyCode === 189
																) {
																	e.preventDefault();
																}
															}}
															onChange={(e) => {
																setDurationsecs(e.target.value);
															}}
															min='0'
															max='59'
															step='1'
														/>
													</div>
													<div>
														<MultipleInput
															name='txtSongFormPerformers'
															required
															type='text'
															maxLength={45}
															reset={reset}
															error={errors.performers}
															placeholder={t("songs:performing-artists")}
															addAnotherText={t(
																"songs:add-another-performer-button"
															)}
															valueKey='name'
															value={performers}
															onBlur={() => {
																checkErrors("performers");
															}}
															onChange={(arr) => {
																setPerformers(arr);
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										<Spacer />
										<HR />
										<Spacer />

										<SongWriterSplitCreate
											onWorkSplitFieldUpdate={setWriterSplitFields}
											onWriterModalOpen={setAddNewWriterModalIsOpen}
											errors={errors}
											existingSplits={splits}
											updateWorkEtag={updateWorkEtag}
										/>

										<Spacer />
										<HR />
										<Spacer />

										<div className='w-full lg:w-2/3'>
											<InnerRadio
												name='isremix'
												label={t("songs:is-this-a-remix")}
												value={isremix}
												onChange={(e) => setIsremix(e.target.value)}
												options={[
													{ label: t("common:yes"), value: true },
													{ label: t("common:no"), value: false },
												]}
												infoTooltip={t("songs:is-this-a-remix-description")}
											/>

											<Spacer />
											<InnerRadio
												name='containssamples'
												label='Does this song contain samples?'
												value={containssamples}
												onChange={(e) => setContainssamples(e.target.value)}
												options={[
													{ label: t("common:yes"), value: true },
													{ label: t("common:no"), value: false },
												]}
												infoTooltip={t("songs:contains-samples-description")}
											/>
										</div>

										<Spacer />
										<HR />
										<Spacer />

										<div className='w-full max-w-xl'>
											<Spacer thin />
											<Spacer />
										</div>
										<Spacer thin />
										<FormError
											mutationError={mutationErrorMessage}
											validationError={validationErrorMessage}
										/>
										<div
											className={`flex flex-wrap items-center gap-4 ${
												suggestion ? "justify-end" : "justify-between"
											}`}
										>
											<>
												<div
													className={`${suggestion ? "order-2" : "order-1"}`}
												>
													<RebrandPrimaryButton
														id={
															isUpdate
																? "btnSongFormUpdateSong"
																: "btnSongFormAddSong"
														}
														onClick={isUpdate ? update : addSong}
														disabled={pending || isLocked}
														pending={pending}
														arrow={!isTunecore}
													>
														{isUpdate
															? t("songs:update-song-button")
															: t("songs:add-song-button")}
													</RebrandPrimaryButton>
												</div>
												<div
													className={`${
														suggestion ? "order-1" : "order-2"
													} mt-2 sm:mt-0 flex justify-center `}
												>
													<RebrandButtonLink
														id={
															isUpdate
																? "btnSongFormCancelUpdateReturn"
																: "btnSongFormCancelReturn"
														}
														onClick={
															isUpdate
																? closeForm
																: () =>
																		suggestion
																			? history.go(0)
																			: history.push("/songs")
														}
													>
														{!suggestion
															? t("songs:cancel-and-return")
															: t("songs:cancel-and-discard")}
													</RebrandButtonLink>
												</div>
											</>
										</div>
									</div>
								</>
							)}
							{id && song.isLoading && <LoadingSmall />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default SongsCreateSameSplit;
