/* eslint-disable no-nested-ternary */
import { FaLock } from "react-icons/fa";
import ButtonIcon from '../images/rebrandIcons/ButtonIcon.png';
import LoadingSmall from './loadingSmall';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function RebrandPrimaryButton(props) {
  const {
    onClick,
    id,
    disabled,
    children,
    smallfont,
    className,
    arrow,
    label,
    pending,
    deletebutton,
    underlined,
  } = props;
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const baseClasses = `px-3.5 py-2 font-bold border rounded-lg uppercase hover:scale-105 transition-all duration-150 focus:ring-2 focus:ring-white focus:scale-105
    ${smallfont ? 'text-xxs' : 'text-xs'}
    ${disabled || pending
      ? 'pointer-events-none border-sentric-rebrand-grey-text text-sentric-rebrand-grey-text'
      : 'border-black'} 
    ${deletebutton ? 'bg-red-500' : 'bg-sentric-rebrand-white text-black'} 
    ${underlined ? 'underline' : ''}
    ${className || ''}`;

  const tunecoreBaseClasses = `px-3 py-5 text-white font-bold rounded-full uppercase tracking-wider tunecore-button-primary
    ${smallfont ? 'text-xxs' : 'text-xs'}
    ${disabled || pending
      ? 'pointer-events-none border-sentric-rebrand-grey-text text-sentric-rebrand-grey-text'
      : ''} 
    ${deletebutton ? 'bg-tunecore-error-red' : 'bg-sentric-rebrand-white text-black'} 
    ${underlined ? 'underline' : ''}
    ${className || ''}`;

  return (
    <>
      {!isTunecore ? (
        <button
          onClick={onClick}
          id={id}
          disabled={disabled}
          type="button"
          className={baseClasses}
        >
          <div className="flex items-center">
            {children}
            <p>{label}</p>
            {disabled ? (
              <FaLock className="h-2.5 inline ml-3" />
            ) : pending ? (
              <div className="h-2.5 ml-3">
                <LoadingSmall className="inline animate-spin" />
              </div>
            ) : !pending && !disabled && arrow ? (
              <img
                className="h-2.5 ml-3"
                src={ButtonIcon}
                alt="Button Arrow Icon"
              />
            ) : null}
          </div>
        </button>
      ) : (
        <button
          onClick={onClick}
          id={id}
          disabled={disabled}
          type="button"
          style={{
            background: disabled
              ? 'linear-gradient(to bottom right, rgba(0, 239, 134, 0.5), rgba(0, 142, 185, 0.5))'
              : 'linear-gradient(to bottom right, #00ef86, #008eb9)',
            borderRadius: '50px',
            padding: '12px 20px',
          }}
          className={tunecoreBaseClasses}
        >
          <div className="flex items-center">
            {children}
            <p>{label}</p>
            {pending ? (
              <div className="h-2.5 ml-3">
                <LoadingSmall className="inline animate-spin" />
              </div>
            ) : !pending && !disabled && arrow ? (
              <img
                className="h-2.5 ml-3"
                src={ButtonIcon}
                alt="Button Arrow Icon"
              />
            ) : null}
          </div>
        </button>
      )}
    </>
  );
}

export default RebrandPrimaryButton;
