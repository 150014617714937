import {
  FaDotCircle,
  FaPlusCircle,
  FaCalendar,
  FaCalendarPlus,
  FaList,
  FaTv,
  FaBroadcastTower,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import AppState from '../store/appstate';
import { getMyAccount } from '../api/queries';
import { hasAdminRights } from '../auth/shared';
import SideNavItem from '../components/sideNavItem';

function SubSideNav({ menuItems }) {
  return (
    <>
      {menuItems.map((item) => (
        <SideNavItem
          key={item.id}
          id={item.id}
          icon={item.icon}
          to={item.to}
          exact={item.exact}
          title={item.title}
          subtitle={item.subtitle}
          disabled={item.disabled}
        />
      ))}
    </>
  );
}

export default SubSideNav;

function useMenuData() {
  const { t } = useTranslation();
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const adminRights = hasAdminRights(myAccount?.data);

  return {
    t,
    adminRights,
  };
}

function OpportunitiesMenu() {
  const { t, adminRights } = useMenuData();

  const opportunitiesMenuItems = [
    {
      id: 'btnViewOpportunities',
      icon: <FaDotCircle />,
      to: adminRights ? '/opportunities/' : '',
      exact: true,
      title: t('common:overview'),
      subtitle: t('other:opportunities-text'),
      disabled: !adminRights,
    },
    {
      id: 'btnApplyOpportunities',
      icon: <FaPlusCircle />,
      to: adminRights ? '/opportunities/apply' : '',
      title: t('other:apply'),
      subtitle: t('other:opportunities-menu-subheader2'),
      disabled: !adminRights,
    },
  ];

  return <SubSideNav menuItems={opportunitiesMenuItems} />;
}

function SyncsMenu() {
  const { t, adminRights } = useMenuData();

  const syncsMenuItems = [
    {
      id: 'btnViewSyncs',
      icon: <FaTv />,
      to: adminRights ? '/syncs/' : '',
      exact: true,
      title: t('common:overview'),
      subtitle: t('other:syncs-menu-subheader1'),
      disabled: !adminRights,
    },
    {
      id: 'btnSubmitSyncs',
      icon: <FaPlusCircle />,
      to: adminRights ? '/syncs/apply' : '',
      title: t('other:apply'),
      subtitle: t('other:syncs-menu-subheader2'),
      disabled: !adminRights,
    },
  ];

  return <SubSideNav menuItems={syncsMenuItems} />;
}

function BroadcastsMenu() {
  const { t, adminRights } = useMenuData();

  const broadcastMenuItems = [
    {
      id: 'btnViewBroadcasts',
      icon: <FaBroadcastTower />,
      to: '/broadcasts',
      exact: true,
      title: t('other:broadcasts-header'),
      subtitle: t('other:view-broadcasts-subheader'),
    },
    {
      id: 'btnAddBroadcasts',
      icon: <FaPlusCircle />,
      to: adminRights ? '/broadcasts/add' : '',
      title: t('common:add'),
      subtitle: t('other:add-broadcast-details-subheader'),
      disabled: !adminRights,
    },
  ];

  return <SubSideNav menuItems={broadcastMenuItems} />;
}

function AddNewSongMenu() {
  // const { t } = useMenuData();

  const AddNewSongMenuItems = [
    {
      id: 'btnViewBroadcasts',
      icon: <FaBroadcastTower />,
      to: '/songs/create-same-split',
      exact: true,
      title: 'Song Multi-Add',
      subtitle: 'Add multiple songs with the same set of writer splits',
    },
    {
      id: 'btnAddBroadcasts',
      icon: <FaPlusCircle />,
      to: '/songs/create',
      exact: true,
      title: 'Add Song',
      subtitle:'Add a new song with its own writer splits',
    },
  ];

  return <SubSideNav menuItems={AddNewSongMenuItems} />;
}

function GigsMenu() {
  const { t, adminRights } = useMenuData();

  const gigsMenuItems = [
    {
      id: 'btnGigsMenuOverview',
      icon: <FaCalendar />,
      to: '/gigs/',
      exact: true,
      title: t('other:gigs-menu-overview'),
      subtitle: t('other:gigs-menu-overview-subtitle2'),
    },
    {
      id: 'btnGigsMenuClaim',
      icon: <FaCalendarPlus />,
      to: adminRights ? '/gigs/claim' : '',
      exact: true,
      title: t('other:gigs-menu-claim'),
      subtitle: t('other:gigs-menu-claim-subtitle'),
      disabled: !adminRights,
    },
    {
      id: 'btnGigsMenuSetlists',
      icon: <FaList />,
      to: adminRights ? '/gigs/setlists' : '',
      exact: true,
      title: t('other:gigs-menu-setlists'),
      subtitle: t('other:gigs-menu-setlists-subtitle'),
      disabled: !adminRights,
    },
    {
      id: 'btnGigsMenuCreate',
      icon: <FaPlusCircle />,
      to: adminRights ? '/gigs/setlists/create' : '',
      title: t('other:gigs-menu-add-setlist'),
      subtitle: t('other:gigs-menu-add-setlist-subtitle'),
      disabled: !adminRights,
    },
  ];

  return <SubSideNav menuItems={gigsMenuItems} />;
}

export {
 OpportunitiesMenu, GigsMenu, SyncsMenu, BroadcastsMenu, AddNewSongMenu,
};
