import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSpinner } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { saveUserMeta } from "../../api/mutations";
import { getUserMeta } from "../../api/queries";
import FormError from "../../components/formError";
import BackButton from "../../components/backButton";
import Select from "../../components/select";
import Spacer from "../../components/spacer";
import Loading from "../../layouts/loading";
import AppState from "../../store/appstate";
import useSiteConfigUnAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import AuthPrimaryButton from "../../components/authPrimaryButton";
import getColours from "../../helpers/getColours";

const cookies = new Cookies();

function Onboarding3() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();

	const userMeta = getUserMeta();
	const saveUserMetaMutation = saveUserMeta();
	const onboarding = AppState.useState((s) => s.onboarding);
	const [terms, setTerms] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
	const [valid, setValid] = useState(true);
	const [pending, setPending] = useState(false);

	const { data: domainConfig, isSuccess: domainConfigIsSuccess } =
		useSiteConfigUnAuth();
	const { onboardingHighlightColour } = getColours(domainConfig);

	const saveUser = async () => {
		setValid(false);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
		setPending(true);
		const data = onboarding;

		saveUserMetaMutation.reset();

		saveUserMetaMutation.mutate(data, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
				userMeta.refetch();
				setTimeout(() => {
					history.push("/");
					setPending(false);
				}, 2000);
				}
			},	
			onError: (error) => {
				setValid(true);
				setMutationErrorMessage(`${error}`);
				setPending(false);
			},
		});
	};

	const howHear = AppState.useState((s) => s.onboarding?.howHear);
	useEffect(() => {
		if (cookies.get("s-ref")) {
			AppState.update((s) => {
				s.onboarding.howHear = cookies.get("s-ref");
			});
		}
	}, [cookies.get("s-ref")]);

	useEffect(() => {
		if (
			domainConfig?.hide_terms_and_conditions &&
			domainConfig?.show_hear_about === false
		) {
			setPending(true);
			saveUser();
		}
	}, [domainConfig.show_hear_about]);

	if (domainConfigIsSuccess) {
		const hearAboutOptions = domainConfig.hear_about_options || [];

		// Map through the hear_about_options array
		const options = hearAboutOptions.map((o) => ({ name: o, value: o }));

		if (
			cookies.get("s-ref") &&
			!options.find((o) => o.name === cookies.get("s-ref"))
		) {
			options.push({ name: cookies.get("s-ref"), value: cookies.get("s-ref") });
		}

		return (
			<div className='container mx-auto'>
				<div className='flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer'>
					{domainConfig?.hide_terms_and_conditions &&
						domainConfig?.show_hear_about === false &&
						pending && (
							<div className='text-4xl animate-spin'>
								<FaSpinner className='align-center' />
							</div>
						)}
					{domainConfig?.show_hear_about && (
						<>
							<h1 className='text-6xl'>
								{t("onboarding:how-did-you-hear-header")}
							</h1>
							<Spacer />
							<Select
								name='hearabout'
								className='custom-select text-old-sentric-dark-gray w-full lg:w-1/3'
								placeholder={t("onboarding:please-select")}
								value={howHear}
								onChange={(option) => {
									AppState.update((s) => {
										s.onboarding.howHear = option;
									});
								}}
								options={options}
								authSearch
								list
							/>
							<Spacer />
						</>
					)}
					{!domainConfig?.hide_terms_and_conditions && (
						<div className='w-full lg:w-1/3'>
							<div>
								<label className='text-xl ' htmlFor='scales'>
									<input
										style={{ accentColor: onboardingHighlightColour }}
										type='checkbox'
										id='scales'
										name='scales'
										className='mr-4'
										value={terms}
										onClick={(e) => setTerms(e.target.checked)}
									/>
									{t("onboarding:to-continue-please-read")}{" "}
									<a
										className='underline'
										target='_blank'
										href='/terms-and-conditions'
									>
										{t("onboarding:terms-and-conds")}
									</a>
								</label>
							</div>
							<Spacer />
							{terms}
							<FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />

							<div className='flex flex-row justify-evenly '>
								<BackButton onClick={() => history.goBack()}>
									{t("onboarding:back-button")}
								</BackButton>
								<AuthPrimaryButton
									pending={pending}
									disabled={!terms || !valid}
									onClick={saveUser}
								>
									{t("onboarding:next-step-button")}
								</AuthPrimaryButton>
							</div>
							<Spacer />
						</div>
					)}
				</div>
			</div>
		);
	}
	return <Loading />;
}

export default Onboarding3;
