import { useState } from "react";
import { useTranslation } from "react-i18next";
import useDomainConfigAuth from "../api/sanity/queries/getSiteConfigWithDomain";

export default function FormError(props) {
	const [showError, setShowError] = useState(false);
	const toggleError = () => setShowError(!showError);
	const { mutationError, validationError } = props;
	const { data: domainConfig } = useDomainConfigAuth();
	const supportEmail = domainConfig?.support_email;
	const isTunecore = domainConfig?.display_name === "TuneCore";
  const { t } = useTranslation('security');

	return (
		<>
			{mutationError && mutationError.length > 0 && (
				<div className='w-4/5 mb-8 text-sm inline-block p-6 bg-red-700 mt-2 text-white rounded-lg border-white border'>
					<p className='mb-4'>
            {t('security:mutation-error-message')}
					</p>
            <p>
              {t('security:for-support-prefix')}{' '}
            {!isTunecore ? (
              <a
                className='underline'
                href={
                  supportEmail
                    ? `mailto:${domainConfig.support_email}`
                    : "mailto:support@sentricmusic.com"
                }
              >
                {supportEmail
                  ? domainConfig.support_email
                  : "support@sentricmusic.com"}
              </a>
            ) : (
              <a
                className='underline'
                target='_blank'
                href='https://support.tunecore.com/hc/en-us/requests/new?ticket_form_id=662748'
                rel='noreferrer'
              >
                tunecore.com/support
              </a>
            )}
            </p>
					<button
						type='button'
						onClick={toggleError}
						className='mb-4 mt-4 bg-sentric-rebrand-teal text-white font-bold py-2 px-4 rounded-lg'
					>
						{showError ? t('security:hide-error') : t('security:show-error')}
					</button>
					{showError && <div className='text-white mt-2'>{mutationError}</div>}
				</div>
			)}

			{validationError && validationError.length > 0 && (
				<div>
					<p className='w-4/5 text-base inline-block p-6 mb-8 bg-red-700 mt-2 text-white rounded-lg border-white border'>
						{validationError}
					</p>
				</div>
			)}
		</>
	);
}
