/* eslint-disable jsx-a11y/control-has-associated-label */
import { format } from 'date-fns';
import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
  getMyAccounts, useTopGigs,
} from '../api/queries';
import ChangeAccountModal from './ChangeAccountModal';
import ChooseAccountModal from './ChooseAccountModal';
import LoadingSmall from './loadingSmall';
import Table from './table';
import RebrandButtonLink from './rebrandButtonLink';
import getColours from '../helpers/getColours';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

const TopGigs = () => {
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const { t } = useTranslation('dashboard');
  const myAccounts = getMyAccounts();
  const topGigs = useTopGigs({ accounts: myAccounts?.data?.artistaccounts });
  const { highlightColour } = getColours();
  const [switchModal, setSwitchModal] = useState(null);
  const [accountChoiceModal, setAccountChoiceModal] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: t('dashboard:ra-column-venue'),
        accessor: 'venueName',
        width: '33%',
        id: 'colTopGigsVenue',
      },
      {
        Header: t('dashboard:ra-column-date'),
        accessor: 'created',
        width: '33%',
        id: 'colTopGigsDate',
        Cell: ({ row }) => (
          ((row?.values?.colTopGigsDate && row?.values?.colTopGigsDate !== '0000-00-00') ? format(Date.parse(row.values.colTopGigsDate), 'yyyy-MM-dd') : null)
        ),
      },
      {
        Header: t('dashboard:ra-column-performer'),
        accessor: 'performer',
        width: '30%',
        id: 'colTopGigsPerformer',
      },
      {
        Header: '',
        accessor: 'expander',
        width: '5%',
        Cell: () => (
          <>
              <div className="float-right"><FaChevronRight /></div>
          </>
        ),
      },
    ],
  );

  const onRowClick = (row) => {
    const song = row.original;
    // switch account, and go to gigs
    setSwitchModal({
      artistaccountcode: song.artistaccountcode,
      cataloguecode: song.catalogueCode,
      name: song.accountname
      });
  };

  return (
    <>
      {!!switchModal
        && <ChangeAccountModal accounts={myAccounts?.data?.artistaccounts} to="/gigs" setSwitchModal={setSwitchModal} account={switchModal} />}
      {!!accountChoiceModal
        && (
        <ChooseAccountModal
          title={t('dashboard:choose-account-gigs-modal-header')}
          to="/gigs/claim"
          setAccountChoiceModal={setAccountChoiceModal}
          accounts={myAccounts?.data?.artistaccounts}
          nonAdminTo="/gigs/"
        />
        )}
        <div className={`${isTunecore ? 'bg-tunecore-dark-element text-white' : 'bg-white text-black'} w-full lg:w-1/2 p-8 rounded-lg`}>
          <div className="flex flex-row justify-between items-center mb-5">
            <h2 className="font-medium text-xl">{t('dashboard:recently-added-gigs-header')}</h2>
            <RebrandButtonLink
              plusIcon
              id="btnTopGigAddGig"
              onClick={() => { setAccountChoiceModal(true); }}
            >
              {t('dashboard:add-new-gig-link')}
            </RebrandButtonLink>
          </div>
          {topGigs.isLoading
          && <LoadingSmall />}
          {topGigs.isSuccess
          && (
          <div>
            <Table
              area="TopGigs"
              data={topGigs.data}
              columns={columns}
              min={5}
              highlightColour={highlightColour}
              revealComponent={null}
              onRowClick={onRowClick}
              searchSuffix={t('common:gigs')}
              disableSearch
              bgClass={`${isTunecore ? 'bg-tunecore-dark-element' : 'bg-white'} rounded-lg p-0`}
              sortBy={{
                id: 'verifieddate',
                desc: true,
              }}
            />
          </div>
          )}
          {topGigs.isError
          && (
          <div>
            <p>{t('dashboard:recent-gigs-error')}</p>
          </div>
          )}
        </div>
    </>
  );
};

export default TopGigs;
