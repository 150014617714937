/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import getColours from "../helpers/getColours";

function NumericalInput(props) {
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour2 } = getColours();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const {
		value,
		pattern,
		accept,
		placeholder,
		maxLength,
		id,
		name,
		type,
		error,
		required,
		showLabel = true,
		onChange = () => {},
		onKeyDown = () => {},
		className = null,
		min,
		max,
		step,
		disabled,
		isPercentage = false,
	} = props;

	const ref = useRef();
	const v = value;

	useEffect(() => {
		if (error) {
			ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
		}
	}, [error]);

	const [isHovered, setIsHovered] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	return (
		<div ref={ref} className={`${className}`}>
			{showLabel && !isPercentage && (
				<label
					className={[
						`pointer-events-none flex text-sm items-center pb-0.5 ${
							isTunecore
								? "text-white opacity-70"
								: "font-sentric-alt font-semibold text-sentric-rebrand-grey-text"
						}`,
					]}
					htmlFor={name}
				>
					{placeholder}
				</label>
			)}
			<div
				className={`rounded-lg relative block w-full ${
					required ? "required-star" : ""
				} ${
					disabled ? "bg-gray-100 opacity-60" : ""
				} rounded-lg font-sentric-alt bg-transparent font-semibold`}
			>
				<input
					style={{
						borderColor: isFocused || isHovered ? highlightColour2 : undefined,
						boxShadow:
							isFocused || isHovered
								? `0 0 0 2px ${highlightColour2}`
								: undefined,
					}}
					disabled={disabled}
					className={[
						"rounded-lg border-0 focus:outline-none focus:ring-2 w-full text-sm font-semibold p-2 pl-3 pr-3",
						(!isPercentage && v && v.toString().length > 0) ||
						v === 0 ||
						["file"].includes(type)
							? ""
							: "",
					].join(" ")}
					id={id}
					name={name}
					value={v || ""}
					type={type || "number"}
					onChange={onChange}
					onKeyDown={onKeyDown}
					min={min}
					pattern={pattern}
					max={max}
					maxLength={maxLength}
					step={step}
					accept={accept}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					onFocus={() => setIsFocused(true)}
					onBlur={(e) => {
						setIsFocused(false);
						onBlur(e);
					}}
					onWheel={(event) => event.currentTarget.blur()}
				/>
			</div>
			{!!error && (
				<p
					className='w-4/5 font-sentric-alt text-sm inline-block p-1 pr-3 pl-3 bg-red-700 mt-2 text-white rounded-lg border-white border'
				>
					{error}
				</p>
			)}
		</div>
	);
}

export default NumericalInput;
