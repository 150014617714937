/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React from 'react';
import { useTable, usePagination } from 'react-table';
import TablePagination from './tablePagination';
import useWindowDimensions from '../helpers/useWindowDimensions';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function SmallTable({
  columns, data,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0 } }, usePagination);
  const { width } = useWindowDimensions();
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  return (
    <div>

      <div className={`${isTunecore? 'text-white' : 'text-old-sentric-table-text'} overflow-hidden rounded-lg`}>
        {width > 768
          && (
            <table className={`${isTunecore ? 'divide-y divide-tunecore-black' : 'divide-y divide-gray-200'} min-w-full text-sm`} {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr style={{ backgroundColor: isTunecore ? '#333333' : '#E9E8E8'}} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className={`px-6 py-3 text-left text-xxs font-semibold ${isTunecore ? 'text-white' :  'text-sentric-rebrand-grey-text'} uppercase tracking-wider`}
                        {...column.getHeaderProps({
                          style: { minWidth: column.width, width: column.width, maxWidth: column.width },

                        })}
                      >
                        {column.render('Header')}

                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className={`${isTunecore ? 'bg-tunecore-grey-element divide-y divide-tunecore-black' : 'bg-white divide-y divide-old-sentric-border-gray'} w-full font-semibold`}{...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      className="cursor-none"
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <td
                          className="whitespace-wrap px-6 py-4"
                          {...cell.getCellProps()}
                        >
                          <div className="truncate whitespace-normal">
                            {cell.render('Cell')}
                          </div>
                        </td>
                      ))}
                    </tr>

                  );
                })}

              </tbody>
            </table>
          )}
        {width < 768
          && (
            <table className="w-full text-sm">
              <tbody className={`${isTunecore ? 'bg-tunecore-grey-element divide-y divide-tunecore-black' : 'bg-white divide-y divide-old-sentric-border-gray'} flex flex-wrap w-full font-semibold`}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={row.getRowProps().key}>
                      <tr>
                        <td colSpan="10" className="w-full relative flex flex-wrap">
                          {row.cells.map((cell) => (
                            <div
                              {...cell.getCellProps()}
                              className="w-full p-1"
                            >
                              {cell.column.id !== 'expander'
                                && (
                                  <div className="flex w-full">
                                    <div className={`w-5/12 text-xxs font-semibold ${isTunecore ? 'text-white' :  'text-sentric-rebrand-grey-text'} uppercase tracking-wider`}>
                                      {cell.column.Header}
                                    </div>
                                    <div className="flex truncate whitespace-normal w-6/12">
                                      {cell.render('Cell')}
                                    </div>
                                  </div>
                                )}
                              {cell.column.id === 'expander'
                                && (
                                  <div className="absolute top-2 right-4">
                                    {cell.render('Cell')}

                                  </div>
                                )}
                            </div>
                          ))}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
        <TablePagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          totalPages={pageCount}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
        />

      </div>
    </div>
  );
}

export default SmallTable;
