import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Select from "../../components/select";
import Spacer from "../../components/spacer";
import AppState from "../../store/appstate";
import AuthPrimaryButton from "../../components/authPrimaryButton";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";

function Onboarding1() {
  const [pending, setPending] = useState(false);
  const { t } = useTranslation("onboarding");
  const role = AppState.useState((s) => s.onboarding?.role);
  const { data: domainConfig } = useDomainConfigAuth();
  const isTunecore = domainConfig?.display_name === "TuneCore";
  const [showArtistInput, setShowArtistInput] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (role) {
      if (role === "Label") {
        setShowArtistInput(true);
        setSelectedLabel(true);
      } else {
        setShowArtistInput(false);
        setSelectedLabel(false);
      }
    }
  }, [role]);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer">
        <h1 className="text-6xl">{t("onboarding:select-account-role")}</h1>
        <Spacer />
        <div className="w-full lg:w-1/3">
          <Select
            authSearch
            list
            className={`${
              isTunecore
                ? "tunecore-select tunecore-input-hover"
                : "custom-select"
            } text-old-sentric-dark-gray`}
            name="artist"
            placeholder={t("onboarding:select-role-placeholder")}
            value={role}
            onChange={(selected) =>
              AppState.update((s) => {
                s.onboarding.role = selected;
              })
            }
            options={[
              { name: t("onboarding:_band"), value: "Band" },
              { name: t("onboarding:_artist"), value: "Artist" },
              { name: t("onboarding:_songwriter"), value: "Songwriter" },
              { name: t("onboarding:_manager"), value: "Manager" },
              { name: t("onboarding:_label"), value: "Label" },
              { name: t("onboarding:_dj"), value: "DJ" },
              { name: t("onboarding:_producer"), value: "Producer" },
            ]}
          />
          {showArtistInput && (
            <>
              <div className="flex justify-center w-full flex-col items-center">
                <Spacer />
                <p className="w-4/5">

                  {t("onboarding:label-error-message")} 
                  <a
                    href="https://share.hsforms.com/1HFoqTJMbSxOiOPIYDoVLSg8mkg1"
                    target="_blank" rel="noreferrer" className="underline" >
                    {t("onboarding:label-error-message-2")}
                  </a>
                  {t("onboarding:label-error-message-3")}
                </p>
              </div>
            </>
          )}
          <Spacer />
          <AuthPrimaryButton
            disabled={!role || selectedLabel}
            pending={pending}
            onClick={() => history.push("/onboarding-2") && setPending(true)}
            arrow
          >
            {t("onboarding:next-step-button")}
          </AuthPrimaryButton>
          <Spacer />
        </div>
      </div>
    </div>
  );
}

export default Onboarding1;
