/* eslint-disable no-nested-ternary */
import { useRef, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { createPortal } from "react-dom";
import getColours from "../helpers/getColours";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";

function Modal({ children, openPortal, closePortal, smallModal }) {
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour } = getColours(siteConfig);
	const [isHovering, setIsHovering] = useState(false);
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const modalRef = useRef(null);
	const mountElement = document.getElementById("modals");

	return createPortal(
		openPortal && (
			<div
				className={`fixed top-0 left-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-75 ${
					isTunecore ? "font-tunecore" : "font-sentric-alt"
				}`}
			>
				<div
					ref={modalRef}
					className={`overflow-y-auto ${
						smallModal ? "w-3/4 md:w-1/2 h-auto" : "w-3/4 h-5/6 md:h-2/3"
					} p-4 lg:p-8 rounded-lg ${
						isTunecore
							? "bg-tunecore-dark-element"
							: "bg-sentric-rebrand-light-grey"
					}`}
				>
					<button
						type='button'
						onMouseEnter={() => setIsHovering(true)}
						onMouseLeave={() => setIsHovering(false)}
						onClick={() => closePortal()}
						style={{
							color: `${isHovering ? highlightColour : ""}`,
						}}
						className={`flex float-right text-3xl ${
							isTunecore
								? "text-white hover:text-tunecore-green focus:text-tunecore-green"
								: ""
						}`}
						tabIndex={0} // Make the button focusable
						aria-label='Close Modal' // Provide a descriptive label
					>
						<IoCloseCircleOutline />
					</button>
					<>{children}</>
				</div>
			</div>
		),
		mountElement
	);
}

export default Modal;
