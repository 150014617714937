/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import ScriptLoader from 'react-script-loader-hoc';
import Spacer from '../../components/spacer';
import BackButton from '../../components/backButton';
import Select from '../../components/select';
import Input from '../../components/input';
import ButtonLink from '../../components/buttonLink';
import AppState from '../../store/appstate';
import { saveUserMeta } from '../../api/mutations';
import { getMyAccounts, getUserMeta } from '../../api/queries';
import FormError from '../../components/formError';
import countries from '../../data/countries';
import useSiteConfigUnAuth from '../../api/sanity/queries/getSiteConfigWithDomain';
import AuthPrimaryButton from '../../components/authPrimaryButton';

function Onboarding2({ scriptsLoadedSuccessfully }) {
  const { t } = useTranslation(['onboarding', 'other', 'countries']);
  const history = useHistory();
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [pending, setPending] = useState(false);
  const userMeta = getUserMeta();
  const [renderComponent, setRenderComponent] = useState(false); // Added state for rendering
  const myAccounts = getMyAccounts();
  const noAccounts = myAccounts.isSuccess && myAccounts.data === null;

  const migratedUser = userMeta.isSuccess && userMeta.data === null && !noAccounts;
  const { data:domainConfig } = useSiteConfigUnAuth();

  if (domainConfig.hide_show_address) {
    history.push('/onboarding-3');
  };
  const saveUserMetaMutation = saveUserMeta();

  let service = null;
  let sessionToken = null;
  let geocoder = null;

  if (scriptsLoadedSuccessfully) {
    service = new google.maps.places.AutocompleteService();
    sessionToken = new google.maps.places.AutocompleteSessionToken();
    geocoder = new google.maps.Geocoder();
  }

  const onboarding = AppState.useState((s) => s.onboarding);

  let addressItems = [];

  const [showAddressArea, setShowAddressArea] = useState(false);

  const getAddressDetails = (add, addItems) => {
    const addressItem = addItems.find((a) => a.description === add);

    geocoder.geocode({ placeId: addressItem.place_id }, (place) => {
      const streetNumber = place[0].address_components.find((c) => c.types.includes('street_number'));
      const route = place[0].address_components.find((c) => c.types.includes('route'));
      const town = place[0].address_components.find(
        (c) => c.types.includes('postal_town') || c.types.includes('locality'),
      );
      let county = place[0].address_components.find((c) => c.types.includes('administrative_area_level_2'));
      if (onboarding.country === 'US') {
        county = place[0].address_components.find((c) => c.types.includes('administrative_area_level_1'));
      }

      const postcode = place[0].address_components.find((c) => c.types.includes('postal_code'));
      const country = place[0].address_components.find((c) => c.types.includes('country'));

      const obj = {
        address1: `${streetNumber ? streetNumber.long_name : ''}  ${
          route ? route.long_name : ''
        }`.trim(),
        address2: null,
        town: town ? town.long_name : null,
        county: county ? county.long_name : null,
        postcode: postcode ? postcode.long_name : null,
        country: country ? country.short_name : null,
      };

      AppState.update((s) => {
        s.onboarding.address1 = obj.address1;
        s.onboarding.address2 = obj.address2;
        s.onboarding.town = obj.town;
        s.onboarding.county = obj.county;
        s.onboarding.postcode = obj.postcode;
        s.onboarding.country = obj.country;
      });

      setShowAddressArea(true);
    });
  };

  const updateAddress = (value, part) => {
    const ad = JSON.parse(JSON.stringify(onboarding));
    ad[part] = value;
    AppState.update((s) => {
      s.onboarding = ad;
    });
  };

  const saveUser = async () => {
    setMutationErrorMessage('');
    setValidationErrorMessage('');
    setPending(true);
    const data = onboarding;

    saveUserMetaMutation.reset();

    saveUserMetaMutation.mutate(data, {
      onSuccess: (response) => {
        if (response.failedvalidation) {
          setValidationErrorMessage(response.data.messages);
          setPending(false);
        } else {
        userMeta.refetch();
        setTimeout(() => {
          history.push('/');
          setPending(false);
        }, 2000);
      }
      },
      onError: (error) => {
        setMutationErrorMessage(`${error}`);
        setPending(false);
      },
    });
  };

  // setTimeout to allow for Select country list to be translated
  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderComponent(true);
    }, 1000); // Adjust the delay as needed (in milliseconds)

    return () => clearTimeout(timer); // Clear the timeout when the component unmounts
  }, []); // Empty dependency array ensures the effect runs only once

  if (!renderComponent) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-4xl animate-spin">
          <FaSpinner className="align-center" />
        </div>
      </div>
    );
  }
  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center p-4 text-center min-h-screen-minus-footer">
          <h1 className="text-6xl">{t('onboarding:location-header')}</h1>
        <Spacer />
        <div className="w-full lg:w-1/3">
          <Select
            authSearch
            className='custom-select text-old-sentric-dark-gray'
            name="country"
            placeholder={t('other:select-country-placeholder')}
            value={onboarding.country}
            onChange={(selected) => {
              AppState.update((s) => {
                s.onboarding.country = selected;
              });
            }}
            options={countries.map((option) => ({
              name: t(`countries:${option.name}`),
              value: option.value,
            }))}
            search
          />
          {onboarding.country !== null && (
            <div>
              <Spacer />
              <div className="flex flex-row items-start justify-center w-full">
                <Select
                  customLabel={t('onboarding:first-line-address')}
                  authSearch
                  options={[]}
                  onChange={(add) => {
                    getAddressDetails(add, addressItems);
                  }}
                  className='custom-select font-sentric-alt text-base text-black font-semibold'
                  search
                  placeholder={t('onboarding:first-line-address')}
                  getOptions={(query) => new Promise((resolve) => {
                      if (!query) {
                        resolve([]);
                        return;
                      }
                      service.getPlacePredictions(
                        {
                          componentRestrictions: {
                            country: onboarding.country,
                          },
                          // types: ['address', 'establishment'],
                          strictBounds: false,
                          input: query,
                          sessionToken,
                        },
                        (items, status) => {
                          // eslint-disable-next-line no-console
                          if (
                            status !== google.maps.places.PlacesServiceStatus.OK
                          ) {
                            // reject(status);
                            return;
                          }
                          if (items === null || items.length === 0) {
                            resolve([]);
                            return;
                          }

                          addressItems = items.filter(
                            (a) => a.types.includes('street_address')
                              || a.types.includes('establishment'),
                          );

                          resolve(
                            addressItems.map((item) => ({
                              value: item.description,
                              name: item.description,
                            })),
                          );
                        },
                      );
                    })}
                />
              </div>
              {!showAddressArea && !onboarding.address1 && (
                <>
                  <Spacer thin />
                  <ButtonLink
                    className="text-white underline focus:outline-none"
                    onClick={() => setShowAddressArea(true)}
                  >
                    {t('onboarding:enter-address-manually')}
                  </ButtonLink>
                </>
              )}
              {(showAddressArea || onboarding.address1) && (
                <div>
                  <Spacer />
                  <Input
                    label="Address 1"
                    placeholder={t('onboarding:address-placeholder1')}
                    whiteLabelText
                    value={onboarding.address1}
                    onChange={(e) => updateAddress(e.target.value, 'address1')}
                    className='auth-page-input'
                  />
                  <Spacer thin />
                  <Input
                    label="Address 2"
                    placeholder={t('onboarding:address-placeholder2')}
                    whiteLabelText
                    value={onboarding.address2}
                    onChange={(e) => updateAddress(e.target.value, 'address2')}
                    className='auth-page-input'
                  />
                  <Spacer thin />
                  <Input
                    label="Town"
                    placeholder={t('onboarding:town-placeholder')}
                    whiteLabelText
                    value={onboarding.town}
                    onChange={(e) => updateAddress(e.target.value, 'town')}
                    className='auth-page-input'
                  />
                  <Spacer thin />
                  <Input
                    label="County"
                    placeholder={t('onboarding:county-placeholder')}
                    whiteLabelText
                    value={onboarding.county}
                    onChange={(e) => updateAddress(e.target.value, 'county')}
                    className='auth-page-input'
                  />
                  <Spacer thin />
                  <Input
                    label="Postcode"
                    whiteLabelText
                    placeholder={t('onboarding:postcode-placeholder')}
                    value={onboarding.postcode}
                    onChange={(e) => updateAddress(e.target.value, 'postcode')}
                    className='auth-page-input'
                  />
                </div>
              )}
            </div>
          )}
          <Spacer />
          <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />

          <div className='flex flex-row justify-evenly '>
            <BackButton onClick={() => history.goBack()}>
              {t('onboarding:back-button')}
            </BackButton>
                <AuthPrimaryButton
                  pending={pending}
                  disabled={!onboarding.address1 || pending}
                  onClick={() => {
                    if (migratedUser) {
                      saveUser();
                    } else {
                      history.push('/onboarding-3');
                    }
                  }}
                  >
                  {t('onboarding:next-step-button')}
                </AuthPrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScriptLoader(
  'https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyBxO6c270Mh4JPSCijKoZwJ1oQEHkRHH9A',
)(Onboarding2);
