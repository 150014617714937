/* eslint-disable no-nested-ternary */
// import { format } from 'date-fns';
import { useTranslation } from "react-i18next";
import { format } from "date-fns/esm";
import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Table from "../../../../components/table";
import SongSuggestionDetails from "./SongSuggestionDetails";
import {
	PlusIconPlus,
	PlusIconMinus,
} from "../../../../components/svg/PlusIcon";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

// Our table component
function SuggestionsTable({
	// eslint-disable-next-line no-unused-vars
	data,
	highlightColour,
}) {
	const { t } = useTranslation("songs");
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const columns = React.useMemo(
		() => [
			{
				Header: () => null,
				accessor: "images",
				width: "80",

				Cell: ({ row }) => {
					let image = null;
					if (row?.values?.images && row?.values?.source === "Spotify") {
						image = JSON.parse(row?.values?.images)?.[2]?.url;
					} else if (
						row?.values?.images &&
						(row?.values?.source === "TuneCore" ||
							row?.values?.source === "TuneCore & Spotify")
					) {
						image = row?.values?.images.small_url;
					}
					
					return (
						<div className='bg-gray-700 w-10 h-10 hidden lg:inline-block'>
							{image ? (
								<img
									alt='thumb'
									title='thumb'
									src={image}
									className='bg-gray-700 w-10 h-10 inline-block'
								/>
							) : (
								<div className='bg-gray-700 w-10 h-10 inline-block' />
							)}
						</div>
					);
				},
			},
			{
				Header: t("songs:spotify-table-name"),
				accessor: "title",
				width: "25%",
			},
			{
				Header: t("songs:release-date"),
				accessor: "release_date",
				width: "20%",
				Cell: ({ row }) =>
					row?.values?.release_date &&
					row?.values?.release_date !== "0000-00-00"
						? format(Date.parse(row.values.release_date), "yyyy-MM-dd")
						: null,
			},
			{
				Header: t("songs:spotify-table-performers"),
				accessor: "artists",
				width: "25%",
				Cell: ({ row }) =>
					row?.values?.artists.replace(/,/g, ", ").replace(/&#44;/g, ","),
			},
			{
				Header: t("songs:spotify-table-suggested-by"),
				accessor: "source",
				width: "20%",
			},
			{
				// Make an expander cell
				Header: () => null, // No header
				id: "expander", // It needs an ID
				width: "7%",

				Cell: ({ row }) => (
					// Use Cell to render an expander for each row.
					// We can use the getToggleRowExpandedProps prop-getter
					// to build the expander.
					<>
						{isTunecore ? (
							<span className='float-right'>
								{row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
							</span>
						) : (
							<span className='float-right'>
								{row.isExpanded ? (
									<PlusIconMinus className='h-4' fill={highlightColour} />
								) : (
									<PlusIconPlus className='h-4' fill={highlightColour} />
								)}
							</span>
						)}
					</>
				),
				// We can override the cell renderer with a SubCell to be used with an expanded row
				SubCell: () => null, // No expander on an expanded row
			},
		],
		[]
	);

	return (
		<Table
			data={data.sort((a, b) => a.popularity - b.popularity)}
			sortBy={{}}
			columns={columns}
			highlightColour={highlightColour}
			searchSuffix={t("common:songs-search-suffix")}
			min={5}
			getRowId={React.useCallback((row) => row.isrc, [])}
			noneMessage={t("songs:spotify-table-none-message")}
			revealComponent={<SongSuggestionDetails />}
		/>
	);
}

export default SuggestionsTable;
