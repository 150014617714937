/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserMeta } from "../../api/queries";
import AuthPrimaryButton from "../../components/authPrimaryButton";
import BackButton from "../../components/backButton";
import OnboardingSongSuggestions from "./signupComponents/OnboardingSongSuggestions";
import OnboardingGigs from "./signupComponents/OnboardingGigs";
import useSiteConfigUnAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import OnboardingSocials from "./signupComponents/OnboardingSocials";
import OnboardingWelcome from "./signupComponents/OnboardingWelcome";
import OnboardingAccountRole from "./signupComponents/OnboardingAccountRole";
import OnboardingAddress from "./signupComponents/OnboardingAddress";
import OnboardingHowHear from "./signupComponents/OnboardingHowHear";
import OnboardingArtistName from "./signupComponents/OnboardingArtistName";

function OnboardingHome() {
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	const [isLocked, setIsLocked] = useState(true);
	const [pending, setPending] = useState(false);
	const { t } = useTranslation("onboarding");
	const { data: siteConfig } = useSiteConfigUnAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const userMeta = getUserMeta();
	const welcome = t("onboarding:welcome-to");
	const title = siteConfig?.display_name;

	const chosenStages = siteConfig.sign_up_page_array;

	const previousClick = () => {
		if (currentStepIndex > 0) {
			setCurrentStepIndex(currentStepIndex - 1);
		}
	};

	const nextClick = () => {
		if (currentStepIndex < chosenStages.length) {
			setCurrentStepIndex(currentStepIndex + 1);
		}
	};

	let stage4title = "";
	let socialsTitle = t("onboarding:connect-social-header1");

	switch (userMeta?.data?.role) {
		case "Artist":
		case "DJ":
		case "Songwriter":
		case "Producer":
			stage4title = t("onboarding:artist-title");
			break;
		case "Band":
			stage4title = t("onboarding:band-title");
			break;
		case "Label":
		case "Manager":
			stage4title = t("onboarding:add-artist-to-account");
			socialsTitle = t("onboarding:connect-social-header2");
			break;
		default:
			break;
	}

	const getCurrentComponent = (index = currentStepIndex) => {
		if (index === chosenStages.length) {
			return {
				component: <OnboardingWelcome />, // final stage before dashboard.
				name: `${welcome} ${title}`,
				// description:
				// 	"Welcome to our platform! We hope you enjoy your experience.",
				description: t("onboarding:sidebar-desc-welcome"),
			};
		}
		const currentStep = chosenStages[index];
		switch (currentStep) {
			case "account_role":
				return {
					component: <OnboardingAccountRole setIsLocked={setIsLocked} />,
					name: t("onboarding:select-account-role"),
					description: t("onboarding:sidebar-desc-account-role"),
				};
			case "account_address":
				return {
					component: <OnboardingAddress setIsLocked={setIsLocked} />,
					name: t("onboarding:location-header"),
					description: t("onboarding:sidebar-desc-location"),
				};
			case "get_how_hear":
				return {
					component: <OnboardingHowHear setIsLocked={setIsLocked} />,
					name: t("onboarding:how-did-you-hear-header"),
					description: t("onboarding:sidebar-desc-how-hear"),
				};
			case "get_artist_name":
				return {
					component: <OnboardingArtistName setIsLocked={setIsLocked} />,
					name: isTunecore ? t("onboarding:artist-title") : stage4title,
					description: t("onboarding:sidebar-desc-artist-name"),
				};
			case "digitally_released":
				return {
					component: <OnboardingSongSuggestions setIsLocked={setIsLocked} />,
					name: t("onboarding:suggestions-title"), // Song Suggestions
					description: t("onboarding:sidebar-desc-song-suggestions"),
				};
			case "perform_live":
				return {
					component: <OnboardingGigs setIsLocked={setIsLocked} />,
					name: t("onboarding:gigs-title"),
					description: t("onboarding:sidebar-desc-gigs"),
				};
			case "socials":
				return {
					component: <OnboardingSocials setIsLocked={setIsLocked} />,
					name: socialsTitle,
					description: t("onboarding:sidebar-desc-socials"),
				};
			default:
				return {
					component: null,
					name: "",
					description: "",
				};
		}
	};

	const getProgressBar = () => {
		const stages = [];
		const totalStages = chosenStages.length;

		for (let i = 0; i < totalStages; i += 1) {
			const { name, description } = getCurrentComponent(i);
			const isLast = i === totalStages - 1;
			const isActive = i === currentStepIndex;
			const isNotActive = i < currentStepIndex;

			stages.push(
				<div key={`stage-${i}`} className='w-full'>
					{isNotActive ? (
						<div className='flex flex-col'>
							<div className='rounded-lg py-4 flex flex-col px-8 text-white blur-sm'>
								<p className='text-base 2xl:text-lg'>{name}</p>
								<p className='text-xs font-sentric-alt'>{description}</p>
							</div>
							{!isLast && (
								<div className='w-[2px] h-3 bg-[#393234] self-center text-blur' />
							)}
						</div>
					) : isActive ? (
						<div className='flex flex-col'>
							<div className={`${isTunecore ? 'bg-tunecore-grey-element text-white' : 'bg-sentric-rebrand-orange text-black'} py-4 rounded-lg flex-col px-8 flex  break-words`}>
								<p className='text-base 2xl:text-lg'>{name}</p>
								<p className='text-xs font-sentric-alt'>{description}</p>
							</div>
							{!isLast && (
								<div className={`w-[2px] h-3 ${isTunecore ? 'bg-tunecore-grey-element' : 'bg-sentric-rebrand-orange'} self-center`} />
							)}
						</div>
					) : (
						<div className='flex flex-col'>
							<div className='flex bg-[#393234] flex-col py-4 px-8 opacity-25'>
								<p className='text-base 2xl:text-lg'>{name}</p>
								<p className='text-xs font-sentric-alt'>{description}</p>
							</div>
							{!isLast && (
								<div className='w-[2px] h-3 bg-[#393234] self-center' />
							)}
						</div>
					)}
				</div>
			);
		}

		return (
			<div className='flex flex-col justify-center items-start text-xl md:text-2xl'>
				{stages}
			</div>
		);
	};

	const StageDots = ({ currentStep, totalSteps }) => (
		<div className='flex justify-center mt-4'>
			{Array.from({ length: totalSteps }).map((_, idx) => (
				<div
					key={`dot-${idx}`}
					className={`mx-1 h-2 rounded-full ${
						idx <= currentStep ? isTunecore ? 'bg-tunecore-green' : 'bg-sentric-rebrand-orange' : "bg-gray-400"
					} ${idx === currentStep ? "w-4" : "w-2"}`}
				/>
			))}
		</div>
	);

	return (
		<>
			<div className='lg:w-full lg:flex lg:flex-row lg:h-full px-8 lg:px-0'>
				<div
					className='w-1/2 xl:w-1/3 2xl:w-1/4 pl-14 py-10 hidden lg:flex flex-col justify-between lg:pr-14'
				>
					<div>{getProgressBar()}</div>
					<StageDots
						currentStep={currentStepIndex}
						totalSteps={chosenStages.length + 1}
					/>
				</div>
				<div className='w-full lg:w-1/2 xl:w-2/3 2xl:w-3/4 lg:mr-14 py-10 flex flex-col justify-between items-center text-center'>
					<div className='flex-grow w-full flex flex-col self-start'>
						<h1 className='text-5xl lg:px-24 xl:px-0 xl:text-6xl'>
							{getCurrentComponent().name}
						</h1>
						{getCurrentComponent().component}
					</div>
					<div
						className={`flex w-full xl:w-1/3 mt-12 lg:mt-0 ${
							currentStepIndex === 0 || currentStepIndex === chosenStages.length
								? "justify-center"
								: "justify-between"
						}`}
					>
						{currentStepIndex > 0 && (
							<BackButton onClick={() => previousClick()}>
								{t("onboarding:back-button")}
							</BackButton>
						)}
						{currentStepIndex < chosenStages.length && (
							<AuthPrimaryButton
								pending={pending}
								disabled={isLocked}
								onClick={() => nextClick() && setPending(true)}
								arrow
							>
								{t("onboarding:next-step-button")}
							</AuthPrimaryButton>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default OnboardingHome;
