import { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import getColours from '../helpers/getColours';

function SideNavItem(props) {
  const {
    icon, to, title, exact, subtitle, disabled, id, match, location,
  } = props;
  const { highlightColour } = getColours();
  const [hovering, setHovering] = useState(false);
  const isActive = match.url === to || (exact && location.pathname === to);
  const inActiveColour = '#A09E9F';
  const activeStyle = {
    color: isActive ? highlightColour : inActiveColour,
  };

  if (!disabled) {
    return (
      <NavLink
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        id={id}
        exact={exact}
        className='rounded-lg xl:rounded-none rb-side-nav-item m-2 xl:m-0 side-nav-item flex justify-start gap-3.5 p-3  xl:pl-7 xl:pr-7 xl:py-12 p z-10 relative'
        to={to}
        activeStyle={activeStyle} // Apply the activeStyle when NavLink is active
      >
        <div
          style={{
            color: hovering || isActive ? highlightColour : inActiveColour,
          }}
          className='text-2xl lg:text-3xl block'
        >
          {icon}
        </div>
        <div className="text-black">
          <p className="font-semibold text-sm mb-1">{title}</p>
          <p className='hidden xs:block font-sentric-alt text-sentric-rebrand-grey-text2 text-sm'>{subtitle}</p>
        </div>
      </NavLink>
    );
  }

  return (
    <div className='rounded-lg xl:rounded-none rb-side-nav-item m-2 xl:m-0 flex justify-start gap-3.5 p-3  xl:pl-7 xl:pr-7 xl:py-12 p z-10 relative'>
      <div className='text-2xl lg:text-3xl text-sentric-rebrand-sidenav-icon-grey'>
        {icon}
      </div>
      <div>
        <p className='font-semibold text-sm mb-1 text-sentric-rebrand-low-opacity-grey'>{title}</p>
        <p className='hidden xs:block font-sentric-alt text-sentric-rebrand-low-opacity-greyext-xs'>{subtitle}</p>
      </div>
    </div>
  );
}

export default withRouter(SideNavItem);
