/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RiErrorWarningFill } from "react-icons/ri";
import { slugify } from '../helpers/helpers';
import Spacer from './spacer';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function VerticalRadio(props) {
  const {
    options, value, onChange, label, name, error, disabled,
  } = props;
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const [id, setId] = useState(null);

  useEffect(() => {
    if (!id) {
      setId(uuidv4());
    }
  }, []);

  if (id) {
    return (
      <>
        {label && (
          <>
            <h2 className={`${isTunecore ? 'text-white' : 'text-black'} font-medium text-base`}>
              {label}
            </h2>
            <Spacer thin />
          </>
        )}

        <div className="flex flex-col">
          {options.map((o) => (
            <div
              className={`${isTunecore ? 'text-white' : 'text-black'} mb-4`}
              key={slugify(`${o.label}${name}${id}`)}
            >
              <input
                onChange={onChange}
                className={`${isTunecore ? 'accent-tunecore-blue' : 'accent-sentric-rebrand-orange'}`}
                id={slugify(`${o.label}${name}${id}`)}
                type="radio"
                name={name}
                value={o.value}
                defaultChecked={value !== null && o.value === value}
                disabled={disabled}
              />
              <label
                className={`p-2 ${disabled ? 'text-sentric-rebrand-grey-text text-opacity-40' : ''} font-sentric-alt text-sm font-base`}
                htmlFor={slugify(`${o.label}${name}${id}`)}
              >
                {o.label}
              </label>
            </div>
          ))}
        </div>
        {!!error && (
          <p className={`w-4/5 ${isTunecore ? 'bg-tunecore-error-red bg-opacity-20 border-none' : 'font-sentric-alt bg-red-700 border-white border'} text-sm inline-block p-1 pr-3 pl-3 mt-2 text-white rounded-lg `}>
          {isTunecore && (
            <span>
              <RiErrorWarningFill className="inline-block text-2xl text-tunecore-error-red mr-2" />
            </span>
          )}
            {error}
          </p>
        )}
      </>
    );
  }
  return <></>;
}

export default VerticalRadio;
