import { slugify, strToBool } from "../helpers/helpers";
import getColours from "../helpers/getColours";
import useSiteConfigUnAuth from "../api/sanity/queries/getSiteConfigWithDomain";

function Radio(props) {
	const { options, value, name, onChange } = props;
	const { data: domainConfig } = useSiteConfigUnAuth();
	const { onboardingHighlightColour } = getColours(domainConfig);

	return (
		<div className='flex w-full lg:w-1/3 flex-row justify-evenly'>
			{options.map((o) => (
				<div
					key={slugify(o.label)}
					className='p-4 bg-white w-1/3 bg-opacity-10 rounded-lg text-white font-bold'
				>
					<input
						onChange={onChange}
						style={{ accentColor: onboardingHighlightColour }}
						className='text-old-sentric-dark-gray cursor-pointer'
						id={slugify(o.label)}
						type='radio'
						name={name}
						value={o.value}
						defaultChecked={value !== null && o.value === strToBool(value)}
					/>
					<label className='p-2' htmlFor={slugify(o.label)}>
						{o.label}
					</label>
				</div>
			))}
		</div>
	);
}

export default Radio;
