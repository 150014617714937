/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-unreachable */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { postSync } from "../../../../api/mutations";
import {
	getMyAccount,
	getMySongs,
	getSyncs,
	getUserMeta,
	getMyProfile,
	getMyWorkAutoSuggest,
} from "../../../../api/queries";
import FormError from "../../../../components/formError";
import ButtonInner from "../../../../components/buttonInner";
import ButtonIcon from "../../../../images/rebrandIcons/ButtonIcon.png";
import H2 from "../../../../components/h2";
import H4 from "../../../../components/h4";
import MultipleSelect from "../../../../components/multipleSelect";
import Select from "../../../../components/select";
import Spacer from "../../../../components/spacer";
import TextArea from "../../../../components/textArea";
import { sendEvent } from "../../../../helpers/helpers";
import AppState from "../../../../store/appstate";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useSyncsQuery from "../../../../api/sanity/queries/getSyncs";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function SyncsApply(props) {
	const { t } = useTranslation("other");
	const { id } = useParams();
	const [opp, setOpp] = useState(id || null);
	const [songList, setSongList] = useState();
	const [text, setText] = useState();
	const [pending, setPending] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
	const [errors, setErrors] = useState({});
	const [searchString, setSearchString] = useState("");
	const [options, setOptions] = useState([]);
	const [cachedSongs, setCachedSongs] = useState([]);
	const [noOptionsMessage, setNoOptionsMessage] = useState("Start typing to search...");
	const fetchOptions = useCallback(
		debounce((inputValue) => {
			if (inputValue.length > 0) {
				setSearchString(inputValue);
			}
		}, 500),
		[]
	);
	const handleInputChange = (inputValue) => {
		fetchOptions(inputValue);
	};

	const selectedAccount = AppState.useState((s) => s.selectedAccount);

	const works = getMyWorkAutoSuggest({
		aac: selectedAccount,
		searchstring: searchString,
	});

	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});
	const userInfo = getMyProfile();
	const createMutation = postSync();

	const history = useHistory();
	const { data: siteConfig } = useSiteConfigAuth();
	const noSyncs = siteConfig?.disable_syncs;

	useEffect(() => {
		if (works && works?.data?.works) {
			const fetchedOptions = works?.data.works.map((songData) => {
				const songOption = {
					label: `${songData.title} - ${songData.performingartists}`,
					value: songData.workcode,
				};
				return songOption;
			});
			setOptions(fetchedOptions);
			setCachedSongs((prevCachedSongs) => [
				...prevCachedSongs,
				...works?.data?.works,
			]);
			setNoOptionsMessage(
				fetchedOptions.length === 0
					? "No options found"
					: "Start typing to search..."
			);
		}
	}, [works?.data?.works]);

	useEffect(() => {
		if (noSyncs) {
			history.push("/");
		}
	}, [noSyncs]);

	useEffect(() => {
		if (selectedAccount) {
			works.refetch();
		}
	}, [selectedAccount]);
	// const syncs = getSyncs();
	const { data: syncsData, isSuccess } = useSyncsQuery();

	const checkErrors = () => {
		const errs = {};
		let valid = true;
		if (!opp) {
			errs.opp = t("regex:choose-sync-error");
			valid = false;
		}

		// has at least one performer
		if (!songList || songList.length < 1) {
			errs.songList = t("regex:add-at-least-one-song-error");
			valid = false;
		}

		if (songList) {
			songList.forEach((sl) => {
				if (!sl.workcode) {
					errs.songList = t("regex:select-every-song-error");
					valid = false;
				}
			});
		}

		setErrors(errs);
		return valid;
	};

	const submitApplication = () => {
		setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
		const valid = checkErrors();

		if (valid) {
			const selectedWorkcodes = songList.map((s) => s.workcode);
			const selectedSongs = selectedWorkcodes.map((workcode) => {
				const song = songList.find((s) => s.workcode === workcode);
				const cachedSong = cachedSongs.find(
					(option) => option.workcode === workcode
				);
				return {
					workcode,
					title: song.title || (cachedSong ? cachedSong.title : ""),
				};
			});

			const data = {
				type: "sync",
				songs: JSON.stringify(selectedSongs),
				content: text,
				opportunity:
					syncsData && opp
						? syncsData.find((sync) => parseInt(sync._id) === parseInt(opp))
								?.name
						: "",
				username: userInfo.data.username,
				email: userInfo.data.email,
				artist: myAccount.data.accountname,
			};

			createMutation.mutate(data, {
				onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
					toast.success(t("other:successfully-submitted-sync-toast"), {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					history.push("/syncs");
					setPending(false);
					sendEvent({ property: "pe14489857_sync_brief_applied_for" });
				}
				},
				onError: (error) => {
          setMutationErrorMessage(error.message);
					setPending(false);
				},
			});
		}
		setPending(false);
	};

	return (
		<div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
			<H2>{t("other:apply-for-sync")}</H2>
			<Spacer />
			{isSuccess && (
				<div className='w-full lg:w-2/3'>
					<H4>{t("other:which-sync-header")}</H4>
					<Spacer thin />
					<Select
						list
						id='selectSync'
						required
						className='select-search font-sentric-alt text-base'
						name='opp'
						placeholder={t("other:select-sync-placeholder")}
						value={opp}
						error={errors.opp}
						onChange={(selected) => setOpp(selected)}
						options={syncsData.map((sync) => ({
							name: sync.name,
							value: sync._id,
						}))}
					/>
					<Spacer />
					<H4>{t("other:select-one-song-header")}</H4>
					<Spacer thin />
					<MultipleSelect
						id='txtSyncAddSong'
						type='text'
						addAnotherText={t("other:add-another-song")}
						customLabel={t("other:select-a-song-placeholder")}
						valueKey='workcode'
						value={songList}
						onChange={(arr) => {
							setSongList(arr);
						}}
						error={errors.songList}
						options={options}
						onInputChange={handleInputChange}
						noOptionsMessage={noOptionsMessage}
					/>
					<Spacer />
					<H4>{t("other:tell-us-more-header")}</H4>
					<Spacer thin />
					<TextArea
						id='txtSyncEvidence'
						className='w-full'
						type='textarea'
						error={errors.durationsecs}
						placeholder={t("other:supporting-text-placeholder")}
						value={text}
						onBlur={() => {}}
						onChange={(e) => {
							setText(e.target.value);
						}}
					/>
					<Spacer />
          <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
					<div className='w-full flex justify-end'>
						<RebrandPrimaryButton
							id='btnSyncSubmit'
							pending={pending}
							onClick={submitApplication}
							arrow
						>
							{t("common:submit-button")}
						</RebrandPrimaryButton>
					</div>
					<Spacer />
				</div>
			)}
		</div>
	);
}
export default SyncsApply;
