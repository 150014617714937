import SmallTable from '../../../../components/smallTable';
import Pie from './_pie';

const TableAndPie = ({ buckets, unique, columns }) => (
  <div className="flex flex-wrap">
    <div className="order-2 lg:order-1 w-full lg:w-7/12 p-2 mt-5"><SmallTable data={buckets} columns={columns} /></div>
    <div className="order-1 lg:order-2 w-full lg:w-5/12 flex justify-center"><Pie buckets={buckets} unique={unique} /></div>
  </div>
);

export default TableAndPie;
