/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { elasticQuery } from '../../../../api/mutations';
import LoadingSmall from '../../../../components/loadingSmall';
import Table from '../../../../components/table';
import {
  buildFilters, noDp, twoDp,
} from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import AccountReveal from './Writers/_accountReveal';
import WriterReveal from './Writers/_writerReveal';
import Tabs from './_tabs';
import { indexReducedPattern } from './constants';
import getColours from '../../../../helpers/getColours';

const WritersSection = ({ matchAccounts, dateType }) => {
  const { t } = useTranslation('other');
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState(null);
  const flags = useFlags();

  const { highlightColour } = getColours();

  const range = AppState.useState((s) => s.range);

  const query = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter: [
            {
              range: { [dateType]: range[dateType] },
            },
            ...buildFilters(flags, matchAccounts),

          ],
        },
      },
      aggs: {
        Writers: {
          terms: {
            field: 'writerCode',
            size: 500,
            order: {
              SongValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            SongValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            SongCount: {
              cardinality: {
                field: 'songCode',
              },
            },
            WriterName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: ['writerLastName', 'writerFirstName', 'writerMiddleName'],
                },
              },
            },

          },
        },
        Accounts: {
          terms: {
            field: 'artistAccountCode',
            size: 500,
            order: {
              SongValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            SongValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            SongCount: {
              cardinality: {
                field: 'songCode',
              },
            },
            AccountName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: ['artistAccountName'],
                },
              },
            },

          },
        },
      },
    },
    size: 0,
  };

  const elasticQueryMutation = elasticQuery();
  useEffect(() => {
    setPending(true);

    elasticQueryMutation.mutate(query, {
      onSuccess: (data) => {
        setPending(false);
        setResult(data);
      },
      onError: (error) => {
        console.log(error);
        setPending(false);
        setResult(null);
      },
    });
  }, [range, dateType]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('other:_writer'),
        accessor: (row) => `${row?.WriterName?.hits?.hits?.[0]._source?.writerFirstName} ${row?.WriterName?.hits?.hits?.[0]._source?.writerMiddleName || ''} ${row?.WriterName?.hits?.hits?.[0]._source?.writerLastName}`,
        width: '25%',
      },
      {
        Header: t('other:song-count'),
        accessor: (row) => `${noDp(row?.SongCount?.value)}`,
        width: '20%',
      },
      {
        Header: t('other:earnings2'),
        accessor: (r) => r.SongValues.value,
        Cell: ({ row: { original } }) => (
          <span>
            {`${currencySymbol}${twoDp(original?.SongValues?.value)}`}
          </span>
        ),
        width: '20%',
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        width: '5%',

        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span>
            {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },

    ],
    [],
  );

  const columnsAccounts = React.useMemo(
    () => [
      {
        Header: t('other:_account'),
        accessor: (row) => `${row?.AccountName?.hits?.hits?.[0]._source?.artistAccountName}`,
        width: '25%',
      },
      {
        Header: t('other:song-count'),
        accessor: (row) => `${noDp(row?.SongCount?.value)}`,
        width: '20%',
      },
      {
        Header: t('other:earnings2'),
        accessor: (r) => r.SongValues.value,
        Cell: ({ row: { original } }) => (
          <span>
            {`${currencySymbol}${twoDp(original?.SongValues?.value)}`}
          </span>
        ),
        width: '20%',
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        width: '5%',

        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span>
            {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },

    ],
    [],
  );

  if (!pending && result) {
    return (
      <>
        <Tabs
          tabs={[
            t('other:_writers'),
            t('other:_accounts'),
          ]}
          objects={[

            <div className="pt-4">
              <Table
                data={result?.body?.aggregations?.Writers?.buckets || []}
                columns={columns}
                highlightColour={highlightColour}
                revealComponent={(
                  <WriterReveal dateType={dateType} matchAccounts={matchAccounts} />
        )}
                searchSuffix={t('common:writers')}
                disableSearch
                initialSort={[{
                  id: 'Earnings',
                  desc: true,
                }]}
              />

            </div>,
            <div className="pt-4">
              <Table
                data={result?.body?.aggregations?.Accounts?.buckets || []}
                columns={columnsAccounts}
                highlightColour={highlightColour}
                revealComponent={(
                  <AccountReveal dateType={dateType} matchAccounts={matchAccounts} />
        )}
                searchSuffix={t('common:writers')}
                disableSearch
                initialSort={[{
                  id: 'Earnings',
                  desc: true,
                }]}
              />

            </div>]}
        />
      </>
    );
  }
  return (
    <div className="p-4">
      <LoadingSmall />

    </div>
  );
};

export default WritersSection;
