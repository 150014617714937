/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaSearch, FaSpotify } from "react-icons/fa";
import * as Tabs from "@radix-ui/react-tabs";
import BackButton from "../../components/backButton";
import H1 from "../../components/h1";
import P from "../../components/p";
import getColours from "../../helpers/getColours";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import FormError from "../../components/formError";
import Input from "../../components/input";
import Spacer from "../../components/spacer";
import { getUserMeta } from "../../api/queries";
import AppState from "../../store/appstate";
import ButtonLink from "../../components/buttonLink";
import Select from "../../components/select";
import AuthPrimaryButton from "../../components/authPrimaryButton";

function AddAccount3() {
	const { t } = useTranslation(["onboarding", "common", "songs"]);
	const history = useHistory();
	const userMeta = getUserMeta();
	const addAccount = AppState.useState((s) => s.addAccount);
	const { data: domainConfig } = useDomainConfigAuth();
	const isTunecore = domainConfig?.display_name === "TuneCore";
	const [apiToken, setAPIToken] = useState(null);
	const [validationErrorMessage, setValidationErrorMessage] = useState("");
	const [pending, setPending] = useState(false);

	const [result, setResult] = useState(addAccount.spotify_obj || null);
	const [loading, setLoading] = useState(false);
	const [spotifyURL, setSpotifyURL] = useState("");
	const [fetchMethod, setFetchMethod] = useState(null);

	const [activeTab, setActiveTab] = useState("searchWithURL");

	if (domainConfig.disable_suggestions) {
		history.push("/add-account-4");
	}

	let title = t("songs:link-spotify-artist-title");
	let desc = t("songs:link-spotify-artist-subtitle");
	switch (userMeta?.data?.role) {
		case "Manager":
			title = t("songs:link-spotify-manager-title");
			desc = t("songs:link-spotify-manager-subtitle");
			break;
		default:
			break;
	}

	const activeStyle = {
		backgroundColor: "#1DB954",
		opacity: 1,
		color: "black",
		textDecoration: "underline",
	};

	const inactiveStyle = {
		backgroundColor: isTunecore ? "#333333" : "#868484",
		color: isTunecore ? "white" : "black",
		cursor: "pointer",
	};

	const getSpotifyAPIToken = async () => {
		const apiUrl = AppState.getRawState()?.apiUrl;

		const response = await fetch(`${apiUrl}/spotify-token`);
		const responseData = await response.json();
		return responseData.access_token;
	};

	useEffect(() => {
		async function go() {
			setAPIToken(await getSpotifyAPIToken());
		}
		go();
	}, []);

	const getOptions = (query) =>
		new Promise((resolve, reject) => {
			if (!query) {
				resolve([]);
				return;
			}
			fetch(
				`https://api.spotify.com/v1/search?q=${query}&type=artist&market=GB&limit=50`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${apiToken}`,
					},
				}
			)
				.then((response) => response.json())
				.then(({ artists }) => {
					setFetchMethod("searchWithName"); 
					resolve(
						artists.items.map((artist) => ({
							value: JSON.stringify(artist),
							name: artist.name,
							photo:
								artist.images && artist.images.length > 0
									? artist.images[0].url
									: null,
						}))
					);
				})
				.catch(reject);
		});

	const handleSpotifyURLSubmit = (e) => {
		// if user is pasting spotify URL
		e.preventDefault();
		setLoading(true);

		const spotifyLinkRegex =
			/https:\/\/open\.spotify\.com\/artist\/([a-zA-Z0-9]{22})/;
		const spotifyUriRegex = /spotify:artist:([a-zA-Z0-9]{22})/;

		let artistId = null;
		const spotifyLinkMatch = spotifyURL.match(spotifyLinkRegex);
		const spotifyUriMatch = spotifyURL.match(spotifyUriRegex);

		// array destructuring
		if (spotifyLinkMatch) {
			[, artistId] = spotifyLinkMatch;
		} else if (spotifyUriMatch) {
			[, artistId] = spotifyUriMatch;
		}

		if (artistId) {
			fetch(`https://api.spotify.com/v1/artists/${artistId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${apiToken}`,
				},
			})
				.then((response) => response.json())
				.then((artist) => {
					setResult(JSON.stringify(artist));
					setLoading(false);
					setValidationErrorMessage("");
					setFetchMethod("searchWithURL");
				})
				.catch((error) => {
					console.error("Error fetching artist by ID:", error);
					setLoading(false);
				});
		} else {
			console.error("Invalid Spotify URL, URI, or artist ID");
			setValidationErrorMessage(t("songs:link-spotify-invalid-url-error"));
			setLoading(false);
		}
	};

	const addSpotify = () => {
		const fetchMethodForHubspot = fetchMethod;
		if (result) {
			AppState.update((s) => {
				s.addAccount.spotify = result;
			});
			AppState.update((s) => {
				s.spotifyAccountMethod = fetchMethodForHubspot;
			});
		}
		console.log(AppState.getRawState().addAccount); // Log the state of the addAccount object
	};

	return (
		<div className='container mx-auto'>
			<div className='flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer'>
				<div className='text-4xl md:text-6xl flex items-center flex-col-reverse gap-4'>
					<h1>{title}</h1>
					<span>
						<FaSpotify className='text-6xl text-[#1DB954]' />
					</span>
				</div>
				<Spacer thin />
				<P>{desc}</P>
				<Spacer />
				<Tabs.Root
					className='flex flex-col w-full'
					defaultValue='searchWithURL'
					onValueChange={(value) => {
						setActiveTab(value);
						setResult(null);
						setValidationErrorMessage("");
						setSpotifyURL("");
					}}
				>
					<Tabs.List
						className={`${
							isTunecore
								? "border-2 border-tunecore-light-grey"
								: "border-2 border-sentric-rebrand-mid-grey"
						} shrink-0 w-5/6 lg:w-2/4 self-center md:flex text-sm rounded-lg xl:text-base overflow-hidden`}
					>
						<Tabs.Trigger
							className='px-5 min-h-[52px] xl:h-[45px] hover:underline w-full flex-1 flex items-center justify-center select-none  md:rounded-none'
							value='searchWithURL'
							style={
								activeTab === "searchWithURL" ? activeStyle : inactiveStyle
							}
						>
							{t("songs:link-spotify-tab-header-link")}
						</Tabs.Trigger>
						<Tabs.Trigger
							className='px-5 min-h-[52px] xl:h-[45px] hover:underline flex-1 w-full flex items-center justify-center select-none  md:rounded-none'
							value='searchWithName'
							style={
								activeTab === "searchWithName" ? activeStyle : inactiveStyle
							}
						>
							{t("songs:link-spotify-tab-header-name")}
						</Tabs.Trigger>
					</Tabs.List>
					<Tabs.Content className='p-8' value='searchWithURL'>
						<div className='flex flex-col'>
							<p className={!isTunecore ? "font-sentric-alt" : ""}>
								{t("songs:link-spotify-url-instructions")}{" "}
								<span>
									<a
										className='underline hover:scale-105'
										href='https://support.spotify.com/us/artists/article/finding-your-artist-url/'
									>
										{t("songs:link-spotify-instructions-link")}
									</a>
								</span>
							</p>
							{!result && (
								<>
									<Spacer thin />
									<div className='self-center w-full lg:w-2/3'>
										<Input
											type='text'
											disabled={loading}
											placeholder={t('songs:link-spotify-paste-url')}
											value={spotifyURL}
											onChange={(e) => setSpotifyURL(e.target.value)}
											className='mb-4 self-center auth-page-input'
										/>
										<AuthPrimaryButton
											type='submit'
											onClick={handleSpotifyURLSubmit}
											disabled={loading || !spotifyURL}
											pending={loading}
											className='w-3/4 sm:w-auto'
										>
											{loading ? `${t("common:loading")}...` : t("songs:link-spotify-fetch-profile")}
										</AuthPrimaryButton>
									</div>
								</>
							)}
						</div>
					</Tabs.Content>

					<Tabs.Content
						className={result ? "p-4" : "p-8"}
						value='searchWithName'
					>
						{!result && (
							<div className='flex flex-col'>
								<div className='self-center w-full lg:w-2/3'>
									<Select
										authSearch
										className={`${
											isTunecore
												? "tunecore-select tunecore-input-hover"
												: "custom-select"
										} text-old-sentric-dark-gray`}
										onChange={(artist) => {
											setResult(artist);
										}}
										getOptions={getOptions}
										search
										customLabel={t("songs:link-spotify-account")}
									/>
								</div>
							</div>
						)}
					</Tabs.Content>
				</Tabs.Root>

				{result && (
					<>
						<div
							style={
								isTunecore
									? {
											background:
												"linear-gradient(to bottom right, #a6a6a6, #505050)",
									  }
									: {
											background: "#868484",
									  }
							}
							className={`${
								isTunecore ? "text-white" : "text-old-sentric-dark-gray "
							} rounded-lg shadow-lg flex flex-col sm:w-80`}
						>
							<div className='mx-4 mt-4'>
								{JSON.parse(result).images &&
									JSON.parse(result).images.length > 0 && (
										<img
											height={160}
											width={160}
											className='max-h-52 w-full bg-gray-300 object-cover rounded-lg'
											alt={JSON.parse(result).name}
											src={JSON.parse(result).images[0].url}
										/>
									)}
								{(!JSON.parse(result).images ||
									JSON.parse(result).images.length === 0) && (
									<div className='max-h-52 w-full bg-gray-300 rounded-lg' />
								)}
							</div>
							<div className='flex flex-col justify-between p-4'>
								<h3 className='text-xl md:text-2xl self-center'>
									{JSON.parse(result).name}
								</h3>
								<Spacer thin />

								<AuthPrimaryButton
									textOnly
									className='self-center w-full sm:w-1/3'
									onClick={() => {
										setResult(null);
										setSpotifyURL("");
										AppState.update((s) => {
											s.addAccount.spotify_obj = null;
										});
									}}
								>
									{t("common:remove")}
								</AuthPrimaryButton>
							</div>
						</div>
						<Spacer />
					</>
				)}

				<FormError
					mutationError={null}
					validationError={validationErrorMessage}
				/>

				<div className='flex flex-row w-full lg:w-1/3 justify-evenly'>
					<BackButton onClick={() => history.push("/add-account-1")}>
						{t("onboarding:back-button")}
					</BackButton>
					<AuthPrimaryButton
						disabled={!result}
						pending={pending}
						onClick={() => {
							addSpotify();
							history.push("/add-account-4");
							setPending(true);
						}}
						arrow
					>
						{t("onboarding:next-step-button")}
					</AuthPrimaryButton>
				</div>
				<Spacer />
				<ButtonLink onClick={() => history.push("/add-account-4")}>
					{t("onboarding:skip-step-button")}
				</ButtonLink>
			</div>
		</div>
	);
}

export default AddAccount3;
