/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select } from 'react-select-virtualized';
import Spacer from './spacer';
import H2 from './h3';
import AppState from '../store/appstate';
import { getMyAccountMutation } from '../api/mutations';
import { hasAdminRights } from '../auth/shared';
import { getMyAccount } from '../api/queries';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function ChooseAccountModal({
  to,
  setAccountChoiceModal,
  title,
  accounts,
  nonAdminTo,
}) {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const accountsToSort = JSON.parse(JSON.stringify(accounts));
  const getAccountMutation = getMyAccountMutation();
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const fireOnChange = async (selectedAccountCode) => {
    getAccountMutation.mutate(selectedAccountCode, {
      onSuccess: (accountDetails) => {
        const hasRights = hasAdminRights(accountDetails);
        if (hasRights || nonAdminTo === undefined) {
          history.push(to);
        } else {
          history.push(nonAdminTo);
        }
        setAccountChoiceModal(false);
        AppState.update((s) => {
          s.selectedAccount = selectedAccountCode;
          s.selectedCatalogue = accountDetails.cataloguecode;
        });
      },
      onError: (error) => {
        console.error('There was an error retrieving account details', error);
      },
    });
  };

  if (accounts.length === 1) {
    const detailedAccount = getMyAccount(accounts[0]);
    const hasRights = hasAdminRights(detailedAccount?.data);
    if (hasRights === undefined || hasRights === false) {
      history.push(nonAdminTo);
    } else {
      history.push(to);
    }
    return <></>;
  }

  return (
    <div
      onClick={() => setAccountChoiceModal(false)}
      className="z-40 top-0 left-0 fixed w-screen h-screen flex items-center justify-center bg-black bg-opacity-50"
    >
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={`${isTunecore ? 'bg-tunecore-dark-element' : 'bg-white'} rounded-lg relative lg:w-1/3 w-7/8 p-8 flex items-center justify-center flex-col text-center`}
      >
        <H2>{title}</H2>
        <Spacer />
        <div className="p-4" style={{ height: '380px', width: '100%' }}>
          <Select
            menuIsOpen
            className="w-full text-old-sentric-dark-gray text-sm focus:outline-none"
            name="artistAccount"
            placeholder={t('dashboard:choose-account-search')}
            value={selectedAccount}
            onChange={(selected) => {
              fireOnChange(selected.value);
            }}
            options={accountsToSort
              ?.sort((a, b) => a.accountname.localeCompare(b.accountname))
              .map((s) => ({
                label: s.accountname,
                value: s.artistaccountcode,
              }))}
            search
          />
        </div>
      </div>
    </div>
  );
}

export default ChooseAccountModal;
