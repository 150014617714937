/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';
import getColours from '../helpers/getColours';

function WriterAmendmentsPill({ object }) {
  const { t } = useTranslation();
  const { data:siteConfig } = useSiteConfigAuth();
  const { statusSuccessColour, statusWarningColour } = getColours();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const styles = isTunecore
  ? { textColor:'black', backgroundColor: '#00ef86' }
  : {};


  let obj = {
    status: 'no status found',
    classes: 'bg-gray-500 text-black',
  };

  if (object.verifiedstate === 0) { // warning
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusWarningColour : '#EE9E26',
      },
      status: t('common:pill-submitted'),
      tooltip: t('writers:writer-tooltip-submitted'),
    };
  } else if (object.verifiedstate === 1 && object.changespending === false) { // success
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusSuccessColour : '#00ef86',
      },
      status: t('common:pill-processed'),
      tooltip: t('writers:writer-tooltip-processed'),
    };
    // changes pending conditions
  } else if ( // warning
    (object.verifiedstate === 1 && object.changespending === true)
    || object.islocked === true
  ) {
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusWarningColour : '#EE9E26',
      },
      status: t('common:pill-change'),
      tooltip: t('writers:writer-tooltip-changes'),
    };
  }

  return (
    <div>
      <ReactTooltip effect="solid" multiline {...styles} />
      <div
        style={obj.colours}
        data-tip={obj.tooltip}
        className={`inline-block text-xxs text-center ${isTunecore ? 'tracking-wider py-2 px-4 rounded-md text-black' : 'text-white rounded-lg py-2 px-5'} uppercase ${obj.classes}`}
      >
        {obj.status}
      </div>
    </div>
  );
}

export default WriterAmendmentsPill;
