import {
	FaBroadcastTower,
	FaTv,
	FaCog,
	FaFileAlt,
	FaQuestionCircle,
	FaChartLine,
	FaRegIdCard,
	FaShieldAlt,
	FaArrowAltCircleLeft,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import NavItem from "./navItem";
import IconDashboard from "../images/ic_dashboard.png";
import IconWriters from "../images/ic_writers.png";
import IconSongs from "../images/ic_songs.png";
import IconGigs from "../images/ic_gigs.png";
import IconOpportunities from "../images/ic_opportunities.png";
import AppState from "../store/appstate";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import IconErrorWarningFill from "../images/rebrandIcons/warningIcon";

const IconStatements = <FaFileAlt />;
const IconRoyalties = <FaChartLine />;
const IconSecurity = <FaRegIdCard />;
const IconPrivacyPolicy = <FaShieldAlt />;
const IconSyncs = <FaTv />;
const IconBroadcasts = <FaBroadcastTower />;
const IconSettings = <FaCog />;
const IconFaq = <FaQuestionCircle />;
const IconArrowLeft = <FaArrowAltCircleLeft />;

function NavItems(props) {
	const { t } = useTranslation("other");
	const navItemsEnabled = AppState.useState((s) => s.navItemsEnabled);
	const kyc = AppState.getRawState()?.kyc;
	const showKyc = kyc?.kycrequired && !kyc?.kycpassed;
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";
  const isRoughTrade = siteConfig?.display_name?.includes('Rough Trade Publishing');

	const {
		highlightColour,
		darkColour,
		enableDashboard,
		enableWriters,
		enableSongs,
		enableGigs,
		enableBroadcasts,
		enableOpportunities,
		enableSyncs,
		enableStatements,
		enableRoyalties,
		enableSecurity,
	} = props;

	const kycRequired = kyc?.kycrequired && !kyc?.kycpassed;
	const warningIcon = (
		<div className='ml-2'>
			<IconErrorWarningFill fill={highlightColour} />
		</div>
	);

	return (
		<>
			{!!enableSecurity && !!showKyc && (
				<NavItem
					exact
					to='/security'
					title={t("other:nav-security")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconSecurity}
					iconIsFA
					notification={kycRequired ? warningIcon : undefined}
				/>
			)}
			{isTunecore && (
				<NavItem
					externalLink
					linkAddress='https://web.tunecore.com/dashboard'
					title={t("back-to-tunecore")}
					icon={IconArrowLeft}
					highlightColour={highlightColour}
					darkColour={darkColour}
					iconIsFA
				/>
			)}
			{!!enableDashboard && (
				<NavItem
					exact
					to='/'
					title={t("other:nav-dashboard")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconDashboard}
					disabled={!navItemsEnabled}
				/>
			)}
			{!!enableWriters && (
				<NavItem
					to='/writers'
					title={t("other:nav-writers")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconWriters}
					disabled={!navItemsEnabled}
				/>
			)}
			{!!enableSongs && (
				<NavItem
					to='/songs'
					title={t("other:nav-songs")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconSongs}
					disabled={!navItemsEnabled}
				/>
			)}

			{!!enableGigs && (
				<NavItem
					to='/gigs'
					title={t("other:nav-gigs")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconGigs}
					disabled={!navItemsEnabled}
				/>
			)}
			{!!enableOpportunities && (
				<NavItem
					to='/opportunities'
					title={t("other:nav-opportunities")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconOpportunities}
					disabled={!navItemsEnabled}
				/>
			)}
			{!!enableSyncs && (
				<NavItem
					to='/syncs'
					title={t("other:nav-syncs")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconSyncs}
					iconIsFA
					disabled={!navItemsEnabled}
				/>
			)}
			{!!enableRoyalties && (
				<NavItem
					to='/royalties'
					title={t("other:nav-royalties")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconRoyalties}
					iconIsFA
					disabled={!navItemsEnabled}
				/>
			)}

			{!!enableStatements && (
				<NavItem
					lighterBackground
					to='/statements'
					title={t("other:nav-statements")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconStatements}
					iconIsFA
					disabled={!navItemsEnabled}
				/>
			)}
			{!!enableBroadcasts && (
				<NavItem
					to='/broadcasts'
					title={t("other:nav-broadcasts")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconBroadcasts}
					iconIsFA
					disabled={!navItemsEnabled}
				/>
			)}

			<NavItem
				lighterBackground
				to='/settings'
				title={t("other:nav-settings")}
				highlightColour={highlightColour}
				darkColour={darkColour}
				icon={IconSettings}
				iconIsFA
				disabled={!navItemsEnabled}
			/>

			{!isTunecore ? (
				<NavItem
					to='privacy-policy'
					title={t("other:nav-privacy-policy")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconPrivacyPolicy}
					iconIsFA
					disabled={!navItemsEnabled}
				/>
			) : null}

			{!!isRoughTrade && (
				<NavItem
					to='faq'
					title={t("other:faq")}
					highlightColour={highlightColour}
					darkColour={darkColour}
					icon={IconFaq}
					iconIsFA
					disabled={!navItemsEnabled}
				/>
			)}
		</>
	);
}

export default NavItems;
