/* eslint-disable no-console */
import H2 from '../../components/h2';
import Spacer from '../../components/spacer';

function PrivacyPolicy() {
  return (
    <div className="mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-6">
      <Spacer />
      <H2>
        Privacy Policy
      </H2>
      <Spacer />
      <div className="privacy-policy-string html-content bg-white rounded-lg shadow-lg p-4 lg:p-8 text-black h-screen overflow-y-scroll font-sentric-alt">
        <>
          <p>
            <strong>
              Last Updated: May 2023
            </strong>
          </p>
          <p>
            <strong>
              Sentric Music Group Ltd.
              {' '}
            </strong>
            and its affiliates and subsidiaries ("
            <strong>
              Sentric
            </strong>
            ", "
            <strong>
              we
            </strong>
            " or "
            <strong>
              us
            </strong>
            ") strive to protect your privacy and to maintain a high level of
            data protection.
          </p>
          <p>
            This Privacy Policy describes our privacy practices, including how
            we collect and use personal data, that are provided to us via our
            websites (or third party partner websites which we may direct you to
            from time to time, either by way of direct contact with us or by
            visiting these websites, as applicable. It also contains
            descriptions of rights associated with personal data that you may
            have under Applicable legislation, and how such rights can be
            exercised. If you have any questions, you are always welcome to
            contact us.
          </p>
          <p>
            "
            <strong>
              Applicable legislation
            </strong>
            " means applicable laws,
            ordinances and regulations, including regulations issued by relevant
            supervisory authorities, concerning the protection of the
            fundamental rights and freedoms of natural persons and in particular
            the right to the protection of their personal data applicable to the
            processing in question; including Regulation (EU) 2016/679 of the
            European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of
            personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation) ("
            <strong>
              GDPR
            </strong>
            ") as well as laws, ordinances and regulations supplementing the
            GDPR and other legal requirements that may apply to us.
          </p>
          <p>
            Where terms like "
            <strong>
              processing
            </strong>
            ", "
            <strong>
              personal data
            </strong>
            ", "
            <strong>
              data subjects
            </strong>
            " etc. that are defined in the
            Applicable legislation are used in this Privacy Policy, they shall
            have the same meanings in this Privacy Policy as those ascribed to
            them in the Applicable legislation.
          </p>
          <h3>
            I. WHO IS THE DATA CONTROLLER FOR THE PROCESSING OF PERSONAL DATA?
          </h3>
          <p>
            For the processing of personal data associated with Sentric's
            websites, Sentric is the data controller. Where you have a business
            relationship with or otherwise have been in contact with another
            Sentric company, such Sentric company is the data controller
            relating to processing of personal data associated with fulfilment
            of e.g. contracts, engaging in regular business correspondence,
            maintaining records with regular details on business contact persons
            etc.
          </p>
          <h3>
            II. WHAT CATEGORIES OF PERSONAL DATA DO WE COLLECT?
          </h3>
          <p>
            While the personal data we collect varies depending upon the
            circumstances, we may collect the following categories of personal
            data (subject to applicable legal requirements and restrictions):
          </p>
          <p />
          <ul>
            <li>
              <em>
                Name, contact information and other identifiers
              </em>
              :
              identifiers such as a name, username, password, log-in
              credentials, account name, address, phone number, birth date,
              email address, social security number, driver’s license number,
              passport number, IPI number, ISNI number, and other identifiers.
            </li>
            <li>
              <em>
                Customer records
              </em>
              : electronic customer records containing
              personal data. We may also collect your payment information as
              well.
            </li>
            <li>
              <em>
                Device information
              </em>
              : internet protocol (IP) address, web
              browser type, operating system version, phone carrier and
              manufacturer, application installations, device identifiers,
              mobile advertising identifiers, and push notification tokens.
            </li>
            <li>
              <em>
                Protected classifications
              </em>
              : characteristics of protected
              classifications under applicable law such as, sex and age.
            </li>
            <li>
              <em>
                Communications
              </em>
              : direct communication, web forms, online
              polls, or interactions with our blogs and posts.
            </li>
            <li>
              <em>
                Commercial information
              </em>
              : including records of products or
              services purchased, obtained, or considered, or other purchasing
              or use histories or tendencies.
            </li>
            <li>
              <em>
                Usage data
              </em>
              : internet or other electronic network activity
              information including, but not limited to, browsing history,
              search history, and information regarding a consumer’s interaction
              with an Internet website, application, or advertisement.
            </li>
            <li>
              <em>
                Transaction Data
              </em>
              : details about payments (invoices and
              payee details), social security information, tax exemption or
              registration status, royalty data (data related to any royalties
              paid or payable to you) or other data related to your revenue
              sources, copyright or metadata relating to musical works, sound
              recordings, merchandise and/or other works in which you have an
              interest.
            </li>
            <li>
              <em>
                Location data
              </em>
              : location information about a particular
              individual or device, general location information (for example,
              your IP address may indicate your more general geographic region).
              However, this information is not precise geolocation.
            </li>
            <li>
              <em>
                Profiles and inferences
              </em>
              : inferences drawn from any of the
              information identified above to create a profile reflecting a
              resident's preferences.
            </li>
            <li>
              <em>
                Global Compliance Screening Data
              </em>
              : PEP status, appearance
              on any sanctions list, business connections, and any other
              information relating to KYC or AML compliance and screening.
            </li>
          </ul>
          <p />
          <h3>
            III. FROM WHERE DO WE COLLECT PERSONAL DATA?
          </h3>
          <p>
            We collect personal data from:
          </p>
          <ol>
            <li>
              <strong>
                You
              </strong>
              , that you either provide to us yourself or
              that we collect from you based on your website activity.
            </li>
            <li>
              <strong>
                An organization or company that you represent, are associated
                with
              </strong>
              , or otherwise do business with, in the context of conducting our
              business, we process personal data in the ways that companies
              normally do to run business or otherwise offer our services,
              products, or offerings. Such personal data may be provided by such
              company or organization.
            </li>
            <li>
              <strong>
                Publicly available sources
              </strong>
              , e.g. business related
              personal data, such as non-sensitive information related to
              individuals in their professional capacity, for the purposes of
              maintaining our business or to identify new business
              opportunities.
            </li>
          </ol>
          <p />
          <h3>
            IV. WHEN AND WHY DO WE PROCESS PERSONAL DATA?
          </h3>
          <h4 className="mt-4 font-bold">
            A. Fulfilment of contract with customers, suppliers and other
            business partners
          </h4>
          <p>
            If you represent or otherwise are associated with an organization or
            company that we do business with, we process your personal data as
            needed in order to fulfil the contract with such organization or
            company, that may be a customer, supplier, or other business
            partner.
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300">
            <tr>
              <td className="border border-gray-300 p-2">
                Categories of personal data
              </td>
              <td className="border border-gray-300 p-2">
                Legal basis under GDPR (if applicable)
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">
                <ul className="list-disc pl-2 ml-4">
                  <li>
                    Identity information (e.g. first and last name), IPI number, ISNI number, social security number, passport number, and other identifiers
                  </li>
                  <li>
                    Contact information (e.g. email address, phone number, postal address)
                  </li>
                  <li>
                    Correspondence data (e.g. personal data contained in emails and information on time of correspondence)
                  </li>
                  <li>
                    Royalty data (e.g. data related to any royalties paid or payable to you) or other data related to your revenue sources
                  </li>
                  <li>
                    Copyright or other related data, including usage data, relating to musical works, sound recordings, merchandise and/or other works in which you have an interest
                  </li>
                </ul>
              </td>
              <td className="border border-gray-300 p-2">
                <em>
                  Legitimate interests (Art. 6.1 f of the GDPR).
                </em>
                {' '}
                The processing is necessary to run our business.
              </td>
            </tr>
          </table>
          <h4 className="mt-4 font-bold">
            B. General business purposes
          </h4>
          <p>
            We process personal data for general business purposes and in
            connection with the products and services that we offer customers,
            e.g. by way of processing personal data associated with prospective
            business relationships and personal data related to media/public
            relations.
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  <ul className="list-disc pl-2 ml-4">
                    <li>
                      Identity information (e.g. first and last name), IPI number, ISNI number, social security number, passport number, and other identifiers
                    </li>
                    <li>
                      Contact information (e.g. email address, phone number, postal address)
                    </li>
                    <li>
                      Correspondence data (e.g. personal data contained in emails and information on time of correspondence)
                    </li>
                    <li>
                      Royalty data (e.g. data related to any royalties paid or payable to you) or other data related to your revenue sources
                    </li>
                    <li>
                      Copyright or other related data, including usage data, relating to musical works, sound recordings, merchandise and/or other works in which you have an interest
                    </li>
                    <li>
                      <em>
                        Global Compliance Screening Data
                      </em>
                      : PEP status, appearance on any sanctions list, business connections, and any other information relating to KYC or AML compliance and screening.
                    </li>
                  </ul>
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legitimate interests (Art. 6.1 f of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to run our business.
                </td>
              </tr>
            </tbody>
          </table>
          <h4 className="mt-4 font-bold">
            C. Provide you with information about our business via newsletters
          </h4>
          <p>
            We process personal data to manage and send out newsletters. These
            newsletters can e.g. contain information and updates regarding our
            business and our services and products. You can unsubscribe from our
            newsletter at any time by clicking on the unsubscribe link in the
            email or by contacting us on the contact details set out below.
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  <ul className="list-disc pl-2 ml-4">
                    <li>
                      Identity information (e.g. first and last name)
                    </li>
                    <li>
                      Contact information (e.g. email address, phone number, postal address)
                    </li>
                  </ul>
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legitimate interest (Art. 6.1 f of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to fulfill our legitimate interest to send our newsletter.
                  <p>
                    <em>
                      Consent (Art. 6.1 a of the GDPR).
                    </em>
                    {' '}
                    Depending on applicable legal requirements, we may also rely on your consent for sending newsletters.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt-4 font-bold">
            D. Develop and improve our products, services and systems for the customers in general and for business insights, statistics, forecasts, machine learning, and various algorithms.
          </h4>
          <p>
            We will process personal data in connection with developing and improving our products, services and systems for customers in general and for business insights, statistics, forecasts, machine learning, and various algorithms. In connection with such purpose, we may, at our discretion and in certain circumstances, anonymize and/or de-identify such personal data.
          </p>

          <table className="privacy-policy-table border-collapse border border-gray-300 mt-4">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  <ul className="list-disc pl-2 ml-4">
                    <li>
                      Identity information (e.g. first and last name), IPI number, ISNI number, and other identifiers
                    </li>
                    <li>
                      Contact information (e.g. email address, phone number, postal address)
                    </li>
                    <li>
                      Payment transaction data
                    </li>
                    <li>
                      Royalty data (e.g. data related to any royalties paid or payable to you) or other data related to your revenue sources
                    </li>
                    <li>
                      Copyright or other related data, including usage data, relating to musical works, sound recordings, merchandise or other works in which you have an interest
                    </li>
                  </ul>
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legitimate interest (Art. 6.1 f of the GDPR)
                  </em>
                  {' '}
                  The processing is necessary to fulfill our legitimate interests to develop and improve our products, services, and systems for the customers in general and for the production of statistics.
                </td>
              </tr>
            </tbody>
          </table>
          <h4 className="mt-4 font-bold">
            E. Evaluate and monitor the use of our website
          </h4>
          <p>
            In order to analyze and better understand how our websites are used, we process your personal data, which we have collected, e.g. by using cookies and similar technologies. This is done by e.g. collecting visitor and click statistics, from which website you came from, which pages you visited on our website and for how long time/number of times. Please read more in our
            {' '}
            <strong>
              <a href="https://sentricmusic.com/cookies-policy/" target="_blank" rel="noreferrer">
                Cookie Policy
              </a>
              <span>
                .
              </span>
            </strong>
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300 mt-4">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  <ul className="list-disc pl-2 ml-4">
                    <li>
                      User-generated data
                    </li>
                    <li>
                      Geographical information, based on IP address
                    </li>
                  </ul>
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legitimate interest (Art. 6.1 f of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to fulfill our legitimate interest in evaluating and monitoring the use of our websites.
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt-4 font-bold">
            F. Improve your experience on our website
          </h4>
          <p>
            In order to improve your experience on our website and to provide you with tailored content when appropriate, we will collect and process your personal data, e.g. via cookies and similar techniques. This means that we can save information about your web history and selected website settings (such as language and display settings). Please read more in our
            {' '}
            <strong>
              <a href="https://sentricmusic.com/cookies-policy/" target="_blank" rel="noreferrer">
                Cookie Policy
              </a>
            </strong>
            .
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300 mt-4">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  <ul className="list-disc pl-2 ml-4">
                    <li>
                      Identity information (e.g. first and last name)
                    </li>
                    <li>
                      User-generated data
                    </li>
                    <li>
                      Geographical information, based on IP address
                    </li>
                  </ul>
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legitimate interest (Art. 6.1 f of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to fulfill our legitimate interest in improving your experience on our website and providing you with tailored content.
                </td>
              </tr>
            </tbody>
          </table>
          <h4 className="mt-4 font-bold">
            G. Manage and address legal claims
          </h4>
          <p>
            In order to manage and address any legal claims, e.g. in connection with a dispute or legal process, we process personal data (as applicable).
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300 mt-4">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  All information necessary to manage and address the legal claim.
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legitimate interest (Art. 6.1 f of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to fulfill our legitimate interest in managing and addressing legal claims, e.g. in connection with a dispute or legal process.
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt-4 font-bold">
            H. Comply with other legal obligations
          </h4>
          <p>
            We process personal data in order to comply with legal obligations such as e.g. obligations regarding accounting and bookkeeping as well as any other applicable legal obligations.
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300 mt-4">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  All information that is necessary to fulfil the respective legal obligation.
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legal obligation (Art. 6.1 c of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to fulfil legal obligations to which we are subject.
                </td>
              </tr>
            </tbody>
          </table>
          <h4 className="mt-4 font-bold">
            I. In connection with a business transaction
          </h4>
          <p>
            We process personal data as necessary and required to evaluate or complete a business transaction, including, without limitation, a potential acquisition, merger, sale, or bankruptcy or in connection with providing various goods and services to you.
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300 mt-4">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  All information that is necessary to fulfil a contemplated business transaction.
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legal obligation and legitimate interest (Art. 6.1 f and 6.1 c of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to fulfil legal obligations to which we are subject or to fulfil our legitimate interest in completing the business transaction, including, without limitation running our governance and compliance programs such as e.g., KYC.
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt-4 font-bold">
            J. Manage and protect systems and services
          </h4>
          <p>
            We process your personal data if necessary, in order to manage and protect our IT systems and services, e.g. in connection with logging, troubleshooting, backup, change and problem management in systems and in connection with any IT incidents.
          </p>
          <table className="privacy-policy-table border-collapse border border-gray-300 mt-4">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  All information listed above.
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legitimate interest (Art. 6.1 f of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to fulfil our legitimate interest in managing and protecting our IT systems and services.
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="mt-4 font-bold">
            K. Fraud Prevention and the identification of natural person status
          </h4>
          <table className="privacy-policy-table border-collapse border border-gray-300 mt-4">
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  Categories of personal data
                </td>
                <td className="border border-gray-300 p-2">
                  Legal basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">
                  All information which is necessary to verify ‘natural person’ status.
                  <p>
                    Special Category Data (biometric data used for identification purposes).
                  </p>
                </td>
                <td className="border border-gray-300 p-2">
                  <em>
                    Legal obligation and legitimate interest (Art. 6.1 f and 6.1 c of the GDPR).
                  </em>
                  {' '}
                  The processing is necessary to fulfil legal obligations to which we are subject or to fulfil our legitimate interest in completing the business transaction, including, without limitation running our governance and compliance programs such as e.g., KYC.
                  <p>
                    <em>
                      Consent (Art 6.1 a of the GDPR).
                    </em>
                    {' '}
                    You have provided your explicit consent to us for the processing of this data to identify you as a natural person.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h3>
            V. HOW LONG DO WE RETAIN YOUR PERSONAL DATA FOR?
          </h3>
          <p>
            We keep your personal data only for as long as is necessary for the
            relevant purpose identified above, or for any period of time
            required by applicable laws. We use a number of criteria for
            determining personal data retention period including our legal
            obligations, our need to defend or bring contractual claims within
            the statutory limitation period and consideration of the original
            purpose for which your personal data was collected.
          </p>
          <h3>
            VI. WITH WHOM DO WE SHARE PERSONAL DATA?
          </h3>
          <p>
            When necessary, we share personal data with the recipients specified
            below. Unless otherwise stated, to the extent GDPR is applicable,
            named recipients are independent data controllers for their own
            processing of personal data.
          </p>
          <table className="privacy-policy-table divide-y border-collapse border border-gray-300 divide-gray-20 mb-4">
            <tbody>
              <tr>
                <td className="px-4 py-2 font-bold">
                  Recipient
                </td>
                <td className="px-4 py-2 font-bold">
                  Purpose
                </td>
                <td className="px-4 py-2 font-bold">
                  Legal Basis under GDPR (if applicable)
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">
                  Companies and providers of various services, including without limitation, customer relationship management (CRM) systems, payment solutions providers (e.g. banks and other payments services providers), marketing services (systems for sending newsletters) and IT services (companies that manage necessary operations, technical support and maintenance of our IT solutions and IT systems).
                </td>
                <td className="px-4 py-2">
                  To enable the functioning of our products and services offered to consumers and customers.
                </td>
                <td className="px-4 py-2">
                  <p>
                    <em>
                      Legitimate interest (Art. 6.1 f of the GDPR).
                    </em>
                    The processing is necessary to fulfil our legitimate interest in disputes and cases being managed by competent courts and legal representatives.
                  </p>
                  <p>
                    Contract performance: (Art. 6.1 of the GDPR). The processing is necessary for the performance of a contract to which the data subject is party.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">
                  Authorities (incl. courts) and legal representatives, as well as the Police, the Public Health Agency and the Tax Agency (as applicable).
                </td>
                <td className="px-4 py-2">
                  To establish, exercise and defend legal claims; In order to fulfil any legal obligations to which we are subject, e.g. in connection with requests from authorities or other legal claims.
                </td>
                <td className="px-4 py-2">
                  <p>
                    <em>
                      Legitimate interest (Art. 6.1 f of the GDPR).
                    </em>
                    The processing is necessary to fulfil our legitimate interest in disputes and cases being managed by competent courts and legal representatives.
                  </p>
                  <p>
                    <em>
                      Legal obligation (Art. 6.1 c of the GDPR).
                    </em>
                    The processing is necessary to fulfil legal obligations to which we are subject.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">
                  Buyers, sellers and external advisors/other parties involved.
                </td>
                <td className="px-4 py-2">
                  To enable business changes, e.g. sale or merger of the business or investments in general.
                </td>
                <td className="px-4 py-2">
                  <em>
                    Legitimate interest (Art. 6.1 f of the GDPR).
                  </em>
                  The processing is necessary to fulfil our legitimate interest in conducting and executing business changes.
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2">
                  Third-party partner Processors.
                </td>
                <td className="px-4 py-2">
                  Fraud prevention. Utilization of third-party tech to verify 'natural person' status.
                </td>
                <td className="px-4 py-2">
                  <p>
                    <em>
                      Legal obligation and legitimate interest (Art. 6.1 f and 6.1 c of the GDPR).
                    </em>
                    The processing is necessary to fulfil legal obligations to which we are subject or to fulfil our legitimate interest in completing the business transaction, including, without limitation running our governance and compliance programs such as e.g., KYC.
                  </p>
                  <p>
                    <em>
                      Consent (Art 6.1 a of the GDPR).
                    </em>
                    You have provided your explicit consent to us for the processing of this data to identify you as a natural person.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>
              We also share personal data internally with Sentric's affiliated
              companies (such as its affiliates and subsidiaries) for the above
              purposes.
            </strong>
          </p>
          <p>
            <strong>
              Appropriate safeguards for the transfer of personal data to third
              countries - where the GDPR applies.
            </strong>
          </p>
          <p>
            If we transfer your personal data to a recipient in a country
            outside the UK/EU/EEA area (third country), we will ensure that
            appropriate safeguards have been taken (such as the EU Commission's
            or UK Information Commissioner's standard contractual clauses and
            other necessary measures), as required under the GDPR.
          </p>
          <p>
            Pursuant to Applicable Legislation, you may have the right, upon
            request, to receive a copy of the documentation demonstrating that
            the necessary protective measures have been taken in order to
            protect your personal data when transferring it to a third country.
            If you would like to know more about the processing of your personal
            data and if your personal data is transferred to a third country,
            please contact us by using the contact information below.
          </p>
          <h3>
            VII. TECHNICAL AND ORGANIZATIONAL MEASURES TO PROTECT PERSONAL DATA
          </h3>
          <p>
            We take measures to ensure that your personal data is adequately
            protected from accidental or unlawful destruction, loss, alteration,
            unauthorized disclosure or access, when transmitted, stored or
            otherwise processed. The measures that we have implemented and take
            into account the state of the art, the cost of implementation and
            the nature, scope, context and purposes of processing, including
            associated risks. However, we cannot guarantee that unauthorized
            access, hacking, data loss, or other similar events will never
            occur. We urge you to take steps to keep your personal data safe,
            such as choosing a strong password and logging out of your account
            and closing your web browser when finished using our services.
          </p>
          <h3>
            VIII. CHANGES TO THIS POLICY
          </h3>
          <p>
            This Policy is kept under regular review and may be updated from
            time to time. When we make changes to this Policy, we will change
            the "Last Updated" date above. If a material change is made to this
            Policy, we may choose to provide notice to you in advance of such
            change, such as by posting notice of that change on the first page
            of this Policy or on our homepage, or by emailing your email address
            of record with us.
          </p>
          <h3>
            IX. CALIFORNIA SHINE THE LIGHT LAW
          </h3>
          <p>
            Under California's "Shine the Light" law (Cal. Civ. Code § 1798.83),
            California residents who provide us certain personal data are
            entitled to request and obtain from us, free of charge, information
            about the personal data (if any) we have shared with third parties
            during the immediately preceding calendar year for their own direct
            marketing use. Such requests may be made once per calendar year for
            information about any relevant third-party sharing in the prior
            calendar year. California residents who would like to make such a
            request may submit a request to the contact information provided
            below. The request should attest to the fact that the requester is a
            California resident and provide a current California address. We are
            only required to respond to a customer request once during any
            calendar year. Please be aware that not all information sharing is
            covered by California's "Shine the Light" law and only information
            sharing that is covered will be included in our response.
          </p>
          <h3>
            X. NOTICE FOR NEVADA RESIDENTS
          </h3>
          <p>
            If you are a resident of Nevada, you have the right to opt-out of
            the sale of certain Personal Information to third parties who intend
            to license or sell that Personal Information. You can exercise this
            right by contacting us at
            {' '}
            <a href="mailto:privacy@sentricmusic.com">
              privacy@sentricmusic.com
            </a>
            <span> </span>
            with the subject line “Nevada Do Not Sell Request” and providing us
            with your name and the email address associated with your account.
          </p>
          <h3>
            XI. INDIVIDUALS' RIGHTS UNDER THE GDPR
          </h3>
          <p>
            Individuals have certain additional rights where the UK/EU GDPR
            applies. You may, under certain conditions, exercise the following
            rights:
          </p>
          <p>
            <strong>
              Access
            </strong>
            : You can request confirmation of whether or
            not your personal data is being processed and, if so, request access
            to your personal data and additional information such as the purpose
            of the processing. You also have the right to receive a copy of the
            personal data that is processed.
          </p>
          <p>
            <strong>
              Rectification
            </strong>
            : If you notice that personal data
            about you is inaccurate or incomplete, you have the right to have
            your personal data rectified.
          </p>
          <p>
            <strong>
              Object to specific processing
            </strong>
            : You can object to
            processing of your personal data if it is based on a legitimate
            interest, on grounds relating to your particular situation, or if
            the processing takes place for direct marketing purposes. If we are
            unable to demonstrate compelling legitimate grounds to continue
            processing, that override your interests, or if the processing is
            not necessary to establish, exercise and defend legal claims, we are
            obliged to cease the processing.
          </p>
          <p>
            <strong>
              Erasure
            </strong>
            : You can have your personal data erased
            under certain circumstances, e.g. when the personal data is no
            longer needed to fulfill the purpose for which the personal data was
            collected or otherwise processed.
          </p>
          <p>
            <strong>
              Restrict processing
            </strong>
            : Under certain circumstances,
            you can request that we restrict the processing of your personal
            data to only involve the storage of your personal data.
          </p>
          <p>
            <strong>
              Withdraw consent
            </strong>
            : To the extent that the processing
            of personal data is based on your consent, you always have the right
            to withdraw your consent. Withdrawal of consent does however not
            affect the lawfulness of any processing that has been based on
            consent prior to its withdrawal.
          </p>
          <p>
            <strong>
              Data portability
            </strong>
            : You have the right to request a
            copy of your personal data that you have provided to us, and which
            has been processed based on your consent (Art. 6.1 a of the GDPR) or
            on contractual necessity (Art. 6.1 b of the GDPR), in a structured,
            commonly used and machine-readable format. Moreover, you also have
            the right to transmit such data to another controller without
            hindrance from us. If technically feasible, you also have the right
            to request that such personal data should be transmitted from us to
            another data controller.
          </p>
          <p>
            <strong>
              Complaints to the supervisory authority:
              {' '}
            </strong>
            Please contact us with questions or complaints regarding the
            processing of your personal data.
            <strong> </strong>
            You also have the right to lodge a complaint regarding the
            processing of your personal data with the data protection authority
            of the country in which you reside or work or where the alleged
            breach has occurred.
          </p>
          <h3>
            XII. CONTACT US
          </h3>
          <p>
            If you have any questions regarding the processing of your personal
            data or if you wish to exercise any of your rights, please contact
            us by reaching out to your regular contact person at Sentric or by
            using the contact details below.
          </p>
          <p>
            <strong>
              Sentric Music Group Ltd.
            </strong>
          </p>
          <p>
            1st Floor 29 Parliament Street, Liverpool, England, L8 5RN
            <br />
          </p>
          <p>
            Email: privacy@sentricmusic.com
          </p>
          <p>
            We may update this Privacy Policy from time to time. We recommend
            that you visit this page on a regular basis to get the latest
            information regarding our processing of personal data.
          </p>
        </>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
