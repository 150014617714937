/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { FaExclamationCircle, FaRegTrashAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import Select from "../../../../components/select";
import { getMyWriters, getSocieties } from "../../../../api/queries";
// import Input from '../../../../components/input';
import LoadingSmall from "../../../../components/loadingSmall";
import HR from "../../../../components/hr";
import { strToBool } from "../../../../helpers/helpers";
import AppState from "../../../../store/appstate";
import NumericalInput from "../../../../components/numericalInput";
import RebrandButtonLink from "../../../../components/rebrandButtonLink";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";
import SubModal from "../../../../components/SubModal";
import WritersForm from "../writers/writersForm";
import getColours from "../../../../helpers/getColours";

function SongWriterSplitCreateRow({
	writer,
	setWriterSplitField,
	i,
	deleteSongWriter,
	updateWorkEtag,
}) {
	const { t } = useTranslation("songs");
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const writers = getMyWriters({ aac: selectedAccount });
	const societies = getSocieties();
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const textColor = isTunecore ? "black" : "white";
	const { highlightColour } = getColours();
	const backgroundColor = isTunecore ? "#00ef86" : highlightColour;

	const [selectedWriterId, setSelectedWriterId] = useState(null);
	const [editWriterModalIsOpen, setEditWriterModalIsOpen] = useState(false);

	const designationCodes = [
		{ name: t("songs:author-option"), value: 1 },
		{ name: t("songs:composer-option"), value: 2 },
		{ name: t("songs:both-option"), value: 3 },
	];

	if (!writers.isSuccess || !societies.isSuccess) {
		return <LoadingSmall />;
	}

	const writerDetails = writers.data.writers.find(
		(w) => w.writercode === writer.writercode
	);
	const society = societies.data.societies.find(
		(s) => s.cisaccode === writerDetails?.societycisaccode
	);
	const writerType = designationCodes.find(
		(d) => d.value === parseInt(writer?.writerdesignationcode)
	);

	const writerUpdate = (writerData) => {
		updateWorkEtag();
		setWriterSplitField({
			writercode: writer.writercode,
			field: "writer",
			x: writerData,
		});
	};

	if (writer.isdeleted) {
		return <></>;
	}

	return (
		<>
			<SubModal
				openSubPortal={editWriterModalIsOpen}
				closeSubPortal={setEditWriterModalIsOpen}
			>
				<WritersForm
					id={selectedWriterId}
					modal
					closeForm={setEditWriterModalIsOpen}
					writerUpdate={writerUpdate}
				/>
			</SubModal>

			<div
				className={`relative flex flex-col md:flex-row gap-6 min-h-32 p-4 ${
					isTunecore ? "bg-tunecore-mid-grey" : "bg-white"
				}`}
			>
				<div className='absolute top-0 right-0' style={{ padding: "0.5rem" }}>
					<RebrandButtonLink
						smallfont
						id={`btnSongWriterSplitremove_${i}`}
						onClick={() => deleteSongWriter(writer.writercode)}
					>
						<FaRegTrashAlt size={16} className='inline-block mr-2' />
					</RebrandButtonLink>
				</div>
				<div className='mb-2 lg:mb-0 flex flex-col w-full md:w-1/3 justify-between'>
					<div>
						<h2
							className={`text-md ${isTunecore ? "text-white" : "text-black"}`}
						>
							{writerDetails?.firstname} {writerDetails?.middlename}{" "}
							{writerDetails?.lastname}
						</h2>
						{society?.name === "" || !society?.name ? (
							!isTunecore && (
								<>
									<FaExclamationCircle
										className='max-w-md'
										data-tip={t("songs:membership-tooltip")}
										data-for='exclamationTooltip'
									/>
									<ReactTooltip
										id='exclamationTooltip'
										type='warning'
										effect='solid'
										textColor={textColor}
										backgroundColor={backgroundColor}
									/>
								</>
							)
						) : (
							<div
								className={`text-sm ${
									isTunecore ? "text-white" : "text-black font-sentric-alt"
								}`}
							>
								<p>
									<strong>{t("songs:membership")}:</strong> {society?.name}
								</p>
								<p>
									<strong>CAE / IPI #:</strong>{" "}
									{writerDetails?.caeipinumber
										? writerDetails.caeipinumber
										: "-"}
								</p>
								{writerDetails?.publishername && (
									<p>
										<strong>{t("songs:publisher")}:</strong>{" "}
										{writerDetails.publishername}
									</p>
								)}
							</div>
						)}
					</div>

					<div className='w-full'>
						<RebrandButtonLink
							smallfont
							onClick={() => {
								setSelectedWriterId(writer.writercode);
								setEditWriterModalIsOpen(true);
							}}
						>
							{t("songs:edit-writer")}
						</RebrandButtonLink>
					</div>
				</div>
				<div
					className={`${
						isTunecore
							? "bg-tunecore-light-grey"
							: "bg-sentric-rebrand-mid-grey"
					} hidden md:block w-[1px]`}
				/>
				<div className='w-full md:w-1/3 flex gap-2 justify-between flex-col mb-2 lg:mb-0'>
					<Select
						list
						id={`btnSongWriterSplitRole_${i}`}
						className={`select-search ${
							isTunecore ? "tunecore-select" : "select-grey-bg"
						} pr-6 lg:pr-10 font-sentric-alt text-base text-black`}
						name='writerdesignationcode'
						placeholder={t("songs:select-role")}
						value={writerType?.value}
						onChange={(selected) =>
							setWriterSplitField({
								writercode: writer.writercode,
								field: "writerdesignationcode",
								x: selected,
							})
						}
						options={[
							// { name: 'Unknown', value: 0 },
							{ name: t("songs:author-option"), value: 1 },
							{ name: t("songs:composer-option"), value: 2 },
							{ name: t("songs:both-option"), value: 3 },
						]}
					/>
					<NumericalInput
						showLabel
						className={` w-1/2 ${
							isTunecore
								? "tunecore-numerical-input"
								: "numerical-input-grey-bg"
						}`}
						id={`btnSongWriterSplitPercent_${i}`}
						min='0'
						max='100'
						type='number'
						placeholder={`${t("songs:writer-table-split")}${" "}%`}
						// isPercentage
						value={writer?.writershare}
						onKeyDown={(e) => {
							if (
								e.keyCode === 69 ||
								e.keyCode === 107 ||
								e.keyCode === 187 ||
								e.keyCode === 109 ||
								e.keyCode === 189
							) {
								e.preventDefault();
							}
						}}
						onChange={(e) =>
							setWriterSplitField({
								writercode: writer.writercode,
								field: "writershare",
								x: e.target.value,
							})
						}
						onBlur={(e) =>
							setWriterSplitField({
								writercode: writer.writercode,
								field: "writershare",
								x: parseFloat(e.target.value).toFixed(2),
							})
						}
					/>
				</div>
				<div
					className={`${
						isTunecore
							? "bg-tunecore-light-grey"
							: "bg-sentric-rebrand-mid-grey"
					} hidden md:block w-[1px]`}
				/>
				<div className='w-full md:w-1/3 self-center'>
					<div className='flex'>
						<input
							style={{
								accentColor: highlightColour,
							}}
							data-tip
							data-for='tipregistertocollect'
							type='checkbox'
							id={`righttocollect_${i}`}
							name={`righttocollect${i}`}
							checked={strToBool(writer?.righttocollect)}
							className={`mr-3 lg:scale-125 ${
								isTunecore ? "text-tunecore-blue" : ""
							}`}
							onChange={(e) => {
								setWriterSplitField({
									writercode: writer.writercode,
									field: "righttocollect",
									x: e.target.value,
								});
								setWriterSplitField({
									writercode: writer.writercode,
									field: "writer",
									x: writerDetails,
								});
							}}
						/>
						<label
							className='text-xs'
							htmlFor={`righttocollect${i}`}
						>
							{t("songs:right-to-collect")}
						</label>
					</div>
					<ReactTooltip
						id='tipregistertocollect'
						className='max-w-md'
						place='right'
						type='warning'
						effect='solid'
						multiline='true'
						textColor={textColor}
						backgroundColor={backgroundColor}
					>
						<center className='font-bold'>
							{t("songs:right-to-collect-tooltip")}
						</center>
					</ReactTooltip>
				</div>
			</div>
			<div className='px-6'>
				<HR />
			</div>
		</>
	);
}

export default SongWriterSplitCreateRow;
