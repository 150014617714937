/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { IoWarningOutline } from "react-icons/io5";
import { useFlags } from "launchdarkly-react-client-sdk";
import { hasAdminRights } from "../auth/shared";
import {
	getMyAccounts,
	getMyAccount,
	getUserMeta,
	getMyProfile,
	getArtistMeta,
	getContracts,
} from "../api/queries";
import Loading from "./loading";
import { logout, sendEvent, sendProperty } from "../helpers/helpers";
import { apiCall } from "../api/shared";
import AppState from "../store/appstate";
import UserDropDown from "../components/userDropDown";
import Spacer from "../components/spacer";
// import LanguageSelector from "../components/languageSelector";
import { updateMyProfile, updateArtistAccountMeta } from "../api/mutations";
import NavItems from "../components/navItems";
import currencies from "../helpers/currency";
import { processKycStatuses } from "../helpers/kycStatus";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import getColours from "../helpers/getColours";
import useWindowDimensions from "../helpers/useWindowDimensions";
// import ErrorThrower from "../screens/authenticated/dashboard/dashboard/errorThrower";

function AuthLayout(props) {
	const { data: siteConfig, isSuccess: configIsSuccess } = useSiteConfigAuth();
	AppState.update((s) => {
		s.hubspotDisabled = siteConfig?.disable_hubspot_tracking;
	});
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const authLayoutClasses = clsx("page-fade-in relative w-full antialiased", {
		"font-sentric bg-sentric-rebrand-light-grey": !isTunecore,
		"font-tunecore bg-tunecore-black text-white": isTunecore,
	});
	const { width } = useWindowDimensions();
  const flags = useFlags();
  const showOldSignUp = flags['pd-show-old-sign-up-flow'];


	// const isUAT = import.meta.env.VITE_STAGE === "dev" || "local";

	// END OF CONDITIONAL CLASSES FOR REBRAND
	const { children } = props;
	const [menu, setMenu] = useState(false);
	const [showContractModal, setShowContractModal] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const userMeta = getUserMeta();
	const updateArtistMeta = updateArtistAccountMeta();
	const profile = getMyProfile();
	const createProfileMutation = updateMyProfile();

	const myAccounts = getMyAccounts();
	const noAccounts =
		myAccounts.isSuccess &&
		(myAccounts.data === null ||
			myAccounts.data === undefined ||
			myAccounts.data === "undefined");

	const newUser =
		userMeta.isSuccess &&
		noAccounts &&
		(userMeta.data === "undefined" ||
			userMeta.data === undefined ||
			userMeta.data === null);
	const selectedAccount = AppState.useState((s) => s.selectedAccount) || "";
	const hubspotProps = AppState.useState((s) => s.hubspot);

	const { darkColour, highlightColour } = getColours();

	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});

	const artistMeta = getArtistMeta({
		aac: selectedAccount,
	});

	const raSpotifyId = myAccount?.data?.spotifyid;
	const pdSpotifyId = artistMeta?.data?.spotify_obj
    ? JSON.parse(artistMeta?.data?.spotify_obj)?.id
    : null;

	const contracts = getContracts({
		artistaccountcode: selectedAccount,
	});

	const adminRights = hasAdminRights(myAccount?.data);

	const switchAccount = async (artistAccount) => {
		AppState.update((s) => {
			s.selectedAccount = artistAccount.artistaccountcode;
			s.selectedCatalogue = artistAccount.cataloguecode;
		});
		window.location.reload();
	};

	useEffect(() => {
		AppState.update((s) => {
			s.allOpportunitiesAvailable =
				siteConfig?.all_opportunities_available ?? false;
			s.allSyncsAvailable = siteConfig?.all_syncs_available ?? false;
		});
	}, [siteConfig]);

	useEffect(() => {
		if (contracts?.data?.results?.length > 0) {
			setShowContractModal(true);
		}
	}, [contracts]);

	useEffect(() => {
		setMenu(false);
	}, [location]);

	// takes the first 3 characters of the selected account code to get the tenant prefix
	// uses that as a parameter in the gettenant endpoint to get the tenant data object which contains currency ISO code
	// uses that to get the currency symbol from the currencies object
	// const tenantprefix = selectedAccount.length >= 3 ? selectedAccount.substring(0, 3) : '';
	const tenant = useQuery(
		["gettenant"],
		() =>
			apiCall({ endpoint: "/rightsapp/get", path: "/api/v1/tenant/gettenant" }),
		{
			enabled: !!selectedAccount,
			staleTime: 1000 * 60 * 60,
		}
	);

	function getTenantCurrencySymbol(code) {
		if (code) {
			const matchingCurrency = currencies[code];

			if (matchingCurrency) {
				const currencySymbol = matchingCurrency.symbol;
				const locale = matchingCurrency.Locale;

				AppState.update((s) => {
					s.tenantCurrencyCode = code;
					s.tenantCurrencySymbol = currencySymbol;
					s.tenantCurrencyLocale = locale;
				});
			} else {
				console.error(`Currency data not found for code: ${code}`);
			}
		}
	}

	const currencyCode = tenant.data?.currencyIso4217Code;

	useEffect(() => {
		getTenantCurrencySymbol(currencyCode);
	}, [currencyCode]); // Empty dependency array means this effect runs once on mount

	useEffect(() => {
		if (userMeta.isSuccess && myAccounts.isSuccess) {
			if (newUser) {
				if (showOldSignUp) {
					history.push("/onboarding-1");
				} else {
					history.push("/onboarding");
				} // After confirming email, user is redirected to onboarding
				sendProperty({
					property: "sign_up_date",
					value: "CURRENT_DATE",
					email: profile?.data?.email,
				});

				sendProperty({
					property: "sign_up_domain",
					value: window.location.hostname,
					email: profile?.data?.email,
				});

				if (window.location.hostname.includes("electronic.")) {
					sendEvent({ property: "pe14489857_b2a_se_sign_up" });
				}

				if (window.location.hostname.includes("music.")) {
					sendEvent({ property: "pe14489857_sign_up_date" });
				}
			}
			if (noAccounts && !newUser) {
				history.push("/add-account-1");
			}
		}
	}, [userMeta, myAccounts]);

	// use new end-point to update user address and name etc
	useEffect(() => {
		if (
			profile.isSuccess &&
			userMeta.isSuccess &&
			userMeta.data !== null &&
			profile.data !== null &&
			profile.data !== undefined &&
			profile.data !== "undefined"
		) {
			if (!profile.data.countrycode) {
				createProfileMutation.reset();
				const data = {
					firstname: profile.data.firstname,
					lastname: profile.data.lastname,
					addressline1: userMeta.data.address_1,
					addressline2: userMeta.data.address_2,
					addressline3: null,
					towncity: userMeta.data.town,
					countystate: userMeta.data.county,
					postzipcode: userMeta.data.postcode,
					phonenumber: null,
					countrycode: userMeta.data.country,
				};
				createProfileMutation.mutate(data, {
					onSuccess: () => {
						profile.refetch();
					},
					// onError: (error) => {
					//   console.log(error);
					// },
				});
			}
		}
	}, [profile.isSuccess, userMeta.isSuccess]);

	useEffect(() => {
		if (
			profile.isSuccess &&
			profile.data !== undefined &&
			profile.data !== "undefined" &&
			profile.data !== null
		) {
			if (!hubspotProps?.last_login_date) {
				sendProperty({
					property: "last_login_date",
					value: "CURRENT_DATE",
					email: profile.data.email,
				});
				AppState.update((s) => {
					s.hubspot.last_login_date = true;
				});
			}
			sendEvent({ property: "pe14489857_portal_login" });
		}
	}, [profile.isSuccess]);

	useEffect(() => {
		if (myAccounts?.data?.artistaccounts && !selectedAccount) {
			const account = myAccounts?.data?.artistaccounts[0];
			AppState.update((s) => {
				s.selectedAccount = account.artistaccountcode;
				s.selectedCatalogue = account.cataloguecode;
			});
		}
	}, [myAccounts]);

	useEffect(() => {
		const handleEvent = (event) => {
			const { message, data } = event;
			if (data === "close" || message === "close") {
				setShowContractModal(false);
				contracts.refetch();
			}
		};

		window.addEventListener("message", handleEvent, false);
		return function cleanup() {
			window.removeEventListener("message", handleEvent);
		};
	}, []);

	useEffect(() => {
		if (myAccount.isSuccess && profile.isSuccess) {
			sendProperty({
				property: "artist_name",
				value: myAccount?.data?.accountname,
				email: profile?.data?.email,
			});
		}
	}, [myAccount.isSuccess, profile.isSuccess]);

	useEffect(() => {
		if (artistMeta.isSuccess && profile.isSuccess && artistMeta?.data) {
			// if (artistMeta?.data?.twitter_obj) {
			//   const tobj = JSON.parse(artistMeta.data.twitter_obj);
			//   sendProperty({
			//     property: 'twitterhandle',
			//     value: tobj?.data?.username,
			//     email: profile?.data?.email,
			//   });
			// }
			if (artistMeta?.data?.twitter) {
				sendProperty({
					property: "twitter",
					value: artistMeta?.data?.twitter_obj,
					email: profile?.data?.email,
				});
			}

			if (artistMeta?.data?.spotify_obj) {
				const sobj = JSON.parse(artistMeta.data.spotify_obj);
				sendProperty({
					property: "spotify_profile",
					value: sobj?.external_urls?.spotify,
					email: profile?.data?.email,
				});
			}

			if (artistMeta?.data?.songkick_obj) {
				const skobj = JSON.parse(artistMeta.data.songkick_obj);
				sendProperty({
					property: "songkick_profile",
					value: skobj?.uri,
					email: profile?.data?.email,
				});
			}

			if (artistMeta?.data?.facebook_obj) {
				sendProperty({
					property: "facebook_page_url",
					value: artistMeta?.data?.facebook_obj,
					email: profile?.data?.email,
				});
			}
			if (artistMeta?.data?.instagram_obj) {
				sendProperty({
					property: "instagram",
					value: artistMeta?.data?.instagram_obj,
					email: profile?.data?.email,
				});
			}
		}
	}, [artistMeta.isSuccess, profile.isSuccess]);

	useEffect(() => {
		if (artistMeta.isSuccess && myAccount.isSuccess && artistMeta?.data && myAccount?.data) {
			if (!raSpotifyId && pdSpotifyId) {
			console.log("Doesnt Match!!");
			const data = {
				account_id: selectedAccount,
				facebook_obj: artistMeta.data?.facebook,
				spotify_obj: null,
				songkick_obj: artistMeta.data?.facebook?.songkick,
				twitter_obj: artistMeta.data?.facebook?.twitter,
				bandsintown_obj: artistMeta.data?.facebook?.bandsintown,
				instagram_obj: artistMeta.data?.facebook?.instagram,
			};
			updateArtistMeta.reset();
			updateArtistMeta.mutate(data, {
				onSuccess: () => {
				artistMeta.refetch();
				}
			});
			} 
		}
		}, [artistMeta.isSuccess, myAccount.isSuccess]);	  

	useEffect(() => {
		if (configIsSuccess) {
			document.title = `${siteConfig?.display_name} Portal`;
		} 
	}, [configIsSuccess]);

	if (!myAccounts?.data || !configIsSuccess) {
		return <Loading />;
	}

	const kyc = AppState.getRawState()?.kyc;
	const { showKyc, showKycPending } = processKycStatuses(kyc);

	const hideRoyalties =
		profile?.data?.hideroyalties || showKyc || showKycPending;
	return (
		<div className={authLayoutClasses}>
			<div className='flex flex-row flex-wrap flex-grow min-h-screen lg:flex-nowrap'>
				<div
					style={
						isTunecore
							? { backgroundColor: "#141414" }
							: { backgroundColor: darkColour }
					}
					className='fixed z-30 flex-shrink-0 w-full text-white lg:w-60 lg:relative'
				>
					<div
						style={
							isTunecore
								? { backgroundColor: "#141414" }
								: { backgroundColor: darkColour }
						}
						className='fixed flex flex-col w-full lg:w-60'
					>
						{!adminRights && isTunecore && (
							<div className='absolute flex items-center justify-center w-full px-2 py-2 text-base bg-opacity-40 lg:rounded-br-lg auto bg-tunecore-green'>
								This site is in read-only mode
							</div>
						)}
						<div
							className={`flex items-center justify-center px-5 align-center mb-14 ${
								adminRights && isTunecore ? "mt-10" : "mt-8"
							}`}
						>
							{siteConfig?.logo && (
								<img className='h-6 lg:h-12' src={siteConfig.logo} alt='logo' />
							)}
						</div>
						<nav className='hidden sidenav lg:block h-screen-minus-side-header md:overflow-y-auto'>
							<NavItems
								darkColour={darkColour}
								highlightColour={highlightColour}
								enableOpportunities={!siteConfig?.disable_opportunities}
								enableGigs={!siteConfig?.disable_gigs}
								enableBroadcasts={!siteConfig?.disable_broadcasts}
								enableDashboard={!siteConfig?.disable_dashboard}
								enableWriters={!siteConfig?.disable_writers}
								enableSongs={!siteConfig?.disable_songs}
								enableSyncs={!siteConfig?.disable_syncs}
								enableStatements={
									!siteConfig?.disable_statements && !hideRoyalties
								}
								enableRoyalties={
									!siteConfig?.disable_royalties && !hideRoyalties
								}
								enableSecurity
							/>
						</nav>
					</div>
				</div>

				<div className='w-full mt-20 lg:mt-0'>
					<div
						style={
							isTunecore
								? { backgroundColor: "#141414" }
								: { backgroundColor: "#FFFFFF" }
						}
						className={`${
							isTunecore ? "text-white" : "text-black"
						} ${
							showKyc || showKycPending ? "h-32 lg:h-16" : "h-16"
						} flex flex-col lg:flex-row items-center justify-between lg:justify-end fixed w-full lg:w-full-minus-nav z-30`}
					>
						<div className='flex flex-row justify-between lg:justify-end order-1 sm:order-2 w-full lg:w-max h-16 px-4'>
							{width < 1024 && ( // lg breakpoint
								<div className="w-full">
									<button
										onClick={() => setMenu(!menu)}
										type='button'
										className='flex items-center h-full justify-center hover:underline'
									>
										<FaBars className='mr-2' /> <p className='mt-1'>Menu</p>
									</button>
								</div>
							)}

							{myAccount?.data?.accountname && (
								<>
										<UserDropDown
											highlightColour={highlightColour}
											siteConfig={siteConfig}
											switchAccount={switchAccount}
											accounts={myAccounts.data}
											logout={logout}
											data={myAccount.data}
											selectedAccount={selectedAccount}
										/>
								</>
							)}
						</div>

						{showKyc && (
							<a
								href='/security'
								type='link'
								className='flex flex-col items-center justify-center w-full lg:grow h-16 p-4 text-xs font-semibold text-center text-black bg-red-500 sm:text-sm lg:text-base hover:underline'
							>
								<p href='/security' className='flex items-center gap-3'>
									<IoWarningOutline className='hidden lg:inline' />
									{t("common:kyc-warning")}
								</p>
							</a>
						)}
						{showKycPending && (
							<a className='flex flex-col items-center justify-center w-full lg:w-grow h-16 p-4 text-xs font-semibold text-center text-black bg-yellow-500 sm:text-sm lg:text-base '>
								<p className='flex items-center gap-3'>
									<IoWarningOutline className='hidden lg:inline' />
									{t("common:kyc-pending-warning")}
								</p>
							</a>
						)}
					</div>

					{menu && ( // mobile version
						<div
							className={`${
								showKyc || showKycPending ? "top-52 lg:top-36" : "top-36"
							} z-20 fixed w-full lg:hidden`}
							style={
								isTunecore
									? { backgroundColor: "#141414" }
									: { backgroundColor: darkColour }
							}
						>
							<div className='h-screen overflow-y-scroll lg:overflow-visible'>
								<Spacer />
								<NavItems
									darkColour={darkColour}
									highlightColour={highlightColour}
									enableOpportunities={!siteConfig?.disable_opportunities}
									enableGigs={!siteConfig?.disable_gigs}
									enableBroadcasts={!siteConfig?.disable_broadcasts}
									enableDashboard={!siteConfig?.disable_dashboard}
									enableWriters={!siteConfig?.disable_writers}
									enableSongs={!siteConfig?.disable_songs}
									enableSyncs={!siteConfig?.disable_syncs}
									enableStatements={
										!siteConfig?.disable_statements && !hideRoyalties
									}
									enableRoyalties={
										!siteConfig?.disable_royalties && !hideRoyalties
									}
									enableSecurity
								/>
								<div className={showKyc || showKycPending ? "h-56" : "h-44"} />
							</div>
						</div>
					)}

					<div
						className={`${
							showKyc || showKycPending ? "mt-32 lg:mt-16" : "mt-16"
						} lg:pb-0`}
					>
						{children}
					</div>
				</div>
			</div>
			{showContractModal && contracts?.data?.results?.length && (
				<div className='fixed top-0 left-0 z-50 flex justify-center w-full h-full bg-black'>
					<div className='w-full h-full bg-white'>
						<iframe
							width='100%'
							height='100%'
							title='sign contract'
							src={`https://contracts-uat.sentricapp.com/sign/${contracts.data.results[0].code}?exit=1`}
							frameBorder='0'
							style={{ height: "100%" }}
						/>
					</div>
				</div>
			)}
		</div>
	);
}
export default AuthLayout;
