import { useQuery } from '@tanstack/react-query';
import client from '../client';

const getWriterTerms = async (id) => {
  
  const query = `*[_id == '${id}'][0]{
    terms
  }`;

  try {
    const termsData = await client.fetch(query);
    return termsData;
  } catch (error) {
    console.error('Error fetching Terms data:', error);
    throw error;
  }
};

const useTermsQuery = (id) => useQuery(['terms', id], () => getWriterTerms(id), {
    onError: (error) => {
      console.error('Error in useTermsQuery:', error);
    },
    staleTime: 120000, // 2 minutes
    enabled: !!id,
  });

export default useTermsQuery;
