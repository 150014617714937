/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import P from '../../components/p';
import Spacer from '../../components/spacer';
import SmallButton from '../../components/smallButton';
import bandsintown from '../../images/bandsintown.png';
import AppState from '../../store/appstate';
import Select from '../../components/select';
import AuthPrimaryButton from '../../components/authPrimaryButton';

function AddBandsintown() {
  const { t } = useTranslation('onboarding');
  const history = useHistory();
  const apiUrl = AppState.getRawState()?.apiUrl;
  const bandsintownResult = AppState.useState((s) => s.addAccount.bandsintown);

  const getOptions = (query) => new Promise((resolve, reject) => {
    if (!query) {
      resolve([]);
      return;
    }
    fetch(`${apiUrl}/bandsintown?s=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .then((data) => {
        resolve([{ value: JSON.stringify(data), name: data.name, photo: data.thumb_url }]);
      })
      .catch(reject);
  });

  return (
    <div className="container mx-auto">
      <div className="flex flex-col justify-center items-center text-white p-4 text-center min-h-screen-minus-footer">

        <img width="80" src={bandsintown} alt="bandsintown logo" />
        <Spacer />
        {!bandsintownResult
            && (
              <>
                <h1 className="text-6xl">{t('onboarding:add-bandsintown-header')}</h1>
                <Spacer />
                <P>{t('onboarding:add-bandsintown-desc')}</P>
                <Spacer />
                <div className="relative w-full flex flex-col items-center justify-center">
                  <div className="w-full lg:w-1/3">
                    <div className="w-full flex flex-row items-start justify-center text-old-sentric-dark-gray">
                      <Select
                        authSearch
                        className='custom-select no-dropdown'
                        debounce={500}
                        onChange={(artist) => {
                          AppState.update((s) => { s.addAccount.bandsintown = artist; });
                        }}
                        getOptions={getOptions}
                        placeholder={t('onboarding:search-bandsintown-placeholder')}
                        search
                        customLabel="Bandsintown Artist Name"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
        {!!bandsintownResult
            && (
              <>
                <h1 className="text-6xl">{t('onboarding:selected-bandsintown-profile')}</h1>
                <Spacer />
                <div className="bg-white rounded-lg shadow-lg flex flex-col p-4 px-6 items-center text-old-sentric-dark-gray">
                  {JSON.parse(bandsintownResult).thumb_url && JSON.parse(bandsintownResult).thumb_url.length > 0
                    && (
                    <img
                      className="w-20 h-20 object-cover rounded-full mb-4"
                      alt={JSON.parse(bandsintownResult).name}
                      src={JSON.parse(bandsintownResult).thumb_url}
                    />
                    )}
                  {(!JSON.parse(bandsintownResult).thumb_url)
                    && <div className="w-20 h-20 bg-gray-300 rounded-full mb-4" />}
                  <p className="font-bold mb-4">{JSON.parse(bandsintownResult).name}</p>
                  <SmallButton onClick={() => {
                    AppState.update((s) => { s.addAccount.bandsintown = null; });
                  }}
                  >
                    {t('common:remove')}
                  </SmallButton>
                </div>

              </>
            )}
        <Spacer />

        <div className="flex flex-row w-full lg:w-1/3 justify-center">
              <AuthPrimaryButton
                onClick={() => history.push('/add-account-5')}
                >
                {t('common:_done')}
              </AuthPrimaryButton>
        </div>
        <Spacer />

      </div>
    </div>
  );
}

export default AddBandsintown;
