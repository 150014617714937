import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getMyAccount, getSetlists } from '../../../../api/queries';
import H2 from '../../../../components/h2';
import Spacer from '../../../../components/spacer';
import AppState from '../../../../store/appstate';
import SetlistsTable from '../../../../components/setlistsTable';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function Setlists() {
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const history = useHistory();
  const { data: siteConfig } = useSiteConfigAuth();
  if (siteConfig.disable_gigs) {
    history.push('/');
  };
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });

  const setlists = getSetlists({
    ac: selectedAccount,
    oc: myAccount?.data?.originatorcode?.code,
  });

  useEffect(() => {
    if (selectedAccount) {
      setlists.refetch();
    }
  }, [selectedAccount]);

  const { t } = useTranslation('gigs');

  return (
      <>
        <div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
          <H2>{t('gigs:your-setlists-header')}</H2>
          <Spacer />
          <SetlistsTable />
        </div>
      </>
  );
}
export default Setlists;
