/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { FaIcons, FaPlusCircle, FaInfoCircle } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import AppState from "../store/appstate";
import ButtonPrimary from "./buttonPrimary";
import RebrandButtonLink from "./rebrandButtonLink";
import Spacer from "./spacer";
import getColours from "../helpers/getColours";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";

function MultipleInput(props) {
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const textColor = isTunecore ? "black" : "white";
	const { t } = useTranslation();
	const {
		value,
		onChange,
		valueKey,
		reset,
		placeholder,
		required,
		addAnotherText,
		name,
		type,
		error,
		onBlur = () => {},
		className,
		maxLength,
		infoTooltip,
	} = props;

	const ref = useRef();

	const { highlightColour, highlightColour2 } = getColours();
	const backgroundColor = isTunecore ? "#00ef86" : highlightColour;
	const [activeInputId, setActiveInputId] = useState({
		hover: null,
		focus: null,
	});
	const handleMouseEnter = (id) =>
		setActiveInputId((prev) => ({ ...prev, hover: id }));
	const handleMouseLeave = () =>
		setActiveInputId((prev) => ({ ...prev, hover: null }));
	const handleFocus = (id) =>
		setActiveInputId((prev) => ({ ...prev, focus: id }));
	const handleBlur = () =>
		setActiveInputId((prev) => ({ ...prev, focus: null }));

	const [focus, setFocus] = useState(null);
	const [items, setItems] = useState(null);
	

	if (!items && !value) {
		setItems([
			{
				identifier: uuidv4(),
				[valueKey]: null,
			},
		]);
	}

	if (!items && value) {
		const itemArray = value.map((v) => ({ ...v, id: uuidv4() }));
		setItems(itemArray);
	}

	useEffect(() => {
		if (value) {
			const itemArray = value.map((v) => ({ ...v, id: uuidv4() }));
			setItems(itemArray);
		} else {
			setItems([
				{
					id: uuidv4(),
					[valueKey]: null,
				},
			]);
		}
	}, [reset]);

	useEffect(() => {
		if (error) {
			ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
		}
	}, [error]);

	const updateOnChange = (newItems) => {
		setItems(newItems);

		// remove ids to return object to orginal state
		const copy = JSON.parse(JSON.stringify(newItems));
		copy.forEach((item) => {
			delete item.id;
		});
		onChange(copy);
	};

	const onChangeItem = ({ id, val }) => {
		const item = items.find((i) => i.id === id);
		item[valueKey] = val;

		updateOnChange(items);
	};

	const remove = (id) => {
		const newItems = items.filter((q) => q.id !== id).slice();
		updateOnChange(newItems);
	};

	const addAnother = () => {
		const newItems = items.slice();
		newItems.push({
			id: uuidv4(),
			[valueKey]: null,
		});
		updateOnChange(newItems);
	};

	return (
		<div className={`${className}`}>
			{items?.map((v, i) => (
				<>
					<label
						className={[
							`pointer-events-none flex text-sm items-center pb-0.5 ${
								isTunecore
									? "text-white opacity-70"
									: "font-sentric-alt font-semibold text-sentric-rebrand-grey-text"
							}`,
						]}
						htmlFor={name}
					>
						{placeholder}
						{items.length > 1 && <> {i + 1}</>}
					</label>
					<div
						key={`item${v.id}`}
						className={`relative block w-full ${required ? "required-star" : ""}
          ${
						required && isTunecore
							? "text-white"
							: isTunecore
							? "text-black"
							: "rounded-lg font-sentric-alt bg-transparent border font-semibold"
					}`}
					>
						<input
							style={{
								borderColor:
									activeInputId.hover === v.id || activeInputId.focus === v.id
										? highlightColour2
										: undefined,
								boxShadow:
									activeInputId.hover === v.id || activeInputId.focus === v.id
										? `0 0 0 2px ${highlightColour2}`
										: undefined,
							}}
							className={[
								`${
									isTunecore
										? "bg-tunecore-grey-element text-white focus:ring-tunecore-green"
										: ""
								} rounded-lg border-0 focus:outline-none focus:ring-2 w-full text-sm font-semibold p-2 pl-3 pr-3`,
								v[valueKey] ? "" : "",
							].join(" ")}
							id={`${name}_${i}`}
							name={name}
							maxLength={maxLength}
							defaultValue={v[valueKey]}
							type={type || "text"}
							onMouseEnter={() => handleMouseEnter(v.id)}
							onMouseLeave={handleMouseLeave}
							onFocus={() => handleFocus(v.id)}
							onBlur={handleBlur}
							onChange={(e) => onChangeItem({ id: v.id, val: e.target.value })}
						/>
					</div>
					{items.length > 1 && (
						<div className='flex justify-end mt-2 text-sm'>
							<RebrandButtonLink
								onClick={() => remove(v.id)}
								className='items-center h-4 underline'
							>
								<span className='ml-2'>{t("common:remove")}</span>
							</RebrandButtonLink>
						</div>
					)}
					<Spacer thin />
				</>
			))}
			{!!error && (
				<p
					ref={ref}
					className={`w-4/5 ${
						isTunecore
							? "bg-tunecore-error-red bg-opacity-20 border-none"
							: "font-sentric-alt bg-red-700 border-white border"
					} text-sm inline-block p-1 pr-3 pl-3 mt-2 text-white rounded-lg `}
				>
					{isTunecore && (
						<span>
							<RiErrorWarningFill className='inline-block mr-2 text-2xl text-tunecore-error-red' />
						</span>
					)}
					{error}
				</p>
			)}

			<div className='flex items-center justify-end w-full h-4 mt-6'>
				{infoTooltip && (
					<div className='flex items-center'>
						<i data-tip data-for='toolTip' type='button'>
							<FaInfoCircle className='mr-2' />
						</i>
						<ReactTooltip
							id='toolTip'
							className='max-w-md'
							place='right'
							type='info'
							effect='solid'
							multiline='true'
							textColor={textColor}
							backgroundColor={backgroundColor}
						>
							{infoTooltip}
						</ReactTooltip>
					</div>
				)}
				<RebrandButtonLink plusIcon onClick={addAnother} id={`btn${name}`}>
					{addAnotherText}
				</RebrandButtonLink>
			</div>
		</div>
	);
}

export default MultipleInput;
