import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/Modal";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";
import RebrandButtonLink from "../../../../components/rebrandButtonLink";
import getColours from "../../../../helpers/getColours";
import Spacer from "../../../../components/spacer";

const SingleWriterWarningModal = ({ isOpen, onClose, onConfirm }) => {
	const [doNotShowAgain, setDoNotShowAgain] = useState(false);
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const { t } = useTranslation(["songs", "common"]);
	const { highlightColour } = getColours();

	const handleConfirm = () => {
		if (doNotShowAgain) {
			localStorage.setItem("showWriterWarning", "false");
		}
		onConfirm();
	};

	return (
		<Modal smallModal openPortal={isOpen} closePortal={onClose}>
			<div className={`flex flex-col justify-between min-h-80 sm:min-h-48 ${
							isTunecore ? "text-white" : "text-black font-sentric"
						}`}>
				<div>
					<h2
						className='text-lg'
					>
						{t('songs:single-writer-warning-header')}
					</h2>
					<Spacer thin />
					<p>
						{t('songs:single-writer-warning-description')}
					</p>
				</div>

				<div>
					<input
						style={{
							accentColor: highlightColour,
						}}
						type='checkbox'
						className={`mr-3 lg:scale-125 ${
							isTunecore ? "text-tunecore-blue" : ""
						}`}
						id='doNotShowAgain'
						checked={doNotShowAgain}
						onChange={() => setDoNotShowAgain(!doNotShowAgain)}
					/>
					<label htmlFor='doNotShowAgain'>
						{t('common:dont-show-again')}
					</label>
				</div>
			</div>
			<Spacer thin />
			<div className='flex flex-col sm:flex-row items-center gap-4 justify-between'>
				<RebrandButtonLink onClick={onClose}>
					{t('common:take-me-back-button')}
				</RebrandButtonLink>
				<RebrandPrimaryButton onClick={handleConfirm}>
					{t('common:i-understand-button')}
				</RebrandPrimaryButton>
			</div>
		</Modal>
	);
};

export default SingleWriterWarningModal;
