const Alert = (props) => {
  const { msg } = props;
  return (
    <div className="w-4/5 text-sm inline-block p-1 pr-3 pl-3 bg-red-700 text-white rounded-lg border-white border" role="alert">
      <div className="flex flex-col items-start">
        <p className="font-bold">Uh oh</p>
        <p className="text-sm">{msg}</p>
      </div>
    </div>

  );
};

export default Alert;
