import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Input from "../../components/input";
import Spacer from "../../components/spacer";
import { getMyAccounts, getUserMeta, getMyCatalogues } from "../../api/queries";
import AppState from "../../store/appstate";
import Select from "../../components/select";
import Loading from "../../layouts/loading";
import FormError from "../../components/formError";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import AuthPrimaryButton from "../../components/authPrimaryButton";

function AddAccount1() {
  const { t } = useTranslation(["onboarding", "regex"]);
  const history = useHistory();
  const userMeta = getUserMeta();
  const addAccountName = AppState.useState((s) => s.addAccount.accountName);
  const myAccounts = getMyAccounts();
  const myCatalogues = getMyCatalogues();
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [valid, setValid] = useState(true);
  const { data: domainConfig } = useDomainConfigAuth();
  const isTunecore = domainConfig?.display_name === "TuneCore";
  const isCatalogueOptions = domainConfig?.add_account_catalogue_options;
  const [pending, setPending] = useState(false);
  const [catalogueOptions, setCatalogueOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (myAccounts.isSuccess && myAccounts.data && myCatalogues.isSuccess && myCatalogues.data) {
      const uniqueCatalogues = [];
      myCatalogues.data.catalogues.forEach((account) => {
        if (
          !uniqueCatalogues.find(
            (item) =>
              item.name === account.cataloguename &&
              item.value === account.cataloguecode
          )
        ) {
          uniqueCatalogues.push({
            name: account.cataloguename,
            value: account.cataloguecode,
          });
        }
      });
      setCatalogueOptions(uniqueCatalogues);
    }
  }, [myAccounts.isSuccess, myAccounts.data, myCatalogues.isSuccess, myCatalogues.data]);

  if (myAccounts.isLoading || userMeta.isLoading) {
    return <Loading />;
  }

  const doesNameAlreadyExist = (name) =>
    myAccounts.data.artistaccounts.find(
      (a) => a.accountname.toLowerCase() === name.toLowerCase().trim()
    );

  const checkValid = (text) => {
    let error = "";
    setValid(true);
    setValidationErrorMessage("");
    const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
    if (emojiRegex.test(text)) {
      error = t("regex:artist-account-no-emoji-error");
      setValid(false);
    }
    if (text.length > 199) {
      error = t("regex:artist-account-name-char-error");
      setValid(false);
    }
    setValidationErrorMessage(error);
  };

  const checkErrors = () => {
    setValidationErrorMessage("");
    if (myAccounts.data) {
      if (doesNameAlreadyExist(addAccountName)) {
        setValidationErrorMessage(t("regex:artist-name-already-exists-error"));
        return;
      }
    }
    if (!valid) {
      return;
    }

    history.push("/add-account-3");
  };

  let title = t("onboarding:artist-title");
  let placeholder = t("onboarding:artist-name-placeholder");
  switch (userMeta?.data?.role) {
    case "Artist":
    case "DJ":
    case "Songwriter":
    case "Producer":
      title = t("onboarding:artist-title");
      placeholder = t("onboarding:artist-name-placeholder");
      break;
    case "Band":
      title = t("onboarding:band-title");
      placeholder = t("onboarding:band-name-placeholder");
      break;
    case "Manager":
      title = t("onboarding:add-artist-to-account");
      placeholder = t("onboarding:artist-name-placeholder");
      break;
    default:
      break;
  }

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer">
        {userMeta.isSuccess && (
          <>
            <h1 className="text-6xl">{title}</h1>
            <Spacer />
            <div className="w-full lg:w-1/3 text-old-sentric-dark-gray">
              <Input
                className={`${!isTunecore ? "auth-page-input" : ""}`}
                placeholder={placeholder}
                whiteLabelText
                value={addAccountName}
                onBlur={(e) => checkValid(e.target.value)}
                onChange={(e) =>
                  AppState.update((s) => {
                    s.addAccount.accountName = e.target.value;
                  })
                }
              />
              <Spacer />
              <Spacer />

              {isCatalogueOptions && catalogueOptions.length > 0 && (
                <>
                  <h2 className="text-white text-2xl">
                    {t("onboarding:catalogue-title")}
                  </h2>
                  
                  <Spacer thin />

                  <Select
                    authSearch
                    list
                    className={`${
                      isTunecore
                        ? "tunecore-select tunecore-input-hover"
                        : "custom-select"
                    } text-old-sentric-dark-gray`}
                    name="artist"
                    placeholder="Please Select"
                    onChange={(e) => {
                      console.log(e);
                      AppState.update((s) => {
                        s.addAccount.cataloguecode = e;
                      });

                      setSelectedOption(true);
                    }}
                    options={catalogueOptions}
                  />

                  <Spacer />
                  <Spacer />
                </>
              )}
              <FormError validationError={validationErrorMessage} />
              <AuthPrimaryButton
                disabled={pending || !addAccountName || !valid || (isCatalogueOptions && !selectedOption && catalogueOptions.length > 0)}
                pending={pending}
                onClick={() => {
                  checkErrors();
                  setPending(true);
                }}
                arrow
              >
                {t("onboarding:next-step-button")}
              </AuthPrimaryButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AddAccount1;
