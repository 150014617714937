import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import getColours from '../helpers/getColours';
// import { FaLock, FaRegEdit } from 'react-icons/fa';

// use classes object if future catalogues need different styling
function GigsPill({ object }) {
  const { t } = useTranslation('gigs');
  const { data: siteConfig } = useSiteConfigAuth();
  const { statusSuccessColour, statusWarningColour, statusErrorColour } = getColours();
  const isTunecore = siteConfig?.display_name === "TuneCore";
  const styles = isTunecore
    ? { textColor:'black', backgroundColor: '#00ef86' }
    : {};
  let obj = {
    status: '',
    classes: '',
  };

  if (object === 1) {
    obj = {
      colours: {
        backgroundColor: statusWarningColour,
      },
      status: t('gigs:pill-not-verified'),
      tooltip: '',
    };
  }
  if (object === 2 || object === 3 || object === 6) {
    obj = {
      colours: {
        backgroundColor: statusSuccessColour,
      },
      status: t('gigs:pill-processed'),
      tooltip: '',
    };
  }
  if (object === 4) {
    obj = {
      colours: {
        backgroundColor: statusErrorColour,
      },
      status: t('gigs:pill-ineligible'),
      tooltip: '',
    };
  }
  if (object === 5) {
    obj = {
      colours: {
        backgroundColor: statusErrorColour,
      },
      status: t('gigs:pill-rejected'),
      tooltip: '',
    };
  }
  if (object === 7) {
    obj = {
      colours: {
        backgroundColor: statusSuccessColour,
      },
      status: t('gigs:pill-archived'),
      tooltip: '',
    };
  }
  return (
    <div className="tooltip-with-edit-icon-container">
      <ReactTooltip effect="solid" multiline {...styles} />
      <div
        style={obj.colours}
        data-tip={obj.tooltip}
        className={`inline-block text-white text-xxs text-center py-2 px-5 rounded-lg uppercase ${obj.classes}`}
      >
        {obj.status}
      </div>
    </div>
  );
}

export default GigsPill;
