/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spacer from "../../components/spacer";
import BackButton from "../../components/backButton";
import SmallButton from "../../components/smallButton";
import bandsintown from "../../images/bandsintown.png";
import songkick from "../../images/songkick.png";
import bandsintownDark from "../../images/bandsintown-dark.png";
import songkickDark from "../../images/songkick-dark.png";
import AppState from "../../store/appstate";
import AuthPrimaryButton from "../../components/authPrimaryButton";

function AddAccount5() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();
	const songkickResult = AppState.useState((s) => s.addAccount.songkick);
	const bandsintownResult = AppState.useState((s) => s.addAccount.bandsintown);

	return (
		<div className='container mx-auto'>
			<div className='flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer'>
				<div className='flex mb-4'>
					<img
						className='mr-4'
						width='80'
						src={bandsintown}
						alt='bandsintown logo'
					/>
					<img width='80' src={songkick} alt='songkick logo' />
				</div>
				<h1 className='text-6xl'>
					{t("onboarding:connect-bands-songkick-header")}
				</h1>
				<Spacer />

				<div className='flex flex-row flex-wrap justify-center gap-6'>
					{!bandsintownResult && (
						<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
							<p className='mb-2 text-4xl'>
								<img width='50' src={bandsintownDark} alt='bandsintown logo' />
							</p>
							<p>{t("onboarding:_bandsintown")}</p>
							<Spacer />
							<SmallButton onClick={() => history.push("/add-bandsintown")}>
								{t("onboarding:add-account-button")}
							</SmallButton>
						</div>
					)}
					{!!bandsintownResult && (
						<>
							<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
								{JSON.parse(bandsintownResult).thumb_url &&
									JSON.parse(bandsintownResult).thumb_url.length > 0 && (
										<img
											className='object-cover w-20 h-20 mb-4 rounded-full'
											alt={JSON.parse(bandsintownResult).name}
											src={JSON.parse(bandsintownResult).thumb_url}
										/>
									)}
								{!JSON.parse(bandsintownResult).thumb_url && (
									<div className='w-20 h-20 mb-4 bg-gray-300 rounded-full' />
								)}
								<p className='mb-4 font-bold'>
									{JSON.parse(bandsintownResult).name}
								</p>
								<SmallButton onClick={() => history.push("/add-bandsintown")}>
									{t("onboarding:edit-bandsintown")}
								</SmallButton>
							</div>
						</>
					)}
					{!songkickResult && (
						<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
							<p className='mb-2 text-4xl'>
								<img width='50' src={songkickDark} alt='songkick logo' />
							</p>
							<p>{t("onboarding:_songkick")}</p>
							<Spacer />
							<SmallButton onClick={() => history.push("/add-songkick")}>
								{t("onboarding:add-account-button")}
							</SmallButton>
						</div>
					)}
					{!!songkickResult && (
						<>
							<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
								<img
									className='object-cover w-20 h-20 mb-4 rounded-full'
									src={songkickDark}
									alt='songkick logo'
								/>
								<p className='mb-4 font-bold'>
									{JSON.parse(songkickResult).displayName}
								</p>
								<SmallButton onClick={() => history.push("/add-songkick")}>
									{t("onboarding:edit-songkick")}
								</SmallButton>
							</div>
						</>
					)}
				</div>

				<Spacer />

				<div className='flex flex-row w-full lg:w-1/3 justify-evenly'>
					<BackButton onClick={() => history.push("/add-account-4")}>
						{t("onboarding:back-button")}
					</BackButton>
					<AuthPrimaryButton onClick={() => history.push("/add-account-6")}>
						{t("onboarding:next-step-button")}
					</AuthPrimaryButton>
				</div>
			</div>
		</div>
	);
}

export default AddAccount5;
