/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';
import getColours from '../helpers/getColours';

function TextArea(props) {
  const {
    value, onChange, accept, placeholder, name, type, error, required, onBlur = () => {}, className, min, max, step,
  } = props;
  const { data: siteConfig } = useSiteConfigAuth();
  const { highlightColour2 } = getColours();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const v = value;

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`${className} `}>
      <label
        className={[
          `pointer-events-none flex text-sm items-center pb-0.5 ${isTunecore ? 'text-white opacity-70' : 'font-sentric-alt font-semibold text-sentric-rebrand-grey-text'}`
        ]}
        htmlFor={name}
      >
        {placeholder}
      </label>
      <div className={`relative focus:ring-2 bg-white block w-full border-0 rounded-md font-sentric-alt ${required ? 'required-star' : ''}`}>
        <textarea
          style={{
            borderColor: isFocused || isHovered ? highlightColour2 : undefined,
            boxShadow: isFocused || isHovered ? `0 0 0 2px ${highlightColour2}` : undefined,
          }}
          className={[
            ' h-36 border-0 focus:outline-none focus:ring-0 w-full bg-transparent rounded-md text-md p-2 pl-3 pr-3 text-old-sentric-dark-gray text-base font-semibold',
            (v || ['file', 'date'].includes(type)) ? '' : '',
          ].join(' ')}
          id={name}
          name={name}
          value={v}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onFocus={() => setIsFocused(true)}
          onBlur={(e) => {
            setIsFocused(false);
            onBlur(e);
          }}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
          accept={accept}
        />

      </div>
      {!!error
      && <p className="inline-block w-4/5 p-1 pl-3 pr-3 mt-2 text-sm text-white bg-red-700 border border-white rounded-lg">{error}</p>}
    </div>

  );
}

export default TextArea;
