import { useTranslation } from "react-i18next";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import RebrandPrimaryButton from "../../components/rebrandPrimaryButton";

function Error404({ resetError, error }) {
	const { data: domainConfig } = useDomainConfigAuth();
	const supportEmail = domainConfig?.support_email;
	const isTunecore = domainConfig?.display_name === "TuneCore";
	const handleBackClick = () => {
		window.location.href = "/";
	};
	const { t } = useTranslation("security");

	const showErrorButton = Boolean(error);

	return (
		<div className='h-full py-10 justify-center flex gap-10 flex-col lg:flex-row-reverse items-center w-full px-10 lg:pt-32 lg:px-28'>
			<img
				className='w-52 invert'
				src='https://static.thenounproject.com/png/2198121-200.png'
				alt='music record'
			/>

			<div className=' flex flex-col gap-5 w-fit md:w-4/5 items-center text-center lg:text-left lg:items-start'>
				<h1 className='text-xl font-extrabold'>
					{t("security:error-page-title")}
				</h1>
				<p>{t("security:error-page-pg-1")}</p>
				<p>
					{t("security:error-page-pg-2")}{" "}
					{!isTunecore ? (
						<a
							className='underline'
							href={
								supportEmail
									? `mailto:${domainConfig.support_email}`
									: "mailto:support@sentricmusic.com"
							}
						>
							{supportEmail
								? domainConfig.support_email
								: "support@sentricmusic.com"}
						</a>
					) : (
						<a
							className='underline'
							target='_blank'
							href='https://support.tunecore.com/hc/en-us/requests/new?ticket_form_id=662748'
							rel='noreferrer'
						>
							tunecore.com/support
						</a>
					)}
				</p>

				{isTunecore ? (
					<div className='flex flex-row gap-4'>
						<RebrandPrimaryButton onClick={handleBackClick}>
							{t("security:error-page-button")}
						</RebrandPrimaryButton>

						{showErrorButton && (
							<RebrandPrimaryButton onClick={resetError}>
								Try Again
							</RebrandPrimaryButton>
						)}
					</div>
				) : (
					<div className='flex flex-row gap-4'>
						<button
							onClick={handleBackClick}
							className='w-fit p-2 rounded-lg bg-sentric-rebrand-orange hover:bg-sentric-rebrand-orange-hover'
							type='button'
						>
							{t("security:error-page-button")}
						</button>
						{showErrorButton && (
							<button
								onClick={resetError}
								className='w-fit p-2 rounded-lg bg-sentric-rebrand-orange hover:bg-sentric-rebrand-orange-hover'
								type='button'
							>
								Try Again
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default Error404;
