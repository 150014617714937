import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import { FaUserTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import H2 from '../../../../components/h2';
import Spacer from '../../../../components/spacer';
import { getKycStatus } from '../../../../api/queries';
import AppState from '../../../../store/appstate';
import {
  startKyc,
} from '../../../../api/mutations';
import RebrandPrimaryButton from '../../../../components/rebrandPrimaryButton';
import FormError from '../../../../components/formError';

function SecurityFailed() {
  const { t } = useTranslation('security');
  const kycStatus = getKycStatus();
  const [gettingKyc, setGettingKyc] = useState(true);
  let message = null;
  const history = useHistory();
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  switch (kycStatus?.data?.kycStatus) {
    case 4:
    case 7:
      console.log('kycStatus', kycStatus?.data?.restartkyc);
      if (kycStatus?.data?.restartkyc === false) {
        message = t('security:security-status-failed-no-retries-left');
      } else {
        message = t('security:security-status-failed');
      }
      break;
    case 5:
      message = t('security:security-status-failed-expired');
      break;
    case 9:
      message = t('security:security-status-failed-picture');
      break;
    case 10:
      message = t('security:security-status-failed-document-not-supported');
      break;
    case 11:
      message = t('security:security-status-failed-invalid-document');
      break;
    case 12:
      message = t('security:security-status-failed-country-mismatch');
      break;
    case 13:
      message = t('security:security-status-failed-duplicate-verification');
      break;
    case 14:
      message = t('security:security-status-failed-incorrect-document');
      break;
    default:
      message = t('security:security-failed-text');
      break;
  }

  const startKycStatusMutation = startKyc();

  const kyc = AppState.useState((s) => s?.kyc);
  if (kyc?.messages[0] === 'KYC still pending') {
    history.push('/security/begin-security-check');
  }

  const beginKyc = () => {
    setGettingKyc(true);
    startKycStatusMutation.reset();
    setMutationErrorMessage('');
    setValidationErrorMessage('');
    startKycStatusMutation.mutate(null, {
      onSuccess: (res) => {
          if (res?.kycpassed === true) {
            setValidationErrorMessage('Security Check Already Completed');
          } else {
            history.push('/security/begin-security-check');
          }
          setGettingKyc(false);
      },
      onError: (error) => {
          setMutationErrorMessage(error.message);
          setGettingKyc(false);
          AppState.update((s) => {
            s.navItemsEnabled = true;
          });
          console.log('start kyc error', res);
      },
    });
  };

  return (
    <div className="flex flex-wrap">
      <div className='w-full h-screen lg:min-h-screen-minus-header'>
        <div className="mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12">
          <div className="text-center lg:text-left">
            <H2>{t('security:security-check-header')}</H2>
          </div>
          <Spacer />
          <div className="flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg whitespace-pre-wrap security-text-container grid grid-flow-col grid-auto-columns: minmax(0, 2fr);">
              <div className="flex flex-col items-center">
                <div className="flex flex-col items-center">
                  <div className="security-icon mb-6">
                    <FaUserTimes />
                  </div>
                  <div className="html-content font-sentric-alt">
                    <Markup content={message} />
                  </div>
                </div>
                {kycStatus?.data?.restartkyc ? (
                  <div className="mt-12">
                    <RebrandPrimaryButton
                    // eslint-disable-next-line no-restricted-globals
                      disabled={!gettingKyc}
                      onClick={() => beginKyc()}
                      arrow
                    >
                      {t('security:security-restart-button')}
                    </RebrandPrimaryButton>
                  </div>
                ) : (
                  <div className="mt-12">
                      <RebrandPrimaryButton
                        disabled={!gettingKyc}
                        onClick={() => history.push('/')}
                        arrow
                      >
                        {t('other:go-to-dashboard')}
                      </RebrandPrimaryButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
        </div>
      </div>
    </div>
  );
}
export default SecurityFailed;
