/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { getRecordingMetas, getMyAccount } from '../../../../api/queries';
import RecordingAttachmentForm from './RecordingAttachmentForm';
import AppState from '../../../../store/appstate';
import { hasAdminRights } from '../../../../auth/shared';
import RebrandPrimaryButton from '../../../../components/rebrandPrimaryButton';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';
import Modal from '../../../../components/Modal';

function SongRecording({ recording, last }) {
  const recordingMetas = getRecordingMetas({ id: recording.workcode });
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const adminRights = hasAdminRights(myAccount?.data);
  const { t } = useTranslation('songs');
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  let recordingInfo = null;

  if (recordingMetas.isSuccess) {
    recordingInfo = recordingMetas?.data?.find(
      (r) => (r.id === recording.recordingidentifier || r.id === recording.uicbarcode),
    );
  }

  const attachMp3lbl = recording.s3presignedurl ? t('songs:attach-new-mp3') : t('songs:attach-mp3');

  const [recordingAttachmentModalIsOpen, setRecordingAttachmentModalIsOpen] = useState(false);
  const [mp3PlayerModalIsOpen, setMp3PlayerModalIsOpen] = useState(false);

  const [recordingMp3Url, setRecordingMp3Url] = useState('');
  const [recordingAttachmentModal, setRecordingAttachmentModal] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      recordingMetas.refetch();
    }, 1000);
  }, []);

  return (
      <>
        <Modal
          smallModal
          openPortal={recordingAttachmentModalIsOpen}
          closePortal={setRecordingAttachmentModalIsOpen}
        >
          {recordingAttachmentModal !== null && (
            <RecordingAttachmentForm
              artistaccountcode={recording.artistaccountcode}
              recordingamendmentidentifier={
                recording.recordingamendmentidentifier
              }
              recordingidentifier={recording.recordingidentifier}
              workcode={recording.workcode}
              modal
              closeModal={setRecordingAttachmentModalIsOpen}
            />
          )}
        </Modal>

        <Modal
          smallModal
          openPortal={mp3PlayerModalIsOpen}
          closePortal={() => setMp3PlayerModalIsOpen(false)}
        >
          <div className='my-16 audioContainer'>
            <div className="flex flex-col md:flex-row gap-6 items-center mb-4">
              <div className="stockImage w-fit" />
              <div className="flex flex-col items-center md:items-start gap-4">
              {recording.title && <p className='font-bold md:text-xl text-[#AFB8BF]'>{recording.title}</p>}
              {recording.performingartist && <p className='font-medium italic text-[#6D7281]'>{recording.performingartist}</p>}
              </div>
            </div>
            
            {recordingMp3Url && (
              <AudioPlayer
                autoPlay
                src={recordingMp3Url}
              />
            )}
          </div>
        </Modal>
        <div className={`flex flex-wrap items-center py-2  ${last ? '' : 'border-b border-old-sentric-menu-gray'} `}>
          {(recordingInfo?.spotify_id || recordingInfo?.mp3)
          && (
          <div className="w-full h-20 mb-3 mr-4">
              {recordingInfo?.spotify_id
              && (
                <iframe
                  title="preview"
                  src={`https://open.spotify.com/embed/track/${
                    recordingInfo.spotify_id
                  }`}
                  className="w-full"
                  height="80"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                  scrolling="no"
                  style={{ borderRadius: '15px' }}
                />
              )}
          </div>
          )}
          <div style={{ flexGrow: 1 }} className="flex flex-col gap-2">
          <h2 className={`${isTunecore ? 'text-white' : 'text-black font-medium mb-2'} text-base font-bold`}>
              {' '}
              {recording.title}
              {' - '}
              {recording.ident}
              {' - '}
              {recording.performingartist}
            </h2>
            <div className={`text-xs flex flex-col gap-2 sm:gap-0 ${isTunecore ? 'text-white' : 'text-sentric-rebrand-grey-text'}`}>
              <p>
                ISRC:
                {' '}
                {recording.isrc || '-'}
              </p>
              <p>
                {t('songs:release-date')}
                :
                {' '}
                {recording.releasedate
                  ? format(Date.parse(recording.releasedate), 'yyyy-MM-dd')
                  : ''}
              </p>
              <p>
                {t('songs:record-label-caps')}
                :
                {' '}
                {recording.recordlabel || '-'}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start gap-1 mt-4 xl:mt-0 lg:justify-end xl:items-end">
            <RebrandPrimaryButton
              onClick={() =>{
                setRecordingAttachmentModal({
                  recordingidentifier: recording.recordingidentifier,
                  recordingamendmentidentifier: recording.recordingamendmentidentifier,
                  workcode: recording.workcode,
                })
                setRecordingAttachmentModalIsOpen(true)
              }
              }
              disabled={!adminRights}
              arrow={!isTunecore}
            >
              {attachMp3lbl}
            </RebrandPrimaryButton>
            <span className="mr-2" />
            {recording?.s3presignedurl
              && (
              <RebrandPrimaryButton
                onClick={() => {
                  setRecordingMp3Url(recording.s3presignedurl);
                  setMp3PlayerModalIsOpen(true);
                }}
                disabled={!adminRights}
                arrow={!isTunecore}
              >
                {t('songs:play-mp3')}
              </RebrandPrimaryButton>
              )}
          </div>
        </div>
      </>
    );
}

export default SongRecording;
