/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaChevronDown, FaChevronUp,
} from 'react-icons/fa';
import { format } from 'date-fns';
import SongDetails from './SongDetails';
import AppState from '../../../../store/appstate';
import Table from '../../../../components/table';
import StatusPill from '../../../../components/statusPill';

function SongsTable({ data, highlightColour, total }) {
  const { t } = useTranslation();
  const tableState = AppState.useState((s) => s.songsTable);
  const setTableState = (type, value) => {
    // eslint-disable-next-line no-param-reassign
    AppState.update((s) => { s.songsTable[type] = value; });
  };

  const columns = React.useMemo(() => [
    {
      Header: t('songs:song-table-name'),
      accessor: 'title',
      width: '20%',
      id: 'title',
    },
    {
      Header: t('songs:song-table-performers'),
      accessor: (row) => row.performingartists.map((p) => p.performingartistname).join(', '),
      width: '20%', 
      disableSortBy: true,
      id: 'performer',
    },
    {
      Header: t('songs:song-table-date'),
      accessor: 'createddate',
      width: '20%',
      Cell: ({ row }) => (
        ((row?.values?.createddate && row?.values?.createddate !== '0000-00-00') ? format(Date.parse(row.values.createddate), 'yyyy-MM-dd') : null)
      ),
      id: 'createddate',
    },
    {
      Header: t('songs:song-table-writers'),
      accessor: (row) => row.writersplits.map((s) => `${s?.firstname} ${s?.middlename ? s?.middlename : ''} ${s.lastname}`).join(', '),
      width: '20%', 
      disableSortBy: true,
      id: 'writers',
    },
    {
      Header: t('songs:song-table-status'),
      accessor: 'verifiedstate',
      width: '20%',
      id: 'verifiedstate',
      Cell: ({ row }) => (
        <div>
          <StatusPill object={row.original} />
        </div>
      ),
    },
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', // It needs an ID
      width: '5%',

      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span>
          {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      ),
      // We can override the cell renderer with a SubCell to be used with an expanded row
      SubCell: () => null, // No expander on an expanded row
    },
  ].filter(Boolean)); // Filters out any falsy values from the columns array, useful for conditionally adding columns


  return (
    <>
      <Table
        area="SongsTable"
        data={data}
        columns={columns}
        highlightColour={highlightColour}
        revealComponent={
          <SongDetails />
        }
        searchSuffix={t('common:songs-search-suffix')}
        paginated
        setTableState={setTableState}
        initialSort={tableState.sortBy}
        onSort={(p) => setTableState('sortBy', p)}
        tableState={tableState}
        total={total}
        currentpage={tableState.page}
        setPage={(p) => {
          setTableState('page', p);
        }}
        setFilter={(p) => {
          setTableState('globalFilter', p);
        }}
        filter={tableState.globalFilter}
        setPerPage={(p) => setTableState('perPage', p)}
        async

      />
    </>

  );
}

export default SongsTable;
