function Loading() {
  return (
    <div className="fixed z-50 top-0 left-0 w-full min-h-screen antialiased bg-old-sentric-lightest-gray flex items-center justify-center">
      <div id="bars">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>

    </div>

  );
}
export default Loading;
