import { useQuery } from "@tanstack/react-query";
import client from "../client";
import AppState from "../../../store/appstate";

const getSiteConfigWithCode = async (catalogueCode) => {
	const domain = window.location.origin;

	const queryFields = `
    'tenant_prefix': genericConfig.tenant_prefix,
    'logo': genericConfig.logo.asset->url,
    'display_name': genericConfig.display_name,
    'catalogue_codes': catalogueConfig.catalogue_codes,
    'dark_colour': catalogueConfig.dark_colour.hex,
    'highlight_colour': catalogueConfig.highlight_colour.hex,
    'highlight_colour_2': catalogueConfig.highlight_colour_2.hex,
    'status_colour_1_success': catalogueConfig.statusColours.status_colour_1_success.hex,
    'status_colour_2_warning': catalogueConfig.statusColours.status_colour_2_warning.hex,
    'status_colour_3_error': catalogueConfig.statusColours.status_colour_3_error.hex,

    'show_writer_terms': catalogueConfig.show_writer_terms,
    'writer_terms': catalogueConfig.writers_terms.writer_terms_selected[0]->{
      _id,
    },
    'dashboard_box_1': catalogueConfig.dashboard_box_1.distribution_dates_selected[0]->{
      dates
    },
    'dashboard_box_2': catalogueConfig.dashboard_box_2.useful_links_selected[0]->{
      useful_links
    },
    'ipicae_required': catalogueConfig.ipicae_required,
    'vanity_publisher': catalogueConfig.vanity_publisher,
    'disable_hubspot_tracking': catalogueConfig.disable_hubspot_tracking,
    'disable_suggestions': catalogueConfig.disable_suggestions,
    'disable_add_account_button': catalogueConfig.disable_add_account_button,
    'date_of_birth_required': catalogueConfig.date_of_birth_required,
    'disable_broadcasts': catalogueConfig.disable_broadcasts,
    'disable_songs': catalogueConfig.disable_songs,
    'disable_dashboard': catalogueConfig.disable_dashboard,
    'disable_writers': catalogueConfig.disable_writers,

    'disable_gigs': catalogueConfig.disable_gigs,
    'disable_statements': catalogueConfig.disable_statements,
    'disable_royalties': catalogueConfig.disable_royalties,
    'disable_syncs': catalogueConfig.disable_syncs,
    'disable_opportunities': catalogueConfig.disable_opportunities,
    'disable_syncs_and_opportunities_on_dashboard': catalogueConfig.disable_syncs_and_opportunities_on_dashboard,
    'all_syncs_available': catalogueConfig.all_syncs_available,
    'all_opportunities_available': catalogueConfig.all_opportunities_available,
  `;

	const mainQuery = `*[_type == 'siteConfiguration' && ('${catalogueCode}' in catalogueConfig.catalogue_codes || '${domain}' in domainConfig.domain)][0]{${queryFields}}`;

	const defaultQuery = `*[_type == 'siteConfiguration' && name=='DefaultSentric'][0]{${queryFields}}`;

	try {
		let configData = await client.fetch(mainQuery);
		if (!configData) {
			configData = await client.fetch(defaultQuery);
		}
		return configData;
	} catch (error) {
		console.error("Error fetching config data:", error);
		throw error;
	}
};

const useSiteConfigAuth = () => {
	const catalogueCode = AppState.useState((s) => s.selectedCatalogue);
	const catalogueCodeExists = catalogueCode !== null;

	return useQuery(
		["config", catalogueCode],
		() => getSiteConfigWithCode(catalogueCode),
		{
			onError: (error) => {
				console.error("Error in useSiteConfigAuth:", error);
			},
			staleTime: 600_000, // 10 minutes
			enabled: catalogueCodeExists,
		}
	);
};
export default useSiteConfigAuth;
