/* eslint-disable no-nested-ternary */
import { useRef, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { createPortal } from 'react-dom';
import getColours from '../helpers/getColours';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function SubModal({ children, openSubPortal, closeSubPortal }) {
  const { data: siteConfig } = useSiteConfigAuth();
  const { highlightColour } = getColours(siteConfig);
  const [isHovering, setIsHovering] = useState(false);
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const modalRef = useRef(null);

  const mountElement = document.getElementById('sub-modals');

  return createPortal(
    openSubPortal && (
      <div className={`fixed top-0 left-0 z-40 flex items-center justify-center w-screen h-screen bg-black bg-opacity-75 ${isTunecore ? 'font-tunecore' : 'font-sentric-alt'}`}>
        <div
          ref={modalRef}
          className={`overflow-y-scroll w-3/4 h-3/4 p-4 lg:p-8 rounded-lg ${isTunecore ? 'bg-tunecore-dark-element' : 'bg-sentric-rebrand-light-grey'}`}
        >
          <button
            type="button"
            onClick={() => closeSubPortal()}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{
              color: `${isHovering ? highlightColour : ''}`,
            }}
            className={`flex float-right text-3xl  ${isTunecore ? 'text-white hover:text-tunecore-green focus:text-tunecore-green' : ''}`}
            tabIndex={0} // Make the button focusable
            aria-label="Close Modal" // Provide a descriptive label
          >
            <IoCloseCircleOutline />
          </button>
        <>
          {children}
        </>
        </div>
      </div>
    ),
    mountElement
  );
}

export default SubModal;
