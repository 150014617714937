/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';
import H1 from '../../components/h1';
import P from '../../components/p';
import Spacer from '../../components/spacer';
import Input from '../../components/input';
import AppState from '../../store/appstate';
import { getUserMeta } from '../../api/queries';
import AuthPrimaryButton from '../../components/authPrimaryButton';
import useDomainConfigAuth from '../../api/sanity/queries/getSiteConfigWithDomain';

function AddFacebook() {
  const { t } = useTranslation('onboarding');
  const history = useHistory();
  const [facebookUrl, setfacebookUrl] = useState('');
  const [error, setError] = useState({});
  const [valid, setValid] = useState(true);
  const [pending, setPending] = useState(false);
  const { data: domainConfig } = useDomainConfigAuth();
  const isTunecore = domainConfig?.display_name === "TuneCore";

  const facebookRegexCheck = new RegExp('^(http|https)://(?:www.)?facebook.com/.*');
  const checkValid = (url) => {
    setValid(true);
    const errs = {};
    // pass regex check
    if (!facebookRegexCheck.test(url)) {
      console.log('valid', valid);
      setValid(false);
      errs.url = t('regex:facebook-domain-regex');
    }

    setError(errs);
  };

  const confirm = () => {
    if (valid || facebookUrl === '') {
      console.log('confirm');
      AppState.update((s) => { s.addAccount.facebook = facebookUrl; });
      history.push('/add-account-6');
    }
  };
  const userMeta = getUserMeta();

  let title = t('onboarding:add-facebook-header1');
  let desc = t('onboarding:add-facebook-desc1');

  switch (userMeta?.data?.role) {
    case 'Manager':
      title = t('onboarding:add-facebook-header2');
      desc = t('onboarding:add-facebook-desc2');

      break;
    default:
      break;
  }
  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer">
        <p className="text-6xl text-white"><FaFacebook /></p>
        <Spacer />
        <>
          <H1>{title}</H1>
          <Spacer />
          <P>
            {desc}
          </P>
          <Spacer />
          <div className="relative flex flex-col items-center justify-center w-full text-old-sentric-dark-gray">
            <div className="w-full lg:w-1/3">
              <Input
                className={`${isTunecore ? 'auth-tunecore-page-input' : 'auth-page-input'}`}
                whiteLabelText
                error={error.url}
                placeholder={t('onboarding:facebook-url-placeholder')}
                value={facebookUrl}
                onBlur={(e) => checkValid(e.target.value)}
                onChange={(e) => setfacebookUrl(e.target.value)}
              />
            </div>
          </div>
        </>

        <Spacer />
        <div className="flex flex-row justify-center w-full lg:w-1/3">
              <AuthPrimaryButton
              pending={pending}
              onClick={() => confirm() && setPending(true)} arrow>
              {t('common:_done')}
              </AuthPrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default AddFacebook;
