/* eslint-disable no-unused-vars */
/* eslint-disable radix */
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getArtistMeta, getSuggestions } from "../../../../api/queries";
import LoadingSmall from "../../../../components/loadingSmall";
import SocialSpotify from "../../../../components/socialSpotify";
import Spacer from "../../../../components/spacer";
import AppState from "../../../../store/appstate";
import SuggestionsTable from "./SuggestionsTable";
// import RebrandButtonLink from "../../../../components/rebrandButtonLink";
// import Modal from "../../../../components/Modal";

function SongSuggestions({ highlightColour }) {
	const { t } = useTranslation();
	// const [spotifyModalIsOpen, setSpotifyModalIsOpen] = useState(false);
	const [showSpotifyForm, setShowSpotifyForm] = useState(true);
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const artistMeta = getArtistMeta({ aac: selectedAccount });

	useEffect(() => {
		if (selectedAccount) {
			artistMeta.refetch();
		}
	}, [selectedAccount]);

	const spotifyID = artistMeta?.data?.spotify_obj
		? JSON.parse(artistMeta?.data?.spotify_obj)?.id
		: null;

	const suggestions = getSuggestions({
		spotifyid: spotifyID,
		accountId: selectedAccount,
	});

	// const isrcs = getArtistISRCS({ aac: selectedAccount });

	return (
		<>
			{/* <Modal
        openPortal={spotifyModalIsOpen}
        closePortal={setSpotifyModalIsOpen}
      >
        <SocialSpotify modal closeModal={setSpotifyModalIsOpen} />
      </Modal> */}

			<div>
				{artistMeta.isSuccess &&
					!artistMeta?.data?.spotify_obj &&
					showSpotifyForm && (
						<>
							<SocialSpotify closeForm={() => setShowSpotifyForm(false)} />
							<Spacer thin />
						</>
					)}
				{suggestions.isLoading && <LoadingSmall />}
				{suggestions?.data && (
					<SuggestionsTable
						data={suggestions?.data}
						highlightColour={highlightColour}
					/>
				)}
			</div>
		</>
	);
}

export default SongSuggestions;
