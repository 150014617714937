/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import ButtonLink from "./buttonLink";
import Spacer from "./spacer";
import RebrandButtonLink from "./rebrandButtonLink";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import getColours from "../helpers/getColours";

function DoubleMultipleInput(props) {
    const {
        value,
        onChange,
        valueKey1,
        valueKey2,
        valueKey3,
        reset,
        placeholder1,
        placeholder2,
        placeholder3,
        area,
        required,
        addAnotherText,
        type,
        error,
        onBlur = () => {},
        className,
        maxLength,
        items,
        setItems,
    } = props;
    const [focus, setFocus] = useState(null);
    const { data: siteConfig } = useSiteConfigAuth();
    const { highlightColour2 } = getColours();
    const isTunecore = siteConfig?.display_name === "TuneCore";
    const { t } = useTranslation();

    const defaultStyle = {
        outline: "none",
        boxShadow: "none",
    };

    useEffect(() => {
        if (value && !items) {
            const itemArray = value.map((v) => ({ ...v, id: uuidv4() }));
            setItems(itemArray);
            console.log("Initial items set from value:", itemArray);
        } else if (!items) {
            const initialItem = [
                {
                    id: uuidv4(),
                    [valueKey1]: "",
                    [valueKey2]: "",
                    [valueKey3]: "",
                },
            ];
            setItems(initialItem);
            console.log("Initial items set as empty:", initialItem);
        }
    }, [value, items, setItems, valueKey1, valueKey2, valueKey3]);

    useEffect(() => {
        if (reset) {
            if (value) {
                const itemArray = value.map((v) => ({ ...v, id: uuidv4() }));
                setItems(itemArray);
                console.log("Items reset from value:", itemArray);
            } else {
                const initialItem = [
                    {
                        id: uuidv4(),
                        [valueKey1]: "",
                        [valueKey2]: "",
                        [valueKey3]: "",
                    },
                ];
                setItems(initialItem);
                console.log("Items reset as empty:", initialItem);
            }
        }
    }, [reset, value, setItems, valueKey1, valueKey2, valueKey3]);

    const updateOnChange = (newItems) => {
        setItems(newItems);

        // remove ids to return object to original state
        const copy = JSON.parse(JSON.stringify(newItems));
        copy.forEach((item) => {
            delete item.id;
        });
        onChange(copy);
    };

    const onChangeItem = ({ id, val, valueKey }) => {
        const item = items.find((i) => i.id === id);
        item[valueKey] = val;

        updateOnChange(items);
    };

    const remove = (id) => {
        const newItems = items.filter((q) => q.id !== id).slice();
        updateOnChange(newItems);
    };

    const addAnother = () => {
        const newItems = items.slice();

        newItems.push({
            id: uuidv4(),
            [valueKey1]: "",
            [valueKey2]: "",
            [valueKey3]: "",
        });
        updateOnChange(newItems);
    };

    return (
        <div className={`${className}`}>
            {items?.map((v, i) => (
                <React.Fragment key={`item${v.id}`}>
                    <div className='rounded-lg font-sentric-alt bg-transparent border font-semibold'>
                        <div>
                            <label
                                className={[
                                    `pointer-events-none flex text-sm items-center pb-0.5 ${
                                        isTunecore
                                            ? "text-white opacity-70"
                                            : "font-sentric-alt font-semibold text-sentric-rebrand-grey-text"
                                    }`,
                                ]}
                                htmlFor={`item${v.id}`}
                            >
                                {placeholder1}
                                {items.length > 1 && <> {i + 1}</>}
                            </label>
                            <div
                                id={`txt${area}${placeholder1}`}
                                className={`mb-2 relative bg-white block w-full rounded-md  border-0 ${
                                    required ? "required-star" : ""
                                }`}
                            >
                                <input
                                    className='rounded-lg border-0 focus:outline-none focus:ring-2 w-full text-sm font-semibold p-2 pl-3 pr-3'
                                    id={`item${v.id}`}
                                    name={`item${v.id}`}
                                    maxLength={maxLength}
                                    defaultValue={v[valueKey1]}
                                    type={type || "text"}
                                    onFocus={(e) => {
                                        e.target.style.boxShadow = `0 0 0 2px ${highlightColour2}`;
                                        setFocus(v.id);
                                    }}
                                    onBlur={(e) => {
                                        e.target.style.boxShadow = "none";
                                        setFocus(null);
                                        onBlur();
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.boxShadow = `0 0 0 2px ${highlightColour2}`)
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.boxShadow =
                                            focus === v.id ? `0 0 0 2px ${highlightColour2}` : "none")
                                    }
                                    onChange={(e) =>
                                        onChangeItem({
                                            id: v.id,
                                            val: e.target.value,
                                            valueKey: valueKey1,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                className={[
                                    `pointer-events-none flex text-sm items-center pb-0.5 ${
                                        isTunecore
                                            ? "text-white opacity-70"
                                            : "font-sentric-alt font-semibold text-sentric-rebrand-grey-text"
                                    }`,
                                ]}
                                htmlFor={`item${v.id}`}
                            >
                                {placeholder2}
                                {items.length > 1 && <> {i + 1}</>}
                            </label>
                            <div
                                id={`txt${area}${placeholder2}`}
                                className={`mb-2 relative bg-white block w-full rounded-md  border-0 ${
                                    required ? "required-star" : ""
                                }`}
                            >
                                <input
                                    className={[
                                        "rounded-lg border-0 focus:outline-none focus:ring-2 w-full text-sm font-semibold p-2 pl-3 pr-3",
                                        v[valueKey2] ? "" : "",
                                    ].join(" ")}
                                    id={`item2${v.id}`}
                                    name={`item2${v.id}`}
                                    maxLength={maxLength}
                                    defaultValue={v[valueKey2]}
                                    type={type || "text"}
                                    onFocus={(e) => {
                                        e.target.style.boxShadow = `0 0 0 2px ${highlightColour2}`;
                                        setFocus(v.id);
                                    }}
                                    onBlur={(e) => {
                                        e.target.style.boxShadow = "none";
                                        setFocus(null);
                                        onBlur();
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.boxShadow = `0 0 0 2px ${highlightColour2}`)
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.boxShadow =
                                            focus === v.id ? `0 0 0 2px ${highlightColour2}` : "none")
                                    }
                                    onChange={(e) =>
                                        onChangeItem({
                                            id: v.id,
                                            val: e.target.value,
                                            valueKey: valueKey2,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                className={[
                                    `pointer-events-none flex text-sm items-center pb-0.5 ${
                                        isTunecore
                                            ? "text-white opacity-70"
                                            : "font-sentric-alt font-semibold text-sentric-rebrand-grey-text"
                                    }`,
                                ]}
                                htmlFor={`item${v.id}`}
                            >
                                {placeholder3}
                                {items.length > 1 && <> {i + 1}</>}
                            </label>
                            <div
                                id={`txt${area}${placeholder3}`}
                                className={`mb-2 relative bg-white block w-full rounded-md border-0 ${
                                    required ? "required-star" : ""
                                }`}
                            >
                                <input
                                    className={[
                                        "rounded-lg border-0 focus:outline-none focus:ring-2 w-full text-sm font-semibold p-2 pl-3 pr-3",
                                        v[valueKey3] ? "" : "",
                                    ].join(" ")}
                                    id={`item${v.id}`}
                                    name={`item${v.id}`}
                                    maxLength={maxLength}
                                    defaultValue={v[valueKey3]}
                                    type={type || "text"}
                                    onFocus={(e) => {
                                        e.target.style.boxShadow = `0 0 0 2px ${highlightColour2}`;
                                        setFocus(v.id);
                                    }}
                                    onBlur={(e) => {
                                        e.target.style.boxShadow = "none";
                                        setFocus(null);
                                        onBlur();
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.boxShadow = `0 0 0 2px ${highlightColour2}`)
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.boxShadow =
                                            focus === v.id ? `0 0 0 2px ${highlightColour2}` : "none")
                                    }
                                    onChange={(e) =>
                                        onChangeItem({
                                            id: v.id,
                                            val: e.target.value,
                                            valueKey: valueKey3,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {items.length > 1 && (
                        <div className='flex justify-end text-sm'>
                            <button
                                className='items-center h-4 underline text-black transition-all duration-150 ease-linear hover:scale-105'
                                type='button'
                                onClick={() => remove(v.id)}
                            >
                                <span className='ml-2'>{t("common:remove")}</span>
                            </button>
                        </div>
                    )}
                    <Spacer thin />
                </React.Fragment>
            ))}
            {!!error && (
                <p className='inline-block w-4/5 p-1 pl-3 pr-3 mt-2 text-sm text-white bg-red-700 border border-white rounded-lg'>
                    {error}
                </p>
            )}

            <div className='flex items-center justify-end w-full h-4 mt-4'>
                <RebrandButtonLink
                    plusIcon
                    onClick={addAnother}
                    id={`btn${area}AddAnother`}
                >
                    {addAnotherText}
                </RebrandButtonLink>
            </div>
        </div>
    );
}

export default DoubleMultipleInput;