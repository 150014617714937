/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
	attachAmendmentOnlyMp3ByPreSignedUrlPost,
	attachMp3ByPresignedUrlPost,
} from "../../../../api/mutations";
import {
	getMyRecordingsDetailed,
	getMyRecording,
	getMyRecordingAmendment,
	generateRecordingAttachmentPresignedUrl,
} from "../../../../api/queries";
import FormError from "../../../../components/formError";
import H4 from "../../../../components/h4";
import Input from "../../../../components/input";
import Spacer from "../../../../components/spacer";
import LoadingSmall from "../../../../components/loadingSmall";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function RecordingAttachmentForm({
	artistaccountcode = null,
	recordingamendmentidentifier = null,
	recordingidentifier = null,
	workcode,
	closeModal,
}) {
	let isSuccess = false;
	let data;
	let recording = null;
	let recordingamendment = null;
	let identifier = null;
	let filename = null;
	let recordingtype = null;
	let putUrl = null;

	const RecordingTypeEnum = {
		RECORDING: 0,
		AMENDMENT: 1,
	};

	if (recordingamendmentidentifier) {
		identifier = recordingamendmentidentifier;
		recordingamendment = getMyRecordingAmendment({
			id: identifier,
			aac: artistaccountcode,
			wc: workcode,
		});
		isSuccess = recordingamendment.isSuccess;
		data = recordingamendment.data;
		recordingtype = RecordingTypeEnum.AMENDMENT;
	} else {
		identifier = recordingidentifier;
		recording = getMyRecording({
			id: identifier,
			aac: artistaccountcode,
			wc: workcode,
		});
		isSuccess = recording.isSuccess;
		data = recording.data;
		recordingtype = RecordingTypeEnum.RECORDING;
	}

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

	const [mp3, setMp3] = useState();
	const [mp3Value, setMp3Value] = useState();

	const attachAmendmentOnlyPreSignedMutation =
		attachAmendmentOnlyMp3ByPreSignedUrlPost();
	const attachPreSignedMutation = attachMp3ByPresignedUrlPost();
	const recordings = getMyRecordingsDetailed({
		aac: artistaccountcode,
		wc: workcode,
	});
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	if (mp3Value != null) {
		filename = mp3Value.replace(/C:\\fakepath\\/, "");
	}

	const preSignedUrl = generateRecordingAttachmentPresignedUrl({
		id: identifier,
		aac: artistaccountcode,
		wc: workcode,
		fn: filename,
		rt: recordingtype,
		ct: "audio/mpeg",
	});

	const [pending, setPending] = useState(false);
	const { t } = useTranslation("songs");

	useEffect(() => {
		if (artistaccountcode) {
			if (recordingamendment != null) {
				recordingamendment.refetch();
			}
			if (recording != null) {
				recording.refetch();
			}
		}
	}, [artistaccountcode]);

	if (recordingamendmentidentifier) {
		if (recordingamendment.isError || recordingamendment.data === null) {
			closeModal();
		}
	}

	if (recordingidentifier) {
		if (recording.isError || recording.data === null) {
			closeModal();
		}
	}

	useEffect(() => {
		preSignedUrl.refetch();
	}, [mp3Value]);

	function uploadComplete() {
		if (recordingamendment) {
			attachAmendmentOnlyPreSignedMutation.reset();
			const wc = workcode;
			const aac = artistaccountcode;
			const payload = {
				mimetype: mp3.type,
				originalfilename: filename,
				artistaccountcode: aac,
				workcode: wc,
				recordingamendmentidentifier:
					recordingamendment.data.recordingamendmentidentifier,
				presignedurl: putUrl,
				etag: recordingamendment.data.etag,
			};

			attachAmendmentOnlyPreSignedMutation.mutate(payload, {
				onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
					recordings.refetch();

					toast.success(`Successfully attached mp3: ${data.title}`, {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});

					setPending(false);
					closeModal();
				}
				},
				onError: (error) => {
          setMutationErrorMessage(error.message);
				},
			});
		} else {
			attachPreSignedMutation.reset();
			const wc = workcode;
			const aac = artistaccountcode;
			const payload = {
				mimetype: mp3.type,
				originalfilename: filename,
				artistaccountcode: aac,
				workcode: wc,
				recordingidentifier: recording.data.recordingidentifier,
				presignedurl: putUrl,
				etag: recording.data.etag,
			};

			attachPreSignedMutation.mutate(payload, {
				onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
					recordings.refetch();

					toast.success(`Successfully attached mp3: ${data.title}`, {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});

					closeModal();
				}
				},
				onError: (error) => {
          setMutationErrorMessage(error.message);
				},
			});
		}
	}

	function uploadFailed() {
		setValidationErrorMessage("There was an error attempting to upload the file.");
	}

	function uploadCanceled() {
		setValidationErrorMessage(
			"The upload has been canceled by the user or the browser dropped the connection."
		);
	}

	const uploadMP3 = async () => {
    setValidationErrorMessage('');
		try {
			setPending(true);
			if (!putUrl) {
				const response = await preSignedUrl.refetch();
				if (response.data && response.data.url) {
					putUrl = response.data.url;
				} else {
					console.error("putUrl is undefined");
					return;
				}
			}

			const xmlHttpRequest = new XMLHttpRequest();
			xmlHttpRequest.addEventListener("load", uploadComplete, false);
			xmlHttpRequest.addEventListener("error", uploadFailed, false);
			xmlHttpRequest.addEventListener("abort", uploadCanceled, false);
			xmlHttpRequest.open("PUT", putUrl, true);
			xmlHttpRequest.setRequestHeader("Content-Type", mp3.type);
			xmlHttpRequest.onload = () => {
				if (xmlHttpRequest.status === 200) {
					console.log("Uploaded data successfully");
				}
			};
			xmlHttpRequest.send(mp3);
		} catch (error) {
			console.error("Error during file upload:", error);
			setValidationErrorMessage("Error during file upload:", error);
			setPending(false);
		}
	};

	if (isSuccess && data) {
		return (
			<>
				<div>
					<h2
						className={`${
							isTunecore ? "text-white" : "text-black"
						} font-semibold text-xl md:text-3xl mr-10`}
					>
						{t("songs:attach-mp3-header")}
					</h2>
					<Spacer thin />
					<div className='w-full'>
						<div className={`${isTunecore ? "text-white" : "text-black"}`}>
							<H4>{data.title}</H4>
							<Spacer />
							{/* 'choose file' and 'no file chosen' placeholders are translated by user's OS, future workaround for custom translated label needed - Matt  */}
							<Input
								type='file'
								placeholder={t("songs:add-mp3-subheader")}
								value={mp3Value}
								onBlur={() => {}}
								onChange={(e) => {
									setMp3(e.target.files[0]);
									setMp3Value(e.target.value);
								}}
								accept='.mp3'
							/>
						</div>
						<Spacer />
						<FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
						<div className='flex flex-col items-center justify-between gap-3 sm:flex-row'>
							<RebrandPrimaryButton
								id='btnRecordingFormAddRecording'
								onClick={uploadMP3}
								pending={pending}
								arrow={!isTunecore}
							>
								{t("songs:attach-mp3")}
							</RebrandPrimaryButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	return <LoadingSmall />;
}
export default RecordingAttachmentForm;
