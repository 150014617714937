import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import P from "../../../components/p";
import Radio from "../../../components/radio";
import Spacer from "../../../components/spacer";
import bandsInTownIcon from "../../../images/bandsintown.png";
import songkickIcon from "../../../images/songkick.png";
import Input from "../../../components/input";
import { getUserMeta } from "../../../api/queries";
import AppState from "../../../store/appstate";
import useDomainConfigAuth from "../../../api/sanity/queries/getSiteConfigWithDomain";

function OnboardingGigs({ setIsLocked }) {
  const { t } = useTranslation("onboarding");
  const history = useHistory();
  const userMeta = getUserMeta();
  const addAccount = AppState.useState((s) => s.addAccount);
  const [showModal, setShowModal] = useState(addAccount.live);
  const [songKickName, setSongKickName] = useState();
  const [bandsInTown, setBandsInTown] = useState();
  const { data: domainConfig } = useDomainConfigAuth();
  const isTunecore = domainConfig?.display_name === 'TuneCore';
  if (domainConfig.hide_gig_integrations) {
    history.push("/add-account-6");
  }

  const handleRadioChange = (value) => {
    setShowModal(value);
    AppState.update((s) => {
      s.addAccount.live = value;
    });
  };
  let desc = t("onboarding:perform-live-desc1");
  switch (userMeta?.data?.role) {
    case "Label":
    case "Manager":
      title = t("onboarding:perform-live-header2");
      desc = t("onboarding:perform-live-desc2");
      break;
    default:
      break;
  }

  useEffect(() => {
    setIsLocked(addAccount.live === null);
  }, [addAccount, setIsLocked]);

  const handleSongKickChange = (e) => {
    const { value } = e.target;
    setSongKickName(value);
    AppState.update((s) => {
      s.addAccount.songkick = value;
    });
  };
  const handleBandsInTownChange = (e) => {
    const { value } = e.target;
    setBandsInTown(value);
    AppState.update((s) => {
      s.addAccount.bandsintown = value;
    });
  };

    useEffect(() => {
      if (addAccount.live === false) {

        AppState.update((s) => {
          s.addAccount.songkick = null;
          s.addAccount.bandsintown = null;
        });
          
      } else {
        AppState.update((s) => {
          s.addAccount.songkick = songKickName;
          s.addAccount.bandsintown = bandsInTown;
        });

      }
    }, [addAccount.live]);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center p-4 text-center text-white">
        <Spacer thin />
        <P>{desc}</P>
        <Spacer />
        <Radio
          name="live"
          placeholder=""
          value={addAccount.live}
          onChange={(e) => {
            const value = e.target.value === "true";
            AppState.update((s) => {
              s.addAccount.live = value;
            });
            handleRadioChange(value);
            console.log(showModal);
          }}
          options={[
            { label: t("common:yes"), value: true },
            { label: t("common:no"), value: false },
          ]}
        />
        <Spacer />

        <div
          className={`flex justify-center items-center ${
            showModal ? "" : "opacity-10 pointer-events-none"
          } `}
        >
          <div className="flex flex-col items-center p-4 px-2 md:px-6 text-white rounded-lg shadow-lg">
            <p className="mb-2 text-4xl">
              <img width="50" src={bandsInTownIcon} alt="bandsintown logo" />
            </p>
            <p>{t("onboarding:_bandsintown")}</p>

            <Input
              placeholder="Enter your account name"
                className={`${!isTunecore ? 'auth-page-input' : ''}`}
              value={addAccount.songkick}
              whiteLabelText
              onChange={handleSongKickChange}
            />
          </div>

          <div className="flex flex-col items-center p-4 px-2 md:px-6 text-white rounded-lg shadow-lg">
            <p className="mb-2 text-4xl">
              <img width="50" src={songkickIcon} alt="songkick logo" />
            </p>
            <p>{t("onboarding:_songkick")}</p>

            <Input
              placeholder="Enter your account name"
                className={`${!isTunecore ? 'auth-page-input' : ''}`}
              value={addAccount.bandsintown}
              whiteLabelText
            //   onBlur={(e) => checkValid(e.target.value)}
              onChange={handleBandsInTownChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingGigs;
