import { useEffect } from "react";
import { RxAvatar } from "react-icons/rx";
import { getArtistMeta } from "../api/queries";
import AppState from "../store/appstate";

function AccountAvatar() {
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const artistMeta = getArtistMeta({ aac: selectedAccount });
	useEffect(() => {
		if (selectedAccount) {
			artistMeta.refetch();
		}
	}, [selectedAccount]);

	let imageUrl = null;

	if (artistMeta.isSuccess && artistMeta.data) {
		if (artistMeta.data.spotify_obj) {
			const obj = JSON.parse(artistMeta.data.spotify_obj);

			imageUrl = obj.images && obj.images[0] ? obj.images[0].url : null;
		}

		// if (!imageUrl && artistMeta.data.twitter_obj) {
		//   const tobj = JSON.parse(artistMeta.data.twitter_obj);

		//   imageUrl = tobj.data.profile_image_url;
		// }

		if (!imageUrl && artistMeta.data.bandsintown_obj) {
			const bobj = JSON.parse(artistMeta.data.bandsintown_obj);

			imageUrl = bobj.thumb_url;
		}

		// if (!imageUrl && artistMeta.data.facebook_obj) {
		//   const fobj = JSON.parse(artistMeta.data.facebook_obj);

		//   imageUrl = fobj?.picture?.data?.url;
		// }
	}

	return imageUrl ? (
		<div
			style={{
				backgroundImage: `url(${imageUrl})`,
				boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.3)",
			}}
			className='w-12 h-12 rounded-full bg-center bg-cover group-hover:grayscale'
			alt='profile avatar'
		/>
	) : (
		<div
			style={{
				boxShadow: "inset 0 0 2px rgba(0, 0, 0, 0.3)",
			}}
			className='w-12 h-12 flex justify-center rounded-full bg-gray-100 text-black'
		>
			<RxAvatar className='self-center' height={12} width={12} size={32} />
		</div>
	);
}

export default AccountAvatar;
