/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import Spacer from '../../../../components/spacer';
import { noDp } from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const TabIncome = ({ result }) => {
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const { t } = useTranslation('other');
  let types = [];
  if (result?.body?.aggregations?.IncomeTypes) {
    types = result?.body?.aggregations?.IncomeTypes.buckets.slice(0, 2);
  }
  let usage = [];
  if (result?.body?.aggregations?.IncomeTypes) {
    usage = result?.body?.aggregations?.UsageTypes.buckets.slice(0, 2);
  }
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  return (
    <div className="p-4  h-full flex items-start flex-col">
      <h1 className={`text-md xl:text-xl font-medium ${isTunecore ? 'text-white' : 'text-black'} lg:mb-4`}>{t('other:income-tab-header')}</h1>
      <h2 className={`text-xxs font-semibold ${isTunecore ? 'text-white' : 'text-sentric-rebrand-grey-text'}`}>{t('other:top-income-types')}</h2>

      {types.map((s, i) => (
        <div className={`h-14 gap-1 ${i < (types.length - 1) ? 'border-b' : ''} flex w-full items-center justify-between font-sentric-alt`} key={`income${i}`}>
          <div className=" text-xs font-semibold flex flex-row">
            <span className="text-xs bg-sentric-rebrand-grey-text text-white flex-none rounded-full w-4 h-4 mr-2">{(i + 1)}</span>
            <span className="text-left line-clamp-2 break-word">
            {s.key}
            </span>
          </div>
          <div className={`${isTunecore ? 'text-white' : 'text-black'}  font-medium text-xs`}>
            {currencySymbol}
            {noDp(s.WriterValues.value)}
          </div>
        </div>
      ))}
      <Spacer thin />
      <h2 className={`text-xxs font-semibold ${isTunecore ? 'text-white' : 'text-sentric-rebrand-grey-text'}`}>{t('other:top-usage-types')}</h2>
      {usage.map((s, i) => (
        <div className={`h-14 gap-1 ${i < (usage.length - 1) ? 'border-b' : ''} flex w-full items-center justify-between font-sentric-alt`} key={`usage${i}`}>
          <div className=" text-xs font-semibold flex flex-row">
            <span className="text-xs bg-sentric-rebrand-grey-text text-white flex-none rounded-full w-4 h-4 mr-2">{(i + 1)}</span>
            <span className="text-left line-clamp-2 break-word">
            {s.key}
            </span>
          </div>
          <div className={`${isTunecore ? 'text-white' : 'text-black'}  font-medium text-xs`}>
            {currencySymbol}
            {noDp(s.WriterValues.value)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TabIncome;
