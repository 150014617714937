import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parse } from 'qs';
import Input from '../../components/input';
import P from '../../components/p';
import Spacer from '../../components/spacer';
import { resetPasswordConfirm } from '../../api/mutations';
import FormError from '../../components/formError';
import AppState from '../../store/appstate';
import AuthPrimaryButton from '../../components/authPrimaryButton';

function ResetPasswordConfirm() {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [valid, setValid] = useState(false);
  const [pending, setPending] = useState(false);
  const passwordRegex = new RegExp('^(?=.{8,100})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!£*]).*$');

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const history = useHistory();
  const location = useLocation();
  const resetConfirmMutation = resetPasswordConfirm();

  const links = (parse(location.search.slice(1)));

  const confirm = async () => {
    setValid(false);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
    setPending(true);
    const data = {
      linktoken: links.lt,
      linkcontext: links.lc,
      newpassword: password,
      confirmPassword,
    };

    resetConfirmMutation.reset();

    resetConfirmMutation.mutate(data, {
      onSuccess: (payload, response) => {
        if (response.failedvalidation) {
          setValidationErrorMessage(response.data.messages);
          setPending(false);
        } else {
        setPending(false);
        const details = {
          renewal_token: btoa(password),
          userName: payload.username,
          access_token: '123',
        };
        AppState.update((s) => { s.authentication = details; });
        history.push('/reset-password-success');
      }
      },
      onError: (error) => {
        setValid(true);
        setMutationErrorMessage(error.message);
        setPending(false);
      },

    });
  };

  const checkValid = (passed = {}) => {
    let val = true;

    // password check

    const pass = passed.password || password;
    const pass2 = passed.confirmPassword || confirmPassword;

    if (pass !== pass2) {
      val = false;
    }

    // password check
    const validPassword = pass.length > 6 && pass.length < 100;

    if (!validPassword) {
      val = false;
    }

    // pass regex check
    if (!passwordRegex.test(pass)) {
      val = false;
    }

    // password check
    const validPassword2 = pass2.length > 6 && pass2.length < 100;

    if (!validPassword2) {
      val = false;
    }

    // pass regex check
    if (!passwordRegex.test(pass2)) {
      val = false;
    }

    setValid(val);
  };

  const checkErrors = () => {
    const errs = {};

    // last name check
    if (password.trim().length > 6) {
      const validPassword = password.trim().length > 6;
      if (!validPassword) {
        errs.password = t('common:longer-password-error');
      }
    }

    if (confirmPassword.trim().length > 6) {
      const validPassword = confirmPassword.trim().length > 6;
      if (!validPassword) {
        errs.confirmPassword = t('common:longer-password-error');
      }
    }

    // pass regex check
    if (!passwordRegex.test(password)) {
      errs.password = t('regex:password-regex-error');
    }

    // pass regex check
    if (!passwordRegex.test(confirmPassword)) {
      errs.confirmPassword = t('regex:password-regex-error');
    }

    if (confirmPassword !== password) {
      errs.confirmPassword = t('regex:non-matching-password-error');
    }

    setErrors(errs);
  };

  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center p-4 text-center min-h-screen-minus-footer">
          <h1 className="text-6xl">{t('auth:new-password-header')}</h1>
          <Spacer />
          <P>{t('auth:new-password-text')}</P>
          <Spacer />
          <div className="w-full lg:w-1/3 text-old-sentric-dark-gray">
            <Input
              error={errors.password}
              className='auth-page-input'
              type="password"
              placeholder={t('auth:password-placeholder')}
              onBlur={() => checkErrors()}
              value={password}
              whiteLabelText
              onChange={(e) => { setPassword(e.target.value); checkValid({ password: e.target.value }); }}
            />
            <Spacer />
            <Input
              error={errors.confirmPassword}
              className='auth-page-input'
              whiteLabelText
              type="password"
              placeholder={t('auth:confirm-password-placeholder')}
              onBlur={() => checkErrors()}
              value={confirmPassword}
              onChange={(e) => { setConfirmPassword(e.target.value); checkValid({ confirmPassword: e.target.value }); }}
            />
            <Spacer />
            <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
            <AuthPrimaryButton
            disabled={!valid}
            pending={pending}
            onClick={confirm}
            >
            {t('common:next-button')}
            </AuthPrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordConfirm;
