import { useTranslation } from 'react-i18next';
import P from '../../components/p';
import Spacer from '../../components/spacer';

function ResetPasswordMessage() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center min-h-screen-minus-footer p-4 text-center">
          <h1 className="text-6xl">{t('auth:reset-thank-you')}</h1>
          <div className="w-full lg:w-2/3 text-white">
            <Spacer />
            <P>{t('auth:reset-email-sent')}</P>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordMessage;
