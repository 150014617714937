/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  FaCheck,
  FaChevronDown, FaChevronUp
} from 'react-icons/fa';
import moment from 'moment';
import { getSocieties } from '../../../../api/queries';
import Table from '../../../../components/table';
import WriterDetails from './WriterDetails';
import WriterAmendmentsPill from '../../../../components/writerAmendmentsPill';
import { PlusIconPlus, PlusIconMinus } from '../../../../components/svg/PlusIcon';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

// Our table component
function WritersTable({
  data, highlightColour
}) {
  const { t } = useTranslation('writers');
  const societies = getSocieties();
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  
  
  const columns = React.useMemo(() => {
    
    const baseColumns = [
      {
        Header: t('writers:writer-name-col'),
        accessor: ({ writername }) => writername,
        width: '20%',
        id: 'colWriterTableName',
      },
      {
        Header: t('writers:pro-membership-col'),
        accessor: ({ societycisaccode }) => {
          if (!societycisaccode) {
            return null;
          }
          if (societies.data) {
            const pro = societies.data.societies.find((s) => s.cisaccode === societycisaccode);
            return pro?.name;
          }
          return null;
        },
        width: '20%',
        id: 'colWriterTablePRO',
      },
      {
        Header: 'CAE/IPI#',
        accessor: ({ caeipinumber }) => caeipinumber,
        width: '15%',
        id: 'colWriterTableCAE',
      },
      {
        Header: t('writers:writer-amendment-status'),
        accessor: ({ verifiedstate }) => verifiedstate,
        width: '20%',
        id: 'colVerifiedState',
        Cell: ({ row }) => (
          <div>
            <WriterAmendmentsPill object={row.original} />
          </div>
        ),
      },
      {
        Header: () => null,
        id: 'expander',
        width: '5%',
        Cell: ({ row }) => (
          <>
            {(isTunecore) ? (
              <span className="float-right">{row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}</span>
            ) : (
              <span className="float-right">
              {row.isExpanded ? (
                <PlusIconMinus className='h-4' fill={highlightColour} />
              ) : (
                <PlusIconPlus className='h-4' fill={highlightColour} />
              )}
              </span>
            
            )}
          </>
        ),
        SubCell: () => null,
      },
    ];
  
    if (isTunecore) {
      baseColumns.splice(1, 0, {
        Header: t('writers:dob'),
        accessor: ({ dateofbirth }) => {
          const dateString = dateofbirth;
          const formattedDate = moment.utc(dateString).format('YYYY-MM-DD');
          if (!dateString) {
            return null;
          }
          return formattedDate;
        },
        width: '15%',
        id: 'colWriterTableDOB',
      });
    }
  
    return baseColumns;
  }, [societies.isSuccess, isTunecore]);

  const generateWriterName = (person) => {
    let fullName = `${person.firstname} ${person.lastname}`;

    if (person?.middlename) {
      fullName = `${person.firstname} ${person.middlename} ${person.lastname}`;
    }

    return fullName;
  };

  const addedWriterNameField = data.map((person) => ({ ...person, writername: generateWriterName(person) }));

  return (
    <>
      <Table
        area="WritersTable"
        data={addedWriterNameField}
        columns={columns}
        highlightColour={highlightColour}
        revealComponent={
          <WriterDetails 
              highlightColour={highlightColour}
          />
        }
        searchSuffix={t('common:writers')}
      />
    </>

  );
}

export default WritersTable;
