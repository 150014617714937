/* API calls via react-query */

import { useQuery } from '@tanstack/react-query';
import { apiCall } from './shared';

export const getOpportunities = () => useQuery(['opportunities'], () => apiCall({ endpoint: '/cms/news' }), {
  staleTime: 6000,
});

export const getSyncs = () => useQuery(['syncs'], () => apiCall({ endpoint: '/cms/syncs' }), {
  staleTime: 6000,
});

export const getBroadcasts = ({ ac, oc }) => useQuery(
  [['broadcasts'], ac, oc],
  () => apiCall({ endpoint: `/broadcasts?ac=${ac}&oc=${oc}` }),
  { manual: true, enabled: !!ac },
);

export const getBroadcast = ({ ac, id }) => useQuery(
  ['broadcast', ac, id],
  () => apiCall({ endpoint: `/broadcast?ac=${ac}&id=${id}` }),
  { staleTime: 6000, enabled: !!ac && !!id },
);

export const getSetlists = ({ ac, oc }) => useQuery(
  ['setlists', ac, oc],
  () => apiCall({ endpoint: `/setlists?ac=${ac}&oc=${oc}` }),
  { enabled: !!ac, manual: true },
);

export const getGigClaims = ({
  ac, oc, performanceDate, venueFilter,
}) => useQuery(
  ['gigclaims', ac, oc, performanceDate, venueFilter],
  () => apiCall({
    endpoint: `/gig-claims?ac=${ac}&oc=${oc}&performanceDate=${performanceDate}&venueFilter=${venueFilter}`,
  }),
  { manual: true, enabled: !!ac },
);

export const getSetlist = ({ ac, id }) => useQuery(
  ['setlist', ac, id],
  () => apiCall({ endpoint: `/setlist?ac=${ac}&id=${id}` }),
  { staleTime: 6000, enabled: !!ac && !!id },
);

export const getGigClaim = ({ ac, id }) => useQuery(
  ['gigclaim', ac, id],
  () => apiCall({ endpoint: `/gig-getclaim?ac=${ac}&id=${id}` }),
  { manual: true, enabled: !!ac && !!id },
);

export const getTheme = ({ cataloguecode }) => useQuery(
  ['theme', cataloguecode],
  () => apiCall({ endpoint: `/cms/theme/${cataloguecode}` }),
  { staleTime: 1000 * 60 * 60, enabled: !!cataloguecode },
);

export const getThemeUnAuth = () => useQuery(['themeUnAuth'], () => apiCall({ endpoint: '/cms/theme' }), {
  staleTime: 1000 * 60 * 60,
});

export const getMyAccounts = () => useQuery(
  ['getmyaccounts'],
  async () => {
    const results = await apiCall({
      endpoint: '/rightsapp/get',
      path: '/api/v1/artistaccount/getmyaccounts?pageSize=10000',
    });
    return results;
  },
  { staleTime: 1000 * 60 * 60, retry: 1 },
);

export const getMyAccount = ({ artistaccountcode }) => useQuery(
  ['getmyaccount', artistaccountcode],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: `/api/v1/artistaccount/getmyaccount?artistaccountcode=${artistaccountcode}`,
  }),
  { staleTime: 1000 * 60 * 60, enabled: artistaccountcode !== null },
);

export const getContracts = ({ artistaccountcode }) => useQuery(
  ['getcontracts', artistaccountcode],
  () => apiCall({ endpoint: `/contracts/account/${artistaccountcode}` }),
  { staleTime: 1000 * 60 * 60, enabled: artistaccountcode !== null },
);

export const getMyProfile = () => useQuery(
  ['getmyprofile'],
  () => apiCall({ endpoint: '/rightsapp/get', path: '/api/v1/user/getprofile' }),
  { staleTime: 1000 * 60 * 60 },
);

export const getUserMeta = () => useQuery(['getUserMeta'], () => apiCall({ endpoint: '/usermeta' }), {
  staleTime: 1000 * 60 * 60,
  retry: 1,
});

export const getArtistMeta = ({ aac }) => useQuery(
  ['getArtistMeta', aac],
  () => apiCall({ endpoint: `/artist-meta?id=${aac}` }),
  { staleTime: 1000 * 60 * 60, retry: 1, enabled: !!aac },
);

export const getArtistISRCS = ({ aac }) => useQuery(
  ['getartistisrcs', aac],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: `/api/v1/artistaccount/getmyaccountrecordingisrclist?artistaccountcode=${aac}`,
  }),
  { staleTime: 1000 * 60 * 60, enabled: aac !== null },
);

export const getSpotifySuggestions = ({ spotifyid, accountId }) => useQuery(
  ['getSpotifySuggestions', spotifyid, accountId],
  () => apiCall({
    endpoint: `/spotify-suggestions?spotifyid=${spotifyid}&accountId=${accountId}`,
  }),
  { enabled: !!spotifyid },
);

export const getSuggestions = ({ spotifyid, accountId }) => useQuery(
  ['getSuggestions', spotifyid, accountId],
  () => apiCall({
    endpoint: `/suggestions?spotifyid=${spotifyid}&accountId=${accountId}`,
  }),
  { enabled: !!accountId },
);

export const getMyCatalogues = () => useQuery(
  ['getmycatalogues'],
  () => apiCall({ endpoint: '/rightsapp/get', path: '/api/v1/catalogue/getmycatalogues' }),
  { staleTime: 1000 * 60 * 60 },
);

export const getSocieties = () => useQuery(
  ['getSocieties'],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: '/api/v1/society/getsocieties?1000',
  }),
  { staleTime: 1000 * 60 * 60 },
);

export const getMyWriters = ({ aac }) => useQuery(
  ['getWriters', aac],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: `/api/v1/writer/getmywritersdetailed?pageSize=200&artistaccountcode=${aac}`,
  }),
  { staleTime: 1000 * 60, enabled: !!aac },
);

export const getMyWriter = ({ aac, wc }) => useQuery(
  ['getWriter', aac, wc],
  async () => {
    const res = await apiCall({
      endpoint: '/rightsapp/get',
      path: `/api/v1/writer/getmywriter?accountcode=${aac}&writercode=${wc}`,
    });

    if (res.changespending) {
      const wa = await apiCall({
        endpoint: '/rightsapp/get',
        path: `/api/v1/writer/getmywriteramendment?accountcode=${aac}&writercode=${wc}`,
      });
      if (wa) {
        wa.etag = res.etag;
        return wa
      }
    }

    return res;
  },
  { staleTime: 0, enabled: !!aac && !!wc },
);

export const getMySongs = ({ aac }) => useQuery(
  ['getMySongs', aac],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: `/api/v1/work/getmyworks?pageSize=300&artistaccountcode=${aac}&sortby=title`,
  }),
  { staleTime: 1000 * 60, enabled: !!aac, retry: 1 },
);

export const getMySongsDetailed = ({ aac, includeAmendments,
  includePerformers,
  includeRecordings,
  includeWorkSplits,
  includeAlternativeTitles }) => useQuery(
  ['getMySongsDetailed', aac,includeAmendments,  includePerformers,  includeRecordings,  includeWorkSplits,  includeAlternativeTitles ],
  async () => {
    let page = 1;
    const pageSize = 500;
    let path = `/api/v1/work/getmyworksdetailed?pageSize=${pageSize}&artistaccountcode=${aac}&page=${page}`;
    if(includePerformers) {
      path += `&includePerformers=true`
    }
    if(includeRecordings) {
      path += `&includeRecordings=true`
    }
    if(includeWorkSplits) {
      path += `&includeWorkSplits=true`
    }
    if(includeAmendments) {
      path += `&includeAmendments=true`
    }
    if(includeAlternativeTitles) {
      path += `&includeAlternativeTitles=true`
    }
    const result = await apiCall({
      endpoint: '/rightsapp/get-no-auto-pagination',
      path,
    });
    while (result.total > page * pageSize) {
      page += 1;
      // eslint-disable-next-line no-await-in-loop
      const resultNew = await apiCall({
        endpoint: '/rightsapp/get-no-auto-pagination',
        path,
      });
      result.works = [...result.works, ...resultNew.works];
    }

    return result;
  },
  { staleTime: 5000 * 60, enabled: !!aac, retry: false },
);

export const getMySong = ({ aac, wc }) => useQuery(
  ['getSong', aac, wc],
  async () => {
    const res = await apiCall({
      endpoint: '/rightsapp/get',
      path: `/api/v1/work/getmywork?accountcode=${aac}&workcode=${wc}`,
    });

    if (res?.changespending) {
      const wa = await apiCall({
        endpoint: '/rightsapp/get',
        path: `/api/v1/work/getmyworkamendment?artistaccountcode=${aac}&workcode=${wc}`,
      });
      if (wa) {
        wa.verifiedstate = res.verifiedstate;
        wa.changespending = res.changespending;
        return wa;
      }
    }
    return res;
  },
  { staleTime: 0, enabled: !!aac && !!wc },
);

export const getMyWorkAutoSuggest = ({ aac, searchstring }) => useQuery(
  ['getMyAutoSuggestedWorks', aac, searchstring],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: `/api/v1/work/getmyautosuggestedworks?pageSize=300&artistaccountcode=${aac}&searchstring=${searchstring}`,
  }),
  { staleTime: 1000 * 60, enabled: !!aac && !!searchstring},
);

export const useTopSongs = ({ accounts }) => useQuery(
  ['getTopSongs', accounts],
  async () => {
    const promises = [];
    const t0 = performance.now();

    if (accounts) {
      const smallerGroup = JSON.parse(JSON.stringify(accounts)).slice(0, 100);

      smallerGroup.forEach((ac) => {
        promises.push(
          apiCall({
            endpoint: '/rightsapp/get-no-auto-pagination',
            path: `/api/v1/work/getmyworks?pageSize=10&page=1&artistaccountcode=${ac.artistaccountcode}&sortby=CreatedDate&sortAsc=false`,
          }).catch(() => console.log('error')),
        );
      });

      // &sortby=createddate&sortAsc=true
      const resultArray = [];

      const results = await Promise.allSettled(promises);
      results.forEach((result, i) => {
        const account = smallerGroup[i];

        if (result.status !== 'fulfilled' || !result?.value?.works) {
          return;
        }
        result.value.works.forEach((r) => {
          r.cataloguecode = account.cataloguecode;
          r.artistaccountcode = account.artistaccountcode;
          r.accountname = account.accountname;
        });
        resultArray.push(result.value);
      });

      const t2 = performance.now();
      console.log(`Call to doSomething took ${t2 - t0} milliseconds.`);

      const combinedResults = resultArray.reduce(
        (a, b) => a.concat(b && b.works ? b.works : []),
        [],
      );

      // order by latest
      combinedResults.sort((a, b) => {
        const c = new Date(a.createddate);
        const d = new Date(b.createddate);
        return d - c;
      });

      return combinedResults;
    }
    return null;
  },
  { staleTime: 1000 * 60 * 60, enabled: false },
);

export const getMySongsDetailedPaginated = ({
  aac,
  perPage,
  page,
  sortBy,
  desc,
  globalFilter,
}) => useQuery(
  [
    'getMySongsDetailedPaginated',
    aac,
    perPage,
    page,
    sortBy,
    desc,
    globalFilter,
  ],
  async () => {
    let sortByString = '';
    let sortAscString = '';
    let useSort = false;
    let filterString = '';
    if (sortBy) {
      sortByString = sortBy;
      sortAscString = desc ? 'false' : 'true';
      useSort = true;
    }

    if (sortByString === 'title') {
      sortByString = 'Title';
    }
    if (sortByString === 'createddate') {
      sortByString = 'CreatedDate';
    }
    if (sortByString === 'verifiedstate') {
      sortByString = 'VerifiedState';
    }

    if (globalFilter) {
      filterString = `&title=${globalFilter}&performingartistname=${globalFilter}`;
    }

    let result = null;
    if (useSort) {
      result = await apiCall({
        endpoint: '/rightsapp/get-no-auto-pagination',
        path: `/api/v1/work/getmyworksdetailed?pageSize=${perPage}&artistaccountcode=${aac}&page=${page}&sortBy=${sortByString}&sortAsc=${sortAscString}${filterString}`,
      });
    } else {
      result = await apiCall({
        endpoint: '/rightsapp/get-no-auto-pagination',
        path: `/api/v1/work/getmyworksdetailed?pageSize=${perPage}&artistaccountcode=${aac}&page=${page}${filterString}`,
      });
    }

    return result;
  },
  {
    keepPreviousData: true,
    staleTime: 5000 * 60,
    enabled: !!aac,
    retry: false,
  },
);

export const useTopWriters = ({ accounts }) => useQuery(
  ['getTopWriters', accounts],
  async () => {
    const promises = [];
    const t0 = performance.now();
    if (accounts) {
      const smallerGroup = JSON.parse(JSON.stringify(accounts)).slice(0, 100);

      smallerGroup.forEach((ac) => {
        promises.push(
          apiCall({
            endpoint: '/rightsapp/get-no-auto-pagination',
            path: `/api/v1/writer/getmywritersdetailed?pageSize=1&artistaccountcode=${ac.artistaccountcode}&sortby=CreatedDate&sortAsc=false`,
          }),
        );
      });
      const resultArray = [];
      const results = await Promise.allSettled(promises);

      results.forEach((result, i) => {
        const account = smallerGroup[i];
        if (result.status !== 'fulfilled' || !result?.value?.writers) {
          return;
        }
        result.value.writers.forEach((r) => {
          r.cataloguecode = account.cataloguecode;
          r.artistaccountcode = account.artistaccountcode;
          r.accountname = account.accountname;
        });
        resultArray.push(result.value);
      });

      const t2 = performance.now();
      console.log(`Call to doSomething took ${t2 - t0} milliseconds.`);

      const combinedResults = resultArray.reduce(
        (a, b) => a.concat(b && b.writers ? b.writers : []),
        [],
      );

      combinedResults.filter((r) => r.verifiedstate === 1);

      // order by latest
      combinedResults.sort((a, b) => {
        const c = new Date(a.verifieddate);
        const d = new Date(b.verifieddate);
        return d - c;
      });

      return combinedResults;
    }
    return null;
  },
  { staleTime: 1000 * 60 * 60, enabled: false },
);

export const useTopGigs = ({ accounts }) => useQuery(
  ['getTopGigsS', accounts],
  async () => {
    // const t0 = performance.now();
    if (accounts) {
      const smallerGroup = JSON.parse(JSON.stringify(accounts)).slice(0, 100);

      const results = await apiCall({
        endpoint: `/top-gigs?acs=${smallerGroup.map(
          (a) => a.artistaccountcode,
        )}`,
      });

      const res = JSON.parse(JSON.stringify(results));

      res.forEach((result) => {
        const account = smallerGroup.find(
          (s) => (s.artistaccountcode = result.account_code),
        );
        result.cataloguecode = account.cataloguecode;
        result.accountname = account.accountname;
      });

      return res;
    }
    return null;
  },
  { staleTime: 1000 * 60 * 60, enabled: false },
);

export const useSpotifyCount = ({ accounts }) => useQuery(
  ['getSpotifyCount', accounts],
  async () => {
    if (accounts) {
      const smallerGroup = JSON.parse(JSON.stringify(accounts)).slice(0, 100);

      const results = await apiCall({
        endpoint: `/top-spotify?acs=${smallerGroup.map(
          (a) => a.artistaccountcode,
        )}`,
      });

      return results;
    }
    return null;
  },
  { staleTime: 1000 * 60 * 60, enabled: false },
);

export const getMyRecordingsDetailed = ({ aac, wc }) => useQuery(
  ['getRecordingsDetails', aac, wc],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: `/api/v1/recording/getmyrecordingsdetailed?accountcode=${aac}&workcode=${wc}`,
  }),
  { staleTime: 1000 * 60, enabled: !!aac && !!wc },
);

export const getRecordingMetas = ({ id }) => useQuery(
  ['getRecordingMetas', id],
  () => apiCall({ endpoint: `/recording-metas?id=${id}` }),
  { staleTime: 1000 * 60, enabled: !!id },
);

export const getMp3 = ({ key }) => useQuery(['getMp3', key], () => apiCall({ endpoint: `/s3/put?key=${key}` }), {
  staleTime: 1000 * 60,
  enabled: !!key,
});

export const getMyStatements = ({ aac }) => useQuery(
  ['getMyStatements', aac],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: `/api/v1/statement/getmyaccountstatements?artistaccountcode=${aac}`,
  }),
  { staleTime: 1000 * 60 * 60, enabled: !!aac },
);

export const getMyStatementFiles = ({ aac, suid }) => useQuery(
  ['getMyStatementFile', aac, suid],
  () => apiCall({
    endpoint: '/rightsapp/get',
    path: `/api/v1/statement/getmyaccountstatementfiles?artistaccountcode=${aac}&statementuniqueidentifier=${suid}`,
  }),
  { staleTime: 1000 * 60 * 10, enabled: !!aac && !!suid },
);

export const getMyRecording = ({ id, aac, wc }) => useQuery(
  ['getRecording', id, aac, wc],
  async () => {
    const result = await apiCall({
      endpoint: '/rightsapp/get',
      path: `/api/v1/recording/getmyrecording?recordingidentifier=${id}&artistaccountcode=${aac}&workcode=${wc}`,
    });
    return result;
  },
  { staleTime: 1000 * 60, enabled: !!id && !!aac && !!wc },
);

export const getMyRecordingAmendment = ({ id, aac, wc }) => useQuery(
  ['getRecordingAmendment', id, aac, wc],
  async () => {
    const result = await apiCall({
      endpoint: '/rightsapp/get',
      path: `/api/v1/recording/getmyrecordingamendment?recordingamendmentidentifier=${id}&artistaccountcode=${aac}&workcode=${wc}`,
    });
    return result;
  },
  { staleTime: 1000 * 60, enabled: !!id && !!aac && !!wc },
);

export const generateRecordingAttachmentPresignedUrl = ({
  id,
  aac,
  wc,
  fn,
  rt,
  ct,
}) => useQuery(
  ['generateRecordingAttachmentPresignedUrl', id, aac, wc, fn, rt, ct],
  async () => {
    const result = await apiCall({
      endpoint: '/rightsapp/get',
      path: `/api/v1/recording/generaterecordingattachmentpresignedurl?identifier=${id}&artistaccountcode=${aac}&workcode=${wc}&filename=${fn}&recordingtype=${rt}&contenttype=${ct}`,
    });
    return result;
  },
  {
    staleTime: 1000 * 60,
    enabled: !!id && !!aac && !!wc && !!fn && !!rt && !!ct,
  },
);

export const getKycStatus = () => useQuery(
  ['getkyc'],
  async () => {
    const result = await apiCall({ endpoint: '/rightsapp/get', path: '/api/v1/user/getkyc' });
    return result;
  },
  // will run on evey page load so need to be careful where to use this
  { refetchOnMount: true },
);

export const getSpotifyArtistDetail = ({ spotifyId, apiToken }) => useQuery(
  ['getSpotifyArtistDetail', spotifyId, apiToken],
  async () => {
    console.log('fetching artist', spotifyId);
    const response = await fetch(`https://api.spotify.com/v1/artists/${spotifyId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch artist from Spotify. Status: ${response.status}`);
    }
    return response.json();
  },
  {
    staleTime: 1000 * 60,
    enabled: !!spotifyId && !!apiToken,
  }
);

