import { useQuery } from '@tanstack/react-query';
import client from '../client';
import AppState from '../../../store/appstate';

const getSyncs = async () => {
  const catalogueCode = AppState.getRawState().selectedCatalogue;
  const domain = window.location.origin;

  const allSyncsQuery = '*[_type == "syncs"]{_type, _id, name, description, "altText": image.altText,"imageUrl": image.asset->url, url}';

  const availableSyncsQuery = `*[_type == 'siteConfiguration' && ('${catalogueCode}' in catalogueConfig.catalogue_codes || '${domain}' in domainConfig.domain)][0]{'syncs_available': syncsAndOpportunities.syncsAvailable[]->{
    _type, _id, name, description, "altText": image.altText,"imageUrl": image.asset->url, url
    },
  }`

  try {
    const allSyncsAvailable = AppState.getRawState()?.allSyncsAvailable;
    const query = allSyncsAvailable ? allSyncsQuery : availableSyncsQuery;

    const syncsData = await client.fetch(query);
    return syncsData;
  } catch (error) {
    console.error('Error fetching Syncs data:', error);
    throw error;
  }
};

const useSyncsQuery = () => useQuery(['syncs'], getSyncs, {
    onError: (error) => {
      console.error('Error in useSyncsQuery:', error);
    },
    staleTime: 120000, // 2 minutes
  });

export default useSyncsQuery;
