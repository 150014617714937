/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useEffect } from "react";
import AppState from "../store/appstate";
import useSiteConfigUnAuth from "../api/sanity/queries/getSiteConfigWithDomain";
import Loading from "./loading";
import getColours from "../helpers/getColours";
// import LanguageSelector from '../components/languageSelector';
// import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function BasicLayout(props) {
	const { children } = props;
	const { data: domainConfig, isSuccess: domainConfigIsSuccess } =
		useSiteConfigUnAuth();
	const { onboardingDarkColour, grad1Colour, grad2Colour } = getColours();
	const clientDisplayName = domainConfig?.display_name;

	const isTunecore = domainConfig?.display_name === "TuneCore";

	AppState.update((s) => {
		s.hubspotDisabled = domainConfig?.disable_hubspot_tracking;
	});

	const basicLayoutClasses = clsx({
		"font-tunecore": isTunecore,
		"font-sentric": !isTunecore,
	});

	useEffect(() => {
		if (domainConfigIsSuccess) {
			document.title = `${domainConfig?.display_name} Portal`;
		}
	}, [domainConfigIsSuccess]);

	useEffect(() => {
		if (clientDisplayName) {
			AppState.update((s) => {
				s.clientDisplayName = clientDisplayName;
			});
		}
	}, [domainConfig]);

	if (!domainConfig || !grad1Colour || !grad2Colour) {
		return <Loading />;
	}

	return (
		<>
        <div className={`flex flex-col h-screen ${basicLayoutClasses}`}>
				<div style={{ backgroundColor: onboardingDarkColour }} className='p-6'>
					{domainConfig?.logo && (
						<img className='h-6 lg:h-12' src={domainConfig.logo} alt='logo' />
					)}
				</div>
				<div
					style={{ backgroundColor: onboardingDarkColour }}
					className='text-white grow flex flex-col items-center justify-center'
				>
					{/* <div className="flex flex-row-reverse pr-10">
            <LanguageSelector />
          </div> */}
					{children}
				</div>
				<div
					style={{
						position: 'fixed',
						bottom: '10px',
						right: '10px',
						padding: '6px',
					}}
					className='text-xs text-sentric-rebrand-grey-text'
				>
					&copy; {domainConfig?.display_name} 2024
				</div>
			</div>
		</>
	);
}
export default BasicLayout;
