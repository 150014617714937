import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getBroadcasts, getMyAccount } from "../../../../api/queries";
import BroadcastsTable from "../../../../components/broadcastsTable";
import H2 from "../../../../components/h2";
import P from "../../../../components/p";
import Spacer from "../../../../components/spacer";
import AppState from "../../../../store/appstate";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function Broadcasts() {
	const { t } = useTranslation("other");
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	// const history = useHistory();

	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});

	const broadcasts = getBroadcasts({
		ac: selectedAccount,
		oc: myAccount?.data?.originatorcode?.code,
	});

	useEffect(() => {
		if (selectedAccount) {
			broadcasts.refetch();
		}
	}, [selectedAccount]);

	const { data: siteConfig } = useSiteConfigAuth();
	const history = useHistory();
	const noBroadcasts = siteConfig?.disable_broadcasts;
	useEffect(() => {
		if (noBroadcasts) {
			history.push("/");
		}
	}, [noBroadcasts]);

	return (
		<div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
			<H2>{t("other:your-broadcasts-header")}</H2>
			<Spacer thin />
			<P className='font-sentric-alt' small>
				{t("other:your-broadcasts-text")}
			</P>
			<Spacer />
			<BroadcastsTable />
		</div>
	);
}
export default Broadcasts;
