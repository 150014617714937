function IconErrorWarningFill({fill, ...props}) {

  return (
    <svg
      viewBox="0 0 24 24"
      fill={fill}
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
    </svg>
  );
}

export default IconErrorWarningFill;
