import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";

function hr(props) {
  const { className } = props;
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === "TuneCore";

  return (
    <div className={`${className} w-full ${isTunecore ? 'border-tunecore-light-grey' : 'border-sentric-rebrand-mid-grey'} border-b`} />
  );
}

export default hr;
