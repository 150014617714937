/* Do not pass null as the property for data - it is not handled correctly by AWS Lambda on non GET requests.
   It will work locally but fail on AWS - pass an empty object instead */

import { useMutation } from '@tanstack/react-query';
import { apiCall, apiCallWithFormData } from './shared';

export const createWriter = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/writer/create', method: 'post', data,
}));

export const updateWriter = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/put', path: '/api/v1/writer/update', method: 'put', data,
}));

export const updateMyProfile = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/put', path: '/api/v1/user/setprofile', method: 'put', data,
}));

export const createSong = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/work/create', method: 'post', data,
}));

export const createRecording = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/recording/create', method: 'post', data,
}));

export const updateSong = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/put', path: '/api/v1/work/update', method: 'put', data,
}));

export const signup = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/user/signup', method: 'post', data, clientId: true, noAuth: true, skipKycCheck: true,
}));

export const signupConfirm = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/user/verifyuser', method: 'post', data, clientId: true, noAuth: true, skipKycCheck: true,
}));

export const saveUserMeta = () => useMutation((data) => apiCall({
  endpoint: '/usermeta', method: 'post', data, skipKycCheck: true,
}));

export const postOpportunity = () => useMutation((data) => apiCall({
  endpoint: '/opportunity', method: 'post', data,
}));

export const postSync = () => useMutation((data) => apiCall({
  endpoint: '/opportunity', method: 'post', data,
}));

export const postBroadcast = () => useMutation((data) => apiCall({
  endpoint: '/broadcast', method: 'post', data,
}));

export const postGigClaim = () => useMutation((data) => apiCall({
  endpoint: '/gig-claim', method: 'post', data,
}));

export const updateGigClaim = () => useMutation((data) => apiCall({
  endpoint: '/gigs/update', method: 'put', data,
}));

export const deleteGigClaim = () => useMutation((data) => apiCall({
  endpoint: `/gigsClaim/delete?id=${data.id}&account_code=${data.account_code}`, method: 'delete',
}));

export const putBroadcast = () => useMutation((data) => apiCall({
  endpoint: '/broadcast', method: 'put', data,
}));

export const postSetlist = () => useMutation((data) => apiCall({
  endpoint: '/setlist', method: 'post', data,
}));

export const putSetlist = () => useMutation((data) => apiCall({
  endpoint: '/setlist', method: 'put', data,
}));

export const addArtistAccount = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/artistaccount/create', method: 'post', data,
}));

export const updateArtistAccount = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/put', path: '/api/v1/artistaccount/update', method: 'put', data,
}));

export const updateArtistAccountMeta = () => useMutation((data) => apiCall({
  endpoint: '/artist-meta', method: 'post', data,
}));

export const resetPassword = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/put', path: '/api/v1/user/forgotpassword', method: 'put', data, clientId: true, noAuth: true, skipKycCheck: true,
}));

export const resetPasswordConfirm = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/put', path: '/api/v1/user/confirmresetpassword', method: 'put', data, clientId: true, noAuth: true, skipKycCheck: true,
}));

export const s3Put = () => useMutation(({ key, contenttype }) => apiCall({ endpoint: `/s3/put?key=${key}&contenttype=${contenttype}`, method: 'get' }));

export const updateRecordingMeta = () => useMutation((data) => apiCall({
  endpoint: '/recording-meta', method: 'post', data,
}));

export const updateSuggestion = () => useMutation((data) => apiCall({
  endpoint: '/spotify-suggestion', method: 'post', data,
}));

export const elasticQuery = () => useMutation((data) => apiCall({
  endpoint: '/royalties/get', method: 'post', data, skipKycCheck: true,
}));

export const attachAmendmentOnlyMp3Put = () => useMutation((data) => apiCallWithFormData({
  endpoint: '/rightsapp/putRecordingWithBinary', path: '/api/v1/recording/attachamendmentonlymp3', method: 'put', data,
}));

export const attachMp3Put = () => useMutation((data) => apiCallWithFormData({
  endpoint: '/rightsapp/putRecordingWithBinary', path: '/api/v1/recording/attachmp3', method: 'put', data,
}));

export const attachAmendmentOnlyMp3ByPreSignedUrlPost = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/recording/attachamendmentonlymp3bypresignedurl', method: 'post', data,
}));

export const attachMp3ByPresignedUrlPost = () => useMutation((data) => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/recording/attachmp3bypresignedurl', method: 'post', data,
}));

export const getMyAccountMutation = () => useMutation((artistaccountcode) => apiCall({
  endpoint: '/rightsapp/get',
  path: `/api/v1/artistaccount/getmyaccount?artistaccountcode=${artistaccountcode}`,
}));

export const refreshKycStatus = () => useMutation(() => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/user/refreshkyc', method: 'post', data: {}, skipKycCheck: true,
}));

export const startKyc = () => useMutation(() => apiCall({
  endpoint: '/rightsapp/post', path: '/api/v1/user/startkyc', method: 'post', data: {}, skipKycCheck: true,
}));
