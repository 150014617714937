/* eslint-disable no-console */
import { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { apiCall } from '../../api/shared';
import AppState from '../../store/appstate';
import Spacer from '../../components/spacer';
import Alert from '../../components/alert';
import H3 from '../../components/h3';
import { logout } from '../../helpers/helpers';

function RaAuth() {
  const [kycObj, setKycObj] = useState(null);
  const location = useLocation();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [startedLogin, setStartedLogin] = useState(false);
  const { redirectUrl } = location.state || { redirectUrl: '/' };
  const history = useHistory();
  const accessToken = AppState.getRawState()?.authentication?.access_token;

  function getToken() {
    const { search } = useLocation();
    return useMemo(() => {
      const urlParams = new URLSearchParams(search);
      return urlParams.get('token');
    }, [search]);
  }

  const token = getToken();
  useEffect(() => {
    const login = async () => {
      setPending(true);
      const apiUrl = AppState.getRawState()?.apiUrl;
      const details = await fetch(`${apiUrl}/rightsapp/delegatedLogin`, {
        method: 'POST',
        body: JSON.stringify({
          token,
        }),
      })
        .then((res) => res.json())
        .catch((e) => {
          setPending(false);
          setError(`${e.name}: ${e.message}`);
        });

      setPending(false);

      if (details?.access_token) {
        AppState.update((s) => {
          s.authentication = details;
        });
        return;
      }
      if (details?.error) {
        setError(details.error_description);
      }
    };
    if (!startedLogin) {
      logout();
      setError(null);
      login();
      setStartedLogin(true);
    }
  });

  useEffect(() => {
    const fetchKycStatus = async () => {
      const kyc = await apiCall({
        endpoint: '/rightsapp/get',
        path: '/api/v1/user/getkyc',
      });
      AppState.update((s) => {
        s.kyc = kyc;
      });
      setKycObj(kyc);
    };
    if (accessToken) {
      fetchKycStatus();
    }
  }, [accessToken]);

  useEffect(() => {
    if (kycObj) {
      const showSecurity = kycObj.kycrequired && !kycObj.kycpassed && kycObj.messages[0] === 'KYC Required';
      const showSecurityStep2 = kycObj.kycrequired && !kycObj.kycpassed && kycObj.messages[0] === 'KYC still pending';
      if (showSecurity) {
        history.push('/security');
      } else if (showSecurityStep2) {
        history.push('/security/begin-security-check');
      } else {
        history.replace(redirectUrl);
      }
    }
  }, [kycObj]);
  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center p-4 text-center min-h-screen-minus-footer">
        <div className='w-full lg:w-1/3 text-white'>
          {pending && (
            <div className="flex flex-col justify-cente">
              <H3>Logging In</H3>
              <Spacer thin />
              <FaSpinner className="w-full animate-spin" size={36} />
            </div>
          )}
          {error && (
            <>
              <Spacer />
              <Alert msg={error} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RaAuth;
