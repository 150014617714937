/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';
import getColours from '../helpers/getColours';

function StatusPill({ object }) {
  const { t } = useTranslation();
  const { data:siteConfig } = useSiteConfigAuth();
  const { statusSuccessColour, statusWarningColour, statusErrorColour } = getColours();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const styles = isTunecore
  ? { textColor:'black', backgroundColor: '#00ef86' }
  : {};

  let obj = { // warning
    colours: {
      backgroundColor: !isTunecore ? statusWarningColour : '#282828',
    },
    status: t('common:pill-submitted'),
    tooltip: t('common:pill-tooltip-submitted'),
  };

  if (object.isdeactivated) { // error
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusErrorColour : '#F44336',
      },
      status: t('common:pill-deactivated'),
      tooltip: t('common:pill-tooltip-deactivated'),
    };
  } else if (object.islocked) { // warning
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusWarningColour : '#EE9E26',
      },
      status: t('common:pill-change'),
      tooltip: t('common:pill-tooltip-amendment'),
    };
  } else if (object.verifiedstate !== 0 && object.changespending) { // warning
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusWarningColour : '#EE9E26',
      },
      status: t('common:pill-change'),
      tooltip: t('common:pill-tooltip-amendment'),
    };
  } else if (object.verifiedstate === 0) { // warning
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusWarningColour : '#EE9E26',
      },
      status: t('common:pill-submitted'),
      tooltip: t('common:pill-tooltip-submitted'),
    };
  } else if (object.verifiedstate === 1) { // success
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusSuccessColour : '#00ef86',
      },
      status: t('common:pill-processed'),
      tooltip: t('common:pill-tooltip-processed'),
    };
  } else if (object.verifiedstate === 2) { // error
    obj = {
      colours: {
        backgroundColor: !isTunecore ? statusErrorColour : '#F44336',
      },
      status: t('common:pill-rejected'),
      tooltip: t('common:pill-tooltip-rejected'),
    };
  }

  return (
    <div>
      <ReactTooltip effect="solid" multiline {...styles} />
      <div
        style={obj.colours}
        data-tip={obj.tooltip}
        className={`inline-block text-xxs text-center rounded-lg  ${isTunecore ? 'text-black tracking-wider py-2 px-4' : 'text-white py-2 px-5'}  uppercase ${obj.classes}`}
      >
        {obj.status}
      </div>
    </div>
  );
}

export default StatusPill;
