import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/input';
import Spacer from '../../../components/spacer';
import { getMyAccounts, getUserMeta } from '../../../api/queries';
import AppState from '../../../store/appstate';
import Loading from '../../../layouts/loading';
import FormError from '../../../components/formError';
import useDomainConfigAuth from '../../../api/sanity/queries/getSiteConfigWithDomain';

function OnboardingArtistName({ setIsLocked }) {
  const { t } = useTranslation('onboarding');
  const userMeta = getUserMeta();
  const addAccountName = AppState.useState((s) => s.addAccount.accountName);
  const myAccounts = getMyAccounts();
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [valid, setValid] = useState(true);
  const { data: domainConfig } = useDomainConfigAuth();
  const isTunecore = domainConfig?.display_name === 'TuneCore';

  // Check if name already exists in accounts
  const doesNameAlreadyExist = (name) =>
    myAccounts.data?.artistaccounts.find(
      (a) => a.accountname.toLowerCase() === name.toLowerCase().trim()
    );

  // Validate the artist name length
  const checkValid = (text) => {
    let error = '';
    if (text.length > 199) {
      error = t('regex:artist-account-name-char-error');
      setValid(false);
    } else {
      setValid(true);
    }
    setValidationErrorMessage(error);
  };

  // Check for any errors in the name
  const checkErrors = (name) => {
    if (doesNameAlreadyExist(name)) {
      setValidationErrorMessage(t('regex:artist-name-already-exists-error'));
    }
  };

  // Effect to update the locked state
  useEffect(() => {
    setIsLocked(!addAccountName || !valid);
  }, [addAccountName, valid, setIsLocked]);

  // Handle input change for the artist name
  const handleInputChange = (e) => {
    const { value } = e.target;
    AppState.update((s) => {
      s.addAccount.accountName = value;
    });
    checkValid(value);
    checkErrors(value);
  };

  // Determine placeholder based on user role
  let placeholder = t('onboarding:artist-name-placeholder');
  switch (userMeta?.data?.role) {
    case 'Band':
      placeholder = t('onboarding:band-name-placeholder');
      break;
    default:
      placeholder = t('onboarding:artist-name-placeholder');
  }

  if (myAccounts.isLoading || userMeta.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex flex-col items-center justify-center text-center">
        {userMeta.isSuccess && (
          <>
            <Spacer />
            <div className="w-full xl:w-1/2 text-old-sentric-dark-gray">
              <Input
                className={`${!isTunecore ? 'auth-page-input' : 'auth-tunecore-page-input'}`}
                placeholder={placeholder}
                whiteLabelText
                value={addAccountName}
                onBlur={(e) => checkValid(e.target.value)}
                onChange={handleInputChange}
              />
              <Spacer />
              <FormError validationError={validationErrorMessage} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default OnboardingArtistName;
