import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaLock, FaRegEdit } from 'react-icons/fa';
import { getGigClaims, getMyAccount } from '../api/queries';
import { hasAdminRights } from '../auth/shared';
import AppState from '../store/appstate';
import LoadingSmall from './loadingSmall';
import Table from './table';
import GigsPill from './gigsPill';
import getColours from '../helpers/getColours';

const AllGigs = () => {
  const { t } = useTranslation(['dashboard', 'gigs', 'songs']);
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const history = useHistory();
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const allGigs = getGigClaims({
    ac: selectedAccount,
    oc: myAccount?.data?.originatorcode?.code,
  });
  const { highlightColour } = getColours();

  const columns = React.useMemo(() => [
    {
      Header: t('dashboard:ra-column-venue'),
      accessor: 'venueName',
      width: '40%',
      id: 'colallGigsVenue',
    },
    {
      Header: t('dashboard:ra-column-date'),
      accessor: 'date',
      width: '10%',
      id: 'colAllGigsDate',
      Cell: ({ row }) => {
        const dateStr = row?.values?.colAllGigsDate;
        return dateStr && dateStr !== '0000-00-00'
          ? dateStr.split('T')[0]
          : 'Unknown date';
      },
    },    
    {
      Header: t('gigs:table-header-time'),
      accessor: 'time',
      width: '10%',
      id: 'colAllGigsTime',
    },
    {
      Header: t('gigs:table-header-setlist'),
      accessor: 'setlistName',
      width: '10%',
      id: 'colallGigsSetlist',
    },
    {
      Header: t('songs:song-table-status'),
      accessor: 'gigStatus',
      width: '20%',
      id: 'colSongTableStatusPill',
      Cell: ({ row }) => (
        <div>
          <GigsPill object={row?.values?.colSongTableStatusPill} />
        </div>
      ),
    },
    {
      accessor: 'gigStatus',
      width: '10%',
      id: 'colGigStatus',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="text-2xl gig-claim-icon">
          {row?.values?.colGigStatus === 1 && hasAdminRights ? (
            <>
              <FaRegEdit style={{color: highlightColour}}  className="gig-claim-editable-pen-icon" />
            </>
          ) : (
            <FaLock className="text-old-sentric-submenu-icon" />
          )}
        </div>
      ),
    },
    // hidden columns in Table component
    {
      Header: '',
      accessor: 'id',
      width: null,
      id: 'colGigId',
      Cell: '',
    },
  ]);

  const onRowClick = (row) => {
    // eslint-disable-next-line no-unused-expressions
    row?.values?.colGigStatus === 1
    || row?.values?.colGigStatus === hasAdminRights
      ? history.push(`/gigs/claim/update/${row?.values?.colGigId}`)
      : null;
  };

  return (
    <>
      <div className="w-full p-8 bg-white rounded-lg">
        {allGigs.isLoading && <LoadingSmall />}
        {allGigs.isSuccess && (
          <div>
            <Table
              area="allGigs"
              data={allGigs.data}
              columns={columns}
              min={5}
              highlightColour={highlightColour}
              revealComponent={null}
              onRowClick={onRowClick}
              searchSuffix={t('common:gigs')}
              bgClass="bg-white"
              sortBy={{
                id: 'date',
              }}
            />
          </div>
        )}
        {allGigs.isError && (
          <div>
            <p>Error getting recent gigs</p>
          </div>
        )}
      </div>
    </>
  );
};

export default AllGigs;
