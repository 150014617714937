/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { getMyWriters, getSocieties } from '../../../../api/queries';
import LoadingSmall from '../../../../components/loadingSmall';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';
import AppState from '../../../../store/appstate';
import getColours from '../../../../helpers/getColours';

function SongWriterSplit({ writer, last, aac = null }) {
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const writers = getMyWriters({ aac: aac || selectedAccount });
  const { t } = useTranslation('songs');
  const societies = getSocieties();
  const { data: siteConfig } = useSiteConfigAuth();
  const { statusSuccessColour } = getColours();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const designationCodes = [
    { name: t('songs:author-option'), value: 1 },
    { name: t('songs:composer-option'), value: 2 },
    { name: t('songs:both-option'), value: 3 },
  ];

  if (!writers.isSuccess || !societies.isSuccess) {
    return (<LoadingSmall />);
  }

  const writerDetails = writers.data.writers.find((w) => w.writercode === writer.writercode);

  const society = societies.data.societies.find((s) => s.cisaccode === writerDetails?.societycisaccode);

  const writerType = designationCodes.find((d) => d.value === parseInt(writer.writerdesignationcode));

  if (writer?.isdeleted) {
    return <></>;
  }

  function name(type) {
    if (writerDetails?.[type]) return writerDetails?.[type];

    if (writer?.[type]) return writer?.[type];

    return '';
  }

  return (
    <div className={`flex items-end py-2  ${last ? '' : 'border-b border-old-sentric-menu-gray'} `}>
      <div className='w-full lg:w-1/2 flex flex-col pr-2'>
        <h2 className={`${isTunecore ? 'text-white' : 'text-black font-medium mb-2'} text-base font-bold`}>
          {' '}
          {name('firstname')}
          {' '}
          {name('middlename')}
          {' '}
          {name('lastname')}
        </h2>
        <div className={`text-xs flex flex-col gap-2 sm:gap-0 ${isTunecore ? 'text-white' : 'text-sentric-rebrand-grey-text'}`}>
          <p>
          {t('songs:membership')}
          :
          {' '}
          {society?.name}
          </p>
          <p>
          CAE/IPI #:
          {' '}
          {writerDetails?.caeipinumber ? writerDetails.caeipinumber : '-'}
          </p>
        </div>
      </div>
      <div className='w-full lg:w-1/2 mt-4 lg:mt-0 flex flex-wrap items-end'>
        <div className='w-full xl:w-1/3'>
          <p className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} font-semibold text-xs`}>
            {writerType?.name}
          </p>
        </div>
        <div className='w-full xl:w-1/3'>
          <p className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} font-semibold text-xs`}>
            {writer.writershare}
            %
            {' '}
            {t('songs:split')}
          </p>
        </div>
        <div className='w-full xl:w-1/3'>
          <p className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} font-semibold text-xs`}>
            {writer.righttocollect
            && (
            <span
              style={{ color: isTunecore ? '#00ef86' : statusSuccessColour }}>
              <FaCheck className="inline mr-2" />
              {t('songs:right-to-collect')}
            </span>
            )}
            {!writer.righttocollect
            && (
            <span className={`${isTunecore ? 'text-tunecore-error-red' : 'text-red-600'}`}>
              <FaTimes className="inline mr-2" />
              {t('songs:no-right-to-collect')}
            </span>
            )}
          </p>
        </div>
      </div>

    </div>
  );
}

export default SongWriterSplit;
