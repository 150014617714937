/* eslint-disable jsx-a11y/control-has-associated-label */
import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaRegEdit } from 'react-icons/fa';
import {
  getSetlists,
  getMyAccount,
} from '../api/queries';
// import { hasAdminRights } from '../auth/shared';
// import { hasAdminRights } from '../auth/shared';
import AppState from '../store/appstate';
// import ButtonPrimaryText from './buttonPrimaryText';
import LoadingSmall from './loadingSmall';
import Table from './table';
import getColours from '../helpers/getColours';

const SetlistsTable = () => {
  const { t } = useTranslation(['gigs', 'dashboard']);
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const history = useHistory();
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const setlists = getSetlists({ ac: selectedAccount, oc: myAccount?.data?.originatorcode?.code });
  const { highlightColour } = getColours();

  const columns = React.useMemo(() => [
    {
      Header: t('gigs:table-header-name'),
      accessor: 'name',
      width: '33%',
      id: 'colSetlistName',
    },
    {
      Header: t('dashboard:ra-column-date'),
      accessor: 'created',
      width: '33%',
      id: 'colSetlistDate',
      Cell: ({ row }) => (row?.values?.colSetlistDate
        && row?.values?.colSetlistDate !== '0000-00-00'
        ? format(Date.parse(row.values.colSetlistDate), 'yyyy-MM-dd')
        : 'unknown'),
    },
    {
      Header: t('gigs:table-header-songs'),
      accessor: 'songs',
      width: '33%',
      id: 'colSetlistSongsCount',
      Cell: ({ row }) => (JSON.parse(row?.values?.colSetlistSongsCount).length),
    },
    {
      Header: '',
      accessor: '',
      width: '10%',
      id: 'colSetlistEdit',
      Cell: ({ row }) => ((
        (String(row.values.colSetlistEdit),
        (<FaRegEdit style={{color: highlightColour}} className="text-2xl gig-claim-editable-pen-icon" />))
      )),
      disableSortBy: true,
    },
    {
      // hidden column in Table component
      Header: '',
      accessor: 'id',
      width: null,
      id: 'colSetlistId',
      Cell: '',
    },
  ]);

  const onRowClick = (row) => {
    // eslint-disable-next-line no-unused-expressions
    history.push(`/gigs/setlists/update/${row?.values?.colSetlistId}`);
  };

  return (
    <>
      <div className="w-full p-8 bg-white rounded-lg">
        {setlists.isLoading && <LoadingSmall />}
        {setlists.isSuccess && (
          <div>
            <Table
              area="setlists"
              data={setlists.data}
              columns={columns}
              min={5}
              highlightColour={highlightColour}
              revealComponent={null}
              onRowClick={onRowClick}
              searchSuffix={t('common:setlists')}
              bgClass="bg-white rounded-lg p-0"
              sortBy={{
                id: 'date',
              }}
            />
          </div>
        )}
        {setlists.isError && (
          <div>
            <p>Error getting recent setlists</p>
          </div>
        )}
      </div>
    </>
  );
};

export default SetlistsTable;
