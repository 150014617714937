/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { format } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { getMyAccounts, useTopSongs } from "../api/queries";
import SongDetails from "../screens/authenticated/dashboard/songs/SongDetails";
import ButtonPrimaryText from "./buttonPrimaryText";
import ChooseAccountModal from "./ChooseAccountModal";
import LoadingSmall from "./loadingSmall";
import Table from "./table";
import RebrandButtonLink from "./rebrandButtonLink";
import getColours from "../helpers/getColours";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import { PlusIconPlus, PlusIconMinus } from "./svg/PlusIcon";

const TopSongs = () => {
	const { t } = useTranslation("dashboard");
	const myAccounts = getMyAccounts();
	const topSongs = useTopSongs({ accounts: myAccounts?.data?.artistaccounts });
	const { highlightColour } = getColours();
	const [accountChoiceModal, setAccountChoiceModal] = useState(false);
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const columns = React.useMemo(() => [
		{
			Header: t("dashboard:ra-column-title"),
			accessor: "title",
			width: "25%",
			id: "colTopSongTitle",
		},
		{
			Header: t("dashboard:ra-column-artist-account"),
			accessor: "accountname",
			width: "25%",
			id: "colTopSongAccount",
		},
		{
			Header: t("dashboard:ra-column-date-added"),
			accessor: "createddate",
			width: "15%",
			id: "colTopSongCreated",
			Cell: ({ row }) =>
				row?.values?.colTopSongCreated &&
				row?.values?.colTopSongCreated !== "0000-00-00"
					? format(Date.parse(row.values.colTopSongCreated), "yyyy-MM-dd")
					: null,
		},
		{
			// Make an expander cell
			Header: () => null, // No header
			id: "expander", // It needs an ID
			width: "5%",

			Cell: ({ row }) => (
				// Use Cell to render an expander for each row.
				// We can use the getToggleRowExpandedProps prop-getter
				// to build the expander.
				<>
					{isTunecore ? (
						<span className='float-right'>
							{row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
						</span>
					) : (
						<span className='float-right'>
							{row.isExpanded ? (
								<PlusIconMinus className='h-4' fill={highlightColour} />
							) : (
								<PlusIconPlus className='h-4' fill={highlightColour} />
							)}
						</span>
					)}
				</>
			),
			// We can override the cell renderer with a SubCell to be used with an expanded row
			SubCell: () => null, // No expander on an expanded row
		},
	].filter(Boolean));

	return (
		<>
			{!!accountChoiceModal && (
				<ChooseAccountModal
					title={t("dashboard:choose-account-songs-modal-header")}
					to='/songs/create'
					setAccountChoiceModal={setAccountChoiceModal}
					accounts={myAccounts?.data?.artistaccounts}
					nonAdminTo='/songs/'
				/>
			)}
			<div
				className={`${
					isTunecore
						? "bg-tunecore-dark-element text-white"
						: "bg-white text-black"
				} w-full p-8 pt-10 rounded-lg`}
			>
				<div className='flex flex-row items-center justify-between mb-5'>
					<h2 className='text-xl font-medium '>
						{t("dashboard:recently-added-songs-header")}
					</h2>
					<RebrandButtonLink
						plusIcon
						id='btnTopSongAddSong'
						onClick={() => {
							setAccountChoiceModal(true);
						}}
					>
						{t("dashboard:add-new-song-link")}
					</RebrandButtonLink>
				</div>
				{topSongs.isLoading && <LoadingSmall />}
				{topSongs.isSuccess && (
					<div>
						<Table
							area='TopSongs'
							data={topSongs.data}
							min={5}
							columns={columns}
							highlightColour={highlightColour}
							revealComponent={<SongDetails />}
							searchSuffix={t("common:songs-search-suffix")}
							bgClass={`${
								isTunecore ? "bg-tunecore-dark-element" : "bg-white"
							} rounded-lg p-0`}
							initialSort={[
								{
									id: "createddate",
									desc: true,
								},
							]}
						/>
					</div>
				)}
				{topSongs.isError && (
					<div>
						<p>{t("dashboard:recent-songs-error")}</p>
					</div>
				)}
			</div>
		</>
	);
};

export default TopSongs;
