/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoadingSmall from './loadingSmall';
import Spacer from './spacer';
import H2 from './h3';
import AppState from '../store/appstate';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function ChangeAccountModal({
  accounts, to, account, setSwitchModal,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  useEffect(() => {
    setTimeout(() => {
      setSwitchModal(null);

      history.push(to);
    }, 2000);

    AppState.update((s) => {
      s.selectedAccount = account.artistAccountCode;
      s.selectedCatalogue = account.catalogueCode;
    });
  }, []);

  if (accounts.length === 1) {
    history.push(to);
    return <></>;
  }

  return (
    <div className="z-40 top-0 left-0 fixed w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
      <div className={`${isTunecore ? 'bg-tunecore-dark-element' : 'bg-white'} rounded-lg relative lg:w-1/3 w-7/8 p-8 flex items-center justify-center flex-col text-center`}>
        <LoadingSmall />
        <Spacer />
        <H2>
          {t('auth:switching-account')}
          {' '}
          <br />
          {account.name}
        </H2>
      </div>
    </div>

  );
}

export default ChangeAccountModal;
