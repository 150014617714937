/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import H1 from "../../components/h1";
import P from "../../components/p";
import Spacer from "../../components/spacer";
import Input from "../../components/input";
import AppState from "../../store/appstate";
import { getUserMeta } from "../../api/queries";
import AuthPrimaryButton from "../../components/authPrimaryButton";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";

function AddInstagram() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();
	const addAccount = AppState.useState((s) => s.addAccount);
	const userMeta = getUserMeta();
	const [pending, setPending] = useState(false);
	const { data: domainConfig } = useDomainConfigAuth();
	const isTunecore = domainConfig?.display_name === "TuneCore";

	let title = t("onboarding:add-instagram-header1");
	let desc = t("onboarding:add-instagram-desc1");

	switch (userMeta?.data?.role) {
		case "Manager":
			title = t("onboarding:add-instagram-header2");
			desc = t("onboarding:add-instagram-desc2");

			break;
		default:
			break;
	}

	return (
		<div className='container mx-auto'>
			<div className='flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer'>
				<p className='text-6xl text-white'>
					<FaInstagram />
				</p>

				<Spacer />

				<>
					<H1>{title}</H1>
					<Spacer />
					<P>{desc}</P>
					<Spacer />
					<div className='relative flex flex-col items-center justify-center w-full text-old-sentric-dark-gray'>
						<div
							className={`w-full lg:w-1/3  ${
								isTunecore ? "tunecore-input-hover" : ""
							}`}
						>
							<Input
								className={`${
									isTunecore ? "auth-tunecore-page-input" : "auth-page-input"
								}`}
								placeholder='@username'
								whiteLabelText
								value={addAccount.instagram}
								onChange={(e) =>
									AppState.update((s) => {
										s.addAccount.instagram = e.target.value;
									})
								}
							/>
						</div>
					</div>
				</>

				<Spacer />

				<div className='flex flex-row justify-center w-full lg:w-1/3'>
					<AuthPrimaryButton
						pending={pending}
						onClick={() => history.push("/add-account-6") && setPending(true)}
						arrow
					>
						{t("common:_done")}
					</AuthPrimaryButton>
				</div>
			</div>
		</div>
	);
}

export default AddInstagram;
