import { useRef, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import useSiteConfigUnAuth from '../api/sanity/queries/getSiteConfigWithDomain';

function BackButton(props) {
  const { children, onClick } = props;
  const { data: siteConfig } = useSiteConfigUnAuth();
  const isTunecore = siteConfig?.display_name === "TuneCore";

  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  }, []);

  const handleClick = (e) => {
    onClick(e);
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  };

  return (
    <>
      <button
        ref={buttonRef}
        type="button"
        style={isTunecore ? {
          background: 'linear-gradient(to bottom right, #a6a6a6, #505050)',
          borderRadius: '50px',
          padding: '12px 20px',
        } : {
          background: '#918F8F'
        }}
        className={`focus:ring-2 focus:ring-white focus:scale-105 disabled:opacity-25 disabled:pointer-events-none hover:bg-opacity-50 font-sentric-alt uppercase font-medium w-1/3 text-sm x-8 p-3 rounded-lg shadow hover:shadow-md disabled:shadow-none outline-none mb-1 ease-linear transition-all duration-150 tracking-tight hover:scale-105 ${isTunecore ? 'text-white' : 'text-sentric-rebrand-dark-grey'}`}
        onClick={handleClick}
      >
        <div className="flex items-center justify-between">
          <FaArrowLeft />
          {children}
        </div>
      </button>
    </>
  );
}

export default BackButton;
