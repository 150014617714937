export const PlusIconPlus = ({ fill, className = '', ariaLabel = 'Plus Icon' }) => (
  <svg
    className={className}
    viewBox="0 0 29 23"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    focusable="false"
    aria-label={ariaLabel}
  >
    <title>{ariaLabel}</title>
    <path d="M16.0573 10.1178V0H12.9427V10.1178H0V12.8809H12.9427V23H16.0573V12.8809H29V10.1178H16.0573Z" />
  </svg>
);

export const PlusIconMinus = ({ fill, className = '', ariaLabel = 'Minus Icon' }) => (
  <svg
    className={className}
    viewBox="0 0 29 23"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    focusable="false"
    aria-label={ariaLabel}
  >
    <title>{ariaLabel}</title>
    <path d="M0 10.1178H29V12.8809H0V10.1178Z" fill={fill} />
  </svg>
);





export default {PlusIconPlus, PlusIconMinus};
