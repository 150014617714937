/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FaLock } from "react-icons/fa";
import debounce from "lodash/debounce";
import ButtonIcon from "../../../../images/rebrandIcons/ButtonIcon.png";
import {
postSetlist,
postSync,
putSetlist,
signup,
} from "../../../../api/mutations";
import {
getMyAccount,
getMySongs,
getSetlist,
getSetlists,
getSyncs,
getUserMeta,
getMyProfile,
getMySongsDetailed,
getMyWorkAutoSuggest,
} from "../../../../api/queries";
import FormError from "../../../../components/formError";
import ButtonInner from "../../../../components/buttonInner";
import DoubleMultipleInput from "../../../../components/doubleMultipleInput";
import H2 from "../../../../components/h2";
import H4 from "../../../../components/h4";
import Input from "../../../../components/input";
import LoadingSmall from "../../../../components/loadingSmall";
import MultipleInput from "../../../../components/multipleInput";
import MultipleSelect from "../../../../components/multipleSelect";
import P from "../../../../components/p";
import Spacer from "../../../../components/spacer";
import TextArea from "../../../../components/textArea";
import { sendEvent, sendProperty } from "../../../../helpers/helpers";
import AppState from "../../../../store/appstate";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function SetlistsCreateUpdate(props) {
const { id } = useParams();
const history = useHistory();
const { data: siteConfig } = useSiteConfigAuth();
if (siteConfig.disable_gigs) {
history.push("/");
}
const profile = getMyProfile();
const isUpdate = !!id;
const [songList, setSongList] = useState([]);
const [pending, setPending] = useState(false);
const [mutationErrorMessage, setMutationErrorMessage] = useState("");
const [validationErrorMessage, setValidationErrorMessage] = useState("");
const [errors, setErrors] = useState({});
const [name, setName] = useState();
const [notes, setNotes] = useState();
const [otherSongs, setOtherSongs] = useState(null);
const [tuneCode, setTuneCode] = useState("");
const [items, setItems] = useState(null);
const [searchString, setSearchString] = useState("");
const [options, setOptions] = useState([]);
const [cachedSongs, setCachedSongs] = useState([]);
const [noOptionsMessage, setNoOptionsMessage] = useState(
"Start typing to search..."
);
const createMutation = postSetlist();
const updateMutation = putSetlist();
const fetchOptions = useCallback(
debounce((inputValue) => {
if (inputValue.length > 0) {
setSearchString(inputValue);
}
}, 500),
[]
);
const handleInputChange = (inputValue) => {
fetchOptions(inputValue);
};
const selectedAccount = AppState.useState((s) => s.selectedAccount);
const myAccount = getMyAccount({
artistaccountcode: selectedAccount,
});
const setlists = getSetlists({ ac: selectedAccount });
const setlist = getSetlist({ ac: selectedAccount, id });
const { t } = useTranslation("gigs");
const works = getMyWorkAutoSuggest({
aac: selectedAccount,
searchstring: searchString,
});

useEffect(() => {
	if (works && works?.data?.works) {
		const fetchedOptions = works?.data.works.map((songData) => {
			const songOption = {
				label: `${songData.title} - (${songData.performingartists})`,
				value: songData.workcode,
			};
			return songOption;
		});
		setOptions(fetchedOptions);
		setCachedSongs((prevCachedSongs) => [
			...prevCachedSongs,
			...works?.data?.works,
		]);
		setNoOptionsMessage(
			fetchedOptions.length === 0
				? "No options found"
				: "Start typing to search..."
		);
	}
}, [works?.data?.works]);

useEffect(() => {
	if (selectedAccount) {
		setlist.refetch();
	}
}, [selectedAccount]);

useEffect(() => {
	if (isUpdate && setlist.isSuccess && setlist.data && id && !name) {
		setName(setlist.data[0].name);
		setNotes(setlist.data[0].notes || "");
		console.log("Raw setlist data:", setlist.data[0].songs); 
		const songs = setlist.data[0].songs
			? JSON.parse(setlist.data[0].songs).map((s) => {
					const cachedSong = cachedSongs.find(
						(song) => song.workcode === s.workcode
					);
					return {
						workcode: s.workcode,
						title: cachedSong ? cachedSong.title : s.title,
						performingartists: cachedSong ? cachedSong.performingartists : s.performingartists,
						label: cachedSong
							? `${cachedSong.title} - (${cachedSong.performingartists || s.performingartists})`
							: s.title,
					};
			  })
			: [];
		setSongList(songs);
		setOtherSongs(
			setlist.data[0].other_songs
				? JSON.parse(setlist.data[0].other_songs)
				: []
		);
	}
}, [isUpdate, setlist.isSuccess, setlist.data, id, name, cachedSongs]);

console.log(songList, "songList");

if (setlist.isSuccess && setlist.data.length === 0 && id && setlist.data.songs.workcode === null) {
	history.push("/gigs/setlists");
}

function checkInvalidCoverItem(item, index) {
	const artistLength = item?.artist?.length;
	const songLength = item?.song?.length;
	const tuneCodeLength = item?.tunecode?.length;
	const validTuneCode = item?.tunecode?.length === 8;

	const errorStart = `${t("regex:cover-song-error-start")} ${index}:`;

	if (artistLength && !songLength) {
		setValidationErrorMessage(
			`${errorStart} ${t("regex:cover-song-song-title-error")}`
		);
		return true;
	}

	if (songLength && !artistLength) {
		setValidationErrorMessage(
			`${errorStart} ${t("regex:cover-song-artist-name-error")}`
		);
		return true;
	}

	if (tuneCodeLength && !validTuneCode) {
		setValidationErrorMessage(
			`${errorStart} ${t("regex:cover-song-tunecode-character-error")}`
		);
		return true;
	}

	if (tuneCodeLength && validTuneCode && (!artistLength || !songLength)) {
		setValidationErrorMessage(
			`${errorStart} ${t("regex:cover-song-tunecode-both-error")}`
		);
		return true;
	}

	setValidationErrorMessage("");
	return false;
}

const validateTuneCodeAndMandatoryFields = () => {
	const foundedError = items.find((item, i) =>
		checkInvalidCoverItem(item, i + 1)
	);
	if (foundedError) return false;
	return true;
};

const checkErrors = () => {
	const errs = {};
	let valid = true;

	// checks the validation of tuneCode
	valid = validateTuneCodeAndMandatoryFields();

	// has at least one song
	if (!songList || songList.length < 1) {
		errs.songList = t("regex:at-least-one-song-error");
		valid = false;
	}

	if (songList) {
		songList.forEach((sl) => {
			if (!sl.workcode) {
				errs.songList = t("regex:selected-every-song-error");
				valid = false;
			}
		});
	}

	if (!name || name.trim().length === 0) {
		errs.name = t("regex:unnamed-setlist-error");
		valid = false;
	}

	setErrors(errs);
	return valid;
};

const addSetlist = () => {
	setPending(true);
	setMutationErrorMessage("");
	setValidationErrorMessage("");
	const valid = checkErrors();
	// we need this much conditional stuff when creating a setlist because we need to 
	// account for the older setlists from before the refactor that have a different JSON structure		
	if (valid) {
		const selectedWorkcodes = songList.map((s) => s.workcode);
    const selectedSongs = selectedWorkcodes.map((workcode) => {
        const song = songList.find((s) => s.workcode === workcode);
        const cachedSong = cachedSongs.find((option) => option.workcode === workcode);
        return {
            workcode,
            title: song.title || (cachedSong ? cachedSong.title : ""),
        };
    });
		createMutation.reset();
		const data = {
			account_code: selectedAccount,
			originator_code: myAccount.data?.originatorcode?.code,
			name,
			notes,
			tuneCode,
			songs: selectedSongs ? JSON.stringify(selectedSongs) : null,
			other_songs: otherSongs ? JSON.stringify(otherSongs) : null,
		};

		createMutation.mutate(data, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
					toast.success(t("gigs:successfully-submitted-setlist-toast"), {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					history.push("/gigs/setlists");
					setlist.refetch();
					setlists.refetch();
					setPending(false);
					sendProperty({
						property: "last_setlist_added",
						value: "CURRENT_DATE",
						email: profile?.data?.email,
					});
					sendEvent({ property: "pe14489857_setlist_added" });
				}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	} else {
		setPending(false);
	}
};

const updateSetlist = () => {
	setPending(true);
	setMutationErrorMessage("");
	setValidationErrorMessage("");
	const valid = checkErrors();

	if (valid) {
		const selectedWorkcodes = songList.map((s) => s.workcode);
		const selectedSongs = selectedWorkcodes.map((workcode) => {
				const song = songList.find((s) => s.workcode === workcode);
				const cachedSong = cachedSongs.find((option) => option.workcode === workcode);
				return {
						workcode,
						title: song.title || (cachedSong ? cachedSong.title : ""),
				};
		});
		songList.forEach((s) => {
			if (!selectedSongs.find((p) => s.workcode === p.workcode)) {
				selectedSongs.push(s);
			}
		});

		updateMutation.reset();
		const data = {
			id,
			account_code: selectedAccount,
			originator_code: myAccount.data?.originatorcode?.code,
			name,
			notes,
			tuneCode,
			songs: selectedSongs ? JSON.stringify(selectedSongs) : null,
			other_songs: otherSongs ? JSON.stringify(otherSongs) : null,
		};

		updateMutation.mutate(data, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
					toast.success(t("gigs:successfully-updated-setlist-toast"), {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					history.push("/gigs/setlists");
					setlist.refetch();
					setlists.refetch();
					setPending(false);
				}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	} else {
		setPending(false);
	}
};

return (
	<>
		<div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
			{isUpdate ? (
				<H2>{t("gigs:update-setlist-header")}</H2>
			) : (
				<H2>{t("gigs:create-setlist-header")}</H2>
			)}
			<Spacer />
			{id && setlist.isLoading && <LoadingSmall />}
			{(!id || setlist.isSuccess) && (
				<div className='w-full lg:w-2/3'>
					<H4>{t("gigs:setlist-name")}</H4>
					<Spacer thin />

					<Input
						id='txtSetlistName'
						required
						type='text'
						error={errors.name}
						placeholder={t("gigs:setlist-name")}
						value={name}
						onBlur={() => {}}
						onChange={(e) => {
							setName(e.target.value);
						}}
					/>
					<Spacer />

					<H4>{t("gigs:add-songs-to-setlist")}</H4>

					<Spacer thin />

					{(isUpdate && songList) || !isUpdate ? (
						<MultipleSelect
							customLabel={t("gigs:select-song")}
							name='SetlistAddSong'
							type='text'
							addAnotherText={t("gigs:add-another-song")}
							valueKey='workcode'
							value={songList}
							onChange={(arr) => {
								setSongList(arr);
							}}
							error={errors.songList}
							options={options}
							onInputChange={handleInputChange}
							noOptionsMessage={noOptionsMessage}
						/>
					) : null}

					<Spacer />
					<H4>{t("gigs:do-you-cover-any-songs")}</H4>
					<Spacer thin />

					<DoubleMultipleInput
						area='Setlist'
						type='text'
						addAnotherText={t("gigs:add-another")}
						error={errors.otherSongs}
						placeholder1={t("gigs:artist-placeholder")}
						placeholder2={t("gigs:songs-placeholder")}
						placeholder3={t("gigs:tunecode-placeholder")}
						valueKey1='artist'
						valueKey2='song'
						valueKey3='tunecode'
						value={otherSongs}
						onBlur={() => {}}
						onChange={(arr) => {
							setOtherSongs(arr);
						}}
						items={items}
						setItems={setItems}
					/>

					<Spacer />
					<H4>{t("gigs:notes-header")}</H4>
					<Spacer thin />
					<TextArea
						id='txtSetlistNotes'
						className='w-full'
						type='textarea'
						error={errors.notes}
						placeholder={t("gigs:notes-placeholder")}
						value={notes}
						onBlur={() => {}}
						onChange={(e) => {
							setNotes(e.target.value);
						}}
					/>

					<Spacer />
					<FormError
						mutationError={mutationErrorMessage}
						validationError={validationErrorMessage}
					/>
					<div className='flex justify-center sm:justify-end'>
						<RebrandPrimaryButton
							id={isUpdate ? "btnSetlistUpdate" : "btnSetlistAdd"}
							onClick={isUpdate ? updateSetlist : addSetlist}
							pending={pending}
							arrow
						>
							{isUpdate
								? t("gigs:update-setlist-button")
								: t("gigs:add-setlist-button")}
						</RebrandPrimaryButton>
					</div>
					<Spacer />
				</div>
			)}
		</div>
	</>
);
}
export default SetlistsCreateUpdate;