import { FaSpinner } from 'react-icons/fa';

function ButtonInner(props) {
  const {
    children, onClick, disabled, pending, style, id,
  } = props;

  return (
    <button id={id} style={style} disabled={disabled} type="button" className=" disabled:opacity-25 disabled:text-gray-400 disabled:pointer-events-none bg-old-sentric-dark-gray text-white active:bg-black hover:bg-black font-bold uppercase text-xs px-8 py-4 rounded-full shadow hover:shadow-md disabled:shadow-none outline-none focus:outline-none mb-1 ease-linear transition-all duration-150" onClick={onClick}>
      {children}
      {pending && <FaSpinner className="ml-3 text-white inline animate-spin" /> }
    </button>
  );
}

export default ButtonInner;
