/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import { useParams } from 'react-router-dom';
import WritersForm from './writersForm';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function WritersCreate() {
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const { id } = useParams();

  return (
    <div className="flex flex-wrap">
      <div className={`w-full ${isTunecore ? 'bg-tunecore-black' : 'bg-sentric-rebrand-light-grey'} lg:min-h-screen-minus-header`}>

        <WritersForm id={id} />

      </div>
    </div>
  );
}
export default WritersCreate;
