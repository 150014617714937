const countries = [
  { name: 'united-kingdom', value: 'GB' },
  { name: 'united-states', value: 'US' },
  { name: 'afghanistan', value: 'AF' },
  { name: 'aland-islands', value: 'AX' },
  { name: 'albania', value: 'AL' },
  { name: 'algeria', value: 'DZ' },
  { name: 'american-samoa', value: 'AS' },
  { name: 'andorra', value: 'AD' },
  { name: 'angola', value: 'AO' },
  { name: 'anguilla', value: 'AI' },
  { name: 'antarctica', value: 'AQ' },
  { name: 'antigua-and-barbuda', value: 'AG' },
  { name: 'argentina', value: 'AR' },
  { name: 'armenia', value: 'AM' },
  { name: 'aruba', value: 'AW' },
  { name: 'australia', value: 'AU' },
  { name: 'austria', value: 'AT' },
  { name: 'azerbaijan', value: 'AZ' },
  { name: 'bahamas', value: 'BS' },
  { name: 'bahrain', value: 'BH' },
  { name: 'bangladesh', value: 'BD' },
  { name: 'barbados', value: 'BB' },
  { name: 'belarus', value: 'BY' },
  { name: 'belgium', value: 'BE' },
  { name: 'belize', value: 'BZ' },
  { name: 'benin', value: 'BJ' },
  { name: 'bermuda', value: 'BM' },
  { name: 'bhutan', value: 'BT' },
  { name: 'bolivia', value: 'BO' },
  { name: 'bosnia-and-herzegovina', value: 'BA' },
  { name: 'botswana', value: 'BW' },
  { name: 'bouvet-island', value: 'BV' },
  { name: 'brazil', value: 'BR' },
  { name: 'british-indian-ocean-territory', value: 'IO' },
  { name: 'brunei-darussalam', value: 'BN' },
  { name: 'bulgaria', value: 'BG' },
  { name: 'burkina-faso', value: 'BF' },
  { name: 'burundi', value: 'BI' },
  { name: 'cambodia', value: 'KH' },
  { name: 'cameroon', value: 'CM' },
  { name: 'canada', value: 'CA' },
  { name: 'cape-verde', value: 'CV' },
  { name: 'cayman-islands', value: 'KY' },
  { name: 'central-african-republic', value: 'CF' },
  { name: 'chad', value: 'TD' },
  { name: 'chile', value: 'CL' },
  { name: 'china', value: 'CN' },
  { name: 'christmas-island', value: 'CX' },
  { name: 'cocos-keeling-islands', value: 'CC' },
  { name: 'colombia', value: 'CO' },
  { name: 'comoros', value: 'KM' },
  { name: 'congo', value: 'CG' },
  { name: 'congo-republic', value: 'CD' },
  { name: 'cook-islands', value: 'CK' },
  { name: 'costa-rica', value: 'CR' },
  { name: 'cote-divoire', value: 'CI' },
  { name: 'croatia', value: 'HR' },
  { name: 'cuba', value: 'CU' },
  { name: 'cyprus', value: 'CY' },
  { name: 'czech-republic', value: 'CZ' },
  { name: 'denmark', value: 'DK' },
  { name: 'djibouti', value: 'DJ' },
  { name: 'dominica', value: 'DM' },
  { name: 'dominican-republic', value: 'DO' },
  { name: 'ecuador', value: 'EC' },
  { name: 'egypt', value: 'EG' },
  { name: 'el-salvador', value: 'SV' },
  { name: 'equatorial-guinea', value: 'GQ' },
  { name: 'eritrea', value: 'ER' },
  { name: 'estonia', value: 'EE' },
  { name: 'ethiopia', value: 'ET' },
  { name: 'falkland-islands', value: 'FK' },
  { name: 'faroe-islands', value: 'FO' },
  { name: 'fiji', value: 'FJ' },
  { name: 'finland', value: 'FI' },
  { name: 'france', value: 'FR' },
  { name: 'french-guiana', value: 'GF' },
  { name: 'french-polynesia', value: 'PF' },
  { name: 'french-southern-territories', value: 'TF' },
  { name: 'gabon', value: 'GA' },
  { name: 'gambia', value: 'GM' },
  { name: 'georgia', value: 'GE' },
  { name: 'germany', value: 'DE' },
  { name: 'ghana', value: 'GH' },
  { name: 'gibraltar', value: 'GI' },
  { name: 'greece', value: 'GR' },
  { name: 'greenland', value: 'GL' },
  { name: 'grenada', value: 'GD' },
  { name: 'guadeloupe', value: 'GP' },
  { name: 'guam', value: 'GU' },
  { name: 'guatemala', value: 'GT' },
  { name: 'guernsey', value: 'GG' },
  { name: 'guinea', value: 'GN' },
  { name: 'guinea-bissau', value: 'GW' },
  { name: 'guyana', value: 'GY' },
  { name: 'haiti', value: 'HT' },
  { name: 'heard-mcdonald-islands', value: 'HM' },
  { name: 'vatican', value: 'VA' },
  { name: 'honduras', value: 'HN' },
  { name: 'hong-kong', value: 'HK' },
  { name: 'hungary', value: 'HU' },
  { name: 'iceland', value: 'IS' },
  { name: 'india', value: 'IN' },
  { name: 'indonesia', value: 'ID' },
  { name: 'iran', value: 'IR' },
  { name: 'iraq', value: 'IQ' },
  { name: 'ireland', value: 'IE' },
  { name: 'isle-of-man', value: 'IM' },
  { name: 'israel', value: 'IL' },
  { name: 'italy', value: 'IT' },
  { name: 'jamaica', value: 'JM' },
  { name: 'japan', value: 'JP' },
  { name: 'jersey', value: 'JE' },
  { name: 'jordan', value: 'JO' },
  { name: 'kazakhstan', value: 'KZ' },
  { name: 'kenya', value: 'KE' },
  { name: 'kiribati', value: 'KI' },
  { name: 'north-korea', value: 'KP' },
  { name: 'south-korea', value: 'KR' },
  { name: 'kuwait', value: 'KW' },
  { name: 'kyrgyzstan', value: 'KG' },
  { name: 'laos', value: 'LA' },
  { name: 'latvia', value: 'LV' },
  { name: 'lebanon', value: 'LB' },
  { name: 'lesotho', value: 'LS' },
  { name: 'liberia', value: 'LR' },
  { name: 'libya', value: 'LY' },
  { name: 'liechtenstein', value: 'LI' },
  { name: 'lithuania', value: 'LT' },
  { name: 'luxembourg', value: 'LU' },
  { name: 'macao', value: 'MO' },
  { name: 'macedonia', value: 'MK' },
  { name: 'madagascar', value: 'MG' },
  { name: 'malawi', value: 'MW' },
  { name: 'malaysia', value: 'MY' },
  { name: 'maldives', value: 'MV' },
  { name: 'mali', value: 'ML' },
  { name: 'malta', value: 'MT' },
  { name: 'marshall-islands', value: 'MH' },
  { name: 'martinique', value: 'MQ' },
  { name: 'mauritania', value: 'MR' },
  { name: 'mauritius', value: 'MU' },
  { name: 'mayotte', value: 'YT' },
  { name: 'mexico', value: 'MX' },
  { name: 'micronesia', value: 'FM' },
  { name: 'moldova', value: 'MD' },
  { name: 'monaco', value: 'MC' },
  { name: 'mongolia', value: 'MN' },
  { name: 'montserrat', value: 'MS' },
  { name: 'morocco', value: 'MA' },
  { name: 'mozambique', value: 'MZ' },
  { name: 'myanmar', value: 'MM' },
  { name: 'namibia', value: 'NA' },
  { name: 'nauru', value: 'NR' },
  { name: 'nepal', value: 'NP' },
  { name: 'netherlands', value: 'NL' },
  { name: 'netherlands-antilles', value: 'AN' },
  { name: 'new-caledonia', value: 'NC' },
  { name: 'new-zealand', value: 'NZ' },
  { name: 'nicaragua', value: 'NI' },
  { name: 'niger', value: 'NE' },
  { name: 'nigeria', value: 'NG' },
  { name: 'niue', value: 'NU' },
  { name: 'norfolk-island', value: 'NF' },
  { name: 'northern-mariana-islands', value: 'MP' },
  { name: 'norway', value: 'NO' },
  { name: 'oman', value: 'OM' },
  { name: 'pakistan', value: 'PK' },
  { name: 'palau', value: 'PW' },
  { name: 'palestinian-territory', value: 'PS' },
  { name: 'panama', value: 'PA' },
  { name: 'papua-new-guinea', value: 'PG' },
  { name: 'paraguay', value: 'PY' },
  { name: 'peru', value: 'PE' },
  { name: 'philippines', value: 'PH' },
  { name: 'pitcairn', value: 'PN' },
  { name: 'poland', value: 'PL' },
  { name: 'portugal', value: 'PT' },
  { name: 'puerto-rico', value: 'PR' },
  { name: 'qatar', value: 'QA' },
  { name: 'reunion', value: 'RE' },
  { name: 'romania', value: 'RO' },
  { name: 'russia', value: 'RU' },
  { name: 'rwanda', value: 'RW' },
  { name: 'saint-helena', value: 'SH' },
  { name: 'saints-kitts-nevis', value: 'KN' },
  { name: 'saint-lucia', value: 'LC' },
  { name: 'saint-pierre-miquelon', value: 'PM' },
  { name: 'saint-vincent-grenadines', value: 'VC' },
  { name: 'samoa', value: 'WS' },
  { name: 'san-marino', value: 'SM' },
  { name: 'sao-tome-principe', value: 'ST' },
  { name: 'saudi-arabia', value: 'SA' },
  { name: 'senegal', value: 'SN' },
  { name: 'serbia-montenegro', value: 'CS' },
  { name: 'seychelles', value: 'SC' },
  { name: 'sierra-leone', value: 'SL' },
  { name: 'singapore', value: 'SG' },
  { name: 'slovakia', value: 'SK' },
  { name: 'slovenia', value: 'SI' },
  { name: 'solomon-islands', value: 'SB' },
  { name: 'somalia', value: 'SO' },
  { name: 'south-africa', value: 'ZA' },
  { name: 'south-georgia-islands', value: 'GS' },
  { name: 'spain', value: 'ES' },
  { name: 'sri-lanka', value: 'LK' },
  { name: 'sudan', value: 'SD' },
  { name: 'suriname', value: 'SR' },
  { name: 'svalbard', value: 'SJ' },
  { name: 'swaziland', value: 'SZ' },
  { name: 'sweden', value: 'SE' },
  { name: 'switzerland', value: 'CH' },
  { name: 'syrian-republic', value: 'SY' },
  { name: 'taiwan', value: 'TW' },
  { name: 'tajikistan', value: 'TJ' },
  { name: 'tanzania', value: 'TZ' },
  { name: 'thailand', value: 'TH' },
  { name: 'timor-leste', value: 'TL' },
  { name: 'togo', value: 'TG' },
  { name: 'tokelau', value: 'TK' },
  { name: 'tonga', value: 'TO' },
  { name: 'trinidad-and-tobago', value: 'TT' },
  { name: 'tunisia', value: 'TN' },
  { name: 'turkey', value: 'TR' },
  { name: 'turkmenistan', value: 'TM' },
  { name: 'turks-and-caicos', value: 'TC' },
  { name: 'tuvalu', value: 'TV' },
  { name: 'uganda', value: 'UG' },
  { name: 'ukraine', value: 'UA' },
  { name: 'united-arab-emirates', value: 'AE' },
  { name: 'united-states-islands', value: 'UM' },
  { name: 'uruguay', value: 'UY' },
  { name: 'uzbekistan', value: 'UZ' },
  { name: 'vanuatu', value: 'VU' },
  { name: 'venezuela', value: 'VE' },
  { name: 'vietnam', value: 'VN' },
  { name: 'british-virgin-islands', value: 'VG' },
  { name: 'us-virgin-islands', value: 'VI' },
  { name: 'wallis-and-futuna', value: 'WF' },
  { name: 'western-sahara', value: 'EH' },
  { name: 'yemen', value: 'YE' },
  { name: 'zambia', value: 'ZM' },
  { name: 'zimbabwe', value: 'ZW' },
];

export default countries;
