/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import worldLow from '@amcharts/amcharts5-geodata/json/worldLow.json';
// import countries from '@amcharts/amcharts5-geodata/data/countries';
import ckmeans from 'ckmeans';
import { mapMapper } from '../../../../helpers/helpers';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const Map = ({ buckets, pending }) => {
  const [currentChart, setCurrentChart] = useState(null);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const drawChart = () => {
    if (currentChart) {
      currentChart.dispose();
    }
    am5.addLicense('AM5C316073875');
    am5.addLicense('AM5M316073875');
    const root = am5.Root.new('chartMap');

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const topBucket = buckets[0];
    const topFeature = worldLow.features.find((f) => f.properties.name.toUpperCase() === topBucket.key);

    let lon = 10;
    let lat = 52;

    if (topFeature) {
      if (topFeature.properties.name.toUpperCase() === 'UNITED STATES') {
        lon = -98;
        lat = 38;
      } else {
        if (topFeature.geometry.type === 'Polygon') {
          const mid = Math.floor(topFeature.geometry.coordinates.length / 2);

          lon = topFeature.geometry.coordinates[mid][0][0];
          lat = topFeature.geometry.coordinates[mid][0][1];
        }
        if (topFeature.geometry.type === 'MultiPolygon') {
          const mid = Math.floor(topFeature.geometry.coordinates.length / 2);

          lon = topFeature.geometry.coordinates[mid][0][0][0];
          lat = topFeature.geometry.coordinates[mid][0][0][1];
        }
      }
    }

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        wheelY: 'none',
        wheelX: 'none',
        homeZoomLevel: 8,
        homeGeoPoint: { longitude: lon, latitude: lat },
      }),
    );

    const data = [];
    let clusteredData = [];
    const values = [];
    if (buckets) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < worldLow.features.length; i++) {
        const feature = worldLow.features[i];
        const match = buckets.find((b) => mapMapper(b.key) === feature.properties.name.toLowerCase());

        if (match) {
          data.push({
            id: feature.id,
            value: match?.WriterValues?.value || 0,
          });
          values.push(match?.WriterValues?.value || 0);
        } else {
          data.push({
            id: feature.id,
            value: 0,
          });
          values.push(0);
        }
      }
    }

    // Create polygon series
    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: worldLow,
        valueField: 'value',
        calculateAggregates: true,
      }),
    );

    polygonSeries.data.setAll(data);

    if (data.length && values.length) {
      clusteredData = ckmeans(values, 21);
    }

    polygonSeries.set('heatRules', [{
      target: polygonSeries.mapPolygons.template,
      dataField: 'value',
      customFunction(sprite) {
        const colors = [
          0xE9E8E8,
          0x44AAB0,
          0x48AEB4,
          0x4BB1B7,
          0x4DB3B9,
          0x4EB4BA,
          0x52B8BE,
          0x53B9BF,
          0x56BCC2,
          0x59BFC5,
          0x5DC3C9,
          0x5FC5CB,
          0x62C8CE,
          0x64CAD0,
          0x67CDD3,
          0x67CDD3,
          0x69CFD5,
          0x6DD3D9,
          0x6FD5DB,
          0x74D6DC,
          0x81D7DC,
        ];

        const newVal = sprite?.dataItem?.dataContext?.value || 0;

        let colorCount = 20;
        for (let index = 0; index < 21; index++) {
          if (index < 20 && newVal >= clusteredData[index] && newVal < clusteredData[index + 1]) {
            colorCount = index;
          }
        }

        sprite.set('fill', am5.color(colors[colorCount]));
      },
    }]);

    // chart.set('zoomControl', am5map.ZoomControl.new(root, {}));

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}{id}',
      interactive: true,
      stroke: am5.color(0x555555), 
      strokeWidth: 0.7,
    });

    polygonSeries.events.on('datavalidated', () => {
      chart.goHome();
    });

    setCurrentChart(root);
  };

  useEffect(() => {
    if (!pending && buckets) {
      drawChart();
    // remember to dispose
    }
    return () => {
      if (currentChart) {
        currentChart.dispose();
      }
    };
  }, [buckets, pending]);

  return (<div id="chartMap" style={{ background: isTunecore ? '#555555' : 'white', width: '100%', height: '100px' }} />
  );
};

export default Map;
