/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import Spacer from "../../components/spacer";
import SmallButton from "../../components/smallButton";
import AppState from "../../store/appstate";
import BackButton from "../../components/backButton";
import { getUserMeta } from "../../api/queries";
import AuthPrimaryButton from "../../components/authPrimaryButton";
import useDomainConfigAuth from "../../api/sanity/queries/getSiteConfigWithDomain";

function AddAccount6() {
	const { t } = useTranslation("onboarding");
	const history = useHistory();
	const twitterResult = AppState.useState((s) => s.addAccount.twitter);
	const facebookResult = AppState.useState((s) => s.addAccount.facebook);
	const instagramResult = AppState.useState((s) => s.addAccount.instagram);
	const userMeta = getUserMeta();
	const [pending, setPending] = useState(false);
	const { data: domainConfig } = useDomainConfigAuth();

	if (domainConfig.hide_socials) {
		history.push("/add-account-final");
	}

	let title = t("onboarding:connect-social-header1");
	switch (userMeta?.data?.role) {
		case "Manager":
			title = t("onboarding:connect-social-header2");
			break;
		default:
			break;
	}
	return (
		<div className='container mx-auto'>
			<div className='flex flex-col items-center justify-center p-4 text-center text-white min-h-screen-minus-footer'>
				<h1 className='text-6xl'>{title}</h1>
				<Spacer />

				<div className='flex flex-row flex-wrap justify-center gap-6'>
					{!instagramResult && (
						<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
							<p className='mb-2 text-4xl'>
								<FaInstagram />
							</p>
							<p>{t("onboarding:_instagram")}</p>
							<Spacer />
							<SmallButton onClick={() => history.push("/add-instagram")}>
								{t("onboarding:add-account-button")}
							</SmallButton>
						</div>
					)}
					{!!instagramResult && (
						<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
							<p style={{ fontSize: "80px" }} className='mb-4'>
								<FaInstagram />
							</p>
							<p className='mb-4 font-bold'>{instagramResult}</p>
							<SmallButton onClick={() => history.push("/add-instagram")}>
								{t("onboarding:edit-instagram")}
							</SmallButton>
						</div>
					)}
					{!facebookResult && (
						<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
							<p className='mb-2 text-4xl'>
								<FaFacebook />
							</p>
							<p>{t("onboarding:_facebook")}</p>
							<Spacer />
							<SmallButton onClick={() => history.push("/add-facebook")}>
								{t("onboarding:add-account-button")}
							</SmallButton>
						</div>
					)}
					{!!facebookResult && (
						<div className='flex flex-col items-center p-4 px-6 mb-4 bg-gray-300 rounded-lg shadow-lg text-old-sentric-dark-gray'>
							<p style={{ fontSize: "80px" }} className='mb-4'>
								<FaFacebook />
							</p>
							<p className='mb-4 font-bold'>
								{facebookResult.replace("https://www.facebook.com/", "")}
							</p>
							<SmallButton onClick={() => history.push("/add-facebook")}>
								{t("onboarding:edit-facebook")}
							</SmallButton>
						</div>
					)}

					{!twitterResult && (
						<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
							<p className='mb-2 text-4xl'>
								<FaTwitter />
							</p>
							<p>{t("onboarding:_twitter")}</p>
							<Spacer />
							<SmallButton onClick={() => history.push("/add-twitter")}>
								{t("onboarding:add-account-button")}
							</SmallButton>
						</div>
					)}
					{!!twitterResult && (
						<div className='flex flex-col items-center p-4 px-6 bg-white rounded-lg shadow-lg bg-opacity-80 text-old-sentric-dark-gray'>
							<p style={{ fontSize: "80px" }} className='mb-4'>
								<FaTwitter />
							</p>
							<p className='mb-4 font-bold'>{twitterResult}</p>
							<SmallButton onClick={() => history.push("/add-twitter")}>
								{t("onboarding:edit-twitter")}
							</SmallButton>
						</div>
					)}
					{/* {!!twitterResult
            && (
              <>
                <div className="flex flex-col items-center p-4 px-6 mb-4 ml-2 mr-2 bg-gray-300 rounded-lg shadow-lg text-old-sentric-dark-gray">
                  {JSON.parse(twitterResult).data.profile_image_url && JSON.parse(twitterResult).data.profile_image_url.length > 0
                     && (
                     <img
                       className="object-cover w-20 h-20 mb-4 rounded-full"
                       alt={JSON.parse(twitterResult).data.name}
                       src={JSON.parse(twitterResult).data.profile_image_url}
                     />
                     )}
                  {(!JSON.parse(twitterResult).data.profile_image_url)
                    && <div className="w-20 h-20 mb-4 bg-gray-300 rounded-full" />}
                  <p className="mb-4 font-bold">{JSON.parse(twitterResult).data.name}</p>
                  <SmallButton onClick={() => history.push('/add-twitter')}>{t('onboarding:edit-twitter')}</SmallButton>
                </div>

              </>
            )} */}
				</div>

				<Spacer />

				<div className='flex flex-row w-full lg:w-1/3 justify-evenly'>
					<BackButton onClick={() => history.push("/add-account-4")}>
						{t("onboarding:back-button")}
					</BackButton>
					<AuthPrimaryButton
						pending={pending}
						onClick={() =>
							history.push("/add-account-final") && setPending(true)
						}
						arrow
					>
						{t("onboarding:next-step-button")}
					</AuthPrimaryButton>
				</div>
			</div>
		</div>
	);
}

export default AddAccount6;
