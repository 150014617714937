/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import { type } from '@amcharts/amcharts5';
import React, { useEffect, useMemo, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Async } from 'react-select-virtualized';
import { elasticQuery } from '../../../../api/mutations';
import LoadingSmall from '../../../../components/loadingSmall';
import AppState from '../../../../store/appstate';
import { indexReducedPattern } from './constants';
import getColours from '../../../../helpers/getColours';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function RoyaltiesFilter({ matchAccounts }) {
  const { t } = useTranslation('other');
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState(null);
  const [artistAccountsResult, setArtistAccountsResult] = useState(null);
  const [writersResult, setWritersResult] = useState(null);
  const [songsResult, setSongsResult] = useState(null);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const { highlightColour } = getColours();

  const highlight = isTunecore ? '#333333' : highlightColour;

  const filters = AppState.useState((s) => s.royaltiesFilter);

  const elasticQuerySearch = elasticQuery();

  const search = useMemo(() => (term, callback) => {
    const querySearch = {
      index: indexReducedPattern,
      body: {
        query: {
          bool: {
            must: {
              multi_match: {
                query: `*${term}*`,
                fields: ['artistAccountName', 'writerLastName', 'writerFirstName', 'songTitle'],
              },
            },
            filter: [
              {
                terms: {
                  artistAccountCode: matchAccounts,
                },
              },
            ],

          },

        },
        collapse: {
          field: 'songCode',
        },
        highlight: {
          fields: {
            artistAccountName: {},
            writerLastName: {},
            writerFirstName: {},
            songTitle: {},
          },
        },

      },
      size: 50,
    };

    elasticQuerySearch.mutate(querySearch, {
      onSuccess: (data) => {
        let j = 0;
        const results = [];
        while (j < data.body.hits.hits.length) {
          const item = data.body.hits.hits[j];

          if (item.highlight.songTitle && !results.find((r) => r.value === item._source.songCode)) {
            const obj = {
              label: `${item._source.songTitle} - ${item._source.artistAccountName}`,
              value: item._source.songCode,
              type: 'Song',
            };
            results.push(obj);
          }

          if (item.highlight.artistAccountName && !results.find((r) => r.value === item._source.artistAccountCode)) {
            const obj = {
              label: `${item._source.artistAccountName}`,
              value: item._source.artistAccountCode,
              type: 'Account',
            };
            results.push(obj);
          }

          if ((item.highlight.writerFirstName || item.highlight.writerLastName) && !results.find((r) => r.value === item._source.writerCode)) {
            const obj = {
              label: `${item._source.writerFirstName} ${item._source.writerMiddleName || ''} ${item._source.writerLastName}`,
              value: item._source.writerCode,
              type: 'Writer',
            };
            results.push(obj);
          }

          j++;
        }
        console.log('CALLBACK', results);
        callback(results);
      },
    });
  }, []);

  useEffect(() => {
    if (songsResult && writersResult && artistAccountsResult) {
      setResult([...artistAccountsResult, ...songsResult, ...writersResult]);
      setPending(false);
    }
  }, [songsResult, writersResult, artistAccountsResult]);

  const addFilter = (item) => {
    if (!item) {
      return;
    }

    let items = filters ? JSON.parse(JSON.stringify(filters)) : [];
    items = items.filter((i) => i.value !== item.value);

    items.push(item);
    console.log(item);
    AppState.update((s) => {
      s.royaltiesFilter = items;
    });
  };

  const removeFilter = (value) => {
    console.log(value);
    if (!value) {
      return;
    }
    console.log(filters);

    let items = filters ? JSON.parse(JSON.stringify(filters)) : [];
    items = items.filter((i) => i.value !== value);
    AppState.update((s) => {
      s.royaltiesFilter = items;
    });
  };

  return (
    <>
      <div>
        <Async
          id="txtSearchAccountWriterSong"
          debounce={1000}
          className="remove-input-txt-border w-full text-old-sentric-dark-gray text-sm focus:outline-none z-20"
          name="filterSeach"
          placeholder={t('other:royalties-search-placeholder')}
          value={null}
          onChange={(selected) => {
            addFilter(selected);
          }}
          styles={{
            control: (base) => ({
              ...base,
              borderWidth: isTunecore ? 2 : 0,
              borderColor: '#cccccc !important',
              // This line disable the blue border
              // boxShadow: 'none',
              backgroundColor: isTunecore ? '#333333' : '#FFF',
            }),
            input: (base) => ({
              border: 0,
              color: isTunecore ? 'white' : '',
            }),
          }}
          loadOptions={search}
          search
          formatOptionLabel={(option) => (
            <div>
              <span className="text-xs bg-old-sentric-border-gray p-1 mr-2 rounded">
                {option.type}
              </span>
              {option.label}
            </div>
          )}
        />
      </div>
      <div className='text-white mt-4 mb-4 flex flex-wrap'>
        {filters.map((f) => (
          <div
            style={{ backgroundColor: highlight }}
            className="flex p-2 rounded-lg mb-2 mr-2 items-center"
          >
            <span className="text-xs bg-old-sentric-border-gray p-1 mr-2 rounded text-black">
              {f.type}
            </span>
            <span className="text-sm">{f.label}</span>
            <button
              className=" focus:outline-none ml-4 p-1 border-transparent bg-transparent text-xs flex items-center"
              type="button"
              onClick={() => removeFilter(f.value)}
            >
              <FaTimes />
            </button>
          </div>
        ))}
      </div>
    </>
  );
}
export default RoyaltiesFilter;
