import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import qs from "qs";
import validator from "email-validator";
import FormError from "../../components/formError";
import Input from "../../components/input";
import Spacer from "../../components/spacer";
import { signup } from "../../api/mutations";
import ButtonLink from "../../components/buttonLink";
import useSiteConfigAuth from "../../api/sanity/queries/getSiteConfigWithCode";
import useSiteConfigUnAuth from "../../api/sanity/queries/getSiteConfigWithDomain";
import AuthPrimaryButton from "../../components/authPrimaryButton";

// const qs = require('qs');

// const validator = require('email-validator');

function SignUp() {
	const { t } = useTranslation();
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [mutationErrorMessage, setMutationErrorMessage] = useState("");
	const [validationErrorMessage, setValidationErrorMessage] = useState("");
	const [valid, setValid] = useState(false);
	const [pending, setPending] = useState(false);

	const [errors, setErrors] = useState({});
	const history = useHistory();
	const location = useLocation();
	const { data: domainConfig } = useSiteConfigUnAuth();
	const signUpMutation = signup();
	const cookies = new Cookies();

	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	console.log(isTunecore);

	let searchObj = null;

	if (location.search) {
		searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });
		cookies.set("s-ref", searchObj?.ref, { path: "/" });
	}

	const signUp = async () => {
		setValid(false);
		setMutationErrorMessage("");
		setValidationErrorMessage("");
		setPending(true);
		const data = {
			firstname: firstName,
			lastname: lastName,
			email,
			returnurl: `${window.location.protocol}//${window.location.host}/confirm`,
		};

		signUpMutation.reset();

		signUpMutation.mutate(data, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
					history.push("/sign-up-continue");
					setPending(false);
				}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setValid(true);
				setPending(false);
			},
		});
	};

	const checkValid = (passed = {}) => {
		let val = true;

		// email check
		const validEmail = validator.validate(passed.email || email);
		if (!validEmail) {
			val = false;
		}

		// firstname check
		const name = passed.firstName || firstName;
		const validFirstName = name.trim().length > 0;
		if (!validFirstName) {
			val = false;
		}

		// lastname check
		const lname = passed.lastName || lastName;
		const validLastName = lname.trim().length > 0;
		if (!validLastName) {
			val = false;
		}

		setValid(val);
	};

	const checkErrors = () => {
		const errs = {};

		// email check
		if (email.trim().length > 0) {
			const validEmail = validator.validate(email);
			if (!validEmail) {
				errs.email = t("regex:invalid-email-error");
			}
		}

		// first name check
		if (firstName.trim().length > 0) {
			const validFirstName = firstName.trim().length > 0;
			if (!validFirstName) {
				errs.firstName = t("regex:enter-first-name-check");
			}
		}

		// last name check
		if (lastName.trim().length > 0) {
			const validLastName = lastName.trim().length > 0;
			if (!validLastName) {
				errs.lastName = t("regex:enter-last-name-check");
			}
		}

		setErrors(errs);
	};

	return (
		<div>
			<h1 className='text-6xl'>
				{t("auth:join-header")} {domainConfig?.display_name}
			</h1>
			<div className='w-full text-old-sentric-dark-gray'>
				<Spacer />
				<Input
					id='txtSignupEmail'
					className='auth-page-input'
					whiteLabelText
					error={errors.email}
					placeholder={t("auth:email-placeholder2")}
					value={email}
					onBlur={() => checkErrors()}
					onChange={(e) => {
						setEmail(e.target.value);
						checkValid({ email: e.target.value });
					}}
				/>
				<Spacer />
				<Input
					id='txtSignupFirstName'
					whiteLabelText
					className='auth-page-input'
					error={errors.firstName}
					type='text'
					placeholder={t("auth:first-name")}
					onBlur={() => checkErrors()}
					value={firstName}
					onChange={(e) => {
						setFirstName(e.target.value);
						checkValid({ firstName: e.target.value });
					}}
				/>
				<Spacer />
				<Input
					className='auth-page-input'
					whiteLabelText
					id='txtSignupLastName'
					error={errors.lastName}
					type='text'
					placeholder={t("auth:last-name")}
					value={lastName}
					onBlur={() => checkErrors()}
					onChange={(e) => {
						setLastName(e.target.value);
						checkValid({ lastName: e.target.value });
					}}
				/>
				<Spacer />
				<FormError
					mutationError={mutationErrorMessage}
					validationError={validationErrorMessage}
				/>
				<div className='w-full flex justify-center'>
					<AuthPrimaryButton
						id='btnSignupNext'
						disabled={!valid}
						pending={pending}
						onClick={signUp}
					>
						{t("common:next-button")}
					</AuthPrimaryButton>
				</div>
			</div>
			<Spacer />
			<div className='flex justify-between'>
				<ButtonLink
					id='btnSignupLogin'
					className='mr-8'
					onClick={() => history.push("/login")}
				>
					{t("common:login-link")}
				</ButtonLink>
				<ButtonLink
					id='btnSignupForgotPassword'
					onClick={() => history.push("/reset-password")}
				>
					{t("auth:forgot-password")}
				</ButtonLink>
			</div>
		</div>
	);
}

export default SignUp;
