/* eslint-disable no-nested-ternary */
import { useRef, useEffect } from "react";
import { FaLock, FaArrowRight } from "react-icons/fa";
import ButtonIcon from "../images/rebrandIcons/ButtonIcon.png";
import LoadingSmall from "./loadingSmall";
import useDomainConfigAuth from '../api/sanity/queries/getSiteConfigWithDomain';
import getColours from "../helpers/getColours";

function AuthPrimaryButton(props) {
  const {
    onClick,
    id,
    disabled,
    children,
    label,
    textOnly,
    pending,
    arrow,
    className, // Adding className prop
  } = props;
  const { data: domainConfig } = useDomainConfigAuth();
  const isTunecore = domainConfig?.display_name === "TuneCore";
  const { onboardingHighlightColour } = getColours(domainConfig);

  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  }, [pending, disabled]);

  const handleClick = (e) => {
    onClick(e);
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  };

  return (
    <>
      {!isTunecore ? (
        <button
          ref={buttonRef}
          type="button"
          disabled={disabled}
          pending={pending}
          onClick={handleClick}
          style={{
            backgroundColor: onboardingHighlightColour,
          }}
          id={id}
          className={`focus:ring-2 focus:ring-white focus:scale-105 w-1/3 p-3 mb-1 text-sm font-medium tracking-tight uppercase transition-all duration-150 ease-linear rounded-lg shadow outline-none disabled:opacity-25 disabled:pointer-events-none text-sentric-rebrand-dark-grey font-sentric-alt x-8 hover:shadow-md disabled:shadow-none hover:scale-105 ${className}`} // Adding the className to the button
        >
          <div className={`flex items-center ${textOnly ? 'justify-center' : 'justify-between'}`}>
            {children}
            <p>{label}</p>
            {disabled ? (
              <FaLock className="h-2.5 inline ml-3" />
            ) : pending ? (
              <div className="h-2.5 ml-3">
                <LoadingSmall className="inline animate-spin text-black" />
              </div>
            ) : !pending && !disabled && arrow ? (
              <img
                className="h-2.5 ml-3"
                src={ButtonIcon}
                alt="Button Arrow Icon"
              />
            ) : null}
          </div>
        </button>
      ) : (
        <button
          ref={buttonRef}
          type="button"
          id={id}
          disabled={disabled}
          pending={pending}
          onClick={handleClick}
          style={{
            background: disabled
              ? 'linear-gradient(to bottom right, rgba(0, 239, 134, 0.5), rgba(0, 142, 185, 0.5))'
              : 'linear-gradient(to bottom right, #00ef86, #008eb9)',
            borderRadius: '50px',
            padding: '12px 20px',
          }}
          className={`hover:scale-105 w-1/3 p-3 mb-1 text-sm font-medium tracking-tight text-white uppercase transition-all duration-150 ease-linear rounded-lg shadow outline-none disabled:opacity-25 disabled:pointer-events-none font-sentric-alt x-8 hover:shadow-md disabled:shadow-none focus:outline-none tunecore-button-primary ${className}`} // Adding the className to the button
        >
          <div className="flex items-center justify-between">
            {children}
            <p>{label}</p>
            {disabled ? (
              <FaLock className="h-2.5 inline ml-3" />
            ) : pending ? (
              <div className="h-2.5 ml-3">
                <LoadingSmall className="inline animate-spin" />
              </div>
            ) : arrow ? (<FaArrowRight />) : null}
          </div>
        </button>
      )}
    </>
  );
}

export default AuthPrimaryButton;
