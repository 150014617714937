import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import H2 from "../../../../components/h2";
import Spacer from "../../../../components/spacer";
import { getMyAccount, getMyWriters } from "../../../../api/queries";
import AppState from "../../../../store/appstate";
import LoadingSmall from "../../../../components/loadingSmall";
import WritersTable from "./WritersTable";
import { hasAdminRights } from "../../../../auth/shared";
import RebrandButtonLink from "../../../../components/rebrandButtonLink";
import getColours from "../../../../helpers/getColours";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function Writers() {
	const { t } = useTranslation("writers");
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const history = useHistory();
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});

	const writers = getMyWriters({ aac: selectedAccount });

	const { highlightColour } = getColours();

	const adminRights = hasAdminRights(myAccount?.data);

	useEffect(() => {
		if (selectedAccount) {
			writers.refetch();
		}
	}, [selectedAccount]);

	return (
		<div className='flex flex-wrap'>
			<div className='w-full lg:min-h-screen-minus-header'>
				<div className='mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12'>
					<div className='flex flex-wrap justify-between items-baseline'>
						<div>
							<H2>{t("writers:writers-header")}</H2>
						</div>
						<div>
							<RebrandButtonLink
								plusIcon
								id='btnWriterAddNew'
								onClick={
									adminRights
										? () => history.push("/writers/create")
										: undefined
								}
							>
								{t("writers:add-new-writer-button2")}
							</RebrandButtonLink>
						</div>
					</div>
					<Spacer />
					<div
						className={`${
							isTunecore ? "bg-tunecore-dark-element" : " bg-white"
						} rounded-lg p-4 lg:p-8`}
					>
						{!writers.isSuccess && <LoadingSmall />}
						{writers.isSuccess && writers?.data?.writers && (
							<WritersTable
								highlightColour={highlightColour}
								data={writers?.data?.writers}
							/>
						)}
					</div>
					<Spacer />
				</div>
			</div>
		</div>
	);
}
export default Writers;
