/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { elasticQuery } from '../../../../../api/mutations';
import LoadingSmall from '../../../../../components/loadingSmall';
import Spacer from '../../../../../components/spacer';
import AppState from '../../../../../store/appstate';
import CumulativeGraph from '../_cumulativeGraph';
import MapSelector from '../_mapSelector';
import Tabs from '../_tabs';
import TableAndPie from '../_tableAndPie';
import { buildFilters, twoDp } from '../../../../../helpers/helpers';
import { indexReducedPattern } from '../constants';

const SongReveal = ({
  row,
  noMap = false,
  passRegion,
  dateType,
  matchAccounts,
}) => {
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState(null);
  const [region, setRegion] = useState(passRegion || 'Global');
  const [regionPending, setRegionPending] = useState(true);
  const [regionResult, setRegionResult] = useState(null);
  const flags = useFlags();
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);

  const range = AppState.useState((s) => s.range);

  const songCode = row?.original?.key;

  const { t } = useTranslation('other');
  let filter = [
    {
      bool: {
        should: [
          {
            match_phrase: {
              songCode,
            },
          },
        ],
        minimum_should_match: 1,
      },
    },
    {
      range: { [dateType]: range[dateType] },
    },
    ...buildFilters(flags, matchAccounts),
  ];

  if (region !== 'Global') {
    filter = [
      {
        bool: {
          should: [
            {
              match_phrase: {
                songCode,
              },
            },
            {
              match_phrase: {
                territoryName: region,
              },
            },
          ],
          minimum_should_match: 2,
        },
      },
      {
        range: { [dateType]: range[dateType] },
      },
      ...buildFilters(flags, matchAccounts),
    ];
  }

  const query = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter,
        },
      },
      aggs: {
        WriterPaymentSum: {
          sum: {
            field: 'ownerWriterPayment',
          },
        },
        Writers: {
          terms: {
            field: 'writerCode',
            size: 7,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            WriterName: {
              top_hits: {
                size: 1,
                _source: {
                  includes: [
                    'writerLastName',
                    'writerFirstName',
                    'writerMiddleName',
                  ],
                },
              },
            },
          },
        },
        Territories: {
          terms: {
            field: 'territoryName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        CumulativeSumHistogram: {
          date_histogram: {
            field: dateType,
            calendar_interval: '1M',
            time_zone: 'Europe/London',
            min_doc_count: 0,
          },
          aggs: {
            CumulativeSumPipeline: {
              cumulative_sum: {
                buckets_path: 'CumulativeSum',
              },
            },
            CumulativeSum: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        IncomeTypes: {
          terms: {
            field: 'incomeType.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        Usage: {
          terms: {
            field: 'usage.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        Source: {
          terms: {
            field: 'sourceName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
            IncomeType: {
              top_hits: {
                size: 10,
                _source: {
                  includes: ['incomeType'],
                },
              },
            },
          },
        },
      },
    },
    size: 0,
  };

  const mapQuery = {
    index: indexReducedPattern,
    body: {
      query: {
        bool: {
          filter: [
            {
              bool: {
                should: [
                  {
                    match_phrase: {
                      songCode,
                    },
                  },
                ],
                minimum_should_match: 1,
              },
            },
            {
              range: { [dateType]: range[dateType] },
            },
            ...buildFilters(flags, matchAccounts),
          ],
        },
      },
      aggs: {
        Territories: {
          terms: {
            field: 'territoryName.keyword',
            size: 500,
            order: {
              WriterValues: 'desc',
            },
            missing: 'N/A',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
        NoTerritories: {
          missing: {
            field: 'territoryName.keyword',
          },
          aggs: {
            WriterValues: {
              sum: {
                field: 'ownerWriterPayment',
              },
            },
          },
        },
      },
    },
  };

  const elasticQueryMutation = elasticQuery();
  const elasticQueryMutatio2 = elasticQuery();

  useEffect(() => {
    if (songCode) {
      setPending(true);
      elasticQueryMutatio2.mutate(mapQuery, {
        onSuccess: (data) => {
          setPending(false);
          setResult(data);
        },
        onError: (error) => {
          console.log('map data', error);
          setPending(false);
          setResult(null);
        },
      });
    }
  }, [range, songCode]);

  useEffect(() => {
    if (songCode) {
      // setRegionPending(true);

      elasticQueryMutation.mutate(query, {
        onSuccess: (data) => {
          const newData = data;
          let j = 0;
          let total = 0;
          const items = newData?.body?.aggregations?.Writers?.buckets || [];
          while (j < items.length) {
            const item = items[j];
            total += parseFloat(item.WriterValues.value);
            j++;
          }

          j = 0;
          while (j < items.length) {
            const item = items[j];
            item.total = total;
            item.percent = (parseFloat(item.WriterValues.value) / total) * 100;
            item.key = `${
              item?.WriterName?.hits?.hits?.[0]._source?.writerFirstName
            } ${
              item?.WriterName?.hits?.hits?.[0]._source?.writerMiddleName || ''
            } ${item?.WriterName?.hits?.hits?.[0]._source?.writerLastName}`;
            j++;
          }

          setRegionPending(false);
          setRegionResult(newData);
        },
        onError: (error) => {
          console.log(error);
          setRegionPending(false);
          setRegionResult(null);
        },
      });
    }
  }, [range, songCode, region]);

  const columnsUsage = React.useMemo(
    () => [
      {
        Header: t('common:_name'),
        accessor: 'key', // accessor is the "key" in the data
      },
      {
        Header: t('common:_total'),
        accessor: (r) => r.WriterValues.value,
        Cell: ({ row: { original } }) => (
          <span>{`${currencySymbol}${twoDp(original?.WriterValues?.value)}`}</span>
        ),
      },
    ],
    [],
  );

  const columnsWriterUsage = React.useMemo(
    () => [
      {
        Header: t('common:_name'),
        accessor: 'key',
      },
      {
        Header: t('common:_percentage'),
        accessor: (r) => `${Number(parseFloat(r.percent)).toFixed(2)}%`,
      },
      {
        Header: t('common:_total'),
        accessor: (r) => r.WriterValues.value,
        Cell: ({ row: { original } }) => (
          <span>{`${currencySymbol}${twoDp(original?.WriterValues?.value)}`}</span>
        ),
      },
    ],
    [],
  );

  if (!pending && result) {
    return (
      <td colSpan={10}>
        <div className="rounded-lg lg:p-4 mt-2 mb-2">
          {!noMap && (
            <MapSelector
              setRegion={setRegion}
              songCode={songCode}
              pending={pending}
              buckets={result?.body?.aggregations?.Territories?.buckets || []}
            />
          )}
          {!noMap && <Spacer />}
          {!regionPending && regionResult && (
            <Tabs
              tabs={[
                t('other:cumulative-earnings-tab'),
                t('other:song-earnings-tab'),
                t('other:income-type-earnings-tab'),
                t('other:usage-earnings-tab'),
                t('other:source-earnings-tab'),
              ]}
              objects={[
                <CumulativeGraph
                  unique={`${songCode}SR`}
                  pending={pending}
                  buckets={
                    regionResult?.body?.aggregations?.CumulativeSumHistogram
                      ?.buckets || []
                  }
                />,
                <TableAndPie
                  unique={`${songCode}SongEarningsSR`}
                  pending={pending}
                  buckets={regionResult?.body?.aggregations?.Writers?.buckets || []}
                  columns={columnsWriterUsage}
                />,
                <TableAndPie
                  unique={`${songCode}IncomeTypesSR`}
                  pending={pending}
                  buckets={
                    regionResult?.body?.aggregations?.IncomeTypes?.buckets || []
                  }
                  columns={columnsUsage}
                />,
                <TableAndPie
                  unique={`${songCode}UsageSR`}
                  pending={pending}
                  buckets={regionResult?.body?.aggregations?.Usage?.buckets || []}
                  columns={columnsUsage}
                />,
                <TableAndPie
                  unique={`${songCode}SourceSR`}
                  pending={pending}
                  buckets={regionResult?.body?.aggregations?.Source?.buckets || []}
                  columns={columnsUsage}
                />,
              ]}
            />
          )}
          {regionPending && <LoadingSmall />}
          <Spacer />
        </div>
      </td>
    );
  }

  return (
    <div className="p-4">
      <LoadingSmall />
    </div>
  );
};

export default SongReveal;
