/* eslint-disable no-nested-ternary */
import { matchSorter } from 'match-sorter';
import React, { useEffect, useMemo, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import clsx from 'clsx';
import {
  useAsyncDebounce,
  useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../helpers/useWindowDimensions';
import TablePagination from './tablePagination';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';
import getColours from '../helpers/getColours';

function GlobalFilter({
  area,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchSuffix,
  total,
  paginated,
}) {
  const count = paginated ? total : preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((v) => {
    setGlobalFilter(v || undefined);
  }, 200);
  const { data: siteConfig } = useSiteConfigAuth();
  const { highlightColour2 } = getColours();

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const searchBarClasses = clsx(
    'rounded-lg p-3 w-full pl-10 bg-sentric-rebrand-light-grey border-0 focus:outline-none focus:ring-2',
    {
      'text-black border-0 focus:ring-2': !isTunecore,
      'bg-tunecore-grey-element text-white border-none focus:ring-tunecore-green': isTunecore,
    },
  );
  return (
    <div className={`${isTunecore ? 'tunecore-filter' : ''} relative mr-6 my-2 w-full`}>
      <input
        style={{
          borderColor: isFocused || isHovered ? highlightColour2 : undefined,
          boxShadow: isFocused || isHovered ? `0 0 0 2px ${highlightColour2}` : undefined,
        }}
        id={`txtTableSearch${area}`}
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} ${searchSuffix}...`}
        type="search"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
        }}
        className={searchBarClasses}
      />
      <div className="absolute top-1/3 left-2 pl-2">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8978 15.4244L11.6412 11.0108C12.5387 9.84986 13.0808 8.38022 13.0808 6.77696C13.0764 3.04065 10.1439 0 6.54041 0C2.93696 0 0 3.04065 0 6.78157C0 10.5225 2.93252 13.5631 6.54041 13.5631C7.88226 13.5631 9.1308 13.1393 10.1661 12.4206L14.4804 16.894C14.6182 17.0369 14.8448 17.0369 14.9825 16.894L15.8934 15.9496C16.0311 15.8068 16.0311 15.5718 15.8934 15.429L15.8978 15.4244ZM1.99944 6.78157C1.99944 4.1878 4.03444 2.07317 6.54041 2.07317C9.04638 2.07317 11.0814 4.1832 11.0814 6.78157C11.0814 9.37994 9.04638 11.49 6.54041 11.49C4.03444 11.49 1.99944 9.37994 1.99944 6.78157Z"
            fill={`${isTunecore ? '#FFF' : '#231F20'}`}
            fillOpacity={`${isTunecore ? '0.5' : '0.3'}`}
          />
        </svg>
      </div>
    </div>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter() {
  return null;
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({
  area = '', columns, data, revealComponent, highlightColour, searchSuffix, disableSearch = false, title = null, bgClass = '', min = 10, noneMessage = null, getRowId = null, disablePagination = false, onRowClick = () => {}, paginated = false, setTableState = () => {}, tableState, total = 0, initialSort, currentpage, filter = () => {}, setFilter = () => {},
}) {
  const { t } = useTranslation();

  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';
  const filterTypes = () => ({
    // Add a new fuzzyTextFilterFn filter type.
    fuzzyText: fuzzyTextFilterFn,
    // Or, override the default text filter to use
    // "startWith"
    text: (rows, id, filterValue) => rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
        : true;
    }),
  });

  const defaultColumn = () => ({
    // Let's set up our default Filter UI
    Filter: DefaultColumnFilter,
  });

  const nextPageState = () => {
    const p = tableState.page;
    setTableState('page', p + 1);
  };

  const setPageSizeState = (size) => {
    setTableState('perPage', size);
  };

  const prevPageState = () => {
    const p = tableState.page;
    setTableState('page', p - 1);
  };

  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex, pageSize, globalFilter, sortBy,
    },

  } = useTable(
    {
      columns,
      data,
      getRowId: getRowId || React.useCallback((row, index) => (index), []),
      autoResetPage: false,
      autoResetExpanded: false,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageIndex: currentpage || 0,
        pageSize: min,
        sortBy: useMemo(() => initialSort || [{
          id: 'title',
          desc: false,
        }], []),
        hiddenColumns: ['colGigId', 'colSetlistId'],
      },
      manualPagination: paginated,
      manualSortBy: paginated,

    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useExpanded,
    usePagination,

  );
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (paginated) {
      setTableState('sortBy', sortBy);
    }
  }, [sortBy]);

  // useEffect(() => {
  //   if (sortBy !== initialSort) {
  //     onSort(sortBy);
  //     setTableState('page', 1);
  //   }
  // }, [sortBy]);


  return (
    <div>
      {!disableSearch
      && (
      <div className="mb-5">
        <GlobalFilter
          area={area}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={paginated ? filter : globalFilter}
          setGlobalFilter={paginated ? setFilter : setGlobalFilter}
          searchSuffix={searchSuffix}
          total={total}
          paginated={paginated}
        />
      </div>
      )}
      {!!title
        && (
        <div className="w-full lg:w-1/2 flex items-center mb-4">
          <h2 className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} font-semibold text-lg`}>{title}</h2>
          <div className={`ml-2 mr-2 inline-block h-5 text-xxs py-0 px-1 rounded uppercase bg-opacity-25 ${isTunecore ? 'bg-white text-white' : 'bg-old-song-sub-color text-old-song-sub-color'}`}>
            {data?.length || 0}
          </div>
        </div>
        )}

      <div className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} ${bgClass}`}>
        {width > 600
        && (
        <table className={`${isTunecore ? 'divide-y divide-tunecore-black' : 'divide-y divide-gray-200'} min-w-full text-sm`} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={`p-2 text-left cursor-pointer ${isTunecore ? 'text-white' : 'text-sm text-sentric-rebrand-table-header-grey'} font-semibold uppercase tracking-wider `}
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      style: { minWidth: column.width, width: column.width, maxWidth: column.width },
                      id: column.id,
                    })}
                  >
                    <span className={`${column.canSort ? 'underline' : ''}`}>
                      {column.render('Header')}
                    </span>
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <FaChevronDown className="inline-block ml-1" />
                          : <FaChevronUp className="inline-block ml-1" />
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={`${isTunecore ? 'bg-transparent divide-y divide-tunecore-black' : 'bg-white divide-y divide-old-sentric-border-gray'} w-full font-semibold`} {...getTableBodyProps()}>
            {page.length > 0 && page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <tr
                    onClick={() => onRowClick(row)}
                    {...(revealComponent ? row.getToggleRowExpandedProps() : [])}
                    className={`cursor-pointer ${isTunecore ? 'hover:bg-tunecore-black' : 'hover:bg-sentric-rebrand-light-grey'}`}
                    {...row.getRowProps()}
                    id={`btn${area}Row`}
                  >
                    {row.cells.map((cell) => (
                      <td
                        className='whitespace-wrap px-2 py-4 pl-2 pr-0'
                        {...cell.getCellProps()}
                      >
                        <div className="truncate whitespace-normal">
                          {cell.render('Cell')}
                        </div>
                      </td>
                    ))}
                  </tr>
                  {row.isExpanded && revealComponent
                  && <>{React.cloneElement(revealComponent, { row })}</>}
                </React.Fragment>
              );
            })}
            {page.length === 0
            && (
              <tr>
                <td colSpan="2">
                  <div className="p-4 w-full">
                    {!noneMessage
                    && (
                    <p>
                      {t('common:table-there-are-no')}
                      {' '}
                      {searchSuffix}
                      .
                    </p>
                    )}
                    {!!noneMessage
                    && (
                    <p>
                      {noneMessage}
                    </p>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        )}
        {width < 600 && (
          <table className="w-full divide-y divide-gray-200 text-sm">
            <tbody className={`${isTunecore ? 'bg-transparent divide-y divide-tunecore-black' : 'bg-white divide-y divide-old-sentric-border-gray'} flex flex-wrap w-full font-semibold `}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.getRowProps().key}>
                    <tr
                      onClick={() => onRowClick(row)}
                      {...(revealComponent ? row.getToggleRowExpandedProps() : [])}
                      className={`${isTunecore && revealComponent ? 'hover:bg-tunecore-black' : revealComponent ? 'hover:bg-old-sentric-menu-gray' : ''} w-full flex`}
                    >
                      <td colSpan="10" className="w-full my-3 relative flex flex-wrap">
                        {row.cells.map((cell) => {
                          const headerContent = typeof cell.column.Header === 'function'
                            ? cell.column.Header(row) // Call the function to get the JSX element, (this gets rid of React warning that was freezing my local - MI)
                            : cell.column.Header; // Otherwise, it's a simple string, use as it is
                          return (
                            <div
                              {...cell.getCellProps()}
                              className="w-full p-1 my-1"
                            >
                              {cell.column.id !== 'expander' && (
                                <div className="flex w-full flex-row justify-between">
                                  <div className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-gray'} sm:w-3/12 w-5/12 text-xxs font-semibold  uppercase tracking-wider`}>
                                    {headerContent}
                                  </div>
                                  <div className="flex truncate whitespace-normal w-6/12">
                                    {cell.render('Cell')}
                                  </div>
                                </div>
                              )}
                              {cell.column.id === 'expander' && (
                                <div className="absolute top-2 right-4">
                                  {cell.render('Cell')}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                    {row.isExpanded && revealComponent && <>{React.cloneElement(revealComponent, { row })}</>}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        )}

      </div>
      {!disablePagination
      && (
      <div className={`${bgClass} pt-4 pb-4`}>
        <TablePagination
          previousPage={() => (paginated ? prevPageState() : previousPage())}
          canPreviousPage={paginated ? tableState.page !== 1 : canPreviousPage}
          nextPage={() => (paginated ? nextPageState() : nextPage())}
          canNextPage={paginated ? tableState.page !== Math.ceil(total / tableState.perPage) : canNextPage}
          pageSize={paginated ? tableState.perPage : pageSize}
          setPageSize={(size) => (paginated ? setPageSizeState(size) : setPageSize(size))}
          pageIndex={paginated ? tableState.page - 1 : pageIndex}
          totalPages={paginated ? Math.ceil(total / tableState.perPage) : pageOptions.length}
          highlightColour={highlightColour}
          area={area}
        />
      </div>
      )}
    </div>
  );
}

export default Table;
