/* eslint-disable no-console */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import validator from 'email-validator';
import Input from '../../components/input';
import Spacer from '../../components/spacer';
import ButtonLink from '../../components/buttonLink';
import P from '../../components/p';
import FormError from '../../components/formError';
import { resetPassword } from '../../api/mutations';
import AuthPrimaryButton from '../../components/authPrimaryButton';

// const validator = require('email-validator');

function ResetPassword() {
  const { t } = useTranslation();
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [pending, setPending] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [errors, setErrors] = useState({});

  const resetMutation = resetPassword();

  const history = useHistory();

  const checkValid = () => {
    const errs = {};
    let valid = true;
    if (!validator.validate(email1)) {
      errs.email1 = t('regex:invalid-email-error');
      valid = false;
    }

    if (!validator.validate(email2)) {
      errs.email2 = t('regex:invalid-email-error');
      valid = false;
    }

    if (email1 !== email2) {
      errs.email2 = t('regex:non-matching-email-error');
      valid = false;
    }

    setErrors(errs);
    return valid;
  };

  const reset = async () => {
    setMutationErrorMessage('');
    setValidationErrorMessage('');
    setPending(true);

    if (checkValid()) {
      const data = {
        email: email1,
        confirmemail: email2,
        returnurl: `${window.location.protocol}//${window.location.host}/reset`,
      };

      resetMutation.reset();

      resetMutation.mutate(data, {
        onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
          history.push('/reset-password-message');
          setPending(false);
        }
        },
        onError: (error) => {
          setMutationErrorMessage(error.message);
          setPending(false);
        },

      });
    } else {
      setPending(false);
    }
  };

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center p-4 text-center min-h-screen-minus-footer">
          <h1 className="text-6xl">{t('auth:reset-password-header')}</h1>
        <Spacer thin />

        <P>{t('auth:reset-password-text')}</P>
        <div className="w-full lg:w-1/3 text-old-sentric-dark-gray">

          <Spacer />
          <Input
            id="txtResetEmail"
            placeholder={t('auth:email-placeholder')}
            value={email1}
            onChange={(e) => {
              setEmail1(e.target.value);
            }}
            whiteLabelText
            error={errors.email1}
            className='auth-page-input'
          />
          <Spacer />
          <Input
            id="txtResetConfirmEmail"
            placeholder={t('auth:confirm-email-placeholder')}
            whiteLabelText
            value={email2}
            onChange={(e) => {
              setEmail2(e.target.value);
            }}
            error={errors.email2}
            className='auth-page-input'
          />
          <Spacer />
          <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
            <AuthPrimaryButton
              id="btnResetSubmit"
              disabled={pending}
              pending={pending}
              onClick={() => { reset(); }}
              >
              {t('common:submit-button')}
            </AuthPrimaryButton>
        </div>
        <Spacer />
        <div className="flex">
          <ButtonLink id="btnResetLogin" className="mr-8" onClick={() => history.push('/login')}>{t('common:login-link')}</ButtonLink>
          <ButtonLink id="btnResetSignup" onClick={() => history.push('/sign-up')}>{t('common:sign-up-link')}</ButtonLink>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
