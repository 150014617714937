import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

const DateRangePicker = ({
  id,
  dateRange,
  setDateRange,
  isClearable = true,
}) => {
  const [startDate, endDate] = dateRange;
  const { t } = useTranslation();
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  return (
    <DatePicker
      id={id}
      selectsRange
      dateFormatCalendar=' '
      showMonthDropdown
      showYearDropdown
      showPopperArrow={false}
      dropdownMode="select"
      useShortMonthInDropdown
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
      }}
      placeholderText={t('other:date-placeholder')}
      isClearable={isClearable}
      dateFormat="yyyy-MM-dd"
      className={`${isTunecore ? 'text-white bg-tunecore-dark-element font-semibold border-1 border-white' : 'border'} text-xs mr-10 w-full rounded-lg`}
    />
  );
};

export default DateRangePicker;
