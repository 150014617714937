/* eslint-disable no-unused-expressions */
import { logout } from '../helpers/helpers';
import AppState from '../store/appstate';

export const relogin = async () => {
  const apiUrl = AppState.getRawState()?.apiUrl;
  const saauth = AppState.getRawState()?.authentication;

  const details = await fetch(`${apiUrl}/rightsapp/login`, {
    method: 'POST',
    body: JSON.stringify({
      username: saauth.userName,
      password: saauth?.renewal_token ? atob(saauth.renewal_token) : null,
    }),
  })
    .then((res) => res.json())
    .catch(() => {
      logout();
      throw new Error('Request was not authorized.');
    });

  if (details?.access_token) {
    // get artist accounts
    details.renewal_token = saauth.renewal_token;
    AppState.update((s) => { s.authentication = details; });
  }
  throw new Error('Request was not authorized.');
};

export const processRes = async (res, noAuth) => {
  const contentType = res.headers.get('content-type');
  if (res.status === 401 && !noAuth) {
    // try logging in again
    return relogin();
  }
  if ([402, 403, 404, 405, 406, 407, 408, 410, 411, 412].includes(res.status)) {
    let message = '';
    try {
      const data = await res.json();
      message = data.messages.join(', ');
    } catch (e) {
      message = 'A server error has occurred.';
    }
    throw new Error(message);
  }
  if (res.status === 500) {
    throw new Error('A server error has occurred.');
  }
  if (contentType === null) return Promise.resolve(null);
  if (contentType.startsWith('application/json;')) {
    return res.json();
  }
  if (contentType.startsWith('text/plain;')) return res.text();
  throw new Error(`Unsupported response content-type: ${contentType}`);
};

export const apiCall = ({
  endpoint, method, data, path, clientId = false, noAuth = false, skipKycCheck = false,
}) => {
  const apiUrl = AppState.getRawState()?.apiUrl;
  const kyc = AppState.getRawState()?.kyc;

  if (!skipKycCheck && (method === 'put' || method === 'post') && (kyc?.kycrequired && !kyc?.kycpassed)) {
    throw new Error('Please verify your identity to continue making changes to your account');
  }
  const accessToken = AppState.getRawState()?.authentication?.access_token;
  let append = '';
  if (path && path.length) {
    append = `?path=${encodeURIComponent(path)}`;
    if (clientId) {
      append += '&ci=1';
    }
  }
  if (clientId && !path) {
    append = '?ci=1';
  }
  return fetch(`${apiUrl}${endpoint}${append}`,
    {
      method: method || 'get',
      headers: {
        Authorization: (!noAuth && accessToken) ? `Bearer ${accessToken}` : null,
      },
      body: data ? JSON.stringify(data) : null,
    }).then((res) => processRes(res, noAuth));
};

export const apiCallWithFormData = ({
  endpoint, method, data, path, clientId = false, noAuth = false,
}) => {
  const apiUrl = AppState.getRawState()?.apiUrl;
  const accessToken = AppState.getRawState()?.authentication?.access_token;
  const kyc = AppState.getRawState()?.kyc;
  if ((method === 'put' || method === 'post') && (kyc?.kycrequired && !kyc?.kycpassed)) {
    throw new Error('Please verify your identity to continue making changes to your account');
  }
  let append = '';
  if (path && path.length) {
    append = `?path=${encodeURIComponent(path)}`;
    if (clientId) {
      append += '&ci=1';
    }
  }
  if (clientId && !path) {
    append = '?ci=1';
  }
  return fetch(`${apiUrl}${endpoint}${append}`,
    {
      method: method || 'get',
      headers: {
        Authorization: (!noAuth && accessToken) ? `Bearer ${accessToken}` : null,
      },
      body: data,
    })
    .then((res) => processRes(res, noAuth));
};
