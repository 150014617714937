/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function CompontentTabs({ tabs, objects }) {
  const [currentTab, setCurrentTab] = useState(0);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  return (
      <>
        <div className="flex flex-wrap">
          {tabs.map((t, i) => (
            <button
              type="button"
              onClick={() => setCurrentTab(i)}
              key={`tab${i}`}
              className={`w-full focus:outline-none lg:w-1/4 ${i === (tabs.length - 1) ? 'pr-0' : 'pr-1'}`}
            >
              <>
              {!isTunecore ? (
                  <div
                    className={`w-full rounded-t-xl ${
                      currentTab === i
                        ? `bg-gradient-to-b from-sentric-rebrand-very-light-grey to-white tab-shadow z-10`
                        : 'bg-sentric-rebrand-very-light-grey'
                    }`}
                    style={{ height: currentTab === i ? '340px' : '320px', marginTop: currentTab === i ? '0px' : '20px' }}
                  >
                    {t}
                  </div>
                ) : (
                  <div
                    className={`w-full rounded-t-xl ${
                      currentTab === i
                        ? `bg-gradient-to-t from-tunecore-dark-element to-tunecore-light-grey tab-shadow z-10`
                        : 'bg-tunecore-mid-grey'
                    }`}
                    style={{ height: currentTab === i ? '340px' : '320px', marginTop: currentTab === i ? '0px' : '20px' }}
                  >
                    {t}
                  </div>
                )}
              </>
            </button>
          ))}
        </div>
        {objects.map((o, i) => (
          <div
            style={{ display: i === currentTab ? 'block' : 'none' }}
            key={`object${i}`}
            className={`z-20 ${isTunecore ? 'bg-tunecore-dark-element' : 'bg-white'} w-full relative p-0 pt-8`}
          >
            {o}
          </div>
        ))}
      </>
  );
}
export default CompontentTabs;
