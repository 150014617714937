/* eslint-disable no-unused-vars */
import React from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";

function NavItem(props) {
  const {
    highlightColour,
    icon,
    to,
    title,
    exact,
    iconIsFA,
    disabled,
    notification,
    externalLink,
    linkAddress,
  } = props;
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === "TuneCore";

  // CONDITIONAL CLASSES FOR REBRAND
  const navItemClasses = clsx(
    "nav-text text-nav-text-normal text-xs font-semibold",
    {
      "text-sm leading-normal capitalize": !isTunecore,
      "text-sm leading-normal uppercase": isTunecore,
    }
  );

  // awaiting introduction of API call from KYC check to determine if passed Security check
  // in navItems.jsx eg disabled={!securityPassed} - Matt I
  if (disabled) {
    return (
      <NavLink
        exact={exact}
        className="nav-link group w-full flex h-14 mb-2 items-center disabled-navlink"
        to={to}
      >
        <div className="nav-link-active h-full w-1 mr-4" />
        <div className="nav-link-hover h-full w-1 mr-4" />
        <div className="nav-link-normal h-full w-1 mr-4" />
        {iconIsFA && (
          <div
            style={{ color: "rgba(152, 152, 152, 0.47)" }}
            className="nav-image h-5 w-10 mr-0 text-xl"
          >
            {icon}
          </div>
        )}
        {!iconIsFA && (
          <img
            className="nav-image h-5 w-10 object-contain object-left mr-0"
            src={icon}
            alt=""
          />
        )}
        <span
          style={{ color: "rgba(95, 95, 95, 1)" }}
          id={`${title.replace(/\s/g, "")}_nav`}
          className="nav-text text-nav-text-normal uppercase text-xs font-semibold"
        >
          {title}
        </span>
      </NavLink>
    );
  }

  if (externalLink) {
    return (
      <div>
        <button
          type="button"
          onClick={() => (window.location.href = linkAddress)}
          className="nav-link group w-full flex h-14 mb-2 items-center hover:text-white"
        >
          <div
            style={{
              backgroundColor: isTunecore ? "#00ef86" : highlightColour,
            }}
            className="nav-link-active h-full w-1.5 mr-4"
          />
          <div
            style={{ backgroundColor: "#40424b" }}
            className="nav-link-hover h-full w-1.5 mr-4"
          />
          <div className="nav-link-normal h-full w-1.5 mr-4" />
          {iconIsFA && (
            <div
              style={{ color: "#898fa1" }}
              className="nav-image h-5 w-10 mr-0 text-xl"
            >
              {icon}
            </div>
          )}
          {!iconIsFA && (
            <img
              className="nav-image h-5 w-10 object-contain object-left mr-0"
              src={icon}
              alt=""
            />
          )}
          <span id="link" className={navItemClasses}>
            {title}
          </span>
        </button>
      </div>
    );
  }

  return (
    <NavLink
      exact={exact}
      className="nav-link group w-full flex h-14 mb-2 items-center"
      to={to}
    >
      <div
        style={{ backgroundColor: isTunecore ? "#00ef86" : highlightColour }}
        className="nav-link-active h-full w-1.5 mr-4"
      />
      <div
        style={{ backgroundColor: "#40424b" }}
        className="nav-link-hover h-full w-1.5 mr-4"
      />
      <div className="nav-link-normal h-full w-1.5 mr-4" />
      {iconIsFA && (
        <div
          style={{ color: "#898fa1" }}
          className="nav-image h-5 w-10 mr-0 text-xl"
        >
          {icon}
        </div>
      )}
      {!iconIsFA && (
        <img
          className="nav-image h-5 w-10 object-contain object-left mr-0"
          src={icon}
          alt=""
        />
      )}
      <span id={`${title.replace(/\s/g, "")}_nav`} className={navItemClasses}>
        {title}
      </span>
      {notification}
    </NavLink>
  );
}

export default NavItem;
