/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AppState from '../../../../store/appstate';
import { getSocieties, getMyAccount } from '../../../../api/queries';
import Spacer from '../../../../components/spacer';
import WritersSongsTable from './WriterSongsTable';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';
import { hasAdminRights } from '../../../../auth/shared';
import RebrandButtonLink from '../../../../components/rebrandButtonLink';
import WritersForm from './writersForm';
import Modal from '../../../../components/Modal';

function WriterDetails(props) {
  const { t } = useTranslation('writers');
  const { row, highlightColour } = props;
  const [selectedWriterId, setSelectedWriterId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { original } = row;
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  let proName = null;

  const societies = getSocieties();
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const adminRights = hasAdminRights(myAccount?.data);
  if (societies.isSuccess && societies?.data && original.societycisaccode) {
    const pro = societies.data.societies.find((s) => s.cisaccode === original.societycisaccode);
    proName = pro?.name;
  }

  return (
    <>
      <Modal openPortal={isModalOpen} closePortal={setIsModalOpen}>
        <WritersForm id={selectedWriterId} modal closeForm={setIsModalOpen} />
      </Modal>
      <tr>
        <td colSpan={isTunecore ? '7' : '5'}>
          <div className={`rounded-lg lg:p-6 p-4 mb-2 mt-2 w-full ${isTunecore ? 'bg-tunecore-black text-white' : 'bg-sentric-rebrand-light-grey text-old-song-sub-color'}`}>
            <div className="flex flex-wrap gap-2 sm:flex-nowrap sm:gap-0">
              <div className="flex items-center w-full lg:w-1/2">
                <h2 className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} font-bold`}>
                  {original?.firstname}
                  {' '}
                  {original?.middlename}
                  {' '}
                  {original?.lastname}
                </h2>
              </div>
              <div className="flex justify-start w-full lg:w-1/2 sm:justify-end">
                  <RebrandButtonLink
                  onClick={() => {
                    if (adminRights) {
                      setSelectedWriterId(original.writercode);
                      setIsModalOpen(true);
                    }
                  }}
                  >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${!adminRights ? 'opacity-20' : ''} mb-1 mr-2`}
                  >
                    <path
                      d="M11.1844 2.43503L13.6915 5.07943C13.7966 5.1903 13.7966 5.37097 13.6915 5.48184L7.62628 11.8793L5.04915 12.1791C4.70657 12.2201 4.4146 11.9122 4.45353 11.5508L4.73771 8.83251L10.8029 2.43503C10.908 2.32416 11.0793 2.32416 11.1844 2.43503ZM15.6847 1.76572L14.3299 0.336754C13.9095 -0.106717 13.2204 -0.106717 12.7961 0.336754L11.8112 1.37563C11.7061 1.48649 11.7061 1.66717 11.8112 1.77804L14.3182 4.42244C14.4234 4.5333 14.5946 4.5333 14.6998 4.42244L15.6847 3.38356C16.1051 2.93599 16.1051 2.21329 15.6847 1.76572ZM10.6667 10.1424V13.1235H1.77908V3.74902H8.1635C8.25304 3.74902 8.33479 3.71206 8.40097 3.64636L9.51046 2.47609C9.72068 2.25436 9.57275 1.87658 9.27299 1.87658H1.33528C0.599513 1.87658 0 2.50484 0 3.28502V13.5998C0 14.3759 0.595621 15.0083 1.33528 15.0083H11.1144C11.8501 15.0083 12.4496 14.38 12.4496 13.5998V8.97623C12.4496 8.66416 12.0915 8.50812 11.8813 8.72575L10.7718 9.89602C10.7095 9.96172 10.6745 10.0521 10.6745 10.1465L10.6667 10.1424Z"
                      fill={`${isTunecore ? '#FFF' : '#aeacad'}`}
                    />
                  </svg>
                    {t('writers:edit-writer')}
                  </RebrandButtonLink>
              </div>
            </div>
            <Spacer thin />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3">
                <p className="text-xs">
                  <span className="font-semibold uppercase">
                    {t('writers:_membership')}
                    :
                  </span>
                  {' '}
                  {proName}
                </p>
              </div>
              <div className="w-full lg:w-1/3">
                <p className="text-xs">
                  <span className="font-semibold uppercase">CAE / IPI #:</span>
                  {' '}
                  {original.caeipinumber}
                </p>
              </div>
              <div className="w-full lg:w-1/3">
                {isTunecore && (<p className="text-xs">
                  <span className="font-semibold uppercase">
                    {t('writers:dob')}
                    :
                  </span>
                  {' '}
                  {moment.utc(original.dateofbirth).format('YYYY-MM-DD')}
                </p>)}
              </div>
            </div>
            <Spacer thin />
            <WritersSongsTable
              highlightColour={highlightColour}
              writercode={original.writercode}
            />
          </div>

        </td>
      </tr>
    </>
  );
}

export default WriterDetails;
