/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";
import getColours from "../../../../helpers/getColours";
import faqData from "./faqData.json";
import Spacer from "../../../../components/spacer";

function FaqPage() {
	const { data: siteConfig } = useSiteConfigAuth();
	const { darkColour } = getColours();
	const [filteredFaqs, setFilteredFaqs] = useState([]);
	const history = useHistory();
  const isRoughTrade = siteConfig?.display_name?.includes('Rough Trade Publishing');

  useEffect(() => {
		if (!isRoughTrade) {
			history.push("/");
		}
	}, [isRoughTrade]);

	useEffect(() => {
		if (siteConfig?.display_name) {
			const clientFaq = faqData.find(
				(client) => client.clientName === siteConfig.display_name
			);
			setFilteredFaqs(clientFaq ? clientFaq.faq : []);
		}
	}, [siteConfig]);

	return (
		<div className='flex flex-wrap'>
			<div className='w-full lg:min-h-screen-minus-header'>
				<div className='mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12'>
					<div
						style={{ backgroundColor: darkColour }}
						className='w-full h-60 sm:h-52 flex flex-col sm:flex-row items-center justify-evenly sm:justify-between text-white rounded-lg px-8'
					>
						<h1 className='text-4xl mt-1 md:text-7xl font-semibold order-2 sm:order-1'>
							Royalties FAQ's
						</h1>
						{siteConfig?.logo && (
							<img className='h-1/4 sm:h-1/2 order-1 sm:order-2' src={siteConfig.logo} alt='logo' />
						)}
					</div>
					<Spacer />
					<div className=' flex flex-col gap-12 bg-white px-8 rounded-lg shadow-lg pt-12 pb-20'>
						{filteredFaqs.map((item, index) => (
							<React.Fragment key={item.id}>
								<div>
									<h1 className='font-sentric font-semibold text-2xl md:text-3xl'>
										{item.question}
									</h1>
									<p className='mt-2 text-lg font-sentric-alt'>{item.answer}</p>
								</div>
                {index < filteredFaqs.length - 1 && (
                  <hr className='border-t-2 mx-12 border-black border-opacity-15' />
                )}
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
export default FaqPage;
