import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaUserCheck } from "react-icons/fa";
import H2 from "../../../../components/h2";
import Spacer from "../../../../components/spacer";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";

function SecuritySuccess() {
	const history = useHistory();
	const { t } = useTranslation("security");
	return (
		<div className='flex flex-wrap'>
			<div className='w-full h-screen lg:min-h-screen-minus-header'>
				<div className='mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12'>
					<div className='text-center lg:text-left'>
						<H2>{t("security:security-check-header")}</H2>
					</div>
					<Spacer />
					<div className='flex items-center justify-center font-sentric-alt'>
						<div className='rounded-lg w-3/4 lg:w-1/2 bg-white py-12 px-6 flex flex-col items-center'>
							<div className='flex flex-col items-center'>
								<div className='flex flex-col items-center'>
									<div className='security-icon mb-6'>
										<FaUserCheck />
									</div>
									<div className='text-center'>
										{t("security:security-success-text")}
									</div>
								</div>
								<div className='mt-12'>
									<RebrandPrimaryButton onClick={() => history.push("/")} arrow>
										{t("other:go-to-dashboard")}
									</RebrandPrimaryButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default SecuritySuccess;
