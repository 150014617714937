/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const Pie = ({ buckets, unique }) => {
  const [currentChart, setCurrentChart] = useState(null);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const drawChart = () => {
    if (currentChart) {
      currentChart.dispose();
    }

    if (!buckets) {
      return;
    }

    const dataSet = buckets;
    let j = 0;

    while (j < dataSet.length) {
      const d = dataSet[j];
      d.value = parseFloat(d.WriterValues.value);
      j += 1;
    }

    // sort by value
    dataSet.sort((a, b) => (b.value - a.value));

    // take first 4 and then rest as Others

    const sortedData = dataSet; // .slice(0, 4);

    // const otherData = dataSet.slice(4);

    // if (otherData.length > 0) {
    //   let p = 0;
    //   let otherTotal = 0;
    //   while (p < otherData.length) {
    //     const d = otherData[p];
    //     otherTotal += d.value;
    //     p += 1;
    //   }

    //   sortedData.push({ value: otherTotal, key: 'Other' });
    // }

    // Create root and chart
    am5.addLicense('AM5C316073875');
    const root = am5.Root.new(`chartPie${unique}`);
    root.numberFormatter.set('numberFormat', '#,###.00');

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(80),
        innerRadius: am5.percent(30),
      }),
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'key',
        alignLabels: false,
      }),
    );

    series.get('colors').set('colors', [
      am5.color(0xe9a838),
      am5.color(0xf57660),
      am5.color(0x60cdbb),
      am5.color(0xf1e15b),
      am5.color(0xe9c2a3),
    ]);

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(sortedData);

    const tooltip = am5.Tooltip.new(root, {
      labelText:
        '[fontFamily:Montserrat; bold; #4eb4ba]{key}[/]\n[fontFamily:Montserrat; bold; fontSize:18px]{value}[/]',
      getFillFromSprite: false,
    });

    tooltip.get('background').setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 1,
      stroke: am5.color(0x4eb4ba),
      strokeWidth: 2,
    });
    series.ticks.template.set('visible', false);

    series.labels.template.setAll({
      fontSize: 12,
      textType: 'circular',
      inside: true,
      radius: 10,
      fill: am5.color(0xffffff),
    });

    series.slices.template.setAll({
      cornerRadius: 5,
      stroke: isTunecore ? am5.color(0x000) : am5.color(0xf0f4f9),
      strokeWidth: 1,

    });

    series.set('tooltip', tooltip);

    series.labels.template.set('forceHidden', true);

    series.appear(1000, 100);
    setCurrentChart(root);
  };

  useEffect(() => {
    drawChart();
    // remember to dispose
    return () => {
      if (currentChart) {
        currentChart.dispose();
      }
    };
  }, [buckets]);

  return (
    <div
      id={`chartPie${unique}`}
      className="w-56 md:w-72 lg:w-96 xl:w-120"
      style={{ height: '350px', marginTop: '25px' }}
    />
  );
};

export default Pie;
