/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { FaSpotify } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import * as Tabs from "@radix-ui/react-tabs";
import H1 from "./h1";
import Spacer from "./spacer";
import AppState from "../store/appstate";
import { getArtistMeta, getMyAccount, getUserMeta } from "../api/queries";
import FormError from "./formError";
import { updateArtistAccountMeta, updateArtistAccount } from "../api/mutations";
import Select from "./select";
import RebrandButtonLink from "./rebrandButtonLink";
import RebrandPrimaryButton from "./rebrandPrimaryButton";
import { sendEvent } from '../helpers/helpers';
import useSiteConfigAuth from "../api/sanity/queries/getSiteConfigWithCode";
import Input from "./input";

function SocialSpotify({ closeForm }) {
	const { t } = useTranslation(["songs", "common"]);
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const artistMeta = getArtistMeta({ aac: selectedAccount });
	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});
	const userMeta = getUserMeta();
	const accountMetaMutation = updateArtistAccountMeta();
	const accountMutation = updateArtistAccount();
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const [apiToken, setAPIToken] = useState(null);

	const [mutationErrorMessage, setMutationErrorMessage] = useState("");
	const [validationErrorMessage, setValidationErrorMessage] = useState("");

	const [pending, setPending] = useState(false);

	const [result, setResult] = useState(null);

	const [loading, setLoading] = useState(false);
	const [spotifyURL, setSpotifyURL] = useState("");
	const [fetchMethod, setFetchMethod] = useState(null);


	const [activeTab, setActiveTab] = useState("searchWithURL");

	const activeStyle = {
		backgroundColor: "#1DB954",
		opacity: 1,
		color: "black",
		textDecoration: "underline",
	};

	const inactiveStyle = {
		backgroundColor: isTunecore ? "#333333" : "white",
		color: isTunecore ? "white" : "black",
		cursor: "pointer",
	};

	const getSpotifyAPIToken = async () => {
		const apiUrl = AppState.getRawState()?.apiUrl;

		const response = await fetch(`${apiUrl}/spotify-token`);
		const responseData = await response.json();
		return responseData.access_token;
	};

	let title = t("songs:link-spotify-artist-title");
	let desc = t("songs:link-spotify-artist-subtitle");
	switch (userMeta?.data?.role) {
		case "Manager":
			title = t("songs:link-spotify-manager-title");
			desc = t("songs:link-spotify-manager-subtitle");
			break;
		default:
			break;
	}

	useEffect(() => {
		async function go() {
			setAPIToken(await getSpotifyAPIToken());
		}
		go();
	}, []);

	const addSpotify = () => {
		setMutationErrorMessage("");
		setValidationErrorMessage("");
		setPending(true);

		const fetchMethodForHubspot = fetchMethod;

		const data = {
			account_id: selectedAccount,
			facebook_obj: artistMeta.data?.facebook,
			spotify_obj: result,
			songkick_obj: artistMeta.data?.facebook?.songkick,
			twitter_obj: artistMeta.data?.facebook?.twitter,
			bandsintown_obj: artistMeta.data?.facebook?.bandsintown,
			instagram_obj: artistMeta.data?.facebook?.instagram,
		};

		accountMetaMutation.reset();
		accountMetaMutation.mutate(data, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
					artistMeta.refetch();
					setPending(false);
					closeForm();
					if (fetchMethodForHubspot === "searchWithName") {
						sendEvent({ property: "pe14489857_spotify_account_name" });
					} else if (fetchMethodForHubspot === "searchWithURL") {
						sendEvent({ property: "pe14489857_spotify_account_link" });
					}
				}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});

		const rightsAppArtistAccount = {
			cataloguecode: myAccount.data.cataloguecode,
			artistaccountcode: myAccount.data.artistaccountcode,
			accountname: myAccount.data.accountname,
			facebookpageurl: myAccount.data.facebookpageurl,
			twitterprofileurl: myAccount.data.twitterprofileurl,
			soundcloudpageurl: myAccount.data.soundcloudpageurl,
			spotifyid: JSON.parse(result).id,
			releasedtoserviceorstore: myAccount.data.releasedtoserviceorstore,
			performedlastsixmonths: myAccount.data.performedlastsixmonths,
			etag: myAccount.data.etag,
		};

		accountMutation.reset();
		accountMutation.mutate(rightsAppArtistAccount, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages.join(", "));
					setPending(false);
				} else {
					artistMeta.refetch();
					setPending(false);
					closeForm();
				}
			},
			onError: (error) => {
				console.log("error", error);
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};

	const getOptions = (
		query // if fetching using search
	) =>
		new Promise((resolve, reject) => {
			if (!query) {
				resolve([]);
				return;
			}
			fetch(
				`https://api.spotify.com/v1/search?q=${query}&type=artist&market=GB&limit=50`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${apiToken}`,
					},
				}
			)
				.then((response) => response.json())
				.then(({ artists }) => {
					setFetchMethod("searchWithName"); 
					resolve(
						artists.items.map((artist) => ({
							value: JSON.stringify(artist),
							name: artist.name,
							photo:
								artist.images && artist.images.length > 0
									? artist.images[0].url
									: null,
						}))
					);
				})
				.catch(reject);
		});

	const handleSpotifyURLSubmit = (e) => {
		// if user is pasting spotify URL
		e.preventDefault();
		setLoading(true);

		const spotifyLinkRegex =
			/https:\/\/open\.spotify\.com\/artist\/([a-zA-Z0-9]{22})/;
		const spotifyUriRegex = /spotify:artist:([a-zA-Z0-9]{22})/;

		let artistId = null;

		const spotifyLinkMatch = spotifyURL.match(spotifyLinkRegex);
		const spotifyUriMatch = spotifyURL.match(spotifyUriRegex);

		// array destructuring
		if (spotifyLinkMatch) {
			[, artistId] = spotifyLinkMatch;
		} else if (spotifyUriMatch) {
			[, artistId] = spotifyUriMatch;
		}

		if (artistId) {
			fetch(`https://api.spotify.com/v1/artists/${artistId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${apiToken}`,
				},
			})
				.then((response) => response.json())
				.then((artist) => {
					setResult(JSON.stringify(artist));
					setLoading(false);
					setValidationErrorMessage("");
					setFetchMethod("searchWithURL");
				})
				.catch((error) => {
					console.error("Error fetching artist by ID:", error);
					setLoading(false);
				});
		} else {
			console.error("Invalid Spotify URL, URI, or artist ID");
			setValidationErrorMessage(
				t("songs:link-spotify-invalid-url-error")
			);
			setLoading(false);
		}
	};

	return (
		<div
			className={`${
				isTunecore
					? "bg-tunecore-black text-white font-tunecore"
					: "bg-sentric-rebrand-light-grey text-old-sentric-dark-gray "
			} flex items-center flex-col p-4 rounded-lg`}
		>
			<Spacer thin />
			<div className='inline-flex flex-row-reverse gap-2'>
				<h1 className='text-2xl md:text-4xl'>{title}</h1>
				<FaSpotify className='text-4xl text-[#1DB954]' />
			</div>
			<Spacer thin />
			<p className={!isTunecore ? "font-sentric-alt" : ""}>{desc}</p>
			<Spacer thin />
			<Tabs.Root
				className='flex flex-col w-full'
				defaultValue='searchWithURL'
				onValueChange={(value) => {
					setActiveTab(value);
					setResult(null);
					setValidationErrorMessage("");
					setSpotifyURL("");
				}}
			>
				<Tabs.List className={`${isTunecore ? 'border-tunecore-light-grey' : 'border-white'} shrink-0 border-4  w-5/6 lg:w-3/4 self-center md:flex text-sm rounded-lg xl:text-base overflow-hidden`}>
					<Tabs.Trigger
							className='px-5 min-h-[52px] xl:h-[45px] hover:underline w-full flex-1 flex items-center justify-center select-none  md:rounded-none'
						value='searchWithURL'
						style={activeTab === "searchWithURL" ? activeStyle : inactiveStyle}
					>
						{t("songs:link-spotify-tab-header-link")}
					</Tabs.Trigger>
					<Tabs.Trigger
							className='px-5 min-h-[52px] xl:h-[45px] hover:underline flex-1 w-full flex items-center justify-center select-none  md:rounded-none'
						value='searchWithName'
						style={activeTab === "searchWithName" ? activeStyle : inactiveStyle}
					>
						{t("songs:link-spotify-tab-header-name")}
					</Tabs.Trigger>
				</Tabs.List>
				<Tabs.Content className='p-4 lg:p-8 w-full' value='searchWithURL'>
					<div className='flex flex-col'>
						<p className={!isTunecore ? "font-sentric-alt" : ""}>
							{t("songs:link-spotify-url-instructions")}
							{" "}
							<span>
								<a
									className='underline hover:scale-105'
									href='https://support.spotify.com/us/artists/article/finding-your-artist-url/'
								>
									{t("songs:link-spotify-instructions-link")}
								</a>
							</span>
						</p>
						{!result && (
							<>
								<Spacer thin />
								<Input
									type='text'
									disabled={loading}
									placeholder={t('songs:link-spotify-paste-url')}
									value={spotifyURL}
									onChange={(e) => setSpotifyURL(e.target.value)}
									className='mb-4 w-full'
								/>
								<div className='self-end'>
									<RebrandPrimaryButton
										type='submit'
										onClick={handleSpotifyURLSubmit}
										disabled={loading || !spotifyURL}
										pending={loading}
										arrow
									>
										{loading ? `${t("common:loading")}...` : t("songs:link-spotify-fetch-profile")}
									</RebrandPrimaryButton>
								</div>
							</>
						)}
					</div>
				</Tabs.Content>

				<Tabs.Content className='p-2 lg:p-8' value='searchWithName'>
					<div className='text-old-sentric-dark-gray'>
						<Select
              className={`select-search ${isTunecore ? 'tunecore-select' : 'font-sentric-alt'}`}
							onChange={(artist) => {
								setResult(artist);
							}}
							getOptions={getOptions}
							search
							customLabel={t("songs:link-spotify-account")}
						/>
					</div>
				</Tabs.Content>
			</Tabs.Root>

			{result && (
				<div
				style={
					isTunecore
						? {
								background:
									"linear-gradient(to bottom right, #a6a6a6, #505050)",
							}
						: {
								background: "#FFF",
							}
				}
					className={`${
						isTunecore
							? "text-white"
							: "text-old-sentric-dark-gray "
					} rounded-lg shadow-lg flex mt-4 lg:mt-0 flex-col sm:w-80`}
				>
					<div className='mx-4 mt-4'>
						{JSON.parse(result).images &&
							JSON.parse(result).images.length > 0 && (
								<img
									height={160}
									width={160}
									className='max-h-52 w-full bg-gray-300 object-cover rounded-lg'
									alt={JSON.parse(result).name}
									src={JSON.parse(result).images[0].url}
								/>
							)}
						{(!JSON.parse(result).images ||
							JSON.parse(result).images.length === 0) && (
							<div className='max-h-52 w-full bg-gray-300 rounded-lg' />
						)}
					</div>
					<div className='flex flex-col justify-between p-4'>
						<h3 className='text-xl md:text-2xl self-center'>
							{JSON.parse(result).name}
						</h3>
						<Spacer thin />

						<div className='flex flex-col-reverse items-center md:flex-row gap-4 md:gap-0 justify-between'>
							<RebrandButtonLink
								onClick={() => {
									setResult(null);
									setSpotifyURL("");
								}}
							>
								{t("common:remove")}
							</RebrandButtonLink>
							<RebrandPrimaryButton
								disabled={pending || !result}
								pending={pending}
								id='btnRecordingFormAddRecording'
								onClick={addSpotify}
								className='md:self-end'
							>
								{t('songs:link-spotify-link-account-button')}
							</RebrandPrimaryButton>
						</div>
					</div>
				</div>
			)}
			<FormError
				mutationError={mutationErrorMessage}
				validationError={validationErrorMessage}
			/>
		</div>
	);
}

export default SocialSpotify;
