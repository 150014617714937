import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../components/select";
import Spacer from "../../../components/spacer";
import AppState from "../../../store/appstate";
import useDomainConfigAuth from "../../../api/sanity/queries/getSiteConfigWithDomain";

function OnboardingAccountRole({ setIsLocked }) {
  const { t } = useTranslation("onboarding");
  const role = AppState.useState((s) => s.onboarding?.role);
  const { data: domainConfig } = useDomainConfigAuth();
  const isTunecore = domainConfig?.display_name === "TuneCore";
  const [showArtistInput, setShowArtistInput] = useState(false);

  useEffect(() => {
    if (role === "Label") {
      setShowArtistInput(true);
      setIsLocked(true);
    } else {
      setShowArtistInput(false);
      setIsLocked(role === null);
    }
  }, [role, setIsLocked]);

  return (
    <div className='container mx-auto'>
      <div className='flex flex-col items-center justify-center text-center text-white'>
        <Spacer />
        <div className='w-full xl:w-1/2'>
          <Select
            authSearch
            list
            className={`${
              isTunecore
                ? "tunecore-select tunecore-input-hover"
                : "custom-select"
            } text-old-sentric-dark-gray`}
            name="artist"
            placeholder={t("onboarding:select-role-placeholder")}
            value={role}
            onChange={(selected) => {
              AppState.update((s) => {
                s.onboarding.role = selected;
              });
              setIsLocked(!selected);
            }}
            options={[
              { name: t("onboarding:_band"), value: "Band" },
              { name: t("onboarding:_artist"), value: "Artist" },
              { name: t("onboarding:_songwriter"), value: "Songwriter" },
              { name: t("onboarding:_manager"), value: "Manager" },
              { name: t("onboarding:_label"), value: "Label" },
              { name: t("onboarding:_dj"), value: "DJ" },
              { name: t("onboarding:_producer"), value: "Producer" },
            ]}
          />
          {showArtistInput && (
            <>
              <div className="flex justify-center w-full flex-col items-center">
                <Spacer />
                <p className="w-4/5">
                  {t("onboarding:label-error-message")}
                  <a
                    href="https://share.hsforms.com/1HFoqTJMbSxOiOPIYDoVLSg8mkg1"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    {t("onboarding:label-error-message-2")}
                  </a>
                  {t("onboarding:label-error-message-3")}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OnboardingAccountRole;
