/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import { noDp } from '../../../../helpers/helpers';
import Map from './_map';
import AppState from '../../../../store/appstate';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const TabTerritories = ({ pending, result }) => {
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const { t } = useTranslation('other');
  let territories = [];
  if (result?.body?.aggregations?.Territories) {
    territories = result?.body?.aggregations?.Territories.buckets.slice(0, 3);
  }
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  return (
    <div className="p-4  h-full flex items-start flex-col">
      <h1 className={`text-md xl:text-xl font-medium ${isTunecore ? 'text-white' : 'text-black'} lg:mb-4`}>{t('other:territories-tab-header')}</h1>
      <h2 className={`text-xxs font-semibold ${isTunecore ? 'text-white' : 'text-sentric-rebrand-grey-text'}`}>{t('other:top-territories')}</h2>
      {territories.map((s, i) => (
        <>
          <div className={`h-14 ${(i < (territories.length - 1) && i > 0) ? 'border-b' : ''} flex w-full items-center justify-between font-sentric-alt`} key={`song${i}`}>
            <div className=" text-xs font-semibold flex flex-row">
              <span className="text-xs bg-sentric-rebrand-grey-text text-white flex-none rounded-full w-4 h-4 mr-2">{(i + 1)}</span>
              <span className="text-left line-clamp-2 break-word">
              {s.key}
              </span>
            </div>
            <div className={`${isTunecore ? 'text-white' : 'text-black'}  font-medium text-xs`}>
              {currencySymbol}
              {noDp(s.WriterValues.value)}
            </div>
          </div>
          {i === 0
          && (
          <div className="rounded-lg overflow-hidden relative w-full">
            <Map pending={pending} buckets={result?.body?.aggregations?.Territories?.buckets || []} />
          </div>
          )}
        </>
      ))}
    </div>
  );
};

export default TabTerritories;
