import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Tabs from "@radix-ui/react-tabs";
import P from "../../../components/p";
import Radio from "../../../components/radio";
import Spacer from "../../../components/spacer";
import { getUserMeta } from "../../../api/queries";
import AppState from "../../../store/appstate";
import Input from "../../../components/input";
import useDomainConfigAuth from "../../../api/sanity/queries/getSiteConfigWithDomain";
import FormError from "../../../components/formError";
import Select from "../../../components/select";
import AuthPrimaryButton from "../../../components/authPrimaryButton";

function OnboardingSongSuggestions({ setIsLocked }) {
	const { t } = useTranslation(["onboarding", "common", "songs"]);
	const userMeta = getUserMeta();
	const addAccount = AppState.useState((s) => s.addAccount);
	const { data: domainConfig } = useDomainConfigAuth();
	const isTunecore = domainConfig?.display_name === "TuneCore";
	const [apiToken, setAPIToken] = useState(null);
	const [validationErrorMessage, setValidationErrorMessage] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [result, setResult] = useState(addAccount.spotify_obj || null);
	const [loading, setLoading] = useState(false);
	const [spotifyURL, setSpotifyURL] = useState("");
	const [fetchMethod, setFetchMethod] = useState(null);

	const [activeTab, setActiveTab] = useState("searchWithURL");

	let desc = "Have you digitally released your music?";
	const spotifyDesc = t("songs:link-spotify-artist-subtitle");
	switch (userMeta?.data?.role) {
		case "Label":
		case "Manager":
			desc = t("onboarding:have-they-released-desc");
			break;
		default:
			break;
	}

	const addSpotify = () => {
		const fetchMethodForHubspot = fetchMethod;
		if (result) {
			AppState.update((s) => {
				s.addAccount.spotify = result;
			});
			AppState.update((s) => {
				s.spotifyAccountMethod = fetchMethodForHubspot;
			});
		}
		console.log(AppState.getRawState().addAccount); // Log the state of the addAccount object
	};

	useEffect(() => {
		addSpotify();
		setIsLocked(addAccount.streaming === null);
	}, [addAccount, setIsLocked]);

	const handleRadioChange = (value) => {
		setShowModal(value);
		AppState.update((s) => {
			s.addAccount.streaming = value;
		});
	};

	const activeStyle = {
		backgroundColor: "#1DB954",
		opacity: 1,
		color: "black",
		textDecoration: "underline",
	};

	const inactiveStyle = {
		backgroundColor: isTunecore ? "#333333" : "#868484",
		color: isTunecore ? "white" : "black",
		cursor: "pointer",
	};

	const getSpotifyAPIToken = async () => {
		const apiUrl = AppState.getRawState()?.apiUrl;

		const response = await fetch(`${apiUrl}/spotify-token`);
		const responseData = await response.json();
		return responseData.access_token;
	};

	useEffect(() => {
		async function go() {
			setAPIToken(await getSpotifyAPIToken());
		}
		go();
	}, []);

	useEffect(() => {
		if (addAccount.streaming) {
			setShowModal(true);
		}
	}, []);

	const getOptions = (query) =>
		new Promise((resolve, reject) => {
			if (!query) {
				resolve([]);
				return;
			}
			fetch(
				`https://api.spotify.com/v1/search?q=${query}&type=artist&market=GB&limit=50`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${apiToken}`,
					},
				}
			)
				.then((response) => response.json())
				.then(({ artists }) => {
					setFetchMethod("searchWithName");
					resolve(
						artists.items.map((artist) => ({
							value: JSON.stringify(artist),
							name: artist.name,
							photo:
								artist.images && artist.images.length > 0
									? artist.images[0].url
									: null,
						}))
					);
				})
				.catch(reject);
		});

	const handleSpotifyURLSubmit = (e) => {
		// if user is pasting spotify URL
		e.preventDefault();
		setLoading(true);

		const spotifyLinkRegex =
			/https:\/\/open\.spotify\.com\/artist\/([a-zA-Z0-9]{22})/;
		const spotifyUriRegex = /spotify:artist:([a-zA-Z0-9]{22})/;

		let artistId = null;
		const spotifyLinkMatch = spotifyURL.match(spotifyLinkRegex);
		const spotifyUriMatch = spotifyURL.match(spotifyUriRegex);

		// array destructuring
		if (spotifyLinkMatch) {
			[, artistId] = spotifyLinkMatch;
		} else if (spotifyUriMatch) {
			[, artistId] = spotifyUriMatch;
		}

		if (artistId) {
			fetch(`https://api.spotify.com/v1/artists/${artistId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${apiToken}`,
				},
			})
				.then((response) => response.json())
				.then((artist) => {
					setResult(JSON.stringify(artist));
					setLoading(false);
					setValidationErrorMessage("");
					setFetchMethod("searchWithURL");
				})
				.catch((error) => {
					console.error("Error fetching artist by ID:", error);
					setLoading(false);
				});
		} else {
			console.error("Invalid Spotify URL, URI, or artist ID");
			setValidationErrorMessage(t("songs:link-spotify-invalid-url-error"));
			setLoading(false);
		}
	};

	return (
		<div>
			<div className='flex flex-col items-center justify-center p-4 text-center text-white'>
				<Spacer />
				<P>{desc}</P>
				<Spacer />
				<Radio
					name='digitallyReleased'
					placeholder=''
					value={addAccount.streaming}
					onChange={(e) => {
						const value = e.target.value === "true";
						AppState.update((s) => {
							s.addAccount.streaming = value;
						});
						handleRadioChange(value);
					}}
					options={[
						{ label: t("common:yes"), value: true },
						{ label: t("common:no"), value: false },
					]}
				/>
				<Spacer />

				{showModal && addAccount.streaming && (
					<>
						<p className='text-left w-2/3'>{spotifyDesc}</p>
						<Spacer />
						<Tabs.Root
							className='flex flex-col w-full'
							defaultValue='searchWithURL'
							onValueChange={(value) => {
								setActiveTab(value);
								setResult(null);
								setValidationErrorMessage("");
								setSpotifyURL("");
							}}
						>
							<Tabs.List
								className={`${
									isTunecore
										? "border-2 border-tunecore-light-grey"
										: "border-2 border-sentric-rebrand-mid-grey"
								} shrink-0 w-5/6 lg:w-2/4 self-center md:flex text-sm rounded-lg xl:text-base overflow-hidden`}
							>
								<Tabs.Trigger
									className='px-5 min-h-[52px] xl:h-[45px] hover:underline w-full flex-1 flex items-center justify-center select-none  md:rounded-none'
									value='searchWithURL'
									style={
										activeTab === "searchWithURL" ? activeStyle : inactiveStyle
									}
								>
									{t("songs:link-spotify-tab-header-link")}
								</Tabs.Trigger>
								<Tabs.Trigger
									className='px-5 min-h-[52px] xl:h-[45px] hover:underline flex-1 w-full flex items-center justify-center select-none  md:rounded-none'
									value='searchWithName'
									style={
										activeTab === "searchWithName" ? activeStyle : inactiveStyle
									}
								>
									{t("songs:link-spotify-tab-header-name")}
								</Tabs.Trigger>
							</Tabs.List>
							<Tabs.Content className='' value='searchWithURL'>
								<div className='flex flex-col mt-8'>
									{!result && (
										<>
											<small
												className={`w-2/3 self-center text-left ${
													!isTunecore ? "font-sentric-alt" : ""
												}`}
											>
												{t("songs:link-spotify-url-instructions")}{" "}
												<span>
													<a
														className='underline hover:scale-105'
														href='https://support.spotify.com/us/artists/article/finding-your-artist-url/'
													>
														{t("songs:link-spotify-instructions-link")}
													</a>
												</span>
											</small>
											<Spacer thin />
											<div className='self-center w-full lg:w-2/3'>
												<Input
													type='text'
													disabled={loading}
													placeholder={t("songs:link-spotify-paste-url")}
													value={spotifyURL}
													onChange={(e) => setSpotifyURL(e.target.value)}
													className={`mb-4 self-center ${isTunecore ? 'auth-tunecore-page-input' : 'auth-page-input'}`}
												/>
												<AuthPrimaryButton
													type='submit'
													onClick={handleSpotifyURLSubmit}
													disabled={loading || !spotifyURL}
													pending={loading}
													className='w-3/4 sm:w-auto'
												>
													{loading
														? `${t("common:loading")}...`
														: t("songs:link-spotify-fetch-profile")}
												</AuthPrimaryButton>
											</div>
										</>
									)}
								</div>
							</Tabs.Content>

							<Tabs.Content
								className={result ? "p-4" : "p-8"}
								value='searchWithName'
							>
								{!result && (
									<div className='flex flex-col'>
										<div className='self-center w-full lg:w-2/3'>
											<Select
												authSearch
												className={`${
													isTunecore
														? "tunecore-select tunecore-input-hover"
														: "custom-select"
												} text-old-sentric-dark-gray`}
												onChange={(artist) => {
													setResult(artist);
												}}
												getOptions={getOptions}
												search
												customLabel={t("songs:link-spotify-account")}
											/>
										</div>
									</div>
								)}
							</Tabs.Content>
						</Tabs.Root>

						{result && (
							<>
								<div
									style={
										isTunecore
											? {
													background:
														"linear-gradient(to bottom right, #a6a6a6, #505050)",
											  }
											: {
													background: "#868484",
											  }
									}
									className={`${
										isTunecore ? "text-white" : "text-old-sentric-dark-gray "
									} rounded-lg shadow-lg flex flex-col sm:w-80`}
								>
									<div className='mx-4 mt-4'>
										{JSON.parse(result).images &&
											JSON.parse(result).images.length > 0 && (
												<img
													height={160}
													width={160}
													className='max-h-52 w-full bg-gray-300 object-cover rounded-lg'
													alt={JSON.parse(result).name}
													src={JSON.parse(result).images[0].url}
												/>
											)}
										{(!JSON.parse(result).images ||
											JSON.parse(result).images.length === 0) && (
											<div className='max-h-52 w-full bg-gray-300 rounded-lg' />
										)}
									</div>
									<div className='flex flex-col justify-between p-4'>
										<h3 className='text-xl md:text-2xl self-center'>
											{JSON.parse(result).name}
										</h3>
										<Spacer thin />

										<AuthPrimaryButton
											textOnly
											className='self-center w-full sm:w-1/3'
											onClick={() => {
												setResult(null);
												setSpotifyURL("");
												AppState.update((s) => {
													s.addAccount.spotify_obj = null;
												});
											}}
										>
											{t("common:remove")}
										</AuthPrimaryButton>
									</div>
								</div>
							</>
						)}

						<FormError
							mutationError={null}
							validationError={validationErrorMessage}
						/>
						<Spacer />
					</>
				)}
			</div>
		</div>
	);
}

export default OnboardingSongSuggestions;
