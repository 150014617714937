/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PortableText } from "@portabletext/react";
// import urlFor from "../../../../api/sanity/sanityImageUrl";
import HR from "../../../../components/hr";
import H2 from "../../../../components/h2";
// import H3 from '../../../../components/h3';
import Spacer from "../../../../components/spacer";
import LoadingSmall from "../../../../components/loadingSmall";
import AppState from "../../../../store/appstate";
import { getMyAccount } from "../../../../api/queries";
import { hasAdminRights } from "../../../../auth/shared";
import P from "../../../../components/p";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
// import getSyncs from '../../../../api/sanity/queries/getSyncs';
import useSyncsQuery from "../../../../api/sanity/queries/getSyncs";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function Syncs() {
	const { t } = useTranslation("other");
	const history = useHistory();
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});
	const adminRights = hasAdminRights(myAccount?.data);

	const { data: syncsData, isLoading, isSuccess } = useSyncsQuery();
	const { data: siteConfig } = useSiteConfigAuth();

	const allSyncsAvailable = AppState.getRawState()?.allSyncsAvailable;
	let syncs = [];
	if (allSyncsAvailable) {
		syncs = syncsData;
	} else {
		syncs = syncsData?.syncs_available;
	}

	const noSyncs = siteConfig?.disable_syncs;
	useEffect(() => {
		if (noSyncs) {
			history.push("/");
		}
	}, [noSyncs]);

	return (
		<div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
			<H2>{t("other:browse-syncs-header")}</H2>
			<Spacer />

			<div className='w-full'>
				{isLoading && <LoadingSmall />}
				{isSuccess && syncs && syncs.length > 0 ? (
					syncs.map((sync) => (
						<React.Fragment key={sync._id}>
							<div>
								<P className='mb-2 font-semibold'>{sync.name}</P>
								<div className='w-full flex flex-row justify-between gap-2'>
									<div className='w-full xl:w-3/4 html-content'>
										<PortableText value={sync.description} />
									</div>
									<img
										className='xl:w-1/4 hidden xl:flex h-72 object-cover rounded-lg'
										src={sync.imageUrl}
										alt={sync.altText}
									/>
								</div>
								<Spacer thin />
								<RebrandPrimaryButton
									id='btnSyncsApply'
									onClick={
										adminRights
											? () => history.push(`/syncs/apply/${sync._id}`)
											: undefined
									}
									disabled={!adminRights}
								>
									{t("other:apply")}
								</RebrandPrimaryButton>
							</div>
							<Spacer />
							<HR className='w-full lg:w-3/4' />
							<Spacer />
						</React.Fragment>
					))
				) : (
					<div>
						<p>{t("other:no-syncs")}</p>
					</div>
				)}
			</div>
		</div>
	);
}
export default Syncs;
