import { useEffect } from 'react';
import IdenfyIFrame from './IdenfyIFrame';
import LoadingSmall from '../../../../components/loadingSmall';
import {
  getKycStatus,
} from '../../../../api/queries';
import AppState from '../../../../store/appstate';
import FormError from '../../../../components/formError';

function BeginSecurityCheck() {
  const authData = getKycStatus();
  useEffect(() => {
    AppState.update((s) => {
      s.navItemsEnabled = false;
    });
  });
  return (
    <div>
      <div>
        <div>
          {authData.isLoading && <LoadingSmall />}
          {authData.isSuccess && authData.data !== undefined && authData.data !== 'undefined' && authData.data !== null
            && authData?.data?.authurl != null
            && authData?.data?.authurl !== undefined ? (
            <IdenfyIFrame authToken={authData.data} />
          ) : (
            AppState.update((s) => {
              s.navItemsEnabled = true;
            })
            && <FormError validationError="No auth token returned from api" />
          )}
        </div>
      </div>
    </div>
  );
}
export default BeginSecurityCheck;
