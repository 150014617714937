/* eslint-disable object-shorthand */
import i18n from 'i18next';
import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { locizePlugin } from 'locize'

const locizeOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  apiKey: import.meta.env.VITE_LOCIZE_API_KEY, // YOU should not expose your apps API key to production!!!
  version: import.meta.env.VITE_LOCIZE_VERSION
};

// add new languages here
const supportedLngs = import.meta.env.VITE_STAGE === 'prd' ? ['en'] : ['en', 'de', 'fr'];

console.log('supportedLngs', supportedLngs);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(locizePlugin)
  .init({
    // comment out fallback to check if any are un-translated or missing keys & LEAVE IN on prod
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    // namespaces declared here will be loaded on app load as they are used across the site in different areas, specific namespaces are loaded at component level
    ns: ['common', 'other', 'auth', 'regex'],
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged editorSaved'
    },
    backend: locizeOptions,
    supportedLngs: supportedLngs,
    saveMissing: process.env.NODE_ENV === 'development',
  });

export default i18n;
