import { useState } from 'react';
import { hasAdminRights } from '../auth/shared';
import AppState from '../store/appstate';
import { getMyAccount } from '../api/queries';
import getColours from '../helpers/getColours';
import {PlusIconPlus} from './svg/PlusIcon'
import useSiteConfigAuth from '../api/sanity/queries/getSiteConfigWithCode';

function RebrandButtonLink(props) {
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const adminRights = hasAdminRights(myAccount?.data);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const { highlightColour } = getColours(siteConfig);
  const [isHovering, setIsHovering] = useState(false);
  
  const {
  children, onClick, id, plusIcon, smallfont,
  } = props;

  return (
    <>
      {
        !isTunecore ? (
          <button
            id={id}
            onClick={onClick}
            disabled={!adminRights}
            smallfont={smallfont}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{
              color: `${isHovering ? highlightColour : ''}`,
            }}
            type="button"
            className={`text-black ${
              !adminRights ? '' : 'underline'
            } flex items-center disabled:text-gray-300 disabled:pointer-events-none hover:scale-105 transition-all duration-150 ${smallfont ? 'text-xs' : 'text-base'} `}
          >
            {children}
            {plusIcon && (
            <PlusIconPlus className={`mb-1 ${
              !adminRights ? 'opacity-20' : ''
            } ml-2 ${
              smallfont ? 'h-3' : 'h-4'
            }`} fill={highlightColour} />
            )}
          </button>
        ) : (
          <button
            id={id}
            onClick={onClick}
            disabled={!adminRights}
            smallfont={smallfont}
            type="button"
            className={`text-white ${
              !adminRights ? '' : 'underline'
            } flex items-center disabled:text-gray-300 disabled:pointer-events-none hover:text-tunecore-green ${smallfont ? 'text-xs' : 'text-base'} `}
          >
            {children}
          </button>
        )
      }
    </>
  );
}

export default RebrandButtonLink;
