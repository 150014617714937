/* eslint-disable react/jsx-fragments */
/* eslint-disable no-prototype-builtins */
import { Suspense } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { withLDProvider, useFlags } from "launchdarkly-react-client-sdk";
import "react-toastify/dist/ReactToastify.css";
// import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "@sentry/react";
import { AwsRum } from "aws-rum-web";

import { getApiUrl } from "./helpers/helpers";
import SignUp from "./screens/auth/signup";

// authenticated routes
import Dashboard from "./screens/authenticated/dashboard/dashboard";
import Login from "./screens/auth/login";
import LdSetup from "./screens/auth/ldSetup";
import ResetPassword from "./screens/auth/resetPassword";

import AuthLayout from "./layouts/authenticated";
import BasicLayout from "./layouts/basic";

import Royalties from "./screens/authenticated/dashboard/royalties/royalties";
import Songs from "./screens/authenticated/dashboard/songs/songs";
import SongsCreateUpdate from "./screens/authenticated/dashboard/songs/songsCreateUpdate";
import Gigs from "./screens/authenticated/dashboard/gigs/gigs";
import GigsClaim from "./screens/authenticated/dashboard/gigs/gigsClaim";
import Setlists from "./screens/authenticated/dashboard/gigs/setlists";

import Opportunities from "./screens/authenticated/dashboard/opportunities/opportunities";
import SignUpMessage from "./screens/auth/signup-message";
import SignUpConfirm from "./screens/auth/signup-confirm";
import AddAccount1 from "./screens/onboarding/addAccount1";
import AddAccount2 from "./screens/onboarding/addAccount2";
import AddAccount3 from "./screens/onboarding/addAccount3";
import AddAccount4 from "./screens/onboarding/addAccount4";
import AddAccount5 from "./screens/onboarding/addAccount5";
import AddAccount6 from "./screens/onboarding/addAccount6";
import Error404 from "./screens/pages/error404";
import AddAccountFinal from "./screens/onboarding/addAccountFinal";
import Writers from "./screens/authenticated/dashboard/writers/writers";
import WritersCreateUpdate from "./screens/authenticated/dashboard/writers/writersCreateUpdate";
import Onboarding1 from "./screens/onboarding/onboarding1";
import Onboarding2 from "./screens/onboarding/onboarding2";
import Onboarding3 from "./screens/onboarding/onboarding3";
import AppState from "./store/appstate";
import ResetPasswordMessage from "./screens/auth/resetPasswordMessage";
import ResetPasswordConfirm from "./screens/auth/resetPasswordConfirm";
import ResetPasswordSuccess from "./screens/auth/resetPasswordSuccess";
import AddTwitter from "./screens/onboarding/addTwitter";
import AddInstagram from "./screens/onboarding/addInstagram";
import AddFacebook from "./screens/onboarding/addFacebook";
import AddSongkick from "./screens/onboarding/addSongkick";
import AddBandsintown from "./screens/onboarding/addBandsintown";
import OpportunitiesApply from "./screens/authenticated/dashboard/opportunities/opportunitiesApply";
import Syncs from "./screens/authenticated/dashboard/syncs/syncs";
import SyncsApply from "./screens/authenticated/dashboard/syncs/syncsApply";
import Broadcasts from "./screens/authenticated/dashboard/broadcasts/broadcasts";
import BroadcastsCreateUpdate from "./screens/authenticated/dashboard/broadcasts/broadcastsCreateUpdate";
import SetlistsCreateUpdate from "./screens/authenticated/dashboard/gigs/setlistsCreateUpdate";
import Tracking from "./helpers/tracking";
import Terms from "./screens/pages/terms";
import PrivacyPolicy from "./screens/pages/privacyPolicy";
import AddAccountPre from "./screens/onboarding/addAccountPre";
import Settings from "./screens/authenticated/dashboard/settings/settings";
import Statements from "./screens/authenticated/dashboard/statements/statements";
import MaintenanceMode from "./screens/pages/maintenanceMode";
import Security from "./screens/authenticated/dashboard/security/security";
import SecuritySuccess from "./screens/authenticated/dashboard/security/securitySuccess";
import SecurityFailed from "./screens/authenticated/dashboard/security/securityFailed";
import BeginSecurityCheck from "./screens/authenticated/dashboard/security/beginSecurityCheck";
import SideNavLayout from "./layouts/sideNavLayout";
import {
	AddNewSongMenu,
	BroadcastsMenu,
	GigsMenu,
	OpportunitiesMenu,
	SyncsMenu,
} from "./layouts/subSideNavMenus";
import TcAuth from "./screens/auth/tcAuth";
import RaAuth from "./screens/auth/raAuth";
import ldDefaultUser from "./screens/auth/ldDefaultUser";
import Loading from "./layouts/loading";
import FaqPage from "./screens/authenticated/dashboard/faq/faq";
import OnboardingHome from "./screens/onboarding/OnboardingHome";
import SongsCreateSameSplit from "./screens/authenticated/dashboard/songs/songsCreateSameSplit";

if (import.meta.env.VITE_STAGE === "prd") {
	try {
		const config = {
			sessionSampleRate: 1,
			guestRoleArn:
				"arn:aws:iam::589126456693:role/RUM-Monitor-eu-west-1-589126456693-3425148983861-Unauth",
			identityPoolId: "eu-west-1:3517ae3d-64c2-4187-9440-a0a1844ae0c4",
			endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
			telemetries: ["performance", "errors", "http"],
			allowCookies: true,
			enableXRay: false,
		};
		const APPLICATION_ID = "2f805d61-28e9-4fac-a724-b63ea62e633e";
		const APPLICATION_VERSION = "1.0.0";
		const APPLICATION_REGION = "eu-west-1";
		// eslint-disable-next-line no-unused-vars
		const rum = new AwsRum(
			APPLICATION_ID,
			APPLICATION_VERSION,
			APPLICATION_REGION,
			config
		);
	} catch (error) {
		console.log(error);
		// Ignore errors thrown during CloudWatch RUM web client initialization
	}
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
		},
	},
});

const PrivateRoute = ({ children, ...rest }) => {
	const accessToken = AppState.useState((s) => s?.authentication?.access_token);

	return (
		<Route
			{...rest}
			render={({ location: routeLocation }) => {
				const { pathname } = routeLocation;
				if (accessToken && accessToken.length > 0) {
					return children;
				}
				return (
					<Redirect
						to={{
							pathname: "/login",
							state: { redirectUrl: pathname },
						}}
					/>
				);
			}}
		/>
	);
};

function App() {
	const authentication = AppState.useState((s) => s?.authentication);
	const accessToken = authentication?.access_token;
	const flags = useFlags();
	const maintenanceEnabled = flags["maintenance-mode"];
	const showOldSignUp = flags["pd-show-old-sign-up-flow"];
	const showOldAddSong = flags["pd-hide-add-song-rework"];

	const saApiUrl = getApiUrl();
	AppState.update((s) => {
		s.apiUrl = saApiUrl;
	});

	const isRoughTrade = AppState.useState((s) =>
		s?.clientDisplayName?.includes("Rough Trade Publishing")
	);

	return (
		<div>
			<Suspense fallback={<Loading />}>
				<QueryClientProvider client={queryClient}>
					<LdSetup />
					<ToastContainer />
					{/* <ReactQueryDevtools initialIsOpen={false} /> */}
					{maintenanceEnabled ? (
						<BasicLayout>
							<MaintenanceMode />
						</BasicLayout>
					) : (
						<Router>
							<ErrorBoundary
								fallback={({ error, componentStack, resetError }) => (
									<>
										{import.meta.env.VITE_STAGE === "prd" ? (
											<BasicLayout>
												<Error404 resetError={resetError} error={error} />
											</BasicLayout>
										) : (
											<>
												<div className='h-screen py-10 justify-center flex flex-col items-center w-full px-10 gap-4 lg:pt-32 lg:px-28 bg-sentric-rebrand-dark-background text-white font-sentric'>
													<div>
														<p>You have encountered an error</p>
														{error.toString()}
													</div>
													<p>{componentStack}</p>
													<button
														className='border border-white p-2 rounded-lg'
														type='button'
														onClick={resetError}
													>
														Try Again
													</button>
												</div>
											</>
										)}
									</>
								)}
							>
								{!!accessToken && <Tracking />}
								<Switch>
									<PrivateRoute exact path='/'>
										<AuthLayout>
											<Dashboard />
										</AuthLayout>
									</PrivateRoute>
									{/* Error Page for Error Exception */}
									<PrivateRoute exact path='/error-404'>
										<AuthLayout>
											<Error404 />
										</AuthLayout>
									</PrivateRoute>
									<PrivateRoute exact path='/security'>
										<AuthLayout>
											<Security />
										</AuthLayout>
									</PrivateRoute>
									<PrivateRoute exact path='/security-success'>
										<AuthLayout>
											<SecuritySuccess />
										</AuthLayout>
									</PrivateRoute>
									<PrivateRoute exact path='/security-failed'>
										<AuthLayout>
											<SecurityFailed />
										</AuthLayout>
									</PrivateRoute>
									<PrivateRoute exact path='/security/begin-security-check'>
										<BeginSecurityCheck />
									</PrivateRoute>
									<PrivateRoute exact path='/privacy-policy'>
										<AuthLayout>
											<PrivacyPolicy />
										</AuthLayout>
									</PrivateRoute>

									{!isRoughTrade && (
										<PrivateRoute exact path='/royalties'>
											<AuthLayout>
												<Royalties />
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/gigs'>
											<AuthLayout>
												<SideNavLayout menu={<GigsMenu />}>
													<Gigs />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/gigs/claim'>
											<AuthLayout>
												<SideNavLayout menu={<GigsMenu />}>
													<GigsClaim key='create' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/gigs/claim/update/:id'>
											<AuthLayout>
												<SideNavLayout menu={<GigsMenu />}>
													<GigsClaim key='update' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/gigs/setlists'>
											<AuthLayout>
												<SideNavLayout menu={<GigsMenu />}>
													<Setlists />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/gigs/setlists/create'>
											<AuthLayout>
												<SideNavLayout menu={<GigsMenu />}>
													<SetlistsCreateUpdate key='create' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/gigs/setlists/update/:id'>
											<AuthLayout>
												<SideNavLayout menu={<GigsMenu />}>
													<SetlistsCreateUpdate key='update' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/writers'>
											<AuthLayout>
												<Writers />
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/writers/create'>
											<AuthLayout>
												<WritersCreateUpdate key='create' />
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/writers/update/:id'>
											<AuthLayout>
												<WritersCreateUpdate key='update' />
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/songs'>
											<AuthLayout>
												<Songs />
											</AuthLayout>
										</PrivateRoute>
									)}

									{!showOldAddSong && !isRoughTrade && (
										<PrivateRoute exact path='/songs/create'>
											<AuthLayout>
												<SongsCreateUpdate key='create' />
											</AuthLayout>
										</PrivateRoute>
									)}

									{showOldAddSong && !isRoughTrade && (
										<PrivateRoute exact path='/songs/create'>
											<AuthLayout>
												<SideNavLayout menu={<AddNewSongMenu />}>
													<SongsCreateUpdate key='create' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{showOldAddSong && !isRoughTrade && (
										<PrivateRoute exact path='/songs/create-same-split'>
											<AuthLayout>
												<SideNavLayout menu={<AddNewSongMenu />}>
													<SongsCreateSameSplit key='create-same-split' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/songs/update/:id'>
											<AuthLayout>
												<SongsCreateUpdate key='update' />
											</AuthLayout>
										</PrivateRoute>
									)}
									{!isRoughTrade && (
										<PrivateRoute exact path='/opportunities'>
											<AuthLayout>
												<SideNavLayout menu={<OpportunitiesMenu />}>
													<Opportunities />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/opportunities/apply'>
											<AuthLayout>
												<SideNavLayout menu={<OpportunitiesMenu />}>
													<OpportunitiesApply key='create' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/opportunities/apply/:id'>
											<AuthLayout>
												<SideNavLayout menu={<OpportunitiesMenu />}>
													<OpportunitiesApply key='update' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/syncs'>
											<AuthLayout>
												<SideNavLayout menu={<SyncsMenu />}>
													<Syncs />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/syncs/apply'>
											<AuthLayout>
												<SideNavLayout menu={<SyncsMenu />}>
													<SyncsApply key='create' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/syncs/apply/:id'>
											<AuthLayout>
												<SideNavLayout menu={<SyncsMenu />}>
													<SyncsApply key='update' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/broadcasts'>
											<AuthLayout>
												<SideNavLayout menu={<BroadcastsMenu />}>
													<Broadcasts />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/broadcasts/add'>
											<AuthLayout>
												<SideNavLayout menu={<BroadcastsMenu />}>
													<BroadcastsCreateUpdate key='create' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									{!isRoughTrade && (
										<PrivateRoute exact path='/broadcasts/add/:id'>
											<AuthLayout>
												<SideNavLayout menu={<BroadcastsMenu />}>
													<BroadcastsCreateUpdate key='update' />
												</SideNavLayout>
											</AuthLayout>
										</PrivateRoute>
									)}

									<PrivateRoute exact path='/statements'>
										<AuthLayout>
											<Statements />
										</AuthLayout>
									</PrivateRoute>
									<PrivateRoute exact path='/faq'>
										<AuthLayout>
											<FaqPage />
										</AuthLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/settings'>
										<AuthLayout>
											<Settings />
										</AuthLayout>
									</PrivateRoute>

									<Route exact path='/sign-up'>
										<BasicLayout>
											<SignUp />
										</BasicLayout>
									</Route>

									<Route exact path='/welcome'>
										<BasicLayout>
											<Login />
										</BasicLayout>
									</Route>

									<Route exact path='/sign-up-continue'>
										<BasicLayout>
											<SignUpMessage />
										</BasicLayout>
									</Route>

									<Route exact path='/confirm'>
										<BasicLayout>
											<SignUpConfirm />
										</BasicLayout>
									</Route>

									<Route exact path='/login'>
										<BasicLayout>
											<Login />
										</BasicLayout>
									</Route>

									<Route exact path='/terms-and-conditions'>
										<BasicLayout>
											<Terms />
										</BasicLayout>
									</Route>

									<Route exact path='/privacy-policy'>
										<BasicLayout>
											<PrivacyPolicy />
										</BasicLayout>
									</Route>

									<Route exact path='/reset-password'>
										<BasicLayout>
											<ResetPassword />
										</BasicLayout>
									</Route>

									<Route exact path='/reset-password-message'>
										<BasicLayout>
											<ResetPasswordMessage />
										</BasicLayout>
									</Route>

									<Route exact path='/reset-password-success'>
										<BasicLayout>
											<ResetPasswordSuccess />
										</BasicLayout>
									</Route>

									<Route exact path='/reset'>
										<BasicLayout>
											<ResetPasswordConfirm />
										</BasicLayout>
									</Route>

									<Route exact path='/tcauth'>
										<BasicLayout>
											<TcAuth />
										</BasicLayout>
									</Route>

									<Route exact path='/raauth'>
										<BasicLayout>
											<RaAuth />
										</BasicLayout>
									</Route>

									{!showOldSignUp && (
										<PrivateRoute exact path='/onboarding'>
											<BasicLayout>
												<OnboardingHome />
											</BasicLayout>
										</PrivateRoute>
									)}

									{showOldSignUp && (
										<PrivateRoute exact path='/onboarding-1'>
											<BasicLayout>
												<Onboarding1 />
											</BasicLayout>
										</PrivateRoute>
									)}

									{showOldSignUp && (
										<PrivateRoute exact path='/onboarding-2'>
											<BasicLayout>
												<Onboarding2 />
											</BasicLayout>
										</PrivateRoute>
									)}

									{showOldSignUp && (
										<PrivateRoute exact path='/onboarding-3'>
											<BasicLayout>
												<Onboarding3 />
											</BasicLayout>
										</PrivateRoute>
									)}

									<PrivateRoute exact path='/add-account-pre'>
										<BasicLayout>
											<AddAccountPre />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-account-1'>
										<BasicLayout>
											<AddAccount1 />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-account-2'>
										<BasicLayout>
											<AddAccount2 />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-account-3'>
										<BasicLayout>
											<AddAccount3 />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-account-4'>
										<BasicLayout>
											<AddAccount4 />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-account-5'>
										<BasicLayout>
											<AddAccount5 />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-account-6'>
										<BasicLayout>
											<AddAccount6 />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-twitter'>
										<BasicLayout>
											<AddTwitter />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-instagram'>
										<BasicLayout>
											<AddInstagram />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-facebook'>
										<BasicLayout>
											<AddFacebook />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-songkick'>
										<BasicLayout>
											<AddSongkick />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-bandsintown'>
										<BasicLayout>
											<AddBandsintown />
										</BasicLayout>
									</PrivateRoute>

									<PrivateRoute exact path='/add-account-final'>
										<BasicLayout>
											<AddAccountFinal />
										</BasicLayout>
									</PrivateRoute>
									{/* Error that happens when page doesn't show */}
									<PrivateRoute>
										<BasicLayout>
											<Error404 invert />
										</BasicLayout>
									</PrivateRoute>
								</Switch>
							</ErrorBoundary>
						</Router>
					)}
				</QueryClientProvider>
			</Suspense>
		</div>
	);
}

export default withLDProvider({
	clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_ID,
	context: ldDefaultUser,
	reactOptions: {
		useCamelCaseFlagKeys: false,
	},
	// deferInitialization: true,
	options: {
		allAttributesPrivate: true,
	},
})(App);
