/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable radix */

const MODULUS_101 = 101;
const SPECIAL_CASE = 1;
const DEFAULT_CHECK_SUM = 0;

function calculateModulo101CheckSum(nineDigitNumber) {
  let sum = 0;
  let subStringCounter = 0;
  for (let i = 10; i > 1; i--) {
    const digit = parseInt(nineDigitNumber.substring(subStringCounter, subStringCounter + 1));
    const newDigit = digit * i;
    sum = sum + newDigit;
    subStringCounter++;
  }
  let checkSum = sum % MODULUS_101;
  if (checkSum !== 0) {
    if (checkSum === SPECIAL_CASE) {
      return DEFAULT_CHECK_SUM;
    }
    checkSum = MODULUS_101 - checkSum;
  }
  return checkSum;
}


function calculateCheckSum(ipNameNumber) {
  const truncatedNumber = ipNameNumber.toString().slice(0, -2);
  const nineDigitNumber = truncatedNumber.padStart(9, '0');

  // Return the result of the calculateModulo101CheckSum function
  return calculateModulo101CheckSum(nineDigitNumber);
}

export default function validateIPNameNumber(ipNameNumber) {
  const calculatedCheckSum = calculateCheckSum(ipNameNumber);
  const lastTwoDigits = ipNameNumber % 100;

  console.log(calculatedCheckSum, ' == ', lastTwoDigits);
  return calculatedCheckSum === lastTwoDigits;
}
