/* eslint-disable react/no-danger */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { format } from "date-fns";
import H2 from "../../../../components/h2";
import Input from "../../../../components/input";
import Spacer from "../../../../components/spacer";
import InnerRadio from "../../../../components/innerRadio";
import ButtonInner from "../../../../components/buttonInner";
import FormError from "../../../../components/formError";
import AppState from "../../../../store/appstate";
import {
	getBroadcast,
	getBroadcasts,
	getMyAccount,
	getMySongs,
	getMyProfile,
} from "../../../../api/queries";
import {
	postBroadcast,
	putBroadcast,
	updateSong,
} from "../../../../api/mutations";
import ButtonIcon from "../../../../images/rebrandIcons/ButtonIcon.png";
import LoadingSmall from "../../../../components/loadingSmall";
import TextArea from "../../../../components/textArea";
import Select from "../../../../components/select";
import { sendProperty } from "../../../../helpers/helpers";
import countries from "../../../../data/countries";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";

function BroadcastsCreateUpdate(props) {
	const { t } = useTranslation(["other", "countries"]);
	const { id } = useParams();

	const isUpdate = !!id;
	const profile = getMyProfile();
	const [medium, setMedium] = useState();
	const [station, setStation] = useState();
	const [program, setProgram] = useState();
	const [country, setCountry] = useState();
	const [dateAired, setDateAired] = useState();
	const [chosenSong, setChosenSong] = useState();
	const [evidence, setEvidence] = useState();

	const [pending, setPending] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
	const [errors, setErrors] = useState({});

	const history = useHistory();
	const createMutation = postBroadcast();
	const updateMutation = putBroadcast();

	const selectedAccount = AppState.useState((s) => s.selectedAccount);

	const broadcast = getBroadcast({ ac: selectedAccount, id });
	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});

	const broadcasts = getBroadcasts({
		ac: selectedAccount,
		oc: myAccount?.data?.originatorcode?.code,
	});

	const songs = getMySongs({ aac: selectedAccount });
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";

	useEffect(() => {
		if (selectedAccount) {
			songs.refetch();
			broadcast.refetch();
		}
	}, [selectedAccount]);

	if (id && broadcast.data?.length === 0) {
		history.push("/broadcasts");
	}

	if (
		broadcast.isSuccess &&
		broadcast.data &&
		broadcast.data[0] &&
		id &&
		!medium
	) {
		const dateNotValid = Number.isNaN(Date.parse(broadcast.data[0].date_aired));
		setDateAired(
			dateNotValid
				? null
				: format(Date.parse(broadcast.data[0].date_aired), "yyyy-MM-dd")
		);
		setEvidence(broadcast.data[0]?.evidence);
		setMedium(broadcast.data[0]?.medium);
		setCountry(broadcast.data[0]?.country);
		setProgram(broadcast.data[0]?.program);
		setChosenSong(
			JSON.parse(broadcast.data[0].song).workcode ||
				JSON.parse(broadcast.data[0].song).tc
		);
		setStation(broadcast.data[0].station);
	}

	const checkErrors = () => {
		const errs = {};

		let valid = true;
		// title check
		if (!chosenSong) {
			errs.chosenSong = t("regex:select-song-error");
			valid = false;
		}

		if (!dateAired) {
			errs.dateAired = t("regex:date-aired-error");
			valid = false;
		}

		function isValidDate(d) {
			return d instanceof Date && !Number.isNaN(d);
		}

		if (!isValidDate(new Date(dateAired))) {
			errs.dateAired = t("regex:invalid-date-error");
			valid = false;
		}

		if (new Date(dateAired) > new Date()) {
			errs.dateAired = t("regex:date-in-future-error");
			valid = false;
		}

		if (!country) {
			errs.country = t("regex:choose-country-error");
			valid = false;
		}

		if (!medium) {
			errs.medium = t("regex:choose-medium-error");
			valid = false;
		}

		if (!program || program.trim().length === 0) {
			errs.program = t("regex:add-program-error");
			valid = false;
		}

		if (!station || station.trim().length === 0) {
			errs.station = t("regex:add-station-error");
			valid = false;
		}

		if (!evidence || evidence.trim().length === 0) {
			errs.evidence = t("regex:add-evidence-error");
			valid = false;
		}

		setErrors(errs);
		return valid;
	};

	const addBroadcast = async () => {
		setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
		// reset mutation
		createMutation.reset();
		const valid = checkErrors();

		if (valid) {
			const data = {
				account_name: myAccount.data.artistaccountcode,
				legacy_name: myAccount?.data?.originatorcode?.code,
				song: JSON.stringify(
					songs.data.works.find((w) => w.workcode === chosenSong)
				),
				medium,
				country,
				station,
				program,
				date_aired: dateAired,
				evidence,
			};

			createMutation.mutate(data, {
				onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
					toast.success(t("other:successfully-added-broadcast-toast"), {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					broadcasts.refetch();
					broadcast.refetch();
					history.push("/broadcasts");
					sendProperty({
						property: "broadcast_opp_applicant",
						value: true,
						email: profile.data.email,
					});
					setPending(false);
					}
				},
				onError: (error) => {
          setMutationErrorMessage(error.message);
					setPending(false);
				},
			});
		} else {
			setPending(false);
		}
	};

	const updateBroadcast = async () => {
		setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
		// reset mutation
		updateMutation.reset();
		const valid = checkErrors();

		if (valid) {
			const data = {
				id,
				account_name: myAccount.data.artistaccountcode,
				legacy_name: myAccount.data?.originatorcode?.code,
				song: JSON.stringify(
					songs.data.works.find((w) => w.workcode === chosenSong)
				),
				medium,
				country,
				station,
				program,
				date_aired: dateAired,
				evidence,
			};

			updateMutation.mutate(data, {
				onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
					toast.success(t("other:successfully-updated-broadcast-toast"), {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					broadcasts.refetch();
					history.push("/broadcasts");
					setPending(false);
					}
				},
				onError: (error) => {
          setMutationErrorMessage(error.message);
					setPending(false);
				},
			});
		} else {
			setPending(false);
		}
	};

	return (
		<>
			<div className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'>
				{songs.isSuccess && (
					<div>
						<div className='flex flex-wrap justify-between'>
							<H2>
								{isUpdate
									? t("other:update-broadcast")
									: t("other:add-broadcast")}
							</H2>
						</div>
						<Spacer />
						<div className='w-full lg:w-2/3'>
							<Select
								id='txtBroadcastSelectSong'
								className='w-full text-old-sentric-dark-gray'
								name='country'
								placeholder={t("other:select-a-song-placeholder")}
								error={errors.chosenSong}
								value={chosenSong}
								onChange={(selected) => {
									setChosenSong(selected);
								}}
								options={songs?.data?.works.map((s) => ({
									name: s.title,
									value: s.workcode,
								}))}
								search
								required
							/>
							<Spacer />
							<InnerRadio
								name='medium'
								label={t("other:choose-medium")}
								value={medium}
								onChange={(e) => setMedium(e.target.value)}
								options={[
									{ label: t("other:radio"), value: "Radio" },
									{ label: t("other:tv"), value: "TV" },
								]}
								error={errors.medium}
								required
							/>
							<Spacer />
							<Input
								id='txtBroadcastStation'
								required
								type='text'
								error={errors.station}
								placeholder={t("other:station-channel-placeholder")}
								value={station}
								onBlur={() => {}}
								onChange={(e) => {
									setStation(e.target.value);
								}}
							/>
							<Spacer />
							<Input
								id='txtBroadcastProgram'
								required
								type='text'
								error={errors.program}
								placeholder={t("other:program-placeholder")}
								value={program}
								onBlur={() => {}}
								onChange={(e) => {
									setProgram(e.target.value);
								}}
							/>
							<Spacer />
							<Select
								id='txtBroadcastCountry'
								className='w-full text-old-sentric-dark-gray'
								name='country'
								error={errors.country}
								placeholder={t("other:select-country-placeholder")}
								value={country}
								onChange={(selected) => {
									setCountry(selected);
								}}
								options={countries.map((option) => ({
									name: t(`countries:${option.name.replace(" ", "-")}`),
									value: option.value,
								}))}
								search
								required
							/>
							<Spacer />
							<Input
								id='dateBroadcastDate'
								required
								className={`${
									isTunecore ? "tunecore-datepicker" : "themed-datepicker"
								} relative z-20`}
								type='date'
								dateFormatCalendar=' '
								showMonthDropdown
								showYearDropdown
								dateDropdownMode='select'
								useShortMonthInDropdown
								error={errors.dateAired}
								placeholder={t("other:date-aired-placeholder")}
								value={dateAired}
								onBlur={() => {}}
								onChange={(date) => {
									setDateAired(date);
								}}
							/>
							<Spacer />
							<TextArea
								name='txtBroadcastEvidence'
								required
								error={errors.evidence}
								placeholder={t("other:evidence-placeholder")}
								value={evidence}
								onBlur={() => {}}
								onChange={(e) => {
									setEvidence(e.target.value);
								}}
							/>
							<Spacer />
							<FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
							<div className='w-full flex justify-end'>
								<RebrandPrimaryButton
									id={isUpdate ? "btnBroadcastEdit" : "btnBroadcastAdd"}
									onClick={isUpdate ? updateBroadcast : addBroadcast}
									disabled={pending}
									pending={pending}
								>
									{isUpdate
										? t("other:update-broadcast")
										: t("other:add-broadcast")}
								</RebrandPrimaryButton>
							</div>
						</div>
					</div>
				)}
				{songs.isLoading && <LoadingSmall />}
			</div>
		</>
	);
}
export default BroadcastsCreateUpdate;
