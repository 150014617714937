import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import Spacer from "../../../components/spacer";
import Input from "../../../components/input";
import AppState from "../../../store/appstate";
import useDomainConfigAuth from "../../../api/sanity/queries/getSiteConfigWithDomain";

function OnboardingSocials({ setIsLocked }) {
	const { t } = useTranslation("onboarding");
	const twitterResult = AppState.useState((s) => s.addAccount.twitter);
	const facebookResult = AppState.useState((s) => s.addAccount.facebook);
	const instagramResult = AppState.useState((s) => s.addAccount.instagram);
	const { data: domainConfig } = useDomainConfigAuth();
	const [facebookError, setFacebookError] = useState("");
	const isTunecore = domainConfig?.display_name === "TuneCore";

	const [twitter, setTwitter] = useState(twitterResult || "");
	const [instagram, setInstagram] = useState(instagramResult || "");
	const [facebook, setFacebook] = useState(facebookResult || "");

	useEffect(() => {
		AppState.update((s) => {
			s.addAccount.twitter = twitter;
			s.addAccount.instagram = instagram;
			s.addAccount.facebook = facebook;
		});
	}, [twitter, instagram, facebook]);

	useEffect(() => {
		setIsLocked(facebookError);
	}, [setIsLocked, facebookError]);

	const validateFacebookUrl = (url) => {
		const facebookRegex = /^(http|https):\/\/(?:www.)?facebook.com\/.*$/;
		if (url && !facebookRegex.test(url)) {
			setFacebookError(t("regex:facebook-domain-regex"));
			return false;
		}
		setFacebookError("");
		return true;
	};

	const handleFacebookChange = (e) => {
		const { value } = e.target;
		setFacebook(value);
		if (value === "") {
			setFacebookError("");
		}
	};

	return (
		<div>
			<div className='flex flex-col items-center justify-center text-center text-white'>
				<Spacer />
				<div className='flex flex-col flex-wrap w-full xl:w-1/2 justify-center items-center gap-10'>
					{/* Instagram */}
					<div className='flex flex-col items-center w-3/4 text-white rounded-lg'>
						<div className='gap-2 justify-center w-full flex flex-row'>
							<div>
								<FaInstagram className='text-xl' />
							</div>
							<p>{t("onboarding:_instagram")}</p>
						</div>
						<Input
							className={`${
								isTunecore ? "" : "auth-page-input self-center w-full"
							}`}
							placeholder='@username'
							whiteLabelText
							value={instagram}
							onChange={(e) => setInstagram(e.target.value)}
						/>
					</div>

					{/* Facebook */}
					<div className='flex flex-col items-center text-white w-3/4 rounded-lg'>
						<div className='gap-2 justify-center w-full flex flex-row'>
							<FaFacebook className='text-xl' />
							<p>{t("onboarding:_facebook")}</p>
						</div>

						<Input
							className={`${
								isTunecore ? "" : "auth-page-input self-center w-full"
							}`}
							whiteLabelText
							error={facebookError}
							placeholder={t("onboarding:facebook-url-placeholder")}
							value={facebook}
							onBlur={(e) => validateFacebookUrl(e.target.value)}
							onChange={handleFacebookChange}
						/>
					</div>

					{/* Twitter */}
					<div className='flex flex-col items-center text-white w-3/4 rounded-lg'>
						<div className='gap-2 justify-center w-full flex flex-row'>
							<FaTwitter className='text-xl' />
							<p>{t("onboarding:_twitter")}</p>
						</div>

						<Input
							className={`${
								isTunecore ? "" : "auth-page-input self-center w-full"
							}`}
							placeholder='@username'
							whiteLabelText
							value={twitter}
							onChange={(e) => setTwitter(e.target.value)}
						/>
					</div>
				</div>

				<Spacer />
			</div>
		</div>
	);
}

export default OnboardingSocials;
