/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import short from "short-uuid";
import {
	createRecording,
	updateRecordingMeta,
} from "../../../../api/mutations";
import {
	getMyRecordingsDetailed,
	getMySong,
	getRecordingMetas,
	getMyProfile,
} from "../../../../api/queries";
import FormError from "../../../../components/formError";
import H2 from "../../../../components/h2";
// import HR from '../../../../components/hr';
// import H4 from '../../../../components/h4';
import Input from "../../../../components/input";
import Spacer from "../../../../components/spacer";
import AppState from "../../../../store/appstate";
import { sendEvent, sendProperty } from "../../../../helpers/helpers";
import LoadingSmall from "../../../../components/loadingSmall";
import RebrandButtonLink from "../../../../components/rebrandButtonLink";
import Select from "../../../../components/select";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";
import getColours from "../../../../helpers/getColours";

// const short = require('short-uuid');

function RecordingForm(props) {
	const { id, closeForm, aac = null } = props;
	let selectedAccount = null;
	if (aac) {
		selectedAccount = aac;
	} else {
		selectedAccount = AppState.useState((s) => s.selectedAccount);
	}
	const song = getMySong({ aac: selectedAccount, wc: id });
	const recordings = getMyRecordingsDetailed({ aac: selectedAccount, wc: id });
	const { data: siteConfig } = useSiteConfigAuth();
	const { highlightColour } = getColours(siteConfig);
	const isTunecore = siteConfig?.display_name === "TuneCore";

	const [pending, setPending] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
	const [errors, setErrors] = useState({});

	const [title, setTitle] = useState();
	const [performingartist, setPerformingartist] = useState();
	const [isrc, setIsrc] = useState("");
	const [recordlabel, setRecordlabel] = useState();
	const [releasedate, setReleasedate] = useState();
	// eslint-disable-next-line no-unused-vars
	const [spotifyResult, setSpotifyResult] = useState();

	// eslint-disable-next-line no-unused-vars
	const [recordingId, setRecordingId] = useState(null);

	const recordingMetas = getRecordingMetas({ id: recordingId });

	const createMutation = createRecording();
	const recordingMetaMutation = updateRecordingMeta();

	const profile = getMyProfile();

	const [apiToken, setAPIToken] = useState(null);

	const getSpotifyAPIToken = async () => {
		const apiUrl = AppState.getRawState()?.apiUrl;

		const response = await fetch(`${apiUrl}/spotify-token`);
		const responseData = await response.json();
		return responseData.access_token;
	};

	const { t } = useTranslation("songs");
	const isrcRegex = new RegExp("^[A-Z]{2}[A-Z0-9]{3}\\d{7}$");

	useEffect(() => {
		async function go() {
			setAPIToken(await getSpotifyAPIToken());
		}
		go();
	}, []);

	useEffect(() => {
		if (selectedAccount) {
			song.refetch();
		}
	}, [selectedAccount]);

	if (song.isError || song.data === null) {
		closeForm();
	}

	const checkErrors = (passed = {}) => {
		const errs = {};
		let valid = true;
		// title check
		const validTitle = title?.trim().length > 0;
		if (!validTitle) {
			errs.title = t("regex:recording-enter-title-regex");
			valid = false;
		}

		// PA check
		const validPA = performingartist?.trim().length > 0;
		if (!validPA) {
			errs.performingartist = t("regex:recording-add-performing-artist-regex");
			valid = false;
		}

		const isrcPassed = passed.isrc || isrc;
		const enteredIsrc = isrcPassed?.trim().length > 0;

		if (!isrcRegex.test(isrcPassed) && enteredIsrc) {
			valid = false;
			errs.isrc = t("regex:recording-incorrect-isrc");
		}

		setErrors(errs);
		return valid;
	};

	const addRecordingMeta = (rid) => {
    setMutationErrorMessage('');
    setValidationErrorMessage('');
		recordingMetaMutation.reset();
		setRecordingId(rid);
		const data = {
			id: rid,
			spotify_id: spotifyResult ? spotifyResult.id : null,
			thumb_url: spotifyResult ? spotifyResult?.album?.images[0].url : null,
			song_id: song.data.workcode,
		};

		recordingMetaMutation.mutate(data, {
			onSuccess: (response) => {
				if (response.failedvalidation) {
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
				song.refetch();
				recordings.refetch();
				recordingMetas.refetch();

				toast.success(`${t("songs:recording-added-toast")}: ${title}`, {
					position: "bottom-right",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});

				sendProperty({
					property: "last_recording_added",
					value: "CURRENT_DATE",
					email: profile?.data?.email,
				});
				sendEvent({ property: "pe14489857_recording_added" });

				closeForm();
				setPending(false);
			}
			},
			onError: (error) => {
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};

	const addRecording = async () => {
		setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
		// reset mutation
		createMutation.reset();
		const valid = checkErrors();
		const date =
			releasedate?.length === 4 ? `${releasedate}-01-01` : releasedate;
		if (valid) {
			const data = {
				artistaccountcode: selectedAccount,
				title,
				performingartist,
				isrc,
				recordlabel,
				// generate out own custom code as UIC not actually used
				// so we can use if for matching front-end data with backend data
				uicbarcode: short.generate().substring(0, 13),
				releasedate: date,
				workcode: song.data.workcode,
				spotifyidentifier: spotifyResult?.id,
				spotifypopularity: spotifyResult?.popularity,
				spotifypopularitylastupdatedutc:
					spotifyResult?.id === undefined || spotifyResult?.id === null
						? null
						: new Date().toISOString(),
				etag: song.data.etag,
			};
			createMutation.mutate(data, {
				onSuccess: (result) => {
          if (result.failedvalidation) {
            setValidationErrorMessage(result.messages.join(', '));
            setPending(false);
          } else {
						addRecordingMeta(result.model.uicbarcode, null);
					}
				},
				onError: (error) => {
          setMutationErrorMessage(error.message);
					setPending(false);
				},
			});
		} else {
			setPending(false);
		}
	};

	const handleChangeSelect = (track) => {
		const tr = JSON.parse(track);
		const date =
			tr?.album.release_date === 4
				? `${tr.album.release_date}-01-01`
				: tr?.album.release_date;
		setTitle(tr.name);
		setPerformingartist(tr.artists[0].name);
		setIsrc(tr?.external_ids?.isrc);
		setReleasedate(date);
		setSpotifyResult(tr);
	};

	const getOptions = (query) =>
		new Promise((resolve, reject) => {
			if (!query) {
				resolve([]);
				return;
			}
			fetch(
				`https://api.spotify.com/v1/search?q=${query}&type=track&market=GB&limit=50`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${apiToken}`,
					},
				}
			)
				.then((response) => response.json())
				.then(({ tracks }) => {
					resolve(
						tracks.items.map((track) => ({
							value: JSON.stringify(track),
							name: `${track.name} - ${track.artists[0].name}`,
							photo:
								track.album &&
								track.album.images &&
								track.album.images.length > 0
									? track.album.images[0].url
									: null,
						}))
					);
					resolve([]);
					setSpotifyResult(null);
				})
				.catch(reject);
		});

	if (song.isSuccess && song.data) {
		return (
			<div className={`${isTunecore ? "text-white" : ""}`}>
				<div className='mr-10'>
					<H2>
						{t("songs:add-recording-header")} <b>{song.data.title}</b>
					</H2>
				</div>
				<Spacer thin />
				<div className='w-full'>
					<div
						style={{
							backgroundColor: isTunecore ? "#333333" : highlightColour,
						}}
						className={
							isTunecore
								? "text-white px-12 py-10 rounded-lg "
								: "px-12 py-10 rounded-lg  text-old-sentric-dark-gray"
						}
					>
						<h2 className='text-xl'>{t("songs:import-spotify-details")}</h2>
						<Spacer thin />
						<p className='font-sentric-alt'>
							{t("songs:spotify-auto-populate-search")}
						</p>
						<Spacer thin />
						<div className='flex flex-row items-start justify-center w-full'>
							<Select
								className={`select-search ${isTunecore ? 'tunecore-select-alt' : ''} select-search--multiple font-sentric-alt text-base text-sentric-rebrand-grey-text`}
								debounce={500}
								onChange={handleChangeSelect}
								getOptions={getOptions}
								placeholder={t("songs:search-on-spotify-placeholder")}
								search
								blackIcon={!isTunecore}
								showLabel={false}
							/>
						</div>
						<Spacer thin />
						{!!spotifyResult && (
							<div>
								<iframe
									title='preview'
									src={`https://open.spotify.com/embed/track/${spotifyResult.id}`}
									className='w-full lg:w-3/4'
									height='80'
									frameBorder='0'
									allowtransparency='true'
									allow='encrypted-media'
								/>
							</div>
						)}
					</div>
					<div className=''>
						<Spacer />
						<h2 className='text-xl font-semibold'>
							{t("songs:recording-details-subheader")}
						</h2>

						<Spacer thin />

						<Input
							required
							type='text'
							error={errors.title}
							placeholder={t("songs:title-placeholder")}
							value={title}
							onBlur={() => {}}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
						/>
						<Spacer thin />

						<Input
							required
							type='text'
							error={errors.performingartist}
							placeholder={t("songs:performed-by")}
							value={performingartist}
							onBlur={() => {}}
							onChange={(e) => {
								setPerformingartist(e.target.value);
							}}
						/>

						<Spacer thin />

						<Input
							type='text'
							error={errors.isrc}
							placeholder={t("songs:isrc-placeholder")}
							value={isrc}
							onBlur={() => {}}
							onChange={(e) => {
								setIsrc(e.target.value);
							}}
						/>
						<Spacer thin />

						<Input
							type='text'
							error={errors.recordlabel}
							placeholder={t("songs:record-label-placeholder")}
							value={recordlabel}
							onBlur={() => {}}
							onChange={(e) => {
								setRecordlabel(e.target.value);
							}}
						/>

						<Spacer thin />

						<Input
							type='date'
							className={`${
								isTunecore ? "tunecore-datepicker" : "themed-datepicker"
							} relative z-20`}
							error={errors.releasedate}
							dateFormatCalendar=' '
							showMonthDropdown
							showYearDropdown
							dateDropdownMode='select'
							useShortMonthInDropdown
							placeholder={t("songs:release-date")}
							value={releasedate}
							onBlur={() => {}}
							onChange={(date) => {
								setReleasedate(date);
							}}
						/>
					</div>
					<Spacer />
					<FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
					<div className='flex flex-col items-center justify-between gap-3 sm:flex-row'>
						<>
							<RebrandPrimaryButton
								id='btnRecordingFormAddRecording'
								onClick={addRecording}
								disabled={pending}
								pending={pending}
								arrow={!isTunecore}
							>
								{t("songs:add-recording-button")}
							</RebrandPrimaryButton>
							<RebrandButtonLink onClick={() => closeForm()}>
								{t("songs:cancel-and-close")}
							</RebrandButtonLink>
						</>
					</div>
				</div>
			</div>
		);
	}
	return <LoadingSmall />;
}
export default RecordingForm;
