function SideNavLayout({ menu, children }) {
  return (
    <div className="flex flex-wrap xl:flex-nowrap">
      <div className='xl:w-52 flex-none w-full bg-sentric-rebrand-mid-grey xl:gap-0 grid grid-cols-2 xl:block'>{menu}</div>
      <div className="flex flex-grow mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12">
        {children}
      </div>
    </div>
  );
}

export default SideNavLayout;
