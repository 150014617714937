/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import AppState from '../../../../store/appstate';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const CumulativeGraph = ({ buckets, unique }) => {
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const [currentChart, setCurrentChart] = useState(null);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const drawChart = () => {
    if (currentChart) {
      currentChart.dispose();
    }

    if (!buckets) {
      return;
    }

    const dataSet = buckets;

    let j = 0;
    let total = 0;

    while (j < dataSet.length) {
      const d = dataSet[j];
      total += parseFloat(d.CumulativeSum.value);
      d.valueNon = parseFloat(d.CumulativeSum.value);
      d.value = total;
      j += 1;
    }

    // Create root and chart
    am5.addLicense('AM5C316073875');
    const root = am5.Root.new(`chartCum${unique}`);
    root.numberFormatter.set('numberFormat', '#,###.00');

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    // Craete Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    const yRenderer = yAxis.get('renderer');
    yRenderer.labels.template.setAll({
      fill: isTunecore ? am5.color(0xFFFFFF) : am5.color(0x525560),
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
    });
    yRenderer.grid.template.setAll({
      stroke: isTunecore ? am5.color(0xFFFFFF) : am5.color(0x25BCBB),
      strokeWidth: 1,
      strokeDasharray: [5, 2],
    });

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );
    xAxis.data.setAll(dataSet);

    const xRenderer = xAxis.get('renderer');
    xRenderer.labels.template.setAll({
      fill: isTunecore ? am5.color(0xFFFFFF) : am5.color(0x525560),
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      paddingTop: 20,
    });
    xRenderer.grid.template.setAll({
      stroke: isTunecore ? am5.color(0xFFFFFF) : am5.color(0x25BCBB),
      strokeWidth: 1,
      strokeDasharray: [5, 2],
    });

    // Create series
    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'key',
        stroke: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
        maskBullets: false,

        // tooltip: am5.Tooltip.new(root, {
        //   labelText: '{valueY}',
        //   fill: am5.color(0x25BCBB),
        // }),
      }),
    );
    series.strokes.template.setAll({
      strokeWidth: 4,
    });

    series.fills.template.setAll({
      fillOpacity: 0.3,
      visible: true,
    });

    series.fills.template.set(
      'fillGradient',
      am5.LinearGradient.new(root, {
        stops: [
          {
            color: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
          },
          {
            color: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
            opacity: 0,
          },
        ],
      }),
    );

    root.dateFormatter.set('dateFormat', 'yyyy-MM-dd');
    root.numberFormatter.set('numberFormat', `${currencySymbol}#,###.00`);

    const tooltip = am5.Tooltip.new(root, {
      labelText:
        '[fontFamily:Montserrat; bold; #25BCBB]{valueX.formatDate()}[/]\n[fontFamily:Montserrat; bold; fontSize:18px]{valueY}[/]',
      getFillFromSprite: false,
    });

    tooltip.get('background').setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 1,
      stroke: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
      strokeWidth: 2,
    });

    series.set('tooltip', tooltip);

    series.bullets.push(() => am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 4,
        stroke: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
        strokeWidth: 2,
        fill: am5.color(0xffffff),
      }),
    }));

    series.data.setAll(dataSet);

    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomX',
      }),
    );

    setCurrentChart(root);
  };

  useEffect(() => {
    drawChart();
    // remember to dispose
    return () => {
      if (currentChart) {
        currentChart.dispose();
      }
    };
  }, [buckets]);

  return (
    <div
      id={`chartCum${unique}`}
      style={{ width: '100%', height: '360px', marginTop: '25px' }}
    />
  );
};

export default CumulativeGraph;
