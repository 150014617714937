export default {
    kind: 'multi',
    user: {
        key: 'anonymous',
    },
    tenant: {
        key: 'UNK'
    },
    domain:{
        key: window.location.hostname
    }
};