function ButtonLink(props) {
  const {
    children, onClick, className, id,
  } = props;

  return (
    <button id={id} type="button" className={`underline hover:scale-105 focus:scale-105 focus:ring-2 transition-all duration-150 ease-linear ${className}`} onClick={onClick}>{children}</button>
  );
}

export default ButtonLink;
