/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { addYears, formatISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import H2 from '../../../../components/h2';
import P from '../../../../components/p';
import Spacer from '../../../../components/spacer';
import countries from '../../../../data/countries';
import Select from '../../../../components/select';
import H4 from '../../../../components/h4';
import HR from '../../../../components/hr';
import Input from '../../../../components/input';
import InnerRadio from '../../../../components/innerRadio';
import { apiCall } from '../../../../api/shared';
import {
  getGigClaims,
  getGigClaim,
  getMyAccount,
  getSetlists,
  getMyProfile,
} from '../../../../api/queries';
import AppState from '../../../../store/appstate';
import FormError from '../../../../components/formError';
import {
  postGigClaim,
  updateGigClaim,
  deleteGigClaim,
  s3Put,
} from '../../../../api/mutations';
import { sendEvent, sendProperty, getS3 } from '../../../../helpers/helpers';
import { hasAdminRights } from '../../../../auth/shared';
import NumericalInput from '../../../../components/numericalInput';
import RebrandButtonLink from '../../../../components/rebrandButtonLink';
import RebrandPrimaryButton from '../../../../components/rebrandPrimaryButton';
import Modal from '../../../../components/Modal';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function GigsClaim() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const { data: siteConfig } = useSiteConfigAuth();
  if (siteConfig.disable_gigs) {
    history.push('/');
  };
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [obj, setObj] = useState({});
  const [showManual, setShowManual] = useState(false);
  const [sections, setSections] = useState([]);
  const [festival, setFestival] = useState(false);
  const [otherPerformers, setOtherPerformers] = useState(false);
  const [evidenceValue, setEvidenceValue] = useState(false);
  const [proofOfPerformance, setProofOfPerformance] = useState(false);
  const [pending, setPending] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [underTwelveEuros, setUnderTwelveEuros] = useState(false);
  const [isUsAlertOpen, setIsUsAlertOpen] = useState(false);
  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });
  const setlists = getSetlists({
    ac: selectedAccount,
    oc: myAccount?.data?.originatorcode?.code,
  });
  const createMutation = postGigClaim();
  const updateMutation = updateGigClaim();
  const deleteMutation = deleteGigClaim();
  const putS3 = s3Put();
  const gigClaims = getGigClaims({ ac: selectedAccount });
  const profile = getMyProfile();
  const isUpdate = !!id;
  const gigClaim = getGigClaim({ ac: selectedAccount, id });
  const adminRights = hasAdminRights(myAccount?.data);
  const { t } = useTranslation(['gigs', 'countries', 'common']);
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  let evidenceType = null;
  let evidenceName = null;
  let putUrl = null;

  function isValidURL(url) {
    // Regular expression for basic URL format validation
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/;

    return urlPattern.test(url);
  }

  const promotorFormItems = [
    {
      type: 'text',
      placeholder: t('gigs:claim-promoter-name'),
      name: 'promotorName',
    },
    {
      type: 'text',
      placeholder: t('gigs:claim-promoter-address1'),
      name: 'promotorAddress1',
    },
    {
      type: 'text',
      placeholder: t('gigs:claim-promoter-street'),
      name: 'promotorStreet',
    },
    {
      type: 'text',
      placeholder: t('gigs:claim-promoter-city'),
      name: 'promotorCity',
    },
    {
      type: 'text',
      placeholder: t('gigs:claim-promoter-postcode'),
      name: 'promotorPostcode',
    },
    {
      type: 'select',
      placeholder: t('gigs:claim-promoter-country'),
      name: 'promotorCountry',
      options: countries,
    },
    {
      type: 'text',
      placeholder: t('gigs:claim-promoter-phone'),
      name: 'promotorPhone',
    },
    {
      type: 'email',
      placeholder: t('gigs:claim-promoter-email'),
      name: 'promotorEmail',
    },
  ];

  const flags = [
    {
      id: 'default',
      onSections: [],
    },
    {
      id: 'AT',
      onSections: ['promotorInfo', 'admissionEUR'],
    },
    {
      id: 'AU',
      onSections: ['promotorInfo'],
    },
    {
      id: 'BE',
      onSections: ['promotorInfo'],
    },
    {
      id: 'CA',
      onSections: ['promotorInfo', 'audienceNumber', 'admissionCAD', 'lineUpPosition', 'evidence'],
    },
    {
      id: 'CH',
      onSections: ['promotorInfo'],
    },
    {
      id: 'DE',
      onSections: ['lineUpPosition', 'promotorInfo', 'eventName'],
    },
    {
      id: 'DK',
      onSections: ['promotorInfo', 'audienceNumber', 'admissionDKK'],
    },
    {
      id: 'ES',
      onSections: ['lineUpPosition', 'promotor', 'otherActs', 'admissionEUR'],
    },
    {
      id: 'FI',
      onSections: ['promotorInfo'],
    },
    {
      id: 'FR',
      onSections: ['lineUpPositionAlt', 'promotor'],
    },
    {
      id: 'GB',
      onSections: [],
    },
    {
      id: 'NL',
      onSections: ['promotor', "admissionEUR"],

    },
    {
      id: 'NO',
      onSections: ['promotorInfo'],
    },
    {
      id: 'SE',
      onSections: ['promotorInfo'],
    },
    {
      id: 'US',
      onSections: ['venueCapacity', 'lineUpPosition', 'admissionFee'],
    },
  ];

  useEffect(() => {
    if (selectedAccount) {
      gigClaim.refetch();
    }
  }, [selectedAccount]);

  if (gigClaim.isSuccess && gigClaim.data && id && !obj.country) {
    const countrySelected = countries.find((e) => e.value === gigClaim.data[0].countryiso);
    setObj({
      ...obj,
      country: countrySelected.value,
      venueId: gigClaim.data[0].venueId,
      venueName: gigClaim.data[0].venueName,
      address: gigClaim.data[0].address,
      city: gigClaim.data[0].city,
      postcode: gigClaim.data[0].postcode,
      performer: gigClaim.data[0].performer,
      date: gigClaim.data[0].date,
      time: gigClaim.data[0].time,
      otherActs: gigClaim.data[0].otherActs,
      promotor: gigClaim.data[0].promotor,
      evidenceURL: gigClaim.data[0].evidenceURL,
      lineUpPosition: gigClaim.data[0].lineUpPosition,
      venuePremisesCategoryId: gigClaim.data[0].venuePremisesCategoryId,
      venuePremisesTypeId: gigClaim.data[0].venuePremisesTypeId,
      prsId: gigClaim.data[0].prsId,
      venueCapacity: gigClaim.data[0].venueCapacity,
      admissionFee: gigClaim.data[0].admissionFee,
      admissionEUR: gigClaim.data[0].admissionEUR,
      admissionCAD: gigClaim.data[0].admissionCAD,
      admissionDKK: gigClaim.data[0].admissionDKK,
      audienceNumber: gigClaim.data[0].audienceNumber,
      eventName: gigClaim.data[0].eventName,
      setlist: gigClaim.data[0].setlist,
      promotorName: gigClaim.data[0].promotorName,
      promotorAddress1: gigClaim.data[0].promotorAddress1,
      promotorStreet: gigClaim.data[0].promotorStreet,
      promotorCity: gigClaim.data[0].promotorCity,
      promotorPostcode: gigClaim.data[0].promotorPostcode,
      promotorCountry: gigClaim.data[0].promotorCountry,
      promotorPhone: gigClaim.data[0].promotorPhone,
      promotorEmail: gigClaim.data[0].promotorEmail,
      festivalStageName: gigClaim.data[0].festivalStageName,
      festivalPosition: gigClaim.data[0].festivalPosition,
      headlineArtist: gigClaim.data[0].headlineArtist,
      supportArtist1: gigClaim.data[0].supportArtist1,
      supportArtist2: gigClaim.data[0].supportArtist2,
      supportArtist3: gigClaim.data[0].supportArtist3,
      setlistName: gigClaim.data[0].setlistName,
      venueIsApproved: gigClaim.data[0].venueIsApproved,
      eventWebsiteURL: gigClaim.data[0].eventWebsiteURL,
    });
  }

  useEffect(() => {
    const def = flags.find((f) => f.id === 'default');
    const spef = flags.find((f) => f.id === obj.country);
    setSections(spef ? spef.onSections : def.onSections);
    setFestival(false);
    setOtherPerformers(false);
    
    if (obj.country === 'US') {
      setIsUsAlertOpen(true);
    }
    
  }, [obj.country]);

  useEffect(() => {
    if (obj.country === 'GB' && obj.venuePremisesCategoryId === 5) {
      setFestival(true);
      setOtherPerformers(false);
    } else if (obj.country === 'GB' && obj.venuePremisesCategoryId === 2) {
      setOtherPerformers(true);
      setFestival(false);
    } else {
      setFestival(false);
      setOtherPerformers(false);
    }
  }, [obj.venuePremisesCategoryId]);

  const checkErrors = () => {
    setErrors({});
    let valid = true;
    const errs = {};
    const genericError = t('regex:incomplete-error');

    if (!obj.country) {
      errors.country = t('regex:choose-country-error');
      valid = false;
    }

    if (!obj.venueName || obj.venueName.trim().length === 0) {
      errs.venueName = t('regex:add-venue-error');
      valid = false;
    }

    if (!obj.performer || obj.performer.trim().length === 0) {
      errs.performer = t('regex:add-performer-error');
      valid = false;
    }

    if (!obj.city || obj.city.trim().length === 0) {
      errs.city = t('regex:add-city-error');
      valid = false;
    }

    if (!obj.address || obj.address.trim().length === 0) {
      errs.address = t('regex:add-address-error');
      valid = false;
    }

    if (!obj.postcode || obj.postcode.trim().length === 0) {
      errs.postcode = t('regex:add-postcode-error');
      valid = false;
    }

    if (!obj.date || obj.date.trim().length === 0) {
      errs.date = t('regex:add-date-error');
      valid = false;
    }

    if (obj.country === 'CA' && (!obj.admissionCAD || obj.admissionCAD < 10)) {
      errs.admissionCAD = t('regex:over-10-cad-error');
      valid = false;
    }

    function isValidDate(d) {
      return d instanceof Date && !Number.isNaN(d);
    }

    if (!isValidDate(new Date(obj.date))) {
      errs.date = t('regex:invalid-date-error');
      valid = false;
    }

    if (!obj.time || obj.time.trim().length === 0) {
      errs.time = t('regex:select-time-error');
      valid = false;
    }

    if (obj.time && obj.date) {
      gigClaims?.data.map((g) => {
        if (
            g.id === id
            && g.time === obj.time
            && Date.parse(g.date) === Date.parse(obj.date)
        ) {
          valid = false;
          setValidationErrorMessage(
              t('gigs:claim-errors-existing-date-time'),
          );
        }
        return null;
      });
    }

    if (!obj.setlist) {
      errs.setlist = t('gigs:claim-errors-setlist');
      valid = false;
    }

    // ugly, needs refactoring into a more modular solution
    if (obj.country === 'ES' && obj.admissionEUR <= 11.99) {
      if (!obj.admissionEUR) {
        errs.admissionEUR = genericError;
        valid = false;
      }

      if (!obj.evidenceURL) {
        errs.proofOfPerformance = genericError;
        valid = false;
      }

      if (!obj.eventWebsiteURL) {
        errs.eventWebsiteURL = genericError;
        valid = false;
      } else if (!isValidURL(obj.eventWebsiteURL)) { // Validate the URL format
        errs.eventWebsiteURL = t('regex:invalid-url-error');
        valid = false;
      }
    }

    sections.forEach((section) => {
      if (
          !['promotorInfo', 'lineUpPositionAlt', 'evidence'].includes(section)
      ) {
        if (!obj[section]) {
          errs[section] = genericError;
          valid = false;
        }
      }
      if (section === 'promotorInfo') {
        promotorFormItems.forEach((p) => {
          if (!obj[p.name]) {
            errs[p.name] = genericError;
            valid = false;
          }
        });
      }
    });

    setErrors(errs);

    return valid;
  };

  function startReqAndCheckValidation() {
    setPending(true);
    setValidationErrorMessage('');
    // reset mutation
    createMutation.reset();
    const valid = checkErrors();
    return valid;
  }

  function onSuccess({ message }) {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    history.push('/gigs');
    gigClaims.refetch();
    sendProperty({
      property: 'last_gig_added',
      value: 'CURRENT_DATE',
      email: profile?.data?.email,
    });
    sendEvent({ property: 'pe14489857_last_gig_added' });
    setPending(false);
  }
  function onError({ error }) {
    setMutationErrorMessage(error.message);
    setPending(false);
  }
  function uploadFailed() {
    setValidationErrorMessage('There was an error attempting to upload evidence.');
  }

  const uploadEvidence = async (e) => {
    if (e) {
      putS3.mutate({ key: evidenceName, contenttype: evidenceType }, {
        onSuccess: (res) => {
          putUrl = res.putURL;
          const xmlHttpRequest = new XMLHttpRequest();
          xmlHttpRequest.addEventListener('error', uploadFailed, false);
          xmlHttpRequest.open('PUT', putUrl, true);
          xmlHttpRequest.setRequestHeader('Content-Type', evidenceType);
          xmlHttpRequest.onload = () => {
            if (xmlHttpRequest.status === 200) {
              console.log('Uploaded data successfully');
            }
          };
          xmlHttpRequest.send(e);
        },
        onError: (error) => {
          console.error('There was an error retrieving account details', error);
        },
      });
    }
  };

  const addClaim = async () => {
    if (!adminRights) return;
    const valid = startReqAndCheckValidation();
    if (valid) {
      const data = {
        performer: obj.performer || null,
        account_code: myAccount.data.artistaccountcode,
        orginator_code: myAccount?.data?.originatorcode?.code,
        country: obj.country || null,
        venueId: obj.venueId || null,
        venuePremisesCategoryId: obj.venuePremisesCategoryId || null,
        venuePremisesTypeId: obj.venuePremisesTypeId || null,
        venueName: obj.venueName || null,
        prsId: obj.prsId || null,
        city: obj.city || null,
        postcode: obj.postcode || null,
        address: obj.address || null,
        date: obj.date || null,
        time: obj.time || null,
        evidenceURL: obj.evidenceURL || null,
        venueCapacity: obj.venueCapacity || null,
        lineUpPosition: obj.lineUpPosition || null,
        promotor: obj.promotor || null,
        admissionFee: obj.admissionFee || null,
        admissionEUR: obj.admissionEUR || null,
        admissionCAD: obj.admissionCAD || null,
        admissionDKK: obj.admissionDKK || null,
        audienceNumber: obj.audienceNumber || null,
        eventName: obj.eventName || null,
        eventWebsiteURL: obj.eventWebsiteURL || null,
        otherActs: obj.otherActs || null,
        setlist: obj.setlist || null,
        promotorName: obj.promotorName || null,
        promotorAddress1: obj.promotorAddress1 || null,
        promotorStreet: obj.promotorStreet || null,
        promotorCity: obj.promotorCity || null,
        promotorPostcode: obj.promotorPostcode || null,
        promotorCountry: obj.promotorCountry || null,
        promotorPhone: obj.promotorPhone || null,
        promotorEmail: obj.promotorEmail || null,
        festivalStageName: obj.festivalStageName || null,
        festivalPosition: obj.festivalPosition || null,
        headlineArtist: obj.headlineArtist || null,
        supportArtist1: obj.supportArtist1 || null,
        supportArtist2: obj.supportArtist2 || null,
        supportArtist3: obj.supportArtist3 || null,
        setlistName: obj.setlist
            ? setlists.data.find((s) => s.id === obj.setlist)?.name
            : null,
        works: obj.setlist
            ? setlists.data.find((s) => s.id === obj.setlist)?.songs
            : null,
        other_songs: obj.setlist
            ? setlists.data.find((s) => s.id === obj.setlist)?.other_songs
            : null,
        venueIsApproved: obj.venueIsApproved || false,
        account_name: myAccount.data.accountname,
      };
      createMutation.mutate(data, {
        onSuccess: () => onSuccess({ message: t('gigs:updated-gig-toast') }),
        onError: (error) => onError({ error }),
      });
    } else {
      setPending(false);
    }
  };

  const deleteClaim = () => {
    if (!adminRights) return;
    const isValid = startReqAndCheckValidation();

    const data = {
      id,
      account_code: myAccount.data.artistaccountcode,
    };

    if (isValid) {
      deleteMutation.mutate(data, {
        onSuccess: () => onSuccess({ message: t('gigs:gig-deleted-toast') }),
        onError: (error) => onError({ error }),
      });
    } else {
      setPending(false);
    }
  };

  const updateClaim = () => {
    if (!adminRights) return;
    const valid = startReqAndCheckValidation();
    let dateObj = new Date(obj.date);
    dateObj = formatISO(dateObj, { representation: 'date' });
    if (valid) {
      const data = {
        id,
        performer: obj.performer || null,
        account_code: myAccount.data.artistaccountcode,
        orginator_code: myAccount?.data?.originatorcode?.code,
        country: obj.country || null,
        venueId: obj.venueId || null,
        venuePremisesCategoryId: obj.venuePremisesCategoryId || null,
        venuePremisesTypeId: obj.venuePremisesTypeId || null,
        venueName: obj.venueName || null,
        prsId: obj.prsId || null,
        city: obj.city || null,
        postcode: obj.postcode || null,
        address: obj.address || null,
        date: dateObj || null,
        time: obj.time || null,
        evidenceURL: obj.evidenceURL || null,
        venueCapacity: obj.venueCapacity || null,
        lineUpPosition: obj.lineUpPosition || null,
        promotor: obj.promotor || null,
        admissionFee: obj.admissionFee || null,
        admissionEUR: obj.admissionEUR || null,
        admissionCAD: obj.admissionCAD || null,
        admissionDKK: obj.admissionDKK || null,
        audienceNumber: obj.audienceNumber || null,
        eventName: obj.eventName || null,
        eventWebsiteURL: obj.eventWebsiteURL || null,
        otherActs: obj.otherActs || null,
        setlist: obj.setlist || null,
        promotorName: obj.promotorName || null,
        promotorAddress1: obj.promotorAddress1 || null,
        promotorStreet: obj.promotorStreet || null,
        promotorCity: obj.promotorCity || null,
        promotorPostcode: obj.promotorPostcode || null,
        promotorCountry: obj.promotorCountry || null,
        promotorPhone: obj.promotorPhone || null,
        promotorEmail: obj.promotorEmail || null,
        festivalStageName: obj.festivalStageName || null,
        festivalPosition: obj.festivalPosition || null,
        headlineArtist: obj.headlineArtist || null,
        supportArtist1: obj.supportArtist1 || null,
        supportArtist2: obj.supportArtist2 || null,
        supportArtist3: obj.supportArtist3 || null,
        setlistName: obj.setlist
            ? setlists.data.find((s) => s.id === obj.setlist)?.name
            : null,
        venueIsApproved: obj.venueIsApproved || false,
      };
      console.log('asd');
      updateMutation.mutate(data, {
        onSuccess: onSuccess({ message: t('gigs:updated-gig-toast') }),
        onError: (error) => onError({ error }),
      });
    } else {
      setPending(false);
    }
  };

  // gets evidence URL filename and removes prefix to show user which file they currently have uploaded in the update screen
  function extractFilenameToShow(evidenceURL) {
    const filenameWithoutPrefix = evidenceURL.replace(/^[\w-]+-/i, ''); // Remove UUID and hyphen
    return filenameWithoutPrefix;
  }

  return (
    <>
        <Modal smallModal openPortal={isUsAlertOpen} closePortal={setIsUsAlertOpen}>
          <div className="flex-col items-center justify-center text-center">
            <p>{t('gigs:us-gig-alert')}</p>
            <Spacer />
              <RebrandPrimaryButton
                onClick={() => setIsUsAlertOpen(false)}
              >
                {t('common:ok')}
              </RebrandPrimaryButton>
          </div>
        </Modal>

      <div
        className='flex-1 xl:w-full bg-sentric-rebrand-light-grey min-h-50 lg:min-h-screen-minus-header order-last'
      >
        {id && (
          <H2>{t('gigs:claim-update-header')}</H2>
        )}
        {!id && (
          <H2>{t('gigs:claim-create-header')}</H2>
        )}
        {/* <Spacer thin />
        <P small>{t('gigs:claim-subheader')}</P> */}
        <Spacer />
        <div className="w-full lg:w-2/3">
          {adminRights && (
            <Select
              id="btnGigsClaimCountry"
              className='select-search font-sentric-alt text-base'
              name="country"
              placeholder={t('gigs:select-country-placeholder')}
              onChange={(selected) => {
                setObj({ ...obj, country: selected });
              }}
              value={obj.country}
              options={countries.map((option) => ({
                name: t(`countries:${option.name}`),
                value: option.value,
              }))}
              search
              filterOptions
            />
          )}
          <Spacer />
          {obj.country && (
            <>
              <H4>{t('gigs:venue-details-header')}</H4>
              <Spacer thin />
              <div className="flex flex-row items-start justify-center w-full">
                <Select
                  id="btnGigsClaimVenue"
                  required
                  options={[]}
                  onChange={(add) => {
                    const details = JSON.parse(add);
                    setShowManual(true);
                    setObj({
                      ...obj,
                      venueId: details.id,
                      venuePremisesCategoryId: details.PremisesCategoryId,
                      venueName: details.venueNameNew,
                      city: details.venueCity,
                      postcode: details.fullPostCode,
                      address: details.addressLine1,
                      venueIsApproved: details.IsApproved,
                      prsId: details.PrsVenueID,
                      venuePremisesTypeId: details.PremisesTypeId,
                    });
                  }}
                  className='select-search font-sentric-alt text-base'
                  search
                  customLabel={t('gigs:search-venue-placeholder')}
                  placeholder={t('gigs:search-venue-placeholder')}
                  filterOptions
                  getOptions={(query) => new Promise((resolve) => {
                    if (!query) {
                      resolve([]);
                      return;
                    }
                    apiCall({
                      endpoint: `/venue-search?name=${query}&countryiso=${obj.country}`,
                      method: 'get',
                      data: null,
                    }).then((results) => {
                      resolve(
                        results.results.map((item) => ({
                          value: JSON.stringify(item),
                          name: `${item.venueNameNew}, ${item.venueCity}`,
                          data: item,
                        })),
                      );
                    });
                  })}
                />
              </div>
              {!showManual && (
                <>
                  <Spacer thin />
                  <div className="flex justify-end">
                      <RebrandButtonLink
                        onClick={() => setShowManual(true)}
                        id="btnGigsClaimAddAddress"
                      >
                        {t('gigs:manually-add-venue')}
                      </RebrandButtonLink>
                  </div>
                </>
              )}
              {(showManual || obj.venueName) && (
                <>
                  <Spacer />

                  <Input
                    id="txtGigsClaimVenueName"
                    required
                    type="text"
                    error={errors.venueName}
                    placeholder={t('gigs:venue-name-placeholder')}
                    value={obj.venueName}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, venueName: e.target.value });
                    }}
                  />

                  <Spacer thin />

                  <Input
                    id="txtGigsClaimAddress"
                    required
                    type="text"
                    error={errors.address}
                    placeholder={t('gigs:venue-address-placeholder')}
                    value={obj.address}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, address: e.target.value });
                    }}
                  />

                  <Spacer thin />

                  <Input
                    id="txtGigsClaimCity"
                    required
                    type="text"
                    error={errors.city}
                    placeholder={t('gigs:venue-city-placeholder')}
                    value={obj.city}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, city: e.target.value });
                    }}
                  />
                  <Spacer thin />

                  <Input
                    id="txtGigsPostCode"
                    required
                    type="text"
                    error={errors.postcode}
                    placeholder={t('gigs:venue-postcode-placeholder')}
                    value={obj.postcode}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, postcode: e.target.value });
                    }}
                  />
                </>
              )}
              <Spacer />
              <HR />
              <Spacer />
              <H4>{t('gigs:gig-details-header')}</H4>
              <Spacer thin />
              <Input
                id="txtGigsClaimPerformer"
                required
                type="text"
                error={errors.performer}
                placeholder={t('gigs:performer-placeholder')}
                value={obj.performer}
                onBlur={() => { }}
                onChange={(e) => {
                  setObj({ ...obj, performer: e.target.value });
                }}
              />
              <Spacer thin />
              <Input
                id="btnGigsClaimDate"
                className={`${isTunecore ? 'tunecore-datepicker' : 'themed-datepicker'} relative z-20` }
                required
                type="date"
                error={errors.date}
                dateFormatCalendar=' '
                showMonthDropdown
                showYearDropdown
                dateDropdownMode="select"
                useShortMonthInDropdown
                placeholder={t('gigs:performance-date-placeholder')}
                value={obj.date}
                onBlur={() => { }}
                onChange={(value) => {
                  setObj({ ...obj, date: value });
                }}
                min={new Date(addYears(new Date(), -3))}
                max={new Date()}
              />
              <Spacer thin />
              <Select
                id="btnGigsClaimTime"
                list
                required
                className='select-search font-sentric-alt text-base'
                name="time"
                error={errors.time}
                placeholder={t('gigs:performance-time-placeholder')}
                value={obj.time}
                onChange={(selected) => {
                  setObj({ ...obj, time: selected });
                }}
                options={[
                  { name: '0:00 AM', value: '00:00' },
                  { name: '1:00 AM', value: '01:00' },
                  { name: '2:00 AM', value: '02:00' },
                  { name: '03:00 AM', value: '03:00' },
                  { name: '04:00 AM', value: '04:00' },
                  { name: '05:00 AM', value: '05:00' },
                  { name: '06:00 AM', value: '06:00' },
                  { name: '07:00 AM', value: '07:00' },
                  { name: '08:00 AM', value: '08:00' },
                  { name: '09:00 AM', value: '09:00' },
                  { name: '10:00 AM', value: '10:00' },
                  { name: '11:00 AM', value: '11:00' },
                  { name: '12:00 PM', value: '12:00' },
                  { name: '1:00 PM', value: '13:00' },
                  { name: '2:00 PM', value: '14:00' },
                  { name: '3:00 PM', value: '15:00' },
                  { name: '4:00 PM', value: '16:00' },
                  { name: '5:00 PM', value: '17:00' },
                  { name: '6:00 PM', value: '18:00' },
                  { name: '7:00 PM', value: '19:00' },
                  { name: '8:00 PM', value: '20:00' },
                  { name: '9:00 PM', value: '21:00' },
                  { name: '10:00 PM', value: '22:00' },
                  { name: '11:00 PM', value: '23:00' },
                  { name: '12:00 PM', value: '24:00' },
                ]}
              />
              {/* 'choose file' and 'no file chosen' placeholders are translated by user's OS, future workaround for custom translated label needed - Matt  */}
              <Spacer />
              {sections.includes('evidence') && (
                <>
                  <P small>{t('gigs:add-evidence-text')}</P>
                  <Spacer thin />
                  <Input
                    id="btnGigClaimAddEvidence"
                    type="file"
                    error={errors.evidenceValue}
                    placeholder={t('gigs:add-evidence-placeholder')}
                    value={evidenceValue || ''}
                    onBlur={() => { }}
                    onChange={(e) => {
                      const key = uuidv4();
                      setEvidenceValue(e.target.value);
                      evidenceName = (`${key}-${e.target.files[0].name}`);
                      evidenceType = e.target.files[0].type;
                      setObj({ ...obj, evidenceURL: evidenceName });
                      uploadEvidence(e.target.files[0]);
                    }}
                    accept=".jpg,.jpeg,.png,.pdf"
                  />
                  <Spacer />
                </>
              )}

              {sections.includes('venueCapacity') && (
                <>
                  <Select
                    id="btnGigClaimVenueCapacity"
                    list
                    required
                    className='select-search font-sentric-alt text-base'
                    name="venueCapacity"
                    placeholder={t('gigs:venue-capacity-placeholder')}
                    value={obj.venueCapacity}
                    error={errors.venueCapacity}
                    onChange={(selected) => setObj({ ...obj, venueCapacity: selected })}
                    options={[
                      { name: t('gigs:under-200'), value: 'Under 200' },
                      { name: '500 - 1,000', value: '500 - 1,000' },
                      { name: '1,000 - 3,000', value: '1,000 - 3,000' },
                      { name: '3,000 -  6,000', value: '3,000 -  6,000' },
                      { name: '6,000 -  10,000', value: '6,000 -  10,000' },
                      { name: '10,000 - 20,000', value: '10,000 - 20,000' },
                      { name: t('gigs:over-20000'), value: 'Over 20,000' },
                    ]}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('lineUpPosition') && (
                <>
                  <InnerRadio
                    required
                    name="lineUpPosition"
                    label={t('gigs:position-in-lineup')}
                    error={errors.lineUpPosition}
                    value={obj.lineUpPosition}
                    onChange={(e) => {
                      setObj({ ...obj, lineUpPosition: e.target.value });
                    }}
                    options={[
                      { label: t('gigs:headline-artist'), value: 'headline' },
                      { label: t('gigs:support-artist'), value: 'support' },
                    ]}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('lineUpPositionAlt') && (
                <>
                  <Select
                    list
                    id="btnGigClaimLineupPosition"
                    required
                    className='select-search font-sentric-alt text-base'
                    name="lineUpPositionAlt"
                    error={errors.lineUpPosition}
                    placeholder={t('gigs:lineup-position-placeholder')}
                    value={obj.lineUpPosition}
                    onChange={(selected) => setObj({ ...obj, lineUpPosition: selected })}
                    options={[
                      { name: t('gigs:headline-artist'), value: 'Headline Artist' },
                      { name: t('gigs:support-artist'), value: 'Support Artist' },
                      {
                        name: t('gigs:festival-performance'),
                        value: 'Festival Performance',
                      },
                    ]}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('promotor') && (
                <>
                  <Input
                    id="txtGigClaimPromotor"
                    required
                    type="text"
                    error={errors.promotor}
                    placeholder={t('gigs:promoter-placeholder')}
                    value={obj.promotor}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, promotor: e.target.value });
                    }}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('admissionFee') && (
                <>
                  <InnerRadio
                    required
                    name="admissionFee"
                    label={t('gigs:admission-fee')}
                    value={obj.admissionFee}
                    error={errors.admissionFee}
                    onChange={(e) => setObj({ ...obj, admissionFee: e.target.value })}
                    options={[
                      { label: t('common:yes'), value: 'Yes' },
                      { label: t('common:no'), value: 'No' },
                    ]}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('admissionCAD') && (
                <>
                  <Input
                    id="txtGigClaimAdmissionFee"
                    required
                    type="number"
                    error={errors.admissionCAD}
                    placeholder={t('gigs:admission-fee-cad-placeholder')}
                    value={obj.admissionCAD}
                    onBlur={() => { }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 69 || e.keyCode === 107
                        || e.keyCode === 187 || e.keyCode === 109 || e.keyCode === 189) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setObj({ ...obj, admissionCAD: e.target.value });
                    }}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('admissionDKK') && (
                <>
                  <Input
                    id="txtGigClaimAdmissionDKK"
                    required
                    type="number"
                    error={errors.admissionDKK}
                    placeholder={t('gigs:admission-fee-dkk-placeholder')}
                    value={obj.admissionDKK}
                    onBlur={() => { }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 69 || e.keyCode === 107
                        || e.keyCode === 187 || e.keyCode === 109 || e.keyCode === 189) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setObj({ ...obj, admissionDKK: e.target.value });
                    }}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('audienceNumber') && (
                <>
                  <Input
                    id="txtGigClaimAudienceNumber"
                    required
                    type="number"
                    error={errors.audienceNumber}
                    placeholder={t('gigs:audience-number')}
                    value={obj.audienceNumber}
                    onBlur={() => { }}
                    step={1}
                    onKeyDown={(e) => {
                      if (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 110 || e.keyCode === 107
                        || e.keyCode === 187 || e.keyCode === 109 || e.keyCode === 189) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setObj({ ...obj, audienceNumber: e.target.value });
                    }}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('eventName') && (
                <>
                  <Input
                    id="txtGigClaimEventName"
                    required
                    type="text"
                    error={errors.eventName}
                    placeholder={t('gigs:event-name')}
                    value={obj.eventName}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, eventName: e.target.value });
                    }}
                  />
                  <Spacer />
                </>
              )}
              {sections.includes('otherActs') && (
                <>
                  <Input
                    id="txtGigClaimOtherActs"
                    required
                    type="text"
                    error={errors.otherActs}
                    placeholder={t('gigs:other-acts')}
                    value={obj.otherActs}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, otherActs: e.target.value });
                    }}
                  />
                  <Spacer />
                </>
              )}
              <H4>{t('gigs:setlist')}</H4>
              <Spacer thin />
              <Select
                id="btnGigClaimSetlist"
                className='select-search font-sentric-alt text-base'
                name="setlistName"
                error={errors.setlist}
                placeholder={t('gigs:setlist')}
                value={obj.setlist}
                onChange={(selected) => setObj({ ...obj, setlist: selected })}
                options={setlists?.data?.map((s) => ({
                  name: s.name,
                  value: s.id,
                }))}
                search
                required
              />
              <Spacer />

                  {sections.includes('admissionEUR') && (
                      <>
                        <NumericalInput
                          id="esTicketPrice"
                          required
                          error={errors.admissionEUR}
                          placeholder={t('gigs:es-ticket-price-place-holder')}
                          value={obj.admissionEUR}
                          onBlur={() => { }}
                          onKeyDown={(e) => {
                              if (e.keyCode === 69 || e.keyCode === 107
                                  || e.keyCode === 187 || e.keyCode === 109 || e.keyCode === 189) {
                                e.preventDefault();
                              }
                            }}
                          onChange={(e) => {
                              const ticketPrice = e.target.value;
                              setObj({ ...obj, admissionEUR: ticketPrice });

                              if(obj.country.includes('ES')) {
                                if (parseFloat(ticketPrice) <= 11.99) {
                                  setUnderTwelveEuros(true);
                                } else {
                                  setUnderTwelveEuros(false);
                                }
                              }
                            }}
                        />
                        <Spacer />
                      </>
                  )}
                  {(underTwelveEuros || (obj.admissionEUR <= 11.99 && isUpdate && obj.country.includes('ES'))) && (
                      <>
                        <P className="mt-4" small>{t('gigs:es-proof-of-performance')}</P>
                        <Spacer thin />
                        <Input
                          id="btnGigClaimProofOfPerfomance"
                          type="file"
                          error={errors.proofOfPerformance}
                          placeholder={t('gigs:add-proof-of-performance')}
                          value={proofOfPerformance || ''}
                          onBlur={() => { }}
                          onChange={(e) => {
                              const key = uuidv4();
                              setProofOfPerformance(e.target.value);
                              evidenceName = (`${key}-${e.target.files[0].name}`);
                              evidenceType = e.target.files[0].type;
                              setObj({ ...obj, evidenceURL: evidenceName });
                              uploadEvidence(e.target.files[0]);
                            }}
                          accept=".jpg,.jpeg,.png,.pdf"
                        />
                        {isUpdate && obj.evidenceURL && (
                          <small className="font-semibold">
                            {t('gigs:current-proof-of-performance')}
                            {' '}
                            {extractFilenameToShow(obj.evidenceURL)}
                          </small>
                        )}
                        <Spacer />
                        <Input
                          id="txtEventWebsiteUrl"
                          required
                          type="url"
                          error={errors.eventWebsiteURL}
                          placeholder={t('gigs:es-website-url')}
                          value={obj.eventWebsiteURL}
                          onBlur={() => { }}
                          onChange={(e) => {
                              setObj({ ...obj, eventWebsiteURL: e.target.value });
                            }}
                        />
                      </>
                  )}
              {sections.includes('promotorInfo') && (
                <>
                  <H4>{t('gigs:promoter-info-header')}</H4>
                  <Spacer thin />
                  {promotorFormItems.map((p) => (
                    <>
                      {p.type !== 'select' && (
                        <Input
                          id="txtGigClaimPromotorInfo"
                          required
                          type="text"
                          error={errors[p.name]}
                          placeholder={p.placeholder}
                          value={obj[p.name]}
                          onBlur={() => { }}
                          onChange={(e) => {
                            setObj({ ...obj, [p.name]: e.target.value });
                          }}
                        />
                      )}
                      {p.type === 'select' && (
                        <Select
                          id="btnGigClaimCountry"
                          required
                          className="w-full text-old-sentric-dark-gray"
                          name="country"
                          error={errors[p.name]}
                          placeholder={p.placeholder}
                          value={obj[p.name]}
                          onChange={(selected) => setObj({ ...obj, [p.name]: selected })}
                          options={countries.map((option) => ({
                            name: t(`countries:${option.name}`),
                            value: option.value,
                          }))}
                          search
                        />
                      )}

                      <Spacer />
                    </>
                  ))}
                </>
              )}
              {festival && (
                <>
                  <H4>{t('gigs:festival-header')}</H4>
                  <Spacer thin />
                  <Input
                    id="txtGigClaimFestivalStage"
                    required
                    type="text"
                    error={errors.festivalStageName}
                    placeholder={t('gigs:stage-name')}
                    value={obj.festivalStageName}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, festivalStageName: e.target.value });
                    }}
                  />
                  <Spacer />
                  <InnerRadio
                    required
                    id="txtGigClaimFestivalPosition"
                    name="festivalPosition"
                    label={t('gigs:position-on-stage')}
                    error={errors.festivalPosition}
                    value={obj.festivalPosition}
                    onChange={(e) => setObj({ ...obj, festivalPosition: e.target.value })}
                    options={[
                      { label: t('gigs:headline-artist'), value: 'headline' },
                      { label: t('gigs:support-artist'), value: 'support' },
                    ]}
                  />
                  <Spacer />
                </>
              )}
              {otherPerformers && (
                <>
                  <H4>{t('gigs:other-performers-header')}</H4>
                  <Spacer thin />
                  <Input
                    id="txtGigClaimHeadlineArtist"
                    required
                    type="text"
                    error={errors.headlineArtist}
                    placeholder={t('gigs:headline-artist-caps')}
                    value={obj.headlineArtist}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, headlineArtist: e.target.value });
                    }}
                  />
                  <Spacer />
                  <Input
                    id="txtGigClaimHeadlineSupport"
                    type="text"
                    error={errors.supportArtist1}
                    placeholder={t('gigs:support-artist1')}
                    value={obj.supportArtist1}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, supportArtist1: e.target.value });
                    }}
                  />
                  <Spacer />
                  <Input
                    id="txtGigClaimSupportArtist2"
                    type="text"
                    error={errors.supportArtist2}
                    placeholder={t('gigs:support-artist2')}
                    value={obj.supportArtist2}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, supportArtist2: e.target.value });
                    }}
                  />
                  <Spacer />
                  <Input
                    id="txtGigClaimSupportArtist3"
                    type="text"
                    error={errors.supportArtist3}
                    placeholder={t('gigs:support-artist3')}
                    value={obj.supportArtist3}
                    onBlur={() => { }}
                    onChange={(e) => {
                      setObj({ ...obj, supportArtist3: e.target.value });
                    }}
                  />
                  <Spacer />
                </>
              )}
              <Spacer />
              <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
                <div className={`flex flex-wrap gap-2 ${isUpdate ? 'justify-between flex-col items-center xs:flex-row' : 'justify-end'}`}>
                  {isUpdate && (
                    <RebrandPrimaryButton
                      id="btnGigClaimDelete"
                      onClick={deleteClaim}
                      disabled={!adminRights}
                      pending={pending}
                      deletebutton
                    >
                      {t('gigs:delete-claim-button')}
                    </RebrandPrimaryButton>
                  )}
                    <RebrandPrimaryButton
                      id={isUpdate ? 'btnGigClaimUpdate' : 'btnGigClaimAdd'}
                      onClick={isUpdate ? updateClaim : addClaim}
                      disabled={!adminRights}
                      pending={pending}
                      arrow
                    >
                      {isUpdate
                      ? t('gigs:update-claim-button')
                      : t('gigs:add-claim-button')}
                    </RebrandPrimaryButton>
                </div>
              <Spacer />
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default GigsClaim;
