/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import debounce from 'lodash.debounce';
import AppState from '../../../../store/appstate';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const CumulativeEarnings = ({ data, graph, dateType }) => {
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const [currentChart, setCurrentChart] = useState(null);
  const [currentXAxis, setCurrentXAxis] = useState(null);
  const [currentYAxis, setCurrentYAxis] = useState(null);
  const selectedDates = AppState.useState((s) => s.selectedDates);
  const [lineGraph, setLineGraph] = useState(null);
  const [barGraph, setBarGraph] = useState(null);
  const [minLineY, setMinLineY] = useState(0);
  const [maxLineY, setMaxLineY] = useState(0);
  const [minBarY, setMinBarY] = useState(0);
  const [maxBarY, setMaxBarY] = useState(0);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  useEffect(() => {
    if (lineGraph && barGraph) {
      if (graph === 'line') {
        barGraph.hide();
        lineGraph.show();
        currentYAxis.setAll({
          min: minLineY,
          max: maxLineY,
        });
      }
      if (graph === 'bar') {
        barGraph.show();
        lineGraph.hide();
        currentYAxis.setAll({
          min: minBarY,
          max: maxBarY,
        });
      }
    }
  }, [graph]);

  const changeHandler = ({ type, date }) => {
    const t = new Date(date);
    if (Number.isNaN(t.valueOf())) {
      console.log('not valid date');
      return;
    }

    if (type === 'start') {
      const gte = date?.toISOString().split('T', 1)[0];

      AppState.update((s) => {
        s.range.publishedStatementDate.gte = gte;
        s.range.receiptDate.gte = gte;
      });
    }

    if (type === 'end') {
      const lte = date?.toISOString().split('T', 1)[0];

      AppState.update((s) => {
        s.range.publishedStatementDate.lte = lte;
        s.range.receiptDate.gte = lte;
      });
    }
  };
  const debouncedChangeHandlerStart = useCallback(
    debounce(changeHandler, 1000),
    [],
  );
  const debouncedChangeHandlerEnd = useCallback(
    debounce(changeHandler, 1000),
    [],
  );

  const drawChart = () => {
    if (currentChart) {
      currentChart.dispose();
    }
    if (currentXAxis) {
      setCurrentXAxis(null);
    }

    if (!data) {
      return;
    }

    const dataSet = data?.body?.aggregations?.CumulativeSumHistogram?.buckets || [];

    let j = 0;
    let total = 0;

    const minBY = null;
    let maxBY = null;

    let minLY = null;
    let maxLY = null;

    while (j < dataSet.length) {
      const d = dataSet[j];
      total += parseFloat(d.CumulativeSum.value);
      d.valueNon = parseFloat(d.CumulativeSum.value);
      d.value = total;
      if (j === 0) {
        minLY = total;
      }
      if (j === (dataSet.length - 1)) {
        maxLY = total;
      }

      if (d.valueNon < minBY) {
        minLY = d.valueNon;
      }

      if (d.valueNon > maxBY) {
        maxBY = d.valueNon;
      }
      j += 1;
    }

    setMinBarY(minBY);
    setMaxBarY(maxBY);
    setMinLineY(minLY);
    setMaxLineY(maxLY);

    // Create root and chart
    am5.addLicense('AM5C316073875');
    const root = am5.Root.new('chartCE');
    root.numberFormatter.set('numberFormat', '#,###.00');

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingTop: 40,
      }),
    );

    // Craete Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    setCurrentYAxis(yAxis);

    const yRenderer = yAxis.get('renderer');
    yRenderer.labels.template.setAll({
      fill: isTunecore ? am5.color(0xFFFFFF) : am5.color(0x525560),
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
    });
    yRenderer.grid.template.setAll({
      stroke: isTunecore ? am5.color(0xFFFFFF) : am5.color(0x25BCBB),
      strokeWidth: 1,
      strokeDasharray: [5, 2],
    });

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
        }),
      }),
    );
    xAxis.data.setAll(dataSet);

    const xRenderer = xAxis.get('renderer');
    xRenderer.labels.template.setAll({
      fill: isTunecore ? am5.color(0xFFFFFF) : am5.color(0x525560),
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      paddingTop: 20,
    });
    xRenderer.grid.template.setAll({
      stroke: isTunecore ? am5.color(0xFFFFFF) : am5.color(0x25BCBB),
      strokeWidth: 1,
      strokeDasharray: [5, 2],
    });

    // Create series
    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        xAxis,
        yAxis,
        valueYField: 'value',
        valueXField: 'key',
        stroke: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
        maskBullets: false,

        // tooltip: am5.Tooltip.new(root, {
        //   labelText: '{valueY}',
        //   fill: am5.color(0x25BCBB),
        // }),
      }),
    );
    series.strokes.template.setAll({
      strokeWidth: 4,
    });

    series.fills.template.setAll({
      fillOpacity: 0.3,
      visible: true,
    });

    series.fills.template.set('fillGradient', am5.LinearGradient.new(root, {
      stops: [{
        color: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
      }, {
        color: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
        opacity: 0,
      }],
    }));

    root.dateFormatter.set('dateFormat', 'yyyy-MM-dd');
    root.numberFormatter.set('numberFormat', `${currencySymbol}#,###.00`);

    const tooltip = am5.Tooltip.new(root, {
      labelText: '[fontFamily:Montserrat; bold; #25BCBB]{valueX.formatDate()}[/]\n[fontFamily:Montserrat; bold; fontSize:18px]{valueY}[/]',
      getFillFromSprite: false,
    });

    tooltip.get('background').setAll({

      fill: am5.color(0xffffff),
      fillOpacity: 1,
      stroke: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
      strokeWidth: 2,
    });

    series.set('tooltip', tooltip);

    series.bullets.push(() => am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 4,
        stroke: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
        strokeWidth: 2,
        fill: am5.color(0xffffff),
      }),
    }));

    series.data.setAll(dataSet);

    setLineGraph(series);

    // bar chart
    const series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis,
        yAxis,
        valueYField: 'valueNon',
        valueXField: 'key',
        stroke: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
        fill: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),

      }),
    );

    series2.data.setAll(dataSet);
    const tooltip2 = am5.Tooltip.new(root, {
      labelText: '[fontFamily:Montserrat; bold; #25BCBB]{valueX.formatDate()}[/]\n[fontFamily:Montserrat; bold; fontSize:18px]{valueY}[/]',
      getFillFromSprite: false,
    });

    tooltip2.get('background').setAll({

      fill: am5.color(0xffffff),
      fillOpacity: 1,
      stroke: isTunecore ? am5.color(0x00ef86) : am5.color(0x25BCBB),
      strokeWidth: 2,
    });

    series2.set('tooltip', tooltip2);

    series2.hide();

    setBarGraph(series2);

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root, {
      behavior: 'zoomX',
    }));

    const scrollbar = chart.set('scrollbarX', am5xy.XYChartScrollbar.new(root, {
      orientation: 'horizontal',
      height: 60,
      maxDeviation: 0,

    }));

    setCurrentXAxis(xAxis);

    series.events.once('datavalidated', (ev) => {
      const axis = ev.target.get('xAxis');

      yAxis.setAll({
        min: minLY,
        max: maxLY,
      });

      axis.onPrivate('selectionMin', (value, target) => {
        const start = new Date(value);
        debouncedChangeHandlerStart({ type: 'start', date: start });
      });

      axis.onPrivate('selectionMax', (value, target) => {
        const end = new Date(value);
        debouncedChangeHandlerEnd({ type: 'end', date: end });
      });
    });

    const sbDateAxis = scrollbar.chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {
        timeUnit: 'month',
        count: 1,
      },
      maxDeviation: 0,
      renderer: am5xy.AxisRendererX.new(root, {}),
    }));

    const sbValueAxis = scrollbar.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    const sbSeries = scrollbar.chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
      xAxis: sbDateAxis,
      yAxis: sbValueAxis,
      valueYField: 'value',
      valueXField: 'key',
    }));

    sbValueAxis.setAll({
      min: minLY,
      max: maxLY,
    });

    const sbSeries2 = scrollbar.chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: sbDateAxis,
      yAxis: sbValueAxis,
      valueYField: 'valueNon',
      valueXField: 'key',
    }));

    if (graph === 'line') {
      sbSeries.data.setAll(dataSet);
    } else {
      sbSeries2.data.setAll(dataSet);
    }

    scrollbar.set('marginBottom', 30);

    setCurrentChart(root);
  };

  useEffect(() => {
    drawChart();
    // remember to dispose
    return () => {
      if (currentChart) {
        currentChart.dispose();
      }
    };
  }, [data]);

  useEffect(async () => {
    if (currentChart && selectedDates) {
      const axis = currentXAxis;
      // reset zoom

      const fromD = new Date(selectedDates.fromDate);
      const toD = new Date(selectedDates.toDate);
      await axis.zoomToDates(fromD, toD);
    }
  }, [selectedDates, currentChart]);

  return (<div id="chartCE" style={{ width: '100%', height: '530px' }} />
  );
};

export default CumulativeEarnings;
