/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import stringSimilarity from "string-similarity";
import Select from "../../../../components/select";
import {
	getArtistMeta,
	getMySong,
	getMySongs,
	getSuggestions,
	getMyProfile,
	getMyAccount,
} from "../../../../api/queries";
import Spacer from "../../../../components/spacer";
import VerticalRadio from "../../../../components/verticalRadio";
import AppState from "../../../../store/appstate";
import SongsCreateUpdate from "./songsCreateUpdate";
import {
	createRecording,
	updateRecordingMeta,
	updateSuggestion,
} from "../../../../api/mutations";
import FormError from "../../../../components/formError";
import { sendEvent, sendProperty } from "../../../../helpers/helpers";
import { hasAdminRights } from "../../../../auth/shared";
import useSiteConfigAuth from "../../../../api/sanity/queries/getSiteConfigWithCode";
import RebrandPrimaryButton from "../../../../components/rebrandPrimaryButton";
import RebrandButtonLink from "../../../../components/rebrandButtonLink";

// const short = require('short-uuid');

// const stringSimilarity = require('string-similarity');

function SongSuggestionDetails(props) {
	const { t } = useTranslation("songs");
	const { row } = props;
	const { original } = row;
	const selectedAccount = AppState.useState((s) => s.selectedAccount);
	const songs = getMySongs({ aac: selectedAccount });
	const [songList, setSongList] = useState([]);
	const [originalWriter, setOriginalWriter] = useState(null);
	const [newSong, setNewSong] = useState(null);
	const [pending, setPending] = useState(false);
	const artistMeta = getArtistMeta({ aac: selectedAccount });
	const spotifyID = artistMeta?.data?.spotify_obj
		? JSON.parse(artistMeta?.data?.spotify_obj)?.id
		: null;
	const suggestions = getSuggestions({
		spotifyid: spotifyID,
		accountId: selectedAccount,
	});
	const [mutationErrorMessage, setMutationErrorMessage] = useState("");
	const [validationErrorMessage, setValidationErrorMessage] = useState("");
	const { data: siteConfig } = useSiteConfigAuth();
	const isTunecore = siteConfig?.display_name === "TuneCore";
	const profile = getMyProfile();

	const updateSuggestionMutation = updateSuggestion();
	const recordingCreateMutation = createRecording();
	const recordingMetaMutation = updateRecordingMeta();
	const [selectedSong, setSelectedSong] = useState(null);

	const cancel = () => {
		setOriginalWriter(null);
		setNewSong(null);
	};

	const songTemp = getMySong({
		aac: selectedAccount,
		wc: selectedSong?.workcode,
	});

	const myAccount = getMyAccount({
		artistaccountcode: selectedAccount,
	});
	const adminRights = hasAdminRights(myAccount?.data);

	useEffect(() => {
		if (songs.data?.works) {
			const ms = songs.data.works.filter(
				(s) => stringSimilarity.compareTwoStrings(s.title, original.title) > 0.3
			);
			if (ms.length > 0) {
				setSelectedSong(ms[0]);
				setSongList([
					...ms,
					...songs.data.works.filter((w) => !ms.includes(w)),
				]);
				console.log(ms[0]);
			} else {
				setSongList([...songs.data.works]);
			}
		}
	}, [songs.isSuccess]);

	const updateSuggestionStatus = ({ status, id }) => {
		/*
    0 = shown in suggestions list
    1 = added as a song
    2 = added as recording
    3 = ignore
    */
		setPending(true);

		updateSuggestionMutation.reset();
		const data = {
			status,
			id,
		};
		updateSuggestionMutation.mutate(data, {
			onSuccess: () => {
				suggestions.refetch();
				setPending(false);
			},
			onError: (error) => {
				console.log(`update sugestion ${error}`);
				setPending(false);
			},
		});
	};

	const addRecording = async ({ suggestion }) => {
		setPending(true);
		setMutationErrorMessage("");
		setValidationErrorMessage("");
		// reset mutation
		recordingCreateMutation.reset();

		const data = {
			artistaccountcode: selectedAccount,
			title: suggestion.title,
			performingartist: suggestion.artists.split(",")[0],
			isrc: suggestion.isrc,
			releasedate: suggestion.releasedate,
			workcode: songTemp.data.workcode,
			uicbarcode: short.generate().substring(0, 13),
			spotifyidentifier: suggestion?.spotify_song_id,
			spotifypopularity: suggestion?.popularity,
			spotifypopularitylastupdatedutc:
				suggestion?.spotify_song_id === undefined ||
				suggestion?.spotify_song_id === null
					? null
					: new Date().toISOString(),
			etag: songTemp?.data?.etag,
		};

		recordingCreateMutation.mutate(data, {
			onSuccess: (response) => {

				if (response.failedvalidation) {
            setValidationErrorMessage(response.messages.join(', '));
					setPending(false);
				} else {
					// eslint-disable-next-line no-use-before-define
					addRecordingMeta(response.model.uicbarcode, suggestion);
				}
			},
			onError: (error) => {
				console.log(`error on addRecording ${error.message}`);
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};

	const addRecordingMeta = (rid, suggestion) => {
		setMutationErrorMessage("");
		setValidationErrorMessage("");

		recordingMetaMutation.reset();
		const data = {
			id: rid,
			spotify_id: suggestion.spotify_song_id,
			mp3: null,
			thumb_url: suggestion?.images
				? JSON.parse(suggestion?.images)?.[0].url
				: null,
			song_id: songTemp.data.workcode,
		};

		recordingMetaMutation.mutate(data, {
			onSuccess: (response) => {
				console.log(response);
				if (response.failedvalidation) {
					console.log(`failed validation ${response.data.messages}`);
					setValidationErrorMessage(response.data.messages);
					setPending(false);
				} else {
					console.log(`validation success ${response.data.messages}`);
					toast.success(`${t("songs:suggestion-details-toast-success")}`, {
						position: "bottom-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					sendProperty({
						property: "last_recording_added",
						value: "CURRENT_DATE",
						email: profile?.data?.email,
					});
					sendEvent({ property: "pe14489857_recording_added" });
					updateSuggestionStatus({ status: 2, id: suggestion.id });
					songs.refetch();
					setPending(false);
				}
			},
			onError: (error) => {
				console.log(
					`error on validation ${error.message} : mutation addRecordingMeta`
				);
				setMutationErrorMessage(error.message);
				setPending(false);
			},
		});
	};

	return (
		<tr>
			<td colSpan='6'>
				<div
					className={`rounded-lg ${
						originalWriter === null ||
						(originalWriter === "true" && newSong === null)
							? "mb-2"
							: "-mb-2 z-10 relative"
					} ${
						isTunecore ? "bg-tunecore-black" : "bg-sentric-rebrand-light-grey"
					} lg:p-6 p-4 mt-2 text-old-song-sub-color lg:w-screen-width-minus-gutter w-full`}
				>
					<div className='flex flex-wrap'>
						<div className='w-full lg:w-1/2 flex items-center'>
							<p
								className={
									isTunecore
										? "text-white text-base"
										: "text-black font-medium text-base"
								}
							>
								{original.title}
							</p>
						</div>
					</div>
					{original.isrc && (
						<p
							className={`${
								isTunecore ? "text-white" : "text-sentric-rebrand-grey-text"
							} text-xxs `}
						>
							<span className='font-semi-bold'>ISRC: </span>
							{original.isrc}
						</p>
					)}
					<Spacer thin />
					<div
						className={`flex flex-wrap ${
							isTunecore ? "text-white" : "text-sentric-rebrand-grey-text"
						}`}
					>
						<div className='w-full lg:w-1/2'>
							<p className='text-xs'>
								<span className='uppercase font-semibold'>
									{t("songs:performed-by")}:
								</span>{" "}
								{original.artists.replace(/&#44;/g, ",")}
							</p>
						</div>
						<div className='w-full lg:w-1/2'>
							<p className='text-xs'>
								<span className='uppercase font-semibold'>
									{t("songs:duration")}:
								</span>{" "}
								{Math.floor(original.duration / 1000 / 60)}{" "}
								{t("common:minutes")}{" "}
								{Math.floor((original.duration / 1000) % 60)}{" "}
								{t("common:seconds")}
							</p>
						</div>
					</div>
					<Spacer thin />
					{original.spotify_song_id && (
						<iframe
							title='preview'
							src={`https://open.spotify.com/embed/track/${original.spotify_song_id}`}
							className='w-full lg:w-5/12'
							height='80'
							frameBorder='0'
							allowtransparency='true'
							allow='encrypted-media'
						/>
					)}
					<Spacer />
					<Spacer thin />
					<VerticalRadio
						name='originalwriter'
						label={t("songs:original-writer-header")}
						value={originalWriter}
						onChange={
							adminRights ? (e) => setOriginalWriter(e.target.value) : undefined
						}
						disabled={!adminRights}
						options={[
							{ label: t("songs:original-writer-confirm"), value: true },
							{ label: t("songs:original-writer-deny"), value: false },
						]}
					/>
					{originalWriter === "true" && (
						<>
							<Spacer />
							<Spacer thin />
							<h2
								className={`${
									isTunecore ? "text-white" : "text-black"
								} mb-2 font-medium text-base`}
							>
								{t("songs:is-this-a-new-song")}
							</h2>
							{selectedSong && (
								<p
									className={
										isTunecore
											? "text-white"
											: "text-sentric-rebrand-grey-text font-sentric-alt"
									}
								>
									<FaInfoCircle className='inline mr-2' />
									{t("songs:your-existing-song")} <b>"{selectedSong?.title}"</b>{" "}
									{t("songs:looks-like-a-match")}
								</p>
							)}
							<Spacer thin />

							<VerticalRadio
								name='newsong'
								value={newSong}
								onChange={(e) => setNewSong(e.target.value)}
								options={[
									{ label: t("songs:this-is-a-new-song-confirm"), value: true },
									{ label: t("songs:song-already-exists"), value: false },
								]}
							/>
						</>
					)}
				</div>

				{originalWriter === "false" && (
					<div
						className={`${
							isTunecore ? "bg-tunecore-black" : "bg-sentric-rebrand-light-grey"
						}  lg:p-6 p-4 rounded-t-none rounded-lg mb-2 flex flex-wrap justify-end items-center`}
					>
						<div className='mx-2'>
							<button
								onClick={() => {
									cancel();
								}}
								type='button'
								className={`${
									isTunecore
										? "text-white hover:text-tunecore-green"
										: "text-black"
								} flex items-center underline`}
							>
								{t("songs:cancel-and-discard")}
							</button>
						</div>
						<div className='mx-2'>
							<RebrandPrimaryButton
								onClick={() => {
									updateSuggestionStatus({ status: 3, id: original.id });
									sendEvent({
										property: "pe14489857_spotify_suggestion_removed",
									});
								}}
								disabled={pending}
								pending={pending}
							>
								{t("songs:remove-suggestion")}
							</RebrandPrimaryButton>
						</div>
					</div>
				)}

				{newSong === "false" && (
					<div
						className={`${
							isTunecore ? "bg-tunecore-black" : "bg-sentric-rebrand-light-grey"
						} lg:p-6 p-4 rounded-t-none rounded-lg mb-2`}
					>
						<h2
							className={`${
								isTunecore ? "text-white" : "text-black"
							} mb-2 text-xl`}
						>
							{t("songs:search-for-existing-and-select")}
						</h2>
						<div className='relative'>
							<Select
								options={songList.map((m) => ({
									name: m.title,
									value: m.workcode,
								}))}
								onChange={(item) => {
									setSelectedSong(songList.find((s) => s.workcode === item));
								}}
								value={selectedSong?.workcode}
								className='select-search select-search--multiple font-sentric-alt text-base text-sentric-rebrand-grey-text required-star lg:w-1/2 w-full'
								search
								filterOptions
								placeholder={t("songs:search-for-existing-placeholder")}
							/>
						</div>
						{selectedSong && songTemp.isSuccess && (
							<>
								<Spacer thin />
								<div className='lg:w-1/2 w-full rounded-lg p-4 bg-sentric-rebrand-mid-grey'>
									<div className='mb-1'>{songTemp?.data.title}</div>
									<div className='flex flex-wrap'>
										<div className='w-1/2'>
											<p className='text-xs text-old-sentric-text-gray'>
												<span className='uppercase font-semibold'>
													{t("songs:existing-song-performer")}
												</span>
												{": "}
												{songTemp?.data?.performingartists
													.map((p) =>
														p.performingartistname.replace(/&#44;/g, ",")
													)
													.join(", ")}
											</p>
											<p className='text-xs text-old-sentric-text-gray mt-1'>
												<span className='uppercase font-semibold'>ISWC:</span>{" "}
												{songTemp?.data?.iswc || "-"}
											</p>
											<p className='text-xs text-old-sentric-text-gray mt-1'>
												<span className='uppercase font-semibold'>
													{t("songs:duration")}
												</span>
												{": "}
												{songTemp?.data?.durationmins} mins{" "}
												{songTemp?.data?.durationsecs} secs
											</p>
										</div>
										<div className='w-1/2 mt-1 flex flex-row justify-end items-end'>
											<RebrandButtonLink
												id='btnTopWriterAddWriter'
												onClick={() => {
													setSelectedSong(null);
												}}
											>
												{t("songs:existing-song-remove-button")}
											</RebrandButtonLink>
										</div>
									</div>
								</div>
							</>
						)}
						<Spacer />
						<FormError
							mutationError={mutationErrorMessage}
							validationError={validationErrorMessage}
						/>

						<div className='flex flex-wrap justify-end items-center'>
							<div className='mx-2'>
								<RebrandButtonLink
									onClick={() => {
										cancel();
									}}
								>
									{t("songs:cancel-and-discard")}
								</RebrandButtonLink>
							</div>
							<div className='mx-2'>
								<RebrandPrimaryButton
									onClick={() => addRecording({ suggestion: original })}
									disabled={!selectedSong || pending || !songTemp?.data}
									pending={pending}
								>
									{t("songs:add-recording")}
								</RebrandPrimaryButton>
							</div>
						</div>
					</div>
				)}

				{newSong === "true" && (
					<div
						className={`${
							isTunecore ? "bg-tunecore-black" : "bg-sentric-rebrand-light-grey"
						} rounded-t-none rounded-lg mb-2`}
					>
						<SongsCreateUpdate
							suggestion={original}
							onComplete={() => {
								updateSuggestionStatus({ status: 1, id: original.id });
							}}
						/>
					</div>
				)}
			</td>
		</tr>
	);
}

export default SongSuggestionDetails;
