/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import getColours from '../../../../helpers/getColours';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

function Tabs({ tabs, objects }) {
  const [currentTab, setCurrentTab] = useState(0);
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const { highlightColour } = getColours();
  const highlight = isTunecore ? '#00ef86' : highlightColour;

  return (
    <>

      <div className={`border-b-2 border-gray-200 lg:h-14 flex items-center ${tabs.length > 2 ? 'justify-between' : 'justify-start'} w-full flex-wrap`}>
        {tabs.map((t, i) => (
          <button type="button" onClick={() => setCurrentTab(i)} style={{ borderColor: currentTab === i ? highlight : 'transparent' }} key={`tab${i}`} className={`${isTunecore ? 'text-white' : 'text-old-sentric-text-gray'} px-3 uppercase text-xs font-semibold flex items-center border-b-4 h-14 -mb-1 focus:outline-none w-full lg:w-auto`}>{t}</button>
        ))}
      </div>
      {objects.map((o, i) => (
        <div style={{ display: i === currentTab ? 'block' : 'none' }} key={`object${i}`}>{o}</div>
      ))}

    </>
  );
}
export default Tabs;
