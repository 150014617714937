import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    refreshKycStatus,
} from '../../../../api/mutations';
import { getMyAccount } from '../../../../api/queries';
import { apiCall } from '../../../../api/shared';
import AppState from '../../../../store/appstate';
import Spacer from '../../../../components/spacer';
import FormError from '../../../../components/formError';

function IdenfyIFrame(authData) {
    const history = useHistory();
    const authDataObj = { authData };
    const authUrl = authDataObj?.authData?.authToken?.authurl;
    const refreshKycStatusMutation = refreshKycStatus();
    const [validationErrorMessage, setValidationErrorMessage] = useState('');
    const selectedAccount = AppState.useState((s) => s.selectedAccount);
    const myAccount = getMyAccount({
        artistaccountcode: selectedAccount,
    });

    function responseReceived(res) {
        const fetchKycStatus = async () => {
            const kyc = await apiCall({
                endpoint: '/rightsapp/get',
                path: '/api/v1/user/getkyc',
            });

            AppState.update((s) => {
                s.kyc = kyc;
            });
        };
        fetchKycStatus();
        AppState.update((s) => {
            s.navItemsEnabled = true;
        });
        if (res?.kycpassed === true) {
            myAccount.refetch();
            setTimeout(() => {
                history.push('/security-success');
            }, 2000);
        } else if (res?.kycpassed === false) {
            setTimeout(() => {
                history.push('/security-failed');
            }, 2000);
        }
    }

    function errorReceived(error) {
        AppState.update((s) => {
            s.navItemsEnabled = true;
        });
        setValidationErrorMessage(error.message);
        console.error('refreshkycFailed', error);
        AppState.update((s) => {
            s.navItemsEnabled = true;
        });
    }

    function receiveMessage(event) {
        if (event?.data?.status != null && event?.data?.status !== undefined && event?.data?.status !== 'undefined') {
            refreshKycStatusMutation.reset();

            refreshKycStatusMutation.mutate(null, {
                onSuccess: (res) => {
                    if (res?.pendingkyccheck === false) {
                        responseReceived(res);
                    }
                },
                onError: (error) => {
                    errorReceived(error);
                },
            });
        }
    }

    window.addEventListener('message', receiveMessage, false);

    return (
    <>
    <div>
        {authUrl != null && authUrl !== undefined && authUrl !== 'undefined'}
        <div>
            <iframe
              title="identity verification"
              id="idenfyIframe"
              allowFullScreen
              className="w-full h-screen"
              src={authUrl}
              allow="camera"
            />

            <p id="display" />
        </div>
    </div>
    <Spacer />
    <FormError validationError={validationErrorMessage} />
    </>
    );
}
export default IdenfyIFrame;
