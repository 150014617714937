/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import worldLow from '@amcharts/amcharts5-geodata/json/worldLow.json';
// import countries from '@amcharts/amcharts5-geodata/data/countries';
import ckmeans from 'ckmeans';
import Select from '../../../../components/select';
import { mapMapper, twoDp } from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const MapSelector = (props) => {
  const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const { t } = useTranslation('other');

  const {
    buckets, pending, songCode, setRegion,
  } = props;

  const key = songCode;

  const [currentChart, setCurrentChart] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('gl');
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  const drawChart = () => {
    if (currentChart) {
      currentChart.dispose();
    }

    am5.addLicense('AM5C316073875');
    am5.addLicense('AM5M316073875');
    const root = am5.Root.new(`chartMapBig${key}`);

    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    root.numberFormatter.set('numberFormat', `${currencySymbol}#,###.00`);

    const lon = 5;
    const lat = 40;

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        wheelY: 'none',
        wheelX: 'none',
        homeZoomLevel: 1.7,
        homeGeoPoint: { longitude: lon, latitude: lat },
      }),
    );

    const data = [];
    let clusteredData = [];
    const values = [];
    const countries = [];
    let total = 0;
    if (buckets) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < worldLow.features.length; i++) {
        const feature = worldLow.features[i];
        const match = buckets.find((b) => mapMapper(b.key) === feature.properties.name.toLowerCase());
        const country = {
          name: feature.properties.name,
          value: i,
          earnings: 0,
        };

        if (match) {
          data.push({
            id: feature.id,
            value: match?.WriterValues?.value || 0,
          });
          country.earnings = match?.WriterValues?.value || 0;
          values.push(match?.WriterValues?.value || 0);
          total += parseFloat(match?.WriterValues?.value || 0);
        } else {
          data.push({
            id: feature.id,
            value: 0,
          });
          values.push(0);
          total += parseFloat(match?.WriterValues?.value || 0);
        }
        countries.push(country);
      }
    }

    countries.sort((a, b) => {
      if (a.earnings === b.earnings) {
        return a.name.localeCompare(b.name);
      }

      return (a.earnings > b.earnings) ? -1 : 1;
    });

    countries.unshift({
      name: 'Global',
      value: 'gl',
      earnings: total,
    });

    setRegions(countries);

    // Create polygon series
    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: worldLow,
        valueField: 'value',
        calculateAggregates: true,
      }),
    );

    polygonSeries.data.setAll(data);

    if (data.length && values.length) {
      clusteredData = ckmeans(values, 21);
    }

    polygonSeries.set('heatRules', [{
      target: polygonSeries.mapPolygons.template,
      dataField: 'value',
      customFunction(sprite) {
        const colors = [
          0xE9E8E8,
          0x44AAB0,
          0x48AEB4,
          0x4BB1B7,
          0x4DB3B9,
          0x4EB4BA,
          0x52B8BE,
          0x53B9BF,
          0x56BCC2,
          0x59BFC5,
          0x5DC3C9,
          0x5FC5CB,
          0x62C8CE,
          0x64CAD0,
          0x67CDD3,
          0x67CDD3,
          0x69CFD5,
          0x6DD3D9,
          0x6FD5DB,
          0x74D6DC,
          0x81D7DC,
        ];

        const newVal = sprite?.dataItem?.dataContext?.value || 0;

        let colorCount = clusteredData.length;
        // somehow need to work out the index if cluster is a lot smaller than 21
        // which cluster is it in
        // how many in cluster
        // split 21 by number in cluster
        // result is your index

        for (let index = 0; index < clusteredData.length; index++) {
          if (index < (clusteredData.length - 1) && newVal >= clusteredData[index] && newVal < clusteredData[index + 1]) {
            colorCount = index;
          }
        }

        // we now have a range of colour counts - we need to translate to position.

        let multiplier = 1;
        multiplier = Math.floor(21 / clusteredData.length);
        let arrayPos = colorCount * multiplier;
        if (arrayPos > 20) {
          arrayPos = 20;
        }
        if (arrayPos < 0) {
          arrayPos = 0;
        }

        sprite.set('fill', am5.color(colors[arrayPos]));
      },
    }]);

    // chart.set('zoomControl', am5map.ZoomControl.new(root, {}));

    const tooltip = am5.Tooltip.new(root, {
      labelText: '[fontFamily:Montserrat; bold; #4eb4ba]{name}[/]\n[fontFamily:Montserrat; bold; fontSize:18px]{value}[/]',
      getFillFromSprite: false,
    });

    tooltip.get('background').setAll({

      fill: am5.color(0xffffff),
      fillOpacity: 1,
      stroke: am5.color(0x4eb4ba),
      strokeWidth: 2,
    });

    polygonSeries.set('tooltip', tooltip);

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}{id}',
      interactive: true,
      stroke: am5.color(0x555555), 
      strokeWidth: 0.7,
    });

    polygonSeries.events.on('datavalidated', () => {
      chart.goHome();
    });

    setCurrentChart(root);
  };

  useEffect(() => {
    if (!pending && buckets) {
      drawChart();

    // remember to dispose
    }
    return () => {
      if (currentChart) {
        currentChart.dispose();
      }
    };
  }, [buckets, pending]);

  return (
    <div className="flex flex-row flex-wrap w-full">
      <div className="w-full lg:w-4/12  flex flex-col p-4 items-start justify-center">
        <h2 className={`${isTunecore ? 'text-white' : 'text-old-sentric-table-text'} text-sm font-semibold mb-2`}>{t('other:select-territory')}</h2>
        {selectedRegion && regions.length > 0
        && (
        <Select
          className={`select-search ${isTunecore ? 'tunecore-select' : ' select-grey-bg'} w-full text-black font-semibold`}
          name="region"
          placeholder={t('other:select-territory')}
          value={selectedRegion}
          onChange={(selected) => { setSelectedRegion(selected); setRegion(regions.find((r) => r.value === selected)?.name); }}
          options={regions}
          search
        />
        )}
        <div className={`mt-4 p-4 rounded-lg flex flex-col w-full ${isTunecore ? 'bg-tunecore-grey-element' : 'bg-sentric-rebrand-light-grey'}`}>
          <h2 style={{ color: isTunecore ? '#FFFFFF' : '#868484'}} className="text-sm font-semibold mb-2">{t('other:total-earnings-header')}</h2>
          <h3 className="text-2xl font-medium">
            {currencySymbol}
            {twoDp(regions.find((r) => r.value === selectedRegion)?.earnings)}
          </h3>

        </div>
      </div>
      <div className="w-full lg:w-8/12">
        <div id={`chartMapBig${key}`} style={{ width: '100%', height: '400px' }} />
      </div>
    </div>
  );
};

export default MapSelector;
