/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaLock } from 'react-icons/fa';
import { PortableText } from '@portabletext/react';
import { RiErrorWarningFill } from "react-icons/ri";
import moment from 'moment';
import H2 from '../../../../components/h2';
// import P from '../../../../components/p';
// import HR from '../../../../components/hr';
import Input from '../../../../components/input';
import Spacer from '../../../../components/spacer';
import InnerRadio from '../../../../components/innerRadio';
import ButtonInner from '../../../../components/buttonInner';
import ButtonIcon from '../../../../images/rebrandIcons/ButtonIcon.png';
import FormError from '../../../../components/formError';
import AppState from '../../../../store/appstate';
import {
  getMyAccount,
  getMyWriter, getMyWriters, getSocieties, getMyProfile,
} from '../../../../api/queries';
import { createWriter, updateWriter } from '../../../../api/mutations';
import LoadingSmall from '../../../../components/loadingSmall';
import { nameRegexTest, publisherRegexTest, sendEvent, sendProperty } from '../../../../helpers/helpers';
import ButtonPrimaryLarge from '../../../../components/buttonPrimaryLarge';
import ButtonPrimaryText from '../../../../components/buttonPrimaryText';
import Select from '../../../../components/select';
import RebrandPrimaryButton from '../../../../components/rebrandPrimaryButton';
import useWritersTerms from '../../../../api/sanity/queries/getWritersTerms';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';
import validateIPNameNumber from '../../../../helpers/caeipiChecker';
import RebrandButtonLink from '../../../../components/rebrandButtonLink';
import DateFieldInput from '../../../../components/DateFieldsInput';
import getColours from '../../../../helpers/getColours';
import Loading from '../../../../layouts/loading';
// import H1 from '../../../../components/h1';

function WritersForm(props) {
  const { id, modal, closeForm, writerUpdate } = props;
  const isUpdate = !!id;

  const selectedAccount = AppState.useState((s) => s.selectedAccount);
  const { data: siteConfigData } = useSiteConfigAuth();
  const { highlightColour } = getColours(siteConfigData);
  const isTunecore = siteConfigData?.display_name === 'TuneCore';
  const writerTermsId = siteConfigData.writer_terms && siteConfigData.writer_terms._id;
  const { data: termsData } = writerTermsId ? useWritersTerms(writerTermsId) : { data: null };

  const myAccount = getMyAccount({
    artistaccountcode: selectedAccount,
  });

  const [firstName, setFirstName] = useState();
  const [middleName, setMiddleName] = useState();
  const [lastName, setLastName] = useState();
  const [associatedPublisherName, setAssociatedPublisherName] = useState();
  const [associatedPublisherCAEIPI, setAssociatedPublisherCAEIPI] = useState();
  const [hasAssociatedPro, setHasAssociatedPro] = useState();
  const [associatedPro, setAssociatedPro] = useState();
  const [whichPRO, setWhichPRO] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const [caeipiNumber, setCaeipiNumber] = useState();
  const [pending, setPending] = useState(false);
  
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  const [errors, setErrors] = useState({});
  const [termsChecked, setTermsChecked] = useState(false);

  const societies = getSocieties();
  const history = useHistory();
  const createMutation = createWriter();
  const updateMutation = updateWriter();
  const profile = getMyProfile();

  const writers = getMyWriters({ aac: selectedAccount });
  const writer = getMyWriter({ aac: selectedAccount, wc: id });

  const { t } = useTranslation(['writers']);

  // society cisac codes for US PROs - ASCAP, BMI, SESAC, AllTrack respectively
  const usPros = ['010', '021', '071', '999'];

  if ((writer.isError || writer.data === null) && isUpdate) {
    history.push('/writers');
  }

  const [dateOfBirth, setDateOfBirth] = useState({ year: '', month: '', day: ''}); // takes input from DateFieldInput
  const [formattedDOB, setFormattedDOB] = useState(''); // formatted datetime string for API
  const [apiDOB, setApiDOB] = useState(''); // DOB from API to be used in Edit Form

  const handleDateChange = (name, value) => {
    const newDateOfBirth = { ...dateOfBirth, [name]: value };
    setDateOfBirth(newDateOfBirth);

    const formattedDate = moment.utc(`${newDateOfBirth.year}-${newDateOfBirth.month}-${newDateOfBirth.day}`, 'YYYY-MM-DD').format();
    setFormattedDOB(formattedDate);
  };

  const checkErrors = () => {
    const errs = {};
    let valid = true;
    // first name check
    const validFirstName = firstName?.trim().length > 0;
    if (!validFirstName) {
      errs.firstName = t('regex:enter-first-name-check');
      valid = false;
    }

    if (!nameRegexTest(firstName)) {
      errs.firstName = t('regex:invalid-characters2');
      valid = false;
    }

    if (middleName && !nameRegexTest(middleName)) {
      errs.middleName = t('regex:invalid-characters2');
      valid = false;
    }

    // last name check
    const validLastName = lastName?.trim().length > 0;
    if (!validLastName) {
      errs.lastName = t('regex:enter-last-name-check');
      valid = false;
    }

    if (!nameRegexTest(lastName)) {
      errs.lastName = t('regex:invalid-characters2');
      valid = false;
    }

    if (!isUpdate && siteConfigData?.show_writer_terms && !writers?.data?.writers.length > 0 && !termsChecked) {
      errs.terms = t('regex:terms-and-conditions-error');
      valid = false;
    }

    if (dateOfBirth.day || dateOfBirth.month || dateOfBirth.year) {
      if (dateOfBirth) {
        if (!dateOfBirth.year || !dateOfBirth.month || !dateOfBirth.day) {
          errs.dateOfBirth = t('regex:invalid-date-of-birth-error');
          valid = false;
        } else {
          const currentYear = new Date().getFullYear();
          const inputYear = parseInt(dateOfBirth.year, 10);
          if (inputYear < currentYear - 100 || inputYear > currentYear) {
            errs.dateOfBirth = t('regex:invalid-date-of-birth-error');
            valid = false;
          }
          const monthRegex = /^(0?[1-9]|1[0-2])$/; // Allows months from 01 to 12
          const dayRegex = /^(0?[1-9]|[12][0-9]|3[01])$/; // Allows days from 01 to 31
          if (!monthRegex.test(dateOfBirth.month) || !dayRegex.test(dateOfBirth.day)) {
            errs.dateOfBirth = t('regex:invalid-date-of-birth-error');
            valid = false;
          }
        }
      }
    }

    if (caeipiNumber || whichPRO) {
      const validcaeipiNumber = caeipiNumber?.trim().length > 0;
      if (!validcaeipiNumber) {
        errs.caeipiNumber = t('regex:cae-ipi-error');
        valid = false;
      }

      if (caeipiNumber?.trim().length < 9) {
        errs.caeipiNumber = t('regex:cae-ipi-regex');
        valid = false;
      }

      if (!whichPRO) {
        errs.whichPRO = t('regex:choose-an-organisation');
        valid = false;
      }

      if(isTunecore) {
        if (caeipiNumber) {
          const checkSum = validateIPNameNumber(caeipiNumber);
          if (!checkSum) {
            errs.caeipiNumber = t('regex:cae-ipi-checksum-error');
            valid = false;
          }
        } else {
          errs.caeipiNumber = t('regex:cae-ipi-error');
          valid = false;
        }
  
        if (caeipiNumber && ['642005099', '641638348', '641080872'].includes(caeipiNumber.trim())) {
          errs.caeipiNumber = t('regex:cae-ipi-excluded-regex');
          valid = false;
        } else if (caeipiNumber?.startsWith('55')) {
          errs.caeipiNumber = t('regex:cae-ipi-startswith-55-error');
          valid = false;
        }
      }
    }

    // Validate vanity publisher name & associatedPublisherCAEIPI
    if (siteConfigData?.vanity_publisher && associatedPro === true && usPros.includes(whichPRO)) {
      const validAssociatedPublisherName = associatedPublisherName?.trim().length > 0;
      if (!validAssociatedPublisherName) {
        errs.associatedPublisherName = t('regex:empty-publisher-name');
        valid = false;
      }
      if (!publisherRegexTest(associatedPublisherName)) {
        errs.associatedPublisherName = t('regex:invalid-characters2');
        valid = false;
      }
    }

    setErrors(errs);
    return valid;
  };

  const addWriter = async () => {
    setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
    // reset mutation
    createMutation.reset();
    const valid = checkErrors();
    if (valid) {
      const data = {
        artistaccountcode: selectedAccount,
        firstname: firstName,
        middlename: middleName,
        lastname: lastName,
        dateofbirth: formattedDOB,
        societycisaccode: whichPRO || '',
        caeipinumber: caeipiNumber || '',
        publishername: associatedPro ? associatedPublisherName : '',
        publishercaeipi: associatedPro ? associatedPublisherCAEIPI : '',
        isunknownwriter: false,
      };
      createMutation.mutate(data, {
        onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
            toast.success(`${t('writers:writer-added-toast')}: ${firstName} ${lastName}`, {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            writers.refetch();
            setPending(false);
            sendProperty({ property: 'last_writer_added', value: 'CURRENT_DATE', email: profile?.data?.email });
            sendEvent({ property: 'pe14489857_writer_added' });
            if (modal) {
              closeForm();
            } else {
              history.push('/writers');
            }
          }
        },
        onError: (error) => {
          console.log('aerror', error);
          setMutationErrorMessage(error.message);
          setPending(false);
        },
      });
    } else {
      setPending(false);
    }
  };

  const update = async () => {
    setPending(true);
    setMutationErrorMessage('');
    setValidationErrorMessage('');
    // reset mutation
    updateMutation.reset();
    const valid = checkErrors();
    if (valid) {
      const data = {
        artistaccountcode: selectedAccount,
        writercode: id,
        firstname: firstName,
        middlename: middleName,
        lastname: lastName,
        societycisaccode: whichPRO || '',
        caeipinumber: caeipiNumber || '',
        dateofbirth: formattedDOB || apiDOB,
        publishername: associatedPro ? associatedPublisherName : null,
        publishercaeipi: associatedPro ? associatedPublisherCAEIPI : null,
        etag: writer.data.etag,
      };

      updateMutation.mutate(data, {
        onSuccess: (response) => {
          if (response.failedvalidation) {
            setValidationErrorMessage(response.data.messages);
            setPending(false);
          } else {
          toast.success(`${t('writers:writer-updated-toast')}: ${firstName} ${lastName}`, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          writer.refetch();
          writers.refetch();
          if (modal) {
            if(writerUpdate) {
              writerUpdate(data);
            }
            closeForm();
          } else {
            history.push('/writers');
          }
          setPending(false);
          }
        },
        onError: (error) => {
          setMutationErrorMessage(error.message);
          setPending(false);
        },
      });
    } else {
      setPending(false);
    }
  };

  // Pre-fills form fields
  useEffect(() => {
    if (id && writer.isSuccess && !firstName) {
      setFirstName(writer.data.firstname);
      setMiddleName(writer.data.middlename);
      setLastName(writer.data.lastname);
      setCaeipiNumber(writer.data.caeipinumber);
      setWhichPRO(writer.data.societycisaccode);
      setAssociatedPublisherName(writer.data.publishername);
      setAssociatedPublisherCAEIPI(writer.data.publishercaeipi);
      setIsLocked(writer.data.islocked);
      // Check if publishername is null
      setAssociatedPro(writer.data.publishername !== null);

      if (writer.data.dateofbirth) {
        console.log('writer.data.dateofbirth', writer.data.dateofbirth);
        const apiFormattedDOB = moment.utc(writer.data.dateofbirth);
        setApiDOB(apiFormattedDOB);
  
        setDateOfBirth({
          year: apiFormattedDOB.format('YYYY'),
          month: apiFormattedDOB.format('MM'),
          day: apiFormattedDOB.format('DD'),
        });
      }
      
    }
  }, [id, writer.data]);



  useEffect(() => {
    if (selectedAccount) {
      writers.refetch();
      writer.refetch();
    }
  }, [selectedAccount]);

  useEffect(() => {
    // Reset associatedPublisherName and associatedPublisherCAEIPI when whichPRO is changed by the user away from a US PRO
    if (whichPRO && !usPros.includes(whichPRO)) {
      setAssociatedPublisherName(null);
      setAssociatedPublisherCAEIPI(null);
    }
  }, [whichPRO]);

  useEffect(() => {
    // Check if writer.data.publishername exists and is not an empty string
    const hasPublisher = associatedPublisherName;

    // Set associatedPro based on the check result
    setHasAssociatedPro(hasPublisher);
  }, [associatedPublisherName]);

  return (
    <>
      {writers.isLoading ? (
        <Loading />
      ): (
        <div
          className={`${modal
            ? ''
            : 'lg:min-h-screen-minus-header mx-4 lg:mx-12 xl:mx-20 2xl:mx-40 my-12'
            }`}
        >
          {(!id || (id && writer.isSuccess)) && (
            <>
              <div className={`flex flex-col-reverse items-start justify-between mb-4 sm:items-baseline sm:flex-row ${isTunecore ? 'text-white font-tunecore' : ''}`}>
                <H2>
                  {isUpdate ? (
                    <span>
                      {isLocked && <FaLock className="inline" />}
                      {' '}
                      {t('writers:update-header')}
                      {' '}
                      <b>
                        {writer?.data?.firstname}
                        {' '}
                      </b>
                    </span>
                  ) : (
                    t('writers:create-new-writer')
                  )}
                </H2>
                {!isUpdate && !modal && (
                  <RebrandButtonLink
                    onClick={() => history.push('/writers')}
                  >
                    {t('writers:back-to-writers')}
                  </RebrandButtonLink>
                )}
              </div>
              <div className={`${modal ? 'w-full' : 'w-full lg:w-1/2'}`}>
              <p
                className={`${
                  isTunecore ? 'text-white font-tunecore' : 'font-sentric-alt text-sentric-rebrand-grey-text'
                }`}
              >
                  {t('writers:legal-name-explanation')}
                </p>
                <Spacer thin />
                <Input
                  className='form-input'
                  id="txtWriterFormName"
                  required
                  type="text"
                  error={errors.firstName}
                  placeholder={t('auth:first-name')}
                  value={firstName}
                  onBlur={() => { }}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
                <Spacer thin />
                <Input
                  className='form-input'
                  id="txtWriterFormMiddleName"
                  type="text"
                  error={errors.middleName}
                  placeholder={t('auth:middle-name')}
                  value={middleName}
                  onBlur={() => { }}
                  onChange={(e) => {
                    setMiddleName(e.target.value);
                  }}
                />
                <Spacer thin />
                <Input
                  className='form-input'
                  id="txtWriterFormLastName"
                  required
                  type="text"
                  error={errors.lastName}
                  placeholder={t('auth:last-name')}
                  value={lastName}
                  onBlur={() => { }}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
                <Spacer thin/>
  
                {isTunecore && (
                  <>
                    <p
                      className='text-sm text-white font-tunecore'
                    >
                      {t('writers:tunecore-mandatory-fields-text')}
                    </p>
                    <Spacer thin />
                  </>
                )}
  
                {siteConfigData.date_of_birth_required && (
                  <>
                    <DateFieldInput
                      year={dateOfBirth.year }
                      month={dateOfBirth.month}
                      day={dateOfBirth.day }
                      onDatetimeChange={handleDateChange}
                      error={errors.dateOfBirth}
                    />
                    <Spacer thin />
                  </>
                )}
  
                {societies.isSuccess
                  && societies?.data?.societies.length > 0 && (
                    <>
                      <div className="w-full">
                        <Select
                          id="txtWriterFormChooseOrg"
                          options={societies.data.societies.map((p) => ({
                            name: p.name,
                            value: p.cisaccode,
                          }))}
                          onChange={(add) => {
                            setWhichPRO(add);
                          }}
                          value={whichPRO}
                          className={`select-search ${isTunecore ? 'tunecore-select'
                              : 'font-sentric-alt text-base'} `}
                          search
                          filterOptions
                          placeholder={t(
                            'writers:choose-organisation-placeholder',
                          )}
                          label={t('writers:writer-pro-name')}
                        />
                        {!!errors.whichPRO && (
                          <p className={`w-4/5 ${isTunecore ? 'bg-tunecore-error-red bg-opacity-20 border-none' : 'font-sentric-alt bg-red-700 border-white border'} text-sm inline-block p-1 pr-3 pl-3 mt-2 text-white rounded-lg `}>
                            {isTunecore && (
                              <span>
                                <RiErrorWarningFill className="inline-block mr-2 text-2xl text-tunecore-error-red" />
                              </span>
                            )}
                            {errors.whichPRO}
                          </p>
                        )}
                      </div>
  
                      <Spacer thin />
                      <Input
                        className='form-input'
                        id="txtWriterFormCAENumber"
                        type="text"
                        maxLength={11}
                        error={errors.caeipiNumber}
                        placeholder={t('writers:cae-ipi-number-placeholder')}
                        value={caeipiNumber}
                        onBlur={() => { }}
                        onChange={(e) => {
                          setCaeipiNumber(e.target.value);
                        }}
                      />
                    </>
                  )}
                {siteConfigData?.vanity_publisher && usPros.includes(whichPRO) && (
                  <>
                    <Spacer thin />
                    <InnerRadio
                      className="form-radio required-star"
                      name="associatedPro"
                      label={t('writers:associated-publisher-confirm')}
                      value={associatedPro}
                      onChange={(e) => setAssociatedPro(e.target.value === 'true')}
                      options={[
                        { label: t('common:yes'), value: true },
                        { label: t('common:no'), value: false },
                      ]}
                    />
                    {associatedPro === true && (
                      <>
                        <Spacer thin />
                        <Input
                          className='form-input'
                          id="associatedPublisherName"
                          required={associatedPro === true}
                          type="text"
                          placeholder={t('writers:associated-publisher-name-placeholder')}
                          value={associatedPublisherName}
                          error={errors.associatedPublisherName}
                          onBlur={() => { }}
                          onChange={(e) => {
                            setAssociatedPublisherName(e.target.value);
                          }}
                        />
                        <Spacer thin />
                        <Input
                          className='form-input'
                          id="associatedPublisherCAEIPI"
                          required={associatedPro === true}
                          type="text"
                          maxLength={11}
                          error={errors.associatedPublisherCAEIPI}
                          placeholder={t('writers:associated-publisher-cae-ipi-number-placeholder')}
                          value={associatedPublisherCAEIPI}
                          onBlur={() => { }}
                          onChange={(e) => {
                            setAssociatedPublisherCAEIPI(e.target.value);
                          }}
                        />
                      </>
                    )}
                  </>
                )}
                <Spacer />
                {!isUpdate
                  && !writers?.data?.writers.length > 0
                  && siteConfigData?.show_writer_terms && (
                    <div>
                      <h2 className="text-xl font-semibold">
                        {t('common:terms')}
                        {' '}
                        &amp;
                        {' '}
                        {t('common:conditions')}
                      </h2>
  
                      <div className="h-64 p-4 mt-4 mb-8 overflow-y-auto rounded bg-old-sentric-content-white">
                        <div
                          className="html-content"
                        >
                          <PortableText value={termsData?.terms} />
                        </div>
                      </div>
                      <div>
                        <label className="" htmlFor="terms">
                          <input
                            style={{ accentColor: highlightColour }}
                            type="checkbox"
                            id="terms"
                            name="terms"
                            value={termsChecked}
                            className="mr-4"
                            onChange={(e) => setTermsChecked(!termsChecked)}
                          />
                          {t('writers:i-have-read-terms-and-conditions')}
                        </label>
                      </div>
                      {!!errors.terms && (
                        <p className="inline-block w-4/5 p-1 pl-3 pr-3 mt-2 text-sm text-white bg-red-700 border border-white rounded-lg">
                          {errors.terms}
                        </p>
                      )}
                      <Spacer />
                    </div>
                  )}
                  <FormError mutationError={mutationErrorMessage} validationError={validationErrorMessage} />
                  <div className="flex flex-col items-start">
                    <RebrandPrimaryButton
                      id={isUpdate ? 'btnWriterFormUpdate' : 'btnWriterFormAdd'}
                      onClick={isUpdate ? update : addWriter}
                      disabled={isLocked}
                      pending={pending}
                      arrow={!isTunecore}
                    >
                      {isUpdate ? t('writers:update-writer-button') : t('writers:add-writer-button')}
                    </RebrandPrimaryButton>
                  </div>
              </div>
            </>
          )}
          {id && writer.isLoading && <LoadingSmall />}
        </div>
      )}
    </>
  );
}
export default WritersForm;