/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';
import { noDp } from '../../../../helpers/helpers';
import AppState from '../../../../store/appstate';
import useSiteConfigAuth from '../../../../api/sanity/queries/getSiteConfigWithCode';

const TabSongs = ({ result }) => {
const currencySymbol = AppState.useState((s) => s.tenantCurrencySymbol);
  const { t } = useTranslation('other');
  let songs = [];
  if (result?.body?.aggregations?.Songs) {
    songs = result?.body?.aggregations?.Songs.buckets.slice(0, 5);
  }
  const { data: siteConfig } = useSiteConfigAuth();
  const isTunecore = siteConfig?.display_name === 'TuneCore';

  return (
    <div className="p-4  h-full flex items-start flex-col">
      <h1 className={`text-md xl:text-xl font-medium ${isTunecore ? 'text-white' : 'text-black'} lg:mb-4`}>{t('other:songs-tab-header')}</h1>
      {songs.map((s, i) => (
        <div className={`h-14 gap-1 ${i < (songs.length - 1) ? 'border-b' : ''} flex w-full items-center justify-between font-sentric-alt`} key={`song${i}`}>
          <div className="text-xs font-semibold flex flex-row">
            <span className="text-xs bg-sentric-rebrand-grey-text text-white flex-none rounded-full w-4 h-4 mr-2">{(i + 1)}</span>
            <span className="text-left line-clamp-2 break-word">
              {s.SongTitle.buckets[0].key}
            </span>
          </div>
          <div className={`${isTunecore ? 'text-white' : 'text-black '} font-medium text-xs`}>
            {currencySymbol}
            {noDp(s.SongValues.value)}
          </div>

        </div>
      ))}
    </div>
  );
};

export default TabSongs;
